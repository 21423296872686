import React, { useEffect, useState, useRef, useCallback } from 'react';
import { RECORD_TYPES } from '../_config';
import { useSelector, useDispatch} from 'react-redux';
import { createSelector } from 'reselect'
import { Scrollbars } from 'react-custom-scrollbars';

import { actions as modalActions } from '../Modals/_actions';
import { actions as scanActions } from '../PloverScan/_actions';
import { actions as recordActions } from '../Records/_actions';
import { actions } from './_actions'

import { services as recordServices } from '../Records/_services';
import { services as mediaUploadServices } from '../MediaUploader/_services';

import { localeFormatDate } from '../_helpers';
import { LoadingCircle } from '../_components/LoadingCircle';

import { getIntlLocale, getIntlMessages, getSelectedProfile } from '../Authentication/_selectors';
import { getFilteredRecords, getAllRecords, getRecordsFilter, getFilteredRecordsWithFilter} from '../Records/_selectors';
import { getUUID, getCurrentConversationId } from '../Chats/_selectors';
import { getShowRecordsAttachment } from './_selectors';

import {AddPhotoIcon} from '../_images/icons/AddPhotoIcon';
import {PaperclipIcon} from '../_images/icons/PaperclipIcon';
import { SelfScanAttachmentIcon } from '../_images/icons/SelfScanAttachmentIcon';
import { FullScanAttachmentIcon } from '../_images/icons/FullScanAttachmentIcon';

import { RecordUploadMedia } from '../_components/RecordUploadMedia';

import {
  StyledChatRecordAttachment, StyledRecordListItem, StyledRecordListItemTitle, StyledRecordPreview,
  StyledRecordAttachmentFilter, StyledRecordAttachmentList, StyledRecordAttachmentFilterActions,
  StyledOtherAttachments, StyledFileAttachment, StyledPloverAttachment, Divider, StyledBlankRecordListItem
} from './styles';
import { ProfilesFilterTabs } from '../Reports/ProfilesFilterTabs';
import { getRequestFilter } from '../Reports/_selectors';


const BlankRecordAttachment = () => {
  const intlMessages = useSelector(getIntlMessages);
  const recordFilter = useSelector(getRecordsFilter)
  const filteredProfileId = useSelector(getRequestFilter);

  if (filteredProfileId == null){ 
    return (
      <StyledBlankRecordListItem>
        {intlMessages[`chats.chatRecordAttachment.attachment.noSelectedProfile`]}
      </StyledBlankRecordListItem>
    )
  }

  return (
    <StyledBlankRecordListItem>
      {intlMessages[`chats.chatRecordAttachment.attachment.${recordFilter}.noRecords`]}
    </StyledBlankRecordListItem>
  )
}

const RecordAttachment = ({record}) => {
  const dispatch = useDispatch();
  const chatUuid = useSelector(getUUID);
  const intlLocale = useSelector(getIntlLocale)
  const intlMessages = useSelector(getIntlMessages);
  const conversationId = useSelector(getCurrentConversationId);
  const sendRecord = () => {
    const message = {type: "record", senderId: chatUuid, text: record.id}
    dispatch(actions.sendRecord(message))
    dispatch(actions.setShowRecordsAttachment(false));
  }
  return (
    <StyledRecordListItem>
      <StyledRecordListItemTitle>
        {localeFormatDate(record && record.created_at, intlLocale, "llll")}
      </StyledRecordListItemTitle>
      <RecordUploadMedia
        upload={record}
        onClick={sendRecord}
        imgHeight={190}
        imgWidth={190}
        imgBorderRadius={4}
        />
    </StyledRecordListItem>
  )
}

const PhotosFilter = ({onClick, filterType}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <StyledRecordAttachmentFilter
      onClick={onClick}
      selected={filterType!="FULLSCAN"}>
      <SelfScanAttachmentIcon />
      <span>
      {intlMessages['chats.chatRecordAttachment.filter.photos.title']}
      </span>
    </StyledRecordAttachmentFilter>
  )
}

const FullScanFilter = ({onClick, filterType}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <StyledRecordAttachmentFilter
      onClick={onClick}
      selected={filterType=="FULLSCAN"}>
      <FullScanAttachmentIcon />
      <span>
      {intlMessages['chats.chatRecordAttachment.filter.fullScan.title']}
      </span>
    </StyledRecordAttachmentFilter>
  )
}

const RecordAttachmentFilter = ({filter}) => {
  const dispatch = useDispatch();
  const handleClick = (type) => {
    dispatch(recordActions.filterRecords(type))
  }

  return (
    <StyledRecordAttachmentFilterActions>
      <PhotosFilter onClick={() => handleClick("SELF")} filterType={filter}/>
      <Divider />
      <FullScanFilter onClick={() => handleClick("FULLSCAN")} filterType={filter}/>
    </StyledRecordAttachmentFilterActions>
  )
}

const FileAttachmentIcon = ({progress}) => {
  const intlMessages = useSelector(getIntlMessages);
  if (progress > 0){
    return (
      <React.Fragment>
        <LoadingCircle />
        <div>
          {progress + "%"}
        </div>
      </React.Fragment>
    )
  } else {
  return (
    <React.Fragment>
      <PaperclipIcon />
      <div>
        {intlMessages['chats.chatRecordAttachment.attachment.file.title']}
      </div>
    </React.Fragment>
  )
  }
}

export const FileAttachment = () => {
  // TODO make this a file attachment
  const dispatch = useDispatch();
  const chatUuid = useSelector(getUUID);
  const currentConversationId = useSelector(getCurrentConversationId);
  const filteredProfileId = useSelector(getRequestFilter);

  const [ currentFile, setCurrentFile ] = useState(null);
  const [ progress, setProgress ] = useState(0);
  const [ error, setError] = useState(null)

  const inputRef = useRef(null);

  useEffect(()=>{
    if (currentFile){
      recordServices.createRecordId("ATTACHMENT", filteredProfileId, {channel_id: currentConversationId})
        .then(
          data => {

            mediaUploadServices.upload(currentFile, data.id, (event) => {
              setProgress(Math.round((95 * event.loaded) / event.total));
            }).then(
              resp => {
                const message = {
                  type: "record",
                  senderId: chatUuid,
                  text: data.id
                }
                dispatch(actions.sendRecord(message))

                dispatch(actions.setShowRecordsAttachment(false))

                setProgress(0);
                setCurrentFile(null);
              }
            ).catch(
              error => {
                setError(error);
                setProgress(0);
              }
            )
          },
          error => {
            setError(error);
            setProgress(0);
          }
        )
    }
  }, [currentFile] )

  const onFileUpload = async () => {
    if (filteredProfileId == null){
      dispatch(modalActions.openErrorModal("select_profile"));
    } else {
      if (progress == 0){
        inputRef.current.click();
      }
    }
  }

  const onFileChange = (event) => {
    setCurrentFile(event.target.files[0]);
  }

  return (
    <StyledFileAttachment btnDisabled={progress > 0} onClick={onFileUpload}>
      <input ref={inputRef} type="file" onChange={onFileChange} />
      <FileAttachmentIcon progress={progress} />
    </StyledFileAttachment>
  )
}

export const PloverAttachment = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const filteredProfileId = useSelector(getRequestFilter);
  // TODO open camera dialog
  const openCamera = () => {
    if (filteredProfileId == null){
      dispatch(modalActions.openErrorModal("select_profile"));
    } else {
      dispatch(scanActions.setScanType(RECORD_TYPES.SELF));
      dispatch(modalActions.openModal('attachmentPloverScan'));
    }
  }

  return (
    <StyledPloverAttachment onClick={openCamera}>
      <AddPhotoIcon />
      <div >{intlMessages['chats.chatRecordAttachment.attachment.plover.title']}</div>
    </StyledPloverAttachment>
  )
}

export const ChatRecordAttachment = () => {
  const dispatch = useDispatch();
  const showRecordsAttachment = useSelector(getShowRecordsAttachment);
  //const recordsList = useSelector(getAllRecords)
  const recordsList = useSelector(getFilteredRecordsWithFilter);
  const recordFilter = useSelector(getRecordsFilter);

  useEffect(()=>{
    dispatch(recordActions.fetchRecords())
  }, [])

  return (
    <StyledChatRecordAttachment show={showRecordsAttachment}>
      <div className={`pt-2 px-2 pb-0 w-full`}>
        <ProfilesFilterTabs page={'CHATRECORDATTACHMENT'} />
      </div>
      <StyledRecordAttachmentList>
          <StyledOtherAttachments>
            <FileAttachment />
            <PloverAttachment />
          </StyledOtherAttachments>
          {recordsList.map((elem, index) =>
            <RecordAttachment key={elem.id} record={elem} />
          )}
          {recordsList.length < 1 &&
            <BlankRecordAttachment />
          }
      </StyledRecordAttachmentList>
      <RecordAttachmentFilter filter={recordFilter} />
    </StyledChatRecordAttachment>
  )
}
