import React from 'react';

export const ArrowRightIcon = ({width=11, height=11, color="#405159"}) => {

  return(
    <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 11 11" color={color} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Arrow">
        <path id="Vector 568" d="M0 5.5H10M10 5.5L5.35714 1M10 5.5L5.35714 10" stroke="currentColor"/>
        </g>
    </svg>
  )
}
