import { Box, VStack, Flex, Text, Button, CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import { FormattedMessage } from 'react-intl';
import { services } from '../../Reports/_services';
import { getIntlLocale, getIntlMessages, getProfile, getSelectedProfile } from '../../Authentication/_selectors';
import { useState, useEffect } from 'react';
import { LoadingCircle } from '../../_components/LoadingCircle';
import { BackChevronIcon } from '../../_images/icons/BackChevronIcon';
import { ForwardChevronIcon } from '../../_images/icons/ForwardChevronIcon';
import { useRequestDates } from './getDates';
import { localeFormatDate } from '../../_helpers';
import { useReportDatesStore } from '../state/reports';

function getBgColor(score){
    if (score == null){
        return '#E1E8EC'
    }
    if (score > 79){
        return '#DAF3ED'
    } else if (score > 59){
        return '#FFEDCC'
    } else {
        return '#FFE0E0'
    }
}

function getTextColor(score){
    if (score == null){
        return '#6B8A99'
    }
    if (score > 79){
        return '#44C5A6'
    } else if (score > 59){
        return '#FFA500'
    } else {
        return '#F66'
    }
}

const ReportStatusText = ({score}) => {
    if (score == null){
        return <></>
    }
    if (score > 79){
        return (
            <FormattedMessage 
                id={'reportDetail.scoreCard.status.healthy'} 
                defaultMessage={'healthy'} 
            />)
    } else if (score > 59){
        return (<FormattedMessage 
            id={'reportDetail.scoreCard.status.fair'} 
            defaultMessage={'fair'}
            />)
    } else {
        return <FormattedMessage id={'reportDetail.scoreCard.status.poor'} defaultMessage={'poor'} />
    }
}


const ScoreChart = ({score}) => {
    return(
      <Flex justify={'center'} w={'100%'}>
        <CircularProgress
            value={score}
            size={'300px'}
            valueText={`${score}%`}
            color={getTextColor(score)}
            thickness={'5px'}
            capIsRound
        >
            <CircularProgressLabel
                color={getTextColor(score)}
            >
                {`${score ? Math.ceil(score) : '--'}%`}
            </CircularProgressLabel>
        </CircularProgress>
      </Flex>
    )
}

const DateNavigator = ({reportId, currentDate, handlePrevious, handleNext, direction, isFirst=false, isLast=false}) => {
    const intlLocale = useSelector(getIntlLocale); 
    return (
        <Flex justify={'space-between'} w={['full']} px={['20px']}>
            <Flex align={'center'} _hover={{cursor: 'pointer'}} p={'10px'} onClick={handleNext} visibility={isLast ? 'hidden' : 'visible'}>
                <BackChevronIcon />
            </Flex>
            <Flex>
                <Text
                    color={'#273238'}
                    fontSize={'18px'}
                >
                    {localeFormatDate(currentDate, intlLocale, 'll')}
                </Text>
            </Flex>
            <Flex align={'center'} _hover={{cursor: 'pointer'}} p={'10px'} onClick={handlePrevious} visibility={isFirst ? 'hidden': 'visible'}>
                <ForwardChevronIcon />
            </Flex>
        </Flex>
    )
}
export const StatusTag = ({score}) => {
    return (
        <Flex
            padding={'5px 10px'}
            justify={'center'}
            align={'center'}
            borderRadius={'30px'}
            bg={getBgColor(score)}
        >
            <Text
                color={getTextColor(score)}
                fontSize={'14px'}
            >
                <ReportStatusText score={score}/>
            </Text>

        </Flex>
    )
}
export const ReportScoreCard = ({report, next, prev, direction, isFirst=false, isLast=false, isFetching=false}) => {
    console.log("report ", report);
    const intlMessages = useSelector(getIntlMessages);
    let score = report?.score
    let currentDate = report?.created_at || new Date()
    let summary = report?.summary
    const history = useHistory()

    if (report == null){
        summary = intlMessages['reportDetail.scoreCard.description.empty']
    }

    const handleClick = () => {
        history.push('/requests/' + report.id)
      }

    const handleNewScan = () => {
        history.replace({
          pathname: "/createrequest/clinics"
        });
    }
    
    return (
        <VStack position={'relative'} align={'center'} spacing={'25px'} w={'full'}>
            <Flex w={'full'} align={'center'} justify={'space-between'}>
                <Text fontSize={'24px'}>
                    {intlMessages['reportDetail.scoreCard.header.title']}
                </Text>
                <StatusTag score={score} />
            </Flex>
            <DateNavigator 
                reportId={report?.id}
                handleNext={next}
                handlePrevious={prev}
                isFirst={isFirst} 
                isLast={isLast}
                currentDate={currentDate} 
                direction={direction}
            />
            <Box position={'relative'} w={['full']}>
                {isFetching && <Flex height={'300px'} w={['full']} position={'absolute'} align={'center'} justify={'center'}><LoadingCircle /></Flex>}
                <ScoreChart score={score} />
            </Box>
            <Flex justify={'center'}  w={['full']} px={['30px']}>
                <Text align={'center'}>
                    {summary}
                </Text>
            </Flex>
            <Flex w={['full']} px={['30px']}>
                {
                    report?.id ?(
                        <Button isDisabled={report?.status != 'READY' && report?.status != 'COMPLETE'}  w={'full'} onClick={handleClick} >
                            <Text textTransform={'uppercase'}>
                                <FormattedMessage
                                    id={'reportDetail.scoreCard.button.label'}
                                    defaultMessage={'View Full Report'}
                                />
                            </Text>
                            
                        </Button>
                    ) : (
                        <Button w={'full'} onClick={handleNewScan} >
                            <Text textTransform={'uppercase'}>
                                <FormattedMessage
                                    id={'dashboard.checkup.information.button.text'}
                                    defaultMessage={'Start a Scan'}
                                />
                            </Text>
                            
                        </Button>
                    )
                }
            </Flex>
        </VStack>
    )
}

const ReportPaginationWrapper = ({profileId, currentIndex, direction, setCurrentIndex}) => {
    //const query = useRequestDates({profileId, lastTs})
    const [ isFetching, setIsFetching ] = useState(false)
    const addReports = useReportDatesStore(state => state.addReports)
    const reports = useReportDatesStore(state => state.reports)
    const hasMore = useReportDatesStore(state => state.hasMore)

    let currentProfileReports = reports[profileId] || []
    let currentProfileHasMore = hasMore[profileId]
    
    const next = () => {
        // prev is technically going backwards in terms of user UI
        if ((currentIndex >= currentProfileReports.length - 1) && currentProfileHasMore){
            setIsFetching(true)
            // fetch more
            services.fetchReportDates(profileId, currentProfileReports[currentIndex].created_at_ts)
                .then((res) => {
                    setIsFetching(false)
                    addReports(profileId, res.request_date_list, res.is_more)
                    if (res.request_date_list.length > 0){
                        setCurrentIndex([currentIndex + 1, -1])
                    }
                }).catch((err) => {
                    setIsFetching(false)
                })  
        } else if (currentIndex < currentProfileReports.length - 1){
            setCurrentIndex([currentIndex + 1, -1])
        }
    }

    const prev = () => {
        // prev is technically going forward in terms of user UI
        if (currentIndex != 0){
            // check if there is more query index
            setCurrentIndex([Math.max(currentIndex - 1, 0), 1])
        }
    }

    if (currentIndex < currentProfileReports.length){
        return (
            <Flex direction={'column'} w={'full'}>
                <ReportScoreCard 
                    direction={direction}
                    isFetching={isFetching}
                    isFirst={currentIndex == 0}
                    isLast={(currentProfileHasMore == false) && (currentIndex >= currentProfileReports.length - 1)}
                    report={currentProfileReports[currentIndex]}
                    prev={prev}
                    next={next}
                />
            </Flex>
        )
    } else {
        return (
            <Flex direction={'column'} w={'full'}>
                <ReportScoreCard 
                    direction={null}
                    isFetching={isFetching}
                    isFirst={true}
                    isLast={true}
                    report={null}
                    prev={null}
                    next={null}
                />
            </Flex>
        )
    }
}

export const ReportScoreContainer = () => {
    const PAGE_SIZE = 20;
    const profile = useSelector(getProfile)
    const [ [currentIndex, direction], setCurrentIndex ] = useState([0,0])
    const [ queryIndex, setQueryIndex ] = useState(0)
    const setReport = useReportDatesStore(state => state.setReports)
    const refetch = useReportDatesStore(state => state.refetch)
    const selected = useSelector(getSelectedProfile)

    useEffect(() => {
        async function f(profileId) {
            let res = await services.fetchReportDates(profileId)
            setReport(profileId, res.request_date_list, res.is_more)
        }
        f(selected?.id || profile?.id)
    }, [profile?.id, selected?.id, refetch ])

    return (
        <Flex direction={'column'} w={'full'}>
            <ReportPaginationWrapper 
                direction={direction}
                profileId={selected?.id}
                currentIndex={currentIndex}
                queryIndex={queryIndex}
                setCurrentIndex={setCurrentIndex}
            />
        </Flex>
    )
}
