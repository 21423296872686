import { US_STRIPE_KEY, JP_STRIPE_KEY } from '../_config';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { PaymentForm as StripePaymentForm } from './StripeClient/PaymentForm';
import { OmisePaymentForm } from './OmiseClient/OmisePaymentForm';


import { actions as modalActions } from '../Modals/_actions';
import { actions as mediaActions } from '../MediaUploader/_actions';
import { actions } from './_actions';

import { getIntlMessages, getIntlLocale, getPaymentProcessorCountry, getPaymentProcessor } from '../Authentication/_selectors';

import {
  Wrapper,
  PaymentFormWrapper,
  StyledPaymentForm,
  StyledCheckupFormHeader,
  StyledStripePaymentForm
} from './styles';
import { useHistory } from 'react-router-dom';


export const PaymentForm = ({paymentIntent, onSuccess}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const paymentProcessor = useSelector(getPaymentProcessor);
  const paymentCountry = useSelector(getPaymentProcessorCountry);

  useEffect(()=>{
    // TODO: if paymentProcessor is createPayment then request new payment creation
    console.log("Payment FOrm on load. bere")
    return () => {
      //dispatch(actions.setPaymentIntent(null))
    };
  }, [])

  const closeModal = () => {
  console.log("close modal")
    dispatch(actions.setPaymentIntent(null))
    dispatch(mediaActions.resetMedia());
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push("/")
  }

  const getProcessorForm = () => {
    console.log("getProcessorForm ", paymentProcessor)
    if (paymentProcessor == "STRIPE"){
      let stripe = null;
      if (paymentCountry == "JP"){
        stripe = loadStripe(
          JP_STRIPE_KEY,
          {locale: intlLocale}
        )
      } else if (paymentCountry == "US"){
        stripe = loadStripe(
          US_STRIPE_KEY,
          {locale: intlLocale}
        )
      }
      console.log("stripe", stripe)
      return (
        <StyledStripePaymentForm>
        <Elements stripe={stripe}>
          <StripePaymentForm
            paymentIntent={paymentIntent}
            onSuccess={onSuccess}
            />
        </Elements>
        </StyledStripePaymentForm>
      )
    } else if (paymentProcessor == "OMISE"){
      return (
        <OmisePaymentForm
          amount={paymentIntent}
        />
      )
    } else{
      return (<div></div>)
    }
  }

  return (
    <PaymentFormWrapper>
      <StyledCheckupFormHeader>
        <div className="navButton isDisabled">{intlMessages['checkup.paymentform.navbutton.request']}</div>
        <div className="title">{intlMessages['checkup.paymentform.title']}</div>
        <div className="navButton" onClick={closeModal}>{intlMessages['checkup.paymentform.navbutton.cancel']}</div>
      </StyledCheckupFormHeader>
      <StyledPaymentForm>
        {getProcessorForm()}
      </StyledPaymentForm>
    </PaymentFormWrapper>
  )
}
