const PREFIX = 'CLINICS_';

export const constants = {
  UPDATE: `${PREFIX}UPDATE`,
  UPDATE_MAP_CENTER :  `${PREFIX}UPDATE_MAP_CENTER`,
  UPDATE_MAP_BOUNDS :  `${PREFIX}UPDATE_MAP_BOUNDS`,
  SEARCHCLINICS_REQUEST: `${PREFIX}SEARCHCLINICS_REQUEST`,
  SEARCHCLINICS_SUCCESS: `${PREFIX}SEARCHCLINICS_SUCCESS`,
  SEARCHCLINICS_FAILURE: `${PREFIX}SEARCHCLINICS_FAILURE`,
  FETCH_DENTIST_DETAILS_REQUEST: `${PREFIX}FETCH_DENTIST_DETAILS_REQUEST`,
  FETCH_DENTIST_DETAILS_SUCCESS: `${PREFIX}FETCH_DENTIST_DETAILS_SUCCESS`,
  FETCH_DENTIST_DETAILS_FAILURE: `${PREFIX}FETCH_DENTIST_DETAILS_FAILURE`, 
  FETCH_DENTIST_REVIEWS_REQUEST: `${PREFIX}FETCH_DENTIST_REVIEWS_REQUEST`,
  FETCH_DENTIST_REVIEWS_SUCCESS: `${PREFIX}FETCH_DENTIST_REVIEWS_SUCCESS`,
  FETCH_DENTIST_REVIEWS_FAILURE: `${PREFIX}FETCH_DENTIST_REVIEWS_FAILURE`
}
