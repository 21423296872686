import { API_URLS } from '../_config';
import { authHeader, handleResponse } from '../_helpers';


export const services = {
  fetchChatUserInformation,
  fetchLatestCheckupWithDentist
};


function fetchChatUserInformation(id, type="uuid"){
  const requestUrl = API_URLS.fetchUserInformation;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{q_type}", type).replace("{unique_id}", id);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchLatestCheckupWithDentist(id, type="uuid", query=null){
  const requestUrl = API_URLS.fetchLatestCheckupwithDentist;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{q_type}", type).replace("{unique_id}", id);
  if (query){
    url += `?q=current`
  }
  return fetch(url, requestOptions).then(handleResponse);
}
