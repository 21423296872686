import { API_URLS } from '../_config';
import axios from 'axios'
import { authHeader, handleResponse } from '../_helpers';


const upload = (file, record, onUploadProgress, extraData = {led: -1}) => {
  let formData = new FormData();
  const requestUrl = API_URLS.uploadToPartialRecord.url.replace('{recordId}', record);
  const requestHeaders = {
    'Content-Type': "multipart/form-data",
    ...authHeader()
  }
  formData.append("file", file);
  formData.append("extra_data", JSON.stringify(extraData));

  return axios.post(requestUrl, formData, {
    headers: requestHeaders,
    onUploadProgress
  });
};

function fetchPartialScan(id){
  const requestUrl = API_URLS.fetchPartialScan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{recordId}', id);
  return fetch(url, requestOptions).then(handleResponse);
}

export const services = {
  fetchPartialScan,
  upload
}
