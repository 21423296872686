import { constants } from './_constants';
import { usePubNub } from 'pubnub-react';

import {
  decryptMessage,
  getNotificationChannelName,
  getMarkNotificationReadAction
} from '../_helpers/pubnubUtils';

export const actions = {
  loadNotificationChannel,
  markNotificationRead,
  markNotificationArrayRead,
  insertNotification
}
/**
 * This is called for notification history. Might have multiple notifications
 * per request id. Mark all those notifications read.
 * @param  {String} chatUuid unique_id of user
 * @param  {Array} notifs   array of notifications from notifications reducer
 */
function markNotificationArrayRead(chatUuid, notifs){
  return (dispatch, getState, context) => {
    let notifChannel = getNotificationChannelName(chatUuid);
    // loop through each of the notifications in the array
    // [request.unique_id]: [notif1, notif2, etc...] since multiple notifications
    // can be sent about a single request
    // (originally function markNotificationRead() for single notification)
    notifs.map((notif) => {
      let notifId = notif.timetoken;
      dispatch(request(notifId));
      context.pubnub.api.addMessageAction(
        {
          channel: notifChannel,
          messageTimetoken: notifId,
          action: getMarkNotificationReadAction(chatUuid)
        },
        (status, response) => {
          if (status.error){
            dispatch(failure(notifId, status))
          } else if (response) {
            dispatch(success(notifId, getState().notifications.byId[notifId], response.data))
          } else {
            console.log("response is empty", response)
            dispatch(failure(notifId, status));
          }
        }
      )
    })
  }
  function request(id){return { type: constants.MARKREAD_NOTIFICATION_REQUEST, data: {id}}};
  function failure(id, error){return { type: constants.MARKREAD_NOTIFICATION_FAILURE, data: {id, error}}}
  function success(id, stateData, data){
    // create a new data field for action if not there, or update the action
    const actionData = {uuid: data.uuid, actionTimetoken: data.actionTimetoken}
    if (stateData.data && stateData.data.read_status){
      if (stateData.data.read_status.read){
        stateData.data.read_status.read = [...stateData.data.read_status.read, actionData]
      } else {
        stateData.data.read_status = {...stateData.data.read_status, read: [actionData]}
      }
    } else {
      stateData.data = {...stateData.data, read_status: {read: [actionData]}}
    }
    return { type: constants.MARKREAD_NOTIFICATION_SUCCESS, data: { notification: stateData, id } }
  }
}

/**
 * Called to mark notification read. Only marks single notification given notifId
 * @param  {String} chatUuid unique_id of user
 * @param  {String} notifId  timetoken of the notification to mark read
 */
function markNotificationRead(chatUuid, notifId){
  return (dispatch, getState, context) => {
    let notifChannel = getNotificationChannelName(chatUuid);
    // assuming pubnub is already set up?
    //context.pubnub.api.setUUID(chatUuid).setAuthKey(chatAuthKey);
    dispatch(request(notifId));
    // add message action for the notification
    context.pubnub.api.addMessageAction(
      {
        channel: notifChannel,
        messageTimetoken: notifId,
        action: getMarkNotificationReadAction(chatUuid)
      },
      (status, response) => {
        if (status.error){
          // returns 409 if action was already made
          dispatch(failure(notifId, status))
        } else if (response) {
          dispatch(success(notifId, getState().notifications.byId[notifId], response.data))
        } else {
          console.log("response is empty", response)
          dispatch(failure(notifId, status));
        }
      }
    )
  }
  function request(id){return { type: constants.MARKREAD_NOTIFICATION_REQUEST, data: {id}}};
  function failure(id, error){return { type: constants.MARKREAD_NOTIFICATION_FAILURE, data: {id, error}}}
  function success(id, stateData, data){
    // create a new data field for action if not there, or update the action
    const actionData = {uuid: data.uuid, actionTimetoken: data.actionTimetoken}
    if (stateData.data && stateData.data.read_status){
      if (stateData.data.read_status.read){
        stateData.data.read_status.read = [...stateData.data.read_status.read, actionData]
      } else {
        stateData.data.read_status = {...stateData.data.read_status, read: [actionData]}
      }
    } else {
      stateData.data = {...stateData.data, read_status: {read: [actionData]}}
    }
    return { type: constants.MARKREAD_NOTIFICATION_SUCCESS, data: { notification: stateData, id } }
  }
}

/**
 * insert a newly received notification from pubnub message listener
 * @param  {String} chatUuid     user's unique_id
 * @param  {PNMessage} notification Pubnub Message object received from listener
 */
function insertNotification(chatUuid, notification){
  return (dispatch, getState, context) => {
    let notifChannel = getNotificationChannelName(chatUuid);
    dispatch(success(context.pubnub.api, notification))
  }
  function success(pn, notification){
    var decrypted = decryptMessage(pn, notification, process.env.REACT_APP_PUBNUB_CIPHER_KEY);
    const newData = {
      ...notification,
      message: decrypted
    };
    return { type: constants.UPDATE_NOTIFICATION_RECEIVED, data: {[notification.timetoken]: newData}}
  }
}

/**
 * initialization of notifications on page load
 * @param  {String} chatUuid    users unique_id
 * @param  {[String} chatAuthKey user pubnub token needed in case pubnub not initialized
 */
function loadNotificationChannel(chatUuid, chatAuthKey){
  return (dispatch, getState, context) => {
    let notifChannel = getNotificationChannelName(chatUuid);
    context.pubnub.api.setUUID(chatUuid).setAuthKey(chatAuthKey);
    dispatch(request());
    context.pubnub.api.fetchMessages(
      {
        channels: [notifChannel],
        includeMessageActions: true,
        includeMeta: true,
        count: 50
      },
      (status, response) => {
          if (status.error ){
            console.log("operation failed w/ error:", status);
            dispatch(failure(status.error));
          } else if (response){
            // set all messages?
            dispatch(success(context.pubnub.api, response.channels[notifChannel]))
          } else {
            console.log("response error ", status)
            dispatch(failure("error fetching notifications"));
          }
      }
    )

    context.pubnub.api.subscribe({
      channels: [notifChannel]
    })

  }
  function request(){return { type: constants.FETCH_NOTIFICATIONS_REQUEST}};
  function failure(error){return { type: constants.FETCH_NOTIFICATIONS_FAILURE, error}}
  function success(pn, data){
    const newData = {};
    const requestData = {};
    (data || []).map((elem) => {
      var decrypted = decryptMessage(pn, elem, process.env.REACT_APP_PUBNUB_CIPHER_KEY);
      newData[elem.timetoken] = {
        ...elem,
        message: decrypted
      };
    })
    return { type: constants.FETCH_NOTIFICATIONS_SUCCESS, data: {byId: newData}}
  }

}
