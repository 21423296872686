import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import { googleMapStyler } from '../_styles/googleMapStyler';
import { MAP_CENTERS } from '../_config';

import { toLower, toUpper } from 'lodash';

// components
import selectedMapIcon from '../_images/SelectedMapIcon.svg';
import mapIcon from '../_images/MapIcon.svg';
import { LoadingEllipses } from '../_components/LoadingEllipses';

// redux actions
import {
  getClinics,
  getCurrentClinicId,
  getClinicsLoading,
  getCurrentClinic,
  getMapCenter,
  getMapBounds,
  getDragStarted,
  getQueryType
 } from './_selectors';
import { actions } from './_actions';

// selectors
import { getCountry, getIntlLocale } from '../Authentication/_selectors';

// styles
import { StyledClinicMapSection } from './styles';

const containerStyle = {
  height: '500px'
};

const center_th = {
  lat: 13.747145208086701,
  lng: 100.50224734643409
}

const center_us_ca = {
  lat: 34.0453855,
  lng: -118.2996791
}

const center_us_ny = {
  lat:40.7437902,
  lng: -74.001601
}

const center_jp = {
  lat: 35.6681625,
  lng: 139.6007869
}

const intlMessages = {
  'clinicSearchPage.clinicmap.searchmore': 'search this area'
}

export const ClinicMap = ({init, setGoogleMap}) => {
  const dispatch = useDispatch();
  const clinics = useSelector(getClinics);
  const clinicsLoading = useSelector(getClinicsLoading);
  const currentClinicId = useSelector(getCurrentClinicId);
  const currentClinic = useSelector(getCurrentClinic);
  const defaultMapCenter = useSelector(getMapCenter);
  const defaultMapBounds = useSelector(getMapBounds);
  const queryType = useSelector(getQueryType);
  const dragStarted = useSelector(getDragStarted);
  const mapRegion = useSelector(getCountry)
  const intlLocale = useSelector(getIntlLocale);

  const [currentMap, setMap] = useState(null)

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    language: `${intlLocale}`,
    region: `${mapRegion}`,
    libraries: ['places']
  })

  useEffect(()=>{
    if (clinics.length > 0 && currentClinicId ==  null){
      dispatch(actions.selectClinic(currentClinic.unique_id));
    }
    if (currentClinic.unique_id != null && clinicsLoading == false && defaultMapCenter == null){
      console.log("does this run last?")
      dispatch(actions.updateMapCenter(currentClinic.clinic.geopoint.lat,
                                    currentClinic.clinic.geopoint.lon));
    }
  }, [clinicsLoading])

  useEffect(()=>{
    // case 1: if not initialized, set bounding box to all clinic results
    // case 2: BUT if only 1 clinic, then set box to a zoom
    // if initialized already, then map center gets updated imperatively with list item click
    if (currentMap && (defaultMapBounds || defaultMapCenter) && init.current == false){
      if (defaultMapBounds && defaultMapBounds.north){
        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend({lat: Number(defaultMapBounds.south), lng: Number(defaultMapBounds.west)});
        bounds.extend({lat: Number(defaultMapBounds.north), lng: Number(defaultMapBounds.east)});
        currentMap.fitBounds(bounds, 0);
      } else {
        currentMap.panTo(defaultMapCenter);
        currentMap.setZoom(15);
      }
      init.current = true;
    } else if (currentMap && queryType == "region"  && init.current == true){
      var bounds = new window.google.maps.LatLngBounds();
      if (defaultMapBounds.south == defaultMapBounds.north){ // clinics length == 1
        dispatch(actions.dragStarted(false));
        currentMap.panTo({lat: Number(defaultMapBounds.south), lng:Number(defaultMapBounds.west)});
        currentMap.setZoom(15);
        dispatch(actions.dragStarted(false));
      } else {
        bounds.extend({lat: Number(defaultMapBounds.south), lng: Number(defaultMapBounds.west)});
        bounds.extend({lat: Number(defaultMapBounds.north), lng: Number(defaultMapBounds.east)});
        currentMap.fitBounds(bounds, 0);
      }
    } else if (currentMap && defaultMapCenter && init.current == true){
    }
  }, [defaultMapCenter, currentMap, clinics, queryType])

  useEffect(()=>{
    if (currentMap != null && init.current == true){
    }
  }, [clinics])

  const onLoad = React.useCallback(function callback(map) {
    setGoogleMap(map);
    map.setZoom(12);
    if (defaultMapCenter && init.current == true){
      map.panTo(defaultMapCenter);
    }
    if (defaultMapBounds && init.current == false){
      var bounds = new window.google.maps.LatLngBounds();
      bounds.extend({lat: defaultMapBounds.south, lng: defaultMapBounds.west});
      bounds.extend({lat: defaultMapBounds.north, lng: defaultMapBounds.east});
      map.fitBounds(bounds);
      init.current = true;
    }
    setMap(map)
    dispatch(actions.dragStarted(false));
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onDragStart = () => {
    dispatch(actions.dragStarted(true));
  }

  const onBoundsChanged = () => {
    //dispatch(actions.dragStarted(true));
    //console.log("onBoundsChanged", currentMap.getBounds().toString())
    //dispatch(actions.updateDragMapBounds(currentMap.getBounds().toJSON()))
  }

  const onZoomChanged = () => {
    dispatch(actions.dragStarted(true));
  }

  const handleRedoSearchClick = () => {
    let {north, west, south, east} = currentMap.getBounds().toJSON()
    dispatch(actions.searchClinics('bbox', `${north},${west},${south},${east}` ))
    dispatch(actions.dragStarted(false));
    init.current = false;
  }

  const handleMarkerClick = (uid) => {
    dispatch(actions.selectClinic(uid));
  }

  if (isLoaded){
    return(
      <StyledClinicMapSection>
          { dragStarted &&
            <div className={'searchAgain'} onClick={handleRedoSearchClick}>
              <div>{intlMessages['clinicSearchPage.clinicmap.searchmore']}</div>
            </div>
          }
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={MAP_CENTERS[toLower(mapRegion) || 'us']}
            zoom={11}
            onLoad={onLoad}
            onUnmount={onUnmount}
            region={toUpper(mapRegion)}
            language={intlLocale}
            onDragStart={onDragStart}
            onBoundsChanged={onBoundsChanged}
            onZoomChanged={onZoomChanged}
            options={{ 
              fullscreenControl: false,
              streetViewControl: false, 
              mapTypeControl: false,
              clickableIcons: false,
              styles: googleMapStyler
            }}
          >
            {
              clinics.map((elem, ind) =>
                <Marker
                  icon={(currentClinicId == elem.unique_id) ? selectedMapIcon : mapIcon }
                  position={{ lat: elem.clinic.geopoint.lat, lng: elem.clinic.geopoint.lon }}
                  onClick={() => handleMarkerClick(elem.unique_id)}
                  />
              )
            }
          </GoogleMap>
      </StyledClinicMapSection>
    )
  } else {
    return (
      <StyledClinicMapSection>
        <div className={'loading'}>
          <LoadingEllipses />
        </div>
      </StyledClinicMapSection>
    )
  }
}
