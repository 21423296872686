import { createSelector } from 'reselect';

export const getAppSettingsSlice = state => state.appSettings;

export const getCountries = createSelector(
  getAppSettingsSlice,
  (appSettings) => {
    return (appSettings.settings.countries)
      ? appSettings.settings.countries
      : []
  }
)

export const getRelationships = createSelector(
  getAppSettingsSlice,
  (appSettings) => {
    return (appSettings.settings.relationships)
      ? appSettings.settings.relationships
      : [];
    }
)

export const getFavoriteDentist = createSelector(
  getAppSettingsSlice,
  (appSettings) => {
    return (appSettings.settings.favoriteDentist)
      ? appSettings.settings.favoriteDentist
      : [];
  }
)

export const getChannelIOLoaded = createSelector(
  getAppSettingsSlice,
  (appSettings) => {
    return appSettings.channelIOLoaded;
  }
)

export const getRegions = createSelector(
  getAppSettingsSlice,
  (appSettings) => {
    return (appSettings.settings.regions)
      ? appSettings.settings.regions
      : {};
    }
)

export const getRegionsLoading = createSelector(
  getAppSettingsSlice,
  (appSettings) => {
    return appSettings.loading.regions
  }
)

export const getCurrentCountry = createSelector(
  getAppSettingsSlice,
  (appSettings) => {
    return appSettings.currentCountry
  }
)

export const getCountryRegions = createSelector(
  [getCurrentCountry, getRegions],
  (currentCountry, regions) => {
    return (currentCountry && regions && regions[currentCountry])
      ? regions[currentCountry]
      : []
  }
)
