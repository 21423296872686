import React from 'react';
import {
    Box,
    Flex,
    Text,
    Divider,
} from '@chakra-ui/react';

import { FormattedMessage } from 'react-intl';
import { HealthyReportIcon } from '../../_images/icons/HealthyReportIcon';
  
export const HealthyReportSection = () => {
    return (
        <Box w={'580px'}>
            <Box
              p={['20px']} 
              pl={[0]}
              m={['5px']}
              bg={['#fff']}
              borderRadius={['15px']}
            >
                <Flex w={'full'} justify={'center'} align={'center'} py={'50px'} direction={'column'}>
                    <HealthyReportIcon />
                    <Text
                        color={'#6B8A99'}
                        fontSize={'18px'}
                        textAlign={'center'}
                        mt={'10px'}
                    >
                        <FormattedMessage 
                        id={'requestDetailPage.reportsection.report.noconditions'}
                        defaultMessage={'No conditions detected'}
                        />
                    </Text>
                </Flex>
                <Divider orientation='horizontal' borderColor={'#C5C5C5'} py={'10px'} />
                <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'} mt={'20px'}>
                    <Text color="#394B55" fontSize={'18px'} textTransform={'capitalize'}
                        textAlign={'left'}
                    >
                        <FormattedMessage 
                            id={'requestDetailPage.reportsection.report.healthy.title'}
                            defaultMessage='Healthy'
                        />
                    </Text>
                    <Text
                        color={'#405159'}
                        fontSize={'15px'}
                        lineHeight={'18px'}
                        textAlign={'left'}
                        mt={'5px'}
                    >
                        <FormattedMessage 
                            id={'requestDetailPage.reportsection.report.healthy.description'}
                            defaultMessage='No issues were found. Continue with your good oral hygiene practices.'
                        />
                    </Text>
                </Flex>
            </Box>
        </Box>
      )
}