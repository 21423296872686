import React, {  useEffect } from 'react';
import {  useDispatch} from 'react-redux';
import {  useLocation } from 'react-router-dom';
import styled from 'styled-components';

// components
import { VideoChat } from './VideoChat';
import { LoadingEllipses } from '../_components/LoadingEllipses';


export const StyledEndCallIcon = styled.div`
  background-color: #ffffff;
  border: white 2px solid;
  height: 54px;
  width: 54px;
  border-radius: 54px;
  display:flex;
  justify-content:center;
  align-items:center;
  &:hover{
    cursor:pointer;

    img{
      transform: translateY(-1px);
    }
  }

  img{
    user-select: none;
    height: 40px;
    width: 40px;
  }
`;

export const StyledVideoChatPage = styled.div`
height: 100vh;
display:flex;
width:100%;
justify-content: center;
align-items: center;
background-color: white;
${StyledEndCallIcon}{
  visibility: hidden;
}
`;
const Page = styled.div`
  min-height:100vh;
  color: '#12171a';
  margin-left:-100px;
`;

export const VideoChatPage = ({}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  let params = new URLSearchParams(location.search);

  useEffect(()=>{
    return () => {};
  }, [])

  const onJoined = (status) => {

  }

  const onLeave = (status) => {
    window.close();
  }

  let room = params.get("rm");
  let token = params.get("t")

  return (
    <Page>
        <StyledVideoChatPage>
          {
            ( !room || !token)
                ? <LoadingEllipsis />
                : <VideoChat
                    room={room}
                    token={token}
                    description={params.get("desc")}
                    onJoined={onJoined}
                    onLeave={onLeave}
                  />
          }
          </StyledVideoChatPage>
    </Page>
  )
}
