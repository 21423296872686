import { createSelector } from 'reselect';

export const getClinicsSlice = (state) => state.clinics;

export const getClinicsById = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.byId;
  }
)

export const getRegionList = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.region_list;
  }
)

export const getDentistReviewsLoading = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.reviewsLoading;
  }
)

export const getDentistReviewsError = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.reviewsError;
  }
)

export const getDentistDetailsLoading = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.detailsLoading;
  }
)

export const getDentistDetailsError = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.detailsError;
  }
)

export const getClinicsLoading =  createSelector(
  [getClinicsSlice],
  (state) => {
    return state.loading;
  }
)

export const getMapCenter = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.currentMapCenter;
  }
)

export const getMapBounds = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.currentMapBounds;
  }
)

export const getQueryType = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.queryType;
  }
)

export const getMapBoundsWest = createSelector(
  [getMapBounds],
  (bounds) => {
    return bounds.west;
  }
)

export const getMapBoundsEast = createSelector(
  [getMapBounds],
  (bounds) => {
    return bounds.east;
  }
)

export const getMapBoundsNorth = createSelector(
  [getMapBounds],
  (bounds) => {
    return bounds.north;
  }
)

export const getMapBoundsSouth = createSelector(
  [getMapBounds],
  (bounds) => {
    return bounds.south;
  }
)

export const getCurrentClinicId = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.currentClinic;
  }
)

export const getDragStarted = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.dragStarted;
  }
)

export const getDragMapBounds = createSelector(
  [getClinicsSlice],
  (state) => {
    return state.dragMapBounds;
  }
)

export const getClinics = createSelector(
  [getClinicsById],
  (clinics) => {
    return (clinics)
      ? Object.values(clinics)
          .map( (entry) => {
            return entry;
          })
      : [];
  }
)

export const getCurrentClinic = createSelector(
  [getClinicsById, getCurrentClinicId],
  (clinics, current) => {

    return clinics[current] || {};
  }
)

export const getCurrentClinicReviews = createSelector(
  [getCurrentClinic],
  (clinic) => {
    return clinic.reviews || [];
  }
)

export const getCurrentClinicReviewsIsMore = createSelector(
  [getCurrentClinic],
  (clinic) => {
    return clinic?.reviewsIsMore;
  }
)

