import { useSelector } from 'react-redux';
import analyzingImage from '../_images/analyzingScanLaptop.png';
import { ModalHeader } from '../Modals/ModalHeader';
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getIntlMessages } from '../Authentication/_selectors';
import { CloseIcon } from '../Modals/styles';


const ModalContainer = ({children, closeModal}) => {
    const { formatMessage } = useIntl();
    return (
      <div style={{position: 'relative', maxWidth: '620px'}}>
        <ModalHeader
          title={formatMessage({"id": "reportGenerative.request.confirmed.header", "defaultMessage": "Scan Completed"})} 
        />
          {children}  
      </div>
    )
  }

export const GenerativeReportConfirmation = ({ closeModal }) => {
    const intlMessages = useSelector(getIntlMessages);

    return (
        <ModalContainer closeModal={closeModal}>
            <Box px={'50px'} py={'30px'}>
                <Text
                    color={'#273238'}
                    fontSize={'38px'}
                >
                    <FormattedMessage
                        id={'reportGenerative.request.confirmed.title'}
                        defaultMessage={'Analyzing your Scans...'}
                        />
                </Text>
                <Text color={'#4D5666'}>
                    <FormattedMessage 
                        id={'reportDetail.scoreCard.score.description.progress'}
                        defaultMessage={'Processing in progress... Your detailed analysis will be ready for viewing soon.'}
                    />
                </Text>
                <Flex px={'140px'} mt={'20px'}>
                    <Image src={analyzingImage} />
                </Flex>
                <Flex w={'full'} mt={'30px'} justify={'center'}>
                    <Button w={'full'} fleGrow={1} onClick={closeModal}>
                        <FormattedMessage 
                            id={'tidbits.close'}
                            defaultMessage={'Close'}
                        />
                    </Button>
                </Flex>
            </Box>
        </ModalContainer>
    )
}