import { constants } from './_constants';

export const actions = {
  openModal,
  openNotifyModal,
  openLoadingModal,
  closeModal,
  openErrorModal,
  openMessageModal,
  openHowToModal,
  openConfirmDialog,
  changeProfileListModal
}

function openModal(type, data={}){
  return (dispatch) => {
    dispatch(open(type, data));
  }
  function open(type, data){
    return { type: constants.UPDATE, data: { ...data, [type] : true } }
  }
}

function openNotifyModal(type){
  return (dispatch) => {
    let doNotShow = localStorage.getItem(`doNotShow__${type}`);
    if (!doNotShow){
      dispatch(open(type));
    } else {
      if ((Date.now() - Number(doNotShow)) > (1000 * 60 * 60 * 24 * 7)){
        dispatch(open(type));
      }
    }
  }
  function open(type){
    return { type: constants.UPDATE, data: { [type] : true } }
  }
}

function openLoadingModal(text){
  return (dispatch) => {
    dispatch(open(text));
  }
  function open(text){
    return { type: constants.UPDATE, data: { loading : true, loadingText: text, error:false } }
  }
}

function openMessageModal(text='general', callback=null){
  return (dispatch) => {
    dispatch(open(text));
  }
  function open(text){
    return { type: constants.UPDATE, data: { message: true, messageText: text, loading:false}}
  }
}

function openHowToModal(content){
  return (dispatch) => {
    content && dispatch(open(content));
  }
  function open(content){
    return { type: constants.UPDATE, data: { howToContent: content, howTo:true }}
  }
}

function changeProfileListModal(page){
  return (dispatch) => {
    dispatch(set(page));
  }
  function set(content){
    return { type: constants.UPDATE, data: { profileListPage: page }}
  }
}

function openConfirmDialog(header, text, negCallbacks, posCallbacks, posLabel='OKAY'){
  return (dispatch) => {
    dispatch(open(header, text, negCallbacks, posCallbacks, posLabel))
  }
  function open(confirmDialogHeader, confirmDialogText, confirmDialogNegativeCallback, confirmDialogPositiveCallback, confirmDialogPositiveLabel){
    let confirmDialog = true;
    return { type: constants.UPDATE, data: { confirmDialogHeader, confirmDialogText, confirmDialogNegativeCallback, confirmDialogPositiveCallback, confirmDialogPositiveLabel, confirmDialog}}
  }
}

function openErrorModal(text='general'){
  return (dispatch) => {
    dispatch(open(text));
  }
  function open(text){
    return { type: constants.UPDATE, data: { error: true, errorText: text, loading:false}}
  }
}

function closeModal(type, callbacks=[]){
  return (dispatch) => {
    dispatch(open(type));
    callbacks && callbacks.map(fn => fn());
  }
  function open(type){
    return { type: constants.UPDATE, data: { [type] : false, loadingText: 'loading' } }
  }
}
