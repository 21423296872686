import React, {useEffect, useMemo, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toUpper, upperFirst, debounce } from 'lodash';
import cn from 'classnames';

import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

import { formatName } from '../_helpers';
import { localeFormatDate } from '../_helpers/date';

// components 

import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { ProfileForm } from './ProfileForm';
import { FaPlus } from 'react-icons/fa';

// constants
import { constants as authConstants } from '../Authentication/_constants';
import { constants as reportConstants } from '../Reports/_constants';

// actions
import { actions as authActions } from '../Authentication/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { actions as reportActions } from '../Reports/_actions';

// components
import { getIntlLocale, getIntlMessages, getIsProfilesLoading, getProfiles } from '../Authentication/_selectors';
import { getProfileListModalPage } from '../Modals/_selectors';

// styles
import { CloseIcon } from '../Modals/styles';
import storage from '../_helpers/storage';
import { ArrowRightIcon } from '../_images/icons/ArrowRightIcon';
import { ChevronRightIcon } from '../_images/icons/ChevronRightIcon';
import { SectionTitle } from './styles';

export const ProfileItem = ({profile, onSelected, isSelected}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale)
  
  const name = profile.first_name //formatName(intlMessages['format.fullName'], profile.first_name, profile.last_name);
  //const subLabel = `${intlMessages[`profiles.relationship.${profile.relationship}`]}, ${localeFormatDate(profile.date_of_birth, intlLocale, "l")}`
  const subLabel = `${intlMessages[`profiles.relationship.${profile.relationship}`]}`
  const onSelect = () => {
    onSelected(profile);
  }

  return (
    <Flex
      direction={["column"]}
      bg={isSelected ? '#273238': '#ffffff'}
      borderRadius={'10px'}
      color={isSelected ? '#ffffff': '#000'}
      py={'18px'}
      mb={'10px'}
      align={["center"]}
      justify={["center"]}
      _hover={{cursor: 'pointer'}}
      onClick={onSelect}
    >
      <Text fontSize={'20px'}>
        {name}
      </Text>
      <Text fontSize={'13px'} color={isSelected ? '#6B8A99' : '#405159' }>
      {subLabel}
      </Text>
    </Flex>
  )
}

export const SettingsProfileItem  = ({profile, onSelected, isSelected}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale)
  
  const name = profile.first_name //formatName(intlMessages['format.fullName'], profile.first_name, profile.last_name);

  const onSelect = () => {
    onSelected(profile);
  }

  //const subText = `${intlMessages[`profiles.relationship.${profile.relationship}`]}, ${localeFormatDate(profile.date_of_birth, intlLocale, "l")}`
  const subText = `${intlMessages[`profiles.relationship.${profile.relationship}`]}`

  return (
    <Flex
      bg={'#ffffff'}
      px={'20px'}
      py={'16px'}
      mr={'10px'}
      justify={["space-between"]}
      _hover={{cursor: 'pointer', backgroundColor: '#F9F9F9'}}
      onClick={onSelect}
      borderBottom={'1px solid #D8D8D8'}
      w={['full']}
    >
      <Flex
        direction={['column']}
        justify={["center"]}
      >
        <Text fontSize={'20px'} 
        color={'#000'}>
          {name}
        </Text>
        <Text fontSize={'13px'} color={isSelected ? '#6B8A99' : '#405159' }>
        {subText}
        </Text>
      </Flex>
      <Flex align={['center']}>
        <ChevronRightIcon />
      </Flex>
    </Flex>
  )
}


export const SearchInput = ({size='md', bg='#ffffff'}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const latestQuery = useRef('');

  const doSearch = query => {
    if (query.trim().length < 2 && query.trim().length > 0){
      return
    }
    if (query.trim() !== latestQuery.current){
      latestQuery.current = query.trim();
      dispatch(authActions.getProfiles(query));
    }
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(doSearch, 400)
  , []);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);

  let iconSizeClass = "w-4 h-4"
  let inputClass = 'pl-8 pr-3 text-[16px] bg-[${bg}]'

  if (size != 'md'){
    iconSizeClass = "w-4 h-4"
    inputClass = 'pl-8 pr-2 text-[12px] bg-[${bg}]'
  }

  return (
    <div class="relative w-full text-gray-600 focus-within:text-gray-400">
      <span class="absolute inset-y-0 left-0 flex items-center pl-2">
        <button type="submit" class="p-1 hover:cursor-default focus:outline-none focus:shadow-outline">
          <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
            viewBox="0 0 24 24" class={iconSizeClass}>
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </button>
      </span>
      <input type="search" name="q" 
        class={cn(inputClass, 'border w-full text-black rounded-full border-[#C7D3D9] py-2 px-6 focus:outline-none')}
        placeholder={intlMessages['profiles.search.placeholder']}
        autoComplete="off" 
        onChange={e => debouncedChangeHandler(e.target.value)}
      />
    </div>
  )
}

export const Skeleton = () => {
  return (
      <div className="w-fit animate-pulse flex-row items-center ">
          <div className={`h-[88px] w-[404px] mb-[20px] rounded-[4px] bg-[#b8babd] `}></div>
      </div>
  );
};

const ProfilesResults = ({isLoading, profiles, onSelected, currentProfile}) => {
  const intlMessages = useSelector(getIntlMessages);
  
  if (isLoading){
    return (
      <>
        {
          [0,1,2,3].map(index => <Skeleton />)
        }
      </>
    )
  }
  if ((profiles || []).length == 0){
    return (
      <>
        {upperFirst(intlMessages['profiles.search.notFound'])}
      </>
    )
  } 
  return (
    <>
      {
        profiles.map((elem) => 
            <ProfileItem key={`${elem.id}`} onSelected={onSelected} isSelected={currentProfile?.id==elem.id} profile={elem} />
        )
      }
    </>
  )
}

export const Profiles = ({addProfile, onSelected, profiles, currentProfile, isTitleShowing=true}) => {
  const intlMessages = useSelector(getIntlMessages);
  const isLoading = useSelector(getIsProfilesLoading);

  return (
    <motion.div 
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ bounce: 0}}
    >
      {isTitleShowing && <SectionTitle >{upperFirst(intlMessages['profiles.list.header'])}</SectionTitle>}
      <div class="flex justify-between items-center py-6 ">
        <SearchInput />
      </div>
      <Box
        h={'350px'}
        overflowY={'overlay'}
        pt={[2]}
      >
        <ProfilesResults 
          isLoading={isLoading} 
          onSelected={onSelected} 
          profiles={profiles}  
          currentProfile={currentProfile}
        />
      </Box>
      <Flex
        w={['full']}
        px={[5]}
        justify={['center']}
        bg={['#F7F7F7']}
      >
        <Flex
          color={'#405159'}
          bg={'transparent'}
          py={'15px'}
          align={'center'}
          justify={'center'}
          _hover={{
            cursor: 'pointer'
          }}
          onClick={() => addProfile()}
        >
          <FaPlus color="#405159" size="10px"/>
          <Text ml={['6px']}>
            {toUpper(intlMessages['profiles.addmember'])}
          </Text>
        </Flex>
      </Flex>
    </motion.div>
  )
}

export const ProfileList = ({closeModal, onSelect}) => {
  const [currentProfile, setCurrentProfile] = React.useState(storage.getScanProfile() || null);
  const dispatch = useDispatch();
  const profiles = useSelector(getProfiles);
  const intlMessages = useSelector(getIntlMessages);
  const page = useSelector(getProfileListModalPage);

  useEffect(()=>{
    dispatch(authActions.getProfiles());
    dispatch({type: authConstants.SELECT_PROFILE, data: null});

  }, [])

  const onProfileClick = (profile) => {
    setCurrentProfile(profile);
  }

  const onProfileSelected = () => {
    dispatch({type: authConstants.SELECT_PROFILE, data: currentProfile});
    onSelect && onSelect(currentProfile);
    storage.setScanProfile(currentProfile);
    closeModal();
  }

  const addProfile = () => {
    dispatch(modalActions.changeProfileListModal(1))
  }

  const onCancel = () => {
    dispatch(modalActions.changeProfileListModal(0));
  }

  const onSuccess = (profile) => {
    dispatch({type: authConstants.SELECT_PROFILE, data: profile});
    closeModal();
    dispatch(modalActions.changeProfileListModal(0));
  }

  return (
    <Box
      position={'relative'}
      w={['570px']}
      py={[12]}
      px={[12]}
      bg={['#F7F7F7']}
      overflowX={['hidden']}
    >
        { page == 0 && 
          <>
            <Profiles 
              key={`ProfileList_Results`} 
              addProfile={addProfile} 
              onSelected={(prof) => onProfileClick(prof)} 
              profiles={profiles} 
              currentProfile={currentProfile}
            />
            <Flex w={["full"]} justify={'center'}>
              <Button w={['full']} onClick={onProfileSelected} isDisabled={currentProfile?.id == null} py={['12px']} maxH={['unset']} h={['40px']}>
                  {intlMessages['tidbits.next']}
              </Button>
            </Flex>
          </> 
        }
        { page == 1 && 
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ bounce: 0}}
          >
            <Flex w={'full'} direction={['column']} px={[3]}>
              <SectionTitle>{upperFirst(intlMessages['profiles.form.header'])}</SectionTitle>
              <ProfileForm 
                key={`ProfileList_Form`} 
                onCancel={onCancel} 
                onSuccess={onSuccess} 
              />
            </Flex>
          </motion.div>
        }
    </Box>
  )  
}


export const DashboardProfileList = ({closeModal, onSelect}) => {
  const [currentProfile, setCurrentProfile] = React.useState(storage.getScanProfile() || null);
  const dispatch = useDispatch();
  const profiles = useSelector(getProfiles);
  const intlMessages = useSelector(getIntlMessages);
  const page = useSelector(getProfileListModalPage);

  useEffect(()=>{
    dispatch(authActions.getProfiles());
    //dispatch({type: authConstants.SELECT_PROFILE, data: null});
  }, [])

  const onProfileClick = (profile) => {
    setCurrentProfile(profile);
  }

  const onProfileSelected = () => {
    dispatch({type: authConstants.SELECT_PROFILE, data: currentProfile});
    onSelect && onSelect(currentProfile);
    storage.setScanProfile(currentProfile);
    const newValue = currentProfile?.id;
    // set requestFilter (profile.id) on change and fetch requests for that user
    dispatch({type: reportConstants.UPDATE, data: {requestFilter: newValue}});
    // set profile value if newValue is not null (All)
    if (newValue){
      dispatch(reportActions.fetchRequestList({profile: newValue}));
      dispatch(authActions.selectProfile(currentProfile))
    } else {
      dispatch(authActions.selectProfile(currentProfile))
      //dispatch(actions.fetchRequestList());
    }
    closeModal();
  }

  const addProfile = () => {
    dispatch(modalActions.changeProfileListModal(1))
  }

  const onCancel = () => {
    dispatch(modalActions.changeProfileListModal(0));
  }

  const onSuccess = (profile) => {
    dispatch({type: authConstants.SELECT_PROFILE, data: profile});
    closeModal();
    dispatch(modalActions.changeProfileListModal(0));
  }

  return (
    <Box
      position={'relative'}
      w={['620px']}
      pb={[12]}
      px={[12]}
      bg={['#F7F7F7']}
      overflowX={['hidden']}
    >
        { page == 0 && 
          <>
            <Profiles 
              key={`ProfileList_Results`} 
              addProfile={addProfile} 
              onSelected={(prof) => onProfileClick(prof)} 
              profiles={profiles} 
              currentProfile={currentProfile}
              isTitleShowing={false}
            />
            <Flex w={["full"]} justify={'center'}>
              <Button w={['full']} onClick={onProfileSelected} isDisabled={currentProfile?.id == null} py={['12px']} maxH={['unset']} h={['40px']}>
                  {intlMessages['dashboard.profiles.selector.button.label']}
              </Button>
            </Flex>
          </> 
        }
        { page == 1 && 
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ bounce: 0}}
          >
            <Flex w={'full'} direction={['column']} px={[3]}>
              <ProfileForm 
                key={`ProfileList_Form`} 
                onCancel={onCancel} 
                onSuccess={onSuccess} 
              />
            </Flex>
          </motion.div>
        }
    </Box>
  )  
}