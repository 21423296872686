import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { TOOTH_NUMBERING_SYSTEM } from '../_config';

const StyledToothSetPdf = styled.div`
font-family:'Proxima Nova';

#toothregions{
    font-family:'Proxima Nova';
    transform-origin: center;
    ${props => props.isFlipped ? 'transform: scale(-1,1);' : ''}
}

#toothnumbers {
    font-family:'Proxima Nova';
    ${props => props.isFlipped ? 'display:none;' : ''}
}

#toothnumbers_reversed {
    font-family:'Proxima Nova';
    ${props => props.isFlipped ? '' : 'display:none;'}
}

.toothnumber{
    font-family:'Proxima Nova';
    white-space: pre;
    fill:#273238;

    > tspan{
        font-size: 10px;
        fill:#273238;
        font-family:'Proxima Nova';
    }

    > text{
        font-size: 10px;
        fill:#273238;
        font-family:'Proxima Nova';
    }

    &.ALL {
        fill:#fff;
        text {
            fill:#fff;
        }
    }
    &.GENERAL {
        fill:#fff;
        text {
            fill:#fff;
        }
    }
    &.OTHERS {
        fill:#fff;
        text {
            fill:#fff;
        }
    }
    &.CARIES {
        fill:#fff;
        text {
            fill:#fff;
        }
    }
    &.ORTHO{
        fill:#fff;
        text {
            fill:#fff;
        }
    }
    &.GUMS{
        fill:#fff;
        text {
            fill:#fff;
        }
    }
    &.HABITS{
        fill:#fff;
        text {
            fill:#fff;
        }
    }
    &.CALCULUS{
        fill:#fff;
        text {
            fill:#fff;
        }
    }
    &.COLOR { 
        fill:#fff;
        text {
            fill:#fff;
        }
    }
        &.MULTIPLE { 
           fill:#fff;
           text {
               fill:#fff;
           }
       }
}

text {
    font-family:'Proxima Nova';
}

#toothregions .toothregion{
    font-family:'Proxima Nova';
    fill:#F2F5F7;
    &.ALL {
        fill:#000000;}
    &.GENERAL {
        fill:#A9A8A8;}
    &.OTHERS {
        fill:#A9A8A8;}
    &.CARIES {
        fill:#DC4E41;}
    &.ORTHO{
        fill:#00574F;}
    &.GUMS{
        fill:#FFA500;}
    &.HABITS{
        fill:#DAFF00;}
    &.CALCULUS{
        fill:#FFA500;}
    &.COLOR { 
        fill:#367FFF;}
     &.MULTIPLE { 
        fill:#405159;}
}

#tongue{
    fill:#F2F5F7;
}
`;


const ToothSetPdf = ({toothClasses={}, width=192, height=362, numberingSystem="palmer", isFlipped=true}) => (
  <StyledToothSetPdf isFlipped={isFlipped}>
<svg width={width} height={height} viewBox="0 0 192 362" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group 1261158647">
<g id="toothregions">
<path id="t43" className={cn("toothregion", ...(toothClasses["t43"] || []))} fillRule="evenodd" clipRule="evenodd" d="M60.3612 328.739C59.7021 325.973 58.3896 323.503 56.3382 321.493C54.5799 319.772 52.4708 319.412 50.2965 320.557C47.977 321.78 45.8766 323.248 44.0347 324.994C42.3734 326.569 40.9196 328.369 39.7292 330.445C39.4563 330.921 39.2228 331.424 39.0181 331.932C37.1954 336.481 38.9365 339.138 43.8627 339.327C44.4104 339.348 44.96 339.33 45.5086 339.33C48.2316 339.109 50.9671 338.924 53.5691 338.158C54.6712 337.835 55.7511 337.417 56.7917 336.832C59.9394 335.06 61.2029 332.273 60.3612 328.739Z" />
<path id="t48" className={cn("toothregion", ...(toothClasses["t48"] || []))} fillRule="evenodd" clipRule="evenodd" d="M23.2393 201.308C20.472 200.501 17.6462 199.897 14.9328 200.015C14.6436 200.027 14.3534 200.041 14.0661 200.07C10.83 199.905 7.92542 200.245 5.14764 201.357C1.88367 202.662 0.29445 205.009 0.236799 208.497C0.212778 209.957 0.242564 211.412 0.253133 212.87C0.265624 214.633 0.248329 216.4 0.0580835 218.183C-0.487672 223.299 2.84355 226.49 8.74309 226.724C10.3141 226.786 11.8908 226.79 13.4675 226.768C15.9273 226.731 18.388 226.614 20.8343 226.42C25.3051 226.068 28.1232 222.383 27.4765 217.772C27.3132 216.606 27.3776 215.543 27.6082 214.51C27.7754 213.761 28.0271 213.027 28.3548 212.284C30.9039 206.513 29.3396 203.088 23.2393 201.308Z" />
<path id="t44" className={cn("toothregion", ...(toothClasses["t44"] || []))} fillRule="evenodd" clipRule="evenodd" d="M47.8009 308.587C47.5377 308.023 47.2513 307.465 46.917 306.922C44.6599 303.263 40.5946 301.478 36.366 303.558C35.4282 304.019 34.5 304.485 33.5949 304.983C31.8135 305.961 30.1215 307.07 28.6351 308.566C26.1043 311.116 26.1081 316.393 28.393 319.813C29.2423 321.084 30.3963 322.107 31.8674 322.628C33.8592 323.331 35.8731 323.855 37.7688 323.731C39.6943 323.77 41.4623 323.511 43.1649 323.093C43.9989 322.888 44.8185 322.649 45.6294 322.382C48.0604 321.582 49.811 319.131 49.6496 316.475C49.4824 313.738 48.9597 311.066 47.8009 308.587Z" />
<path id="t45" className={cn("toothregion", ...(toothClasses["t45"] || []))} fillRule="evenodd" clipRule="evenodd" d="M41.5677 295.128C41.3804 293.02 41.0546 290.939 40.4147 288.984C39.6355 286.603 38.3883 284.41 36.3379 282.588C34.3787 280.848 32.0016 279.954 29.416 280.683C27.796 281.14 26.2529 281.746 24.7809 282.486C22.596 283.586 20.5715 284.99 18.7152 286.716C15.653 289.563 15.5492 294.554 17.3556 298.263C18.3424 300.289 19.8912 301.938 21.859 302.634C24.0536 303.41 26.2702 303.837 28.7127 303.941C30.2193 303.792 31.7826 303.676 33.343 303.422C34.3153 303.265 35.2858 303.054 36.2408 302.748C40.0525 301.525 41.9213 299.102 41.5677 295.128Z" />
<path id="t47" className={cn("toothregion", ...(toothClasses["t47"] || []))} fillRule="evenodd" clipRule="evenodd" d="M29.6832 245.801C29.1375 244.263 28.8723 242.764 28.8118 241.256C28.7541 239.856 28.8732 238.447 29.1202 236.992C29.7947 233.02 27.7433 229.79 23.8519 228.753C20.4947 227.859 17.1597 227.576 13.8717 227.891C10.92 228.174 8.00577 228.937 5.15112 230.229C1.83912 231.73 0.506436 234.525 0.979168 238.208C1.13963 239.455 1.42115 240.662 1.80453 241.855C2.08317 242.723 2.32146 243.595 2.51459 244.476C2.74423 245.527 2.90853 246.594 2.96426 247.701C3.25443 253.404 6.8422 256.628 13.6545 256.531C14.8969 256.298 16.3218 256.064 17.8419 255.801C20.1517 255.402 22.6903 254.937 25.1904 254.306C28.9184 253.363 30.9871 249.473 29.6832 245.801Z" />
<path id="t46" className={cn("toothregion", ...(toothClasses["t46"] || []))} fillRule="evenodd" clipRule="evenodd" d="M33.952 272.144C33.2774 270.383 32.6942 268.605 32.2273 266.791C31.89 265.48 31.6046 264.152 31.4259 262.785C30.7687 257.738 26.6842 254.871 21.7243 256.026C20.6127 256.285 19.5106 256.596 18.4133 256.927C16.459 257.517 14.5257 258.194 12.6242 258.923C9.67928 260.05 7.92383 263.616 8.52916 266.947C8.83374 268.627 9.28822 270.271 9.81476 271.893C10.1828 273.025 10.5815 274.148 11.0091 275.257C11.9219 277.62 13.9204 278.878 16.2466 279.586C18.5459 280.288 20.9028 280.683 23.0926 280.486C23.5653 280.495 24.0217 280.482 24.48 280.469C26.5737 280.416 28.5213 280.151 30.4045 279.548C34.2095 278.33 35.3798 275.874 33.952 272.144Z" />
<path id="t41" className={cn("toothregion", ...(toothClasses["t41"] || []))} fillRule="evenodd" clipRule="evenodd" d="M94.9527 355.304C94.8441 354.617 94.7365 353.901 94.6145 353.171C94.0447 349.785 93.1357 346.058 90.185 343.063C88.7063 341.561 86.9652 341.187 84.9763 341.578C81.5288 342.254 78.8029 344.285 76.0866 346.333C74.8597 347.259 73.7739 348.323 72.7573 349.457C72.2798 349.99 71.8119 350.535 71.3622 351.096C69.1773 353.821 69.3723 355.4 72.1299 357.453C73.4876 358.465 75.0566 359.006 76.6487 359.357C79.8329 360.061 83.0604 361.051 86.2629 361.024C88.6256 361.005 91.2515 360.839 93.5172 359.618C94.7298 358.965 94.9959 358.302 94.9527 355.304Z" />
<path id="t42" className={cn("toothregion", ...(toothClasses["t42"] || []))} fillRule="evenodd" clipRule="evenodd" d="M71.5491 339.605C70.866 336.439 69.2969 334.619 65.0202 334.986C61.0923 335.322 57.8745 336.996 55.0525 339.336C53.7227 340.439 52.4803 341.688 51.2937 343.018C50.1551 344.294 50.3924 345.768 51.8318 347.291C54.0801 349.671 56.9338 351.095 59.923 352.271C62.3039 353.209 64.7166 353.926 67.3454 353.573C68.3563 353.437 69.0356 352.989 69.4343 352.117C69.9829 350.919 70.4288 349.694 70.7862 348.437C71.3858 346.321 71.7298 344.109 71.8672 341.76C71.7836 341.183 71.7173 340.383 71.5491 339.605Z" />
<path id="t33" className={cn("toothregion", ...(toothClasses["t33"] || []))} fillRule="evenodd" clipRule="evenodd" d="M131.629 328.739C132.288 325.973 133.601 323.503 135.652 321.493C137.411 319.772 139.52 319.412 141.694 320.557C144.013 321.78 146.114 323.248 147.956 324.994C149.617 326.569 151.071 328.369 152.261 330.445C152.534 330.921 152.768 331.424 152.972 331.932C154.795 336.481 153.054 339.138 148.128 339.327C147.58 339.348 147.03 339.33 146.482 339.33C143.759 339.109 141.023 338.924 138.421 338.158C137.319 337.835 136.239 337.417 135.199 336.832C132.051 335.06 130.787 332.273 131.629 328.739Z" />
<path id="t38" className={cn("toothregion", ...(toothClasses["t38"] || []))} fillRule="evenodd" clipRule="evenodd" d="M168.761 201.308C171.528 200.501 174.354 199.897 177.067 200.015C177.356 200.027 177.646 200.041 177.934 200.07C181.17 199.905 184.074 200.245 186.852 201.357C190.116 202.662 191.705 205.009 191.763 208.497C191.787 209.957 191.757 211.412 191.747 212.87C191.734 214.633 191.752 216.4 191.942 218.183C192.488 223.299 189.156 226.49 183.257 226.724C181.686 226.786 180.109 226.79 178.532 226.768C176.073 226.731 173.612 226.614 171.166 226.42C166.695 226.068 163.877 222.383 164.523 217.772C164.687 216.606 164.622 215.543 164.392 214.51C164.225 213.761 163.973 213.027 163.645 212.284C161.096 206.513 162.66 203.088 168.761 201.308Z" />
<path id="t34" className={cn("toothregion", ...(toothClasses["t34"] || []))} fillRule="evenodd" clipRule="evenodd" d="M144.191 308.587C144.454 308.023 144.741 307.465 145.075 306.922C147.332 303.263 151.397 301.478 155.626 303.558C156.564 304.019 157.492 304.485 158.397 304.983C160.178 305.961 161.87 307.07 163.357 308.566C165.888 311.116 165.884 316.393 163.599 319.813C162.75 321.084 161.596 322.107 160.125 322.628C158.133 323.331 156.119 323.855 154.223 323.731C152.298 323.77 150.53 323.511 148.827 323.093C147.993 322.888 147.173 322.649 146.362 322.382C143.932 321.582 142.181 319.131 142.342 316.475C142.51 313.738 143.032 311.066 144.191 308.587Z" />
<path id="t35" className={cn("toothregion", ...(toothClasses["t35"] || []))} fillRule="evenodd" clipRule="evenodd" d="M150.429 295.128C150.616 293.02 150.942 290.939 151.582 288.984C152.361 286.603 153.608 284.41 155.659 282.588C157.618 280.848 159.995 279.954 162.581 280.683C164.201 281.14 165.744 281.746 167.216 282.486C169.401 283.586 171.425 284.99 173.282 286.716C176.344 289.563 176.448 294.554 174.641 298.263C173.654 300.289 172.106 301.938 170.138 302.634C167.943 303.41 165.727 303.837 163.284 303.941C161.778 303.792 160.214 303.676 158.654 303.422C157.681 303.265 156.711 303.054 155.756 302.748C151.944 301.525 150.075 299.102 150.429 295.128Z" />
<path id="t36" className={cn("toothregion", ...(toothClasses["t36"] || []))} fillRule="evenodd" clipRule="evenodd" d="M158.037 272.143C158.711 270.382 159.295 268.604 159.762 266.79C160.099 265.479 160.384 264.151 160.563 262.784C161.22 257.737 165.305 254.87 170.265 256.025C171.376 256.284 172.478 256.595 173.576 256.926C175.53 257.516 177.463 258.193 179.365 258.922C182.31 260.049 184.065 263.615 183.46 266.946C183.155 268.626 182.701 270.27 182.174 271.892C181.806 273.024 181.407 274.148 180.98 275.256C180.067 277.619 178.068 278.877 175.742 279.585C173.443 280.287 171.086 280.682 168.896 280.485C168.424 280.494 167.967 280.481 167.509 280.468C165.415 280.415 163.468 280.15 161.584 279.547C157.779 278.329 156.609 275.874 158.037 272.143Z" />
<path id="t37" className={cn("toothregion", ...(toothClasses["t37"] || []))} fillRule="evenodd" clipRule="evenodd" d="M162.313 245.801C162.859 244.263 163.124 242.764 163.185 241.256C163.242 239.856 163.123 238.447 162.876 236.992C162.202 233.02 164.253 229.79 168.145 228.753C171.502 227.859 174.837 227.576 178.125 227.891C181.077 228.174 183.991 228.937 186.845 230.229C190.157 231.73 191.49 234.525 191.017 238.208C190.857 239.455 190.575 240.662 190.192 241.855C189.913 242.723 189.675 243.595 189.482 244.476C189.252 245.527 189.088 246.594 189.032 247.701C188.742 253.404 185.154 256.628 178.342 256.531C177.1 256.298 175.675 256.064 174.155 255.801C171.845 255.402 169.306 254.937 166.806 254.306C163.078 253.363 161.009 249.473 162.313 245.801Z" />
<path id="t31" className={cn("toothregion", ...(toothClasses["t31"] || []))} fillRule="evenodd" clipRule="evenodd" d="M97.0512 355.304C97.1598 354.617 97.2674 353.901 97.3894 353.171C97.9592 349.785 98.8682 346.058 101.819 343.063C103.298 341.561 105.039 341.187 107.028 341.578C110.475 342.254 113.201 344.285 115.917 346.333C117.144 347.259 118.23 348.323 119.247 349.457C119.724 349.99 120.192 350.535 120.642 351.096C122.827 353.821 122.632 355.4 119.874 357.453C118.516 358.465 116.947 359.006 115.355 359.357C112.171 360.061 108.943 361.051 105.741 361.024C103.378 361.005 100.752 360.839 98.4867 359.618C97.2741 358.965 97.008 358.302 97.0512 355.304Z" />
<path id="t32" className={cn("toothregion", ...(toothClasses["t32"] || []))} fillRule="evenodd" clipRule="evenodd" d="M120.439 339.605C121.122 336.439 122.691 334.619 126.968 334.986C130.896 335.322 134.114 336.996 136.936 339.336C138.266 340.439 139.508 341.688 140.695 343.018C141.833 344.294 141.596 345.768 140.156 347.291C137.908 349.671 135.054 351.095 132.065 352.271C129.684 353.209 127.272 353.926 124.643 353.573C123.632 353.437 122.953 352.989 122.554 352.117C122.005 350.919 121.559 349.694 121.202 348.437C120.602 346.321 120.258 344.109 120.121 341.76C120.205 341.183 120.271 340.383 120.439 339.605Z" />
<path id="t13" className={cn("toothregion", ...(toothClasses["t13"] || []))} fillRule="evenodd" clipRule="evenodd" d="M60.3612 32.2837C59.7021 35.0493 58.3896 37.5194 56.3382 39.5294C54.5799 41.2506 52.4708 41.6106 50.2965 40.4651C47.977 39.2425 45.8766 37.7745 44.0347 36.0283C42.3734 34.4535 40.9196 32.6534 39.7292 30.577C39.4563 30.1014 39.2228 29.5989 39.0181 29.0907C37.1954 24.5413 38.9365 21.8844 43.8627 21.6958C44.4104 21.6746 44.96 21.6929 45.5086 21.6929C48.2316 21.9133 50.9671 22.0981 53.5691 22.8644C54.6712 23.1878 55.7511 23.6056 56.7917 24.1909C59.9394 25.9621 61.2029 28.7499 60.3612 32.2837Z" />
<path id="Mask" fillRule="evenodd" clipRule="evenodd" d="M23.2393 159.715C20.472 160.523 17.6462 161.126 14.9328 161.009C14.6436 160.996 14.3534 160.983 14.0661 160.953C10.83 161.119 7.92542 160.779 5.14764 159.667C1.88367 158.362 0.29445 156.015 0.236799 152.526C0.212778 151.067 0.242564 149.611 0.253133 148.154C0.265624 146.39 0.248329 144.624 0.0580835 142.84C-0.487672 137.725 2.84355 134.534 8.74309 134.3C10.3141 134.237 11.8908 134.233 13.4675 134.255C15.9273 134.292 18.388 134.409 20.8343 134.603C25.3051 134.955 28.1232 138.64 27.4765 143.251C27.3132 144.417 27.3776 145.481 27.6082 146.514C27.7754 147.262 28.0271 147.996 28.3548 148.739C30.9039 154.51 29.3396 157.935 23.2393 159.715Z" />
<path id="t18" className={cn("toothregion", ...(toothClasses["t18"] || []))} fillRule="evenodd" clipRule="evenodd" d="M23.2393 159.715C20.472 160.523 17.6462 161.126 14.9328 161.009C14.6436 160.996 14.3534 160.983 14.0661 160.953C10.83 161.119 7.92542 160.779 5.14764 159.667C1.88367 158.362 0.29445 156.015 0.236799 152.526C0.212778 151.067 0.242564 149.611 0.253133 148.154C0.265624 146.39 0.248329 144.624 0.0580835 142.84C-0.487672 137.725 2.84355 134.534 8.74309 134.3C10.3141 134.237 11.8908 134.233 13.4675 134.255C15.9273 134.292 18.388 134.409 20.8343 134.603C25.3051 134.955 28.1232 138.64 27.4765 143.251C27.3132 144.417 27.3776 145.481 27.6082 146.514C27.7754 147.262 28.0271 147.996 28.3548 148.739C30.9039 154.51 29.3396 157.935 23.2393 159.715Z" />
<path id="t14" className={cn("toothregion", ...(toothClasses["t14"] || []))} fillRule="evenodd" clipRule="evenodd" d="M47.8009 52.4388C47.5377 53.0029 47.2513 53.5602 46.917 54.1032C44.6599 57.7621 40.5946 59.5478 36.366 57.4675C35.4282 57.0064 34.5 56.5405 33.5949 56.0429C31.8135 55.0648 30.1215 53.9559 28.6351 52.459C26.1043 49.909 26.1081 44.6328 28.393 41.2126C29.2423 39.941 30.3963 38.9187 31.8674 38.3979C33.8592 37.6942 35.8731 37.1705 37.7688 37.2947C39.6943 37.2552 41.4623 37.5142 43.1649 37.932C43.9989 38.137 44.8185 38.3767 45.6294 38.6434C48.0604 39.4433 49.811 41.8942 49.6496 44.55C49.4824 47.2878 48.9597 49.959 47.8009 52.4388Z" />
<path id="t15" className={cn("toothregion", ...(toothClasses["t15"] || []))} fillRule="evenodd" clipRule="evenodd" d="M41.5677 65.894C41.3804 68.0022 41.0546 70.0834 40.4147 72.0385C39.6355 74.4191 38.3883 76.6129 36.3379 78.4342C34.3787 80.1746 32.0016 81.0689 29.416 80.3392C27.796 79.882 26.2529 79.2765 24.7809 78.5362C22.596 77.4369 20.5715 76.0324 18.7152 74.3064C15.653 71.459 15.5492 66.4687 17.3556 62.7597C18.3424 60.7334 19.8912 59.0844 21.859 58.3884C24.0536 57.6125 26.2702 57.1851 28.7127 57.0812C30.2193 57.2304 31.7826 57.3469 33.343 57.6C34.3153 57.7579 35.2858 57.9687 36.2408 58.2748C40.0525 59.4974 41.9213 61.9203 41.5677 65.894Z" />
<path id="t16" className={cn("toothregion", ...(toothClasses["t16"] || []))} fillRule="evenodd" clipRule="evenodd" d="M33.9598 88.8801C33.2853 90.6417 32.702 92.4197 32.2351 94.2333C31.8978 95.5444 31.6124 96.8728 31.4337 98.2397C30.7765 103.287 26.692 106.154 21.7322 104.998C20.6205 104.739 19.5184 104.428 18.4211 104.097C16.4668 103.507 14.5336 102.831 12.6321 102.102C9.68709 100.975 7.93164 97.408 8.53697 94.0773C8.84156 92.3976 9.29603 90.7534 9.82257 89.1314C10.1906 87.9993 10.5893 86.8759 11.0169 85.767C11.9297 83.4047 13.9282 82.1465 16.2544 81.438C18.5537 80.7363 20.9106 80.3416 23.1004 80.538C23.5731 80.5293 24.0295 80.5428 24.4878 80.5553C26.5815 80.6083 28.5291 80.873 30.4124 81.4765C34.2173 82.6943 35.3876 85.1499 33.9598 88.8801Z" />
<path id="Mask_2" fillRule="evenodd" clipRule="evenodd" d="M29.6832 115.225C29.1375 116.764 28.8723 118.263 28.8118 119.77C28.7541 121.171 28.8732 122.579 29.1202 124.034C29.7947 128.006 27.7433 131.237 23.8519 132.274C20.4947 133.168 17.1597 133.451 13.8717 133.135C10.92 132.852 8.00577 132.09 5.15112 130.797C1.83912 129.296 0.506436 126.502 0.979168 122.819C1.13963 121.571 1.42115 120.364 1.80453 119.171C2.08317 118.303 2.32146 117.432 2.51459 116.55C2.74423 115.5 2.90853 114.432 2.96426 113.325C3.25443 107.623 6.8422 104.399 13.6545 104.495C14.8969 104.728 16.3218 104.963 17.8419 105.226C20.1517 105.624 22.6903 106.089 25.1904 106.721C28.9184 107.663 30.9871 111.553 29.6832 115.225Z" />
<path id="t17" className={cn("toothregion", ...(toothClasses["t17"] || []))} fillRule="evenodd" clipRule="evenodd" d="M29.6832 115.225C29.1375 116.764 28.8723 118.263 28.8118 119.77C28.7541 121.171 28.8732 122.579 29.1202 124.034C29.7947 128.006 27.7433 131.237 23.8519 132.274C20.4947 133.168 17.1597 133.451 13.8717 133.135C10.92 132.852 8.00577 132.09 5.15112 130.797C1.83912 129.296 0.506436 126.502 0.979168 122.819C1.13963 121.571 1.42115 120.364 1.80453 119.171C2.08317 118.303 2.32146 117.432 2.51459 116.55C2.74423 115.5 2.90853 114.432 2.96426 113.325C3.25443 107.623 6.8422 104.399 13.6545 104.495C14.8969 104.728 16.3218 104.963 17.8419 105.226C20.1517 105.624 22.6903 106.089 25.1904 106.721C28.9184 107.663 30.9871 111.553 29.6832 115.225Z" />
<path id="t11" className={cn("toothregion", ...(toothClasses["t11"] || []))} fillRule="evenodd" clipRule="evenodd" d="M94.9527 5.72055C94.8441 6.40787 94.7365 7.1231 94.6145 7.85374C94.0447 11.2393 93.1357 14.9666 90.185 17.9613C88.7063 19.463 86.9652 19.8375 84.9763 19.4467C81.5288 18.7699 78.8029 16.7398 76.0866 14.6913C74.8597 13.7652 73.7739 12.7015 72.7573 11.5676C72.2798 11.0343 71.8119 10.4894 71.3622 9.9282C69.1773 7.203 69.3723 5.62429 72.1299 3.571C73.4876 2.55928 75.0566 2.01829 76.6487 1.66693C79.8329 0.963245 83.0604 -0.0263348 86.2629 0.000617981C88.6256 0.0198708 91.2515 0.185442 93.5172 1.40606C94.7298 2.05968 94.9959 2.72197 94.9527 5.72055Z" />
<path id="t12" className={cn("toothregion", ...(toothClasses["t12"] || []))} fillRule="evenodd" clipRule="evenodd" d="M71.5491 21.4177C70.866 24.5837 69.2969 26.4031 65.0202 26.0364C61.0923 25.7004 57.8745 24.0264 55.0525 21.6862C53.7227 20.5831 52.4803 19.3345 51.2937 18.0042C50.1551 16.7287 50.3924 15.2549 51.8318 13.7311C54.0801 11.3515 56.9338 9.92773 59.923 8.7514C62.3039 7.8138 64.7166 7.09665 67.3454 7.44993C68.3563 7.58566 69.0356 8.03328 69.4343 8.90542C69.9829 10.1039 70.4288 11.3284 70.7862 12.5856C71.3858 14.7014 71.7298 16.9135 71.8672 19.2623C71.7836 19.8399 71.7173 20.6399 71.5491 21.4177Z" />
<path id="t23" className={cn("toothregion", ...(toothClasses["t23"] || []))} fillRule="evenodd" clipRule="evenodd" d="M131.629 32.2837C132.288 35.0493 133.601 37.5194 135.652 39.5294C137.411 41.2506 139.52 41.6106 141.694 40.4651C144.013 39.2425 146.114 37.7745 147.956 36.0283C149.617 34.4535 151.071 32.6534 152.261 30.577C152.534 30.1014 152.768 29.5989 152.972 29.0907C154.795 24.5413 153.054 21.8844 148.128 21.6958C147.58 21.6746 147.03 21.6929 146.482 21.6929C143.759 21.9133 141.023 22.0981 138.421 22.8644C137.319 23.1878 136.239 23.6056 135.199 24.1909C132.051 25.9621 130.787 28.7499 131.629 32.2837Z" />
<path id="t28" className={cn("toothregion", ...(toothClasses["t28"] || []))} fillRule="evenodd" clipRule="evenodd" d="M168.761 159.716C171.528 160.524 174.354 161.127 177.067 161.01C177.356 160.997 177.646 160.984 177.934 160.954C181.17 161.12 184.074 160.78 186.852 159.668C190.116 158.363 191.705 156.016 191.763 152.527C191.787 151.068 191.757 149.612 191.747 148.155C191.734 146.391 191.752 144.625 191.942 142.841C192.488 137.726 189.156 134.535 183.257 134.301C181.686 134.238 180.109 134.234 178.532 134.256C176.073 134.293 173.612 134.41 171.166 134.604C166.695 134.956 163.877 138.641 164.523 143.252C164.687 144.418 164.622 145.482 164.392 146.515C164.225 147.263 163.973 147.997 163.645 148.74C161.096 154.511 162.66 157.936 168.761 159.716Z" />
<path id="t24" className={cn("toothregion", ...(toothClasses["t24"] || []))} fillRule="evenodd" clipRule="evenodd" d="M144.191 52.4388C144.454 53.0029 144.741 53.5602 145.075 54.1032C147.332 57.7621 151.397 59.5478 155.626 57.4675C156.564 57.0064 157.492 56.5405 158.397 56.0429C160.178 55.0648 161.87 53.9559 163.357 52.459C165.888 49.909 165.884 44.6328 163.599 41.2126C162.75 39.941 161.596 38.9187 160.125 38.3979C158.133 37.6942 156.119 37.1705 154.223 37.2947C152.298 37.2552 150.53 37.5142 148.827 37.932C147.993 38.137 147.173 38.3767 146.362 38.6434C143.932 39.4433 142.181 41.8942 142.342 44.55C142.51 47.2878 143.032 49.959 144.191 52.4388Z" />
<path id="t25" className={cn("toothregion", ...(toothClasses["t25"] || []))} fillRule="evenodd" clipRule="evenodd" d="M150.429 65.894C150.616 68.0022 150.942 70.0834 151.582 72.0385C152.361 74.4191 153.608 76.6129 155.659 78.4342C157.618 80.1746 159.995 81.0689 162.581 80.3392C164.201 79.882 165.744 79.2765 167.216 78.5362C169.401 77.4369 171.425 76.0324 173.282 74.3064C176.344 71.459 176.448 66.4687 174.641 62.7597C173.654 60.7334 172.106 59.0844 170.138 58.3884C167.943 57.6125 165.727 57.1851 163.284 57.0812C161.778 57.2304 160.214 57.3469 158.654 57.6C157.681 57.7579 156.711 57.9687 155.756 58.2748C151.944 59.4974 150.075 61.9203 150.429 65.894Z" />
<path id="t26" className={cn("toothregion", ...(toothClasses["t26"] || []))} fillRule="evenodd" clipRule="evenodd" d="M158.037 88.8801C158.711 90.6417 159.295 92.4197 159.762 94.2333C160.099 95.5444 160.384 96.8728 160.563 98.2397C161.22 103.287 165.305 106.154 170.265 104.998C171.376 104.739 172.478 104.428 173.576 104.097C175.53 103.507 177.463 102.831 179.365 102.102C182.31 100.975 184.065 97.408 183.46 94.0773C183.155 92.3976 182.701 90.7534 182.174 89.1314C181.806 87.9993 181.407 86.8759 180.98 85.767C180.067 83.4047 178.068 82.1465 175.742 81.438C173.443 80.7363 171.086 80.3416 168.896 80.538C168.424 80.5293 167.967 80.5428 167.509 80.5553C165.415 80.6083 163.468 80.873 161.584 81.4765C157.779 82.6943 156.609 85.1499 158.037 88.8801Z" />
<path id="t27" className={cn("toothregion", ...(toothClasses["t27"] || []))} fillRule="evenodd" clipRule="evenodd" d="M162.313 115.222C162.859 116.76 163.124 118.259 163.185 119.766C163.242 121.167 163.123 122.575 162.876 124.031C162.202 128.002 164.253 131.233 168.145 132.27C171.502 133.164 174.837 133.447 178.125 133.131C181.077 132.848 183.991 132.086 186.845 130.793C190.157 129.292 191.49 126.498 191.017 122.815C190.857 121.567 190.575 120.36 190.192 119.167C189.913 118.299 189.675 117.428 189.482 116.546C189.252 115.496 189.088 114.428 189.032 113.321C188.742 107.619 185.154 104.395 178.342 104.491C177.1 104.724 175.675 104.959 174.155 105.222C171.845 105.62 169.306 106.085 166.806 106.717C163.078 107.659 161.009 111.549 162.313 115.222Z" />
<path id="t21" className={cn("toothregion", ...(toothClasses["t21"] || []))} fillRule="evenodd" clipRule="evenodd" d="M97.0512 5.72055C97.1598 6.40787 97.2674 7.1231 97.3894 7.85374C97.9592 11.2393 98.8682 14.9666 101.819 17.9613C103.298 19.463 105.039 19.8375 107.028 19.4467C110.475 18.7699 113.201 16.7398 115.917 14.6913C117.144 13.7652 118.23 12.7015 119.247 11.5676C119.724 11.0343 120.192 10.4894 120.642 9.9282C122.827 7.203 122.632 5.62429 119.874 3.571C118.516 2.55928 116.947 2.01829 115.355 1.66693C112.171 0.963245 108.943 -0.0263348 105.741 0.000617981C103.378 0.0198708 100.752 0.185442 98.4867 1.40606C97.2741 2.05968 97.008 2.72197 97.0512 5.72055Z" />
<path id="t22" className={cn("toothregion", ...(toothClasses["t22"] || []))} fillRule="evenodd" clipRule="evenodd" d="M120.439 21.4177C121.122 24.5837 122.691 26.4031 126.968 26.0364C130.896 25.7004 134.114 24.0264 136.936 21.6862C138.266 20.5831 139.508 19.3345 140.695 18.0042C141.833 16.7287 141.596 15.2549 140.156 13.7311C137.908 11.3515 135.054 9.92773 132.065 8.7514C129.684 7.8138 127.272 7.09665 124.643 7.44993C123.632 7.58566 122.953 8.03328 122.554 8.90542C122.005 10.1039 121.559 11.3284 121.202 12.5856C120.602 14.7014 120.258 16.9135 120.121 19.2623C120.205 19.8399 120.271 20.6399 120.439 21.4177Z" />
<path id="tongue" fillRule="evenodd" clipRule="evenodd" d="M127.384 200.64C136.719 203.547 142.056 213.021 141.138 224.782V224.914C141.15 225.601 142.156 296.192 114.797 311.758L114.636 311.864C114.636 311.864 114.629 311.869 114.62 311.876C114.296 312.118 109.094 316 97.9404 316C87.464 316 80.5574 312.453 79.376 311.847C79.3403 311.828 79.3098 311.813 79.2846 311.8C79.201 311.757 79.124 311.717 79.042 311.67C52.5236 296.331 52.823 228.312 52.8546 224.914C52.8554 224.821 52.8522 224.728 52.8449 224.637C51.9818 212.944 57.3135 203.534 66.6094 200.6C69.8922 199.552 73.299 199.014 76.7262 199.001C83.1927 198.958 89.4995 201.065 94.7728 205.012C95.4201 205.497 96.1818 205.779 96.9743 205.779C97.7547 205.779 98.5139 205.527 99.1466 205.04C102.625 202.366 112.854 196.074 127.384 200.64ZM97.0696 272.298C97.3323 272.298 97.5455 272.08 97.5455 271.812V216.471C97.5455 216.202 97.3323 215.984 97.0696 215.984C96.8069 215.984 96.5938 216.202 96.5938 216.471V271.812C96.5938 272.08 96.8069 272.298 97.0696 272.298Z"/>
</g>
<g id="toothnumbers">
<g id="toothnumber_t18" className={cn("toothnumber", ...(toothClasses["t18"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="10.0455" y="150.9" >{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t18"]}</text></g>
<g id="toothnumber_t48" className={cn("toothnumber", ...(toothClasses["t48"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="8.45176" y="215.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t48"]}</text></g>
<g id="toothnumber_t47" className={cn("toothnumber", ...(toothClasses["t47"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="9.28867" y="244.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t47"]}</text></g>
<g id="toothnumber_t37" className={cn("toothnumber", ...(toothClasses["t37"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="171.294" y="244.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t37"]}</text></g>
<g id="toothnumber_t36" className={cn("toothnumber", ...(toothClasses["t36"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="164.413" y="271.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t36"]}</text></g>
<g id="toothnumber_t46" className={cn("toothnumber", ...(toothClasses["t46"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="15.4078" y="271.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t46"]}</text></g>
<g id="toothnumber_t45" className={cn("toothnumber", ...(toothClasses["t45"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="23.4127" y="295.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t45"]}</text></g>
<g id="toothnumber_t35" className={cn("toothnumber", ...(toothClasses["t35"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="157.418" y="295.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t35"]}</text></g>
<g id="toothnumber_t34" className={cn("toothnumber", ...(toothClasses["t34"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="148.079" y="316.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t34"]}</text></g>
<g id="toothnumber_t44" className={cn("toothnumber", ...(toothClasses["t44"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="33.0738" y="316.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t44"]}</text></g>
<g id="toothnumber_t43" className={cn("toothnumber", ...(toothClasses["t43"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="44.0787" y="333.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t43"]}</text></g>
<g id="toothnumber_t33" className={cn("toothnumber", ...(toothClasses["t33"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="137.084" y="333.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t33"]}</text></g>
<g id="toothnumber_t32" className={cn("toothnumber", ...(toothClasses["t32"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="124.427" y="346.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t32"]}</text></g>
<g id="toothnumber_t42" className={cn("toothnumber", ...(toothClasses["t42"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="58.4225" y="346.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t42"]}</text></g>
<g id="toothnumber_t41" className={cn("toothnumber", ...(toothClasses["t41"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="79.1676" y="354.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t41"]}</text></g>
<g id="toothnumber_t31" className={cn("toothnumber", ...(toothClasses["t31"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="104.172" y="354.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t31"]}</text></g>
<g id="toothnumber_t38" className={cn("toothnumber", ...(toothClasses["t38"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="171.457" y="215.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t38"]}</text></g>
<g id="toothnumber_t28" className={cn("toothnumber", ...(toothClasses["t28"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="171.3" y="150.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t28"]}</text></g>
<g id="toothnumber_t17" className={cn("toothnumber", ...(toothClasses["t17"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="11.3824" y="122.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t17"]}</text></g>
<g id="toothnumber_t27" className={cn("toothnumber", ...(toothClasses["t27"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="170.137" y="122.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t27"]}</text></g>
<g id="toothnumber_t16" className={cn("toothnumber", ...(toothClasses["t16"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="16.0016" y="95.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t16"]}</text></g>
<g id="toothnumber_t26" className={cn("toothnumber", ...(toothClasses["t26"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="164.256" y="95.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t26"]}</text></g>
<g id="toothnumber_t15" className={cn("toothnumber", ...(toothClasses["t15"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="25.0064" y="70.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t15"]}</text></g>
<g id="toothnumber_t25" className={cn("toothnumber", ...(toothClasses["t25"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="156.261" y="70.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t25"]}</text></g>
<g id="toothnumber_t14" className={cn("toothnumber", ...(toothClasses["t14"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="34.1676" y="50.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t14"]}</text></g>
<g id="toothnumber_t24" className={cn("toothnumber", ...(toothClasses["t24"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="147.422" y="50.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t24"]}</text></g>
<g id="toothnumber_t13" className={cn("toothnumber", ...(toothClasses["t13"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="46.1725" y="33.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t13"]}</text></g>
<g id="toothnumber_t12" className={cn("toothnumber", ...(toothClasses["t12"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="58.0162" y="19.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t12"]}</text></g>
<g id="toothnumber_t11" className={cn("toothnumber", ...(toothClasses["t11"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="80.2613" y="12.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t11"]}</text></g>
<g id="toothnumber_t21" className={cn("toothnumber", ...(toothClasses["t21"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="103.016" y="12.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t21"]}</text></g>
<g id="toothnumber_t22" className={cn("toothnumber", ...(toothClasses["t22"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="123.271" y="19.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t22"]}</text></g>
<g id="toothnumber_t23" className={cn("toothnumber", ...(toothClasses["t23"] || []))} xmlSpace="preserve" letterSpacing="-0.3px"><text x="136.427" y="33.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t23"]}</text></g>
</g>

<g id="toothnumbers_reversed">
<g id="toothnumber_reversed_t18" className={cn("toothnumber", ...(toothClasses["t18"] || []))}  letterSpacing="-0.3px"><text x="173.045" y="150.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t18"]}</text></g>
<g id="toothnumber_reversed_t48" className={cn("toothnumber", ...(toothClasses["t48"] || []))}  letterSpacing="-0.3px"><text x="172.452" y="215.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t48"]}</text></g>
<g id="toothnumber_reversed_t47" className={cn("toothnumber", ...(toothClasses["t47"] || []))}  letterSpacing="-0.3px"><text x="172.289" y="244.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t47"]}</text></g>
<g id="toothnumber_reversed_t37" className={cn("toothnumber", ...(toothClasses["t37"] || []))}  letterSpacing="-0.3px"><text x="10.2934" y="244.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t37"]}</text></g>
<g id="toothnumber_reversed_t36" className={cn("toothnumber", ...(toothClasses["t36"] || []))}  letterSpacing="-0.3px"><text x="16.4126" y="271.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t36"]}</text></g>
<g id="toothnumber_reversed_t46" className={cn("toothnumber", ...(toothClasses["t46"] || []))}  letterSpacing="-0.3px"><text x="165.408" y="271.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t46"]}</text></g>
<g id="toothnumber_reversed_t45" className={cn("toothnumber", ...(toothClasses["t45"] || []))}  letterSpacing="-0.3px"><text x="157.413" y="295.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t45"]}</text></g>
<g id="toothnumber_reversed_t35" className={cn("toothnumber", ...(toothClasses["t35"] || []))}  letterSpacing="-0.3px"><text x="23.4175" y="295.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t35"]}</text></g>
<g id="toothnumber_reversed_t34" className={cn("toothnumber", ...(toothClasses["t34"] || []))}  letterSpacing="-0.3px"><text x="33.0786" y="316.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t34"]}</text></g>
<g id="toothnumber_reversed_t44" className={cn("toothnumber", ...(toothClasses["t44"] || []))}  letterSpacing="-0.3px"><text x="148.074" y="316.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t44"]}</text></g>
<g id="toothnumber_reversed_t43" className={cn("toothnumber", ...(toothClasses["t43"] || []))}  letterSpacing="-0.3px"><text x="137.079" y="333.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t43"]}</text></g>
<g id="toothnumber_reversed_t33" className={cn("toothnumber", ...(toothClasses["t33"] || []))}  letterSpacing="-0.3px"><text x="44.0835" y="333.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t33"]}</text></g>
<g id="toothnumber_reversed_t32" className={cn("toothnumber", ...(toothClasses["t32"] || []))}  letterSpacing="-0.3px"><text x="56.4272" y="346.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t32"]}</text></g>
<g id="toothnumber_reversed_t42" className={cn("toothnumber", ...(toothClasses["t42"] || []))}  letterSpacing="-0.3px"><text x="122.422" y="346.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t42"]}</text></g>
<g id="toothnumber_reversed_t41" className={cn("toothnumber", ...(toothClasses["t41"] || []))}  letterSpacing="-0.3px"><text x="104.167" y="354.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t41"]}</text></g>
<g id="toothnumber_reversed_t31" className={cn("toothnumber", ...(toothClasses["t31"] || []))}  letterSpacing="-0.3px"><text x="79.1723" y="354.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t31"]}</text></g>
<g id="toothnumber_reversed_t38" className={cn("toothnumber", ...(toothClasses["t38"] || []))}  letterSpacing="-0.3px"><text x="9.45652" y="215.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t38"]}</text></g>
<g id="toothnumber_reversed_t28" className={cn("toothnumber", ...(toothClasses["t28"] || []))}  letterSpacing="-0.3px"><text x="9.30027" y="150.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t28"]}</text></g>
<g id="toothnumber_reversed_t17" className={cn("toothnumber", ...(toothClasses["t17"] || []))}  letterSpacing="-0.3px"><text x="172.382" y="122.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t17"]}</text></g>
<g id="toothnumber_reversed_t27" className={cn("toothnumber", ...(toothClasses["t27"] || []))}  letterSpacing="-0.3px"><text x="11.1372" y="122.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t27"]}</text></g>
<g id="toothnumber_reversed_t16" className={cn("toothnumber", ...(toothClasses["t16"] || []))}  letterSpacing="-0.3px"><text x="167.001" y="95.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t16"]}</text></g>
<g id="toothnumber_reversed_t26" className={cn("toothnumber", ...(toothClasses["t26"] || []))}  letterSpacing="-0.3px"><text x="16.2563" y="95.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t26"]}</text></g>
<g id="toothnumber_reversed_t15" className={cn("toothnumber", ...(toothClasses["t15"] || []))}  letterSpacing="-0.3px"><text x="158.006" y="70.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t15"]}</text></g>
<g id="toothnumber_reversed_t25" className={cn("toothnumber", ...(toothClasses["t25"] || []))}  letterSpacing="-0.3px"><text x="24.2612" y="70.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t25"]}</text></g>
<g id="toothnumber_reversed_t14" className={cn("toothnumber", ...(toothClasses["t14"] || []))}  letterSpacing="-0.3px"><text x="149.167" y="50.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t14"]}</text></g>
<g id="toothnumber_reversed_t24" className={cn("toothnumber", ...(toothClasses["t24"] || []))}  letterSpacing="-0.3px"><text x="33.4223" y="50.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t24"]}</text></g>
<g id="toothnumber_reversed_t13" className={cn("toothnumber", ...(toothClasses["t13"] || []))}  letterSpacing="-0.3px"><text x="137.172" y="33.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t13"]}</text></g>
<g id="toothnumber_reversed_t12" className={cn("toothnumber", ...(toothClasses["t12"] || []))}  letterSpacing="-0.3px"><text x="125.016" y="19.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t12"]}</text></g>
<g id="toothnumber_reversed_t11" className={cn("toothnumber", ...(toothClasses["t11"] || []))}  letterSpacing="-0.3px"><text x="105.261" y="12.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t11"]}</text></g>
<g id="toothnumber_reversed_t21" className={cn("toothnumber", ...(toothClasses["t21"] || []))}  letterSpacing="-0.3px"><text x="80.0161" y="12.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t21"]}</text></g>
<g id="toothnumber_reversed_t22" className={cn("toothnumber", ...(toothClasses["t22"] || []))}  letterSpacing="-0.3px"><text x="57.271" y="19.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t22"]}</text></g>
<g id="toothnumber_reversed_t23" className={cn("toothnumber", ...(toothClasses["t23"] || []))}  letterSpacing="-0.3px"><text x="44.4272" y="33.9">{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t23"]}</text></g>
</g>
</g>

</svg>

  </StyledToothSetPdf>

)

export {ToothSetPdf};
