import { constants } from './_constants';
import { services } from './_services';
import axios from 'axios'

export const actions = {
  fetchPartialScan,
  selectRegion,
  setPartialScanId,
  uploadPartialScan,
  uploadAllPartialScan,
  addPartialScanImage,
  removePartialScanImage,
  toggleReview,
  setPartialScanMessageTimeToken
}

function toggleReview(toggle){
  return (dispatch) => {
    dispatch(update(toggle));
  }
  function update(toggle){ return { type: constants.UPDATE, data: {reviewOpen: toggle} }}
}

function setPartialScanId(id){
  return (dispatch) => {
    dispatch(select(id));
  }
  function select(id) { return { type: constants.UPDATE, data: {partialScanId: id} }}
}

function selectRegion(region){
  return (dispatch) => {
    dispatch(select(region));
  }
  function select(region) { return { type: constants.UPDATE, data: {selectedRegion: region} }}
}

function setPartialScanMessageTimeToken(timeToken){
  return (dispatch) => {
    dispatch(update(timeToken));
  }
  function update(timeToken) { return { type: constants.UPDATE, date: {partialScanMessageTimeToken: timeToken}}}
}

function uploadPartialScan(partialScan){
  return (dispatch) => {
    services.upload(partialScan.data,  (event) => {
      dispatch(setProgress(partialScan.region, Math.round((95 * event.loaded) / event.total)))
    }, {})
      .then(
        resp => dispatch(success(partialScan.region, resp.data))
      ).catch(
        error => dispatch(failure(partialScan.region, error))
      )
  }
  function setProgress(region, progress){return {type: constants.UPLOAD_SETPROGRESS, data: {region: region, progress: progress}}}
  function success(region, data){ return {type: constants.UPLOAD_SUCCESS, data: {region: region, data: data} }}
  function failure(region, error){ return {type: constants.UPLOAD_FAILURE, data: {region: region} }}
}

function uploadAllPartialScan(partialScanList){
  return (dispatch) => {
    let toUpload = [];
    let uploadKeys = [];
    partialScanList.map( (item, index) => {
      toUpload.push(
        services.upload(item.data, item.id, (event) => {
          dispatch(setProgress(item.region, Math.round((95 * event.loaded) / event.total)))
        }, {})
      )
      uploadKeys.push(index);
    })
    axios.all(toUpload)
      .then( axios.spread((...responses) => {
        responses.map((resp, index) => {
          dispatch(success(resp.data.region, resp.data))
        })
      })).catch(errs => {
        if(Array.isArray(errs)){
          console.log("ERRS UPLAOD: ", errs)
          errs.map((err, index) => {
            dispatch(failure(uploadKeys[index], err))
          })
        } else if (typeof errs == typeof {}){
          // dispatch(failure())
          console.log("ERR: ", errs)
        } else {
          console.log("ERR: ", errs)
        }
      })
  }
  function setProgress(region, progress){return {type: constants.UPLOAD_SETPROGRESS, data: {region: region, progress: progress}}}
  function success(region, data){ return {type: constants.UPLOAD_SUCCESS, data: {region: region, uploadData: data} }}
  function failure(region, error){ return {type: constants.UPLOAD_FAILURE, data: {region: region} }}
}

function fetchPartialScan(id){
    return (dispatch, getState) => {
      dispatch(request());
      services.fetchPartialScan(id)
        .then(
          data => dispatch(success(data)),
          error => dispatch(failure(error))
        )
    }
    function request() { return { type: constants.FETCHPARTIALSCAN_REQUEST } }
    function success(respData) {
      // breakdown reports...
      const data = {
        partialScan: respData,
        byId: respData.regions
                ? respData.regions.reduce((a,b) => {a[b.region] = b;return a;}, {})
                : {}
      }
      return { type:constants.FETCHPARTIALSCAN_SUCCESS, data }
    }
    function failure(error) { return { type:constants.FETCHPARTIALSCAN_FAILURE, error} }
}

function addPartialScanImage(region, data, extraData={}){
  return (dispatch) => {
    dispatch(update(region, data, extraData))
  }
  function update(region, data, extraData){
    return { type: constants.UPDATE_PARTIAL_SCAN, data:{region, data, objectUrl: URL.createObjectURL(data), mimeType: data.type}}
  }
}

function removePartialScanImage(region){
  return (dispatch) => {
    dispatch(remove(region))
  }
  function remove(region){
    return { type: constants.REMOVE_PARTIAL_SCAN, data:{region}}
  }
}
