import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Link, useLocation, useParams, useHistory, Redirect } from 'react-router-dom';

import { localeFormatDate, formatName } from '../_helpers';
import { Button, Flex, Text, Box } from '@chakra-ui/react';
// components
import { BiRadar } from "react-icons/bi";
import { LoadingEllipses } from '../_components/LoadingEllipses';
import { RecordUploadMedia } from '../_components/RecordUploadMedia';

// actions
import { actions as recordActions } from '../Records/_actions';
import { actions as modalActions } from '../Modals/_actions';

import { FormattedMessage } from 'react-intl';

// selectors
import { getIntlLocale, getIntlMessages } from '../Authentication/_selectors';
import { getRecordsLoading, getAllRecords, getRecordsIsMore } from '../Records/_selectors';

// styles
import {
  StyledDetailsRecordsHistory,
  StyledIsMore
} from './styles';

const RecordItem = ({record, selectedRecord, handleClick}) => {
  const dispatch = useDispatch();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);

  const selected = selectedRecord == record.id;

  const onClick = () => {
    //dispatch(recordActions.fetchRecordDetail(record.id));
    handleClick(record.id)
    //dispatch(recordActions.selectRecord(record.id));
    //dispatch(modalActions.openModal('record'));
  }

  /*
    record.is_detect &&
      (<div className={'recordDetection'}>
        <BiRadar />
      </div>)
  */

  //const nameLabel = formatName(intlMessages['format.fullName'], record.user_profile?.first_name, record.user_profile?.last_name)
  const nameLabel = record.user_profile?.first_name

  return (
    <Flex 
      w={['full']} 
      direction={['column']} 
      bg={'#FFF'} 
      p={['5px']}
      py={'20px'}
      onClick={onClick}
      _hover={{cursor: 'pointer'}}
      borderBottom="1px solid #D8D8D8"
    >
        <Text fontSize={['20px']} color={selected ? '#F7F7F7' : '#12171A'}>
          {nameLabel}
        </Text>
        <Text fontSize={['14px']} color={selected ? '#6B8A99' : '#405159'}>
          {intlMessages[`config.RECORD_TYPES.${record.type}`]}
        </Text>
        <Text fontSize={['14px']} color={selected ? '#6B8A99' : '#405159'}>
          {record.created_at && localeFormatDate(record.created_at, intlLocale, "lll")}
        </Text>
        <Flex w={['full']} mt={['10px']}>
          <RecordUploadMedia
            upload={record}
            imgHeight={50}
            imgWidth={50}
            imgBorderRadius={4}
          />
          {record.upload_count > 1 && (
            <Flex align={['center']} px={['15px']}>
              <Text fontSize={['14px']}  color={selected ? '#6B8A99' : '#405159'}>
                {'+ '}{record.upload_count - 1}
              </Text>
            </Flex>
          )}
        </Flex>
    </Flex>
  )

}

const IsMore = ({lastItem}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const recordsLoading = useSelector(getRecordsLoading);

  const onClick = () => {
    dispatch(recordActions.fetchRecords({'last_ts': lastItem.created_at_ts}))
  }

  return (
    <Flex w={['full']} py={['20px']}>
      <StyledIsMore>
        {!recordsLoading
          ?
            <div onClick={onClick}>
              {intlMessages['settings.recordHistory.loadMore']}
            </div>
          :
            <div>
              <LoadingEllipses />
            </div>
        }
      </StyledIsMore>
    
    </Flex>
  )
}

export const RecordsHistory = ({onSelect=null, partial=false, profileId=null}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [ selectedRecord, setSelectedRecord ] = useState(null);
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const recordsLoading = useSelector(getRecordsLoading);
  let recordsList = useSelector(getAllRecords);
  const isMore = useSelector(getRecordsIsMore);

  let [ succeeded, setSucceeded] = useState(null);

  let params = new URLSearchParams(location.search);

  let isScanning = onSelect ? true : false;

  const handleClick = () => {
    if (isScanning){
      onSelect(selectedRecord);
      return;
    }
  }

  const handleRecordSelect = (recId) => {
    // there is a next screen, otherwise open modal
    if (isScanning){
      setSelectedRecord(recId);
    } else {
      let newParams = new URLSearchParams();
      newParams.set("rec", recId);
      history.push((`${location.pathname}?${newParams.toString()}`))
    }
  }


  useEffect(()=>{
    let rec = params.get('rec');
    dispatch(recordActions.fetchRecords())
    // record has been selectoed, open it
    if (rec){
      dispatch(recordActions.selectRecord(rec));
      dispatch(modalActions.openModal('recordDetail'));
    }
  }, [location.search])
  

  let lastRecord = recordsList[recordsList.length - 1];

  //if (profileId){
  //  recordsList = recordsList.filter(rec => rec.user_profile?.id == profileId);
  //}

  return (
    <StyledDetailsRecordsHistory>
      {!partial && <h2>{intlMessages['settings.menu.menulist.menuItem.recordshistory.title']}</h2>}
        <Box bg={'transparent'} position={'relative'} height={'660px'} overflow={'overlay'}>
          { !isMore && recordsLoading && <div className={'loading'}><LoadingEllipses /></div>}
          <Flex w={['full']} direction={['column']} px={['0px']} pr={'30px'}>
            {
              recordsList.map((elem, index) =>
                <RecordItem key={elem.id} record={elem} handleClick={handleRecordSelect} selectedRecord={selectedRecord} />
              )
            }
          </Flex>
          { isMore && <IsMore lastItem={lastRecord} />}
        </Box>
      {isScanning && (
        <Flex w={'full'} p={['20px']} bg={'#F7F7F7'}>
          <Button 
            variant={'solid'} 
            w={['full']}
            isDisabled={!selectedRecord}
            onClick={handleClick}
          >
              {intlMessages['tidbits.next']}
          </Button>
        </Flex>
      )}
    </StyledDetailsRecordsHistory>
  )
}
