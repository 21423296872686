import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';

import { Flex, Text } from '@chakra-ui/react';

import { localeFormatDate } from '../_helpers';

// components
import { BiRadar } from "react-icons/bi";
import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { LoadingEllipses } from '../_components/LoadingEllipses';

// redux state
import { actions } from './_actions';
import {
  getCurrentMediaId,
  getCurrentSelectedMediaItem,
  getCurrentRecordId,
  getCurrentRecordDetail,
  getCurrentRecordUploadsById,
  getCurrentRecordUploads,
  getCurrentSelectedMedia,
  getCurrentRecordLoading,
  getCurrentRecordError,
  getCurrentRecordCreatedDate
} from './_selectors';

// actions
import { actions as modalActions } from '../Modals/_actions';

// selectors
import { getIntlLocale, getIntlMessages } from '../Authentication/_selectors';
import { getRecordUploads } from '../Reports/_selectors'



// styles
import {
  Wrapper,
  StyledRecordDetail,
  StyledMediaPreview,
  StyledMediaList,
  StyledMediaItem,
  StyledPreviewImage,
  BlankMediaItem,
  BlankDiv
} from './styles';

const PreviewImage = () => {
  const dispatch = useDispatch();
  const currentSelectedId = useSelector(getCurrentMediaId);
  const item = useSelector(getCurrentSelectedMedia);
  const [ upload, setUpload ] = useState(null)

  const detectClick = () => {
    dispatch(actions.setCurrentDetectionMedia(currentSelectedId));
    dispatch(modalActions.openModal('uploadDetection'));
  }

  useEffect(()=>{
    return () => {
      dispatch(actions.selectRecord(null))
    }
  }, [])

  useEffect(() => {
      setUpload(item)
  }, [item && item.id])

  if (item && upload){
    return (
      <React.Fragment>
        <RecordUploadMedia
          key={'RecordUploadMediaL' + item.id}
          upload={upload}
          imgWidth={640}
          imgHeight={480}
          imgBorderRadius={5}
          play={true}
        />
      {upload.is_detect && <div className={'detectIcon'} onClick={detectClick}><BiRadar className={cn({'ready': upload.detect_complete})}/></div>}
      </React.Fragment>
    )
  } else {
  return (
    <BlankMediaItem
      imgWidth={640}
      imgHeight={480}
      imgBorderRadius={5}
      >
    </BlankMediaItem>
  )
  }
}

const MediaItem = ({item, selected}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (item){
      dispatch(actions.setCurrentMedia(item.id));
    }
    //dispatch(mediaActions.uploadMedia(item, 14))
  }

  return(
    <RecordUploadMedia
      key={'RecordUploadMedia130'+item.id}
      onClick={handleClick}
      selected={selected}
      upload={item}
      imgWidth={130}
      imgHeight={130}
      imgBorderRadius={5}
      marginLeft={2}
      marginTop={2}
    />
  )
}

export const MediaList = () => {
  //const recordUploads = useSelector(getRecordUploads)
  //const currentSelectedId = useSelector(getCurrentMediaId);
  //const currentSelectedItem = useSelector(getCurrentSelectedMediaItem);

  const recordUploads = useSelector(getCurrentRecordUploads)
  const currentSelectedId = useSelector(getCurrentMediaId);

  return (
      <StyledMediaList>
        <Scrollbars autoHide style={{height: '100%'}} >
        <div>
        {
          recordUploads.map((item) =>
            <MediaItem
              key={item.id}
              item={item}
              selected={item.id == currentSelectedId}
            />
          )
        }
        </div>
      </Scrollbars>
      </StyledMediaList>
  )
}

export const MediaPreview = () => {
  const dispatch = useDispatch();

  return (
    <StyledMediaPreview>
      <PreviewImage />
    </StyledMediaPreview>
  )
}

export const RecordDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const currentRecordError = useSelector(getCurrentRecordError);
  const currentRecordLoading = useSelector(getCurrentRecordLoading);
  const recordUploads = useSelector(getCurrentRecordUploads);
  const currentRecordId = useSelector(getCurrentRecordId);
  const currentSelectedId = useSelector(getCurrentMediaId);
  const currentRecordDetail = useSelector(getCurrentRecordDetail);
  const currentRecordCreatedDate = useSelector(getCurrentRecordCreatedDate);

  let params = new URLSearchParams(location.search);
  let upl = params.get('upl');
  let det = params.get('det');

  useEffect(()=>{
    // initialization. if no record in reducer, fetch.
    if (currentRecordId && !currentRecordDetail){
      console.log("fetch new record detail please fpr " , currentRecordId)
      dispatch(actions.setCurrentMedia(null));
      dispatch(actions.fetchRecordDetail(currentRecordId));
    }
  }, [currentRecordId, currentRecordDetail])

  useEffect(()=>{
    // initialization. set first upload if no upload is selected
    if (!currentRecordLoading && currentSelectedId == null && recordUploads.length > 0){
      if (upl){
        dispatch(actions.setCurrentMedia(upl));
        if (det){
          dispatch(actions.setCurrentDetectionMedia(upl));
          dispatch(modalActions.openModal('uploadDetection'));
        }
      } else {
        dispatch(actions.setCurrentMedia(recordUploads[0].id));
      }
    }
  }, [currentRecordLoading, currentSelectedId, recordUploads.length])

  return (
    <Wrapper>
      <Flex align={'center'}>
        <Text fontSize={['18px']}>
          {intlMessages[`config.RECORD_TYPES.${currentRecordDetail?.type}`]}
        </Text>
        <Text fontSize={['18px']} color={'#405159'} ml={'8px'}>
          {currentRecordCreatedDate && localeFormatDate(currentRecordCreatedDate, intlLocale, "lll")}
        </Text>
      </Flex>
      <StyledRecordDetail>
        {currentRecordError && <div className={'error'}>{intlMessages['chats.messageTemplates.recordmessage.errorFetching']}</div>}
        {currentRecordLoading && <div className={'loading'}><LoadingEllipses /></div>}
        <MediaPreview />
        <MediaList />
      </StyledRecordDetail>
    </Wrapper>

  )
}
