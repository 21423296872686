import styled from 'styled-components';
import { StyledMain } from '../_styles/common';
import { NavigationBar } from '../NavigationBar';
import { SideNav } from '../SideNav';
import { Scrollbars } from 'react-custom-scrollbars';

const StyledMainPublic = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  height: 100vh;
  background-color: #F7F7F7;
`;

export const MainLayoutPublic = ({ children, hasSideBar=false }) => {
  return (
    <div>
      <NavigationBar />
      <StyledMainPublic showSidebar={hasSideBar}>
        <Scrollbars autoHide style={{height: '100%'}}>
          {children}
        </Scrollbars>
      </StyledMainPublic>
    </div>
  );
};

export const MainLayout = ({ children, hasSideBar }) => {
  return (
    <div>
      <NavigationBar />
      <StyledMain showSidebar={hasSideBar}>
        { hasSideBar && <SideNav />}
        <Scrollbars autoHide style={{height: '100%'}}>
          {children}
        </Scrollbars>
      </StyledMain>
    </div>
  );
};
