import { FULLSCAN_STATES } from '../_config';
import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';

// actions
import { actions as modalActions } from '../Modals/_actions';

// selectors
import { getIntlMessages } from '../Authentication/_selectors';
import { getCurrentRegion, getCurrentState } from './_selectors';

import { MdSwapHoriz, MdInfoOutline } from 'react-icons/md';
import {Region1, Region2, Region3, Region4, Tongue} from '../_components/FullScan';

import {
  StyledFullScanChart,
  StyledFullScanChartTeethWrapper,
  StyledFullScanChartDirection,
  StyledFullScanChartTeeth,
  StyledMaxilla,
  StyledMandible,
  StyledFullScanChartRegions,
  StyledFullScanRegionButton,
  StyledFullScanRegionText,
  StyledFlipChartButton,
  StyledInfoButton
} from './styles';


const MdSwapHorizontal = () => {
  return (
    <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z"></path>
    </svg>
  )
}

export const Maxilla = () => {
  const currentRegion = useSelector(getCurrentRegion);
  const currentState = useSelector(getCurrentState);
  return(
    <StyledMaxilla>
      <Region3 region={currentRegion} state={currentState}/>
      <Region4  region={currentRegion} state={currentState} />
    </StyledMaxilla>
  )
}

export const Mandible = () => {
  const currentRegion = useSelector(getCurrentRegion);
  const currentState = useSelector(getCurrentState);
  return(
    <StyledMandible>
      <Region1 region={currentRegion} state={currentState}/>
      <Region2 region={currentRegion} state={currentState}/>
      <Tongue />
    </StyledMandible>
  )
}

export const FullScanChartTeeth = ({chartView}) => {
  const intlMessages = useSelector(getIntlMessages);

  return(
    <StyledFullScanChartTeethWrapper isFlipped={chartView == 'd'} >
      <StyledFullScanChartDirection>{intlMessages['checkup.fullscan.chart.right']}</StyledFullScanChartDirection>
      <StyledFullScanChartTeeth>
        <Maxilla />
        <Mandible />
      </StyledFullScanChartTeeth>
      <StyledFullScanChartDirection>{intlMessages['checkup.fullscan.chart.left']}</StyledFullScanChartDirection>
    </StyledFullScanChartTeethWrapper>
  )
}

export const FullScanRegionButton = ({state, region, label}) => {
  let currentState = false;
  if (region > label){
    currentState = FULLSCAN_STATES.FINISHED
  } else if (region == label){
    currentState = state;
  }
  return(
    <StyledFullScanRegionButton className={cn(currentState, {'FILLED': currentState})}>
      {label + 1}
    </StyledFullScanRegionButton>
  )
}

export const FullScanChartRegions = () => {
  const currentRegion = useSelector(getCurrentRegion);
  const currentState = useSelector(getCurrentState);

  return (
    <StyledFullScanChartRegions>
      <FullScanRegionButton label={0} region={currentRegion} state={currentState} />
      <FullScanRegionButton label={1} region={currentRegion} state={currentState} />
      <FullScanRegionButton label={2} region={currentRegion} state={currentState} />
      <FullScanRegionButton label={3} region={currentRegion} state={currentState} />
    </StyledFullScanChartRegions>
  )
}

export const FullScanChartText = () => {
  const intlMessages = useSelector(getIntlMessages);
  const currentRegion = useSelector(getCurrentRegion);
  const currentState = useSelector(getCurrentState);

  return (
    <StyledFullScanRegionText>
      {intlMessages[`checkup.fullscan.chart.text.${currentRegion}.${currentState}`]}
    </StyledFullScanRegionText>)

}

export const FlipChartButton = ({onClick}) => {

  return (
    <StyledFlipChartButton>
      <div onClick={onClick}>
        <MdSwapHorizontal />
      </div>
    </StyledFlipChartButton>
  )
}

export const InfoButton = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    //dispatch(modalActions.openHowToModal('guidedScan'));
  }

  return (
    <StyledInfoButton>
      <div onClick={handleClick}>
        <MdInfoOutline color={"white"} />
      </div>
    </StyledInfoButton>
  )
}

export const FullScanChart = () => {
  const [ chartView, setChartView ] = useState(localStorage.getItem('chartView'))

  const handleFlipped = () => {
    console.log("chartView, " , chartView)
    localStorage.setItem('chartView', chartView == 'd' ? null : 'd');
    setChartView(old => old == null ? 'd' : null);
  }

  return (
    <StyledFullScanChart>
      <FlipChartButton onClick={handleFlipped} />
      <FullScanChartTeeth chartView={chartView} />
      <FullScanChartRegions />
      <FullScanChartText />
      {/*<InfoButton />*/}
    </StyledFullScanChart>
  )
}
