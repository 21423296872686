import { Button } from '../_styles/common';
import styled from 'styled-components';


export const BlankDiv = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;

  display:flex;
  justify-content: center;
  align-items:center;
`;

export const StyledMediaItem = styled.div`
  border-radius:3px;
  position:relative;
  height:72px;
  width:72px;
  background-color: ${({selected}) => (selected ? '#367FFF' : 'transparent')};
  border: 1px solid ${({selected}) => (selected ? '#367FFF' : 'transparent')};
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

  video {
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    display:block;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover{
    cursor: pointer;
  }
`;

export const RemoveButton = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 20px;
  height: 20px;
  background: #F9F9F9;
  opacity: 0.9;
  border: 0.5px solid #405159;
  box-sizing: border-box;
  border-radius:10px;
  display:flex;
  align-items:center;
  justify-content: center;

  > svg {
    width:10px;
    height:10px;
  }
`;

export const BlankMediaItem = styled.div`
  border-radius: ${({borderRadius}) => (borderRadius ? borderRadius: '3')}px;
  height:${({imgHeight}) => (imgHeight ? imgHeight: '72')}px;
  width: ${({imgWidth}) => (imgWidth ? imgWidth: '72')}px;
  box-sizing: border-box;
  background-color: #D8D8D8;

  > ${BlankDiv} {
    border-radius: 3px;
    background-color: #D8D8D8;
    height:72px;
    width:72px;
  }
`;


export const StyledMediaList = styled.div`
  display:flex;
  flex-wrap: wrap;
  max-width: 305px;

  > * {
    margin-right: 4px;
    margin-bottom: 4px;
  }
`;
