const PREFIX = 'PARTIALSCANS_';
export const constants = {
  FETCHPARTIALSCAN_REQUEST: `${PREFIX}FETCHPARTIALSCAN_REQUEST`,
  FETCHPARTIALSCAN_SUCCESS: `${PREFIX}FETCHPARTIALSCAN_SUCCESS`,
  FETCHPARTIALSCAN_FAILURE: `${PREFIX}FETCHPARTIALSCAN_FAILURE`,

  UPLOAD_SETPROGRESS: `${PREFIX}UPLOAD_SETPROGRESS`,
  UPLOAD_SUCCESS: `${PREFIX}UPLOAD_SUCCESS`,
  UPLOAD_FAILURE: `${PREFIX}UPLOAD_FAILURE`,
  UPDATE_PARTIAL_SCAN: `${PREFIX}UPDATE_PARTIAL_SCAN`,
  REMOVE_PARTIAL_SCAN: `${PREFIX}REMOVE_PARTIAL_SCAN`,

  UPDATE: `${PREFIX}UPDATE`,
  UPDATE_STATE: `${PREFIX}UPDATE_STATE`,
  UPDATE_STATE_OBJECT: `${PREFIX}UPDATE_STATE_OBJECT`
}
