import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux state
import { actions } from './_actions';
import { getIsLoggedInFlag, getIsLoggedInLoading, getIsProfileLoading, getProfile } from "./_selectors";

const authContext = createContext();

export function ProvideAuth({children}) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}  
    </authContext.Provider>
  )
}

const useProvideAuth = () => {
  const dispatch = useDispatch();
  const [ isLoading, setIsLoading ] = useState(false);
  const isLoggedIn = useSelector(getIsLoggedInFlag);
  const isLoggingIn = useSelector(getIsLoggedInLoading);
  const profile = useSelector(getProfile);
  const profileLoading = useSelector(getIsProfileLoading);

  useEffect(()=>{
      setIsLoading(isLoggingIn || profileLoading);
  }, [isLoggingIn, profileLoading ])

  const login = (email, password, redirectURL) => {
    dispatch(actions.login(email, password, redirectURL));
  }
  const logout = () => {
    dispatch(actions.logout())
  }

  const fetchProfile = () => {
    dispatch(actions.getProfile())
  }

  return {
    login,
    logout,
    isLoading,
    user: profile,
  }

}

export function useAuth() {
  return useContext(authContext);
}
