import { constants } from './_constants';


const initState = {
  byId:{},
  loading: false,
  error: false,
  detailsLoading: false,
  detailsError: false,
  reviewsLoading: false,
  reviewsError: false,
  currentClinic: null,
  currentMapCenter: null,
  currentMapBounds: null,
  queryType: null,
  dragStarted: false,
  dragMapBounds: null,
  region_list: []
};

export function clinics(state = initState, action){
  switch(action.type){
    case constants.SEARCHCLINICS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case constants.SEARCHCLINICS_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
        error: false
      }
    case constants.SEARCHCLINICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      }
    case constants.FETCH_DENTIST_DETAILS_REQUEST:
      return {
        ...state,
        detailsLoading: true,
      }
    case constants.FETCH_DENTIST_DETAILS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.unique_id] : action.data
        },
        detailsLoading: false,
        detailsError: false
      }
    case constants.FETCH_DENTIST_DETAILS_FAILURE:
      return {
        ...state,
        detailsLoading: false,
        detailsError: true
      }
    case constants.FETCH_DENTIST_REVIEWS_REQUEST:
      return {
        ...state,
        reviewsLoading: true,
        reviewsError: false
      }
    case constants.FETCH_DENTIST_REVIEWS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.unique_id] : {
            ...state.byId[action.unique_id],
            reviews: [
              ...state.byId[action.unique_id]?.reviews || [],
              ...action.data.reviews
            ],
            reviewsIsMore: action.data.is_more,
          }
        },
        reviewsLoading: false,
        reviewsError: false
      }
    case constants.FETCH_DENTIST_REVIEWS_FAILURE:
      return {
        ...state,
        reviewsLoading: false,
        reviewsError: true
      }
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_MAP_CENTER:
      return {
        ...state,
        currentMapCenter: Object.assign({}, {}, action.data)
      }
    case constants.UPDATE_MAP_BOUNDS:
      return {
        ...state,
        currentMapBounds: Object.assign({}, {}, action.data)
      }
    default:
      return state
  }
}
