import React, { useState, useEffect, useRef }  from 'react';
import { Form, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form';
import { useDispatch, useSelector } from 'react-redux';

import { required } from '../../_helpers/utils';

import Styles, {StyledPayButton} from './Styles'
import Card from './Card'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate
} from './cardUtils'

import { services as authenticationServices } from '../../Authentication/_services';
import { constants as authenticationConstants} from '../../Authentication/_constants';
import { getIntlMessages, getPayments } from '../../Authentication/_selectors';

import { actions as modalActions } from '../../Modals/_actions';

import {
  ErrorLabel,
  StyledInput
} from '../../_styles/forms'

export const CCardForm = ({handleSubmit}) => {
  const dispatch = useDispatch();
  const [initializing, setInitializing ] = useState(true);
  const intlMessages = useSelector(getIntlMessages);
  const omiseRef = useRef();
  const Omise = useRef();
  const cards = useSelector(getPayments)


  const formSubmit = async (token) => {
    try{
        const resp = await authenticationServices.addPayment({token})
        dispatch({type: authenticationConstants.UPDATE, data: {payments: [resp.card, ...cards]}})
        dispatch(modalActions.closeModal('cardForm'));
        return null;
    } catch (err){
        return {
          [FORM_ERROR]: intlMessages['settings.details.payments.cardForm.error.general']
        }
    }
  }

  const onSubmit = async (values) => {
    const {expiry, cvc, number, ...data} = values;
    const [expiration_month, expiration_year] = values.expiry.split("/")
    const submitValues = {...data, security_code: cvc, number: number.replace(/\s/g, ''), expiration_month, expiration_year}

    Omise.current.createToken("card", submitValues,  (statusCode, response) => {
      if (response.object == "error" || !response.card.security_code_check) {
        var errors = {}
        // Display an error message.
        errors['security_code'] = "error"
        if(response.object == "error") {
          errors[FORM_ERROR] = response.message
        }
        return errors;

      } else {
        // Then fill the omise_token.
        return formSubmit(response.id)
      }
    })
  }

  useEffect(()=>{
    omiseRef.current = document.createElement('script');
    omiseRef.current.src = "https://cdn.omise.co/omise.js.gz";
    omiseRef.current.async = true;
    omiseRef.current.onload = () => {
      console.log("script onload")
      Omise.current = window.Omise;
      Omise.current.setPublicKey( process.env.REACT_APP_TH_OMISE_CLIENT_ID )
      console.log(Omise.current);
      setInitializing(false);
    }
    document.body.appendChild(omiseRef.current);

    return () => {
      omiseRef.current && document.body.removeChild(omiseRef.current);
      document.getElementById("omise-checkout-iframe-app") && document.getElementById("omise-checkout-iframe-app").remove();
    }
  }, [])

  return(
    <Styles>
      <Form
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          active
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card
                number={values.number || ''}
                name={values.name || ''}
                expiry={values.expiry || ''}
                cvc={values.cvc || ''}
                focused={active}
                placeholders={
                  { name: intlMessages['ccard.form.name.label']}
                }
                locale={
                  { valid: intlMessages['ccard.form.expiry.label']}
                }
              />
              <div>
                <Field
                  name="number"
                  component="input"
                  type="text"
                  pattern="[\d| ]{16,22}"
                  placeholder={intlMessages['ccard.form.number.label']}
                  validate={required}
                  format={formatCreditCardNumber}
                />
              </div>
              <div>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder={intlMessages['ccard.form.name.label']}
                  validate={required}
                />
              </div>
              <div>
                <Field
                  name="expiry"
                  component="input"
                  type="text"
                  pattern="\d\d/\d\d"
                  placeholder={intlMessages['ccard.form.expiry.label']}
                  validate={required}
                  format={formatExpirationDate}
                />
                <Field
                  name="cvc"
                  component="input"
                  type="text"
                  pattern="\d{3,4}"
                  placeholder={intlMessages['ccard.form.cvc.label']}
                  validate={required}
                  format={formatCVC}
                />
              </div>
              <div className="buttons">
                <StyledPayButton type="submit" disabled={submitting || !Omise.current}>
                  {intlMessages['settings.details.payments.addcard.text']}
                </StyledPayButton>
              </div>
            </form>
          )
        }}
      />
    </Styles>
  )
}
