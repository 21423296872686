import styled from 'styled-components';
import { StyledButton } from '../_styles/forms';

export const StyledLoadingText = styled.div`
  margin-top: 20px;
`;

export const StyledHowToContent = styled.div`
  padding: 20px 0;
`;

export const StyledLoadingHeader = styled.div`
  margin-top: 10px;
  font-size: 110%;
  font-family: Proxima Nova Semibold;
`;

export const StyledActions = styled.div`
  padding-top: 20px;
`;

export const StyledLoadingContent = styled.div`
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  width: 350px;
  padding: 30px 20px;

  ${StyledLoadingHeader} ~ ${StyledLoadingText}{
    padding: 0 6px;
    line-height: 20px;
  }

  ${StyledActions}{
    display:flex;
    justify-content: flex-end;
    width: 100%;

    ${StyledButton}{
      width: fit-content;
      margin-left: 10px;
      min-width: unset;
      margin-right: 0;
    }
  }

`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  z-index:5;
  padding-right: 15px;
  padding-top: 20px;
  &:hover{
    cursor:pointer;
    opacity: 0.9;
  }
`;
