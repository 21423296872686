import { createSelector } from 'reselect';

export const getUnreadMessageCountSlice = (state) => state.unreadMessageCounts;

export const getUnreadMessageCounts = createSelector(
  [getUnreadMessageCountSlice],
  (state) => {
    return state.byId;
  }
)

export const getTotalUnreadMessages = createSelector(
  [getUnreadMessageCounts],
  (unreadCounts) =>{
    return Object.values(unreadCounts).reduce((acc, curr) => { return acc+curr}, 0)
  }
)
