
import CariesCategory from './tools/CategoryBlock/CariesCategory';
import HabitsCategory from './tools/CategoryBlock/HabitsCategory';
import ColorCategory from './tools/CategoryBlock/ColorCategory';
import OrthoCategory from './tools/CategoryBlock/OrthoCategory';
import GumsCategory from './tools/CategoryBlock/GumsCategory';
import OthersCategory from './tools/CategoryBlock/OthersCategory';

export const READ_ONLY_EDITOR_BOX_PROPS = {
    direction: 'column',
    borderRadius: '15px',
    background: '#FFF',
    color: '#405159',
    fontWeight: 400,
    fontFamily: 'Proxima Nova',
    border: 'none',
    fontSize: '15px', 
    textAlign: 'left',
    pl: '10px',
    pr: '0px',
    pt: '0px',
    maxW: '650px',
}

export const EDITOR_BOX_PROPS = {
    direction: 'column',
    border: '1px solid rgba(54, 69, 77, 0.60)',
    borderRadius: '5px',
    background: '#FAFAFA',
    color: '#979797',
    fontWeight: 400,
    fontFamily: 'Proxima Nova',
    fontSize: '15px', 
    textAlign: 'left',
    p: '20px',
    maxW: '800px'
}
export const EDITOR_TOOLS = {
    paragraph: OthersCategory,
    cariesCategory: CariesCategory,
    colorCategory: ColorCategory,
    orthoCategory: OrthoCategory,
    habitsCategory: HabitsCategory,
    gumsCategory: GumsCategory,
}