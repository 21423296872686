import { constants } from './_constants';

const initState = {
  paymentStatus: null,
  paymentIntent: null,
  paymentProcessor: null,
  paymentInformation: null,
  checkupClinicDentist: null,
  checkupRequest: null,
  checkupRecord: null, //68 local test
}

export function checkups(state=initState, action){
  switch (action.type){
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    case constants.RESET:
      return initState;
    default:
      return state
  }
}
