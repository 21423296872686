import React from 'react';

export const CameraDefaultButton = ({video=false}) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 41C32.0457 41 41 32.0457 41 21C41 9.95431 32.0457 1 21 1C9.95431 1 1 9.95431 1 21C1 32.0457 9.95431 41 21 41Z" stroke="#273238"/>
      <path stroke={video ? "#DC4E41" : "#273238"}  fill={video && "#DC4E41"} d="M34.5564 21.0009C34.5564 28.4874 28.4874 34.5564 21.0009 34.5564C13.5143 34.5564 7.44531 28.4874 7.44531 21.0009C7.44531 13.5143 13.5143 7.44531 21.0009 7.44531C28.4874 7.44531 34.5564 13.5143 34.5564 21.0009Z" stroke-width="4"/>
    </svg>
  )
}
