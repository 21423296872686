import { constants } from './_constants';

const initState = {
  scanningPage: false,
  partialScan: false,
  detectionScan: false,
  uploadDetection: false,
  recordDetail: false,
  attachmentPloverScan: false,
  videoChat: false,
  firebaseNotifications: false,
  apnNotifications: false,
  loading: false,
  loadingText: "appstart",
  cardForm: false,
  error: false,
  errorText: 'general',
  message: false,
  messageText: '',
  profileList: false,
  profileListPage: 0,
  confirmDialog: false,
  confirmDialogHeader: '',
  confirmDialogText: '',
  confirmDialogNegativeCallback: null,
  confirmDialogPositiveCallback: null,
  confirmDialogPositiveLabel: 'OKAY'
}

export function modals(state=initState, action){
  switch(action.type){
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state
  }
}
