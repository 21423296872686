import styled , { keyframes } from 'styled-components';
import { Header } from '../PloverScan/styles';


export const StyledMediaItem = styled.div`
  border-radius:3px;
  position:relative;
  height:72px;
  width:72px;
  background-color: ${({selected}) => (selected ? '#367FFF' : 'transparent')};
  border: 1px solid ${({selected}) => (selected ? '#367FFF' : 'transparent')};
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

  video {
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    display:block;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover{
    cursor: pointer;
  }
`;


export const BlankDiv = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  border: 1px solid ${({selected}) => (selected ? '#367FFF' : 'transparent')};

  display:flex;
  justify-content: center;
  align-items:center;
`;

export const BlankMediaItem = styled.div`
  border-radius:3px;
  position:relative;
  height:72px;
  width:72px;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

  &:hover{
    cursor: pointer;
  }

  > ${BlankDiv} {
    border-radius: 3px;
    background-color: #D8D8D8;
    height:72px;
    width:72px;
  }
`;

export const StyledPartialScanButtons = styled.div`
  width: 100%;
  display:flex;
  justify-content: flex-end;
  padding-top:20px;
`;

export const StyledMediaListTitle = styled.div`
  padding-bottom: 10px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;

  color: #121719;

`;

export const StyledMediaList = styled.div`
  display:flex;

  > * {
    margin-right: 4px;
    margin-bottom: 4px;
  }
`;

export const StyledPartialScanText = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 20px;
  height:62px;
`;

export const StyledFlipButton = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  margin: 5px;
  pointer-events: all;
  z-index: 3;
  svg{
    height: 21px;
    width: 21px;
    pointer-events: none;

    path{
      fill: #FFFFFF;
      opacity:0.2;
    }
  }

  &:hover{
    cursor:pointer;
  }
`;


export const StyledPartialScanDirection = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1.38461px;
  color: #FFFFFF;
  opacity:0.2;
`;

export const StyledPartialScanChartTeeth = styled.div`
  display:flex;
  position:relative;
  justify-content:center;

  ${StyledPartialScanDirection}:first-child{
    margin-right: 5px;
    justify-content: flex-end;
  }

  ${StyledPartialScanDirection}:last-child{
    margin-left: 5px;
    justify-content: flex-start;
  }
`;

export const StyledPartialScanChart = styled.div`
    background: #273238;
    border-radius: 5px;
    padding: 20px;
    padding-top: 50px;
    width: 350px;
`;

export const StyledPartialScanColumn = styled.div`
padding-left: 15px;
padding-right: 20px;

${Header} {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 1px;
  color: #121719;
}

`;

export const PartialScanMediaListSection = styled.section`
    width: 100%;
    display: flex;
    flex-direction:column;
    padding: 0 20px;
`;

export const StyledPartialScan = styled.div`
    width: 1080px;
    padding: 20px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
`;
