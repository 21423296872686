import React, {useState, useRef, useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector, useDispatch } from 'react-redux';
import {
  upperFirst, toUpper
} from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map'
import { motion } from 'framer-motion';
import { constants as reportConstants } from '../../Reports/_constants';

import { actions as modalsActions } from '../../Modals/_actions';
import { actions as authenticationActions } from '../../Authentication/_actions';

import { actions as checkupActions } from '../_actions';
import { services as checkupServices } from '../_services';

import { getPayments, getDefaultPayment} from '../../Authentication/_selectors';
import { getPaymentInformation } from '../_selectors';

import { FaCcMastercard, FaCcVisa, FaCcJcb, FaCreditCard} from 'react-icons/fa';
import { getIntlMessages, getPaymentsLoading } from '../../Authentication/_selectors';

import { LoadingEllipses } from '../../_components/LoadingEllipses';


import MCLogo from '../../_images/MasterCard_light.png'
import VisaLogo from '../../_images/Visa_light.png'
import JCBLogo from '../../_images/JCB_light.png'


import { ErrorLabel } from '../../_styles/forms';
import {
  StyledOmisePayment,
  StyledOmiseCard,
  StyledPriceDetails,
  StyledChangeCardLink,
  StyledPayButton,
} from './styles';

import './index.css';

const OmiseCardBrand = ({brand}) => {
  if (brand == "Visa") {
    return (<img src={VisaLogo} />)
  } else if (brand == "MasterCard"){
    return (<img src={MCLogo} />)
  } else if (brand == "JCB"){
    return (<img src={JCBLogo} />)
  } else {
    return (<FaCreditCard />)
  }
}

const OmiseCard = ({card, handleClick}) => {
  const clickCard = () => {
    if (handleClick){
      handleClick(card.id)
    }
  }
  if (card){
    return(
      <StyledOmiseCard onClick={clickCard}>
        <div className="cardIcon">
          <OmiseCardBrand brand={card.brand} />
        </div>
        <div className="cardDetails">
          <div className="cardNumber">{'************  '}<span>{card.last_digits}</span></div>
          <div className="cardExp">{'exp. '}<span>{`${card.expiration_month}/${card.expiration_year.toString().slice(-2)}`}</span></div>
        </div>
      </StyledOmiseCard>
    )
  } else {
    return(
      <div></div>
    )
  }
}

const OmiseCardList = ({handleClick, handleAddClick}) => {
  const dispatch = useDispatch();
  const cards = useSelector(getPayments);
  const intlMessages = useSelector(getIntlMessages);

  const openCardForm = () => {
    dispatch(modalsActions.openModal('cardForm'));
  }
  return (
    <React.Fragment>
      { cards.map(item => <OmiseCard key={item.id} handleClick={handleClick} card={item} />)}

      <StyledChangeCardLink onClick={openCardForm}>
        <span>{toUpper(intlMessages['checkup.paymentform.addCard'])}</span>
      </StyledChangeCardLink>
    </React.Fragment>
  )
}

const ChangeCardLink = ({handleClick}) => {
  const intlMessages = useSelector(getIntlMessages);

  return(
    <StyledChangeCardLink onClick={handleClick}>
      <span>{toUpper(intlMessages['checkup.paymentform.addCard.text'])}</span>
    </StyledChangeCardLink>
  )

}

const OmiseCurrentCard = ({selectedCard}) => {
  const cards = useSelector(getPayments);
  if (selectedCard){
    return (
      cards.filter(item => item.id == selectedCard ).map(elem => <OmiseCard card={elem} /> )
    )
  } else if (selectedCard == null){
    return(
      cards.filter(item => item.default == true ).map(elem => <OmiseCard card={elem} /> )
    )
  } else {
    return(
      <OmiseCard card={null} />
    )
  }
}

const PriceDetails = () => {
  const info = useSelector(getPaymentInformation)
  const intlMessages = useSelector(getIntlMessages);

  // price. thb and usd use decimals for the number. i.e. 33000 -> 330.00
  let adjPrice = info?.price_data?.total;
  if (["USD", "THB"].indexOf(toUpper(info?.price_data?.currency)) != -1){
    adjPrice = info?.price_data?.total/100
  }
  return(
    <StyledPriceDetails>
      <h1>
        {(info && info.price_data && info.price_data.total > 0) ? intlMessages[`checkup.paymentform.price.${info.price_data.type}`].replace("{price}", getSymbolFromCurrency(toUpper(info.price_data.currency)) + " " +
          (adjPrice).toLocaleString(navigator.language, {
            minimumFractionDigits: (["USD", "THB"].indexOf(toUpper(info.price_data.currency)) != -1) ? 2 : 0,
          }))
          : intlMessages['checkup.paymentform.loading']
        }
      </h1>
      <h4>
        {intlMessages['checkup.paymentform.header']}
      </h4>
    </StyledPriceDetails>
  )
}

export const OmisePaymentForm = ({amount}) => {
  const dispatch = useDispatch();
  const [initializing, setInitializing ] = useState(true);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [formClosed, setFormClosed] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [submitError, setSubmitError ] = useState(null);
  const [page, setPage] = useState(0);
  const formRef = useRef();
  const omiseRef = useRef();
  const cardRef = useRef();
  const OmiseCardRef = useRef();

  const defaultPayment = useSelector(getDefaultPayment);
  const paymentInformation = useSelector(getPaymentInformation);
  const cards = useSelector(getPayments);
  const isPaymentsLoading = useSelector(getPaymentsLoading);
  const intlMessages = useSelector(getIntlMessages);

  useEffect(()=>{
    dispatch(authenticationActions.getPayments());
    omiseRef.current = document.createElement('script');
    omiseRef.current.src = "https://cdn.omise.co/omise.js.gz";
    omiseRef.current.async = true;
    omiseRef.current.onload = () => {
      OmiseCardRef.current = window.OmiseCard;
      OmiseCardRef.current.configure({
        publicKey: process.env.REACT_APP_TH_OMISE_CLIENT_ID ,
        image: process.env.REACT_APP_PUBLIC_URL + '/logo.png',
      })
      console.log(OmiseCardRef.current);
      setInitializing(false);
    }
    document.body.appendChild(omiseRef.current);

    return () => {
      omiseRef.current && document.body.removeChild(omiseRef.current);
      document.getElementById("omise-checkout-iframe-app") && document.getElementById("omise-checkout-iframe-app").remove();
    }
  }, [])

  useEffect(()=>{
    if (!isPaymentsLoading && defaultPayment){
      setSelectedCard(defaultPayment.id);
    }
  }, [isPaymentsLoading, defaultPayment])

 const addCard = () => {
   if (OmiseCardRef.current){
     OmiseCardRef.current.open({
       amount: amount,
       locale: 'en',
       location: 'yes',
       buttonLabel: intlMessages['checkup.paymentform.addCard.button'],
       submitLabel: intlMessages['checkup.paymentform.addCard.button'],
       frameLabel: intlMessages['checkup.paymentform.addCard.omise.label'],
       hideAmount: 'true',
       frameDescription: intlMessages['checkup.paymentform.addCard.omise.description'],
       onCreateTokenSuccess: (nonce) => {
         handleAddCard(nonce);
       },
       onFormClosed: () => {
         /* Handler on form closure. */
         handleFormClose();
       }
     })
   }
 }

 const handleSubmit = async () => {
   setSubmitError(null);
   if (!selectedCard ){
     setSubmitError(intlMessages['checkup.paymentform.error.emptycard'])
     return;
   }
   if (!paymentInformation || paymentInformation.id == null){
     setSubmitError(intlMessages['checkup.paymentform.error.payment'])
     return;
   }
   try{
      const response = await checkupServices.createOmisePayment({"card": selectedCard, "payment": paymentInformation.id })
      // update request id
      if (response.request){
        // setPaymentIntent normally accepts a request model, maybe a HACK
        dispatch(checkupActions.setPaymentIntent({id: response.request, payment: response}))
      } else {
        setSubmitError(intlMessages['checkup.paymentform.error.request'])
      }
   } catch (error){
     setSubmitError(intlMessages['checkup.paymentform.error.request'])
   }
 }

  const handleAddCard = async (ev) => {
    setProcessing(true);
    // TODO: call our api and confirm payment. response.status == "sucessful"

  }

  const openCardList = () => {
    setPage(1);
  }

  const selectCard = (cardId) => {
    setSelectedCard(cardId)
    setPage(0);
  }

  const handleFormClose = () => {
    setFormClosed(true);
  }

  if (paymentInformation){
    return (
      <React.Fragment>
        <StyledOmisePayment isShowing={page == 0}>
          <PriceDetails />
          <div>{intlMessages['checkup.paymentform.selectedcard']}</div>
          {
            (isPaymentsLoading)
              ? <LoadingEllipses />
              : <OmiseCurrentCard selectedCard={selectedCard} />
          }
          <ChangeCardLink handleClick={openCardList}/>
          <ErrorLabel isError={submitError}>{submitError}</ErrorLabel>
          { (paymentInformation && paymentInformation.status != "successful" && paymentInformation.authorize_uri)
            ? <motion.div animate={{scale: [1, 1.05, 1, 1.05, 1, 1.05, 1]}} transition={{ delay: 1.3, reapeat: Infinity }}><a href={paymentInformation.authorize_uri}><StyledPayButton>{intlMessages['checkup.paymentform.button.confirm.omise']}</StyledPayButton></a></motion.div>
            : <StyledPayButton disabled={!selectedCard} onClick={handleSubmit}>{intlMessages['checkup.paymentform.button.pay']}</StyledPayButton>

          }
        </StyledOmisePayment>
        <StyledOmisePayment isShowing={page == 1}>
          <OmiseCardList handleClick={selectCard} handleAddClick={addCard}/>
        </StyledOmisePayment>
      </React.Fragment>
    )
  } else {
    return(
      <div style={{padding: 50}}>
        {processing && <div>{intlMessages['checkup.paymentform.processing']}</div>}
        {intlMessages['checkup.paymentform.omise.loading']}
        <form ref={formRef} method="POST" onSubmit={handleSubmit}>
        </form>
      </div>
    )
  }
}
