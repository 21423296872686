import { constants } from './_constants';

export const actions = {
  setPloverDeviceId,
  updateState,
  updateStateObject
}

function setPloverDeviceId(id){
  return (dispatch) => {
    dispatch(update(id));
  }

  function update(id){
    return { type: constants.SET_PLOVERDEVICEID, data: id}
  }
}

function updateState(key, value){
  return (dispatch) => {
    dispatch(update(key, value));
  }
  function update(key, value){
    return { type: constants.UPDATE_STATE, data: {key, value}}
  }
}

function updateStateObject(key, value){
  return (dispatch) => {
    dispatch(update(key, value));
  }
  function update(key, value){
    return { type: constants.UPDATE_STATE_OBJECT, data: {key, value}}
  }
}
