import { constants } from './_constants';

const initState ={
  loading: false,
  error: false,
  loadingToken: false,
  errorToken: false,
  currentVideoChat: null,
  currentVideoChatToken: null,
  byId: {}
}

export function videoChats(state = initState, action){
  switch (action.type) {
    case constants.FETCHVIDEOCHAT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      }
    case constants.FETCHVIDEOCHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        currentVideoChat: action.data.id
      }
    case constants.FETCHVIDEOCHAT_FAILURE:
      return {
          ...state,
          loading: false,
          error: "err"
        }
    case constants.FETCHAPPOINTMENTS_REQUEST:
      return {
        ...state,
        appointmentsLoading: true,
        appointmentsError: ""
      }
    case constants.FETCHAPPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointmentsLoading: false,
        appointmentsError: "",
        byId: Object.assign({}, state.byId, action.data.byId)
      }
    case constants.FETCHAPPOINTMENTS_FAILURE:
      return {
          ...state,
          appointmentsLoading: false,
          appointmentsError: "err"
        }

    case constants.FETCHVIDEOCHATS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      }
    case constants.FETCHVIDEOCHATS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        byId: Object.assign({}, state.byId, action.data.byId)
      }
    case constants.FETCHVIDEOCHATS_FAILURE:
      return {
          ...state,
          loading: false,
          error: "err"
        }
    case constants.FETCHVIDEOCHATTOKEN_REQUEST:
      return {
        ...state,
        loadingToken: true,
        errorToken: ""
      }
    case constants.FETCHVIDEOCHATTOKEN_SUCCESS:
      return {
        ...state,
        loadingToken: false,
        errorToken: "",
        currentVideoChatToken: action.data
      }
    case constants.FETCHVIDEOCHATTOKEN_FAILURE:
      return {
          ...state,
          loadingToken: false,
          errorToken: "err"
        }
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state
  }
}
