export const joyrideStyles = {
    options: {
        arrowColor: '#273238',
        backgroundColor: '#273238',
        beaconSize: 36,
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#44C5A6',
        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        textColor: '#FFF',
        width: undefined,
        zIndex: 100,
        borderRadius: '10px'
    },
    buttonNext: {
        borderRadius: '30px',
        color: '#273238',
        padding: '8px 20px'
    },
    tooltip: {
        borderRadius: '10px',
        padding: '20px 30px'
    }
  };

export const joyrideOptions = {
    continuous: true,
    showProgress: true, 
    hideBackButton: true,
    scrollToFirstStep: true
}