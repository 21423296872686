import { TOOTH_NUMBERING_SYSTEM, REGEX_ONLY_TOOTH, REGEX_ONLY_REGION,
  RECORD_TYPES, DEVICE_NAME, DEVICE_NAME_DESKTOP  } from '../_config';
import React, {useEffect, useState, useRef} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import ReactPlayer from 'react-player';
import { toUpper } from 'lodash';

// components
import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { PloverCamera, NoCamera, UploadProgress } from '../PloverCamera';
import { PartialScanTeeth } from '../_components/PartialScanTeeth';
import { FaTimes } from 'react-icons/fa';
import { MdSwapHoriz } from 'react-icons/md';

// redux state
import { actions } from './_actions';
import {
  getCurrentPartialScanId,
  getIsComplete,
  getSelectedRegion,
  getRegionsById,
  getRegionsList,
  getCurrentPartialScanData,
  getCurrentPartialScanRegion,
  getReviewOpen,
  getSortedRegionList,
  getIsReadyToUpload,
  getPartialScansToUpload
} from './_selectors';

// actions
import { actions as cameraActions } from '../PloverCamera/_actions';
import { actions as mediaActions } from '../MediaUploader/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { actions as scanActions } from '../PloverScan/_actions';

// selectors
import { getIntlMessages } from '../Authentication/_selectors';
import { getPloverDeviceId } from '../PloverCamera/_selectors';

//styles
import {
  FlexBox,
  NextButton,
  ScanCameraSection,
  Header,
  StyledVideoFrame,
  StyledRecordedStreamReview,
  StyledRecordedStreamReviewOverlay
} from '../PloverScan/styles';

import {
  RemoveButton
} from '../MediaUploader/styles';

import {
  StyledPartialScanColumn,
  StyledPartialScan,
  StyledPartialScanDirection,
  StyledPartialScanChartTeeth,
  StyledPartialScanChart,
  StyledPartialScanText,
  PartialScanMediaListSection,
  StyledMediaListTitle,
  StyledPartialScanButtons,
  StyledMediaList,
  StyledMediaItem,
  StyledFlipButton,
  BlankMediaItem,
  BlankDiv
} from './styles';


let DEFAULT_NUMBERING = "international";

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const RecordedStreamReview = ({item,  handleClose, handleRedo}) => {
  const intlMessages = useSelector(getIntlMessages);
  if (item.status){
    return(
      <StyledRecordedStreamReview>
        <RecordUploadMedia
          upload={item}
          imgWidth={640}
          imgHeight={480}
          imgBorderRadius={3}
          play={true}
        />
        <StyledRecordedStreamReviewOverlay>
          <div className={'reviewTitle'}>{intlMessages['checkup.partialscan.review.label']}</div>
          <div className={'closeBtn'} onClick={handleClose}>Close</div>
        </StyledRecordedStreamReviewOverlay>
        <UploadProgress />
      </StyledRecordedStreamReview>
    )
  }
  if (item.mimeType.startsWith("video")){
    return (
      <StyledRecordedStreamReview>
        <ReactPlayer
            url={item.objectUrl || item.upload}
            muted={true}
            controls={true}
            width={'640px'}
            height={'480px'}
          />
            <StyledRecordedStreamReviewOverlay>
              <div className={'reviewTitle'}>{intlMessages['checkup.partialscan.review.label']}</div>
              <div className={'closeBtn'} onClick={handleClose}>Close</div>
            </StyledRecordedStreamReviewOverlay>
            <UploadProgress />
      </StyledRecordedStreamReview>
    )
  } else if (item.mimeType.startsWith("image")){
    return (
      <StyledRecordedStreamReview>
        <img
            src={item.objectUrl || item.upload}
            width={'640px'}
            height={'480px'}
          />
            <StyledRecordedStreamReviewOverlay>
              <div className={'reviewTitle'}>{intlMessages['checkup.partialscan.review.label']}</div>
              <div className={'closeBtn'} onClick={handleClose}>Close</div>
            </StyledRecordedStreamReviewOverlay>
            <UploadProgress />
      </StyledRecordedStreamReview>
    )
  } else {
    return null;
  }
}

const PartialScanActions = () => {
  return(
    <div></div>
  )
}

const MediaItem = ({region, regionData}) => {
  const currentRegion = useSelector(getSelectedRegion);

  const dispatch = useDispatch();

  const handleClick = () => {
    if (region){
      if (regionData.data || regionData.upload){
      dispatch(actions.toggleReview(true));
      } else {
        dispatch(actions.toggleReview(false));
      }
      dispatch(actions.selectRegion(region))
    }
  }

  const handleRemove = () => {
    dispatch(actions.toggleReview(false));
    dispatch(actions.removePartialScanImage(region));
  }

  if (regionData.status && regionData && regionData.upload){
    return (
      <RecordUploadMedia
        upload={regionData}
        imgWidth={72}
        imgHeight={72}
        imgBorderRadius={3}
        play={true}
      />
    )
  } else if (!(regionData.status || regionData.data)){
    return (
      <BlankMediaItem
        onClick={handleClick}
      >
        <BlankDiv
          selected={currentRegion == region}>
          {TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][region.match(REGEX_ONLY_TOOTH)]
            + "-" +
            region.match(REGEX_ONLY_REGION) }
        </BlankDiv>
      </BlankMediaItem>
    )
  } else if (regionData.mimeType.startsWith("video")){
    return (
      <StyledMediaItem
        selected={currentRegion == region}
        onClick={handleClick}
        >
        {(regionData.upload || regionData.objectUrl) && <RemoveButton onClick={handleRemove}><FaTimes /></RemoveButton>}
        <video
            src={regionData.upload || regionData.objectUrl}
          />
      </StyledMediaItem>
    )
  } else if(regionData.mimeType.startsWith("image") ) { // should be image
    return (
      <StyledMediaItem
        selected={currentRegion == region}
        onClick={handleClick}
        >
        {(regionData.upload || regionData.objectUrl) && <RemoveButton onClick={handleRemove}><FaTimes /></RemoveButton>}
        <img
            src={regionData.upload || regionData.objectUrl}
          />
      </StyledMediaItem>
    )
  } else {
    return null;
  }
}

export const PartialScanMediaList = () => {
  const regionsList = useSelector(getSortedRegionList);
  const regionData = useSelector(getRegionsById);

  return (
    <StyledMediaList>
      {regionsList.map( elem => {
        return (
          <MediaItem
            region={elem}
            regionData = {regionData[elem]}
          />
        )
      })}
    </StyledMediaList>
  )
}

export const PartialScanChartTeeth = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentRegion = useSelector(getSelectedRegion);
  const regionsList = useSelector(getRegionsList);
  const regionData = useSelector(getRegionsById);
  const [ chartView, setChartView ] = useState(localStorage.getItem('chartView'))

  let charting = {};
  let numberCharting = {};

  const handleFlipped = () => {
    localStorage.setItem('chartView', chartView == 'd' ? null : 'd');
    setChartView(old => (old == 'd') ? null : 'd');
  }

  regionsList.map(elem => {
    let number = elem.match(REGEX_ONLY_TOOTH);
    let currClass = '';
    let numClass = '';
    numClass = 'INIT';
    if ((regionData && regionData[elem]) && (regionData[elem].status || regionData[elem].data)){
      currClass += 'FILLED';
    } else if ((regionData && regionData[elem])  && regionData[elem].status == false){
      currClass += 'INIT';
    }
    /*if (regionData[elem].status || regionData[elem].data){
      if (numberCharting[number]){
        numClass += (numberCharting[number] && (numberCharting[number] == 'FILLED' || numberCharting[number] == 'FILLEDSELECTED')) ? numberCharting[number]: 'INIT';
      } else {
        numClass += (numberCharting[number] && (numberCharting[number] == 'INIT' || numberCharting[number] == 'INITSELECTED')) ?  numberCharting[number] : 'INIT';
      }
    } else {
      numClass += (numberCharting[number] && (numberCharting[number] == 'INIT' || numberCharting[number] == 'INITSELECTED')) ?  numberCharting[number] : 'INIT';
      //numClass = (numberCharting[number] && (numberCharting[number] == 'FILLED' || numberCharting[number] == 'FILLEDSELECTED'))  ? 'FILLED' : 'INIT';
    }*/
    if (currentRegion == elem) {
      currClass += 'SELECTED';
      //numClass += 'SELECTED';
    }
    charting[elem] =  currClass;
    numberCharting[number] = numClass;
  })

  const clickRegion = (region) => {
    dispatch(actions.selectRegion(region));
  }


  return (
    <StyledPartialScanChartTeeth>
      <StyledFlipButton onClick={handleFlipped}>
        <MdSwapHoriz />
      </StyledFlipButton>
      <StyledPartialScanDirection>
        {intlMessages[`checkup.fullscan.chart.${chartView != "d" ? 'left' : 'right'}`]}
      </StyledPartialScanDirection>
      <PartialScanTeeth
        charting={charting}
        numberCharting={numberCharting}
        numberingSystem="international"
        isFlipped={chartView == "d"}
        onClick={clickRegion}
        />
      <StyledPartialScanDirection>
        {intlMessages[`checkup.fullscan.chart.${chartView != "d" ? 'right' : 'left'}`]}
      </StyledPartialScanDirection>
    </StyledPartialScanChartTeeth>
  )
}

export const PartialScanStudio = ({onScanFinished, type}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  let [ uploadState, setUploadState ] = useState(-1);
  const currentDeviceId = useSelector(getPloverDeviceId);
  const currReg = useSelector(getCurrentPartialScanRegion);
  const isReviewOpen = useSelector(getReviewOpen);
  const noMoreUploads = useSelector(getIsComplete);
  const toUpload = useSelector(getPartialScansToUpload);
  const readyToUpload = useSelector(getIsReadyToUpload);
  const regionList = useSelector(getSortedRegionList);

  let options = {mimeType: 'video/webm', videoBitsPerSecond: 5000000}; //150,000,000 18.75Mbps, 50,000,000 6.25Mbps

  useEffect(() =>{
    // unmount
    return () => {};
  }, [])

  useEffect(() => {
    // select initial region on load
    if (regionList.length && currReg == null && !noMoreUploads){
      dispatch(actions.selectRegion(regionList[0]));
    }
  }, [regionList])

  const closeReview = () => {
    dispatch(actions.toggleReview(false));
  }

  const startUpload = () => {
    setUploadState(0);
    dispatch(actions.uploadAllPartialScan(toUpload))
  }

  const closeModal = () => {
    dispatch(modalActions.closeModal('partialScan'))
  }

  useEffect(() => {
    // trigger something on finish upload
    if (noMoreUploads.length == 0 && uploadState == 0){
      // uploads done and uplaods started 0 (not init -1)
      setUploadState(1);
      onScanFinished();
      console.log("nomore upload length ", noMoreUploads.length)
    }
  }, [noMoreUploads.length] )


  if(MediaRecorder.isTypeSupported('video/mp4')){
    options = Object.assign({}, options, {mimeType: 'video/mp4'} )
  } else if (MediaRecorder.isTypeSupported("video/webm\;codecs=h264")){
    options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=h264'} )
  } else if(MediaRecorder.isTypeSupported('video/webm\;codecs=vp9')){
    options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=vp9'} )
  } else if(MediaRecorder.isTypeSupported('video/webm\;codecs=vp8')){
    options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=vp8'} )
  } else if(MediaRecorder.isTypeSupported('video/webm')){
    options = Object.assign({}, options, {mimeType: 'video/webm'} )
  } else{
    options = Object.assign({}, options, {mimeType: null} );
    // probably throw an error here. or check options null in video
  }

  return (
      <StyledPartialScan>
        <ScanCameraSection>
          <StyledVideoFrame>
            <Header>{intlMessages['checkup.partialscan.title']}</Header>
            {
              (isReviewOpen && ((!currReg.status && currReg.objectUrl) || (currReg.status && currReg.upload))) &&
              <RecordedStreamReview
                item={currReg}
                handleClose={closeReview}
              />
            }
            { currentDeviceId
              ? <PloverCamera
                  mediaRecorderOptions={options}
                  deviceId={currentDeviceId}
                />
              : <NoCamera />
            }
          </StyledVideoFrame>
          <StyledPartialScanColumn>
            <Header>{'Charting'}</Header>
            <StyledPartialScanChart>
              <PartialScanChartTeeth />
              <StyledPartialScanText>
                {intlMessages['checkup.partialscan.scans.description']}
              </StyledPartialScanText>
            </StyledPartialScanChart>
          </StyledPartialScanColumn>
        </ScanCameraSection>
        <PartialScanMediaListSection>
          <StyledMediaListTitle>
            {intlMessages['checkup.partialscan.scans.title']}
          </StyledMediaListTitle>
          <PartialScanMediaList />
        </PartialScanMediaListSection>
        <StyledPartialScanButtons>
          <FlexBox>
            <NextButton onClick={startUpload} disabled={noMoreUploads.length == 0 || readyToUpload.length > 0} >{toUpper(intlMessages['checkup.fullscan.buttons.send'])}</NextButton>
          </FlexBox>
        </StyledPartialScanButtons>
      </StyledPartialScan>
  )

}

export const PartialScan = ({closeModal, onScanFinished}) => {
  const dispatch = useDispatch();
  const partialScanId = useSelector(getCurrentPartialScanId);
  const [checkMediaDevices, setCheckMediaDevices ] = useState(false);
  const currentDeviceId = useSelector(getPloverDeviceId);
  const currentDeviceName = useRef(null);

  useEffect(()=>{
    if (partialScanId){
      dispatch(scanActions.setScanType(RECORD_TYPES.PARTIALSC));
      dispatch(actions.fetchPartialScan(partialScanId))
    }
    return () => {};
  }, [partialScanId])


  const onDevicesChanged = (event) => {
    setCheckMediaDevices(!checkMediaDevices);
  }

  const updateDeviceId = (deviceId) => {
    dispatch(cameraActions.setPloverDeviceId(deviceId))
  }

  const connectDevice = (deviceId) => {

  }

  const disconnectDevice = (deviceId) => {
    dispatch(cameraActions.setPloverDeviceId(deviceId))
  }

  const handleSuccess = () => {
    // TODO continue on?
  }

  const handleError = (err) => {
    //TODO dialog explaining how permissions work
    console.log("ERROR:", err);
    alert("need to give permission for camera: ");
  }

  useEffect(()=>{
    async function connectPlover(devId){
      // NOTE need to wait for plover to start up, or there is error redaing the stream
      dispatch(cameraActions.updateState('awaitingDevice', true));
      await timeout(2000);
      dispatch(cameraActions.updateState('awaitingDevice', false));
      updateDeviceId(devId);
    }
    const videoConstraints = { video: true};
    if (navigator && navigator.mediaDevices){
      if (navigator.mediaDevices.getUserMedia){
        // handle permissions
        navigator.mediaDevices.getUserMedia(videoConstraints).then(
          ()=>{
          navigator.mediaDevices.ondevicechange = onDevicesChanged;
          let deviceId = null;
          navigator.mediaDevices
            .enumerateDevices()
            .then(r => {
              console.log("DEBUG DEVICES: ", r)
              for (var i =0;i < r.length; i++){
                if (r[i].label.startsWith(DEVICE_NAME)){
                  deviceId = r[i].deviceId;
                  currentDeviceName.current = r[i].label;
                }
              }
              if (currentDeviceId && !deviceId){ // disconnected plover
                updateDeviceId(null);
              } else if (!currentDeviceId && deviceId){ // connected plover
                connectPlover(deviceId)
              } else {
                // nothing to do with plover. other devices connected/disconnected
              }
            })

          }
        ).catch(
          err => handleError(err)
        )
      }
    }
  }, [checkMediaDevices])

  return(
    <PartialScanStudio
      onScanFinished={onScanFinished}
      />
  )
}
