import { RECORD_TYPES } from '../_config';
import styled from 'styled-components';
import { Wrapper as BaseWrapper, Button } from '../_styles/common';
import { StyledPloverCamera } from '../PloverCamera/styles'
import { StyledMediaList } from '../MediaUploader/styles';
import { StyledTidbit } from './InformationTidbits';
import {  StyledRawButton } from '../_styles/forms';
import { FlexColumn, FlexRow } from '../_styles/common';
import {motion} from 'framer-motion';

export const Wrapper = styled(BaseWrapper)`
  width: 100%;
  margin: auto;
`;

export const FlexBox = styled.div`
  display:flex;
`;

export const Header = styled.div`
  font-size: 15px;
  font-family: Proxima Nova Semibold;
  padding: 10px 0;
`;

export const CancelButton = styled.div`
  background: #4D5666;
  border-radius: 103px;
  width:130px;
  min-width: fit-content;
  height: 36px;
  margin-right: 10px;
  text-align:center;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  &:disabled{
    &:hover{
      cursor:default;
    }
    opacity: 0.2;
  }
  &:hover{
    cursor:pointer;
  }
`;

export const NextButton = styled(Button)`
  background: #273238;
  border-radius: 103px;
  width:130px;
  min-width: fit-content;
  height: 36px;

  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  letter-spacing: revert;
  &:disabled{
    opacity: 0.2;
  }
`;

export const ChartIcon = styled.div`
  margin: 5px;
  margin-right: 15px;
  pointer-events: all;
  z-index: 3;
  svg{
    height: 21px;
    width: 21px;
    pointer-events: none;

    path{
      fill: #ffffff;
      opacity:0.8;
    }
  }

  &:hover{
    cursor:pointer;
  }
`;

export const MediaIcon = styled.div`
  margin: 5px;
  margin-right: 15px;
  pointer-events: all;
  z-index: 3;
  svg{
    height: 21px;
    width: 21px;
    pointer-events: none;

    path{
      fill: #121719;
      opacity:0.8;
    }
  }

  &:hover{
    cursor:pointer;
  }
`;

export const StyledSelectedChartReview = styled.div`
  background-color: rgba(239,239,239,0.9);
  width:100%;
  height: 540px;
  position:absolute;
  z-index: 4;

  .chartReviewHeader{
    font-size: 18px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 30px;
    text-align:left;
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  .chartReviewChart{
    width:100%;

  }

  .chartReviewActions{
    display:flex;
    justify-content: flex-end;
    ${StyledRawButton}{
      margin: 10px;
      min-width: 120px;
    }
    > div {
      font-size: 16px;
      font-family: Proxima Nova Semibold;
      padding: 20px 30px;
      text-transform: capitalize;

      &:hover{
        cursor:pointer;
      }

      &.isDisabled{
        pointer-events: none;
        opacity: 0.2;
        &:hover{
          cursor:default;
        }
      }
    }
  }
`;

export const StyledWaitingConnection = styled.div`

  background: #F7F7F7;
  padding: 20px 40px;

  .captureButtonTidbit{
    width: 550px;
    padding: 10px 50px 0 50px;
  }

  .loadingRow{
    display:flex;
    justify-content: space-around;
    padding: 20px 0;
    align-items:center;

    &.error{
      color: red;
    }

    .loadingIcon{
      margin-left: 15px;
    }

    .helpIcon{
      margin-left: 10px;
      &:hover{
        cursor:pointer;
      }
    }

    ${Button}{
      margin-left: 15px;
      width: 100px;
      height: 30px;
      line-height: unset;
    }
  }
`;

export const StyledScanOptions = styled.div`
  width: 800px;
  padding: 20px 30px;
  background: #EFEFEF;

  .header{
    display:flex;
    justify-content: center;
    font-family: Proxima Nova Bold;
    font-size: 110%;
    margin-bottom: 15px;
    text-align:center;
  }

  .scanInformation{

    ${FlexColumn}{
        width: 50%;
        justify-content: space-between;
    }

    .actions{
      display:flex;
      justify-content:center;
    }
  }

`;

export const StyledProfileOptions = styled.div``;

export const StyledRecordedStreamReviewOverlay = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position:absolute;
  top:0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #00000021;
  color: white;
  align-items:center;

  > .reviewTitle{
    margin-left: 10px;
  }

  > .closeBtn {
    margin-right: 10px;
    padding: 5px 10px;
    color:white;
    border-radius: 20px;
    background: #00000021;
    &:hover{cursor:pointer;}
  }
`;

export const StyledRecordedStreamReview = styled(motion.div)`
  position:relative;
  height: 540px;

  &.FULLSCAN{
    height: unset;
  }
  background-color: #efefef;

  video{
    border: 4px solid #273238;
    border-top-width: 3px;
    border-bottom-width: 3px;
   
  }

    img, {
      vertical-align:middle;
      box-sizing: border-box;
      border: 4px solid #273238;
    }

  .streamReviewActions{
    display:flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items:center;
    padding-right: 10px;
    ${StyledRawButton}{
      margin: 10px;
      min-width: 120px;
    }
  }
`;

export const VideoLoading = styled.div`

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 640px;
  height: 480px;
  color: white;
  background-color: #273238;
  display:flex;
  align-items:center;
  justify-content: center;
  margin-bottom: 45px;
`;

export const FrameWrapper = styled.div`
  height: 540px;
  width: 640px;
`;

export const StyledVideoFrame = styled.div`
  position:relative;
  > ${Header} {
    font-family: Proxima Nova Bold;
    font-style: normal;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 1px;
    color: #121719;
  }

  ${FrameWrapper}{
  }


  ${StyledRecordedStreamReview} {
    position: absolute;
    z-index:2;
    width: 100%;
  }

  ${StyledPloverCamera} {
    vertical-align:top;
      img {
        vertical-align:middle;
      }
  }
`;

export const StyledVideoFrameOld = styled.div`
  > ${Header} {
    font-family: Proxima Nova Bold;
    font-style: normal;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 1px;
    color: #121719;
  }

  ${StyledRecordedStreamReview} {
    position: absolute;
    z-index:2;
  }

  > ${Header} + ${StyledRecordedStreamReview}  +  ${StyledPloverCamera}{
    > video, img{
      display: none;
    }
  };
  > div + div + img{
    vertical-align:middle;
    display: none;
  };
  > div + ${StyledRecordedStreamReview}  +  ${VideoLoading}{
    display: none;
    margin-bottom: 45px;
  };
  > ${Header} + ${StyledPloverCamera}{
    > video, img{
      vertical-align:middle;
      display: block;
    }
  };
`;

export const ToggleButton = styled.div`
  font-family: Proxima Nova;
  width:100px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  height: 36px;
  text-shadow: none;
  color: #36454D;
  background-color: ${props => props.toggleOn ? '#D8D8D8' : '#E5E5E5'};
  display:flex;
  justify-content: center;
  align-items: center;


  &:hover{
    cursor: ${props => props.toggleOn ? 'default' : 'pointer'};
    opacity:  ${props => props.toggleOn ? '1' : 'inherit'};
  }

  > span:hover{
      cursor: ${props => props.toggleOn ? 'default' : 'pointer'};
  }

  &:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

export const ToggleButtons = styled.div`
  display: flex;
  align-items: center;
`;

export const ScanActionsSection = styled.section`
  width: 100%;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SwitchScanButton = styled.div`
  border: 2px solid #4D5666;
  border-radius: 103px;
  width:fit-content;
  height: 36px;
  padding: 15px;
  margin-right: 10px;
  text-align:center;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #4D5666;
  &:disabled{
    &:hover{
      cursor:default;
    }
    opacity: 0.2;
  }
  &:hover{
    cursor:pointer;
  }
`;

export const StyledMediaColumn = styled.div`
  padding-left: 15px;
  padding-right: 20px;
  &.SELF{
    padding-left:5px;
    padding-right: 30px;
  }
  ${Header} {
    font-family: Proxima Nova Semibold;
    font-style: normal;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 1px;
    color: #121719;
  }

  ${Header}.SELF{
    padding-bottom: 0;
    padding-left:10px;
  }

  ${StyledMediaList}{
    width: 310px;
  }

  ${StyledMediaList}.FULLSCAN {
  }

  ${StyledMediaList}.SELF {
    width: 315px;
    padding-top: 10px;
    padding-left:10px;
    > * {
      margin: 0;
    }

    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    height: 540px;
  }
`;

export const ScanCameraSection = styled.section`
  width: 100%;
  display:flex;
  padding: 20px 20px;
  padding-top: 0px;
  padding-right: 0px;
`;
