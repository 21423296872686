import styled from 'styled-components';

export const StyledHistoryListItem = styled.li`
  &:first-child{
   margin-top: 10px; 
  }
  border-radius: ${props => props.selected ? '5px' : '0'};
  background-color: ${props => props.selected ? '#edf3f6' : 'transparent'};
  border-bottom: ${props => props.selected ? 'none' : '1px solid #efefef'};

  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 3px;
  margin-bottom: 10px;
  margin-right: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.17);
  border-radius: 5px;

  &:before {
    content:'';display:inline-block;width:9px;height:9px;border-radius:9px;
    background-color:${props => !props.isRead ? '#367fff' : '#d8d8d8' };
    visibility: hidden;
  }

  &:hover{
    cursor:${props => props.selected ? 'auto' : props.blank ? 'auto' : 'pointer'};
  }
  button.camera {
    background:#3E5159;
    border-radius:50%;border:none;
    width:30px;height:30px;
    padding:0;margin:auto;flex-grow:1;
    display:flex;justify-content:center;align-items:center;
    img{width:22px;height:22px;}

    
  }
  button.generative {
    background:#367FFF33;
    border-radius:50%;border:none;
    width:30px;height:30px;
    padding:0;margin:auto;flex-grow:1;
    display:flex;justify-content:center;align-items:center;
    img{width:30px;height:30px;
  }  
  .name {
    font-family: Proxima Nova Semibold;
    font-size:13px;line-height:17px;flex-grow:6;color:#000000;
  }
  .blank {font-size:14px;font-family:Proxima Nova Semibold;line-height:17px;color:#979797;}
  .date {
    flex-grow:1;font-size:12px;line-height:15px;color:#4d5666;
    span {margin-left:10px;}
  }
  .status {
    flex-grow:1;
    height:24px;font-size:10px;line-height:12px;
    color:#979797;
    font-family: Proxima Nova Bold;
    letter-spacing:0.5px;
    border-radius:3px;
    border:none;
    background-color:${props => props.selected ? '#ffffff' : '#ededed'};

    &.GRADE_1{
        background: #FFEDED ;
        color: #E8686D;
    }

    &.GRADE_2{
        background: #FDF8E2 ;
        color: #E9B54D;
    }

    &.GRADE_3{
        background: #E9F9F1 ;
        color: #43AC72;
    }

    &.PAYMENT_REQUIRED{
      color: #273237;
  }

  }

`;

export const StyledHistoryList = styled.ul`
  margin-top:0px;
  display:inline-block;
  width: 100%;
  height: 100%;
  max-height: 860px;
  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  li {
    display:grid;
    grid-template-columns:  0px 70px 1fr;
    align-items:center;
    padding-left:4px;
    padding-right:10px;
    border-top: 1px solid #EBF2F5;
    &:last-child {
      border-bottom: 1px solid #EBF2F5;
    }
  }
`;

export const StyledIsMore = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
  align-items:center;
  padding: 20px 0;
  font-size: 90%;
  &:hover{
    cursor: pointer;
  }
  > div{

  }
`;