import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const ReportVideoIcon = ({width=20, height=20, color="#405159", ...props}) => {
  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 20 20" color={color}  {...props}>
        <path d="M5.59009 8.04626H8.52185" stroke="currentColor" stroke-width="1.4" stroke-linejoin="bevel"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.45326 5L3 5.45156V15.5484L3.45326 16H13.0586L13.5118 15.5484V14.2402L16.3123 15.9232L17 15.5355V5.46317L16.3123 5.07677L13.5118 6.75915V5.45156L13.0586 5H3.45326ZM3.90653 5.90312H12.6053V7.3036V8.35896L13.5118 7.81322L16.0928 6.26308V14.7363L13.5118 13.1855L12.6053 12.641V13.6951V15.0969H3.90653V5.90312Z" fill="currentColor"/>
    </Icon>
  )
}