import { useSelector, useDispatch } from "react-redux";
import { 
    getDentistDetailsLoading,
    getCurrentClinic, 
    getCurrentClinicReviewsIsMore,
    getCurrentClinicReviews,
    getDentistDetailsError, 
    getDentistReviewsLoading,
    getDentistReviewsError
} from "./_selectors";
import { useRouteMatch} from 'react-router-dom';
import { actions } from './_actions';
import { StyledClinicDetailsSection, StyledReviewItem, StyledReviews } from "./styles"
import { LoadingEllipses } from "../_components/LoadingEllipses";
import { useEffect, useState } from "react";
import { GoogleMap, Marker } from '@react-google-maps/api';
import { googleMapStyler } from '../_styles/googleMapStyler';

import { toUpper } from 'lodash';
import { getCountry, getIntlLocale, getIntlMessages } from '../Authentication/_selectors';

import selectedMapIcon from '../_images/SelectedMapIcon.svg';
import dentistProfile from '../_images/dentistProfilePlaceholderImage.svg';
import Scrollbars from "react-custom-scrollbars";
import { ReviewStars } from "./ReviewStars";
import { ReviewBar, getReviewSummary } from "./ReviewBar";
import { formatTimeAgo } from "../_helpers";

const containerStyle = {
    height: '300px'
  };  

const ReviewItem = ({review}) => {
    const locale = useSelector(getIntlLocale);

    return (
        <StyledReviewItem>
            <div className="header">
                <div className="reviewer">{review.user_name}</div>
                <div className="time">{formatTimeAgo(review.updated_at, locale)}</div>
            </div>
            <div className="stars">
                <ReviewStars rating={review.rating} justify={'start'} margin={3} />
            </div>
            <div className="comment">{review.comment}</div>
        </StyledReviewItem>
    )
}

const ReviewItems = () => {
    const dispatch = useDispatch();
    const intlMessages = useSelector(getIntlMessages)
    const [offset, setOffset ] = useState(0);
    const dentist = useSelector(getCurrentClinic);
    const dentistReviews = useSelector(getCurrentClinicReviews);
    const isReviewsMore = useSelector(getCurrentClinicReviewsIsMore)
    const isLoading = useSelector(getDentistReviewsLoading);

    useEffect(()=>{
        dispatch(actions.fetchDentistReviews(dentist.id, dentist.unique_id, offset))
    }, [])

    const onLoadMore = () => {
        dispatch(actions.fetchDentistReviews(dentist.id, dentist.unique_id, offset+1))
        setOffset(offset + 1);
    }

    return (

        <StyledReviews>
        {
            (dentistReviews || []).map((review) => (
                <ReviewItem key={`review_${review.id}`} review={review} />
            ))
        }
        {
            isReviewsMore && 
            <div className="loadMore">
                {isLoading
                    ? <div className={'loading'}><LoadingEllipses /></div>
                    : <button onClick={onLoadMore} >{intlMessages['clinicSearchPage.clinicDetails.reviewSummary.reviews.loadMore']}</button>
                }
            </div>
        }
        </StyledReviews>
    )
}

const ReviewSummary = ({rating}) => {
    const intlMessages = useSelector(getIntlMessages)
    if (rating == null){
        return (
            <div className="summary">
                <div className="details">
                    <div className="empty">
                        {intlMessages['clinicSearchPage.clinicDetails.reviewSummary.empty']}
                    </div>    
                </div>
            </div>    
        )
    }

    const summary = getReviewSummary(rating);

    console.log("reviews", rating )
    console.log("reviewssummary", summary )
    return(
        <div className="summary">
            <div className="details">
                <div className="rating">{summary?.averageRating}</div>
                <div className="stars">
                    <ReviewStars rating={Math.round(summary?.averageRating)} />
                </div>
                <div className="totalReviews">{intlMessages['clinicSearchPage.clinicDetails.reviewSummary.numberOfReview'].replace("{totalCount}", summary?.totalReviews)}</div>

            </div>
            <div className="border"></div>
            <div className="bars">
                <div className="bar bar5">
                    <div>{5}</div>
                    <ReviewBar percent={summary?.reviewCounts["5"]} />
                </div>
                <div className="bar bar4">
                    <div>{4}</div>
                    <ReviewBar percent={summary?.reviewCounts["4"]} />
                </div>
                <div className="bar bar3">
                    <div>{3}</div>
                    <ReviewBar percent={summary?.reviewCounts["3"]}/>
                </div>
                <div className="bar bar2">
                    <div>{2}</div>
                    <ReviewBar percent={summary?.reviewCounts["2"]} />
                </div>
                <div className="bar bar1">
                    <div>{1}</div>
                    <ReviewBar percent={summary?.reviewCounts["1"]} />
                </div>
            </div>
        </div>
    )
}

export const ClinicDetails = ({onBackClick}) => {
    const dispatch = useDispatch();
    const intlMessages = useSelector(getIntlMessages)
    const clinicDetailRouteMatch = useRouteMatch("/createrequest/clinics/id/:clinicId");
    const clinicId = clinicDetailRouteMatch?.params?.clinicId

    useEffect(()=>{
        dispatch(actions.fetchDentistDetails(clinicId))
    }, [clinicId])

    const dentist = useSelector(getCurrentClinic);
    const isLoading = useSelector(getDentistDetailsLoading);
    const mapRegion = useSelector(getCountry)
    const intlLocale = useSelector(getIntlLocale);

    const dentistCategories = (dentist.categories || "").split(",")

    return (
        <StyledClinicDetailsSection>
            <div className="goBack" onClick={onBackClick}>{intlMessages['clinicSearchPage.clinicDetails.goBack']}</div>
            {isLoading && <div className={'loading'}><LoadingEllipses /></div>}
                <div className={'clinicDetails'}>
                    <Scrollbars autoHide>
                    <div  className="avatarColumn">
                        <div className="avatar"><img src={dentist.avatar ? dentist.avatar : dentistProfile} alt={'dentist profile'} /></div>
                        <div className="reviewList">
                            <div className="title2">{intlMessages['clinicSearchPage.clinicDetails.reviewSummary.title']}</div>
                            <ReviewSummary rating={dentist?.rating} />
                        </div>
                        <ReviewItems />
                    </div>
                    </Scrollbars>
                    <Scrollbars autoHide>
                        <div className="detailsColumn">
                            <div className="title">{dentist?.name}</div>
                            <div className="clinicName">{dentist.clinic?.name}</div>
                            
                            <div className={'specialty'}>
                                {(dentistCategories || []).map((tag) => (
                                    <div key={`tag_${tag}`}>{tag}</div>
                                ))}
                            </div>
                            <div className="description">{dentist.description ? dentist.description  : intlMessages['clinicSearchPage.clinicDetails.description.empty']}</div>
                            <div className="map">
                                <div className="title2">{intlMessages['clinicSearchPage.clinicDetails.location.title']}</div>
                                <div className="address">{dentist.clinic?.address}</div>

                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{
                                        lat: dentist.clinic?.geopoint.lat, 
                                        lng: dentist.clinic?.geopoint.lon 
                                    }}
                                    zoom={17}
                                    region={toUpper(mapRegion)}
                                    options={{ 
                                        zoomControl: true, 
                                        scaleControl: false, 
                                        mapTypeControl: false, 
                                        panControl: false, 
                                        rotateControl: false, 
                                        streetViewControl: false, 
                                        fullscreenControl: false,
                                        gestureHandling: 'none',
                                        clickableIcons: false,
                                        styles: googleMapStyler
                                    }}
                                    language={intlLocale}>
                                        <Marker
                                            icon={selectedMapIcon}
                                            position={{ 
                                                lat: dentist.clinic?.geopoint.lat, 
                                                lng: dentist.clinic?.geopoint.lon 
                                            }}
                                        />
                                </GoogleMap>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
        </StyledClinicDetailsSection>
    )
}