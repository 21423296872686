import React from 'react';
import { StyledLoadingContent, StyledLoadingText, StyledLoadingHeader} from './styles';

export const ErrorContent = ({header, text}) => {
  return (
    <StyledLoadingContent>
      <StyledLoadingHeader>{header}</StyledLoadingHeader>
      <StyledLoadingText>{text}</StyledLoadingText>
    </StyledLoadingContent>
  )
}
