import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { startCase } from 'lodash'
import { BASE_URL } from '../../_config';
import { useHistory } from 'react-router-dom';

import BeatLoader from 'react-spinners/BeatLoader';

// components
import EndCallIcon from '../../_images/EndCallIcon.svg'

// services
import { services as videoChatServices } from '../../VideoChats/_services';

// selectors

import { StyledVideoCallMessage } from './styles';
import { getIntlMessages } from '../../Authentication/_selectors';

const VideoChatMessageButton = ({
  status, toUrl, clickVideoOpen, clickPopupClose}) => {
  const intlMessages = useSelector(getIntlMessages);
  if (status === "inprogress"){
    return (
      <div className={'bottom'} onClick={clickPopupClose}>
        {intlMessages['videoChat.closePopup.button.label']}
      </div>
    )
  } else if (status === "valid"){
    return (
      <a href={toUrl} target="popup" onClick={clickVideoOpen}>
        <div className={'bottom'}>
          {intlMessages['chats.messageTemplates.videochat.message.button']}
        </div>
      </a>
    )
  } else {
    return (
      <div className={'bottom chatCompleted'}>
        <BeatLoader color={'#d8dddf'} loading={true} size={6} />
      </div>
    )
  }
}

export const VideoChatMessage = ({completed, token, room}) => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const history = useHistory();

  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ showVideo, setShowVideo ] = useState(false);
  const [ isValid, setIsValid ] = useState(false);

  let popupWindow = useRef(null)


  useEffect(()=> {
    // check token here
    async function f(token){
      try{
        const resp = await videoChatServices.checkVideoChatToken(token)
        setLoading(false);
        setIsValid(true);
      } catch (err) {
        setLoading(false);
        console.log("VIDEOCALL token expired")
        setIsValid(false);
      }
    }
    if (token){
      setLoading(true);
      f();
    }
  }, [token])

  // for a better way to open into new window...
  // https://stackoverflow.com/questions/47574490/open-a-component-in-new-window-on-a-click-in-react


  const clickVideoOpen = (tok) => {
    //console.log("handlevideoopen", e, url)
    let popupParams = `status=no,location=no,toolbar=no,menubar=no,top=0,left=0,height=650,width=900`;

    let description = `Patient `//`${currentAppointmentRequest.first_name} ${currentAppointmentRequest.last_name}`;

    let tokUrl = BASE_URL + `/videochat?rm=${room}&t=${token}&desc=${description}`;

    popupWindow.current = window.open(tokUrl, 'popup', popupParams);
    popupWindow.current.addEventListener("beforeunload", handlePopupClose);
    setShowVideo(true)
    console.log("set sho video to true")
    // send message to patient in chat
    // url for token and room name?
    /*const appMessage = {
       type: "videochat",
       senderId: userId,
       text: "",
       data: {
         vid: "DAILYv1",
         token: patientToken.current,
         meeting: room
       }
    }*/
    //dispatch(chatActions.sendMessage(appMessage));
  }

  const handlePopupClose = () => {
    if (popupWindow.current != null){
      popupWindow.current.close();
    }
    setShowVideo(false);

    // appointment notes for patient
    // video chat ended message in chat
    /*const appMessage = {
       type: "videoChatEnd",
       senderId: userId,
       text: ""
    }*/
    //dispatch(chatActions.sendMessage(appMessage));
  }

  const clickPopupClose = (e) => {
    e.preventDefault();
      console.log("clickPopupClose")
    if (popupWindow.current != null){
      popupWindow.current.close();
    }
    setShowVideo(false)
    // appointment notes for patient
    // video chat ended message in chat
    /*const appMessage = {
       type: "videoChatEnd",
       senderId: userId,
       text: ""
    }*/
    //dispatch(chatActions.sendMessage(appMessage));
  }
  let description = `Patient `//`${currentAppointmentRequest.first_name} ${currentAppointmentRequest.last_name}`;
  let toUrl = BASE_URL + `/videochat?rm=${room}&t=${token}&desc=${description}`;
  let status = "loading"
  if (isValid){
    status = showVideo ? "inprogress" : "valid";
  }

  if (completed){
    return (
      <StyledVideoCallMessage>
        <div>
          <div className={'top'}>
            {intlMessages['chats.messageTemplates.videochat.message.text']}
          </div>
          <div className={cn('bottom', {'chatCompleted': completed})}>
            {intlMessages['chats.messageTemplates.videochat.message.ended.text']}
          </div>
        </div>
      </StyledVideoCallMessage>
    )
  }

  return(
    <StyledVideoCallMessage>
      <div className={cn({'onlyText': !isValid})}>
        <div>
          <div className={'top'}>
            {intlMessages['chats.messageTemplates.videochat.message.text']}
          </div>
          <VideoChatMessageButton 
            status={isValid ? showVideo ? "inprogress" : "valid" : "loading"}
            toUrl={toUrl}
            clickVideoOpen={clickVideoOpen}
            clickPopupClose={clickPopupClose}
          />
        </div>
      </div>
      </StyledVideoCallMessage>
  )
}
