import React from 'react';
import { upperFirst } from 'lodash';
import { Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  StyledLoadingContent,
  StyledLoadingText,
  StyledLoadingHeader,
  StyledActions
} from './styles';
import { StyledButton } from '../_styles/forms'

import { getIntlMessages } from '../Authentication/_selectors';

export const MessageContent = ({header, text, closeModal, negativeCallback, positiveLabel=null}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <StyledLoadingContent>
      <StyledLoadingHeader>{header}</StyledLoadingHeader>
      <StyledLoadingText>{text}</StyledLoadingText>
      <StyledActions>
        {negativeCallback && 
          <Button variant={"outlineDefault"} onClick={negativeCallback}>{upperFirst(intlMessages['checkup.fullscan.buttons.cancel'])}</Button>
        }
        <Button ml={["10px"]} onClick={closeModal}>{positiveLabel || upperFirst(intlMessages['checkup.review.close'])}</Button>
      </StyledActions>
    </StyledLoadingContent>
  )
}
