import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const HealthyReportIcon = ({width=87, height=84, color="#4C5666", ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 87 84" color={color}  {...props}>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        fill="none"
        d="M52.6375 12.5996C49.7596 12.5996 47.7888 13.5469 45.8835 14.4622C44.1864 15.2787 42.5835 16.0458 40.4015 16.0487C38.2167 16.0458 36.6166 15.2758 34.9196 14.4622C33.0143 13.5469 31.0434 12.5996 28.1655 12.5996C19.857 12.5996 13.1001 20.8257 13.1001 29.2872C13.1001 38.2455 18.9101 70.4671 27.3726 71.3795C27.4753 71.3911 27.5722 71.397 27.6721 71.397C30.5927 71.397 31.8106 67.0384 33.2196 61.994C34.7684 56.4557 36.5225 50.1764 40.3559 50.0544H40.3644H40.4472C44.2777 50.1764 46.0318 56.4557 47.5806 61.994C48.9924 67.0384 50.2103 71.397 53.131 71.397C53.2508 71.4057 53.3278 71.3911 53.4276 71.3795C61.893 70.4671 67.7001 37.7632 67.7001 27.9476C67.7001 19.4833 60.9432 12.5996 52.6375 12.5996Z" 
        stroke="currentColor"/>
      <circle cx="64" cy="21" r="15.5" fill="#DAF3ED" stroke="currentColor"/>
      <path d="M56.1499 22.5L60.8749 27L72.4249 16" fill="none" stroke="currentColor"/>
    </Icon>
  )
}