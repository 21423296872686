import { constants } from './_constants';

const initState = {
  chatsList: null,
  chatsListLoading: false,
  chatsListError: false,
  chatsListLoaded: false,
  membershipsLoaded: false,
  currentConversationId: null,
  messageDraftById: {},
  loadingMessageById: {},
  showRecordsAttachment: false,
  latestsMessagesByChannel: {},
  messageActionsById: {}
};

export function chatsList(state = initState, action) {
  switch(action.type) {
    case constants.CHAT_LIST_SELECT_CONVERSATION:
      return {
        ...state,
        currentConversationId: action.data
      }
    case constants.CHAT_LIST_LOAD_REQUEST:
      return {
        ...state,
        chatsListLoading: true,
        chatsListLoaded: false
      }
    case constants.CHAT_LIST_LOAD_SUCCESS:
      return {
        ...state,
        chatsList: action.data,
        chatsListLoading: false,
        chatsListError: false,
        chatsListLoaded: true
      }
    case constants.CHAT_LIST_LOAD_FAILURE:
      return {
        ...state,
        chatsListLoading: false,
        chatsListLoaded: true,
        chatsListError: action.error
      }
    case constants.MESSAGE_DRAFT_UPDATED:
      return {
        ...state,
        messageDraftById : {
          ...state.messageDraftById,
          [action.data.conversationId]: action.data.draft
        }
      }
    case constants.MESSAGE_DRAFT_DISCARDED:
      const {
        [action.data.conversationId]: discardDraft,
        ...messageDraftById
      } = state.messageDraftById;
      return {
        ...state,
        messageDraftById
      };
    case constants.LOADING_MESSAGE_UPDATE:
      return {
        ...state,
        loadingMessageById: {
          ...state.loadingMessageById,
          [action.data.conversationId]: Object.assign({}, state.loadingMessageById[action.data.conversationId], action.data.message)
        }
      }
    case constants.UPDATE_CHANNEL_MESSAGE_ACTIONS:
      return {
        ...state,
        messageActionsById: {
          ...state.messageActionsById,
          [action.data.channel] : [...(state.messageActionsById[action.data.channel] || []), ...action.data.messageActions]
        }
      }
    case constants.LOADING_MESSAGE_DISCARDED:
      const {
        [action.data.conversationId]: discardMessage,
        ...loadingMessageById
      } = state.loadingMessageById;
      return {
        ...state,
        loadingMessageById
      };
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}
