import { RECORD_TYPES } from '../_config';
import { constants } from './_constants';
import { MediaItem } from './_models';

// scanType: 'CHECKUP', 'SCAN'
const initState = {
  scanType: RECORD_TYPES.FULLSCAN,
  recordId: null,
}

export function ploverScan(state=initState, action){
  switch (action.type){
    case constants.UPDATE_SCANTYPE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state
  }
}
