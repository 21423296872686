import { RECORD_TYPES, REQUEST_TYPES, URLS, TIME_FORMAT } from '../_config';
import React, { useRef, useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { createSelector } from 'reselect'
import cn from 'classnames';
import moment from 'moment';
import TimePicker from '../_components/TimePicker';
import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { Flex, Text, Button } from '@chakra-ui/react';
import { FORM_ERROR } from 'final-form';
import { motion } from "framer-motion"
import {
  upperFirst, toUpper, find, range, startCase
} from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map'

// components
import { SelectChevronDown } from '../_images/icons/SelectChevronDown';

// redux state
import { actions } from './_actions';
import { services } from './_services';

// actions
import { actions as reportActions } from '../Reports/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { actions as mediaActions } from '../MediaUploader/_actions';
import { actions as appsettingsActions } from '../Settings/_actions';

// selectors
import { getRegions, getCountryRegions} from '../Settings/_selectors';
import { getIntlLocale, getIntlMessages, getCountry, getSelectedProfile} from '../Authentication/_selectors';


//styles
import {
  ScanSectionTitleStyles,
  SubsectionTitleStyles,
  StyledSelectedFields,
  ErrorSpan,
  errorCss,
  StyledForm,
  StyledFormHeader
} from '../_styles/forms';
import {
  Wrapper,
  StyledTimeFieldIcon,
  RequestTypeField,
  StyledLabel,
  ErrorLabel,
  StyledCheckupFormHeader,
  StyledCheckupFormBody,
  StyledInputLayout,
  StyledDescription,
  StyledTextArea,
  StyledButtons,
  StyledButton,
  StyledFormRow,
  StyledPaymentTotalRow,
  StyledPaymentSummaryRow,
  FormRadioGroup,
  FormRadioLabel,
  RequestTypeFieldPrice,
  RequestTypeFieldText,
  StyledCheckupForm,
  StyledTerms,
  RequestAppointmentField,
  RequestAppointmentFieldTime,
  RequestAppointmentFieldDate
} from './styles'
import storage from '../_helpers/storage';
const required = value => (value ? undefined : 'required')

const selectStyles = {
  container: (provided, state) => {
    const width = '100%';

      return {
        ...provided,
        width : '100%',
        fontFamily: 'Proxima Nova Semibold',
        fontSize: '13px',
        fontWeight: 'normal'
      };
    },
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';

    return { ...provided, color };
  },
  valueContainer: (provided, state) => {
    const height = '38px' // add for 1px border
    const justifyContent = 'center';
    return { ...provided, height, justifyContent };
  },
  control: (provided, state) => {
    const borderColor = state.hasValue ? '#36454D' : '#C7D3D9';
    const borderRadius = '5px';
    const height = '40px' // add for 1px border
    return { ...provided, borderColor, borderRadius, height};
  },
  input: (provided, state) => {
    const height = '38px' // add for 1px border
    return { ...provided, height};
  },
  dropdownIndicator: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    const position = 'absolute';
    const paddingRight = '15px';
    const right = '3px';
    return { ...provided, color, position, right, paddingRight};
  },
  indicatorSeparator:  (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  }

}
const SelectAdapterNonClearable = ({
  input, meta, items, label, type,  intlMessages , className="SelectAdapter", defaultValue, disabled, ...rest 
}) => {
  let newSelectStyles = selectStyles;
  if ((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)){
    selectStyles.control['borderColor'] = 'red';
    newSelectStyles = {
      ...selectStyles,
        control: (provided, state) => {
          const borderColor = 'red';
          const borderRadius = '5px';
          const borderWidth = '2px';
          const height = '40px' // add for 1px border
          return { ...provided, borderColor, height, borderRadius, borderWidth};
        }
    }
  }
  return(
  <StyledInputLayout className={className}>
    <Select
      {...input}
      styles={newSelectStyles}
      className={ cn({"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      onChange={inputValue => {input.onChange(inputValue.value)}}
      options={items}
      isDisabled={disabled}
      isClearable={false}
      isSearchable={false}
      value={find(items, {'value': defaultValue || input.value})}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      {...rest}
    />
    <ErrorLabel
      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
      className={cn("Error__Label", 
          {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} 
    >
      {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && 
        intlMessages[`checkup.checkupform.location.error`]
      }
    </ErrorLabel>
  </StyledInputLayout>
  )
}

const RequestPrice = ({price, priceField}) => {
  if (price){
    if (priceField == "total"){
      return(
        <RequestTypeFieldPrice>
          {getSymbolFromCurrency(toUpper(price.currency))}{" "}
          {(price.total).toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}{" "}
        </RequestTypeFieldPrice>
      )
    } else if (priceField == "tax"){
      return(
        <RequestTypeFieldPrice>
          {getSymbolFromCurrency(toUpper(price.currency))}{" "}
          {(price.total - price.price).toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}{" "}
        </RequestTypeFieldPrice>
      )
    } else {
      return(
        <RequestTypeFieldPrice>
          {getSymbolFromCurrency(toUpper(price.currency))}{" "}
          {(price.price).toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}{" "}
        </RequestTypeFieldPrice>
      )
    }
  } else{
    return(
      <RequestTypeFieldPrice readyStatus={"loading"}>
        {"Retrieving"}
      </RequestTypeFieldPrice>
    )
  }
}

export const TimeFieldIcon = (isSelected) => {
  return (
    <StyledTimeFieldIcon
      isSelected={isSelected}
    >
      <SelectChevronDown />
    </StyledTimeFieldIcon>
  )
}

export const CheckupForm = ({recordId, onSuccess}) => {
  const dispatch= useDispatch();
  const history = useHistory();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const regions = useSelector(getRegions);
  const country = useSelector(getCountry);
  const countryRegions = useSelector(getCountryRegions);
  const [ prices, setPrices] = useState({});
  const selectedProfile = useSelector(getSelectedProfile);

  useEffect(()=>{
    async function f(){
      try{
        const resp = await services.fetchPaymentPrices();
        const prices = resp.prices.reduce((a,b) => {a[b.key] = b; return a} , {})
        console.log(prices)
        setPrices(prices)
      } catch(err){
        console.log("error retreiving prices")
      }
    }
    f();
    // unmount
    return () => {};
  }, [])

  useEffect(()=>{
    if (country == "US" && !regions[country]){
      dispatch(appsettingsActions.fetchAppSettings('regions', country));
    }
  }, [country])

  const initValues = {record: recordId, clinic_dentist: storage.getScanDentist() , type: "REPORT"}

  const backRescan = () => {
    dispatch(actions.setCheckupRecord(null));
    dispatch(mediaActions.resetMedia());
    history.push("/createrequest/scan");

  }
  const closeModal = () => {
    // cancel checkup request
    //dispatch(modalActions.closeModal('scanningPage'))
    let negativeCallbacks = [
      modalActions.closeModal('confirmDialog')
    ]
    let positiveCallbacks = [
      () => storage.clearScanDetails(),  
      () => history.push("/"),
      modalActions.closeModal('confirmDialog', [])
      
    ]
    
    dispatch(modalActions.openConfirmDialog(
      intlMessages['checkup.checkupform.loseprogress.title'],
      intlMessages['checkup.checkupform.loseprogress.description'],
      negativeCallbacks,
      positiveCallbacks,
      intlMessages['checkup.checkupform.loseprogress.button.confirm']
    ))
  }

  const requestCheckup = async (values) => {
    function filterFields(vals){
      if (values.type == REQUEST_TYPES.VIDEOCHAT){
        let { accept_terms, appt_time, appt_date, ...submitValues } = values;
        let [ year, month, date ] = appt_date.split("-");
        appt_time.set({year, date: parseInt(date), month: parseInt(month)-1, seconds: 0})
        // unix() numbers of seconds (not milli) since the epoch
        submitValues['availability'] = {'value': appt_time.unix()}
        return submitValues;
      } else {
        let submitValues = {
          availability: {}, ...values
        }
        return submitValues;
      }
    }

    try{
      // TODO:
      let data = filterFields(values);
      data = {
        ...data, 
        user_profile: selectedProfile.id, 
        remark: data.remark || intlMessages['checkup.remark.defaultvalue']
      }
      const checkupRequest = await services.createCheckupRequest(data)
      dispatch(reportActions.addToRequestList(checkupRequest))
      dispatch(actions.setCheckupRequest(checkupRequest.id))
      console.log("checkupRequest after checkup request ", checkupRequest)
      // Check .payment.client_secret. Omise doesn't return a request.client_secret
      if (checkupRequest?.payment?.client_secret == null || checkupRequest?.payment?.client_secret == "FREE"){

        // NO PAYMENT REQUIRED
        if (checkupRequest?.payment?.client_secret == "FREE"){
          // Done, go to receipt page
          onSuccess(checkupRequest)
        } else {
          // error
          console.log("there was an error with checkup", checkupRequest?.payment)
          return {
            [FORM_ERROR]: upperFirst(intlMessages['checkup.paymentform.error.request'])
          }
        }
      }else {
        // TODO check all the different stautses in STRIPE
        // requires_payment_method, requires_confirmation, requires_action, processing, requires_capture, canceled, or succeeded
        console.log("checkup request client secret not null", checkupRequest)
        onSuccess(checkupRequest)
      }
      return null;
    } catch(err){
      return {
        ...err,
        [FORM_ERROR]: upperFirst(intlMessages['checkup.page.error'])
      }
    }
  }



  let labelFormat = "ddd, MMM Do"
  let valueFormat = "YYYY-MM-DD";

  let dateItems = [...Array(6).keys()].map((num) => {
    let temp = moment().add(num, 'days').locale(intlLocale)
    return {label: temp.format(labelFormat) , value: temp.format(valueFormat) }
  });

  let labelTimeFormat = "h:mm a";
  let valueTimeFormat = "HH:mm";

  let timeItems = [...Array(24).keys()].map((num) => {
    let temp = moment().hour(0).minute(0).add(num, 'hours').locale(intlLocale);
    return {label: temp.format(labelTimeFormat), vaue: temp.format(valueTimeFormat)}
  })

  return(
    <Wrapper>
      <Form
        onSubmit={requestCheckup}
        initialValues={initValues}
        validate={ values => {
          const errors = {};
          if (!(values.accept_terms && values.accept_terms.length > 0)){
            errors.accept_terms = 'required'
          }
          if (!values.type){
            errors.type = 'required'
          }
          if (values.type == REQUEST_TYPES.VIDEOCHAT){
            if (!values.appt_time) errors.appt_time = 'required';
            if (!values.appt_date) errors.appt_date = 'required';
          }
          return errors;
        }}
        render = {({
          submitError,
          formError,
          handleSubmit,
          mutators,
          form,
          reset,
          submitting,
          pristine,
          validating,
          values
        }) => (
          <StyledCheckupForm
            onSubmit={handleSubmit}
            >
              <StyledCheckupFormHeader>
                <div className="navButton" onClick={backRescan}>
                  {intlMessages['checkup.checkupform.navButton.rescan']}
                </div>
                <div className="title">{intlMessages['checkup.checkupform.title']}</div>
                <div className="navButton" onClick={closeModal}>
                  {intlMessages['checkup.checkupform.navButton.cancel']}
                </div>
              </StyledCheckupFormHeader>
              <StyledCheckupFormBody>
                { country == "US" &&
                  <React.Fragment>
                    <StyledLabel>
                      {intlMessages['checkup.checkupform.location.label']}
                    </StyledLabel>
                    <Field
                      name="location"
                      validate={required}
                      items={country && regions[country]
                        ? [...regions[country].map(item => {
                            return { label: item.area, value: item.code }
                           }),
                           { label: intlMessages['config.country.OTHER.label'], value: "OTHER"}
                          ]
                        : []
                      }
                      intlMessages={intlMessages}
                      disabled={(submitting) ? true : false}
                      component={SelectAdapterNonClearable}
                      placeholder={upperFirst(intlMessages['checkup.checkupform.location.placeholder'])}
                    />
                    <StyledDescription>
                      <div>{'*'}</div>
                      <div>{intlMessages['checkup.checkupform.location.footnote']}</div>
                    </StyledDescription>
                  </React.Fragment>
                }
                <Field name="remark" >
                  {({ input, meta}) => (
                    <StyledInputLayout>
                      <StyledLabel>
                        {intlMessages['checkup.checkupform.remark.label']}
                      </StyledLabel>
                      <StyledTextArea
                        {...input}
                        columns={20}
                        disabled={submitting}
                        rows={5}
                        type="text"
                        addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) 
                            && errorCss
                        }
                        placeholder={upperFirst(intlMessages['checkup.checkupform.remark.placeholder'])}
                      />
                      <ErrorLabel
                        isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                        >
                          {((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) 
                            && 'error' || meta.submitError}
                      </ErrorLabel>
                    </StyledInputLayout>
                  )}
                </Field>
              <StyledLabel>
                {intlMessages['checkup.checkupform.requesttype.label']}
              </StyledLabel>
              <FormRadioGroup direction={'horizontal'}>
                {(Object.values(prices) || []).map((item) =>
                  <FormRadioLabel key={item}>
                    <Field
                        name="type"
                        component="input"
                        type="radio"
                        value={item.key}
                      />
                    <RequestTypeField>
                      <RequestTypeFieldText>
                        {intlMessages[`checkup.checkupform.requesttype.${item.key}.label`]}
                      </RequestTypeFieldText>
                      <RequestPrice
                        price={item}
                        priceField={'price'}
                        />
                    </RequestTypeField>
                  </FormRadioLabel>
                )}
              </FormRadioGroup>
              <Field name="type">
                {({ meta }) => {
                  return (
                    <ErrorLabel
                      setLineHeight={17}
                      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                      >
                        {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && 
                          upperFirst(intlMessages['checkup.checkupform.requesttype.error']) || meta.submitError
                        }
                    </ErrorLabel>
                  )
                }}
              </Field>
            <StyledFormRow
              isShowing={(values && values.type == REQUEST_TYPES.VIDEOCHAT)}
            >
              <StyledLabel>
                {intlMessages['checkup.checkupform.availability.label']}
              </StyledLabel>
              <RequestAppointmentField>
                <RequestAppointmentFieldDate>
                  <Field
                    name="appt_date"
                    items={dateItems}
                    intlMessages={intlMessages}
                    disabled={(submitting) ? true : false}
                    component={SelectAdapterNonClearable}
                    placeholder={upperFirst(intlMessages['checkup.checkupform.availability.date.date'])}
                  />
                </RequestAppointmentFieldDate>
                <RequestAppointmentFieldTime>
                  <Field name="appt_time">
                    {({input, meta}) => {
                      return (
                        <div>
                          <TimePicker
                            placeholder={upperFirst(intlMessages['checkup.checkupform.availability.date.time'])}
                            showSecond={false}
                            locale={intlLocale}
                            inputIcon={<TimeFieldIcon isSelected={!(input && input.value)} />}
                            minuteStep={10}
                            className={cn('TimePicker', 
                                {'TimePickerError': (meta.error && meta.touched) || 
                                    (meta.submitError && !meta.dirtySinceLastSubmit)})
                            }
                            onChange={(inputValue) => (input.onChange(inputValue))}
                            format={TIME_FORMAT}
                            use12Hours
                            inputReadOnly
                          />
                          <ErrorLabel
                            isError={(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)}
                            >
                              {((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) 
                                ? 'Time Error' : undefined}
                          </ErrorLabel>
                        </div>
                      )
                    }}
                  </Field>
                </RequestAppointmentFieldTime>
              </RequestAppointmentField>
              <StyledDescription>
                <div>{'*'}</div>
                <div>{intlMessages['checkup.checkupform.availability.footnote']}</div>
              </StyledDescription>
            </StyledFormRow>

            
            <StyledFormRow
              isShowing={values?.type}
            >
              <StyledLabel> 
                {intlMessages['checkup.checkupform.paymentsummary.label']}
              </StyledLabel>
              <StyledPaymentSummaryRow>
                <div>{startCase(intlMessages['checkup.checkupform.paymentsummary.subtotal.label'])}</div>
                <RequestPrice
                  price={prices[values?.type]}
                  priceField={'subtotal'}
                />
              </StyledPaymentSummaryRow>
              <StyledPaymentSummaryRow>
                <div>{startCase(intlMessages['checkup.checkupform.paymentsummary.tax.label'])}</div>
                <RequestPrice
                  price={prices[values?.type]}
                  priceField={'tax'}
                />
              </StyledPaymentSummaryRow>
              <StyledPaymentTotalRow>
                <div>{startCase(intlMessages['checkup.checkupform.paymentsummary.total.label'])}</div>
                <RequestPrice
                  price={prices[values?.type]}
                  priceField={'total'}
                />
              </StyledPaymentTotalRow>
            </StyledFormRow>

            <StyledTerms>
              <Field
                name="accept_terms"
                component="input"
                type="checkbox"
                value="yes"
                id="yes"
                />
              <label>
                {intlMessages['checkup.checkupform.accept_terms.label']}{' '}
                <a target="_blank" href={country ? `${URLS.terms.url}?country=${country}` : `${URLS.terms.url}`}>
                  {intlMessages['registration.form.accept_terms.text']}
                </a>
              </label>
            </StyledTerms>
            <Field name="accept_terms">
              {({ meta }) => {
                return (
                  <ErrorLabel
                    setLineHeight={17}
                    isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                    >
                      {
                        ((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && 
                        upperFirst(intlMessages['registration.form.accept_terms.error']) || meta.submitError
                      }
                  </ErrorLabel>
                )
              }}
            </Field>
            </StyledCheckupFormBody>
            <Flex px={['50px']}>
              <Button
                variant={'solid'}
                w={['full']}
                isLoading={submitting}
                type="submit"
              >
                <Text>
                  {toUpper(intlMessages['checkup.checkupform.submit'])}
                </Text>
              </Button>
            </Flex>
          </StyledCheckupForm>
        )}
      />
    </Wrapper>
  )
}
