import React from 'react';

export const FullScanAttachmentIcon = () => {
  return(
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="#12171A" strokeWidth="1.2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.3163 10.531C15.5612 10.6885 15.5612 11.0465 15.3163 11.2039L9.51529 14.9331C9.24909 15.1043 8.89899 14.9131 8.89899 14.5967V7.13828C8.89899 6.82182 9.24909 6.63068 9.51529 6.80181L15.3163 10.531Z" stroke="#12171A" strokeWidth="1.2"/>
    </svg>
  )
}
