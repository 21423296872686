import React, {useState, useEffect, useRef} from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { getIntlMessages } from '../../Authentication/_selectors';

import { bytesToString } from '../../_helpers/utils';

import { PaperclipIcon } from '../../_images/icons/PaperclipIcon';
import { LoadingCircle } from '../LoadingCircle';
import {
  StyledRecordUploadIcon,
  StyledLoadingMedia,
  StyledRecordUploadMedia,
  StyledErrorDiv,
  BlankMediaItem
}
from './styles';

export const RecordMessageVideo = ({url, play,
  onError, imgHeight="100%", imgWidth="100%"
}) => {
  return(
    <ReactPlayer
        onError={onError}
        url={url}
        muted={true}
        controls={play}
        width={imgWidth}
        height={imgHeight}
      />
  )
}

export const RecordMessageImage = ({url, onError}) => {
  return(
    <img
      src={url}
      onError={onError}
      />
  )
}

export const RecordUploadMedia = ({
  upload, selected, onClick,
  imgHeight=100, imgWidth=100, imgBorderRadius=30,
  play=false, marginTop=0, marginLeft=0
}) => {
  const intlMessages = useSelector(getIntlMessages);
  const [ error, setError] = useState(null);
  const [ mimeType, setMimeType ] = useState(null);
  const contentSize = useRef(0);

  let urlKey = "";
  let urlHeadKey = "";
  let fileName = "Uploaded File";
  if (upload && upload.file){
    urlKey = "file";
    urlHeadKey = "file_head"
  } else if (upload && upload.upload) {
    urlKey = "upload";
    urlHeadKey = "upload_head";
  }
  if (upload && upload.converted_url){
    urlKey = "converted_url"
  }

  if (upload && upload.file_name){
    fileName = upload.file_name
  }


  useEffect(() => {
    if (upload) {
      if (upload[urlHeadKey]){
        fetch(upload[urlHeadKey], {method: 'HEAD'})
          .then( response => {
            let contenttype = response.headers.get("content-type");
            contentSize.current = response.headers.get("content-length");
            setMimeType(contenttype)
          }).catch(error => {
            setError(true);
          })
      } else if (upload[urlKey]) {
        setMimeType("image/*");
      } else {
        setError(true);
      }
    }
  }, [upload])

  const onError = (e) => {
    setMimeType("video/*");
  }

  const onVideoError = () => {
    // TODO: dont set to error
    //setError(true);
  }

  if (!upload){
    return (
      <StyledRecordUploadMedia
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <BlankMediaItem></BlankMediaItem>
      </StyledRecordUploadMedia>)
  }

  if (error){
    return (
      <StyledRecordUploadMedia
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <StyledErrorDiv>
          {intlMessages['media.errorFetching']}
        </StyledErrorDiv>
      </StyledRecordUploadMedia>
    )
  }
  if (mimeType && mimeType.includes("image")){
    return(
      <StyledRecordUploadMedia
        onClick={onClick}
        selected={selected}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <RecordMessageImage
          onError={onError}
          url={upload[urlKey]} />
      </StyledRecordUploadMedia>
    )
  } else if (mimeType && mimeType.includes("video")) {
    return(
      <StyledRecordUploadMedia
        onClick={onClick}
        selected={selected}
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <RecordMessageVideo
          onError={onVideoError}
          imgWidth={imgWidth}
          imgHeight={imgHeight}
          play={play}
          url={upload[urlKey]} />
      </StyledRecordUploadMedia>
    )
  } else if (mimeType){
    return(
      <StyledRecordUploadIcon
        onClick={onClick}
        selected={selected}
        imgHeight={100}
        imgWidth={250}
        imgBorderRadius={24}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
          <div className={'attachmentIcon'}>
            <PaperclipIcon />
          </div>
          <div className={'mediaDetails'}>
            <div className={'mediaName'}>
              <span>{fileName}</span>
            </div>
            <div className={'mediaSize'}>
              <span>{bytesToString(contentSize.current)}</span>
            </div>
          </div>
      </StyledRecordUploadIcon>
    )
  } else {
    return (
      <StyledLoadingMedia
        imgHeight={imgHeight}
        imgWidth={imgWidth}
        imgBorderRadius={imgBorderRadius}
        marginTop={marginLeft}
        marginLeft={marginTop}
        >
        <LoadingCircle />
      </StyledLoadingMedia>
    )
  }
}
