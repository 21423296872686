import React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { 
  FormControl, 
  FormLabel, 
  FormErrorMessage 
} from '@chakra-ui/react';
import { SelectFieldFromDefaultNoInput } from './SelectComponents';

export const SelectOptionField = ({name, value, onChange, placeholder, isInvalid, selectOptions, isMultiple=false, optionValue=null, label="", error="", isLoading=false, isDisabled=false}) => {

    //if (isLoading){
    //  return <Skeleton startColor={'#e7e7e7'} endColor={'#d7d7d7'} height={'45px'} w={'full'} borderRadius={'30px'} />
    //}
  
    return (
      <FormControl id={name} isInvalid={isInvalid} >
        <FormLabel align="center">
          {label}
        </FormLabel>
        <SelectFieldFromDefaultNoInput 
          name={name}
          value={optionValue ? optionValue : value}
          onChange={onChange}
          isLoading={isLoading}
          placeholder={placeholder}
          isDisabled={isDisabled}
          multi={isMultiple}
          isError={isInvalid}
          options={selectOptions}
        />
        <FormErrorMessage>{error ? error : ""}</FormErrorMessage>
      </FormControl>
    )
  }
  