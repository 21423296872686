import { createSelector } from 'reselect';

import { getUUID } from '../Authentication/_selectors';

import { checkNotificationArrayRead } from '../_helpers/pubnubUtils';

export const getNotificationsSlice = (state) => state.notifications;

export const getNotificationsAllLoading = createSelector(
  [getNotificationsSlice],
  (state) => {
    return state.loadingAll
  }
)

export const getNotificationsAllError = createSelector(
  [getNotificationsSlice],
  (state) => {
    return state.errorAll
  }
)

export const getNotificationsById = createSelector(
  [getNotificationsSlice],
  (state) => {
    return state.byId
  }
)

export const getNotificationsByRequestId = createSelector(
  [getNotificationsById],
  (byId)=>{
    return Object.values(byId).reduce(
      (acc, curr) => {
        if (curr.message?.type == "report" && curr.message?.user_request_unique_id){
          acc[curr.message.user_request_unique_id]
            ? acc[curr.message.user_request_unique_id].push(curr)
            : acc[curr.message.user_request_unique_id] = [curr]
        }
        return acc;
      }, {})
  }
)

export const getTotalRequestNotifications = createSelector(
  [getNotificationsByRequestId, getUUID],
  (notifications, uid) => {
    return Object.values(notifications).filter(elem => !checkNotificationArrayRead(elem, uid)).length
  }
)

export const getNotifications = createSelector(
  [getNotificationsById],
  (byId) => {
    return (byId)
      ? Object.values(byId)
          .map( (entry) => {
            return entry;
          }).sort((msgA, msgB) => {
            if (msgA.timetoken === msgB.timetoken) {
              return 0;
            } else if (msgA.timetoken > msgB.timetoken) {
              return 1;
            } else {
              return -1;
            }
          })
      : [];
  }
)
