import { range } from 'lodash';
import { Flex, Box } from '@chakra-ui/react';
import { ReviewStar } from '../_images/icons/ReviewStar';

export const ReviewStars = ({numOfStars=5, rating=1, justify='end', margin=4}) => {
    
    return (
        <Flex
            items={'center'}
            justify={justify}
        >
            {
                range(numOfStars).map((i) => (
                    <Box  
                        ml={justify == 'center' ? 0 : justify == 'end' ? `${margin}px` : 0}
                        mr={justify == 'center' ? 0 : justify == 'end' ? 0 : `${margin}px`}
                    >
                        <ReviewStar on={rating >= i + 1 ? true : false} />
                    </Box>
                ))
            }
        </Flex>
    )
}