import { constants } from './_constants';

const initState = {
  byId: {},
  loading: {},
  error: {},
}

export function dentists(state = initState, action) {
  switch(action.type){
    case constants.FETCH_DENTIST_REQUEST:
      return {
        ...state,
        error: false,
        loading: true
      }
    case constants.FETCH_DENTIST_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        ...action.data
      }
    case constants.FETCH_DENTIST_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      }
    case constants.UPDATE_DENTISTS:
      return {
        ...state,
        byId: Object.assign(
          {},
          state.byId,
          {[action.data.id] : {...state.byId[action.data.id], ...action.data.data} }
        )
      }
    case constants.UPDATE_DENTISTS_LATEST_CHECKUP_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data.id]: true
        }
      }
    case constants.UPDATE_DENTISTS_LATEST_CHECKUP:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data.id]: false
        },
        byId: Object.assign(
          {},
          state.byId,
          {[action.data.id] : {...state.byId[action.data.id], ...action.data.data} }
        )
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state;
  }
}
