import React, { useState, useEffect }  from 'react';
import ReactDOM from 'react-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { toUpper } from 'lodash';
import { useUrlSearchParams } from '../_helpers/useUrlSearchParams';

import { URLS } from '../_config';
import { getCountry } from '../Authentication/_selectors';

import { Link } from '@chakra-ui/react';

import styled, {css} from 'styled-components'


import { InformationalPageContent } from '../_styles/common';
import { useSelector } from 'react-redux';

const Page = styled.div`
  min-height:100vh;
  color: '#12171a';
`;

const COUNTRY_LIST = [
  {value: 'TH', label: 'ประเทศไทย (TH)'}, 
  {value: 'JP', label: '日本 (JP)'}
]

export const PrivacyPage = () => {
  const history = useHistory();
  const [ html, setHtml ] = useState();
  const [ country, setCountry]  = useState();

  const userCountry = useSelector(getCountry);
  let url = URLS['privacy_us'];
  if (toUpper(userCountry)  == 'TH'){ 
    url = URLS['privacy_th'];
  } else if (toUpper(userCountry)  == 'JP'){
    url = URLS['privacy_jp'];
  }

  return (
    <Page>
      <InformationalPageContent>
        <Link to={url}>Latest Privacy Policy Here</Link>
      </InformationalPageContent>
    </Page>
  )
}
