import { REPORTCHART_TYPES } from '../_config';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import storage from '../_helpers/storage';
import { VStack, Box, Flex, Grid, Text, Button, useDisclosure } from '@chakra-ui/react';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { toUpper, upperFirst, capitalize, startCase } from 'lodash';
import cn from 'classnames';
import Joyride, { STATUS } from 'react-joyride';  
import {joyrideStyles, joyrideOptions} from '../_styles/joyrideStyles';
import {
  localeFormatDate,
  localeFormatFromNow,
  formatName,
  checkNotificationRead,
  checkNotificationArrayRead } from '../_helpers'
import { Scrollbars } from 'react-custom-scrollbars';

// components
import dashboardImage from '../_images/ploverHeroFamily.png';
import {NoReportsIcon} from '../_images/icons/NoReportsIcon';
import { BlankCircle } from '../_images/icons/BlankCircle';
import cameraIcon from '../_images/camera.png'
import reportIcon from '../_images/reportIcon.png'
import { LoadingEllipses } from '../_components/LoadingEllipses';
import { ToothChart as ReportToothChart} from '../Reports/ReportReview';
import  {DashboardCheckupRequestHistory} from './DashboardCheckupHistory/DashboardCheckUpHistory';

// actions
import { actions as reportActions } from '../Reports/_actions';
import { actions, actions as modalActions } from '../Modals/_actions'
import { actions as checkupActions } from '../Checkups/_actions';
import { actions as notificationActions } from '../Notifications/_actions';

// selectors
import {
  getRequestList, getRequestDetails , getRequestReport, getReportListLoading, getRequestDetailsId, getRequestDetailById,
   getFilteredReportCharts, getChartCategory, getRequestListIsMore, getRequestDetailsUniqueId, getRequestListWithFilter
 } from '../Reports/_selectors';
import { getFirstName, getIntlLocale, getIntlMessages, getIsDentalType, getIsOrphanage, getProfile, getSelectedProfile, getUUID } from '../Authentication/_selectors';
import { getCurrentRequestId, getReportList, getReportLoading } from '../Reports/_selectors';
import { getNotificationsByRequestId } from '../Notifications/_selectors';


// styles
import {
  SectionPage,
  SectionTitle,
  StyledSectionWrapper,
  StyledCheckupColumn,
  StyledCheckupSection,
  StyledScanHistoryColumn,
  StyledCheckupLink,
  StyledCheckupInformation,
  StyledCheckupSummary,
  StyledCheckupRequestHistory,
  StyledCheckupRequestHistoryItem,
  StyledCheckupToothChartContainer,
  StyledToothChart,
  StyledToothChartInformation,
  StyledToothChartInformationCategories,
  StyledReportChartCategoryItem,
  StyledNoReports,
  StyledIsMore
}
from './styles'

import { ReportData } from '../Reports/components/ReportData';
import { ProfilesFilterSuper } from '../Reports/ProfilesFilterSuper';
import { isPaymentRequired, isCompleted } from '../_helpers/reports';
import { ArrowRightIcon } from '../_images/icons/ArrowRightIcon';
import { ChartSection } from '../Reports/ReportReview';
import { Categories } from '../Reports/components/ToothChart';
import { useReportStore } from '../Reports/state/report';
import { CircleScore } from '../_components/CircleScore/CircleScore';
import { ReportScoreContainer, ScoreCard } from './ScoreCard/ScoreCard';
import { StyledHistorySection } from '../Reports/styles';
import { SelectChevronDown } from '../_images/icons/SelectChevronDown';
import { ProfileSelectorModal } from './ProfileSelector/ProfileSelector';
import { PrintPreviewReportSection } from '../Reports/components/ReportPreview';


/**
 * Tooth Chart Categories for CheckupToothChart
 *
 * @ReportChartCategories
 */
const ReportChartCategories = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const chartCategory = useSelector(getChartCategory);

  const selectCategory = (cat) => {
    dispatch(reportActions.selectReportCategory(cat))
  }

  return(
    <StyledToothChartInformationCategories>
      <StyledReportChartCategoryItem
        onClick={() => selectCategory(null)}
        selected={chartCategory==null}
        className="ALL"
        >{capitalize(intlMessages['toothChart.category.ALL'])}
        </StyledReportChartCategoryItem>
        {REPORTCHART_TYPES.map((elem) => {
          return (
            <StyledReportChartCategoryItem
              key={elem.value}
              onClick={() => selectCategory(elem.value)}
              selected={chartCategory== elem.value}
              className={elem.value}
              >
              {intlMessages[`toothChart.category.${elem.value}`]}
            </StyledReportChartCategoryItem>
          )
        })}
    </StyledToothChartInformationCategories>
  )

}

/**
 * Tooth Chart Information for CheckupToothChart
 *
 * @ToothChartInformation
 */
const ToothChartInformation = () => {
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const request = useSelector(getRequestDetails);
  const report = useSelector(getRequestReport);
  const profile = useSelector(getProfile);
  const requestUuid = useSelector(getRequestDetailsUniqueId);

  // set default text
  let patient_name = '';
  let dateText = '';
  let titleText = '';

  // set strings based on if report was received (completed) or not
  if (report && report.sent_at){
    dateText = intlMessages['dashboard.toothchart.date.text'] + localeFormatDate(report.sent_at, intlLocale, "l");
    titleText = intlMessages["dashboard.toothchart.title.COMPLETE"];
  } else {
    if (request){
      if (isPaymentRequired(request)){
        titleText = intlMessages[`dashboard.toothchart.title.PENDING_PAYMENT`];
      } else {
        titleText = intlMessages[`dashboard.toothchart.title.${request.status}`];
      }
      dateText = intlMessages['dashboard.toothchart.date.text'] + localeFormatDate(request.created_at, intlLocale, "l");  
    } else {
      titleText = '';
      dateText = intlMessages['dashboard.toothchart.date.text']
    }
  }

  if (request?.user_profile?.id != null){
    patient_name = formatName(intlMessages['format.fullName'], request.user_profile.first_name, request.user_profile.last_name);
  } else if (profile != null) {
    patient_name =  formatName(intlMessages['format.fullName'], profile.first_name, profile.last_name);
  }

  return (
    <StyledToothChartInformation>
      <div className="bg-bdBrown text-white text-[14px] py-1 px-2 rounded-[4px] mb-[29px] w-fit self-end">
        <div>
          <span>{patient_name}</span>
        </div>
      </div>
      <p className="date">
        {titleText} <br />
        <strong>{dateText}</strong>
      </p>
      <ReportChartCategories />
    </StyledToothChartInformation>
  )
}

/**
 * Tooth Chart for CheckupToothChart
 *
 * @ToothChart
 */
const ToothChart = () => {
  const dispatch = useDispatch();
  const request = useSelector(getRequestDetails);
  const requestNotifications = useSelector(getNotificationsByRequestId);
  const chatUuid = useSelector(getUUID);
  const report = useSelector(getRequestReport);
  const reportLoading = useSelector(getReportLoading);
  const requestId = useSelector(getRequestDetailsId);
  const requestUid = useSelector(getRequestDetailsUniqueId);

  useEffect(()=>{
    // trigger mark request notification read here. after loading is finished
    if (!reportLoading && requestUid){
      let notifs = requestNotifications[requestUid]
        ? requestNotifications[requestUid].filter(elem=>!checkNotificationRead(elem, chatUuid))
        : [];
      notifs && (notifs.length > 0) && !checkNotificationArrayRead(notifs, chatUuid) && dispatch(notificationActions.markNotificationArrayRead(chatUuid, notifs));
    }
  }, [reportLoading, request])

  const isDMFT = (request?.type || "").indexOf("DMFT") !== -1

  if (isDMFT){

    return(
      <StyledToothChart key={`dashboard.chart.${requestId}`}>
        <Box 
          px={['50px']}
          mt={'20px'}
        >
          <ReportToothChart data={report?.report_data} 
            isDMFT={isDMFT}
            width={`${220}`}
            height={`${(3/2)* 220}`}
          />
        </Box>
          
      </StyledToothChart>
    )
  } else {

    return(
      <StyledToothChart key={`dashboard.chart.${requestId}`}>
          <ReportToothChart data={report?.report_data} 
            isDMFT={isDMFT}
            width={`${220}`}
            height={`${(3/2)* 220}`}
          />
          <Categories />
          
      </StyledToothChart>
    )
  }
}

/**
 * Component for tooth chart of selected CheckupRequestHistoryItem
 *
 * @CheckupToothChart
 */
const CheckupToothChart = () => {
  const currentRequestId = useSelector(getCurrentRequestId);
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const report = useSelector(getRequestReport)
  const reportLoading = useSelector(getReportLoading);

  console.log("reportLoading", reportLoading)

  useEffect(()=>{
    // fetch report details after request is selected action
    if (currentRequestId) {
      dispatch(reportActions.fetchReport(currentRequestId))
    }
  }, [currentRequestId])

  if (currentRequestId) {
    return (
      <StyledCheckupToothChartContainer>
        {reportLoading &&
          <div className={'loading'}>
            <LoadingEllipses />
          </div>
        }
        <ToothChart />
        <ToothChartInformation />
      </StyledCheckupToothChartContainer>
    )
  } else {
    return (
      <StyledCheckupToothChartContainer>
        { reportLoading &&
          <div className={'loading'}>
            <LoadingEllipses />
          </div>
        }
        <StyledNoReports>
          <NoReportsIcon />
          <p>
            {intlMessages['dashboard.toothchart.noReport']}
          </p>
        </StyledNoReports>
      </StyledCheckupToothChartContainer>
    )
  }
}

const BlankCheckupRequestHistoryItem = () => {
  const intlMessages = useSelector(getIntlMessages)
  return (
    <StyledCheckupRequestHistoryItem isBlank={true}>
      <button disabled><BlankCircle /></button>
      <div className="info">
        <p className="blank">{intlMessages['requestItem.noDataText']}</p>
      </div>
    </StyledCheckupRequestHistoryItem>
  )
}
/**
 * Component to load more items if isMore is true
 * @param {request} lastItem request item to get created_at_ts field
 */
const IsMore = ({lastItem}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const requestListLoading = useSelector(getReportListLoading);

  const onClick = () => {
    dispatch(reportActions.fetchRequestList({'last_ts': lastItem.created_at_ts}))
  }

  return (
    <StyledIsMore>
      {!requestListLoading
        ?
          <div onClick={onClick}>
            <span>
              {intlMessages['settings.loadmore']}
            </span>
          </div>
        :
          <div >
            <LoadingEllipses />
          </div>
      }
    </StyledIsMore>
  )
}


/**
 * Component for summary below start scan CheckupInformation
 *
 * @CheckupSummary
 */
const CheckupSummary = () => {
  const intlMessages = useSelector(getIntlMessages)
  const intlLocale = useSelector(getIntlLocale)
  const requestList = useSelector(getRequestList);
  const reportList = useSelector(getReportList);
  const profile = useSelector(getProfile)
  const [firstName, setFirstName] = useState(null)

  useEffect(()=> {
    // after profile has been loaded, setFirstName
    if (profile && profile.first_name){
      setFirstName(profile.first_name)
    }
  }, [profile])

  // set default strings
  let lastScan = intlMessages['dashboard.checkup.summary.lastScan.default']
  let daysPassed = intlMessages['dashboard.checkup.summary.daysPassed.default']
  let recommendScan = intlMessages['dashboard.checkup.summary.recommendScan.default']

  // set latest request if request array is found
  if (requestList.length){
    let latestRequest = requestList[0]
  }

  // set strings/date based on last request
  if (reportList.length){
    lastScan = intlMessages['dashboard.checkup.summary.lastScan'] + " " + localeFormatDate( reportList[0].report.sent_at , intlLocale, "ll") + "."
    daysPassed = localeFormatFromNow(reportList[0].report.sent_at, intlLocale) + " " +  intlMessages['dashboard.checkup.summary.daysPassed'];
  }

  return (
    <StyledCheckupSummary>
      <span className="greeting">
        {intlMessages['dashboard.checkup.summary.greeting'].replace("{first_name}", firstName ? firstName : '')}</span><br /><br />
        {upperFirst(lastScan)} <br />
        {upperFirst(daysPassed)}<br />
        {upperFirst(recommendScan)}
    </StyledCheckupSummary>
  )
}

/**
 * Tooth Chart Report for CheckupToothChart
 *
 * @ToothChartReport
 */
const ToothChartReport = () => {
  const dispatch = useDispatch();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const history = useHistory();
  const request = useSelector(getRequestDetails);
  const report = useSelector(getRequestReport);
  const profile = useSelector(getProfile);
  const currentProfile = useSelector(getSelectedProfile)
  const currentRequestId = useSelector(getCurrentRequestId)

  // set default text
  let patient_name = '';
  let dateText = '';
  let titleText = '';
  let reportDate = ""

  useEffect(()=>{
    dispatch(reportActions.fetchLatestRequest())
  }, [])

  const handleClick = () => {

    history.push('/requests/' + request.id)
  }

  // set strings based on if report was received (completed) or not
  if (report && report.sent_at){
    reportDate = localeFormatDate(report.sent_at, intlLocale, "ll")
    dateText = intlMessages['dashboard.toothchart.date.text'] + reportDate;
    titleText = intlMessages["dashboard.toothchart.title.COMPLETE"];
  } else {
    if (request){
      if (isPaymentRequired(request)){
        titleText = intlMessages[`dashboard.toothchart.title.PENDING_PAYMENT`];
      } else {
        titleText = intlMessages[`dashboard.toothchart.title.${request.status}`];
      }
      dateText = intlMessages['dashboard.toothchart.date.text'] + localeFormatDate(request.created_at, intlLocale, "ll");  
    } else {
      titleText = '';
      dateText = intlMessages['dashboard.toothchart.date.text']
    }
  } 

  if (request?.user_profile?.id != null){
    patient_name = formatName(intlMessages['format.fullName'], request.user_profile.first_name, request.user_profile.last_name);
  } else if (profile != null) {
    patient_name =  formatName(intlMessages['format.fullName'], profile.first_name, profile.last_name);
  }

  const isDMFT = request?.type == "DMFT" || request?.type == "GEN_DMFT"

  return (
    <Flex direction="column" justify="space-between">
      <Box id="reportdataboxcontainer" overflow={'hidden'}>
        <Box>
          <Text
            color="#273238"
            fontSize="30px"
          >{intlMessages['dashboard.report.title']}</Text>
        </Box>
        <Box>
          <Text
            color="#6B8A99"
            fontSize="16px"
          >{reportDate}</Text>
        </Box>
        {/*<Box mt={'30px'}> 
          <Text
            color="#405159"
            fontSize="26px"
          >{intlMessages['reportDetail.reportchartinformation.dentistcomments']}</Text>
        </Box>*/}
        {
          isDMFT ? (
            <Box id="reportdatabox" 
              position={'relative'} 
              height={'350px'}
              _after={{
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '40px',
                zIndex: 2,
                bg: 'linear-gradient(to bottom, rgba(255,255,255,0.3), rgba(255,255,255,0.95))'
              }}
            >
              <PrintPreviewReportSection 
                data={report?.report_data}
                status={request?.status}
              />
            </Box>
          ) : (
            <Box id="reportdatabox" 
              position={'relative'} 
              height={'340px'}
              _after={{
                content: '""',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '40px',
                zIndex: 2,
                bg: 'linear-gradient(to bottom, rgba(255,255,255,0.3), rgba(255,255,255,0.95))'
              }}
            >
              <ReportData 
                data={report?.report_data} 
                editorBoxStyle={{paddingLeft: '0px', maxWidth: 'unset'}}
                editorStyle={{height: '340px', overflowY: 'hidden'}}  
                pl={'0px'}
              />
            </Box>
          )
        }
        
      </Box>
      <Flex>
        <Button variant="outlineDefault" size={"md"} rightIcon={<ArrowRightIcon height={13} width={15} />} onClick={handleClick}>
          {intlMessages['dashboard.toothchart.url'].replace(">", "")}
        </Button>
      </Flex>
    </Flex>
  )
}

/**
 * Component for start scan display
 *
 * @CheckupInformation
 */

const CheckupInformation = () => {
  const requestList = useSelector(getRequestList);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);

  let params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.get("detectionbeta") == 1){
      dispatch(modalActions.openModal('detectionScan'));
    } else if(params.get("detectionbeta") == null){
      dispatch(modalActions.closeModal('detectionScan'));
    }
  }, [location.search])

  const startScan = () => {
    //dispatch(modalActions.openModal('scanningPage'));
    history.replace({
      pathname: "/createrequest/clinics"
    });
  }

  const startDetection = () => {
    //dispatch(modalActions.openMessageModal('detectionSent'));
    let newParams = new URLSearchParams();
    newParams.set("detectionbeta", 1)
    history.push(`${location.pathname}?${newParams}`)

  }

  return (
    <StyledCheckupInformation>
      <p>{intlMessages['dashboard.checkup.information.title.1']}<br />{intlMessages['dashboard.checkup.information.title.2']}</p>
      <button onClick={startScan}>{intlMessages['dashboard.checkup.information.button.text']}</button>
      {/*<button onClick={startDetection}>{'Detection'}</button>*/}
    </StyledCheckupInformation>
  )
}

/**
 * Component for the url to more report details
 *
 * @CheckupLink
 */
const CheckupLink = () => {
  const currentRequestId = useSelector(getCurrentRequestId);
  const intlMessages = useSelector(getIntlMessages);

  if (currentRequestId){
    return (
      <StyledCheckupLink>
        <Link
          to={'/requests/' + currentRequestId}
        >{intlMessages['dashboard.toothchart.url']}</Link>
      </StyledCheckupLink>
    )
  } else {
    return (
      <StyledCheckupLink >
      </StyledCheckupLink>
    )
  }
}

const DashboardHero = ({handleClick}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <Flex
      borderRadius="20px"
      backgroundColor="#35BE9D"
      boxShadow="0px 0px 1px 0px rgba(200, 201, 211, 0.60);"
      px={'60px'}
      justifyContent="space-between"
      mr={'60px'}
    >
      <VStack 
        w="70%" 
        direction="column" 
        py={'30px'}
        justifyContent="center"
        alignItems="flex-start"
        height="280px"
        spacing="05px"
      >
        <Box>
          <Text
            color="#273238"
            fontSize="40px"
            lineHeight="50px"
          >{`${intlMessages['dashboard.checkup.introduction.heading.alt.2']}`}</Text>  
        </Box>        
        <Box>
          <Text
            color="#273238"
            fontSize="24px"
          >{intlMessages['dashboard.checkup.introduction.subheading.alt.3']}</Text>  
        </Box>    
        <Box pt={'10px'}>
          <Button variant="dark" onClick={handleClick} className={'dashboardHeroGetStarted'} >
            <Box as="span">
              {intlMessages['dashboard.checkup.information.button.text']}
            </Box>
          </Button>
        </Box>        
      </VStack>
      <Flex align="flex-end" maxWidth={'450px'} width={'35%'}>
        <img src={dashboardImage} alt="home"/>
      </Flex>
    </Flex>
  )
}

/**
 * Component combining all the separate components
 *
 * @Dashboard
 */
export const Dashboard = () => {
  console.log("storage.getTourOn('dashboard')", storage.getTourOn('dashboard'))
  const dispatch = useDispatch();
  const history = useHistory();
  const setCategory = useReportStore(state => state.setCategory);
  const intlMessages = useSelector(getIntlMessages);
  const { formatMessage } = useIntl();
  const currentRequestId = useSelector(getCurrentRequestId);
  let params = new URLSearchParams(location.search);
  const givenName = useSelector(getFirstName);
  const currentProfile = storage.getScanProfile();
  const isDentalType = useSelector(getIsDentalType);
  const isOrphanage = useSelector(getIsOrphanage)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [ joyrideRun, setJoyrideRun ] = useState(storage.getTourOn('dashboard'));
  const [ joyrideSteps ] = useState([
    {
      target: '.home',
      content: formatMessage({id: 'showcase.dashboard.intro', defaultMessage: 'View a brief summary of your oral health here.'}),
      placement: 'right',
      disableBeacon: true
    },
    {
      target: '.requests',
      content: formatMessage({id: 'showcase.dashboard.reports', defaultMessage: 'Review your check-up history.'}),
      placement: 'right'
    },
    {
      target: '.settings',
      content: formatMessage({id: 'showcase.dashboard.settings', defaultMessage: 'Customize your app preferences and settings here. Also, download your reports in bulk.'}),
      placement: 'right'
    },
    //{
    //  target: '.dashboardProfileSelect',
    //  content: formatMessage({id: 'showcase.dashboard.switchProfiles', defaultMessage: 'If you have multiple profiles, switch between the profiles here.'}),
    //},
    {
      target: '.dashboardHeroGetStarted',
      content: formatMessage({id: 'showcase.dashboard.startScan', defaultMessage: 'Click here to start a scan and get an oral health report.'}),
    }
  ])

  useEffect(() => {
    if (params.get("detectionbeta") == 1){
      dispatch(modalActions.openModal('detectionScan'));
    } else if(params.get("detectionbeta") == null){
      dispatch(modalActions.closeModal('detectionScan'));
    }
  }, [location.search])

  const handleClick = () => {
    if (isDentalType){
      history.push("/createrequest/clinics");
    } else {
      history.push("/createrequest/scan");
    }
  }

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setJoyrideRun(false);
      storage.setTourOff('dashboard');
    }
  };

  const _returnDentistUserDashboard = () => {
    return (
      <SectionPage>
        <Joyride 
          run={joyrideRun} 
          steps={joyrideSteps} 
          styles={joyrideStyles} 
          callback={handleJoyrideCallback}
          {...joyrideOptions} 
        />
        <Flex align={['center']} justify={['space-between']} mr={['60px']} pb={['15px']}>
          <SectionTitle>
            <FormattedMessage 
              id={'dashboard.greeting'}
              defaultMessage={'Hello, {givenName}'}
              values={{givenName: givenName}}
            />
          </SectionTitle>
        </Flex>
          <DashboardHero handleClick={handleClick} />
          <Flex 
            mt="20px" 
            borderRadius="20px" 
            boxShadow="0px 0px 1px 0px rgba(200, 201, 211, 0.60)" 
            bg="#fff" 
            py="40px"
            px={"40px"}
            mr={'60px'}>
              <Box 
                px="10px" 
                mr={'60px'}
                minH="330px" 
                borderRadius="15px"
                border="1px solid #C7D3D9"
                pt={['15px']}
              >
                <ToothChart />
              </Box>
              <ToothChartReport />
          </Flex>
      </SectionPage>
    )
  }

  const _returnUserDashboard = () => {
    return (
      <SectionPage height={'100%'}>
        <Joyride 
          {...joyrideOptions} 
          run={joyrideRun} 
          steps={joyrideSteps} 
          styles={joyrideStyles} 
          callback={handleJoyrideCallback}
        />
        <ProfileSelectorModal isOpen={isOpen} onClose={onClose}/>
        <Flex align={['center']} justify={['space-between']} mr={['60px']} pb={['15px']}>
          <SectionTitle>
            <Flex className={'dashboardProfileSelect'} onClick={onOpen} _hover={{cursor: 'pointer'}}>
              <Flex align={'center'}>
                <FormattedMessage 
                  id={'dashboard.greeting'}
                  defaultMessage={'Hello, {givenName}'}
                  values={{givenName: currentProfile?.first_name || givenName}}
                />
                <Flex ml={2}>
                  <SelectChevronDown color={'#7A8F99'}/>
                </Flex>
                </Flex>
              </Flex>
          </SectionTitle>
        </Flex>
        <DashboardHero handleClick={handleClick} />
          <Grid gridTemplateColumns={'500px 1fr'} gridGap={'20px'}>
            <Flex 
              mt="20px" 
              borderRadius="20px" 
              boxShadow="0px 0px 1px 0px rgba(200, 201, 211, 0.60)" 
              bg="#fff" 
              justify={'center'}
              py="30px"
              px={"40px"}>
                <ReportScoreContainer />
            </Flex>
            <Flex 
              mt="20px" 
              borderRadius="20px" 
              boxShadow="0px 0px 1px 0px rgba(200, 201, 211, 0.60)" 
              bg="#fff" 
              py="30px"
              px={"40px"}
              mr={'60px'}>
                <Box
                  marginBottom={'15px'}
                  height={'450px'}
                  width={'100%'}
                >
                      <Flex justify={'space-between'} direction={'column'} pr={['15px']}>
                        <Text 
                          as={'h2'}
                          fontSize={['25px']}
                          color={'#273238'}
                          pb={'30px'}
                        >
                          {startCase(intlMessages['reportDetail.history.title'])}
                        </Text>
                        {/*<ProfilesFilterSuper fullWidth={true} boxProps={{p: 0}}/>*/}
                      </Flex>
                      <DashboardCheckupRequestHistory height={'100%'} />
                </Box>
            </Flex>
          </Grid>
      </SectionPage>)
  }

  if (isDentalType || isOrphanage){
    return _returnDentistUserDashboard()
  } else {
    return _returnUserDashboard()
  }

}
