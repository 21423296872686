import styled , { keyframes } from 'styled-components';

const animSpeed = 8; // seconds

const blink = keyframes`
  50% {
    opacity: 1;
  }
`;

const guidePath = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const guidePathReverse = keyframes`
  to{
    stroke-dashoffset: 580;
  }
`;

export const StyledMaxilla = styled.div`
  left: 4.3px;

  #region3{
    top: 0;
    left: 0;

    height: 130px;
    width: 162px;

    .animEdge.START, .animEdge.SCANNING{
      visibility: visible;
      stroke-dasharray: 420;
      stroke-dashoffset: 420;
      animation: ${guidePath} ${animSpeed}s linear infinite;
    }
  }

  #region4{
    left: 21.9px;
    top: 13.8px;
    height: 120.0px;
    width: 120.0px;

    .animEdge.START, .animEdge.SCANNING{
      visibility: visible;
      stroke-dasharray: 290;
      stroke-dashoffset: 290;
      animation: ${guidePathReverse} ${animSpeed}s linear infinite;
    }

  }
`;

export const StyledMandible = styled.div`

  #tongue{
    top: 116.27px;
    left: 53.27px;
    height: 137px;
    width: 65px;
  }

  #region1{
    top: 135.27px;
    left: 0;

    height: 137px;
    width: 171px;

    .animEdge.START, .animEdge.SCANNING{
      visibility: visible;
      stroke-dasharray: 420;
      stroke-dashoffset: 420;
      animation: ${guidePath} ${animSpeed}s linear infinite;
    }

  }
  #region2{
    top: 136px;
    left: 25.9px;
    height: 120px;
    width: 121.7px;


    .animEdge.START, .animEdge.SCANNING{
      visibility: visible;
      stroke-dasharray: 290;
      stroke-dashoffset: 290;
      animation: ${guidePathReverse} ${animSpeed}s linear infinite;
    }

  }
`;

export const StyledFullScanChartTeeth = styled.div`
  position:relative;
  width: 170px;
  height: 267px;

  ${StyledMandible}, ${StyledMaxilla}, #tongue {
    position:absolute;
  }

  .region {
    position:absolute;

    .tooth.FINISHED{
      opacity:1;
    }

    .edge.FINISHED{
      opacity:1;
    }

    .animEdge{
      visibility:hidden;
    }

    .startTooth.START{
      animation: ${blink} 2s linear infinite;
    }

    .tooth.SCANNING{
      animation: ${blink} 2s linear infinite;
    }
  }




`;

export const StyledFullScanRegionButton = styled.div`
  background: transparent;
  color: #ffffff;
  border: 1px solid #FFFFFF;
  height: 30px;
  width: 30px;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  margin-right: 12px;
  border-radius: 35px;
  display:flex;
  align-items:center;
  justify-content:center;
  &:last-child{
    margin-right:0;
  }

  &.FILLED{
    color: #273238;
    background: #ffffff;
  }

  &.SCANNING{
    opacity: 0.2;
    animation: ${blink} 2s linear infinite;
  }

`;

export const StyledFullScanChartRegions = styled.div`
  display:flex;
  position: relative;
  padding-top: 20px;
  padding-bottom: 15px;
  justify-content:center;
`;

export const StyledFullScanRegionText = styled.div`
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
`;


export const StyledFullScanChartDirection = styled.div`
  display:flex;
  align-items:center;
  font-family: Proxima Nova Semibold;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: 1.38461px;
  color: #D8D8D8;

  &:first-child{
    margin-right: 10px;
  }

  &:last-child{
    margin-left: 10px;
  }

`;

export const StyledFullScanChartTeethWrapper = styled.div`
  display:flex;
  justify-content:center;

  ${props => !props.isFlipped && 'transform: scale(-1,1);'}
  ${StyledFullScanChartDirection}{
    ${props => !props.isFlipped && 'transform: scale(-1,1);'}

`;

export const StyledFlipChartButton = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  margin: 15px;
  pointer-events: all;

  svg{
    height: 21px;
    width: 21px;
    pointer-events: none;

    path{
      fill: #ffffff;
    }
  }

  &:hover{
    cursor:pointer;
  }
`;

export const StyledInfoButton = styled.div`
  position:absolute;
  top: 0;
  right: 0;
  margin: 15px;
  pointer-events: all;

  svg{
    height: 21px;
    width: 21px;
    pointer-events: none;

    path{
      fill: #ffffff;
    }
  }

  &:hover{
    cursor:pointer;
  }
`;

export const StyledFullScanChart = styled.div`
  background: #273238;
  border-radius: 5px;
  position:relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: fit-content;
  padding: 30px 20px;
  padding-bottom: 20px;


`;
