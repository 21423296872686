import moment from 'moment';
import {
  isToday,
  isThisWeek,
  isThisYear,
  format,
  differenceInYears,
} from 'date-fns';

import { ja, th } from 'date-fns/locale';
import {MOMENT_DATE_FORMAT} from '../_config';
/**
 * moment requires language with region or something did
 * what do i need to do for region
 */

export function formatTimeAgo(dateString, locale){
  const date = new Date(dateString);

  if (isToday(date)) {
    return 'Today';
  } else if (isThisWeek(date)) {
    return format(date, 'EEEE');  // Day of the week, like 'Monday'
  } else if (isThisYear(date)) {
    return format(date, 'MMMM d');  // Month and day, like 'December 31'
  } else {
    const yearsAgo = differenceInYears(new Date(), date);
    return `${yearsAgo} years ago`;
  }
}

export function apiFormatDate(time, locale){
  return "-"
}

export function localeFormatDate(time, locale, format){
  var regionFormat = format || MOMENT_DATE_FORMAT;
  if (!time){
    return "-"
  }
  //const actualTime = moment(time).locale(locale).format(regionFormat);
  return moment(time).locale(locale).format(regionFormat);
};

export function localeFormatFromNow(time, locale){
  if (!time){
    return "-"
  }
  //const actualTime = moment(time).locale(locale).fromNow();
  return moment(time).locale(locale).fromNow(true);
};

export function localeFormatChatListItem(time, locale){
  var timeFormat = "MMM D"
  if (!time){
    return "-"
  }
  // TODO change this based on fromNow, same day, etc.
  return moment(parseInt(time)/10000).locale(locale).format(timeFormat)
}

export function localeFormatMessageTime(time, locale){
  var timeFormat = "LT";
  if (!time){
    return ""
  }
  return moment(parseInt(time)/10000).locale(locale).format(timeFormat);
}

export function getCurrentPubnubTimeToken(){
  return new Date().getTime() * 10000;
}
