import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from 'react-redux';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  upperFirst, toUpper, upperCase
} from 'lodash';
import { convertStripeAmountToText } from '../../_helpers/utils';
import getSymbolFromCurrency from 'currency-symbol-map'

import { actions as modalActions } from '../../Modals/_actions';

import { getIntlMessages } from '../../Authentication/_selectors';

import './index.css'

export const PaymentForm = ({paymentIntent, onSuccess}) => {
  const intlMessages = useSelector(getIntlMessages);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [clientSecret, setClientSecret] = useState(paymentIntent);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(()=>{
    async function f(){
      console.log("payment intent ", paymentIntent)
      const pi = await stripe.retrievePaymentIntent(paymentIntent);
      console.log(pi)
      if (pi.paymentIntent.status == "succeeded"){
        onSuccess(pi.paymentIntent)
      } else {
        setAmount(convertStripeAmountToText(pi.paymentIntent.amount,pi.paymentIntent.currency));
        setCurrency(pi.paymentIntent.currency);
      }
    }
    if (stripe && (paymentIntent != "FREE")){
      f();
    }
    return () => {};
  }, [stripe])

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    let payload = null;
    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    try{
      payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: ev.target.name.value,
          },
        },
        setup_future_usage: 'off_session'
      });
    } catch(err){
      setError(`Payment failed: ${err}`);
      setProcessing(false);
      console.log("[error]",err)
      return
    }

    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
      console.log("[error]", payload.error);
    } else {
      setError(null);
      setSucceeded(true);
      setProcessing(false);
      setMetadata(payload.paymentIntent);
      onSuccess(payload.paymentIntent)
      console.log("[PaymentIntent]", payload.paymentIntent);
    }
  }

  const renderSuccess = () => {
    return (
      <div className="sr-field-success message">
        <h1>Your test payment succeeded</h1>
        <p>View PaymentIntent response:</p>
        <pre className="sr-callout">
          <code>{JSON.stringify(metadata, null, 2)}</code>
        </pre>
      </div>
    );
  }

  const renderError = () => {
    return (
      <div className="sr-field-success message">
        <h1>Payment Error</h1>
        <h4>An error has occurred fetching payment information. Please contact us if this continues.</h4>
      </div>
    )
  }

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Proxima Nova", "Open Sans", sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };

    return (
      <form className="stripePaymentForm" onSubmit={handleSubmit}>
        <h1>
          {(amount > 0) ? getSymbolFromCurrency(toUpper(currency)) + " " +
            (amount).toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
            })
            : intlMessages['checkup.paymentform.loading']
          }
        </h1>
        <h4>{intlMessages['checkup.paymentform.header']}</h4>

        <div className="sr-combo-inputs">
          <div className="sr-combo-inputs-row">
            <input
              type="text"
              id="name"
              name="name"
              placeholder={upperCase(intlMessages['checkup.paymentform.card.field.name.text'])}
              autoComplete="cardholder"
              className="sr-input"
            />
          </div>

          <div className="sr-combo-inputs-row">
            <CardElement
              className="sr-input sr-card-element"
              options={options}
            />
          </div>
        </div>

        {error && <div className="message sr-field-error">{error}</div>}

        <button
          className="btn"
          disabled={processing || !stripe}
        >
          {processing ? intlMessages['checkup.paymentform.button.processing'] : intlMessages['checkup.paymentform.button.pay']}
        </button>
      </form>
    );
  };

  if (clientSecret){
    return (
        <div className="checkout-form">
          <div className="sr-payment-form">
            <div className="sr-form-row" />
            {succeeded ? renderSuccess() : renderForm()}
          </div>
        </div>
    );
  } else {
    return (
        <div className="checkout-form">
          <div className="sr-payment-form">
            <div className="sr-form-row" />
            { renderError() }
          </div>
        </div>
    )
  }
}
