import styled from 'styled-components';

import { StyledContent, StyledContentTitle, StyledContentSection } from '../_styles/common'
import { StyledRecordUploadMedia, StyledLoadingMedia, StyledRecordUploadIcon } from '../_components/RecordUploadMedia/styles'

export const StyledIsMore = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
  align-items:center;
  padding: 20px 0;
  font-size: 90%;
  &:hover{
    cursor: pointer;
  }
  > div{

  }
`;

export const StyledCategoryLabel = styled.div`

  position:relative;
  border-radius:3px;
  font-size: 14px;
  font-family: Proxima Nova Semibold;
  line-height:24px;
  margin-right: 5px;
  padding: 0 7px;
  padding-right: 15px;
  background-color: #efefef;
  &::before {content:'';display:inline-block;width:10px;height:10px;border-radius:10px;vertical-align:middle;margin-left:7px;margin-right:10px;}
  &.COLOR{
    &::before {background-color:#367FFF}
  }
  &.ORTHO{
    &::before {background-color:#00574F}
  }
  &.GUMS{
    &::before {background-color:#daff00}
  }
  &.CARIES{
    &::before {background-color:#DC4E41}
  }
  &.CALCULUS{
    &::before {background-color:#ffa500}
  }
`;

export const StyledGradeLabel = styled.div`
    min-height:24px;
    font-size:13px;line-height:12px;
    color:#979797;
    font-family: Proxima Nova Bold;
    letter-spacing:0.5px;
    border-radius:60px;
    border:none;
    background-color:${props => props.selected ? '#ffffff' : '#ededed'};
    max-width: fit-content;
    display:flex;
    align-items:center;
    padding: 3px 16px;

    &.GRADE_1{
        border: 0.5px solid #6B8A99;
        background-color: transparent;
        color: #E8686D;
    }

    &.GRADE_2{
        border: 0.5px solid #6B8A99;
        background-color: transparent;
        color: #E9B54D;
    }

    &.GRADE_3{
        border: 0.5px solid #6B8A99;
        background-color: transparent;
        color: #43AC72;
    }
`;

export const StyledReportToothChart = styled.div`
  border-radius: 10px;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 20px;
  display:flex;
  align-items:center;
  > div{
    margin: 0 auto;
  }
`;

export const StyledReportChartInformationRegions = styled.div`
  margin-bottom:15px;
  li {
    display:inline-block;width:25px;height:25px;font-size:12px;border-radius:50px;background-color:#405159;
    color:#ffffff;text-align:center;line-height:25px;
    &.ALL{
      width: 35px;
    }
  }
  li:not(:last-child) {margin-right:5px;}
`;

export const StyledReportChartInformationComment = styled.div`
  color: #273238;
  font-size:20px;
  line-height:150%;
  margin-bottom:20px;
`;

export const StyledReportChartInformationCategory = styled.div`
  ${StyledReportChartInformationComment} {
    line-height:24px;
  }
  margin-right: 40px;
  border-top: 0.5px solid #C7D3D9;
  padding-top: 30px;
  padding-bottom: 10px;
`;

export const StyledReportChartInformationGeneral= styled.div`
`;

export const StyledReportChartInformation = styled.div`
  h4 {color: #405159;
    font-family: Proxima Nova;
    font-size: 25px;margin-bottom:8px;}
`;

export const StyledReportChartCategoryItem = styled.li`
  background-color: ${props => props.selected ? '#405159' : '#efefef'};
  color: ${props => props.selected ? '#ffffff' : '#4D5666'};
`;

export const StyledReportChartCategories = styled.div`
  > ul {
    display:flex;
    li {
      position:relative;
      border-radius:80px;
      width:150px;
      font-size:14px;
      font-family: Proxima Nova Semibold;
      line-height:24px;
      padding: 5px;
      &:hover{
        cursor:pointer;
        opacity: 0.8;
      }
    }
    li::before {content:'';display:inline-block;width:10px;height:10px;border-radius:10px;vertical-align:middle;margin-left:7px;margin-right:10px;}
    li.COLOR::before {background-color:#367FFF}
    li.ORTHO::before {background-color:#00574F}
    li.HABITS::before {background-color:#daff00}
    li.GUMS::before {background-color:#daff00}
    li.CARIES::before {background-color:#DC4E41}
    li.CALCULUS::before {background-color:#ffa500}
    li.ALL::before {background-color:#000000}
    li:not(:last-child) {margin-bottom:5px;}
  }
`;// report__info--left

export const StyledReportInformation = styled.div`
    width: 100%;
    position: relative;
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 10px;
`; // report--wrap

export const StyledHistoryListItem = styled.li`
  &:first-child{
   margin-top: 10px; 
  }
  border-radius: ${props => props.selected ? '5px' : '0'};
  background-color: ${props => props.selected ? '#edf3f6' : 'transparent'};
  border-bottom: ${props => props.selected ? 'none' : '1px solid #efefef'};

  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 3px;
  margin-bottom: 10px;
  margin-right: 20px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.17);
  border-radius: 5px;

  &:before {
    content:'';display:inline-block;width:9px;height:9px;border-radius:9px;
    background-color:${props => !props.isRead ? '#367fff' : '#d8d8d8' };
    visibility: hidden;
  }

  &:hover{
    cursor:${props => props.selected ? 'auto' : props.blank ? 'auto' : 'pointer'};
  }
  button.camera {
    background:#3E5159;
    border-radius:50%;border:none;
    width:30px;height:30px;
    padding:0;margin:auto;flex-grow:1;
    display:flex;justify-content:center;align-items:center;
    img{width:22px;height:22px;}
  }
  button.generative {
    background:#367FFF33;
    border-radius:50%;border:none;
    width:30px;height:30px;
    padding:0;margin:auto;flex-grow:1;
    display:flex;justify-content:center;align-items:center;
    img{width:30px;height:30px;
  }  
  .name {
    font-family: Proxima Nova Semibold;
    font-size:13px;line-height:17px;flex-grow:6;color:#000000;
  }
  .blank {font-size:14px;font-family:Proxima Nova Semibold;line-height:17px;color:#979797;}
  .date {
    flex-grow:1;font-size:12px;line-height:15px;color:#4d5666;
    span {margin-left:10px;}
  }
  .status {
    flex-grow:1;
    height:24px;font-size:10px;line-height:12px;
    color:#979797;
    font-family: Proxima Nova Bold;
    letter-spacing:0.5px;
    border-radius:3px;
    border:none;
    background-color:${props => props.selected ? '#ffffff' : '#ededed'};

    &.GRADE_1{
        background: #FFEDED ;
        color: #E8686D;
    }

    &.GRADE_2{
        background: #FDF8E2 ;
        color: #E9B54D;
    }

    &.GRADE_3{
        background: #E9F9F1 ;
        color: #43AC72;
    }

    &.PAYMENT_REQUIRED{
      color: #273237;
  }

  }

`;

export const StyledHistoryList = styled.ul`
  margin-top:0px;
  display:inline-block;
  min-width:330px;
  max-height: 860px;
  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  li {
    display:grid;
    grid-template-columns:  0px 70px 1fr;
    align-items:center;
    padding-left:4px;
    padding-right:10px;
    border-top: 1px solid #EBF2F5;
    &:first-child {
      margin-top:15px;
    }
    &:last-child {
      border-bottom: 1px solid #EBF2F5;
    }
  }

`;

export const StyledHistorySection = styled.div`
  margin-bottom:15px;
  min-height:750px;
  padding-right: 10px;
  padding-left:${'40px'};
  h2{
    color: #273238;
    font-family: Proxima Nova;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }

`; //history__box--wrap
export const StyledReportSection = styled.div`
    position:relative;
    width: 100%;

    h3 {font-size:24px;line-height:30px;}
`; //report__box--wrap

export const StyledBlankReportSection = styled.div`
  margin-top: 20px;
  margin-right:30px;
  width: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 435px;
  border-radius: 10px;
  p{
    font-family: Proxima Nova;
    font-size: 16px;
    text-align: center;
    color: #979797;
    margin-top: 10px;
  }
  p.bookScan{
    font-family: Proxima Nova Semibold;
    text-align: center;
    color: #367FFF;
    padding-bottom: 40px;
    &:hover{
      cursor:pointer;
    }
  }
`;


export const StyledImageBoxes = styled.div`
  position:relative;
  margin-top:0px;
  padding:0 0;
  overflow:hidden;

  > div{
    display:flex;
    padding 15px 0;
  }

  ${StyledRecordUploadMedia} {
    float:left;margin-left:0;
    &:hover{ cursor:pointer;}
  }
  ${StyledRecordUploadIcon}{
    float:left;margin-left:0;
    &:hover{ cursor:pointer;}
  }
  ${StyledLoadingMedia}{
    float:left;margin-left:0;
  }
  ${StyledRecordUploadMedia}:not(:last-child) {margin-right:20px;}
  ${StyledLoadingMedia}:not(:last-child) {margin-right:20px;}
  ${StyledRecordUploadIcon}:not(:last-child) {margin-right:20px;}
`; // image--wrap

export const StyledImageSection = styled.div`
  position:relative;
  margin-top:10px;
`; // teeth__box--wrap
export const StyledToothChartSection = styled.div``; // image__box--wrap

export const StyledDetailColumn = styled.div`
  width:100%;
`; //section__box--right
export const StyledChartColumn = styled.div``; //section__box--left
export const StyledSection = styled.div`
  box-shadow: 0px 1px 6px 0px rgba(200, 201, 211, 0.60);
  padding: 30px 40px;
  border-radius: 20px;
  margin-right: 30px;
  background-color: #fff;

  > section {
    height:850px;
    padding:30px 0 0 0px;
    display:grid;
    grid-template-columns:400px 500px;
    grid-gap:2%;

  }

  h2 {
    color: #273238;
    font-family: Proxima Nova;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
  }
`;
//content__section--wrap > section.content__section--result
export const StyledSectionWrapper = styled(StyledContentSection)`

`;

export const SectionTitle = styled(StyledContentTitle)`
`; //content__title

export const SectionPage = styled(StyledContent)`
  margin-top: 60px;
  padding: 0px;
  padding-left: 60px;
  height: calc( 100vh - 60px );
  .loading{
    z-index:2;
    position:absolute;
    top: 0;
    left: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.2);
    border-radius: 10px;

  }


`; // content report
