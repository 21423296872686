import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const DownloadIcon = ({width=20, height=21, color="#405159", ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 20 21" color={color}  {...props}>
        <path d="M10.2333 3.5L10.2333 14.2059M10.2333 14.2059L15 9.23529M10.2333 14.2059L5.46667 9.23529" stroke="currentColor"/>
        <path d="M4 16.5L16 16.5" stroke="currentColor"/>
    </Icon>
  )
}