import { constants } from './_constants';

const initState = {
  reviewOpen: false,
  detectionScan: null,
  detectionUploaded: false,
  uploadProgress: 0,
  uploadError: false
}

export function detection(state=initState, action){
  switch (action.type){
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPLOAD_SETPROGRESS:
      return {
        ...state,
        uploadProgress: action.data.progress
      }
    case constants.UPLOAD_SUCCESS:
      return {
        ...state,
        uploadProgress: 100,
        detectionUploaded: true
      }
    case constants.UPLOAD_FAILURE:
      return {
        ...state,
        uploadProgress: 0,
        uploadError: true,
      }
    default:
      return state;
  }
}
