const PREFIX = 'REPORTS_';
export const constants = {
  FETCHREQUESTS_REQUEST: `${PREFIX}FETCHREQUESTS_REQUEST`,
  FETCHREQUESTS_SUCCESS: `${PREFIX}FETCHREQUESTS_SUCCESS`,
  FETCHREQUESTS_FAILURE: `${PREFIX}FETCHREQUESTS_FAILURE`,

  FETCHLATESTREQUEST_REQUEST: `${PREFIX}FETCHLATESTREQUEST_REQUEST`,
  FETCHLATESTREQUEST_SUCCESS: `${PREFIX}FETCHLATESTREQUEST_SUCCESS`,
  FETCHLATESTREQUEST_FAILURE: `${PREFIX}FETCHLATESTREQUEST_FAILURE`,

  FETCHREPORT_REQUEST: `${PREFIX}FETCHREPORT_REQUEST`,
  FETCHREPORT_SUCCESS: `${PREFIX}FETCHREPORT_SUCCESS`,
  FETCHREPORT_FAILURE: `${PREFIX}FETCHREPORT_FAILURE`,

  ADDTO_REPORTLIST: `${PREFIX}ADDTO_REPORTLIST`,
  UPDATE_REQUEST_DATA: `${PREFIX}UPDATE_REQUEST_DATA`,

  UPDATE: `${PREFIX}UPDATE`,
  UPDATE_PAYMENT: `${PREFIX}UPDATE_PAYMENT`,
  UPDATE_REPORT: `${PREFIX}UPDATE_REPORT`,
  UPDATE_STATE: `${PREFIX}UPDATE_STATE`,
  UPDATE_STATE_OBJECT: `${PREFIX}UPDATE_STATE_OBJECT`


}
