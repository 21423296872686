import { useQuery } from 'react-query';
import { queryConfig, queryClient } from '../../_helpers/react-query';

export const QUERY_KEY_NAME = 'RequestDetails';

import { services } from '../_services'

const newQueryConfig = {
    ...queryConfig,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    }
}

export const queryRequest = async ({ requestId }) => {
    return services.fetchReport(requestId);
}

export const updateQueryData = (requestId, newData) => {
    queryClient.setQueryData([QUERY_KEY_NAME, {requestId}], (oldData) => {
      // Here you can update the data as needed
      return {
        ...oldData,
        ...newData,
      };
    });
  };

export const useRequestDetails = ({ requestId, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        enabled: !!requestId,
        queryKey: [QUERY_KEY_NAME, {requestId}],
        queryFn: () => queryRequest({requestId}),
      });
}