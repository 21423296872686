import './index.css'

import { categoryClasses } from './constants';

const iconString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0.2 -0.3 9 11.4" width="12" height="14">
<path d="M0 2.77V.92A1 1 0 01.2.28C.35.1.56 0 .83 0h7.66c.28.01.48.1.63.28.14.17.21.38.21.64v1.85c0 .26-.08.48-.23.66-.15.17-.37.26-.66.26-.28 0-.5-.09-.64-.26a1 1 0 01-.21-.66V1.69H5.6v7.58h.5c.25 0 .45.08.6.23.17.16.25.35.25.6s-.08.45-.24.6a.87.87 0 01-.62.22H3.21a.87.87 0 01-.61-.22.78.78 0 01-.24-.6c0-.25.08-.44.24-.6a.85.85 0 01.61-.23h.5V1.7H1.73v1.08c0 .26-.08.48-.23.66-.15.17-.37.26-.66.26-.28 0-.5-.09-.64-.26A1 1 0 010 2.77z"/>
</svg>`

// altered from Paragraph Alignment tool

class BaseCategory {
    /**
     * Default placeholder for Paragraph Tool
     *
     * @return {string}
     * @constructor
     */
    static get DEFAULT_PLACEHOLDER() {
        return 'Enter your comment';
    }

    /**
     * Allowed paragraph category
     *
     * @public
     * @returns {{left: string, center: string}}
     */

    static get CATEGORIES() {
        return {
            caries: 'caries',
            ortho: 'ortho',
            color: 'color',
            habits: 'habits',
            gums: 'gums',
            general: 'general',
            others: 'others'
        };
    }

    /**
     *
     * @returns {boolean}
     */
    static get isReadOnlySupported() {
        return true;
    }

    /**
     * Default paragraph category
     *
     * @public
     * @returns {string}
     */

    static get DEFAULT_CATEGORY() {
        return BaseCategory.CATEGORIES.others;

    }

    /**
     *
     * @param data
     * @param config
     * @param api
     * @param readOnly
     */
    constructor({data, config, api, readOnly}) {
        this.api = api;
        this.config = config;
        this.readOnly = readOnly;
        this._CSS = {
            block: this.api.styles.block,
            wrapper: 'ce-paragraph',
            alignment: {
                left: 'ce-paragraph--left',
                center: 'ce-paragraph--center',
                right: 'ce-paragraph--right',
                justify: 'ce-paragraph--justify',
            },
            category: {
                caries: categoryClasses['caries'],
                ortho: categoryClasses['ortho'],
                color: categoryClasses['color'],
                habits: categoryClasses['habits'],
                gums: categoryClasses['gums'],
                general: categoryClasses['general'],
                others: categoryClasses['others']
            }
        }
        this.CSS = {
            baseClass: this.api.styles.block,
            loading: this.api.styles.loader,
            input: this.api.styles.input,
            settingsButton: this.api.styles.settingsButton,
            settingsButtonActive: this.api.styles.settingsButtonActive,
        }

        this.settings = [
            {
                name: 'caries',
                icon: `<svg height="20" viewBox="0 0 40 40" width="20"><circle cx="15" cy="15" r="20" fill="#DC4E41"/></svg>`
            },
            {
                name: 'gums',
                icon: `<svg height="20" viewBox="0 0 40 40" width="20"><circle cx="15" cy="15" r="20" fill="#ffa500"/></svg>`
            },
            {
                name: 'habits',
                icon: `<svg height="20" viewBox="0 0 40 40" width="20"><circle cx="15" cy="15" r="20" fill="#daff00"/></svg>`
            },
            {
                name: 'ortho',
                icon: `<svg viewBox="0 0 40 40" width="20" height="20"><circle cx="15" cy="15" r="20" fill="#00574F"/></svg>`
            },
            {
                name: 'color',
                icon: `<svg viewBox="0 0 40 40" width="20" height="20"><circle cx="15" cy="15" r="20" fill="#367FFF"/></svg>`
            }, 
            {
                name: 'general',
                icon: `<svg viewBox="0 0 40 40" width="20" height="20"><circle cx="15" cy="15" r="20" fill="#A9A7A7"/></svg>`
            },
            {
                name: 'others',
                icon: `<svg viewBox="0 0 40 40" width="20" height="20"><circle cx="15" cy="15" r="20" fill="#A9A7A7"/></svg>`
            }
        ];

        this.onKeyUp = this.onKeyUp.bind(this)
        this.onCustomPaste = this.onCustomPaste.bind(this)

        /**
         * Placeholder for paragraph if it is first Block
         * @type {string}
         */
        this._placeholder = config.placeholder ? config.placeholder : BaseCategory.DEFAULT_PLACEHOLDER;

        this._data = {
            text: data.text || '',
            //alignment: data.alignment || config.defaultAlignment || Paragraph.DEFAULT_ALIGNMENT,
            category: data.category || config.defaultCategory || BaseCategory.DEFAULT_CATEGORY,
            grade: null,
            regions: [],
        };

        this._element = this.drawView();
        this.data = data;

        this._preserveBlank = config.preserveBlank !== undefined ? config.preserveBlank : false;
        this.api.caret.setToLastBlock('end')
    }

    /**
     * Check if text content is empty and set empty string to inner html.
     * We need this because some browsers (e.g. Safari) insert <br> into empty contenteditanle elements
     *
     * @param {KeyboardEvent} e - key up event
     */
    onKeyUp(e) {
        if (e.code !== 'Backspace' && e.code !== 'Delete') {
            return;
        }

        const {textContent} = this._element;

        if (textContent === '') {
            this._element.innerHTML = '';
        }
    }

    onCustomPaste() {
        console.log("onCustomPaste");
    }

    /**
     * Create Tool's view
     * @return {HTMLElement}
     * @private
     */
    drawView() {
        let div = document.createElement('DIV');

        div.classList.add(
            this._CSS.wrapper, 
            this._CSS.block, 
            this._CSS.category[this.data.category]
        );
        div.contentEditable = !this.readOnly;
        div.dataset.placeholder = this.api.i18n.t(this._placeholder);
        div.innerHTML = this.data.text;

        div.addEventListener('keyup', this.onKeyUp);
        //div.addEventListener('paste', this.onCustomPaste);

        return div;
    }

    /**
     * Return Tool's view
     * @returns {HTMLDivElement}
     * @public
     */
    render() {
        return this._element;
    }

    /**
     * Method that specified how to merge two Text blocks.
     * Called by Editor.js by backspace at the beginning of the Block
     * @param {ParagraphData} data
     * @public
     */
    merge(data) {
        console.log("merge")
        let newData = {
            text: this.data.text += data.text,
            //alignment: this.data.alignment,
            category: this.data.category,
            grade: this.data.grade,
            regions: this.data.regions
        };

        this._element.innerHTML = this.data.text;

        this.data = newData;
    }

    /**
     * Validate Paragraph block data:
     * - check for emptiness
     *
     * @param {ParagraphData} savedData — data received after saving
     * @returns {boolean} false if saved data is not correct, otherwise true
     * @public
     */
    validate(savedData) {
        if (savedData.text.trim() === '' && !this._preserveBlank) {
            return false;
        }

        return true;
    }

    /**
     * Extract Tool's data from the view
     * @param {HTMLDivElement} toolsContent - Paragraph tools rendered view
     * @returns {ParagraphData} - saved data
     * @public
     */
    save(toolsContent) {
        return Object.assign(this.data, {
            text: toolsContent.innerHTML,
        });
    }

    /**
     * On paste callback fired from Editor.
     *
     * @param {PasteEvent} event - event with pasted data
     */
    onPaste(event) {
        console.log("onPaste", event)
        const data = {
            text: event.detail.data.innerHTML,
            //alignment: this.config.defaultAlignment || Paragraph.DEFAULT_ALIGNMENT,
            category: this.config.defaultCategory || BaseCategory.DEFAULT_CATEGORY,
            grade: null,
            regions: []
        };
        this.data = data;
    }

    /**
     * Get current Tools`s data
     * @returns {ParagraphData} Current data
     * @private
     */
    get data() {
        return this._data;
    }

    /**
     * Store data in plugin:
     * - at the this._data property
     * - at the HTML
     *
     * @param {ParagraphData} data — data to set
     * @private
     */
    set data(data) {
        this._data = {
            text: data.text || '',
            //alignment: data.alignment || this.config.defaultAlignment || Paragraph.DEFAULT_ALIGNMENT,
            category: data.category || this.config.defaultCategory || BaseCategory.DEFAULT_CATEGORY,
            grade: data.grade || null,
            regions: data.regions || []
        }
        this._element.innerHTML = this._data.text || '';
    }


    /**
     * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
     */
    static get conversionConfig() {
        return {
            export: 'text', // to convert Paragraph to other block, use 'text' property of saved data
            import: 'text' // to covert other block's exported string to Paragraph, fill 'text' property of tool data
        };
    }

    /**
     * Sanitizer rules
     */
    static get sanitize() {
        return {
            text: {
                br: true,
                toothnumbers: true,
                tooth: true,
            },
            //alignment: {},
            category: {},
            grade: {},
            regions: {}
        };
    }

    /**
     * Used by Editor paste handling API.
     * Provides configuration to handle P tags.
     *
     * @returns {{tags: string[]}}
     */
    static get pasteConfig() {
        return {
            tags: ['DIV']
        };
    }

    /**
     *
     * @returns {HTMLDivElement}
     */
    renderSettings() {
        const wrapper = document.createElement('div');

        this.settings.map(tune => {
            /**
             * buttonのdomを作成して、alignのtoggleをactiveに設定する
             * @type {HTMLDivElement}
             */
            const button = document.createElement('div');
            button.classList.add('cdx-settings-button');
            button.innerHTML = tune.icon;

            //button.classList.toggle(this.CSS.settingsButtonActive, tune.name === this.data.alignment);
            button.classList.toggle(this.CSS.settingsButtonActive, tune.name === this.data.category);

            wrapper.appendChild(button);

            return button;
        }).forEach((element, index, elements) => {

            element.addEventListener('click', () => {

                this._toggleTune(this.settings[index].name);

                elements.forEach((el, i) => {
                    const {name} = this.settings[i];
                    //el.classList.toggle(this.CSS.settingsButtonActive, name === this.data.alignment);
                    //paragraphのdivにalignmentのclassをつける。
                    //this._element.classList.toggle(this._CSS.alignment[name], name === this.data.alignment)

                    el.classList.toggle(this.CSS.settingsButtonActive, name === this.data.category);
                    this._element.classList.toggle(this._CSS.category[name], name === this.data.category)
                });
            });
        });

        return wrapper;
    }

    /**
     * @private
     * Click on the Settings Button
     * @param {string} tune — tune name from this.settings
     */
    _toggleTune(tune) {
        //this.data.alignment = tune;
        this.data.category = tune;
    }

    /**
     * Icon and title for displaying at the Toolbox
     *
     * @return {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: iconString,
            title: 'Others'
        };
    }
}

export {BaseCategory};