import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const ReportIcon = ({width=13, height=14, color="#F7F7F7", ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 13 14" color={color}  {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.94824 6.70474H9.05169V5.96143H3.94824V6.70474Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.94824 9.80191H9.05169V9.05859H3.94824V9.80191Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.057 1.74756V2.36699H11.5239V12.8973H1.47574V2.36699H2.94267V1.74756H0.758789V13.5167H12.2415V1.74756H10.057Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.94336 0.758301V1.6822V2.45405V3.57168H10.0576V2.45405V1.6822V0.758301H2.94336ZM3.6611 2.48994V1.37771H9.34004V2.48994V2.98822H3.6611V2.48994Z" fill="currentColor"/>
    </Icon>
  )
}
