import { API_URLS } from '../_config';

import { constants } from './_constants';
import {
  getCurrentPubnubTimeToken,
  createPubnubMessageReportPayload,
  createPubnubMessageTextPayload,
  createPubnubMessageRecordPayload,
  endPubnubMessageVideoChatPayload,
  createPubnubMessageVideoChatPayload,
  createPubnubMessagePartialScanPayload,
  extractChannelType,
  extractDentistUuidFromChannelId,
  extractPatientUuidFromChannelId, getNotificationChannelName  } from '../_helpers';
import {
  fetchChannelData,
  fetchUserData,
  setUserData,
  setMemberships,
  fetchMemberships,
  fetchMessageHistory,
  sendMessage as sendPnMessage } from "pubnub-redux";
import { actions as dentistActions} from "../Dentists/_actions";

export const actions = {
  selectConversation,
  loadChatChannels,
  sendMessage,
  sendRecord,
  fetchMessageActions,
  updateLoadingMessage,
  discardLoadingMessage,
  updateMessageDraft,
  discardMessageDraft,
  fetchMessagesForConversation,
  updateMembership,
  loadMemberships,
  setLatestMessages,
  setShowRecordsAttachment,
  addMessageActionPartialScan,
  sendPartialScan
}

function fetchMessageActions(args){
  // args : {channel, start, end, limit}
  return (dispatch, getState, context) => {
    context.pubnub.api.getMessageActions(
      args
    , 
    (status, response) => {
      if (status.error){
        console.log("operation failed w/ error:", status);
      } else {
        // TODO make this by message action id since a pure list will have duplicates potentially
        dispatch(success({messageActions: response?.data || [], channel: args.channel}))
      }
    })
  }
  function success(data){ return { type: constants.UPDATE_CHANNEL_MESSAGE_ACTIONS, data}}
}

function setShowRecordsAttachment(show){
  return (dispatch) => {
    dispatch(update( {showRecordsAttachment: show} ));
  }
  function update(data) { return { type: constants.UPDATE, data}}
}

function setLatestMessages(channels){
  return (dispatch) => {
    dispatch(update( { latestsMessagesByChannel: channels } ));
  }
  function update(data) { return { type: constants.UPDATE, data}}
}

function selectConversation(chatId) {
  return (dispatch, getState, context) => {
    dispatch(select(chatId));
  }
  function select(data) { return { type: constants.CHAT_LIST_SELECT_CONVERSATION, data}}
}

function fetchMessagesForConversation(channel){
  return (dispatch) => {
    return dispatch(
      fetchMessageHistory({
        count: 15,
        channel,
        stringifiedTimeToken: true
      })
    );
  }
}

function updateMembership(chatUuid, channel){
  return (dispatch, getState, context) => {
    if (channel){
      const customData = { last_read_time_token: getCurrentPubnubTimeToken() + 1 }
      dispatch(setMemberships(
          {
            uuid: chatUuid,
            channels: [{id:channel, custom: customData}],
            include: {
              customFields: true,
            }
          }
        )).then(
          v => {},
          err=>{}
        )
      }
    }
    function success(data) { return { type: constants.CHAT_LIST_LOAD_SUCCESS, data } }
}

function addMessageActionPartialScan(messageTimeToken){
  return (dispatch, getState, context) => {
    const state = getState();
    context.pubnub.api.addMessageAction({
      channel: state.chatsList.currentConversationId,
      messageTimeToken: messageTimeToken,
      action: {
        type: 'partialscan_reply',
        value: 'uploaded'
      }
    },
    (status, response) => {
      if (status.error){
        console.log("pubnub operation addmessageaction failed with error: ", status);
      } else {
        console.log("message action added")
      }
    })
  }
}

function loadMemberships(chatUuid, chatAuthKey, chatChannelGroup){
  return (dispatch, getState, context) => {
    context.pubnub.api.setUUID(chatUuid).setAuthKey(chatAuthKey);
    async function f(){
      try{
        const resp = await dispatch(fetchMemberships(
          {uuid: chatUuid, limit: 1000, include: {customFields: true}}
        ))
        dispatch({type: constants.UPDATE, data: {membershipsLoaded: true} })
      } catch (err) {
        dispatch({type: constants.UPDATE, data: {membershipsLoaded: true} })
      }
    }
    f();
  }
}

function loadChatChannels(chatUuid, chatAuthKey, chatChannelGroup){
  return (dispatch, getState, context) => {
    dispatch(request());
    context.pubnub.api.setUUID(chatUuid).setAuthKey(chatAuthKey);
    //dispatch(setUserData({ uuid: chatUuid, data: {name:"Bill Park", externalId: "1", custom:{userType: "DENT"}} }))
    //  .then(() => {
        context.pubnub.api.channelGroups.listChannels(
          {channelGroup: [chatChannelGroup]},
          (status, response) => {
            if (status.error) {
              console.log("operation failed w/ error:", status);
              dispatch(failure(status.error));
              return;
            } else {
              context.pubnub.api.fetchMessages(
                {
                  channels: response.channels,
                  count: 1
                },
                (status, response) => {
                    // handle response
                    if (status.error){
                      console.log("operation failed w/ error:", status);
                    } else {
                      dispatch(actions.setLatestMessages(response.channels))
                    }
                }
              )
              response.channels.forEach( function (channel) {
                if (channel){
                  try{
                    //dispatch(fetchChannelData({channel:channel}));

                    //dispatch(fetchMessagesForConversation(channel));
                    if (extractChannelType(channel) == "DENTIST"){
                      dispatch(dentistActions.fetchChatUserInformation(extractDentistUuidFromChannelId(channel), "uuid"));
                      dispatch(dentistActions.fetchLatestCheckupWithDentist(extractDentistUuidFromChannelId(channel), "uuid"))
                    } else if (extractChannelType(channel) == "CLINICALSUPPORT"){

                    }
                    //dispatch(patientActions.loadPatientDetailsByUUid(extractPatientUuidFromChannelId(channel)));
                  } catch (err){
                    console.log("dispatch error fetchChannelData ", err);
                  }
                }
              })
              dispatch(success(response.channels));
              context.pubnub.api.subscribe({
                channelGroups: [chatChannelGroup]
              })
            }
          }
        )
    //  })
  }
  function request() { return { type: constants.CHAT_LIST_LOAD_REQUEST } }
  function success(data) { return { type: constants.CHAT_LIST_LOAD_SUCCESS, data } }
  function failure(error) { return { type: constants.CHAT_LIST_LOAD_FAILURE, error } }
}

function sendMessage(message, sender){
  return (dispatch, getState, context) => {
    const state = getState();
    return dispatch(
      sendPnMessage({
        channel: state.chatsList.currentConversationId,
        message: createPubnubMessageTextPayload(context.pubnub, message, state.chatsList.currentConversationId, sender)
      })
    )
  }
}

function sendRecord(message){
  return (dispatch, getState, context) => {
    const state = getState();
    return dispatch(
      sendPnMessage({
        channel: state.chatsList.currentConversationId,
        message: createPubnubMessageRecordPayload(context.pubnub, message, state.chatsList.currentConversationId)
      })
    )
  }
}

function sendPartialScan(message){
  return (dispatch, getState, context) => {
    const state = getState();
    return dispatch(
      sendPnMessage({
        channel: state.chatsList.currentConversationId,
        message: createPubnubMessagePartialScanPayload(context.pubnub, message, state.chatsList.currentConversationId)
      })
    )
  }
}

function updateMessageDraft(conversationId, draft){
  return dispatch => {
    dispatch(update({conversationId, draft}));
  }
  function update(data) { return { type: constants.MESSAGE_DRAFT_UPDATED, data}}
}

function discardMessageDraft(conversationId){
  return dispatch => {
    dispatch(discard({conversationId}))
  }
  function discard(data) {return {type: constants.MESSAGE_DRAFT_DISCARDED, data}}
}

function updateLoadingMessage(conversationId, message){
  return dispatch => {
    dispatch(update({conversationId, message}));
  }
  function update(data) { return { type: constants.LOADING_MESSAGE_UPDATE, data}}
}

function discardLoadingMessage(conversationId){
  return dispatch => {
    dispatch(discard({conversationId}))
  }
  function discard(data) {return {type: constants.LOADING_MESSAGE_DISCARDED, data}}
}
