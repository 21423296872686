import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { usePubNub } from 'pubnub-react';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import cn from 'classnames';
import { toUpper, startCase } from 'lodash';
import { VideoIcon } from '../_images/icons/VideoIcon';

import {
  extractDentistUuidFromChannelId, localeFormatDate, extractPatientUuidFromChannelId,
  localeFormatChatListItem, formatName, decryptMessage
} from '../_helpers';

//components

import { ChatConversation } from './ChatConversation';
import { LoadingEllipses } from '../_components/LoadingEllipses';

import { actions as videoChatActions } from '../VideoChats/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { actions as unreadMessageCountActions } from '../UnreadMessageCount/_actions';
import { actions } from './_actions';

import { getChannelIOLoaded } from '../Settings/_selectors';
import { getUpcomingVideoChatList, getPendingVideoChatList,getUpcomingLoading, getPendingLoading } from '../VideoChats/_selectors';
import { getUnreadMessageCounts } from '../UnreadMessageCount/_selectors';
import { getIntlMessages, getIntlLocale, getChatsList, getLatestMessagesChatList, getDentistsById,
        getIsMembershipsLoaded, getUUID,
        getCurrentConversationId, getChannelDataById, isChatsListLoading, getMembershipById
} from './_selectors';

import { InitialsAvatar } from '../_styles/common'
import {
  Wrapper, StyledChatListItem, StyledChatList, StyledChatUI, StyledUnreadNotification,
  selectedCss, StyledChatListItemDetails, StyledChatAvatar, StyledChatListItemMessage,
  StyledChatListItemTime, StyledChatListItemName, StyledChatListItemSubtitle, StyledBlankChatListItem,
  StyledChatAvatarWrapper, StyledAppointmentList, StyledChatsSection, StyledAppointmentListItemAvatar,
  SectionPage, SectionTitle, StyledSectionWrapper, StyledAppointmentListItem, StyledBlankAppointmentListItem
} from './styles';

const ChatListItemAvatar = ({src, initials}) => {
  if (src && src.length > 0){
    return (
        <img src={src} />
    )
  } else {
    return (
        <InitialsAvatar>
          {initials}
        </InitialsAvatar>
    )
  }
}

const ChatListItem = ({entry, item, membership, unread}) => {
  const dispatch = useDispatch();
  const pn = usePubNub();
  const history = useHistory();
  const myUuid = useSelector(getUUID);
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const membershipsById = useSelector(getMembershipById);
  const membershipsLoaded = useSelector(getIsMembershipsLoaded);
  const currentConversation = useSelector(getCurrentConversationId);

  /*useEffect(()=> {
    if (entry.id){
      if (membershipsById[entry.id] && membershipsById[entry.id].custom){
        console.log("LAST READ TIME TOKEN: ",entry.id,  membershipsById[entry.id].custom.last_read_time_token)
        if (membershipsById[entry.id].custom.last_read_time_token){
          dispatch(unreadMessageCountActions.getUnreadCounts([entry.id], [membershipsById[entry.id].custom.last_read_time_token]))
        } else {
          dispatch(unreadMessageCountActions.getUnreadCounts([entry.id], [1]))
        }
      }
    }
  }, [membershipsById])*/

  const onClick = () => {
    if (entry.id.startsWith("clinical")){
      window.ChannelIO && window.ChannelIO('openChat');
    } else if (currentConversation != entry.id){
      history.push(`/chats/${entry.id}`)
      //dispatch(patientActions.updateCurrentPatient(extractPatientUuidFromChannelId(id)));
    }
  }
  let name = "";
  let lastMessageText = "";
  let lastTimeToken = "";
  let initials = "B";
  let avatar = null;
  if (item && item.avatar && item.avatar.length > 0){
    avatar = item.avatar;
  }
  if (item && item.first_name != null){
    name = formatName(intlMessages['format.fullName'], item.first_name, item.last_name)
    initials = name.charAt(0);
  } else if (item && item.name != null){
    name = item.name
    initials = name.charAt(0);
  } else {
    name = "-"
  }
  if (entry && entry.lastMessage && entry.lastMessage.message){
    lastTimeToken = localeFormatChatListItem(entry.lastMessage.timetoken, intlLocale)
    const msg = decryptMessage(pn, entry.lastMessage,  process.env.REACT_APP_PUBNUB_CIPHER_KEY);
    if (msg && msg.type){
      let senderMe = (myUuid == msg.senderId || extractPatientUuidFromChannelId(entry.id) == msg.senderId)
      if (msg.type.toUpperCase() == "TEXT"){
        lastMessageText = msg.text;
      } else if (msg.type.toUpperCase() == "REPORT") {
        lastMessageText = senderMe ? intlMessages['chats.messages.attachment.sent'] : intlMessages['chats.messages.attachment.received']
      } else if (msg.type.toUpperCase() == "PARTIALSCAN") {
        lastMessageText = senderMe ? intlMessages['chats.messages.record.sent'] : intlMessages['chats.messages.record.request']
      } else {
        lastMessageText = senderMe ? intlMessages['chats.messages.attachment.sent'] : intlMessages['chats.messages.attachment.received']
      } // add video chat
    }
  }

  return(
    <StyledChatListItem selected={currentConversation == entry.id} onClick={onClick}>
      <StyledChatAvatar>
        <StyledChatAvatarWrapper>
          { (unread > 0 || (membershipsLoaded && membershipsById[entry.id] && membershipsById[entry.id].custom == null)) && <StyledUnreadNotification />}
          <ChatListItemAvatar
            src={avatar}
            initials={initials}
          />
        </StyledChatAvatarWrapper>
      </StyledChatAvatar>
      <StyledChatListItemDetails>
        <StyledChatListItemName>{name}</StyledChatListItemName>
        <StyledChatListItemSubtitle>{intlMessages['chats.chatList.item.name.title']}</StyledChatListItemSubtitle>
        <StyledChatListItemMessage>{lastMessageText}</StyledChatListItemMessage>
      </StyledChatListItemDetails>
      <StyledChatListItemTime>{lastTimeToken}</StyledChatListItemTime>
    </StyledChatListItem>
  )
}

const ChatList = () => {
  const dispatch = useDispatch();
  // TODO change chatsList to setChannelData and then only need to get data from channelsDataById
  const chatsList = useSelector(getLatestMessagesChatList);
  const chatsListLoading = useSelector(isChatsListLoading);
  const channelsDataById = useSelector(getChannelDataById);
  const membershipsById = useSelector(getMembershipById)
  const dentistsById = useSelector(getDentistsById);
  const intlMessages = useSelector(getIntlMessages);
  const unreadMessageCounts = useSelector(getUnreadMessageCounts);
  const currentConversation = useSelector(getCurrentConversationId);


  if (chatsListLoading || chatsList == null || (chatsList && chatsList.length == 0)){
    return (
      <StyledChatList>
        <h2>{intlMessages['chats.chatList.title']}</h2>
        <StyledBlankChatListItem>
          <div>{intlMessages['chats.chatList.chatItem.blank']}</div>
        </StyledBlankChatListItem>
      </StyledChatList>
    )
  }
  return (
      <StyledChatList>
        <h2>{intlMessages['chats.chatList.title']}</h2>
        {chatsList.map( (item, index) => (
          (dentistsById[extractDentistUuidFromChannelId(item.id)]) ?
            <ChatListItem
              key={item.id}
              entry={item}
              membership={membershipsById[item.id] || {id: item.id}}
              unread={unreadMessageCounts[item.id]}
              item={dentistsById[extractDentistUuidFromChannelId(item.id)]}
            />
            : <ChatListItem
                key={item.id}
                entry={item}
                membership={membershipsById[item.id] || {id: item.id}}
                unread={unreadMessageCounts[item.id]}
                item={channelsDataById[item.id]}
              />
        ))}
      </StyledChatList>
  )
}

const AppointmentListItemAvatar = ({status, name, avatar}) => {
  if (status === "INIT"){
    return (
      <StyledAppointmentListItemAvatar>
          <VideoIcon />
      </StyledAppointmentListItemAvatar>
    )
  } else if (avatar){
    return (
      <StyledAppointmentListItemAvatar>
        <img src={avatar} alt="drphoto" />
      </StyledAppointmentListItemAvatar>
    )
  } else {
    return (
      <StyledAppointmentListItemAvatar>
        <InitialsAvatar>
          {name.charAt(0)}
        </InitialsAvatar>
      </StyledAppointmentListItemAvatar>
    )
  }
}

export const AppointmentListItem = ({appointment}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages)
  const intlLocale = useSelector(getIntlLocale)

  let name = startCase(intlMessages['chats.chatList.item.name.title']);
  let status = false;
  let avatar = "";
  let date = intlMessages['chats.appointmentList.appointmentItem.pending'];
  let time = "";
  let availableNow = false;
  let dentistUuid = "";

  if (appointment.status == "INIT"){
    avatar = ""
  } else if (appointment.status == "PENDING" || appointment.status == "ACCEPTED"){
    if (appointment.dentist){
      dentistUuid = appointment.dentist.uuid;
      if (appointment.dentist.first_name){
        name = formatName(intlMessages['format.fullName'], appointment.dentist.first_name, appointment.dentist.last_name);
      }
      if (appointment.dentist.avatar){
        avatar = appointment.dentist.avatar;
      }
    }
    if (appointment.video_chat && appointment.video_chat.date){
      date = localeFormatDate(appointment.video_chat.date, intlLocale, "dddd LL")
      time = localeFormatDate(appointment.video_chat.date, intlLocale, "LT")
      if (moment().isAfter(moment(appointment.video_chat.date).subtract(10, "minutes"))
          && appointment.status != "COMPLETE"){
        availableNow = true;
      }
    }
  }

  const openVideoChat = () => {
    if (availableNow){
      dispatch(videoChatActions.fetchLatestCheckupwithDentist('uuid', dentistUuid, {"q": "currentVideo"}))
      dispatch(modalActions.openModal('videoChat'));
    }
  }

  const clickChange = () => {
      window.ChannelIO && window.ChannelIO('openChat', null, intlMessages['chats.appointmentList.appointmentItem.change.message'] + appointment.unique_id);
  }

  const clickCancel = () => {
      window.ChannelIO && window.ChannelIO('openChat', null, intlMessages['chats.appointmentList.appointmentItem.cancel.message'] + appointment.unique_id);
  }

  return (
    <StyledAppointmentListItem>
      <div className="profile">
        <AppointmentListItemAvatar
          avatar={avatar}
          status={appointment.status}
          name={name}
        />
        {
          (status === "INIT")
            ? <span>{intlMessages['chats.appointmentList.appointmentItem.info']}</span>
            : <div className="info"><p>{name}</p><span>{intlMessages['chats.appointmentList.appointmentItem.info']}</span></div>
        }
      </div>
      <div className={cn('detail', {availableNow})}>
        <span className="purpose">
          {intlMessages['chats.appointmentList.appointmentItem.purpose']}<br />
          <span className="date">{date}</span>
        </span>
        { (time)
            ? <span className="time" onClick={openVideoChat}>{time}</span>
            : <span></span>
        }
      </div>
      <div className="btn--wrap">
        <button onClick={clickChange}>{toUpper(intlMessages['chats.chatList.appointment.action.change'])}</button>
        <button onClick={clickCancel}>{toUpper(intlMessages['chats.chatList.appointment.action.cancel'])}</button>
      </div>
    </StyledAppointmentListItem>
  )
}

const BlankAppointmentListItem = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);

  const handleClick = () => {
    dispatch(modalActions.openModal('scanningPage'))
  }
  return(
    <React.Fragment>
      <StyledBlankAppointmentListItem>
        <div className={'blankAppointment'}>{intlMessages['chats.appointmentList.appointmentItem.blank']}</div>
      </StyledBlankAppointmentListItem>
      <StyledBlankAppointmentListItem onClick={handleClick}>
          <div className={'blankScheduleAppointment'}>{intlMessages['chats.appointmentList.appointmentItem.blank.schedule']}</div>
      </StyledBlankAppointmentListItem>
    </React.Fragment>
  )
}

export const AppointmentList = () =>{
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const upcomingList = useSelector(getUpcomingVideoChatList);
  const pendingList = useSelector(getPendingVideoChatList);

  const upcomingLoading = useSelector(getUpcomingLoading)
  const pendingLoading = useSelector(getPendingLoading)

  const [showPending, setShowPending] = useState(false);

  useEffect(() => {
    dispatch(videoChatActions.fetchVideoChatList());
    dispatch(videoChatActions.fetchAppointmentsList());
    return () => {};
  }, [] )

  if (upcomingList.length > 0 || pendingList.length > 0){
    return (
      <StyledAppointmentList>
        <h2>{intlMessages['chats.appointmentList.title']}</h2>
        { (upcomingLoading || pendingLoading) && <LoadingEllipses />}
        {upcomingList.map((item, ) =>
          <AppointmentListItem
            appointment={item}
          />
        )}
        <div className={'pending'} >
        {showPending
          ? pendingList.map((item, ) =>
            <AppointmentListItem
              appointment={item}
            />)
          : (pendingList.length > 0)
              ? <div className={'showPendingButton'} onClick={() => setShowPending(true)}>
                  <span>{intlMessages['chats.appointmentList.showPending']}</span>
                </div>
              : <div></div>
        }
        </div>
      </StyledAppointmentList>
    )
  } else {
    return (
      <StyledAppointmentList>
        <h2>{intlMessages['chats.appointmentList.title']}</h2>
        { (upcomingLoading || pendingLoading) && <LoadingEllipses />}
        <BlankAppointmentListItem />
      </StyledAppointmentList>
    )
  }
}

export const ChatsSection = () => {

  return (
    <section>
      <Scrollbars autoHide style={{height: '100%'}} >
        <StyledChatsSection>
          <AppointmentList />
          <ChatList />
        </StyledChatsSection>
      </Scrollbars>
    </section>
  )
}

export const Chats = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const intlMessages = useSelector(getIntlMessages);
  const myUuid = useSelector(getUUID);
  const currentConversationId = useSelector(getCurrentConversationId)
  const channelIOLoaded = useSelector(getChannelIOLoaded);

  useEffect(() => {
    return () => {
      dispatch(unreadMessageCountActions.markChannelRead(currentConversationId));
      dispatch(actions.updateMembership(myUuid, currentConversationId));
      dispatch(actions.selectConversation(null));
      window.ChannelIO('hideMessenger');
      //window.ChannelIO('showChannelButton')
      window.ChannelIO('hideChannelButton');
    }
  }, [])

  useEffect(()=>{
    if (params.chatId){
      dispatch(actions.selectConversation(params.chatId));
    }
  }, [params.chatId])

  useEffect(()=>{
    console.log("appsettings need to hide channel button ", channelIOLoaded)
    if (window.ChannelIO){
      window.ChannelIO('hideChannelButton');
    }
  }, [channelIOLoaded])

  return (
    <SectionPage>
    <SectionTitle>{intlMessages['chats.title']}</SectionTitle>
      <StyledSectionWrapper>
        <ChatsSection />
        <ChatConversation />
      </StyledSectionWrapper>
    </SectionPage>
  )
}
