import { 
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalContent, 
    ModalCloseButton, 
    ModalBody,
    Flex,
    Text
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { DashboardProfileList } from '../../Settings/ProfileList';

export const ProfileSelectorModal = ({isOpen, onClose}) => {
    const onSelect = (profile) => {
        console.log(profile);
        onClose();
    }

    return (
        <Modal isOpen={isOpen} isCentered size={'xl'} closeOnOverlayClick={true} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={'#F7F7F7'} pt={'20px'}>
                <ModalHeader
                >
                    <Flex justify={'center'}>
                        <Text
                            color={'#273238'}
                            fontSize={'30px'}
                            lineHeight={'30px'}
                            fontWeight={'normal'}
                        >
                            <FormattedMessage
                                id={'dashboard.profiles.selector.title'}
                                defaultMessage={'Select a Profile'}
                            />
                        </Text>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Flex>
                        <DashboardProfileList 
                            onSelect={onSelect} 
                            closeModal={onClose}    
                        />
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}