import React, { useState, useEffect }  from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import styled, {css} from 'styled-components'

import showdown from 'showdown';
import { web } from '../_data/opensource/web/web';

import { LoadingEllipses } from '../_components/LoadingEllipses'


import { InformationalPageContent } from '../_styles/common';

const Page = styled.div`
  min-height:100vh;
  color: '#12171a';
`;

export const OpenSourceWebPage = () => {
  const [ html, setHtml ] = useState();
  useEffect(()=>{
    const converter = new showdown.Converter();
    setHtml(converter.makeHtml(web))
  }, [])

  return (
    <Page>
      <InformationalPageContent>
        {html
          ? <div dangerouslySetInnerHTML={{
              __html: html
            }}>
            </div>
          : <LoadingEllipses />}
      </InformationalPageContent>
    </Page>
  )
}
