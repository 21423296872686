import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { toUpper } from 'lodash';

import { FormattedMessage, useIntl } from 'react-intl';

import { MdHelpOutline } from 'react-icons/md';

import { Text, Button, Flex, OrderedList, ListItem } from '@chakra-ui/react';

// components
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { LoadingDots } from '../_components/LoadingDots';
import { 
  TidbitCameraPermission, 
  TidbitConnectedPlover, 
  TidbitConnectPlover,
  TidbitErrorPlover 
} from './InformationTidbits';

// actions
import { actions as modalActions } from '../Modals/_actions';

// styles
import { CloseIcon } from '../Modals/styles';
import { ModalHeader } from '../Modals/ModalHeader';
import { NextButton, StyledWaitingConnection } from './styles';
import { getIntlMessages } from '../Authentication/_selectors';

// error, waiting, loading, connected
export const PloverConnectionBar = ({ error, nextPage, isLoading, deviceId }) => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();

  const openQuickStart = () => {
    dispatch(modalActions.openHowToModal('quickstartPlover'));
  }

  if (error){
    return (
      <Flex justify={['center']} align={['center']} px={['20px']} mt={['10px']}>
        <Button isDisabled={true} w={['full']} onClick={nextPage} textTransform={"uppercase"}>
            <FormattedMessage
              id={"tidbits.connecting.plover.continue"}
              defaultMessage={"Continue"}
            />
        </Button>
      </Flex>
    )
  } else if(deviceId && !isLoading){
    return (
      <Flex justify={['center']} align={['center']} px={['20px']} mt={['10px']}>
        <motion.div 
          style={{width: '100%'}}
          animate={{scale: [1, 1.02, 1, 1.02, 1, 1.02, 1]}} 
          transition={{ delay: .2, repeat: Infinity, repeatDelay: 1 }}
        >
          <Button w={['full']} onClick={nextPage} textTransform={"uppercase"}>
            <FormattedMessage
              id={"tidbits.connecting.plover.continue"}
              defaultMessage={"Continue"}
            />
          </Button>
        </motion.div>
      </Flex>
    )
  } else if (isLoading){
    return (
      <div className={'loadingRow connection'}>
        <div className={'flex items-center'}>
          <div>{ intlMessages['tidbits.connecting.plover.connecting'] }</div>
          <MdHelpOutline className={'helpIcon'}  size={18} color={'#000000B3'} onClick={openQuickStart} />
        </div>
        <div className={'loadingIcon'}>
          <LoadingDots width={100} height={15} animate={isLoading}/>
        </div>
      </div>
    )
  } else { //!deviceId && !isLoading
    return (
      <div className={'loadingRow connection'}>
        <div className={'flex items-center'}>
          <div>{ intlMessages['tidbits.connecting.plover.pleaseconnect']}</div>
          <MdHelpOutline className={'helpIcon'}  size={18} color={'#000000B3'} onClick={openQuickStart} />
        </div>
        <div className={'loadingIcon'}>
          <LoadingDots width={100} height={15} animate={isLoading}/>
        </div>
      </div>
    )
  }
}

const ModalContainer = ({children, closeModal}) => {
  const { formatMessage } = useIntl();
  return (
    <div style={{position: 'relative', maxWidth: '620px'}}>
      <ModalHeader
        title={formatMessage({"id": "connectingPlover.modal.title", "defaultMessage": "Connecting Plover"})} 
      />
      <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        {children}  
    </div>
  )
}

export const PloverWaitingConnection  = ({
      currentDeviceId, isAwaitingPlover, deviceError, nextPage, closeModal
  }) => {
  // if deviceError.name == PermissionDismissedError  NotAllowedError
  //  there was an error, say reconnect the device or refresh th browser
  // if currentDeviceId == null
  //  if isAwaitingPlover == true
  //    wait for 2 seconds to connect
  //  else
  //    then everything is ready, should already be on next screen
  //
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();

  useEffect(()=>{
    let doNotShow = localStorage.getItem(`doNotShow__quickScanTidbits`);
    if (!doNotShow){
      dispatch(modalActions.openHowToModal('quickstartPlover'));
    } else {

    }
    return () => {};
  }, [])

  if (deviceError){
    return (
      <ModalContainer closeModal={closeModal}>
          <StyledWaitingConnection>
            <Text
              fontSize={["32px"]}
              color={"#273238"}
            >
              <FormattedMessage id={"connectingPlover.connection.error.title"} defaultMessage={"Connection Error"} />
            </Text>
            <Text
              fontSize={["16px"]}
              color={"#4D5666"}
            >
              <FormattedMessage id={"connectingPlover.connection.error.description"} defaultMessage={"We are having trouble connecting to your Plover device. Here are some potential issues:"} />
            </Text>
            <OrderedList mt={['14px']} spacing={["4px"]}>
              <ListItem fontSize={["14px"]} color={'#4D5666'}>
                <FormattedMessage id={"connectingPlover.connection.error.description.1"} defaultMessage={"Check that the site has the correct browser permissions to access your camera."} />
              </ListItem>
              <ListItem fontSize={["14px"]} color={'#4D5666'}>
                <FormattedMessage id={"connectingPlover.connection.error.description.2"} defaultMessage={"Check that Plover and the cable are properly connected to the computer."} />
              </ListItem>
              <ListItem fontSize={["14px"]} color={'#4D5666'}>
                <FormattedMessage id={"connectingPlover.connection.error.description.3"} defaultMessage={"Close any other browser tabs, windows, and other applications that may be using your camera."} />
              </ListItem>
              <ListItem fontSize={["14px"]} color={'#4D5666'}>
                <FormattedMessage id={"connectingPlover.connection.error.description.4"} defaultMessage={"Refresh the page and try again."} />
              </ListItem>
              <ListItem fontSize={["14px"]} color={'#4D5666'}>
                <FormattedMessage id={"connectingPlover.connection.error.description.5"} defaultMessage={"Restart your computer and only start the browser."} />
              </ListItem>
              <ListItem fontSize={["14px"]} color={'#4D5666'}>
                <FormattedMessage id={"connectingPlover.connection.error.description.6"} defaultMessage={"If the problem persists, please contact support."} />
              </ListItem>
            </OrderedList>
            <div className={'loadingRow'}>
              <TidbitErrorPlover />
            </div>
          <PloverConnectionBar error={true} />
        </StyledWaitingConnection>
      </ModalContainer>
    )
  } else if(currentDeviceId && !isAwaitingPlover){
    // ready for next page
    return (
      <ModalContainer closeModal={closeModal}>
          <StyledWaitingConnection>
            <Text
              fontSize={["32px"]}
              color={"#273238"}
            >
              <FormattedMessage id={"connectingPlover.connection.connected.title"} defaultMessage={"Connected"} />
            </Text>
            <Text
              fontSize={["16px"]}
              color={"#4D5666"}
            >
              <FormattedMessage id={"connectingPlover.connection.connected.description"} defaultMessage={"Plover has successfully connected and is ready to use!"} />
            </Text>
            <div className={'loadingRow'}>
              <TidbitConnectedPlover />
            </div>
          <PloverConnectionBar deviceId={'asdf2s'} isLoading={false} nextPage={nextPage}/>
        </StyledWaitingConnection>
      </ModalContainer>
    )
  } else {
    return (
        <ModalContainer closeModal={closeModal}>
          <StyledWaitingConnection>
            <Text
              fontSize={["32px"]}
              color={"#273238"}
            >
              <FormattedMessage id={"connectingPlover.connection.connecting.title"} defaultMessage={"Connecting to Plover"} />
            </Text>
            <Text
              fontSize={["16px"]}
              color={"#4D5666"}
            >
              <FormattedMessage id={"connectingPlover.connection.connecting.description"} defaultMessage={"Please connect your Plover device to your computer via the provided cable."} />
            </Text>
            <div className={'loadingRow'}>
              <TidbitConnectPlover />
            </div>
            <PloverConnectionBar deviceId={null} isLoading={isAwaitingPlover} />
          </StyledWaitingConnection>
        </ModalContainer>
    )
  }
}
