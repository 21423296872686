import React, { useState, useEffect }  from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { toUpper } from 'lodash';
import { useUrlSearchParams } from '../_helpers/useUrlSearchParams';
import { FaGlobe } from 'react-icons/fa';

import { getCountry } from '../Authentication/_selectors';
import styled, {css} from 'styled-components'


import showdown from 'showdown';
import { terms, terms_th, terms_jp } from '../_data/terms/terms';


import { LoadingEllipses } from '../_components/LoadingEllipses'

import { InformationalPageContent } from '../_styles/common';
import { useSelector } from 'react-redux';

const Page = styled.div`
  min-height:100vh;
`;

const COUNTRY_LIST = [
  {value: 'TH', label: 'ประเทศไทย (TH)'}, 
  {value: 'JP', label: '日本 (JP)'}
]

export const TermsPage = () => {
  const [ html, setHtml ] = useState();
  const [ country, setCountry]  = useState();

  const userCountry = useSelector(getCountry);
  const search = useUrlSearchParams();

  useEffect(()=>{
    const converter = new showdown.Converter();
    if (toUpper(country) == 'TH'){
      setHtml(converter.makeHtml(terms_th))
    } else if (toUpper(country) == 'JP') {
      setHtml(converter.makeHtml(terms_jp))
    } else {
      setHtml(converter.makeHtml(terms))
    }
  }, [country]);

  useEffect(() => {
    if (search.get("country")){
      // scroll to country privacy
      setCountry(toUpper(search.get("country")));
    } else if (userCountry) {
      // scroll to user's country
      setCountry(toUpper(userCountry));
    }
  }, [search, userCountry]);

  return (
    <Page>
      <InformationalPageContent>
        <div class="flex justify-end items-center">
          <div>
            <FaGlobe size={20} />
          </div>
          <div class="ml-3 flex-grow sm:w-full xl:w-60">
            <select class="form-select appearance-none
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding bg-no-repeat
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:outline-none" 
              aria-label="Default select example"
              value={country}
              onChange={ (e) => history.push("/policies/privacy?country=" + e.target.value )} >
                <option>{'-'}</option>
                {
                  COUNTRY_LIST.map(o => <option key={o.value} value={o.value} >{o.label}</option>)
                }
            </select>
          </div>
        </div>
        {html
          ? <div dangerouslySetInnerHTML={{
              __html: html
            }}>
            </div>
          : <LoadingEllipses />}
      </InformationalPageContent>
    </Page>
  )
}
