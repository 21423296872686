import { combineReducers } from 'redux';

import { intlReducer } from 'react-intl-redux';
import { authentication } from './Authentication/_reducer';
import { ploverCamera } from './PloverCamera/_reducer';
import { mediaUploader } from './MediaUploader/_reducer';
import { ploverScan } from './PloverScan/_reducer';
import { fullScanChart } from './FullScanChart/_reducer';
import { reports } from './Reports/_reducer';
import { dentists } from './Dentists/_reducer';
import { checkups } from './Checkups/_reducer';
import { records } from './Records/_reducer';
import { chatsList } from './Chats/_reducer';
import { notifications } from './Notifications/_reducer';
import { videoChats } from './VideoChats/_reducer';
import { partialScans } from './PartialScans/_reducer';
import { unreadMessageCounts } from './UnreadMessageCount/_reducer';
import { modals } from './Modals/_reducer';
import { clinics } from './Clinics/_reducer';
import { detection } from './Detection/_reducer';
import { appSettings } from './Settings/_reducer';

import {
  createNetworkStatusReducer,
  createMessageReducer,
  createPresenceReducer,
  createUserDataReducer,
  createUsersListReducer,
  createChannelDataReducer,
  createChannelsListReducer,
  createMembershipReducer,
  createChannelMembersReducer,
  createSignalReducer
} from "pubnub-redux";

const appReducer = combineReducers({
  intl: intlReducer,
  authentication,
  ploverCamera,
  mediaUploader,
  ploverScan,
  fullScanChart,
  reports,
  checkups,
  records,
  dentists,
  chatsList,
  modals,
  videoChats,
  partialScans,
  unreadMessageCounts,
  detection,
  appSettings,
  clinics,
  notifications,
  // pubnub reducers
  pnNetworkStatus: createNetworkStatusReducer(false),
  pnMessages: createMessageReducer(),
  pnPresence: createPresenceReducer(),
  pnUserData: createUserDataReducer(),
  pnUsersList: createUsersListReducer(),
  pnChannelData: createChannelDataReducer(),
  pnChannelList: createChannelsListReducer(),
  pnMembership: createMembershipReducer(),
  pnChannelMembers: createChannelMembersReducer(),
  pnSignals: createSignalReducer()
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT_ALL') {
    const {intl} = state;
    state = {intl};
  }

  return appReducer(state, action);
};

export default rootReducer;
