import { constants } from './_constants';

const initState = {
  loadingAll: false,
  errorAll: false,
  byId:{},
  loading: {},
  error: {}
};

export function notifications(state = initState, action){
  switch(action.type){
    case constants.FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loadingAll: true,
      }
    case constants.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        ...action.data,
        loadingAll: false,
        errorAll: false
      }
    case constants.FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loadingAll: false,
        errorAll: true
      }
    case constants.MARKREAD_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data.id]: true,
        },
      }
    case constants.MARKREAD_NOTIFICATION_SUCCESS:
      // update the entire message so state will change in component
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data.id]: false,
        },
        error: {
          ...state.error,
          [action.data.id]: false
        },
        byId: {
          ...state.byId,
          [action.data.id]: Object.assign({}, state.byId[action.data.id], action.data.notification)
        }
      }
    case constants.MARKREAD_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data.id]: false
        },
        error: {
          ...state.error,
          [action.data.id]: action.data.error
        },
      }
    case constants.UPDATE_NOTIFICATION_RECEIVED:
      // update the entire message so state will change in component
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.data
        }
      }
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}
