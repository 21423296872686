import { API_URLS } from '../_config';

import { authHeader, handleResponse } from '../_helpers';

export const services = {
  fetchLatestCheckupwithDentist,
  fetchVideoChatToken,
  fetchVideoChatList,
  checkVideoChatToken
}

function checkVideoChatToken(token){
  const requestUrl = API_URLS.checkVideoChatToken;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url + `?token=${token}`
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchLatestCheckupwithDentist(q_type, unique_id, urlParams) {
  const requestUrl = API_URLS.fetchLatestCheckupwithDentist;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{q_type}", q_type).replace("{unique_id}", unique_id);
  if (urlParams){
    url = url + "?" + new URLSearchParams(urlParams)
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchVideoChatList(query={}){
  const requestUrl = API_URLS.fetchVideoChatList
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url + '?' + new URLSearchParams(query);;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchVideoChatToken(data){
  const requestUrl = API_URLS.fetchVideoChatToken;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}
