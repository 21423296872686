const PREFIX = 'NOTIFICATIONS_';

export const constants = {
  UPDATE: `${PREFIX}UPDATE`,
  FETCH_NOTIFICATIONS_REQUEST: `${PREFIX}FETCH_NOTIFICATIONS_REQUEST`,
  FETCH_NOTIFICATIONS_SUCCESS: `${PREFIX}FETCH_NOTIFICATIONS_SUCCESS`,
  FETCH_NOTIFICATIONS_FAILURE: `${PREFIX}FETCH_NOTIFICATIONS_FAILURE`,
  MARKREAD_NOTIFICATION_REQUEST: `${PREFIX}MARKREAD_NOTIFICATION_REQUEST`,
  MARKREAD_NOTIFICATION_SUCCESS: `${PREFIX}MARKREAD_NOTIFICATION_SUCCESS`,
  MARKREAD_NOTIFICATION_FAILURE: `${PREFIX}MARKREAD_NOTIFICATION_FAILURE`,
  UPDATE_NOTIFICATION_RECEIVED: `${PREFIX}UPDATE_NOTIFICATION_RECEIVED`
}
