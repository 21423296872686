import React from 'react';

export const LoadingEllipses = ({width, height, viewBox}) => {
  const svgWidth = (width) ? width : '120px';
  const svgHeight = (height) ? height : '30px';
  const svgViewBox = (viewBox) ? viewBox : '0 30 100 50';
  return (
    <svg width={svgWidth}  height={svgHeight} xmlns="http://www.w3.org/2000/svg" viewBox={svgViewBox} preserveAspectRatio="xMidYMid">
      <circle cx="84" cy="50" r="0" fill="#a3a3ac">
        <animate attributeName="r" values="10;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="84;84;84;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
      <circle cx="49.9762" cy="50" r="10" fill="#b8babd">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-1.05s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-1.05s"></animate>
      </circle>
      <circle cx="16" cy="50" r="9.99299" fill="#d8dddf">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-0.525s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-0.525s"></animate>
      </circle>
      <circle cx="84" cy="50" r="0.00701046" fill="#f3f3f3">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
      <circle cx="83.9762" cy="50" r="10" fill="#a3a3ac">
        <animate attributeName="r" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
    </svg>
  )
}
