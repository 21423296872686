import create from 'zustand';

export const useReportStore = create((set) => ({
    category: null,
    selectedReport: null,
    blockData: [],
    setCategory: (categoryVal) => 
        set((state) => ({
            ...state,
            category: categoryVal
        })),
    setSelectedReport: (reportId) => 
        set((state) => ({
            ...state,
            selectedReport: reportId,
            blockData: [],
            category: null
        })),
    setBlockData: (data) => 
        set((state) => ({
            ...state,
            blockData: data
        })),
}));