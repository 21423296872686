import React, { useState } from 'react';
import { 
  Box, 
  Text,
  Flex,
  HStack,
  VStack,
  Skeleton,
  Circle,
  Center,
  Divider,
  Button,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  AccordionButton,
  FormLabel,
  Checkbox,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Progress, useDisclosure

} from '@chakra-ui/react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { set, toUpper } from 'lodash';
import { useReportStore } from '../Reports/state/report';
import storage from '../_helpers/storage';
import { Categories } from '../Reports/components/ToothChart';

import { useBulkReports } from './api/getBulkReports';

import { ToothSetPdf } from '../_components/ToothSetPdf';
import { localeFormatDate } from '../_helpers';
import { getIntlLocale } from '../Authentication/_selectors';
import { StyledDownloadReport } from './styles';
import { SelectOptionField } from '../_components/Forms/SelectOptionField';
import { DownloadIcon } from '../_images/icons/DownloadIcon';
import { useSelectOptions } from './api/getSelectOptions';
import { useSearchParamsObject } from '../_helpers/useUrlSearchParams';
import { useHistory } from 'react-router-dom';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DateRangeModal } from './DateRangeModal';
import { HealthyReportSection } from '../Reports/components/HealthyReportSection';

function formatDateToYYYYMMDD(dateString) {
    // Create a new Date object from the input date string
    const date = new Date(dateString);
  
    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Combine them into the desired format
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
  }
  
const PDFWidthPx = 1050;
const PDFToothChartWidthPx = (495/1050)*PDFWidthPx;
const PDFChartLabelsWidthPx = (555/1050)*PDFWidthPx;
const PDFWidthMm = 210
const pxTommRatio = PDFWidthMm/PDFWidthPx;
const Html2CanvasScale = 1.15;

const convertToMM = (pixels) => {
  return (pixels * pxTommRatio)/Html2CanvasScale;
}

export const ToothChart = ({data, isDMFT=false, height=360, width=190}) => {
  const [ isFlipped, setIsFlipped ] = useState(storage.getChartFlipped() || true)
  const { category } = useReportStore();

  const toothClasses = {};
  const categoriesCount = {};

  // go through the block data and convert it to tooth charting object
  // object with key of tooth number "txx" and value of category in all caps for class
  (data?.blocks || []).map((block) => {
      const data = block?.data;
      if (category != null){
        // check that the category matches the selected category
        if (toUpper(data.category) == toUpper(category)){
          data.regions.map((region) => {
            if (region.length > 0){
              // will overwrite any previous block styles
              if (toothClasses[`t${region}`]){
                  toothClasses[`t${region}`].push(toUpper(block.data.category))
                  !isDMFT && toothClasses[`t${region}`].push("MULTIPLE")
              } else {
                  toothClasses[`t${region}`] = [toUpper(block.data.category)]
              }
            }
          })
        }
      } else {
        // if category is null, then add all categories
        data.regions.map((region) => {
          if (region.length > 0){
            // will overwrite any previous block styles
            if (toothClasses[`t${region}`]){
                toothClasses[`t${region}`].push(toUpper(block.data.category))
                !isDMFT && toothClasses[`t${region}`].push("MULTIPLE")
            } else {
                toothClasses[`t${region}`] = [toUpper(block.data.category)]
            }
          }
        })
      }
      categoriesCount[toUpper(data.category)] = (categoriesCount[toUpper(data.category)] || 0) + 1
      
  })

  const handleFlip = () => {
    //storage.setChartFlipped(!isFlipped)
    //setIsFlipped(!isFlipped)
  }

  return (
      <Flex 
        w={['full']}
        justify={'center'} 
        background={'#fff'}
        borderRadius={['25px']}
        direction={isFlipped ? 'row-reverse' : 'row'}
        onClick={handleFlip}
      >
        <Flex align={['center']}>
          <Text mr={['5px']}>
            <FormattedMessage
              id={'reportDetailsPage.reviewReportModal.toothChart.right'}
              defaultMessage={'R'}
              />
          </Text>
        </Flex>
        <ToothSetPdf
          toothClasses={toothClasses}
          width={width}
          height={height}
          isFlipped={isFlipped}
        />
        <Flex align={['center']}>
          <Text ml={['5px']}>
            <FormattedMessage
              id={'reportDetailsPage.reviewReportModal.toothChart.left'}
              defaultMessage={'L'}
              />
          </Text>
        </Flex>
      </Flex>
  )
}

const ToothLabelItem = ({toothNum, category=""}) => {
    let color = "#273238"
    let fontColor = "#FFF"
    if (category.toLowerCase() == "caries"){
      color="#DC4E41"
      fontColor="#fff"
    } else if(category.toLowerCase() == "color"){
      color="#367FFF"
      fontColor="#fff"
    } else if(category.toLowerCase() == "gums"){
      color="#FFA500"
      fontColor="#273238"
    } else if(category.toLowerCase() == "habits"){
      color="#DAFF00"
      fontColor="#273238"
    } else if(category.toLowerCase() == "ortho"){
      color="#405159"
      fontColor="#fff"
    } else if(category.toLowerCase() == "general" || category.toLowerCase() == "others"){
      color="#A9A8A8"
      fontColor="#273238"
    }
    return (
      <Circle size={'30px'} bg={color} alignItems={'center'} justify={'center'}>
        <Text
          color={fontColor}
          fontSize={'15px'}
          mb={'16px'}
        >
          {toothNum}
        </Text>
      </Circle>
    )
  }
  
  
//import html2canvas from 'html2canvas';
//import { jsPDF } from "jspdf";
const CategoryLabelItem = ({block}) => {
    // data?.
    // block?.data?.category <- for color
    // block?.data?.subCategory
    // block?.data?.regions
    return (
      <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'}>
        <Text color="#394B55" fontSize={'18px'} textTransform={'capitalize'}>
          {block?.data?.title || block?.data?.subCategory}
        </Text>
        <HStack spacing={['8px']} mt={['10px']}>
          {
            block?.data?.regions.map((toothNum) => (
              <ToothLabelItem key={`${block?.id}_${toothNum}`} toothNum={toothNum} category={block?.data?.category || ""} />
            ))
          }
        </HStack>
      </Flex>
    )

}
const CategoryLabelSection = ({data}) => {

  return (
    <VStack align={'flex-start'} justify={'flex-start'} spacing={['15px']}>
      {
        (data?.blocks || []).map((block) => (<CategoryLabelItem key={block?.id} block={block} />))
      }

    </VStack>
  )
}
function removeXmlAndEntities(str) {
  // Remove XML tags
  return str.replace(/<toothnumbers[^>]*>(.*?)<\/toothnumbers>/gis, function(match, p1) {
    return p1.replace(/<tooth[^>]*>[\s\S]*?<\/tooth>/gi, '') // Remove all <tooth> tags and their contents
             .replace(/&nbsp;|,/g, ''); // Remove all &nbsp; entities and commas
  }).replace(/^[\s.]+/, '');
}

function removeXmlAndKeepTeeth(str) {
  // Removing HTML tags
  let output = str.replace(/<\/?[^>]+(>|$)/g, "");
  // Replacing HTML entities
  output = output.replace(/&nbsp;/gi, " "); // Replace all occurrences of &nbsp; with a space

  return output;
}

const CategoryCommentItem = ({block, type}) => {

    // data?.
    // block?.data?.category <- for color
    // block?.data?.subCategory
    // block?.data?.regions
    // block?.data?.text

    const comment = (type == "DMFT") ? removeXmlAndEntities(block?.data?.text) : removeXmlAndKeepTeeth(block?.data?.text)
    return (
      <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'}>
        <Text color="#394B55" fontSize={'18px'} textTransform={'capitalize'}
          textAlign={'left'}
        >
        {block?.data?.title || block?.data?.subCategory}
        </Text>
        <Text
          color={'#405159'}
          fontSize={'15px'}
          lineHeight={'18px'}
          textAlign={'left'}
          mt={'5px'}
        >
          {comment}
        </Text>
      </Flex>
    )
}

const CategoryCommentsSection = ({data, type}) => {
  return (
    <VStack align={'flex-start'} justify={'flex-start'} spacing={['15px']} mt={'20px'}>
      {
        (data?.blocks || []).map((block) => (<CategoryCommentItem key={block?.id} block={block} type={type} />))
      }
    </VStack>
  )
}

export const PrintPreviewReportSection = ({data, type="DMFT", status=null}) => {
  // if report is complete and is healthy (no conditions, )
  const isCleanReport = (status == 3 || ((status || "").indexOf("COMPLETE") !== -1)) && (!data?.blocks || data?.blocks?.length == 0);
  if (isCleanReport){
    return (
      <HealthyReportSection />
    )
  } else if (!data?.blocks || data?.blocks?.length == 0){
      console.log("report status ", status)
      if (status == 3 || ((status || "").indexOf("COMPLETE") !== -1) || ((status || "").indexOf("INSPECTING") !== -1)){
        return (
          <HealthyReportSection />
        )
      } else {
        return (
            <Box 
              p={['20px']} 
              pl={[0]}
              m={['5px']}
              bg={['#fff']}
              w={`${PDFChartLabelsWidthPx}px`}
              borderRadius={['15px']}
            >
              <Skeleton height={['580px']} w={['full']} />
            </Box>
        )
      }
  } 
  return (
      <Box
        w={`${PDFChartLabelsWidthPx}px`}
      >

          <Box
            p={['20px']} 
            pl={[0]}
            m={['5px']}
            bg={['#fff']}
            borderRadius={['15px']}
          >
              <CategoryLabelSection data={data} />
              <Divider orientation='horizontal' borderColor={'#C5C5C5'} py={'10px'} />
              <CategoryCommentsSection data={data} type={type}/>
          </Box>
      </Box>
  )
}

const PrintPreviewChartSection = ({status, type, data, height=360, width=190}) => {
  const categoriesCount = {};

  // go through the block data and convert it to tooth charting object
  // object with key of tooth number "txx" and value of category in all caps for class
  (data?.blocks || []).map((block) => {
      categoriesCount[toUpper(block?.data.category)] = (categoriesCount[toUpper(block?.data.category)] || 0) + 1  
  })

  const isDMFT = (type || "").indexOf("DMFT") !== -1

  if (isDMFT || data == null){

    return( 
      <Box  
        px={['0px']}
        py={'20px'}
        pb={'30px'}
        bg={'#fff'}
        minW={['390px']}
        m={['5px']}
        borderRadius={['30px']}
        w={`${PDFToothChartWidthPx}px`}
      >
          <ToothChart 
            isDMFT={isDMFT}
            data={data} 
            width={width}
            height={height}
          />
      </Box>
    )
  } else {
    return( 
      <Box  
        px={['20px']}
        bg={'#fff'}
        minW={['390px']}
        m={['5px']}
        borderRadius={['30px']}
        w={`${PDFToothChartWidthPx}px`}
      >
        <Flex w={'full'} justify={['end']} pt={['20px']}>
          {/*<CategoriesFilterSelect isDisabled={!data?.blocks} categoriesCount={categoriesCount} isLabelShowing={false} />*/}
        </Flex>
          <ToothChart 
            data={data} 
            width={width}
            height={height}
          />
        <Categories isHidden={(type || "").indexOf("DMFT") !== -1} type={type} />
      </Box>
    )
  }
}

const PrintPreview = ({ data }) => {
    // download report has no alternative field, just report?.report_data
    if (data?.report?.report_data) {
        return (
          <Box
          >
            <Flex w={'full'} overflow={'auto'} maxH={['70vh']} justify={['center']}>
                <PrintPreviewChartSection status={data?.report?.status} type={data?.type}  data={data?.report?.report_data} />
                <PrintPreviewReportSection status={data?.report?.status} type={data?.type} data={data?.report?.report_data} remark={data?.report?.remark} />
            </Flex>
          </Box>
        );
    } else {
        return <></>;  // Return null instead of empty fragment for potentially cleaner output
    }
};

const ReportPrintPreview = ({data, status}) => {
    const intlLocale = useSelector(getIntlLocale);
    let dateString = data?.created_at
    return (
        <Box pt={'20px'}>
            <Flex overflow={'auto'} maxH={['70vh']} w={'full'} justify={['center']} pb={'30px'}>
            <Text fontSize={['24px']} color={'#273238'}>
                { `${localeFormatDate(dateString, intlLocale, 'll')}` }
            </Text>
            <Text fontSize={['24px']} color={'#273238'} ml={'5px'}>
                {data?.user_profile?.first_name}
            </Text>
            </Flex>
            <PrintPreview status={status} data={data} />
        </Box>
    )
}


const Item = ({data}) => {
    return (
        <Box>
            {data.created_at}
        </Box>
    )
}

const DateGroup = ({date, items, selectedItems, handleDateCheckboxChange, handleCheckboxChange}) => {
  const locale = useSelector(getIntlLocale); 
  return (
    <AccordionItem
      mt={['10px']}
      isFocusable={false}
    >
      <Box 
        p={['15px']} pt={['15px']} pb={['15px']}
        boxShadow={['0px 0px 4px 0px rgba(0, 0, 0, 0.15)']}
        borderRadius={['5px']}
        mr={'5px'}
      >
        <AccordionButton _hover={{bg: 'transparent'}} _focus={{outline: 'none'}}>
          <Flex justify={'space-between'} w={'full'}>
            <Flex textAlign='left' align={'center'}>
                <Checkbox
                  isChecked={selectedItems[date] ? selectedItems[date].every((isSelected) => isSelected) : false}
                  onChange={() => handleDateCheckboxChange(date)}
                >
                  <FormLabel ml={'5px'}>
                    {localeFormatDate(date, locale)}
                  </FormLabel>
                </Checkbox>
            </Flex>
            <AccordionIcon />
          </Flex>
        </AccordionButton>  
      </Box>
    <AccordionPanel pb={4} pr={'4px'}>
      {(items || []).map((item, index) => (
          <AccordionItem key={index}
            ml={'10px'}
            pl={'20px'}
            py={'20px '}
            mt={['10px']}
            borderRadius={['5px']}
            boxShadow={['0px 0px 4px 0px rgba(0, 0, 0, 0.15)']}
            isFocusable={false}
          >
            <Flex textAlign='left' align={'center'} onClick={() => handleCheckboxChange(date, index)}>
                <Checkbox
                  isChecked={selectedItems[date] ? selectedItems[date][index] : false}
                  onChange={() => handleCheckboxChange(date, index)}
                >
                  <FormLabel ml={'5px'}>
                    {item.user_profile?.first_name}
                  </FormLabel>
                </Checkbox>
            </Flex>
          </AccordionItem>
      ))}
    </AccordionPanel>
  </AccordionItem>
  )
}

const ItemList = ({grouped, order, selectedItems, handleCheckboxChange, selectAll, setSelectAll, setSelectedItems}) => {

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll;
        setSelectAll(newSelectAll);
        const newSelectedItems = order.reduce((acc, date) => {
            acc[date] = new Array(grouped[date].length).fill(newSelectAll);
            return acc;
        }, {});
        setSelectedItems(newSelectedItems);
    };

    const handleDateCheckboxChange = (date) => {
        const allSelected = selectedItems[date] ? selectedItems[date].every((isSelected) => isSelected) : true;
        const newSelectedItems = { ...selectedItems };
        newSelectedItems[date] = new Array(grouped[date].length).fill(!allSelected);
        setSelectedItems(newSelectedItems);
    };
    
    return (
        <Box ml={'5px'} pr={'5px'}>
          <Flex w={'full'} pl={'31px'} py={'5px'} justify={'space-between'}>
            <Checkbox
              isChecked={selectAll}
              onChange={handleSelectAllChange}
            >
              <FormLabel ml={'5px'}>
                <FormattedMessage
                  id={'downloadReportPage.checkbox.selectAll'}
                  defaultMessage={'Select All'}
                />
              </FormLabel>
            </Checkbox>
            <Flex>
              <Text
                color={'#9B9B9B'}
              >
                <FormattedMessage
                  id={'downloadReportPage.text.total'}
                  defaultMessage={'{count} Items Selected'}
                  values={{count: Object.values(selectedItems).reduce((acc, arr) => acc + arr.filter((isSelected) => isSelected).length, 0)}}
                />
              </Text>
            </Flex>
          </Flex>
          <Accordion 
            defaultIndex={[0]}
            allowMultiple allowToggle
            pb={'50px'}
          >
          
            {(order || []).map((date) => (
              <DateGroup 
                date = {date}
                items={grouped[date]}
                selectedItems={selectedItems}
                handleDateCheckboxChange={handleDateCheckboxChange}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))}
          </Accordion>
        </Box>
    )
}

const groupByDate = (data) => {
  if (data?.reports){
      const grouped = data?.reports.reduce((acc, item) => {
          const date = formatDateToYYYYMMDD(item.created_at);
          if (!acc[date]) {
          acc[date] = [];
          }
          acc[date].push(item);
          return acc;
      }, {});

      // Sort dates in descending order
      const order = Object.keys(grouped).sort((a, b) => new Date(b) - new Date(a));

      return { grouped, order };
      
  } else{
      return {}
  }
};

export const DownloadReport = () => {
    const history = useHistory(); 
    const searchParams = useSearchParamsObject();
    const intlLocale = useSelector(getIntlLocale);
    const { data, isLoading, isFetching } = useBulkReports({...searchParams});
    const { isOpen: isDatePickerOpen, onOpen: onDatePickerOpen, onClose: onDatePickerClose } = useDisclosure();
    const { data: filtersData, isLoading: filtersIsLoading, isFetching: filtersIsFetching } = useSelectOptions({option: 'report_bulk_filter'});
    const [ currentFilter, setCurrentFilter ] = useState('last_week')
    const [ selectedItems, setSelectedItems ] = useState({})
    const [selectAll, setSelectAll] = useState(false);
    const [ isGenerating, setIsGenerating ] = useState(false)
    const [progress, setProgress] = useState(0); // Progress state
    const [ isReportRendering, setReportRendering ] = useState(true)
    const defaultData = React.useMemo(() => [], []);
    const previewRefs = React.useRef({});
    const { formatMessage } = useIntl();

    const { grouped, order } = groupByDate(data);

    const handleCheckboxChange = (date, index) => {
        const newSelectedItems = { ...selectedItems };
        if (newSelectedItems[date]) {
          newSelectedItems[date][index] = !newSelectedItems[date][index];
        } else {
          // create new array for date if it doesnt exists. length based on the original grouped data
          newSelectedItems[date] = new Array(grouped[date].length).fill(false);
          newSelectedItems[date][index] = true;
        }
        setSelectedItems(newSelectedItems);
    };

    const svgToCanvas = (targetElem) => {
      console.log("svgToCanvas ", targetElem);
      const svgElems = targetElem.querySelectorAll('svg text');
      svgElems.forEach((node) => {
        let fontFamily = window.getComputedStyle(node, null).getPropertyValue('font-family');
        fontFamily = fontFamily.replace(/['"]+/g, ''); // Remove quotes from font-family
        node.setAttribute('font-family', fontFamily);
        console.log(node)
        node.replaceWith(node);
      });
    };


    // font-family fix attempt. does not fix.
    // maybe in svg, switch text and tspan. so that number is directly a child of text, and not tspan
    // next try: https://github.com/niklasvh/html2canvas/issues/1463
    const processSVGs = (element) => {
      const ids = ['toothnumbers', 'toothnumbers_reversed'];
      ids.forEach((id) => {
        const svgElement = element.querySelector(`#${id}`);
        if (svgElement) {
          svgToCanvas(svgElement);
        }
      });
    };

    const generatePDFsInBulk = async () => {
        setIsGenerating(true);
        setProgress(0);
        let generatedCount = 0;
        const zip = new JSZip();
        await document.fonts.ready;
        
        const total = Object.values(selectedItems).reduce((acc, arr) => acc + arr.filter((isSelected) => isSelected).length, 0)

        for (const date of order) {
          for (let i = 0; i < grouped[date].length; i++) {
            if (!selectedItems[date]){
              // if date was never selected, its not created in selectedItems
              continue;
            }
            if (selectedItems[date][i]) {
              const item = grouped[date][i];
              const element = previewRefs.current[`${date}-${item?.id}`];
              if (!element) {
                continue;
              }
              //processSVGs(element);
              const canvas = await html2canvas(element, {
                useCORS: true,
                scale: Html2CanvasScale, // Increase scale for better quality
                logging: true, // Enable logging for debugging
                //onclone: (clonedDoc) => {
                //  let fontFamily = getComputedStyle(document.body).fontFamily;
                //  fontFamily = fontFamily.replace(/['"]+/g, ''); // Remove quotes from font-family
                //  clonedDoc.documentElement.style.fontFamily = fontFamily; // Apply the correct font-family
                //}
              });
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF();
              pdf.addImage(imgData, 'PNG', 0, 0, convertToMM(canvas.width), convertToMM(canvas.height));
              const pdfBlob = pdf.output('blob');
              //const pdfTitle = `${formatDateToYYYYMMDD(item?.created_at)} ${item?.user_profile?.first_name}`;
              const pdfTitle = `${item?.user_profile?.first_name}`;
              zip.file(`${pdfTitle}.pdf`, pdfBlob);
              generatedCount++;
              setProgress((generatedCount / total) * 100);
            }
          }
        }
    
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const currentDate = new Date();
          saveAs(content, `${currentDate.getTime()}.zip`); 
          setIsGenerating(false);
        });
    };

    const renderPrintPreviews = (orderedReports=[], groupedReports={}) => {
      const renderedPreviews =  orderedReports.map((date) => (
        <div key={date}>
          {groupedReports[date].map((item) => (
            <Box
              key={`${date}-${item?.id}`}
              ref={(el) => (previewRefs.current[`${date}-${item?.id}`] = el)}
              position={'absolute'}
              top={'9999px'}
              w={`${PDFWidthPx}px`}
            >
              <ReportPrintPreview data={item} />
            </Box>
          ))}
        </div>
      ));
      return renderedPreviews;
    };

    const handleFilterChange = (v) => {
      let params = {};
      if (v?.value == 'custom'){
        // open date picker
        onDatePickerOpen();
        return;
      } else if (v?.value){
        params.date_filter = v?.value;
      } else {
        delete params.date_filter
      }
      const newParams = new URLSearchParams(params)
      history.push({search: newParams.toString()})
    }

    const handleDatePickerChange = (date) => {
      let params = searchParams;
      console.log("datepickervalue ", date)
      params.date_filter = 'custom'
      params.start_date = formatDateToYYYYMMDD(date[0].toISOString())
      params.end_date = formatDateToYYYYMMDD(date[1].toISOString())
      const newParams = new URLSearchParams(params)
      history.push({search: newParams.toString()})
      //}`)
    }

    return (
      <>
        {renderPrintPreviews(order, grouped)}
        <DateRangeModal 
          isOpen={isDatePickerOpen} 
          onClose={onDatePickerClose} 
          onSelected={handleDatePickerChange}
        />

        <Modal closeOnOverlayClick={false} isOpen={isGenerating} isCentered size={'lg'}>
          <ModalOverlay />
          <ModalContent >
            <ModalHeader
              boxShadow={'0px -2px 15px 0px rgba(0, 0, 0, 0.08)'}
              backgroundColor={'#fff'}
              borderTopRadius={'10px'}
            >
              <Center>
                <Text
                  color={'#12171A'}
                  fontSize={'16px'}
                >
                  <FormattedMessage
                    id={'downloadReportPage.modal.title'}
                    defaultMessage={'Download Report'}
                  />
                </Text>
              </Center>
            </ModalHeader>
            <ModalBody>
              <Box>
                <Flex w={'full'} align={'flex-start'} py={'40px'} px={'30px'} pt={'20px'} direction={'column'}>
                  <Text 
                    color={'#273238'}
                    fontSize={'36px'}
                  >
                    <FormattedMessage 
                      id={'downloadReportPage.text.progress.title'}
                      defaultMessage={'Downloading Reports'}
                    />
                  </Text>
                  <Text
                    mt={'10px'}
                    color={'#9B9B9B'}
                    fontSize={'15px'}
                  >
                    <FormattedMessage 
                      id={'downloadReportPage.text.progress.description'}
                      defaultMessage={'Please wait while we prepare your reports for download. Please do not navigate away from this page.'}
                    />
                  </Text>
                  <Progress 
                    borderRadius={'10px'}
                    mt={'40px'}
                    colorScheme={'progressColor'}
                    hasStripe={true}
                    isAnimated={true}
                    value={progress+2} 
                    w={'full'} 
                  />
                </Flex>
                </Box>
              </ModalBody>
          </ModalContent> 
        </Modal>
        <StyledDownloadReport>
            <h2>
                <FormattedMessage 
                    id={'settings.menu.menulist.menuItem.downloadreport.title'}
                    defaultMessage={'Download Report'}
                />
            </h2>
            <Box bg={'transparent'} position={'relative'} height={'660px'} overflow={'overlay'} pr={'20px'}>
                <Flex mt={'10px'} w={'full'} align={'center'} justify={'space-between'} px={'5px'}>
                    <Flex w={'280px'}>
                      <SelectOptionField 
                          isLoading={filtersIsFetching || filtersIsLoading}
                          isMultiple={false}
                          value={searchParams?.date_filter}
                          name={'sort_by'}
                          onChange={handleFilterChange}
                          selectOptions={filtersData?.options || defaultData}
                          isInvalid={false}
                          error={null}
                          optionValue={searchParams?.date_filter == 'custom' ? 
                            {value: 'custom' , label: `${searchParams.start_date} - ${searchParams.end_date}`} : null
                          }
                      />
                    </Flex>
                         
                  <Button
                      leftIcon={<DownloadIcon />}
                      isLoading={isGenerating}
                      isDisabled={isGenerating || !Object.values(selectedItems).some((arr) => arr.some((isSelected) => isSelected))}
                      onClick={generatePDFsInBulk}
                  >
                      <FormattedMessage 
                          id={'downloadReportPage.button.download'}
                          defaultMessage={'Download'}
                      />
                  </Button>                       
                </Flex>
                <Box mt={'20px'}>
                    <ItemList 
                        grouped={grouped}
                        order={order}
                        selectedItems={selectedItems} 
                        handleCheckboxChange={handleCheckboxChange}
                        setSelectedItems={setSelectedItems}
                        setSelectAll={setSelectAll}
                        selectAll={selectAll}
                    />
                </Box>
            </Box>
        </StyledDownloadReport>
      </>
    )
}
