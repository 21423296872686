import { createSelector } from 'reselect'

import { getRecordUploads, getRequestFilter } from '../Reports/_selectors';

export const getRecordsState = (state) => state.records;

export const getRecordsLoading = createSelector(
  [getRecordsState],
  (state) => {
    return state.loading;
  }
)

export const getRecordDetailsErrorById = createSelector(
  [getRecordsState],
  (state) => {
    return state.detailsError;
  }
)

export const getRecordDetailsLoadingById = createSelector(
  [getRecordsState],
  (state) => {
    return state.detailsLoading;
  }
)

export const getRecordsIsMore = createSelector(
  [getRecordsState],
  (state) => {
    return state.isMore;
  }
)

export const getRecordsById = createSelector(
  [getRecordsState],
  (state) => {
    return state.byId;
  }
)

export const getCurrentMediaId = createSelector(
  [getRecordsState],
  (state) => {
    return state.currentSelectedMedia;
  }
)

export const getRecordUploadsById = createSelector(
  [getRecordUploads],
  (uploads) => {
    return (uploads)
      ? uploads.reduce((obj, upl) => {obj[upl.id] = upl;return obj}, {})
      : {};
  }
)

export const getCurrentDetectionId = createSelector(
  [getRecordsState],
  (state) => {
    return state.currentDetectionMedia;
  }
)

export const getDetectionsById = createSelector(
  [getRecordsState],
  (state) => {
    return state.detectionsById;
  }
)

export const getDetectionsLoading = createSelector(
  [getRecordsState],
  (state) => {
    return state.detectionsLoading;
  }
)

export const getCurrentDetectionMediaItem = createSelector(
  [getCurrentDetectionId, getRecordUploadsById],
  (currentId, uploads) => {
    return uploads[currentId];
  }
)

export const getCurrentSelectedDetectionDetails = createSelector(
  [getCurrentDetectionId, getDetectionsById],
  (currentId, detections) => {
    return detections[currentId];
  }
)

export const getCurrentSelectedDetectionFps = createSelector(
  [getCurrentSelectedDetectionDetails],
  (detection) => {
    return detection
      ? detection.fps
      : null;
  }
)

export const getCurrentSelectedDetectionTotalFrames = createSelector(
  [getCurrentSelectedDetectionDetails],
  (detection) => {
    return detection
      ? detection.frames
      : null;
  }
)

export const getCurrentDetectionByFrames = createSelector(
  [getCurrentSelectedDetectionDetails],
  (detections)=>{
    return detections
        ? detections.detections
        : {}
  }
)


export const getCurrentDetectionLoading = createSelector(
  [getCurrentMediaId, getDetectionsLoading],
  (currentId, loading) => {
    return loading[currentId];
  }
)

export const getCurrentSelectedMediaItem = createSelector(
  [getCurrentMediaId, getRecordUploadsById],
  (currentId, uploads) => {
    return uploads[currentId];
  }
)


export const getRecordDetailsById = createSelector(
  [getRecordsState],
  (state) => {
    return state.recordDetailById;
  }
)

export const getCurrentRecordId = createSelector(
  [getRecordsState],
  (state) => {
    return state.currentRecord;
  }
)
export const getCurrentRecordError= createSelector(
  [getRecordDetailsErrorById, getCurrentRecordId],
  (errorById, currentRecordId) => {
    return (errorById)
      ? errorById[currentRecordId]
      : false;
  }
)

export const getCurrentRecordLoading = createSelector(
  [getRecordDetailsLoadingById, getCurrentRecordId],
  (loadingById, currentRecordId) => {
    return (loadingById)
      ? loadingById[currentRecordId]
      : false;
  }
)

export const getCurrentRecordDetail = createSelector(
  [getRecordDetailsById, getCurrentRecordId],
  (details, currentRecordId) => {
    return (currentRecordId)
      ? details[currentRecordId]
      : null;
  }
)

export const getCurrentRecordCreatedDate = createSelector(
  [getCurrentRecordDetail],
  (detail) => {
    return detail && detail.created_at;
  }
)

export const getCurrentRecordUploadsById = createSelector(
  [getCurrentRecordDetail],
  (detail) => {
    return (detail && detail.record_uploads)
      ? detail.record_uploads.reduce((obj, upl) => {obj[upl.id] = upl;return obj}, {})
      : {}
  }
)

export const getCurrentRecordUploads = createSelector(
  [getCurrentRecordUploadsById],
  (byId) => {
    return byId
      ? Object.values(byId)
      : [];
  }
)

export const getCurrentSelectedMedia = createSelector(
  [getCurrentRecordUploadsById, getCurrentMediaId],
  (uploads, currentId) =>{
    return (uploads && currentId)
      ? uploads[currentId]
      : null;
  }
)

export const getCurrentRecordFilter = createSelector(
  [getRecordsState],
  (state) => {
    return state.recordFilter;
  }
)

export const getRecordsFilter = createSelector(
  [getRecordsState],
  (state) => {
    return state.recordFilter;
  }
)

export const getAllRecords = createSelector(
  [getRecordsById, getRecordsFilter],
  (records, recordType ) => {
    return (records)
      ? Object.values(records)
          .map( (entry) => {
            return entry;
          }).sort((a,b) => {
            if (a.created_at === b.created_at){
              return 0;
            } else if (a.created_at > b.created_at){
              return -1;
            } else{
              return 1;
            }
          })
      : [];
  }
)

export const getFilteredRecords = createSelector(
  [getRecordsById, getRecordsFilter],
  (records, recordType ) => {
    return (records)
      ? Object.values(records)
          .filter((data) => data && data.type == recordType)
          .map( (entry) => {
            return entry;
          }).sort((a,b) => {
            if (a.created_at === b.created_at){
              return 0;
            } else if (a.created_at > b.created_at){
              return -1;
            } else{
              return 1;
            }
          })
      : [];
  }
)

export const getFilteredRecordsWithFilter = createSelector(
  [getFilteredRecords, getRequestFilter],
  (records, requestFilter) => {
    return (records)
      ? Object.values(records)
          .filter((data) => data && data.user_profile?.id == requestFilter)
          .map( (entry) => {
            return entry;
          }).sort((a,b) => {
            if (a.created_at === b.created_at){
              return 0;
            } else if (a.created_at > b.created_at){
              return -1;
            } else{
              return 1;
            }
          })
      : [];
  }
)
