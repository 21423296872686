import { RECORD_TYPES, FULLSCAN_STATES, DETECTION_VIDEO_TIME_LIMIT, VIDEO_TIME_LIMIT } from '../_config';
import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import useMediaRecorder from '@wmik/use-media-recorder';

import { getSeekableBlob } from './_helpers';
import { useTimer } from '../_helpers/timer';


// components
import {CameraDefaultButton} from '../_images/icons/CameraDefaultButton';
import {CameraStopButton} from '../_images/icons/CameraStopButton';

// actions
import { actions as mediaActions } from '../MediaUploader/_actions';
import { actions as fullScanActions } from '../FullScanChart/_actions';
import { actions as partialScanActions } from '../PartialScans/_actions';
import { actions as detectionScanActions } from '../Detection/_actions';
import { actions as modalActions } from '../Modals/_actions';

// selectors
import {
  getSelectedRegion, getReviewOpen, getCurrentPartialScanRegion,
  getUploadProgress as getPartialScanUploadProgress, getUploadTotal as getPartialScanUploadTotal
} from '../PartialScans/_selectors';
import { getDetectionScan,
  getUploadTotal as getDetectionScanTotal,
  getUploadProgress as getDetectionScanProgress
 } from '../Detection/_selectors';
import { getScanType, getUploadTotal, getUploadProgress  } from '../PloverScan/_selectors';
import { getIsAwaitingPlover } from '../PloverCamera/_selectors';
import { getScanningPageModal } from '../Modals/_selectors';
import { getIntlMessages } from '../Authentication/_selectors';
import { getCurrentSelected, getCurrentSelectedItem,
  getMediaByRegion1, getMediaByRegion2, getMediaByRegion3, getMediaByRegion4 } from '../MediaUploader/_selectors';
import { getCurrentRegion, getCurrentState as getCurrentScanState } from '../FullScanChart/_selectors';

// styles
import { ToggleButton, ToggleButtons } from '../PloverScan/styles';
import {
  StyledVideoFrame,
  StyledLivePreview,
  Canvas,
  VideoLoading,
  NextRegionButton,
  StyledPloverCamera,
  StyledCameraButton,
  StyledCameraTextButton,
  StyledFullScanButtons,
  StyledSelfScanButtons,
  StyledPartialScanButtons,
  StyledDetectionScanButtons,
  CameraButtonsWrapper,
  ButtonSection,
  RecordButton,
  StopButton,
  PhotoButton,
  FlipButton,
  RedoRegionButton,
  StyledTextButton,
  StyledUploadProgress,
  StyledProgressRail,
  StyledProgressBar,
  StyledProgressTextContainer,
  StyledProgressText
}
from './styles';
import storage from '../_helpers/storage';

const LoadingCircle = () => {
  return(
    <svg width="100px"  height="100px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{background:'none'}}>
      <circle cx="50" cy="50" fill="none"
          stroke="#f3f3f3" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(113.506 50 50)">
        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
      </circle>
    </svg>
  )
}

export const UploadProgress = () => {
  const scanType = useSelector(getScanType);
  const intlMessages = useSelector(getIntlMessages);
  const uploadProgress = useSelector(getUploadProgress);
  const uploadTotal = useSelector(getUploadTotal);
  const partialScanProgress = useSelector(getPartialScanUploadProgress);
  const partialScanTotal = useSelector(getPartialScanUploadTotal);
  const detectionScanProgress = useSelector(getDetectionScanProgress);
  const detectionScanTotal = useSelector(getDetectionScanTotal)

  if (scanType == RECORD_TYPES.PARTIALSC){
    return (
      <StyledUploadProgress>
        <StyledProgressTextContainer progress={Math.round((100 * partialScanProgress) / partialScanTotal)}>
          <StyledProgressText>
            {intlMessages['uploading.text']}{` ${Math.round((100 * partialScanProgress) / partialScanTotal)}%`}
          </StyledProgressText>
        </StyledProgressTextContainer>
        <StyledProgressRail></StyledProgressRail>
        <StyledProgressBar progress={Math.round((100 * partialScanProgress) / partialScanTotal)}></StyledProgressBar>
      </StyledUploadProgress>
    )
  } else if (scanType == RECORD_TYPES.DETECTIONSC){
    return (
      <StyledUploadProgress>
        <StyledProgressTextContainer progress={Math.round((100 * detectionScanProgress) / detectionScanTotal)}>
          <StyledProgressText>
            {intlMessages['uploading.text']}{` ${Math.round((100 * detectionScanProgress) / detectionScanTotal)}%`}
          </StyledProgressText>
        </StyledProgressTextContainer>
        <StyledProgressRail></StyledProgressRail>
        <StyledProgressBar progress={Math.round((100 * detectionScanProgress) / detectionScanTotal)}></StyledProgressBar>
      </StyledUploadProgress>
    )
  } else {
    return (
      <StyledUploadProgress>
        <StyledProgressTextContainer progress={Math.round((100 * uploadProgress) / uploadTotal)}>
          <StyledProgressText>
            {intlMessages['uploading.text']}{` ${Math.round((100 * uploadProgress) / uploadTotal)}%`}
          </StyledProgressText>
        </StyledProgressTextContainer>
        <StyledProgressRail></StyledProgressRail>
        <StyledProgressBar progress={Math.round((100 * uploadProgress) / uploadTotal)}></StyledProgressBar>
      </StyledUploadProgress>
    )
  }


}

const UploadText = () => {
  return (
    <div>Uploading </div>
  )
}


const LiveStreamPreview = React.forwardRef(
  ({stream, mirrored, status, error, isRunning, stopStream, scanType}, ref) => {
    let videoPreviewRef = useRef();
    let intlMessages = useSelector(getIntlMessages);
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(
      () => {
        let interval;
        if (isRunning) {
          interval = setInterval(
            () => setElapsedTime(prevElapsedTime => {
              if (scanType == RECORD_TYPES.DETECTIONSC
                  && prevElapsedTime > DETECTION_VIDEO_TIME_LIMIT){
                stopStream();
                return prevElapsedTime;
              } else if (prevElapsedTime > VIDEO_TIME_LIMIT){
                stopStream();
                return prevElapsedTime;
              } else {
                return prevElapsedTime + 0.1;
              }
            }),
            100
          );
        }
        setElapsedTime(0);
        return () => clearInterval(interval);
      },
      [isRunning]
    );

    useEffect(() => {
      if (ref.current && stream) {
        ref.current.srcObject = stream;
        const [videoTrack] = stream.getVideoTracks();
        //const capabilities = videoTrack.getCapabilities();
        const settings = videoTrack.getSettings();
        //console.log("live stream capabilities", capabilities);
        console.log("live stream settings", settings);
      }
    }, [stream]);

    //console.log("CAMERALOG: Live Preview ", !!error?.constraint, !stream, ref?.height)

    if (error?.constraint == "deviceId"){
      return(
        <div className={'videoContainer'}>
          <VideoLoading>
            {intlMessages[`camera.loading.errorplover.${error.constraint}`]}
          </VideoLoading>
        </div>
      )
    }

    if (error?.constraint){
      console.log("error with constraint");
      return(
        <div className={'videoContainer'}>
          <VideoLoading>
            {intlMessages['camera.loading.errorplover']}
          </VideoLoading>
        </div>
      )
      //
    }

    if (!stream) {
      return(
        <div className={'videoContainer'}>
          <VideoLoading>
            <LoadingCircle />
          </VideoLoading>
        </div>
      );
    }

    return (
      <div className={'videoContainer'}>
        { elapsedTime > 0 &&
          <div className={'videoTimerContainer'}>
            <div className={'recordingTimer'}>
              <div className={'recordingDot recordingDot--basic'}></div>
              <div className={'recordingTimerText'}>{`00:${Math.floor(elapsedTime).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}`}</div>
            </div>
          </div>
        }
        <StyledLivePreview ref={ref} mirrored={mirrored} width={640} height={480} autoPlay />
      </div>
    );
  }
)

const ScanNextButton = ({onClick, btnDisabled}) => {
  const intlMessages = useSelector(getIntlMessages);

  const handleClick = () => {
    if (!btnDisabled){
      onClick();
    }
  }
  return (
    <StyledCameraButton
      onClick={handleClick}
      btnDisabled={btnDisabled}
      >
      <StyledCameraTextButton>
        <span>{intlMessages['camera.buttons.fullscan.buttons.next.label']}</span>
      </StyledCameraTextButton>
    </StyledCameraButton>
  )
}

const ScanStopButton = ({onClick, btnDisabled}) => {
  const handleClick = () => {
    if (!btnDisabled){
      onClick();
    }
  }
  return (
    <StyledCameraButton
      onClick={handleClick}
      btnDisabled={btnDisabled}
      >
      <CameraStopButton
        />
    </StyledCameraButton>
  )
}

const ScanCameraDefaultButton = ({onClick, btnDisabled, video=false}) => {
  const handleClick = () => {
    if (!btnDisabled){
      onClick();
    }
  }
  return (
    <StyledCameraButton
      onClick={handleClick}
      btnDisabled={btnDisabled}
      >
      <CameraDefaultButton
        video={video}
        />
    </StyledCameraButton>
  )
}

const FullScanCameraButton = ({start, stop, takePhoto, isLiveStream, status, error}) => {
  const dispatch = useDispatch();
  const currentScanState = useSelector(getCurrentScanState);
  const currentRegion = useSelector(getCurrentRegion);

  const nextRegion = () => {
    dispatch(fullScanActions.goToNextRegion(currentRegion));
    dispatch(mediaActions.setCurrentItem(null));
  }

  let disabled = !isLiveStream || error;
  console.log('disabled ', disabled, " status ", status);
  if ((currentScanState == FULLSCAN_STATES.FINISHED && currentRegion == 3)
      || (currentRegion > 3)){
    // finished, so dont show the record button
    return (
        <></>
    )
  } else if (currentScanState == FULLSCAN_STATES.FINISHED && status != 'recording'){
    // next button
    return (
      <ScanNextButton
        onClick={nextRegion}
        btnDisabled={disabled}
        />
    )
  } else if (status == 'recording'){
    return (
      <ScanStopButton
        onClick={stop}
        btnDisabled={disabled}
        />
    )
  } else {
    return (
      <ScanCameraDefaultButton
        onClick={start}
        video={true}
        btnDisabled={disabled}
        />
    )
  }
}

const SelfScanVideoButton = ({stop, start, takePhoto, status, btnDisabled}) => {
    if (status == 'recording'){
      return (
        <ScanStopButton
          onClick={stop}
          btnDisabled={btnDisabled}
          />
      )
    } else {
      return(
          <ScanCameraDefaultButton
            onClick={start}
            video={true}
            btnDisabled={btnDisabled}
            />
      )
    }
}

const SelfScanPhotoButton = ({takePhoto, btnDisabled}) => {
  return (
      <ScanCameraDefaultButton
        onClick={takePhoto}
        btnDisabled={btnDisabled}
        />
    )
}

const SelfScanCameraButton = ({type, currentRegion, start, stop, takePhoto, isLiveStream, status, error}) => {
  let disabled = !isLiveStream || error;
  if (type == "PHOTO") {
    return(
      <SelfScanPhotoButton
        takePhoto={takePhoto}
        btnDisabled={disabled}
      />
    )
  } else {
    return (
      <SelfScanVideoButton
        start={start}
        stop={stop}
        takePhoto={takePhoto}
        status={status}
        btnDisabled={disabled}
        />
    )
  }

}

const PartialScanCameraButton = ({type, currentRegion, start, stop, takePhoto, isLiveStream, status, error}) => {
  let disabled = !isLiveStream || error || !currentRegion;
  if (type == "PHOTO") {
    return(
      <SelfScanPhotoButton
        takePhoto={takePhoto}
        btnDisabled={disabled}
      />
    )
  } else {
    return (
      <SelfScanVideoButton
        start={start}
        stop={stop}
        takePhoto={takePhoto}
        status={status}
        btnDisabled={disabled}
        />
    )
  }
}

const DetectionScanCameraButton = ({type, currentItem, start, stop, takePhoto, isLiveStream, status, error}) => {
  let disabled = !isLiveStream || currentItem;
  return (
    <SelfScanVideoButton
      start={start}
      stop={stop}
      takePhoto={takePhoto}
      status={status}
      btnDisabled={disabled}
      />
  )
}

const SelfScanButtons = ({start, stop, takePhoto,isLiveStream, status, error, photoMode}) => {
  const intlMessages = useSelector(getIntlMessages);
  const [ type, setType] = useState(photoMode);
  return (
    <StyledFullScanButtons>
      <div></div>
      <SelfScanCameraButton
        type={type}
        start={start}
        stop={stop}
        takePhoto={takePhoto}
        isLiveStream={isLiveStream}
        status={status}
        error={error}
      />
      <ToggleButtons>
        <ToggleButton
          onClick={() => setType("VIDEO")}
          toggleOn={type=="VIDEO"}
          ><span>{intlMessages['camera.buttons.selfscan.toggle.video.label']}</span></ToggleButton>
        <ToggleButton
          onClick={() => setType("PHOTO")}
          toggleOn={type=="PHOTO"}
          ><span>{intlMessages['camera.buttons.selfscan.toggle.photo.label']}</span></ToggleButton>
      </ToggleButtons>
    </StyledFullScanButtons>
  )

}

const FullScanButtons = ({start, stop, takePhoto, isLiveStream, status, error}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentScanState = useSelector(getCurrentScanState);
  const currentScanRegion = useSelector(getCurrentRegion)
  const currentSelected = useSelector(getCurrentSelected)
  const mediaByRegion1 = useSelector(getMediaByRegion1);
  const mediaByRegion2 = useSelector(getMediaByRegion2);
  const mediaByRegion3 = useSelector(getMediaByRegion3);
  const mediaByRegion4 = useSelector(getMediaByRegion4);


  useEffect(()=>{
    if (!currentSelected){
      if (currentScanRegion == 0 && mediaByRegion1.length > 0){
        dispatch(mediaActions.setCurrentItem(mediaByRegion1[0].getId()));
        dispatch(fullScanActions.stopScanning());
      } else if (currentScanRegion == 1 && mediaByRegion2.length > 0){
        dispatch(mediaActions.setCurrentItem(mediaByRegion2[0].getId()));
        dispatch(fullScanActions.stopScanning());
      } else if (currentScanRegion == 2 && mediaByRegion3.length > 0){
        dispatch(mediaActions.setCurrentItem(mediaByRegion3[0].getId()));
        dispatch(fullScanActions.stopScanning());
      } else if (currentScanRegion == 3 && mediaByRegion4.length > 0){
        dispatch(mediaActions.setCurrentItem(mediaByRegion4[0].getId()));
        dispatch(fullScanActions.stopScanning());
      }
    }

  }, [currentScanRegion])

  const startRegion = () => {
    dispatch(fullScanActions.startScanning())
    start();
  }

  const stopRegion = () => {
    dispatch(fullScanActions.stopScanning());
    stop();
  }

  const resetAll = () => {
    let negativeCallbacks = [modalActions.closeModal('confirmDialog')]
    let positiveCallbacks = [
      fullScanActions.resetAll(),
      mediaActions.setCurrentItem(null),
      mediaActions.resetMedia(),
      () => storage.clearScanDetails(),
      modalActions.closeModal('confirmDialog')
    ]
    dispatch(modalActions.openConfirmDialog(
      intlMessages['scanningpage.guidedScan.resetAll.title'],
      intlMessages['scanningpage.guidedScan.resetAll.description'],
      negativeCallbacks,
      positiveCallbacks,
      intlMessages['scanningpage.guidedScan.resetAll.confirm.text']
    ));
  }

  const redoRegion = () => {
    dispatch(mediaActions.removeItem(currentSelected));
    dispatch(fullScanActions.redoRegion());
  }

  let resetBtnEnabled = (currentScanRegion > 0 || (currentScanRegion == 0 && currentScanState == FULLSCAN_STATES.FINISHED))

  return (
    <StyledFullScanButtons>
      <StyledTextButton
        onClick={resetAll}
        btnDisabled={!(resetBtnEnabled)}
        >
        <span>{intlMessages['camera.buttons.fullscan.buttons.cancel.label']}</span>
      </StyledTextButton>
      <FullScanCameraButton
        start={startRegion}
        stop={stopRegion}
        takePhoto={takePhoto}
        isLiveStream={isLiveStream}
        status={status}
        error={error}
        />
      <StyledTextButton
        btnDisabled={currentScanState !==  FULLSCAN_STATES.FINISHED}
        onClick={redoRegion}
        >
        <span>{intlMessages['camera.buttons.fullscan.buttons.redo.label']}</span>
      </StyledTextButton>
    </StyledFullScanButtons>
  )
}

const PartialScanButtons = ({start, stop, takePhoto, isLiveStream, status, error}) => {
  const [ type, setType] = useState('PHOTO');
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentRegion = useSelector(getSelectedRegion);
  const currentRegionData = useSelector(getCurrentPartialScanRegion)
  const isReviewOpen = useSelector(getReviewOpen);

  const redoRegion = () => {
    dispatch(partialScanActions.toggleReview(false));
    dispatch(partialScanActions.removePartialScanImage(currentRegion));
  }

  return(
    <StyledPartialScanButtons>
      <StyledTextButton
        btnDisabled={true}
        >
        <span>{intlMessages['camera.buttons.fullscan.buttons.cancel.label']}</span>
      </StyledTextButton>
      <PartialScanCameraButton
        type={type}
        currentRegion={currentRegion}
        start={start}
        stop={stop}
        takePhoto={takePhoto}
        isLiveStream={isLiveStream}
        status={status}
        error={error}
      />
      <StyledTextButton
        btnDisabled={!isReviewOpen && !(currentRegionData && currentRegionData.objectUrl)}
        onClick={redoRegion}
        >
        <span>{intlMessages['camera.buttons.fullscan.buttons.redo.label']}</span>
      </StyledTextButton>
    </StyledPartialScanButtons>
  )
}

const DetectionScanButtons = ({start, stop, takePhoto, isLiveStream, status, error}) => {
  const [ type, setType ] = useState('VIDEO');
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentItem = useSelector(getDetectionScan);

  const redoRegion = () => {
    dispatch(detectionScanActions.removeDetection());
  }

  return (
    <StyledDetectionScanButtons>
      <StyledTextButton
        btnDisabled={true}
        >
        <span>{/*intlMessages['camera.buttons.fullscan.buttons.cancel.label']*/}</span>
      </StyledTextButton>
      <DetectionScanCameraButton
        type={type}
        start={start}
        stop={stop}
        takePhoto={takePhoto}
        isLiveStream={isLiveStream}
        currentItem={currentItem}
        status={status}
        error={error}
      />
      <StyledTextButton
        btnDisabled={!currentItem}
        onClick={redoRegion}
        >
        <span>{intlMessages['camera.buttons.fullscan.buttons.redo.label']}</span>
      </StyledTextButton>
    </StyledDetectionScanButtons>
  )
}

const CameraButtons = ({start, stop, takePhoto, isLiveStream, status, error, flip, photoMode}) => {
  const scanType = useSelector(getScanType);

  if (scanType == RECORD_TYPES.PARTIALSC){
    return(
      <PartialScanButtons
        start={start}
        stop={stop}
        takePhoto={takePhoto}
        isLiveStream={isLiveStream}
        status={status}
        error={error}
        />
    )
  }

  if (scanType == RECORD_TYPES.DETECTIONSC){
    return (
      <DetectionScanButtons
        start={start}
        stop={stop}
        takePhoto={takePhoto}
        isLiveStream={isLiveStream}
        status={status}
        error={error}
      />
    )
  }

  if (scanType == RECORD_TYPES.FULLSCAN) {
    return (
      <FullScanButtons
        start={start}
        stop={stop}
        takePhoto={takePhoto}
        isLiveStream={isLiveStream}
        status={status}
        error={error}
      />)
  } else {
    return (
      <SelfScanButtons
        start={start}
        stop={stop}
        photoMode={photoMode}
        takePhoto={takePhoto}
        isLiveStream={isLiveStream}
        status={status}
        error={error}
      />)
  }
/*
  return(
    <ButtonSection>
      <RecordButton
          onClick={start}
          disabled={!isLiveStream || status === 'recording' || error}
          >Record</RecordButton>
      <StopButton
          onClick={stop}
          disabled={!isLiveStream || status && status !== 'recording' || error}
        >Stop</StopButton>
      <PhotoButton
        onClick={takePhoto}
        disabled={error || !isLiveStream}
      >Capture</PhotoButton>
      <NextRegionButton
        onClick={nextRegion}
        disabled={!isLiveStream || error || scanType != RECORD_TYPES.FULLSCAN || currentScanState != 'END' || currentRegion > 4}
        >NEXT</NextRegionButton>
      <RedoRegionButton
        onClick={redoRegion}
        disabled={!isLiveStream || error || scanType != RECORD_TYPES.FULLSCAN || currentScanState != 'END' || currentRegion > 4 || currentRegion < 1}
        >REDO</RedoRegionButton>
      <FlipButton
          onClick={flip}
          disabled={error || !isLiveStream}
        >Mirror</FlipButton>
    </ButtonSection>
  )*/
}

export const PloverCamera = ({deviceId, mediaRecorderOptions, photoMode='PHOTO', extraBlobOptions={}}) => {
  const dispatch = useDispatch();
  const scanType = useSelector(getScanType);
  const currentRegion = useSelector(getCurrentRegion);
  const isScanOpen = useSelector(getScanningPageModal);
  const [mirrored, setMirrored] = useState(false);
  const canvas = useRef(null);
  const [ isRunning, setIsRunning ] = useState(false);
  const liveStreamPreview = useRef(null);
  const currentPartialScanRegion = useSelector(getSelectedRegion);

  const mediaStreamConstraints = {
    video: {
      width: 640,
      height: 480,
      zoom: true,
      deviceId: { exact: deviceId }
    }
  };

  let blobOptions = {
    type: 'video/webm',
    ...extraBlobOptions
  }


  //if (options.mimeType == 'video/webm\;codecs=h264') {
  //  blobOptions = Object.assign({}, blobOptions, {type: 'video/mp4'})
  //} else if (options.mimeType == 'video/mp4') {
  //  blobOptions = Object.assign({}, blobOptions, {type: 'video/mp4'})
  //}

  let {
      error,
      status,
      mediaBlob,
      stopRecording,
      getMediaStream,
      clearMediaStream,
      startRecording,
      liveStream
    } = useMediaRecorder({
      blobOptions: blobOptions,
      mediaRecorderOptions: mediaRecorderOptions,
      mediaStreamConstraints: mediaStreamConstraints
    });

    if (error){
      console.log("ERROR MEDIA STREAM RECORDER ", error);
    }

    useEffect(()=>{
      if (!isScanOpen){
        stopRecording();
        clearMediaStream();
      }
    }, [isScanOpen])

    const start = () => {
      dispatch(mediaActions.setCurrentItem(null));
      setIsRunning(true);
      startRecording();

    }

    const stop = () => {
      stopRecording();
      getMediaStream();

      setIsRunning(false);
    }

    const takePhoto = () => {
      if (canvas && canvas.current){
        let photoWidth = 640;
        let photoHeight = 480;

        canvas.current.width = photoWidth;
        canvas.current.height = photoHeight;

        const context = canvas.current.getContext('2d');
        if (context && liveStreamPreview){
          context.drawImage(liveStreamPreview.current, 0 , 0, photoWidth, photoHeight)
          //let tempUrl = canvas.current.toDataURL("image/png", 1.0);
          canvas.current.toBlob(blob => {
            if (scanType == RECORD_TYPES.PARTIALSC){
              dispatch(partialScanActions.addPartialScanImage(currentPartialScanRegion, blob, {led:-1}))
            } else {
              dispatch(mediaActions.addMediaItem(blob, "testname", "image", scanType, { led:-1 } ));
            }
          }, "image/png", 1.0)
          //dispatch(mediaActions.addMediaItem(canvas.current, "testname", "image"));
          //dispatch(mediaActions.addMediaItem(, "testname", "image"));
        }
      }
    }

    const addVideoToMediaList = (vid) => {
      if (vid){
        if (scanType == RECORD_TYPES.FULLSCAN){
          dispatch(mediaActions.addMediaItem(vid, "testname", "video", scanType, {region: currentRegion, led:-1}));
        } else if (scanType == RECORD_TYPES.PARTIALSC){
          dispatch(partialScanActions.addPartialScanImage(currentPartialScanRegion, vid, {led:-1}));
        } else if (scanType == RECORD_TYPES.DETECTIONSC){
          dispatch(detectionScanActions.addDetectionScan(vid, "testname", "video", RECORD_TYPES.SELF, {led:-1, is_detect:true}))
        } else {
          dispatch(mediaActions.addMediaItem(vid, "testname", "video", scanType, { led:-1 }));
        }
      }
    }

    useEffect(()=>{
      clearMediaStream();
      if (deviceId){
        getMediaStream();
        console.log("getMediaStream",deviceId, status)
      }
    },
    [deviceId])

    useEffect(() => {
      // run this if it is webm.
      if (mediaBlob){
        addVideoToMediaList(mediaBlob);
        //addVideoToMediaList(mediaBlob);
        // TODO: broken with mediarecorder
        //getSeekableBlob(mediaBlob, function(seekableBlob){
        //  addVideoToMediaList(seekableBlob);
        //})
      } else {
        console.log("no media blob or getseekableblob")
      }
    },
    [mediaBlob])

    useEffect(() =>{
      return () => {
        clearMediaStream();
        console.log("cleaned up");
      };
    },
    [])

    return(
      <StyledPloverCamera>
        <LiveStreamPreview
            ref={liveStreamPreview}
            stream={liveStream}
            mirrored={mirrored}
            error={error}
            status={status}
            isRunning={isRunning}
            stopStream={stop}
            scanType={scanType}
        />
        <Canvas ref={canvas} />
        <UploadProgress />
        <CameraButtonsWrapper>
          <CameraButtons
              start={start}
              stop={stop}
              photoMode={photoMode}
              takePhoto={takePhoto}
              isLiveStream={liveStream != null}
              status={status}
              error={error}
              flip={() => setMirrored(old => !old)}
          />
        </CameraButtonsWrapper>
      </StyledPloverCamera>
    )
}

export const NoCamera = () => {
  const intlMessages = useSelector(getIntlMessages);
  const isAwaitingPlover = useSelector(getIsAwaitingPlover);
  if (isAwaitingPlover){
    return (
      <StyledPloverCamera>
        <VideoLoading>
          {intlMessages['camera.loading.readingplover']}
        </VideoLoading>
        <UploadProgress />
        <CameraButtons
          isLiveStream={false}
        />
      </StyledPloverCamera>
    )
  } else {
    return (
      <StyledPloverCamera>
        <VideoLoading>
          {intlMessages['camera.loading.awaitingplover']}
        </VideoLoading>
        <UploadProgress />
        <CameraButtons
          isLiveStream={false}
        />
      </StyledPloverCamera>
    )
  }
}
