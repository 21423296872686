const PREFIX = 'RECORDS_';
export const constants = {
  FETCHRECORDS_REQUEST: `${PREFIX}FETCHRECORDS_REQUEST`,
  FETCHRECORDS_SUCCESS: `${PREFIX}FETCHRECORDS_SUCCESS`,
  FETCHRECORDS_FAILURE: `${PREFIX}FETCHRECORDS_FAILURE`,

  FETCHRECORD_REQUEST: `${PREFIX}FETCHRECORD_REQUEST`,
  FETCHRECORD_SUCCESS: `${PREFIX}FETCHRECORD_SUCCESS`,
  FETCHRECORD_FAILURE: `${PREFIX}FETCHRECORD_FAILURE`,

  FETCHDETECTIONS_REQUEST:`${PREFIX}FETCHDETECTIONS_REQUEST`,
  FETCHDETECTIONS_SUCCESS:`${PREFIX}FETCHDETECTIONS_SUCCESS`,
  FETCHDETECTIONS_FAILURE:`${PREFIX}FETCHDETECTIONS_FAILURE`,

  UPDATE: `${PREFIX}UPDATE`,
  UPDATE_STATE: `${PREFIX}UPDATE_STATE`,
  UPDATE_STATE_OBJECT: `${PREFIX}UPDATE_STATE_OBJECT`
}
