import React from 'react';

export const CameraStopButton = () => {
  return(
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#E54848"/>
      <rect x="15" y="15" width="10" height="10" fill="white"/>
      </svg>
  )
}
