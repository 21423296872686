import React from 'react';
import {
    Box,
    Flex,
    Text,
} from '@chakra-ui/react';

import {
    StyledReportSection,
    StyledReportInformation
} from '../styles';
import { FormattedMessage } from 'react-intl';
import { NoReportIcon } from '../../_images/icons/NoReportIcon';
  
export const ReportNotAvailableSection = () => {
    return (
        <StyledReportSection>
          <StyledReportInformation>
            <Flex pl={['20px']} direction={'column'} grow={[1]} w={['full']}>
              <Box>
                <Flex align={['center']} flexWrap={'wrap'}>
                  <Text
                    color="#273238"
                    fontSize={['25px']}
                    mr={['15px']}
                  >
                    {""}
                    {/*<ScanTypeText type={request?.record?.type} />
                    <FormattedMessage
                      id="format.fullName"
                      values={{
                        givenName: request?.user_profile?.first_name,
                        familyName: request?.user_profile?.last_name
                      }}
                      />*/}
                  </Text>
                </Flex>
                <Text color="#6B8A99" fontSize={['16px']}>
                  {""}
                </Text>
              </Box>
              <Box
                p={0} 
                m={['5px']}
                bg={['#fff']}
                w={['full']}
                borderRadius={['15px']}
              >   
                <Flex mt={['30px']}>
                  <Text fontSize={['30px']} color={'#273238'}>
                    <FormattedMessage
                        id="requestDetailPage.reportsection.report.title"
                        defaultMessage="Report Notes"
                    />
                  </Text>
                </Flex>
                <Flex 
                  w={'580px'}
                  p={['40px']}
                  py={['50px']}
                  pt={['80px']}
                  m={['5px']}
                  bg={['#fff']}
                  justify={'center'}
                  align={'center'}
                  borderRadius={['15px']}
                  direction={['column']}
                >
                  <NoReportIcon />
                  <Text mt={['10px']} color={'#6B8A99'} fontSize={['20px']}>
                    <FormattedMessage   
                      id={'reportDetail.history.noReport'}
                      defaultMessage={'No data'}
                    />
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </StyledReportInformation>
        </StyledReportSection>
      )
}