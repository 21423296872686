import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const ReportAIIcon = ({width=14, height=15, color="#367FFF", ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 14 15" color={color}  {...props}>
        <g clip-path="url(#clip0_768_4062)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.20312 6.45H7.9304V5.75H3.20312V6.45Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.20312 9.2498H7.9304V8.5498H3.20312V9.2498Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8864 7.73372V1.90039H8.87727V2.48372H10.2955V5.05039L7.34091 10.6504H9.82273V12.1671H0.959091V2.48372H2.25909V1.90039H0.25V12.6337H9.82273V13.2171V14.5004L10.7682 12.6337L10.8864 12.2837L13.25 7.73372H10.8864ZM10.4136 12.0504V10.0671H8.28636L10.1773 6.33372V8.31706H12.3045L10.8864 11.0004L10.4136 12.0504Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25781 0.966797V3.53346H8.87599V0.966797H2.25781ZM8.1669 3.0668H2.9669V1.55013H8.28509V3.0668H8.1669Z" fill="currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_768_4062">
            <rect width="13" height="14" fill="white" transform="translate(0.25 0.5)"/>
            </clipPath>
        </defs> 
    </Icon>
  )
}