import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import localeData from '../../_locales/data.json';
import { LANGUAGE_LIST } from '../../_config';

import { getIntlMessagesWithDefault } from '../../store';

// actions
import { updateIntl } from 'react-intl-redux';

// selectors
import { getIntlMessages, getIntlLocale } from '../../Authentication/_selectors';

// styles
import { StyledSwitchLanguage } from './styles';
import { StyledButton } from '../../_styles/forms';

export const SwitchLanguage = ({closeModal}) => {
  const modalName = 'switchLanguage'
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale)

  let currLanguage = localStorage.getItem('language')

  const handleClick = (lang) => {
    // all variables involving language switched
    localStorage.setItem("language", lang);
    if (window.ChannelIO){
      window.ChannelIO('updateUser', {language: lang});
    }
    dispatch(updateIntl({locale: lang, messages: getIntlMessagesWithDefault(lang)}));
    closeModal()
  }

  return (
    <StyledSwitchLanguage>
      <div className={'title'}>{intlMessages['settings.menu.menulist.menuItem.switchLanguage.title']}</div>
      <div className={'actions'}>
        {LANGUAGE_LIST.map(item => (
          <StyledButton disabled={intlLocale == item.key}  onClick={() => handleClick(item.key)}>
            {item.label}
          </StyledButton>
        ))} 
      </div>
    </StyledSwitchLanguage>
  )

}
