import { constants } from './_constants';
import { services } from './_services';

export const actions = {
  fetchLatestCheckupwithDentist,
  fetchVideoChatToken,
  fetchVideoChatList,
  setCurrentVideoChat,
  fetchAppointmentsList
}

function fetchLatestCheckupwithDentist(q_type, unique_id, urlParams){
  return (dispatch) => {
    dispatch(request());
    services.fetchLatestCheckupwithDentist(q_type, unique_id, urlParams)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCHVIDEOCHAT_REQUEST } }
  function success(data) {
    // breakdown reports...

    return { type:constants.FETCHVIDEOCHAT_SUCCESS, data}
  }
  function failure(error) { return { type:constants.FETCHVIDEOCHAT_FAILURE, error} }
}

function setCurrentVideoChat(id){
  return (dispatch) => {
    dispatch(update(id))
  }
  function update(id){ return {type: constants.UPDATE, data: {currentVideoChat: id} }}
}

function fetchAppointmentsList(){
  return (dispatch, getState) => {
    dispatch(request());
    services.fetchVideoChatList({status: 'APPOINTMENT'})
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCHAPPOINTMENTS_REQUEST } }
  function success(respData) {
    // breakdown reports...
    let byId = respData.request_list
      ? respData.request_list.reduce((a,b) => {a[b.unique_id] = b;return a}, {})
      : {};
    return { type:constants.FETCHAPPOINTMENTS_SUCCESS, data: {byId}}
  }
  function failure(error) { return { type:constants.FETCHAPPOINTMENTS_FAILURE, error} }
}

function fetchVideoChatList(){
  return (dispatch, getState) => {
    dispatch(request());
    services.fetchVideoChatList()
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCHVIDEOCHATS_REQUEST } }
  function success(respData) {
    // breakdown reports...
    let byId = respData.request_list
      ? respData.request_list.reduce((a,b) => {a[b.unique_id] = b;return a}, {})
      : {};
    return { type:constants.FETCHVIDEOCHATS_SUCCESS, data: {byId}}
  }
  function failure(error) { return { type:constants.FETCHVIDEOCHATS_FAILURE, error} }
}

function fetchVideoChatToken(q_type, unique_id){
  return (dispatch) => {
    dispatch(request());
    let data = {[q_type] : unique_id}
    console.log("FETCHVIDEOCHATTOKEN ", data);
    services.fetchVideoChatToken(data)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCHVIDEOCHATTOKEN_REQUEST } }
  function success(data) {
    // breakdown reports...

    return { type:constants.FETCHVIDEOCHATTOKEN_SUCCESS, data}
  }
  function failure(error) { return { type:constants.FETCHVIDEOCHATTOKEN_FAILURE, error} }
}
