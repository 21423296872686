import React from 'react';

export const LoadingDots = ({width, height, color, viewBox, animate=true}) => {
  const svgWidth = (width) ? `${width}px` : '120px';
  const svgHeight = (height) ? `${height}px` : '30px';
  const svgViewBox = (viewBox) ? viewBox : '0 0 120 30';
  const svgColor = (color) ? color : '#273238';
  if (animate){
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width={svgWidth} height={svgHeight} viewBox={svgViewBox} fill={color}>
        <circle cx="15" cy="15" r="15">
              <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/>
              <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/>
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3">
            <animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite"/>
            <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"/>
        </circle>
        <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/>
            <animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/>
        </circle>
      </svg>
    )
  } else {
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width={svgWidth} height={svgHeight} viewBox={svgViewBox} fill={color}>
        <circle cx="15" cy="15" r="9" fill-opacity="0.4">
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3">
        </circle>
        <circle cx="105" cy="15" r="9" fill-opacity="0.5">
        </circle>
      </svg>
    )
  }

}
