import { createSelector } from 'reselect'

import { getMediaToUpload, getMediaList } from '../MediaUploader/_selectors';

export const getPloverScanState = (state) => state.ploverScan;

export const getScanType = createSelector(
  [getPloverScanState],
  (state) => {
    return state.scanType;
  }
)

export const getCurrentRecordId = createSelector(
  [getPloverScanState],
  (state) => {
    return state.recordId;
  }
)

export const getCurrentMediaToUpload = createSelector(
  [getMediaToUpload, getScanType],
  (mediaToUpload, scanType) => {
    return (mediaToUpload)
      ? mediaToUpload.filter(item => item.getScanType() == scanType)
      : [];
  }
)

export const getCurrentMediaList = createSelector(
  [getMediaList, getScanType],
  (items, scanType) => {
    return (items)
      ? items.filter(item => item.getScanType() == scanType)
      : [];
  }
)

export const getUploadTotal = createSelector(
  [getCurrentMediaList],
  (items) => {
    return items
      ? items.length * 100
      : 0;
  }
)

export const getUploadProgress = createSelector(
  [getCurrentMediaList],
  (items) => {
    return items
      ? items.reduce((a,b) => {
          return a + b.getProgress();
        }, 0)
      : 0;
  }
)
