import { constants } from './_constants';
import { services } from './_services';
import { createMediaItem, updateMediaItem as updateExistingMediaItem } from './_models';
import { actions as fullScanActions } from '../FullScanChart/_actions';
import axios from 'axios'

export const actions = {
  removeItem,
  setCurrentItem,
  addMediaItem,
  updateMediaItem,
  updateState,
  updateStateObject,
  uploadMedia,
  uploadAll,
  resetMedia
}

function setCurrentItem(id){
  return (dispatch) => {
    dispatch(update(id))
  }
  function update(id){
    return { type: constants.SET_CURRENTSELECTED, data: id}
  }
}

function resetMedia(){
  return (dispatch) => {
    dispatch(reset())
    dispatch(fullScanActions.resetAll());
  }
  function reset(){
    return { type: constants.RESET_MEDIA }
  }
}

function removeItem(id){
  return (dispatch) => {
    dispatch(remove(id))
  }
  function remove(id){
    return { type: constants.REMOVE_MEDIA, data: [id]}
  }
}

function uploadMedia(item, record, onSuccess=null){
  return (dispatch) => {
    services.upload(item.getData(), record, (event) => {
      // HACK set final progress to 95 because there is a delay between progress and finished upload
      dispatch(setProgress(item.getId(), Math.round((95 * event.loaded) / event.total)))
    }, item.getExtraData())
      .then(
        resp => {
          dispatch(success(item.getId(), resp.data));
          onSuccess && dispatch(onSuccess(item.getId(), resp.data));
        }
      ).catch(
        error => dispatch(failure(item.getId(), error))
      )
  }
  function setProgress(id, progress){return {type: constants.UPLOAD_SETPROGRESS, data: {id: id, progress: progress}}}
  function success(id, data){ return {type: constants.UPLOAD_SUCCESS, data: {id: id, uploadData: data} }}
  function failure(id, error){ return {type: constants.UPLOAD_FAILURE, data: {id: id} }}
}

function uploadAll(mediaList, record){
  return (dispatch) => {
    // ALTERNATIVE, put scanType as arg and fitler from here...
    let toUpload = []
    let uploadKeys = []
    mediaList.map( (item, index) => {
      toUpload.push(
        services.upload(item.getData(), record, (event) => {
          // HACK set final progress to 95 because there is a delay between progress and finished upload
          dispatch(setProgress(item.getId(), Math.round((95 * event.loaded) / event.total)))
        }, item.getExtraData()))
      uploadKeys.push(item.getId())
    })
    axios.all(toUpload)
      .then( axios.spread((...responses) => {
        responses.map((resp, index) => {
          dispatch(success(uploadKeys[index], resp.data))
        })
      })).catch(errs => {
        console.log(errs);
        // TODO errs lenght or check if object
        if (errs.length > 0){
          errs.map((err, index) => {
            dispatch(failure(uploadKeys[index], err))
          })
        } else if (typeof errs == typeof {}){
          dispatch(failure(0, JSON.stringify(errs)))
        } else {
          dispatch(failure(0,errs))
        }
      })
  }
  function setProgress(id, progress){return {type: constants.UPLOAD_SETPROGRESS, data: {id: id, progress: progress}}}
  function success(id, data){ return {type: constants.UPLOAD_SUCCESS, data: {id: id, uploadData: data} }}
  function failure(id, error){ return {type: constants.UPLOAD_FAILURE, data: {id: id} }}
}

function addMediaItem(data, name, type, scanType, extraData={} ){
  return (dispatch) => {
    dispatch(update(data, name, type, scanType, extraData));
  }
  function update(data, name, type, scanType, extraData){
    let mediaItem = createMediaItem(data, name, type, scanType, extraData);
    return { type: constants.UPDATE_MEDIA, data: {id: mediaItem.getId(), data: mediaItem}}
  }
}

function updateMediaItem(id, data){
  return (dispatch, getState) => {
    const byId = getState().mediaUploader.byId;
    dispatch(update(id, byId[id], data));
  }
  function update(id, oldData, newData){
    let mediaItem = updateExistingMediaItem(oldData, newData)
    return { type: constants.UPDATE_MEDIA, data: {id: id, data: mediaItem}}
  }
}

function updateState(key, value){
  return (dispatch) => {
    dispatch(update(key, value));
  }
  function update(key, value){
    return { type: constants.UPDATE_STATE, data: {key, value}}
  }
}

function updateStateObject(key, value){
  return (dispatch) => {
    dispatch(update(key, value));
  }
  function update(key, value){
    return { type: constants.UPDATE_STATE_OBJECT, data: {key, value}}
  }
}
