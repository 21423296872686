
import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { Tabs, Tab, TabList, Box } from '@chakra-ui/react';
import Select from 'react-select';
import { find } from 'lodash';

import { SearchInput } from '../Settings/ProfileList';

import { getIntlMessages, getProfiles, getSelectedProfile } from "../Authentication/_selectors";

import { actions } from './_actions';
import { constants } from './_constants';

import { formatName } from '../_helpers/utils';
import { getRequestFilter } from './_selectors';

import { actions as authActions } from '../Authentication/_actions';

import {
  StyledLabel,
  StyledInputLayout,
} from '../_styles/forms';

// custom select component
const settingsStyles = {
  container: (provided, state) => {
    const width = '100%';
    return { ...provided, width };
  },
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#273238' : '#C7D3D9';
    const paddingLeft = '7px'
    return { ...provided, color, paddingLeft };
  },
  control: (provided, state) => {
    const border = '0.5px solid #4D5666;';
    const background = 'transparent';
    const fontSize = '16px';
    const height = '40px';
    const borderRadius = "60px";
    const boxSizing = 'border-box';
    return { ...provided, border, background, fontSize, height, borderRadius, boxSizing };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      paddingLeft: "7px",
      color: "#999",
    }
  },
  dropdownIndicator: (provided, state) => {
    const color = '#273238';
    return { ...provided, color };
  },
  indicatorSeparator:  (provided, state) => {
    const backgroundColor = '#F9F9F9';
    return { ...provided, backgroundColor };
  },
  menu: (provided, state) => {
    const zIndex = 1080;
    return {...provided, zIndex}
  }
}
const SelectAdapterNonClearable = (
  {meta, items, label, type,  intlMessages , onChange, isLabelShowing,
    className="SelectAdapter", defaultValue, disabled, ...rest }) => {
  return(
    <StyledInputLayout className={className}>
      {isLabelShowing && <StyledLabel>{label}</StyledLabel>}
      <Select
        styles={settingsStyles}
        options={items}
        isDisabled={disabled}
        clearable={false}
        searchable={true}
        placeholder={intlMessages['profiles.search.placeholder']}
        value={find(items, {'value': defaultValue})}
        onChange={(value) => onChange(value)}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        {...rest}
      />
    </StyledInputLayout>
  )
}



export const ProfilesFilterTabs = ({page, isLabelShowing=true}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const profiles = useSelector(getProfiles);
  const selected = useSelector(getSelectedProfile);
  // overwriting styles for tabs

  const tabSx = {
    fontSize: 12,
    fontFamily: 'Proxima Nova Semibold',
    padding: '12px 18px',
    minHeight:'32px',
  }

  // setup some variables based on page
  let showAllOption = true;
  let maxWidth = 430;
  if (page == 'CHATRECORDATTACHMENT'){
    showAllOption = false;
    maxWidth = '100%'
  } else if (page == 'REPORT_DETAILS'){
    maxWidth = 580
  } else if (page == 'DASHBOARD'){
    tabSx.padding = '5px 10px'
  }

  useEffect(()=>{
    // initialize, set back to null and fetch all profiles except CHATRECORDATTACHMENT page
    showAllOption && dispatch(authActions.getProfiles());
    dispatch({type: constants.UPDATE, data: {requestFilter: null}});
    return () => {};
  }, [])

  const handleChange = (newValue) => {
    // set requestFilter (profile.id) on change and fetch requests for that user
    dispatch({type: constants.UPDATE, data: {requestFilter: newValue?.id}});
    // set profile value if newValue is not null (All)
    if (newValue?.id){
      dispatch(actions.fetchRequestList({profile: newValue?.id}));
      dispatch(authActions.selectProfile(newValue));
    } else {
      dispatch(actions.fetchRequestList());
    }
  };

  return (
      <Box minW={'250px'} w={'unset'} pb={'10px'}>
          <SelectAdapterNonClearable 
            name={'profile'}
            className={'SelectAdapter'}
            items={profiles.map(profile => {
              return {...profile, label: formatName(intlMessages['format.fullName'], profile.first_name, profile.last_name), value: profile.id}
            })}
            intlMessages={intlMessages}
            defaultValue={selected?.id}
            onChange={handleChange}
            isLabelShowing={isLabelShowing}
          />
      </Box>
  )
}
