import React from 'react';
import { LoadingEllipses } from '../_components/LoadingEllipses';
import { StyledLoadingContent, StyledLoadingText} from './styles';

export const LoadingContent = ({text}) => {
  return (
    <StyledLoadingContent>
      <LoadingEllipses />
      <StyledLoadingText>{text}</StyledLoadingText>
    </StyledLoadingContent>
  )
}
