import { constants } from './_constants';

const initState = {
  byId: {}
}

export function unreadMessageCounts(state=initState, action){
  switch (action.type){
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_BYID:
      return {
        ...state,
        byId: Object.assign({}, state.byId, action.data.byId)
      }
    case constants.UPDATE_CHANNEL:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.channel]: action.data.count
        }
      }
    case constants.UPDATE_CHANNEL_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.channel]: state.byId[action.data.channel] + 1
        }
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state
  }
}
