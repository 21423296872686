import { SETTINGS_LIST, URLS, GENDERS, API_DATE_FORMAT, DENTAL_USER_SETTINGS_LIST, ORPH_USER_SETTINGS_LIST } from '../_config';
import React, { useRef, useState, useMemo,  useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';
import { Link, useLocation, useParams, useHistory, Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";
import storage from '../_helpers/storage';
import { upperFirst, toUpper, find, map, cloneDeep, debounce, set} from 'lodash';
import { Box, Flex, Grid, HStack, Text, Avatar, IconButton, Button,
  Menu as ChakraMenu, 
  MenuButton, 
  MenuList as ChakraMenuList,  
  MenuItem as ChakraMenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure
 } from '@chakra-ui/react';

 import { ModalHeader } from '../Modals/ModalHeader';
import { toast } from 'react-toastify';
import { formatName, localeFormatDate, convertToAPIValues} from '../_helpers';
import { isEmpty } from '../_helpers/utils';
import PhoneInput from 'react-phone-number-input/input'
import VerticalDotsIcon from '../_images/icons/VerticalDotsIcon';

// components
import Select from 'react-select';
import { RecordsHistory } from './RecordsHistory';
import { DownloadReport } from './DownloadReport';
import { LoadingCircle } from '../_components/LoadingCircle';
import { LoadingEllipses } from '../_components/LoadingEllipses';
import { DayPickerAdapter } from '../_components/DayPickerAdapter';
import { GrNewWindow } from 'react-icons/gr';
import { BiSupport } from 'react-icons/bi';
import { MdChevronRight } from 'react-icons/md';
import { IoLanguageSharp } from 'react-icons/io5';
import { FaLanguage } from 'react-icons/fa'
import { SettingsOmise } from './Payments/SettingsOmise';
import { SearchInput, SettingsProfileItem as ProfileItem } from './ProfileList';
import { FaPlus } from 'react-icons/fa';
import { EditProfileForm, ProfileForm } from './ProfileForm';
import { RemoveAssignedDentistModal } from './RemoveAssignedDentistModal';

// redux state
import { services } from './_services';
import { actions } from './_actions';
import {
  getChannelIOLoaded,
  getCountryRegions,
  getCurrentCountry,
  getFavoriteDentist,
  getRegionsLoading
} from './_selectors';

// constants
import { constants as authConstants } from '../Authentication/_constants';

// actions
import { actions as modalActions } from '../Modals/_actions';
import { actions as authenticationActions} from '../Authentication/_actions';

// services
import { services as mediaUploadServices } from '../MediaUploader/_services';
import { services as authenticationServices } from '../Authentication/_services';

// selectors
import {
  getProfile,
  getProfiles,
  getIsProfileLoaded,
  getIsProfilesLoading,
  getFirstName,
  getLastName,
  getEmail,
  getAvatar,
  getCountry,
  getPayment,
  getPaymentProcessor,
  getPaymentProcessorCountry,
  getIntlMessages,
  getIntlLocale,
  getIsLoggedInFlag,
  getIsPaymentsLoaded,
  getPaymentPortalUrl
} from '../Authentication/_selectors';


// styles
import { InitialsAvatar } from '../_styles/common'
import {
  ErrorLabel,
  errorCss,
  StyledInput,
  StyledButton
}
from '../_styles/forms';
import {
  SectionPage,
  SectionTitle,
  StyledSectionWrapper,
  StyledMenuColumn,
  StyledMenuList,
  StyledMenuHeader,
  StyledMenuSubHeader,
  StyledMenuItem,
  StyledProfileAvatar,
  StyledDetailColumn,
  StyledEditAvatar,
  StyledDetailPersonalProfile,
  StyledField,
  StyledForm,
  StyledLabel,
  StyledInputLayout,
  StyledDetailsProfilesList,
  StyledDetailsProfilesDetails,
  StyledDetailsAuthentication,
  StyledDetailsPayment,
  StyledDetailsNotifications,
  StyledDetailsNotificationsContent,
  StyledLogoutButton

}
from './styles';


// custom select component
const settingsStyles = {
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    const paddingLeft = '7px'
    return { ...provided, color, paddingLeft };
  },
  control: (provided, state) => {
    const borderColor = '#C7D3D9';
    const background = '#F9F9F9';
    const fontSize = '16px';
    const height = '42px';
    const borderRadius = "3px";
    return { ...provided, borderColor, background, fontSize, height, borderRadius };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      paddingLeft: "7px",
      color: "#999",
    }
  },
  dropdownIndicator: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    return { ...provided, color };
  },
  indicatorSeparator:  (provided, state) => {
    const backgroundColor = '#F9F9F9';
    return { ...provided, backgroundColor };
  },
  menu: (provided, state) => {
    const zIndex = 1080;
    return {...provided, zIndex}
  }
}
const SelectAdapterNonClearable = (
  {input, meta, items, label, type,  intlMessages ,
    className="SelectAdapter", defaultValue, disabled, ...rest }) => {
  return(
  <StyledInputLayout className={className}>
    <StyledLabel>{label}</StyledLabel>
    <Select
      {...input}
      styles={settingsStyles}
      className={
        cn({"Input_Error": (meta.error && meta.touched)
            || (meta.submitError && !meta.dirtySinceLastSubmit)})
      }
      onChange={inputValue => {input.onChange(inputValue.value)}}
      options={items}
      isDisabled={disabled}
      clearable={false}
      searchable={false}
      value={find(items, {'value': defaultValue || input.value})}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      {...rest}
    />
    <ErrorLabel
      className={
        cn("Error__Label",
          {"Error": (meta.error && meta.touched)
                    || (meta.submitError && !meta.dirtySinceLastSubmit)} )
    } >
      {(meta.error && meta.touched
        || meta.submitError && !meta.dirtySinceLastSubmit)
        && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
    </ErrorLabel>
  </StyledInputLayout>
  )
}

const EditAvatar = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const [ currentFile, setCurrentFile ] = useState(null);
  const [ progress, setProgress ] = useState(0);
  const [ error, setError] = useState(null)

  const inputRef = useRef(null);

  useEffect(()=>{
    if (currentFile){

      mediaUploadServices.uploadAvatar(currentFile, (event) => {
        setProgress(Math.round((95 * event.loaded) / event.total));
      }).then(
        resp => {
          setProgress(0);
          setCurrentFile(null);
          let data = {key: 'profile', value: {avatar:resp.data.avatar}}
          dispatch(authenticationActions.updateObject(data))
        }
      ).catch(
        error => {
          setError(error);
          setProgress(0);
        }
      )

    }
  }, [currentFile] )

  const onFileUpload = async () => {
    if (progress == 0){
      inputRef.current.click();
    }
  }

  const onFileChange = (event) => {
    setCurrentFile(event.target.files[0]);
  }

  return(
    <StyledEditAvatar progress={progress}>
      <input ref={inputRef} type="file" onChange={onFileChange} />
      { progress > 0
        ? <LoadingEllipses width={'100%'} height={'20px'}/>
        : <div onClick={onFileUpload}>{intlMessages['settings.header.avatar.edit']}</div>
      }

    </StyledEditAvatar>
  )
}

const ProfileAvatar = ({src, initials}) => {
  if (src && src.length > 0){
    return (
      <StyledProfileAvatar>
        <img src={src} />
      </StyledProfileAvatar>
    )
  } else {
    return (
      <StyledProfileAvatar>
        <InitialsAvatar>
          {initials}
        </InitialsAvatar>
      </StyledProfileAvatar>
    )
  }
}

const DetailsNotifications = () => {
  const intlMessages = useSelector(getIntlMessages);

  return(
    <StyledDetailsNotifications>
      <h2>{intlMessages['settings.details.notifications.title']}</h2>
      <StyledDetailsNotificationsContent>
        {intlMessages['settings.details.notifications.description']}
      </StyledDetailsNotificationsContent>
    </StyledDetailsNotifications>
  )
}

const SettingsStripe = () => {
  const [ url, setUrl ] = useState(null);
  const [ error, setError ] = useState(null);
  const profileLoaded = useSelector(getIsProfileLoaded);
  const location = useSelector(useLocation)

  useEffect(()=>{
    async function f(){
      try{
        const resp = await services.fetchCustomerPortal({return_url: `${process.env.REACT_APP_PUBLIC_URL}${location.pathname}`});
        setUrl(resp.url);
      } catch (err){
        setError(err);
        setUrl(null);
      }
    }
    if (profileLoaded){
      f();
    }
  }, [profileLoaded])

  return(
    <div>
      { !url && <div className="loading"><LoadingCircle /></div> }
      <Redirect to={url} />
    </div>
  )
}

const DetailsPayments = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const [page, setPage] = useState(null);

  const userCountry = useSelector(getCountry);
  const paymentProcessor = useSelector(getPaymentProcessor);
  const paymentProcessorCountry = useSelector(getPaymentProcessorCountry);

  useEffect(()=>{
    dispatch(authenticationActions.getSettingsPayment())
    return () => {};
  }, [])

  useEffect(()=>{
    if (paymentProcessor == "OMISE" && paymentProcessorCountry == "TH"){
      setPage("OMISETH")
    } else if (paymentProcessor == "STRIPE" && paymentProcessorCountry == "US"){
      setPage("STRIPEUS")
    } else if (paymentProcessor == "STRIPE" && paymentProcessorCountry == "JP"){
      setPage("STRIPEJP")
    }
  }, [paymentProcessor, paymentProcessorCountry])

  if (page == "OMISETH"){
    return(
      <StyledDetailsPayment>
        <h2>{intlMessages['settings.details.payments.title']}</h2>
        <SettingsOmise />
      </StyledDetailsPayment>
    )
  } else if (page == "STRIPEUS"){
    return(
      <StyledDetailsPayment>
        <h2>{intlMessages['settings.details.payments.title']}</h2>
        <SettingsStripe />
      </StyledDetailsPayment>
    )
  } else if (page == "STRIPEJP"){
    return(
      <StyledDetailsPayment>
        <h2>{intlMessages['settings.details.payments.title']}</h2>
        <SettingsStripe />
      </StyledDetailsPayment>
    )
  } else {
    return(
      <StyledDetailsPayment>
        <h2>{intlMessages['settings.details.payments.title']}</h2>
        {intlMessages['settings.paymentmethod.notSupported']}
      </StyledDetailsPayment>
    )
  }
}

const DetailsAuthentication = () => {
  const dispatch = useDispatch();
  const changePasswordForm = useRef(null)
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  let [ succeeded, setSucceeded] = useState(null);

  const changePassword = async (values) => {

    try{
      const resp = await authenticationServices.changePassword(values);
      setSucceeded(intlMessages['settings.details.authentication.form.success.message'])
      changePasswordForm.current.reset();
      return null;
    } catch (error) {
      let newErrors = {}
      map(error, (value, key) => {
        newErrors[key] = value[0]
      })
      return {
        ...newErrors,
        [FORM_ERROR]: intlMessages['form.error.general_error']
      };
    }
  }

  return (
    <StyledDetailsAuthentication>
      <h2>{intlMessages['settings.details.authentication.title']}</h2>
        <div className={'successMessage'}>{succeeded && <span>{succeeded}</span>}</div>
        <Form
           onSubmit={changePassword}
           render = {({
             submitError,
             formError,
             handleSubmit,
             mutators,
             form,
             reset,
             submitting,
             pristine,
             validating,
             values
           }) => {
              changePasswordForm.current = form
              return (
                <StyledForm
                  onSubmit={handleSubmit}
                  >
                  {submitError && <div className="error">{submitError}</div>}
                  <StyledField name="old_password" parse={v => v}>
                      {({ input, meta}) => (
                        <StyledInputLayout>
                          <StyledLabel className="Input__Label">{upperFirst(intlMessages['settings.details.authentication.form.old_password.label'])}</StyledLabel>
                          <StyledInput
                            {...input}
                            type="password"
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            placeholder={upperFirst(intlMessages['settings.details.authentication.form.old_password.label'])} />
                          <ErrorLabel
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            >
                              {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.error || meta.submitError}
                          </ErrorLabel>
                        </StyledInputLayout>
                      )}
                    </StyledField>
                  <StyledField name="new_password1" parse={v => v}>
                      {({ input, meta}) => {
                        return (
                        <StyledInputLayout>
                          <StyledLabel className="Input__Label">{upperFirst(intlMessages['settings.details.authentication.form.new_password1.label'])}</StyledLabel>
                          <StyledInput
                            {...input}
                            type="password"
                            addCSS={((meta.error) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            placeholder={upperFirst(intlMessages['settings.details.authentication.form.new_password1.label'])} />
                          <ErrorLabel
                            isError={(meta.error || meta.submitError)}
                            addCSS={(meta.error || meta.submitError) && errorCss}
                            >
                              {meta.error || meta.submitError}
                          </ErrorLabel>
                        </StyledInputLayout>
                      )}}
                    </StyledField>
                    <StyledField name="new_password2" parse={v => v}>
                        {({ input, meta}) => (
                          <StyledInputLayout>
                            <StyledLabel className="Input__Label">{upperFirst(intlMessages['settings.details.authentication.form.new_password2.label'])}</StyledLabel>
                            <StyledInput
                              {...input}
                              type="password"
                              addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                              placeholder={upperFirst(intlMessages['settings.details.authentication.form.new_password2.label'])} />
                            <ErrorLabel
                              addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                              >
                                {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) &&  meta.error || meta.submitError}
                            </ErrorLabel>
                          </StyledInputLayout>
                        )}
                      </StyledField>
                          <Flex pb={'20px'}>
                            <Text
                              whiteSpace={'pre'}
                            >
                              {intlMessages['registration.form.password.requirements']}
                            </Text>
                          </Flex>
                          <Flex position={'relative'}>
                            <Button 
                                variant="solid"
                                position={'relative'}
                                type={"submit"}
                                disabled={submitting || pristine} 
                              >
                                {toUpper(intlMessages['settings.details.personalProfile.form.submit'])}
                            </Button>
                          </Flex>
                </StyledForm>
           )}}
        />
    </StyledDetailsAuthentication>
  )
}

export const Skeleton = () => {
  return (
      <div className="w-full animate-pulse flex-row items-center ">
          <div className={`h-[88px] w-full mb-[20px] rounded-[4px] bg-[#b8babd] `}></div>
      </div>
  );
};

const ProfilesResults = ({isLoading, profiles, onSelected}) => {
  const intlMessages = useSelector(getIntlMessages);

  if (isLoading){
    return (
      <>
        {
          [0,1,2,3].map(index => <Skeleton w={["full"]}/>)
        }
      </>
    )
  }
  if ((profiles || []).length == 0){
    return (
      <>
        {upperFirst(intlMessages['profiles.search.notFound'])}
      </>
    )
  } 
  return (
    <>
      {
        profiles.map((elem) => {
          if (!elem.is_default){
            return(<ProfileItem key={`${elem.id}`} onSelected={onSelected} profile={elem} />)
          } else {
            return <></>
          }
        })
      }
    </>
  )
}

const DetailsProfilesList = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: routeParams.settingsId == "profiles"});
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const profiles = useSelector(getProfiles);
  const profilesIsLoading = useSelector(getIsProfilesLoading)

  useEffect(()=>{
    dispatch(authenticationActions.getProfiles());
    dispatch({type: authConstants.SELECT_PROFILE, data: null});

  }, [])

  const addProfile = () => {
    history.push(URLS['profilesdetail'].url.replace("{profileId}", ''))
  }

  const onSelected = (profile) => {
    if (profile.is_default){
      //history.push(URLS['profile'].url)
      history.push(URLS['profilesdetail'].url.replace("{profileId}", profile.unique_id))

    } else {
      history.push(URLS['profilesdetail'].url.replace("{profileId}", profile.unique_id))
    }
  }
  // TODO: unhack this
  const paddingRight = '20px'

  return (
    <StyledDetailsProfilesList>
      <ProfileModal isOpen={isOpen} />
      <h2>{intlMessages['settings.details.profilesList.title']}</h2>
      <Flex justify={['flex-end']} w={['full']} mt={['15px']} px={['10px']} pr={[paddingRight]}>
        <div 
          class="flex text-[#273238] rounded-md flex-row items-center cursor-pointer ml-1 w-fit"
          onClick={() => addProfile()}
        >
          <Button variant={"outlineDefault"}>
            {'+  '}{intlMessages['profiles.addmember']}
          </Button>
        </div>
      </Flex>
      <Flex align={['center']} justify={['space-between']} pt={['15px']} pb={['4px']} pr={[paddingRight]}>
        <SearchInput bg={'#F9F9F9'} size={'md'} />
      </Flex>

      <div class="h-[613px] overflow-scroll">
        <Flex w={['full']} mt={['15px']} pr={[paddingRight]} direction={['column']} >
          <Box borderTop={['1px solid #D8D8D8']}>
            <ProfilesResults 
              isLoading={profilesIsLoading} 
              onSelected={onSelected} 
              profiles={profiles} 
            />
          </Box>
        </Flex>
      </div>
    </StyledDetailsProfilesList>
  )
}

const ProfileModal = ({isOpen}) => {
  const location = useLocation();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  let params = new URLSearchParams(location.search);
  let profId = params.get('uid');

  const onCancel = () => {
    history.replace(URLS['profileslist'].url)
  }

  return (
    <Modal isOpen={isOpen} size={'xl'} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader 
          title={profId ? intlMessages['profiles.editmember'] : intlMessages['profiles.addmember']} 
          onClose={onCancel} 
          props={{
            borderTopLeftRadius: ['6px'],
            borderTopRightRadius: ['6px']
          }}
        />
        <ModalBody p={'30px'}justify={'center'}>
          <Flex w={['full']} justify={'center'}  px={'30px'} >
            <DetailsProfilesDetail />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const DetailsProfilesDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);

  let params = new URLSearchParams(location.search);
  let profId = params.get('uid');

  const onDelete = () => {
    history.replace(URLS['profileslist'].url);
    toast.success(intlMessages['settings.details.personalProfile.form.delete.success.message'],
      {
        position: 'top-right',
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      }
    );
  }

  const onCancel = () => {
    history.replace(URLS['profileslist'].url)
  }

  const onEditSuccess = () => {
    toast.success(intlMessages['settings.details.personalProfile.form.edit.success.message'],
    {
      position: 'top-right',
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light'
    });
  }

  const onCreateSuccess = () => {
    toast.success(intlMessages['settings.details.personalProfile.form.create.success.message'],
    {
      position: 'top-right',
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light'
    });
    history.push(URLS['profileslist'].url)
  }

  const goBack = () => {
    history.push(URLS['profileslist'].url)
  }

  return (
    <StyledDetailsProfilesDetails>
      <div class="w-full">
        { profId
          ? <EditProfileForm profileId={profId} onDelete={onDelete} onCancel={onCancel} onSuccess={onEditSuccess}/>
          : <ProfileForm onCancel={onCancel}  onSuccess={onCreateSuccess} />
        }
      </div>
    </StyledDetailsProfilesDetails>
  )
}

const DetailsPersonalProfile = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const profile = useSelector(getProfile);
  const profileIsLoaded = useSelector(getIsProfileLoaded);
  const currentCountry = useSelector(getCurrentCountry);
  const countryRegions = useSelector(getCountryRegions);
  const regionsLoading = useSelector(getRegionsLoading);

  let [ succeeded, setSucceeded] = useState(null);

  useEffect(()=> {
    if (!isEmpty(currentCountry) ){
      dispatch(actions.fetchAppSettings('regions', currentCountry));
    }
  }, [currentCountry])

  useEffect(()=> {
    if (!profileIsLoaded){
      dispatch(authenticationActions.getProfile());
    }
  }, [profileIsLoaded])

  const updateProfile = async (values) => {
    const valuesCopy = cloneDeep(values);
    if (valuesCopy.date_of_birth == ""){
      valuesCopy.date_of_birth = null;
    } else {
      valuesCopy.date_of_birth =  localeFormatDate(valuesCopy.date_of_birth, intlLocale, API_DATE_FORMAT)
    }
    const convertedValues = convertToAPIValues(valuesCopy);
    try {
      const data = await authenticationServices.updateProfile(convertedValues);
      dispatch({ type: authConstants.UPDATE_OBJECT, data: {key: 'profile', value: data} })
      //setSucceeded(intlMessages["settings.details.personalProfile.form.success.message"]);
      toast.success(intlMessages['settings.details.personalProfile.form.success.message'],
        {
          position: 'top-right',
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        }
      );
      return null;
    } catch (error){
      let newErrors = {}
      map(error, (value, key) => {
        newErrors[key] = value[0]
      })
      return {
        ...newErrors,
        [FORM_ERROR]: intlMessages['form.error.general_error']
      };
    }
  }

  if (profile && Object.keys(profile).length == 0) {

    return (
      <StyledDetailPersonalProfile>
        <h2>{intlMessages['settings.details.personalProfile.title']}</h2>
        <div className="loading">
          <LoadingCircle />
        </div>
      </StyledDetailPersonalProfile>
    )
  } else if (profile) {
    let initValues = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      date_of_birth: profile.date_of_birth ? localeFormatDate(profile.date_of_birth, intlLocale, "l") : "",
      gender: profile.gender,
      location: profile.location,
      phonenumber: profile.phonenumber
    }

    return (
      <StyledDetailPersonalProfile>
        <h2>{intlMessages['settings.details.personalProfile.title']}</h2>
        <div className={'successMessage'}>{succeeded && <span>{succeeded}</span>}</div>
        <Form
           onSubmit={updateProfile}
           initialValues = {initValues}
           render = {({
             submitError,
             formError,
             handleSubmit,
             mutators,
             form,
             reset,
             submitting,
             pristine,
             validating,
             values
           }) => (
             <StyledForm
               onSubmit={handleSubmit}
               >
               <StyledField name="first_name" parse={v => v}>
                   {({ input, meta}) => (
                     <StyledInputLayout>
                       <StyledLabel className="Input__Label">
                         {upperFirst(intlMessages['settings.details.personalProfile.form.first_name.label'])}
                       </StyledLabel>
                       <StyledInput
                         {...input}
                         type="text"
                         addCSS={
                           ((meta.error && meta.touched)
                            || (meta.submitError && !meta.dirtySinceLastSubmit)
                            )
                            && errorCss
                          }
                         placeholder={upperFirst(intlMessages['settings.details.personalProfile.form.first_name.label'])} />
                       <ErrorLabel
                         addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                         >
                           {(
                             (meta.error && meta.touched)
                             || (meta.submitError && !meta.dirtySinceLastSubmit)
                            )
                            && upperFirst(intlMessages['settings.details.personalProfile.form.first_name.error'])
                              || meta.submitError
                           }
                       </ErrorLabel>
                     </StyledInputLayout>
                   )}
                 </StyledField>
                 {/*<StyledField name="last_name" parse={v => v}>
                     {({ input, meta}) => (
                       <StyledInputLayout>
                         <StyledLabel className="Input__Label">
                           {upperFirst(intlMessages['settings.details.personalProfile.form.last_name.label'])}
                         </StyledLabel>
                         <StyledInput
                           {...input}
                           type="text"
                           addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                           placeholder={upperFirst(intlMessages['settings.details.personalProfile.form.last_name.label'])} />
                         <ErrorLabel
                           addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                           >
                             {((meta.error && meta.touched)
                               || (meta.submitError && !meta.dirtySinceLastSubmit)
                              )
                              && upperFirst(intlMessages['settings.details.personalProfile.form.last_name.error'])
                                 || meta.submitError
                            }
                         </ErrorLabel>
                       </StyledInputLayout>
                     )}
                   </StyledField>
                   <StyledField
                      className={'dateField'}
                      name="date_of_birth"
                      dateFormat="l"
                      intlMessages={intlMessages}
                      intlLocale={intlLocale}
                      autoComplete={"off"}
                      label={upperFirst(intlMessages['settings.details.personalProfile.form.date_of_birth.label'])}
                      component={DayPickerAdapter}
                      placeholder={upperFirst(intlMessages['settings.details.personalProfile.form.date_of_birth.label'])}
                    />
                    <StyledField
                      name="gender"
                      className={'SelectAdapter'}
                      type='settings'
                      items={GENDERS.map(elem=>{elem.label=intlMessages[`config.GENDERS.${elem.value}`];return elem;})}
                      intlMessages={intlMessages}
                      label={upperFirst(intlMessages['settings.details.personalProfile.form.gender.label'])}
                      disabled={(submitting) ? true : false}
                      component={SelectAdapterNonClearable}
                      placeholder={upperFirst(intlMessages['settings.details.personalProfile.form.gender.label'])}
                    />
                   <StyledField name="phonenumber" parse={v => v}>
                       {({ input, meta}) => (
                         <StyledInputLayout>
                           <StyledLabel className="Input__Label">{upperFirst(intlMessages['settings.details.personalProfile.form.phonenumber.label'])}</StyledLabel>
                           <PhoneInput
                             {...input}
                             country={currentCountry}
                             addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                             placeholder={upperFirst(intlMessages['settings.details.personalProfile.form.phonenumber.label'])} />
                           <ErrorLabel
                             addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                             >
                               {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && upperFirst(intlMessages['settings.details.personalProfile.form.phonenumber.error']) || meta.submitError}
                           </ErrorLabel>
                         </StyledInputLayout>
                       )}
                     </StyledField>
                     */}
                      <Flex position={'relative'}>
                        <Button 
                            variant="solid"
                            position={'relative'}
                            type={"submit"}
                            disabled={submitting || pristine} 
                          >
                            {toUpper(intlMessages['settings.details.personalProfile.form.submit'])}
                        </Button>
                      </Flex>
             </StyledForm>
           )}
           />
      </StyledDetailPersonalProfile>
    )


  } else {
    return (<></>)
  }

}

const DetailsMyDentist = () => {
  const dispatch = useDispatch();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const intlMessages = useSelector(getIntlMessages);
  const favoriteDentists = useSelector(getFavoriteDentist);

  useEffect(()=>{
    // fetch favorite dentist detail
    dispatch(actions.fetchAppSettings("favoriteDentist"))
  }, [])

  const removeFavorite = (id) => {
    dispatch()
  }

  if (favoriteDentists.length == 0){
    return (
      <Box>
          <Text as={"h2"}>{intlMessages['settings.details.favoriteDentist.title']}</Text>
          <Flex direction="column" py={["18px"]}>
            {intlMessages['settings.details.favoriteDentist.empty']}
          </Flex>
      </Box>
    )
  }

  let favoriteDentist = favoriteDentists[0];

  const dentistCategories = (favoriteDentist?.dentist?.categories || "").split(",")

  const menuItemProps = {
    fontSize: "16px",
    color: "#12171A",
    px: "20px",
    py: "10px"
}

  return (
    <Box position={'relative'}>
      <RemoveAssignedDentistModal isOpen={isOpen} onClose={onClose} favoriteId={favoriteDentists[0]?.id} />
      <Text as={"h2"} mr={'20px'}>{intlMessages['settings.details.favoriteDentist.title']}</Text>
      <Flex 
        direction="column" 
        py={["18px"]} 
        borderBottom="1px solid #D8D8D8"
        pr="20px"
        mr={"20px"}
      >
        <Grid 
          templateColumns="2fr 10fr 2fr" gridRows="1fr"
          position={'relative'}
        >
        <Flex position={'absolute'} h={'100%'} align={'center'} top={0} right={0} >
        <ChakraMenu placement={"bottom-end"}  isLazy>
            <MenuButton 
                as={IconButton}
                aria-label='Options'
                icon={<VerticalDotsIcon w={'15px'} maxH={'16px'} />}
                variant='unstyled'
                minHeight={'unset'}
                minWidth={'unset'}
                px={"4px"}
                py={0}
            />
            <ChakraMenuList 
              py={"10px"} borderRadius={"10px"} 
              boxShadow={'0px 0px 10px #0000001a;'}
            >
                <ChakraMenuItem {...menuItemProps} onClick={onOpen}>
                    {intlMessages['settings.details.favoriteDentist.menu.remove.label']}
                    {/* TODOintlMessages settings.details.favoriteDentist.menu.remove' */}
                </ChakraMenuItem>
            </ChakraMenuList>
        </ChakraMenu>
        </Flex>
            <Flex align="center" justify="center">
              {/* dentist avatar */}
              <Avatar w="64px" h="64px" bg="#405159" color={"white"} name={favoriteDentist?.dentist.name} src={favoriteDentist?.dentist?.avatar} />
            </Flex>
            <Flex direction="column">
              {/* dentist details */}
              <Box>
                <Text fontSize="20px" color="#111619">{`Dr. ${favoriteDentist?.dentist?.name}`}</Text>
              </Box>
              <Box>
                <Text fontSize="14px" color="#405159">{favoriteDentist?.dentist?.clinic?.name}</Text>
                <Text fontSize="14px" color="#405159">{favoriteDentist?.dentist?.clinic?.address}</Text>
              </Box>
            </Flex>
        </Grid>
        <Flex
          ml="16px"
        >
          {
            (dentistCategories || []).map((tag) => (
              <Flex
                key={`fave-dentist-tag-${tag}`}
                align="center"
                justify="center"
                marginTop="8px"
                marginRight="5px"
                fontSize="12px"
                borderRadius="100px"
                w="fit-content"
                padding="4px 10px"
                border="1px solid #6B8A99"
                textTransform="uppercase"
                color="#6b8a99"
              >{tag}</Flex>
            ))
          }
        </Flex>
      </Flex>
    </Box>
  )
}

const MenuDetails = () => {
  const params = useParams();
  const location = useLocation();

  useEffect(()=>{
    if (params.settingsId){

    } else {
      // default profile
    }
  }, [params])

  if (params.settingsId == "payments"){
    return(
      <StyledDetailColumn>
        <DetailsPayments />
      </StyledDetailColumn>
    )
  } else if (params.settingsId == "notifications"){
    return(
      <StyledDetailColumn>
        <DetailsNotifications />
      </StyledDetailColumn>
    )
  } else if (params.settingsId == "authentication"){
    return(
      <StyledDetailColumn>
        <DetailsAuthentication />
      </StyledDetailColumn>
    )
  } else if (params.settingsId == "recordshistory"){
    return(
      <StyledDetailColumn>
        <RecordsHistory />
      </StyledDetailColumn>
    )
  } else if (params.settingsId == "downloadreports"){
    return(
      <StyledDetailColumn>
        <DownloadReport />
      </StyledDetailColumn>
    )
  } else if (params.settingsId == "profileslist" || params.settingsId == "profiles"){
    return(
      <StyledDetailColumn>
        <DetailsProfilesList /> 
      </StyledDetailColumn>
    )
  } else if (params.settingsId == "favoriteDentist"){
    return(
      <StyledDetailColumn>
        <DetailsMyDentist />
      </StyledDetailColumn>
    )
  } else {
    return(
      <StyledDetailColumn>
        <DetailsPersonalProfile />
      </StyledDetailColumn>
    )
  }
}

const MenuSubHeader = () => {
  return (
    <StyledMenuSubHeader>
    </StyledMenuSubHeader>
  )
}

const MenuItem = ({selected, item, label, url, external}) => {
  if (external){
    return(
      <StyledMenuItem selected={false}>
        <a target="_blank" rel="noreferrer" href={url}>
          {label}
        </a>
        <GrNewWindow />
      </StyledMenuItem>
    )
  } else {
    return(
      <StyledMenuItem selected={false}>
        <Link to={url}>
          {label}
        </Link>
      </StyledMenuItem>
    )
  }
}

const ContactMenuItem =  ({selected, item, label, url, external}) => {
    const userCountry = useSelector(getCountry);
    if (userCountry == "TH"){
      url = "mailto:support-th@plover.life"
    } else if (userCountry == "JP"){
      url = "mailto:support-jp@plover.life"
    }
    url = "mailto:support@plover.life"
    return(
      <StyledMenuItem selected={false}>
        <a target="_blank" rel="noreferrer" href={url}>
          {label}
        </a>
        <BiSupport />
      </StyledMenuItem>
    )
}

const LanguageSwitchItem = ({item, label}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(modalActions.openModal('switchLanguage'))
  }

  return(
    <StyledMenuItem selected={false}>
      <div className={'clickItem'} onClick={handleClick} >
        {label}
      </div>
      <IoLanguageSharp />
    </StyledMenuItem>
  )
}

const ClickMenuItem = ({item, label, onClick}) => {
  return(
    <StyledMenuItem selected={false}>
      <div className={'clickItem'} onClick={onClick}>
        {label}
      </div>
      <BiSupport />
    </StyledMenuItem>
  )
}

const getSettingsList = (isDentalType, isOrphanage) => {
  if (isDentalType){
    return DENTAL_USER_SETTINGS_LIST
  } else if (isOrphanage){
    return ORPH_USER_SETTINGS_LIST
  } else {
    return SETTINGS_LIST
  }
}

const MenuList = () => {
  const params = useParams();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const userCountry = useSelector(getCountry);
  const portalUrl = useSelector(getPaymentPortalUrl)

  const isDentalUser = storage.getIsDentalType()
  const isOrphanageUser = storage.getIsOrphanage()


  const handleLogout = () => {
    setIsLoggingOut(true);
    authenticationServices.logout().then(
      resp => {
        localStorage.removeItem('user');
        history.push("/login")
      }
    );
  }

  const clickContact = () => {
    if (window.ChannelIO){
      window.ChannelIO('openChat');
    }
  }
  

  return(
    <StyledMenuList>
      {getSettingsList(isDentalUser, isOrphanageUser).map((elem) =>
        <div>
          <StyledMenuSubHeader key={elem.subHeaderKey}>
            {intlMessages[`settings.menu.menulist.subHeader.${elem.subHeaderKey}.title`]}
          </StyledMenuSubHeader>
          {
            elem.items.map((item) => {
              if (item.key == "payments"){
                return(
                  <MenuItem
                    selected={params.settingsId == item.key}
                    key={item.key}
                    label={intlMessages[`settings.menu.menulist.menuItem.${item.key}.title`]}
                    url={portalUrl ? portalUrl : URLS[item.key].url}
                    external={portalUrl ? true : false}
                  />
                )
              } else if (item.key == "contactUs"){
                /*return(
                  <ClickMenuItem
                    key={item.key}
                    label={intlMessages[`settings.menu.menulist.menuItem.${item.key}.title`]}
                    onClick={clickContact}
                  />
                )*/
                return (
                  <ContactMenuItem 
                    selected={params.settingsId == item.key}
                    key={item.key}
                    label={intlMessages[`settings.menu.menulist.menuItem.${item.key}.title`]}
                    url={URLS[item.key].url}
                    external={item.external}
                  />
                )
              } else if (item.key == "switchLanguage"){
                return(
                  <LanguageSwitchItem
                    key={item.key}
                    label={intlMessages[`settings.menu.menulist.menuItem.${item.key}.title`]}

                  />
                )
              } else if (item.key == 'helpCenter') {
                let url = URLS['helpCenter_us'].url;
                if (toUpper(userCountry) == "TH"){
                  url = URLS['helpCenter_th'].url;
                } else if (toUpper(userCountry) == "JP"){
                  url = URLS['helpCenter_jp'].url;
                }
                return(
                  <MenuItem
                    selected={params.settingsId == item.key}
                    key={item.key}
                    label={intlMessages[`settings.menu.menulist.menuItem.${item.key}.title`]}
                    url={url}
                    external={true}
                  />)
              }else if (item.key == 'privacy') {
                let url = URLS['privacy_us'].url;
                if (toUpper(userCountry) == "TH"){
                  url = URLS['privacy_th'].url;
                } else if (toUpper(userCountry) == "JP"){
                  url = URLS['privacy_jp'].url;
                }
                return(
                  <MenuItem
                    selected={params.settingsId == item.key}
                    key={item.key}
                    label={intlMessages[`settings.menu.menulist.menuItem.${item.key}.title`]}
                    url={url}
                    external={true}
                  />)
              } else if ( item.key == 'terms') {
                let url = URLS['terms_us'].url;
                if (toUpper(userCountry) == "TH"){
                  url = URLS['terms_th'].url;
                } else if (toUpper(userCountry) == "JP"){
                  url = URLS['terms_jp'].url;
                }
                return(
                  <MenuItem
                    selected={params.settingsId == item.key}
                    key={item.key}
                    label={intlMessages[`settings.menu.menulist.menuItem.${item.key}.title`]}
                    url={url}
                    external={true}
                  />)
              } else {
                return(
                  <MenuItem
                    selected={params.settingsId == item.key}
                    key={item.key}
                    label={intlMessages[`settings.menu.menulist.menuItem.${item.key}.title`]}
                    url={URLS[item.key].url}
                    external={item.external}
                  />)
              }
            })
          }
        </div>
      )}
      <StyledLogoutButton>
        <Button 
          variant={'solid'}
          w={'250px'}
          isLoading={isLoggingOut}
          onClick={handleLogout}
          mt={'20px'}
        >
          {intlMessages['settings.form.logout']}
        </Button>
      </StyledLogoutButton>
    </StyledMenuList>
  )
}

const MenuHeader = () => {
  const intlMessages = useSelector(getIntlMessages)
  const profile = useSelector(getProfile);
  const profileIsLoaded = useSelector(getIsProfileLoaded);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const email = useSelector(getEmail);
  const avatar = useSelector(getAvatar);
  let [ name, setName ] = useState("")
  let [ initials, setInitials ] = useState("")

  useEffect(()=>{
    //setName((firstName || lastName) ? formatName(intlMessages['format.fullName'], profile.first_name, profile?.last_name) : "Beforedent User");
    setName((firstName) ?  profile.first_name : "Beforedent User");
    setInitials( (name) ? name.charAt(0) : "B");
  }, [firstName] )

  if (profileIsLoaded) {

    return(
      <StyledMenuHeader>
        <div>
          <div className="image">
            <ProfileAvatar
              src={avatar}
              initials={initials}
            />
            <EditAvatar />
          </div>
            <Flex align={'center'} overflowX={'hidden'}>
              <div className="details">
                <div className="name">
                  <Text noOfLines={2}>{name}</Text>
                </div>
                <div className="email">
                  <Text noOfLines={1}>{email}</Text>
                </div>
              </div>
            </Flex>
        </div>
      </StyledMenuHeader>
    )
  } else {
    return (
      <StyledMenuHeader>
        <h2>{intlMessages['settings.header.details.title']}</h2>
        <div className="loading">
          <LoadingCircle />
        </div>
      </StyledMenuHeader>
    )
  }
}

export const Settings = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const intlMessages = useSelector(getIntlMessages);
  const channelIOLoaded = useSelector(getChannelIOLoaded);

  useEffect(()=>{
    dispatch(authenticationActions.getProfile());
    dispatch(authenticationActions.getSettingsPayment());
    return () => {
      //window.ChannelIO('showChannelButton')
      window.ChannelIO('hideChannelButton');
    };
  }, [])

  useEffect(()=>{
    if (window.ChannelIO){
      window.ChannelIO('hideChannelButton');
    }
  }, [channelIOLoaded])

  return (
    <SectionPage>
      <SectionTitle>{intlMessages['settings.title']}</SectionTitle>
      <StyledSectionWrapper>
        <section>
        <StyledMenuColumn>
          <MenuHeader />
          <MenuList />
        </StyledMenuColumn>
        <MenuDetails />
        </section>
      </StyledSectionWrapper>

    </SectionPage>
  )
}
