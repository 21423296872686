const PREFIX = 'APPSETTINGS_';
export const constants = {
  FETCHSETTING_REQUEST: `${PREFIX}FETCHSETTING_REQUEST`,
  FETCHSETTING_SUCCESS: `${PREFIX}FETCHSETTING_SUCCESS`,
  FETCHSETTING_FAILURE: `${PREFIX}FETCHSETTING_FAILURE`,
  FETCHFAVORITEDENTIST_REQUEST: `${PREFIX}FETCHFAVORITEDENTIST_REQUEST`,
  FETCHFAVORITEDENTIST_SUCCESS: `${PREFIX}FETCHFAVORITEDENTIST_SUCCESS`,
  FETCHFAVORITEDENTIST_FAILURE: `${PREFIX}FETCHFAVORITEDENTIST_FAILURE`,
  REMOVE_FAVORITE_DENTIST: `${PREFIX}REMOVE_FAVORITE_DENTIST`,
  UPDATE: `${PREFIX}UPDATE`,
}
