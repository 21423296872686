import React from 'react';

import { ComprehensiveScanTidbits, QuickStartTidbits } from '../PloverScan/InformationTidbits';

export const HowToContent = ({content, closeModal}) => {
  if (content == 'guidedScan'){
      return (
        <ComprehensiveScanTidbits close={closeModal} />
      )
  } else if (content == 'quickstartPlover') {
      return (
        <QuickStartTidbits close={closeModal} />
      )
  } else {
    return (
      <div>{() => closeModal()}</div>
    )
  }
}
