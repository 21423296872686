import { useQuery } from 'react-query';
import { queryConfig } from '../../_helpers/react-query';

export const QUERY_KEY_NAME = 'BulkReports';

import { services } from '../_services'

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
        refetchOnMount: false
    }
}

export const queryRequest = async (queryParams) => {
    return services.fetchBulkReports(queryParams);
}

export const useBulkReports = ({ date_filter, start_date, end_date, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        queryKey: [QUERY_KEY_NAME, {date_filter, start_date, end_date}],
        queryFn: () => queryRequest({date_filter, start_date, end_date}),
      });
}