import React, { useEffect, useRef } from 'react';
import { 
  Box, 
  Text
} from '@chakra-ui/react';

import { ReadOnlyEditorDashboard } from '../Editor/ReadOnlyEditor';
import { useReportStore } from '../state/report';
import { FormattedMessage } from 'react-intl';
import { useDashboardStore } from '../../Dashboard/state/latestReport';

export const ReportData = ({data, height=400, pl=['20px'], editorStyle, editorBoxStyle}) => {
    const { category } = useReportStore();
    const { editorInstance, setEditorInstance : setEditorInstanceState } = useDashboardStore();
  

    const setEditorInstance = (instance) => {
        setEditorInstanceState(instance)
    }
  
  
    if (!data?.blocks || data?.blocks?.length == 0){
      return (
        <Box 
          p={0} 
          mt={['5px']}
          bg={['#fff']}
          borderRadius={['15px']}
        >
            <Text color={'#6B8A99'}>
                <FormattedMessage 
                    id={'reportDetail.history.noReport'}
                    defaultMessage={'No data'}
                />
            </Text>
        </Box>
      )
    } else {
      return (
        <Box pl={pl}>
            <ReadOnlyEditorDashboard
                editorInstance={editorInstance} 
                setEditorInstance={setEditorInstance} 
                data={data}
                isTitleOn={false}
                editorBoxStyle={editorBoxStyle}
                editorStyle={editorStyle}
            />
        </Box>
      )
    }
  
  }