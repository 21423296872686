import { RECORD_TYPES } from '../_config';
import { constants } from './_constants';

const partialData = {
    "id": 55,
    "created_at": "2021-03-29T08:12:01.537157Z",
    "regions": [
        {
            "region": "t26o",
            "status": false,
            "upload": "",
            "upload_head": "",
            "updated_at": "2021-03-29T08:12:01.550521Z",
            "id": 133
        },
        {
            "region": "t46o",
            "status": false,
            "upload": "",
            "upload_head": "",
            "updated_at": "2021-03-29T08:12:01.556446Z",
            "id": 134
        },
        {
            "region": "t26ld",
            "status": false,
            "upload": "",
            "upload_head": "",
            "updated_at": "2021-03-29T08:12:01.563305Z",
            "id": 135
        },
        {
            "region": "t31l",
            "status": false,
            "upload": "",
            "upload_head": "",
            "updated_at": "2021-03-29T08:12:01.568818Z",
            "id": 136
        },
        {
            "region": "t27lm",
            "status": false,
            "upload": "",
            "upload_head": "",
            "updated_at": "2021-03-29T08:12:01.580779Z",
            "id": 137
        }
    ],
    "parent": 4,
    "type": "PARTIALSC"
}

const byId = {
  "t26o" : {
      "region": "t26o",
      "status": false,
      "upload": "",
      "upload_head": "",
      "updated_at": "2021-03-29T08:12:01.550521Z",
      "id": 133
  },
  "t46o": {
      "region": "t46o",
      "status": false,
      "upload": "",
      "upload_head": "",
      "updated_at": "2021-03-29T08:12:01.556446Z",
      "id": 134
  },
  "t26ld": {
      "region": "t26ld",
      "status": false,
      "upload": "",
      "upload_head": "",
      "updated_at": "2021-03-29T08:12:01.563305Z",
      "id": 135
  },
  "t31l": {
      "region": "t31l",
      "status": false,
      "upload": "",
      "upload_head": "",
      "updated_at": "2021-03-29T08:12:01.568818Z",
      "id": 136
  },
  "t27lm": {
      "region": "t27lm",
      "status": false,
      "upload": "",
      "upload_head": "",
      "updated_at": "2021-03-29T08:12:01.580779Z",
      "id": 137
  }
}

// scanType: 'CHECKUP', 'SCAN'
const initState = {
  partialScanId: 55,
  partialScanMessageTimeToken: null,
  partialScan: null,
  byId: null,
  selectedRegion: null,
  loading: false,
  error: false,
  reviewOpen: false,

}

export function partialScans(state=initState, action){
  switch (action.type){
    case constants.FETCHPARTIALSCAN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case constants.FETCHPARTIALSCAN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.data,
      }
    case constants.FETCHPARTIALSCAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.UPLOAD_SETPROGRESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.region]: Object.assign({}, state.byId[action.data.region], {progress: action.data.progress})
        }
      }
    case constants.UPLOAD_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.region]: Object.assign({}, state.byId[action.data.region], {progress: 100, uploaded: true, ...action.data.uploadData})
        }
      }
    case constants.UPLOAD_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.region]: Object.assign({}, state.byId[action.data.region], {progress: 0, uploadError: true})
        }
      }
    case constants.REMOVE_PARTIAL_SCAN:
      const { data, objectUrl, mimeType, ...newData} = state.byId[action.data.region]
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.region]: Object.assign({}, {}, newData)
        },
        reviewOpen: false
      }
    case constants.UPDATE_PARTIAL_SCAN:
      return {
        ...state,
        byId:{
          ...state.byId,
          [action.data.region]: Object.assign({}, state.byId[action.data.region], action.data)
        },
        reviewOpen: true
      }
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state
  }
}
