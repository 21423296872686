import React, {useState, useEffect, useRef} from 'react';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';

import { authHeader, handleResponse } from '../_helpers';

import styled, {css} from 'styled-components'
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { LoadingEllipses } from '../_components/LoadingEllipses';

import { toUpper, upperFirst, capitalize } from 'lodash';

import { API_URLS, URLS } from '../_config';

import { getIntlMessages } from '../Authentication/_selectors';
import { services } from '../Authentication/_services';
// components are from ai project
import {
  ErrorLabel,
  ErrorSpan
}
from '../_styles/forms'

import {
StyledCompanyLogo,
StyledActivationMessage,
StyledActivationWrapper,
StyledResetWrapper,
StyledInputLayout,
StyledInputWithOverlay,
StyledInput,
StyledLoginButton,
errorCss

} from '../Registration/styles';

import {
  Wrapper,
  StyledLoadingButtonLoading
} from '../LoginPage/styles'

const required = value => (value ? undefined : 'required')
const mustBeNumber = value => (isNaN(value) ? 'must_be_number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const Page = styled.div`
  background-color: #F7F9FA;
  height:100vh;
  display:flex;
`;

const formName = 'createPasswordForm';

export const PasswordCreatePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [initValues, setInitValues] = useState({})
  const formRef = useRef(null);
  const emailRef = useRef();
  const intlMessages = useSelector(getIntlMessages);

  const [ sent, setSent ] = useState(false);
  const [ isLoading, setIsloading ] = useState(false);

  let params = new URLSearchParams(location.search);

  useEffect(()=>{

    let uid = params.get('uid');
    let code = params.get('code');
    if (uid && code){
      setInitValues({uid, code})
    }
  }, [location.search])

  // handles form submission
  const handleSubmit = async (submitValues) => {
    console.log(submitValues)

    let { ...values } = submitValues;
    console.log("submit forms", values)
    if (values.new_password1 !== values.new_password2){
      return {
        new_password2: 'passwordMatch'
      }
    }
    setIsloading(true)
    try{
      const user = await services.createNewPassword(values);
      console.log("created new passord")
      //this.props.history.push(URLS.login.url);
    } catch (err){
      var errType = 'general.error';
      if (err.code !== undefined || err.uid !== undefined){
        errType = 'token';
      }
      return {
        ...err,
        [FORM_ERROR] : errType
      }
    } finally{
      setIsloading(false)
    }
  }

  const renderForm = () => {
    return (
      <StyledResetWrapper styles={{overflowY: 'auto'}}>
        <Form
           initialValues={initValues}
           onSubmit={handleSubmit}
           render = {({
             submitError,
             formError,
             handleSubmit,
             mutators,
             form,
             reset,
             submitting,
             pristine,
             validating,
             values
           }) => {
             formRef.current = form;
             return (
               <form
                 id={formName}
                 onSubmit={handleSubmit}
                >
                  <StyledCompanyLogo>{intlMessages['company.name']}</StyledCompanyLogo>
                  <StyledActivationMessage>{upperFirst(intlMessages['createPasswordForm.form.header'])}</StyledActivationMessage>
                  <Field name="uid" parse={v => v} validate={required}>
                      {({ input, meta}) => {
                      return (
                        <StyledInputLayout>
                          <StyledInput
                            {...input}
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            type="hidden"
                            />
                        </StyledInputLayout>
                      )}
                    }
                    </Field>
                    <Field name="code" parse={v => v} validate={required}>
                      {({ input, meta}) => (
                        <StyledInputLayout>
                          <StyledInput
                            {...input}
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            type="hidden"
                            />
                        </StyledInputLayout>
                      )}
                    </Field>
                    <Field name="new_password1" parse={v => v} validate={required}>
                      {({ input, meta}) => (
                        <StyledInputLayout>
                          <StyledInput
                            {...input}
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            type="text"
                            placeholder={upperFirst(intlMessages['createPasswordForm.form.password.label'])} />
                          <ErrorLabel
                            setLineHeight={5}
                            isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                            >
                              {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`resetPasswordForm.form.password.error`] || meta.submitError}
                          </ErrorLabel>
                        </StyledInputLayout>
                      )}
                    </Field>
                    <Field name="new_password2" parse={v => v} validate={required}>
                      {({ input, meta}) => (
                        <StyledInputLayout>
                          <StyledInput
                            {...input}
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            type="text"
                            placeholder={upperFirst(intlMessages['createPasswordForm.form.password2.label'])} />
                          <ErrorLabel
                            setLineHeight={5}
                            isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                            >
                              {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`resetPasswordForm.form.password2.error`] || meta.submitError}
                          </ErrorLabel>
                        </StyledInputLayout>
                      )}
                    </Field>
                    <StyledLoginButton
                      disabled={
                        submitting ||
                        !(
                          values.new_password1 &&
                          values.new_password2
                         )
                      }
                      type="submit">
                      { (submitting)
                          ? <StyledLoadingButtonLoading><LoadingEllipses /></StyledLoadingButtonLoading>
                          : toUpper(intlMessages['createPasswordForm.form.submit'])
                      }
                    </StyledLoginButton>
              </form>
             )
           }
         } />
      </StyledResetWrapper>
    )
  }

  return (
    <Wrapper>
      {renderForm()}
    </Wrapper>
  )
}
