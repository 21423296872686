import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const ForwardChevronIcon = ({width=7, height=11, color="#7A8F99", ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 7 11" color={color}  {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.826667 10.806L6.1875 5.44518L0.742667 0.000348091L8.34465e-05 0.743515L4.70233 5.44518L0.0840836 10.0634L0.826667 10.806Z" fill="currentColor"/>
    </Icon>
  )
}
