import React, { useState } from 'react';
import { APPLE_WEBSERVICEURL, APPLE_WEBSITEPUSHID } from '../_config';
import { useSelector } from 'react-redux';

// selectors
import { getIntlMessages } from '../Authentication/_selectors';

// styles
import { StyledBrowserNotifications } from './styles';
import { StyledButton } from '../_styles/forms';

export const APNNotifications = ({closeModal, checkAPNPermission}) => {
  const modalName = 'apnNotifications'; // move to a config file
  const intlMessages = useSelector(getIntlMessages);
  const [ checked, setChecked ] = useState(false);

  const handleClose = () => {
    if (checked){
      localStorage.setItem(`doNotShow__${modalName}`, Date.now().toString());
    }
    closeModal()
  }

  const onChange = (e) => {
    setChecked(e.target.checked);
  }

  return (
    <StyledBrowserNotifications>
      <div className={'title'}>{intlMessages['browsernotifications.apn.modal.title']}</div>
      <div className={'examples'}>
        {/* insert notificatoin gif, images showing how to enable here */}
      </div>
      <div className={'description'}>
        <p>{intlMessages['browsernotifications.apn.modal.description']}</p>
      </div>
      <div className={'action'}>
        <StyledButton onClick={handleClose}>
          {intlMessages['browsernotifications.apn.modal.action.positive']}
        </StyledButton>
      </div>
      <div className={'notShowAgain'}>
        <input
          name={`doNotShow__${modalName}`}
          component="input"
          type="checkbox"
          value="yes"
          id="yes"
          onChange={onChange}
          />
        <label>{intlMessages['browsernotifications.apn.modal.action.donotshow']}</label>
      </div>
    </StyledBrowserNotifications>
  )
}
