import { TOOTH_NUMBERING_SYSTEM, DEVICE_NAME, DEVICE_NAME_DESKTOP, REGEX_ONLY_TOOTH, REGEX_ONLY_REGION, RECORD_TYPES } from '../_config';
import React, {useEffect, useState, useRef} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import ReactPlayer from 'react-player';

import { toUpper } from 'lodash';


// components
import { PartialScanTeeth } from '../_components/PartialScanTeeth';
import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { PloverCamera, NoCamera, UploadProgress } from '../PloverCamera';
import { PloverWaitingConnection } from '../PloverScan/PloverWaitingConnection';
import {
  FaTimes,
  FaRegSun,
  FaStopwatch,
  FaCommentAlt,
  FaReadme
} from 'react-icons/fa';

// redux state
import { actions } from './_actions';
import { getIsDetectionUploaded, getDetectionScan, getUploadProgress } from './_selectors';
import { constants } from './_constants';

// actions
import { actions as cameraActions } from '../PloverCamera/_actions';
import { actions as mediaActions } from '../MediaUploader/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { actions as scanActions } from '../PloverScan/_actions';

// services
import { services as recordServices } from '../Records/_services';

// selectors
import { getIntlMessages, getSelectedProfile } from '../Authentication/_selectors';
import { getPloverDeviceId, getIsAwaitingPlover, getCameraIsError } from '../PloverCamera/_selectors';
import { getCurrentMediaList } from '../MediaUploader/_selectors';

// styles
import {
  FlexBox,
  NextButton,
  ScanCameraSection,
  Header,
  StyledVideoFrame,
  StyledRecordedStreamReview,
  StyledRecordedStreamReviewOverlay
} from '../PloverScan/styles';

import {
  RemoveButton
} from '../MediaUploader/styles';

import {
  StyledDetectionScan,
  StyledDetectionInformationColumn,
  StyledDetectionScanColumn,
  StyledDetectionScanButtons
} from './styles';


let DEFAULT_NUMBERING = "international";

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


const RecordedStreamReview = ({item,  handleClose, handleRedo}) => {
  const intlMessages = useSelector(getIntlMessages);
  if (item.mimeType.startsWith("video")){
    return (
      <StyledRecordedStreamReview>
        <ReactPlayer
            url={item.getUrl()}
            muted={true}
            controls={true}
            width={'640px'}
            height={'480px'}
          />
            <StyledRecordedStreamReviewOverlay>
              <div className={'reviewTitle'}>{'Video Review'}</div>
              {/*<div className={'closeBtn'} onClick={handleClose}>{toUpper(intlMessages['checkup.fullscan.review.redo'])}</div>*/}
            </StyledRecordedStreamReviewOverlay>
            <UploadProgress />
      </StyledRecordedStreamReview>
    )
  } else if (item.mimeType.startsWith("image")){
    return (
      <StyledRecordedStreamReview>
        <img
            src={item.objectUrl || item.upload}
            width={'640px'}
            height={'480px'}
          />
            <StyledRecordedStreamReviewOverlay>
              <div className={'reviewTitle'}>{'Photo Review'}</div>
              {/*<div className={'closeBtn'} onClick={handleClose}>{toUpper(intlMessages['checkup.fullscan.review.redo'])}</div>*/}
            </StyledRecordedStreamReviewOverlay>
            <UploadProgress />
      </StyledRecordedStreamReview>
    )
  } else {
    return null;
  }
}

export const DetectionScanStudio = ({onScanFinished, type}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const currentItem = useSelector(getDetectionScan);
  const selectedProfile = useSelector(getSelectedProfile);
  const location = useLocation();
  const history = useHistory();
  const isDetectionUploaded = useSelector(getIsDetectionUploaded);
  const progress = useSelector(getUploadProgress);
  let [ uploadState, setUploadState ] = useState(-1);
  const currentDeviceId = useSelector(getPloverDeviceId);
  const recordId = useRef();

  let options = {mimeType: 'video/webm', videoBitsPerSecond: 5000000}; //150,000,000 18.75Mbps, 50,000,000 6.25Mbps

  useEffect(() =>{
    // unmount
    dispatch(scanActions.setScanType(RECORD_TYPES.DETECTIONSC));
    return () => {
      dispatch(actions.removeDetection());
    };
  }, [])

  useEffect(() => {
    console.log("ISDETECTIONUPLOADED "+ isDetectionUploaded);
    if (isDetectionUploaded){
      // clear uploads
      // close modal and open message modal
      //

      let newParams = new URLSearchParams();
      newParams.delete("detectionbeta")
      history.push(`${location.pathname}?${newParams}`)
      dispatch(modalActions.closeModal('loading'))
      dispatch(actions.removeDetection());
      dispatch(modalActions.openMessageModal('detectionSent'));
    }
  }, [isDetectionUploaded])

  const closeReview = () => {
    dispatch(actions.removeDetection());
    dispatch(mediaActions.resetMedia());
  }

  const startUpload = async () => {
    setUploadState(0);

    dispatch(modalActions.openLoadingModal('detectionSent'))
    try{
      let extraData = null;
      const recordResp = await recordServices.createRecordId(RECORD_TYPES.SELF, selectedProfile.id, {"is_detect": true});
      // recordResp.id
      recordId.current = recordResp.id;
    } catch(err) {
      console.log("UPLOAD ERROR: ", err)
    }
    if (currentItem){
      dispatch(actions.uploadMedia(currentItem, recordId.current))
    } else {
      alert("error with upload");
    }
  }

  const closeModal = () => {
    dispatch(modalActions.closeModal('detectionScan'))
  }


  if(MediaRecorder.isTypeSupported('video/mp4')){
    options = Object.assign({}, options, {mimeType: 'video/mp4'} )
  } else if (MediaRecorder.isTypeSupported("video/webm\;codecs=h264")){
    options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=h264'} )
  } else if(MediaRecorder.isTypeSupported('video/webm\;codecs=vp9')){
    options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=vp9'} )
  } else if(MediaRecorder.isTypeSupported('video/webm\;codecs=vp8')){
    options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=vp8'} )
  } else if(MediaRecorder.isTypeSupported('video/webm')){
    options = Object.assign({}, options, {mimeType: 'video/webm'} )
  } else{
    options = Object.assign({}, options, {mimeType: null} );
    // probably throw an error here. or check options null in video
  }

  return (
      <StyledDetectionScan>
        <ScanCameraSection>
          <StyledVideoFrame>
            <Header>{'DETECTION'}</Header>
            {
              currentItem &&
              <RecordedStreamReview
                handleClose={closeReview}
                item={currentItem}
              />
            }
            { currentDeviceId
              ? <PloverCamera
                  mediaRecorderOptions={options}
                  deviceId={currentDeviceId}
                  photoMode={'VIDEO'}
                />
              : <NoCamera />
            }
          </StyledVideoFrame>
          <StyledDetectionScanColumn>
            <Header>{'REQUESTION DETECTION'}</Header>
            <StyledDetectionInformationColumn>
              <div className={'infoColumn'}>
                <div className={'ledModeInfo'}><div><FaRegSun /></div><div>{'Make sure Plover is on White LED mode.'}</div></div>
                <div className={'videoLengthInfo'}><div><FaStopwatch /></div><div>{'You can request detection for videos up to 15 seconds.'}</div></div>
                <div className={'notificationInfo'}><div><FaCommentAlt /></div><div>{'After uploading, we will send you a notification once detection has been completed.'}</div></div>
                <div className={'disclaimerInfo'}><div><FaReadme /></div><div>{'Your upload will be processed through our deep learning model. No personal information will be processed.'}</div></div>
              </div>
            </StyledDetectionInformationColumn>
          </StyledDetectionScanColumn>
        </ScanCameraSection>
        <StyledDetectionScanButtons>
          <FlexBox>
            <NextButton onClick={startUpload} disabled={!currentItem || progress > 0 } >{toUpper('Request')}</NextButton>
          </FlexBox>
        </StyledDetectionScanButtons>
      </StyledDetectionScan>
  )
}

export const DetectionScan = ({closeModal, onScanFinished}) => {
  const dispatch = useDispatch();
  const [ page, setPage ] = useState(0);
  const [checkMediaDevices, setCheckMediaDevices ] = useState(false);
  const deviceError = useSelector(getCameraIsError);
  const currentDeviceId = useSelector(getPloverDeviceId);
  const isAwaitingPlover = useSelector(getIsAwaitingPlover);
  const currentDeviceName = useRef(null);

  useEffect(() =>{
    return () => {};
  }, [])

  const onDevicesChanged = (event) => {
    setCheckMediaDevices(!checkMediaDevices);
  }

  const updateDeviceId = (deviceId) => {
    dispatch(cameraActions.setPloverDeviceId(deviceId))
  }

  const connectDevice = (deviceId) => {

  }

  const disconnectDevice = (deviceId) => {
    dispatch(cameraActions.setPloverDeviceId(deviceId))
  }

  const handleSuccess = () => {
    // TODO continue on?
  }

  const handleError = (err) => {
    //TODO dialog explaining how permissions work
    console.log("ERROR:", err);
    alert("need to give permission for camera: ");
  }

  useEffect(()=>{
    async function connectPlover(devId){
      // NOTE need to wait for plover to start up, or there is error redaing the stream
      dispatch(cameraActions.updateState('awaitingDevice', true));
      await timeout(2000);
      dispatch(cameraActions.updateState('awaitingDevice', false));
      updateDeviceId(devId);
    }
    const videoConstraints = { video: true};
    if (navigator && navigator.mediaDevices){
      if (navigator.mediaDevices.getUserMedia){
        // handle permissions
        navigator.mediaDevices.getUserMedia(videoConstraints).then(
          ()=>{
          navigator.mediaDevices.ondevicechange = onDevicesChanged;
          let deviceId = null;
          navigator.mediaDevices
            .enumerateDevices()
            .then(r => {
              console.log("DEBUG DEVICES: ", r)
              for (var i =0;i < r.length; i++){
                if (r[i].label.startsWith(DEVICE_NAME)){
                  deviceId = r[i].deviceId;
                  currentDeviceName.current = r[i].label;
                }
              }
              if (currentDeviceId && !deviceId){ // disconnected plover
                updateDeviceId(null);
              } else if (!currentDeviceId && deviceId){ // connected plover
                connectPlover(deviceId)
              } else {
                // nothing to do with plover. other devices connected/disconnected
              }
            })

          }
        ).catch(
          err => handleError(err)
        )
      }
    }
  }, [checkMediaDevices])

  if (page == 0){
    return (
      <PloverWaitingConnection
        nextPage={()=> setPage(1)}
        currentDeviceId={currentDeviceId}
        deviceError={deviceError}
        closeModal={closeModal}
        isAwaitingPlover={isAwaitingPlover} />
    )
  } else {
    return(
      <DetectionScanStudio
        onScanFinished={onScanFinished}
        />
    )
  }
}
