import styled from 'styled-components';

export const StyledOmiseCard = styled.div`
  padding: 15px 45px;
  width: 100%;
  margin: 15px 30px;
  display:flex;
  justify-content:space-between;
  border: 1px solid #36454D;
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 14px;
  background-color:white;
  .cardIcon{
    img {
      width: 44px;
    }
  }
  .cardDetails{
    margin-left: 20px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:flex-end;
  }

  .cardNumber span, .cardExp span{
    font-family: Proxima Nova Bold;
  }

  &:first-child{
    margin-top: 0;
  }

  &:hover{
    cursor:pointer;
    opacity: 0.8;
  }
`;

export const StyledChangeCardLink = styled.div`
  span{
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height, or 146% */

    letter-spacing: 0.9px;

    color: #7A8F99;
  }

  &:hover{
    cursor:pointer;
  }
`;

export const StyledPriceDetails = styled.div`
  align-self: flex-start;
  h1 {
    font-size: 20px;
    color: #12171a;
  }
  h4 {
    margin-top:10px;
  }
  padding-bottom: 30px;
`;

export const StyledPayButton = styled.button`
  background: #273238;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  position:relative;
  border-radius: 5px;
  width: 200px;
  height: 48px;
  text-transform: uppercase;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.9px;
  margin-top: 20px;
  color: #FFFFFF;
  border:none;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  filter: drop-shadow(0px 2px 14px rgba(39, 50, 56, 0.5));



  &:hover{
       opacity:0.8;
       cursor:pointer;
     }

  &:disabled{
    background: #C4C4C4;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    filter: drop-shadow(0px 2px 14px rgba(39, 50, 56, 0.5));
    color: #273238;
    &:hover{
      cursor:default;
    }
  }
`;

export const StyledOmisePayment = styled.div`
  padding: 0 80px;
  padding-top:20px;
  flex-direction: column;
  align-items:center;
  justify-content: flex-start;
  margin: 0 10px;
  display: ${({isShowing}) => (isShowing) ? 'flex': 'none'};
  height: 320px;

  a,a:hover,a:visited,a:active,a:focus{
   color: inherit;
   text-decoration: none !important;
   outline: none !important;
  }
`;
