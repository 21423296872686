const storagePrefix = `plover_`;
const STORAGE_KEYS = {
    SCAN_RECORD: 'scan_record',
    SCAN_PROFILE: 'scan_profile',
    SCAN_DENTIST: 'scan_dentist',
    CHECKUP_REQUEST: 'checkup_request'
}
const storage = {
    set: (key, value) => {
      window.localStorage.setItem(`${storagePrefix}${key}`, JSON.stringify(value));
    },
    get: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${key}`));
      } catch (err) {
        return '';
      }
    },
    setRegister: (value) => {
      window.localStorage.setItem(`${storagePrefix}register`, JSON.stringify(value));
    },
    getRegister: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}register`));
      } catch (err) {
        return {};
      }
    },
    clearRegister: () => {
      window.localStorage.removeItem(`${storagePrefix}register`);
    },
    clearScanDetails: () => {
        window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`);
        window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`);
        window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`);
        window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`);
    },
    clear: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${key}`);
    },
    getCountry: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}country`));
      } catch (err) {
        return '';
      }
    }, 
    setCountry: (value) => {
      window.localStorage.setItem(`${storagePrefix}country`, JSON.stringify(value));
    },
    clearCountry: () => {
      window.localStorage.removeItem(`${storagePrefix}country`);
    },
    setChartFlipped: (value) => {
      window.localStorage.setItem(`${storagePrefix}chart_flipped`, JSON.stringify(value));
    },
    getChartFlipped: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}chart_flipped`));
      } catch (err) {
        return '';
      }
    },
    setLineLoginType: (loginType) => {
      window.localStorage.setItem(`${storagePrefix}linelogintype`, JSON.stringify(loginType));
    },
    clearLineLoginType: () => {
      window.localStorage.removeItem(`${storagePrefix}linelogintype`);
    },
    getLineLoginType: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}linelogintype`));
      } catch (err) {
        return '';
      }
    },
    setSocialAppId: (appId) => {
      window.localStorage.setItem(`${storagePrefix}socialappid`, JSON.stringify(appId));
    },
    getSocialAppId: () => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}socialappid`));
      } catch (err) {
        return '';
      }
    },
    clearSocialAppId: () => {
      window.localStorage.removeItem(`${storagePrefix}socialappid`);
    },
    setCheckupRequest: (value) => {
        window.localStorage.setItem(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`, JSON.stringify(value));
    },
    getCheckupRequest: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`));
      } catch (err) {
        return {};
      }
    },
    clearCheckupRequest: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.CHECKUP_REQUEST}`);
    },
    setScanRecord: (value) => {
        window.localStorage.setItem(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`, JSON.stringify(value));
  
    },
    getScanRecord: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`));
      } catch (err) {
        return '';
      }
    },
    clearScanRecord: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_RECORD}`);
    },
    setScanProfile: (value) => {
        window.localStorage.setItem(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`, JSON.stringify(value));

    },
    getScanProfile: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`));
      } catch (err) {
        return {};
      }
    },
    clearScanProfile: (key) => {
      window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_PROFILE}`);
    },
    setScanDentist: (value) => {
        window.localStorage.setItem(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`, JSON.stringify(value));
    },
    getScanDentist: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`));
      } catch (err) {
        return '';
      }
    },
    clearScanDentist: (key) => {
    window.localStorage.removeItem(`${storagePrefix}${STORAGE_KEYS.SCAN_DENTIST}`);
    },
    setIsDentalType: (key) => {
      window.localStorage.setItem(`${storagePrefix}is_dental_type`, JSON.stringify(key));
    },
    getIsDentalType: () => {
      // orphage will determine visibility of UI and functions in the app
      // mainly dentist select
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}is_dental_type`)) === true;
      } catch (err) {
        return false;
      }
    },
    setIsOrphanage: (key) => {
      window.localStorage.setItem(`${storagePrefix}is_orphanage`, JSON.stringify(key));
    },
    getIsOrphanage: () => {
      // orphage will determine visibility of UI and functions in the app
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}is_orphanage`)) === true;
      } catch (err) {
        return false;
      }
    },
    setTourOff: (key) => {
      window.localStorage.setItem(`${storagePrefix}start_tour_${key}`, JSON.stringify(false));
    },
    getTourOn: (key) => {
      try {
        return JSON.parse(window.localStorage.getItem(`${storagePrefix}start_tour_${key}`)) !== false;
      } catch (err) {
        return true;
      }
    }
  };
  
  export default storage;
  