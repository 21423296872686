import { createSelector } from 'reselect'
import { usePubNub } from 'pubnub-react';
import moment from 'moment';
import { map as lodashMap, uniqBy } from 'lodash';

import { decryptMessage, extractDentistUuidFromChannelId, unminifySignalMessage } from '../_helpers';

export const getMessagesSlice = (state) => state.pnMessages;
export const getAuthenticationStateSlice = (state) => state.authentication;
export const getChatListStateSlice = (state) => state.chatsList;
export const getDentistsSlice = (state) => state.dentists;
export const getUsersSlice = (state) => state.pnUserData;
export const getChannelData = (state) => state.pnChannelData;
export const getSignalsSlice = (state) => state.pnSignals;
export const getMembership = (state) => state.pnMembership;
export const getIntlSlice = (state) => state.intl;


export const getIntlMessages = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.messages;
  }
)

export const getIntlLocale = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.locale;
  }
)

export const getUUID = createSelector(
  getAuthenticationStateSlice,
  (state) => {
    return state.chat && state.chat.uuid
  }
)

export const getChatsList = createSelector(
  getChatListStateSlice,
  (chatsList) => {
    return chatsList.chatsList
  }
)

export const getDentistsById = createSelector(
  [getDentistsSlice],
  (dentists) => {
    return dentists.byId;
  }
)

export const getDentistsLoadingById = createSelector(
  [getDentistsSlice],
  (dentists) => {
    return dentists.loading;
  }
)

export const getLoadingMessagesById = createSelector(
  [getChatListStateSlice],
  (state) =>{
    return state.loadingMessagesById
  }
)

export const getIsMembershipsLoaded = createSelector(
  [getChatListStateSlice],
  (chatsList) => {
    return chatsList.membershipsLoaded;
  }
)

export const getMembershipById = createSelector(
  [getMembership, getUUID],
  (memberships, uuid) => {
    const data = {}
    if (memberships && uuid){
      if (memberships.byId[uuid]) {
        memberships.byId[uuid].map((item) => data[item.id] = item )
      }
    }
    return data;
  }
)

export const getChatListMembershipLastRead = createSelector(
  [getChatsList, getMembershipById],
  (chatList, membership) => {
    return (chatList && chatList.length > 0)
      ? chatList.reduce((acc, current) => {
          if (membership[current] && membership[current].custom && membership[current].custom.last_read_time_token){
            acc.push([current, membership[current].custom.last_read_time_token]);
          }
          return acc;
        }, [])
      : []
  }
)

export const getCurrentConversationId = createSelector(
 [getChatListStateSlice],
 (chatLists) => {
   return chatLists.currentConversationId;
 }
);

export const getShowRecordsAttachment = createSelector(
 [getChatListStateSlice],
 (chatLists) => {
   return chatLists.showRecordsAttachment;
 }
)

export const getCurrentConversationLoadingMessages = createSelector(
  [getLoadingMessagesById, getCurrentConversationId],
  (loadingMessages, conversationId) => {
      return loadingMessages
              ? loadingMessages.map((elem, index) => {return elem})
              : []
  }
)

export const getCurrentDentist = createSelector(
  [getDentistsById, getCurrentConversationId],
  (byId, currentConversation ) => {
    return currentConversation ? byId[extractDentistUuidFromChannelId(currentConversation)] : null;
  }
)

export const getCurrentDentistLoading = createSelector(
  [getDentistsLoadingById, getCurrentConversationId],
  (loading, currentConversation ) => {
    return currentConversation ? loading[extractDentistUuidFromChannelId(currentConversation)] : null;
  }
)

export const getDentistLatestExpired = createSelector(
  [getCurrentDentist],
  (dentist) => {
    return dentist && dentist.latestExpired;
  }
)

export const getDentistIsCurrent = createSelector(
  [getDentistLatestExpired],
  (req) => {
    return (req && moment(req.expired).isBefore(moment()))
        || (req && !req.expired && req.id)
  }
)

export const getChannelDataById = createSelector(
  [getChannelData],
  (channels) => {
    return channels.byId;
  }
)

export const isChatsListLoading = createSelector(
  getChatListStateSlice,
  (chatsList) => {
    return chatsList.chatsListLoading
  }
)

export const getChatsListLoaded = createSelector(
  getChatListStateSlice,
  (chatsList) => {
    return chatsList.chatsListLoaded;
  }
)

export const getMessagesById = createSelector(
  [getMessagesSlice],
  (messages) => {
    return messages?.byId;
  }
)

export const getMessageActions = createSelector(
  [getChatListStateSlice],
  (chatList) => {
    return chatList.messageActionsById
  }
)

export const getMessageActionsByChannel = createSelector(
  [getMessageActions, getCurrentConversationId],
  (messageActions, conversationId) => {
    return messageActions[conversationId]
  }
)

export const getLatestMessagesById = createSelector(
  [getChatListStateSlice],
  (chatList) => {
    return chatList.latestsMessagesByChannel;
  }
)

export const getUsersById = createSelector(
  [getUsersSlice],
  (users) => {
    return users.byId;
  }
)

export const getSignalsById = createSelector(
  [getSignalsSlice],
  (signals) => { 
    console.log("signal dict updated")
    return signals?.byId
  }
);

export const getMessageActionSignals = createSelector(
  [getSignalsById, getCurrentConversationId],
  (signals, conversationId) => {
    console.log("signal being updated. new signal found?")
    return signals[conversationId]
      ? signals[conversationId]
          .filter((data) => data.message.t == "ma")
          .map((sig)=> {
            return {messageTimetoken: sig.message.mid, data: unminifySignalMessage(sig)};
          })
      : []
  }
)

export const getCurrentConversationMessages = createSelector(
  [getMessagesById, getMessageActions, getSignalsById, getCurrentConversationId, getUsersById],
  (messages, messageActions, signals, conversationId, users) => {
    const pn = usePubNub();
    return messages[conversationId]
      ? Object.values(messages[conversationId])
          .filter((data) => data.channel === conversationId)
          .map( (entry) => {
            var decryptedMessage = decryptMessage(pn, entry, process.env.REACT_APP_PUBNUB_CIPHER_KEY);
            if (entry.message.data && (decryptedMessage != undefined)){
              let actions = uniqBy((messageActions[conversationId] || []).filter(x=> x.messageTimetoken == entry.timetoken.toString()), 'actionTimetoken') || []
              let msgSignals = uniqBy((signals[conversationId] || []).filter(x=> x.messageTimetoken == entry.timetoken.toString()), 'timetoken') || []
              actions = actions.concat((msgSignals|| []))
              console.log("signals: ", signals);
              console.log("msgSignals: ", msgSignals);
              console.log("actions: ", actions);
              return {
                ...decryptedMessage,
                timetoken: entry.timetoken.toString(),
                actions,
                sender:
                  users[decryptedMessage.senderId]
                    ? {
                        id: decryptedMessage.senderId,
                        name: decryptedMessage.senderId
                      }
                    : {
                        id: "unknown",
                        name: "unknown"
                      }
              }
            } else {
              return {}
            }
          }
        ).sort((msgA, msgB) => {
          if (msgA.timetoken === msgB.timetoken) {
            return 0;
          } else if (msgA.timetoken > msgB.timetoken) {
            return 1;
          } else {
            return -1;
          }
        })
      : [];
  }
);

// TODO get chatlist latest message
export const getLatestMessagesChatList = createSelector(
  [getMessagesById, getLatestMessagesById, getChatsList],
  (messages, latestMessages, chatList) => {
    return (chatList && messages && latestMessages)
      ? chatList.filter(item => !item.startsWith("notifications.")).map((entry) => {
          if (latestMessages[entry] != null && latestMessages[entry].length > 0){
            let reducerLatest = {timeotken: 0};
            let latestMessage = latestMessages[entry][0]
            if (messages[entry] != null && messages[entry].length > 0){
              reducerLatest = messages[entry][messages[entry].length - 1]
            }
            return {id: entry, lastMessage: (parseInt(reducerLatest.timetoken) > (parseInt(latestMessage.timetoken))) ? reducerLatest : latestMessage }
          } else {
            return {id: entry, lastMessage: {timetoken: 0}}
          }
        }).sort((a,b) => {
          if (parseInt(a.lastMessage.timetoken) === parseInt(b.lastMessage.timetoken)){
            return 0;
          } else if (parseInt(a.lastMessage.timetoken) > parseInt(b.lastMessage.timetoken)){ // this gives reverse order. latest on top
            return -1;
          } else {
            return 1;
          }
        })
      : [];
  }
)

export const getUnknownUsers = createSelector(
  [getMessagesById, getCurrentConversationId, getUsersById],
  (messages, conversationId, users) => {
    return messages[conversationId]
      ? Object.values(messages[conversationId])
          .filter(message => message.channel === conversationId)
          .filter(
            message =>
              // if the user is unknown queue up a request for the missing data
              !users[message.message.senderId]
          )
          .map(message => message.message.senderId)
      : [];
  }
);

export const getCurrentConversationData = createSelector(
  [getChannelDataById, getCurrentConversationId],
  (conversations, currentConversationId) => {
    return (conversations[currentConversationId] && conversations[currentConversationId].custom)
            ? {...conversations[currentConversationId].custom }
            : {patient_name: 'Anonymous', request_created_at: '-'};
  }
)

export const getMessageDrafts = createSelector(
  [getChatListStateSlice],
  (chatsList) => {
    return chatsList.messageDraftById
  }
)

export const getConversationMessageDraft = createSelector(
  [getMessageDrafts, getCurrentConversationId],
  (drafts, conversationId) => {
    return drafts[conversationId];
  }
)
