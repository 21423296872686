import React from 'react';
import { Box } from '@chakra-ui/react';
import { COLORS_CATEGORIES } from '../_config';

export function getCategoryColor(category = ''){
    const cat = category.toUpperCase();
    if (cat === 'CARIES') {
        return COLORS_CATEGORIES.CARIES;
    } else if (cat === 'COLOR') {
        return COLORS_CATEGORIES.COLOR;
    } else if (cat === 'GUMS') {
        return COLORS_CATEGORIES.GUMS;
    } else if (cat === 'HABITS') {
        return COLORS_CATEGORIES.HABITS;
    } else if (cat === 'ORTHO') {
        return COLORS_CATEGORIES.ORTHO;
    } else if (cat === 'MULTIPLE') {
        return COLORS_CATEGORIES.MULTIPLE
    } else {
        return COLORS_CATEGORIES.OTHERS;
    }   
}

export const CategorytDot = ({category, width=['12px'], height=['12px'], marginRight=['14px']}) => {
    return (
        <Box
            w={width}
            h={height}
            borderRadius={['50%']}
            bg={getCategoryColor(category)}
            mr={marginRight}
        />
    )
}