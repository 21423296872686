import React, { useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { VStack, Flex, Text } from '@chakra-ui/react';
import {
  localeFormatDate,
  formatName,
  checkNotificationArrayRead
} from '../../_helpers'
import { Scrollbars } from 'react-custom-scrollbars';
import { toUpper } from 'lodash';

import cameraIcon from '../../_images/camera.png'
import reportIcon from '../../_images/reportIcon.png'
import aiReportIcon from '../../_images/aiReportIcon.png'
import { BlankCircle } from '../../_images/icons/BlankCircle';
import { LoadingEllipses } from '../../_components/LoadingEllipses';

// actions
import { actions as reportActions } from '../../Reports/_actions';
import { actions as notificationActions } from '../../Notifications/_actions';


import { getIntlLocale, getIntlMessages, getSelectedProfile, getUUID } from '../../Authentication/_selectors';
import { getNotificationsByRequestId } from '../../Notifications/_selectors';
import {
  getRequestListIsMore,
  getReportListLoading,
  getRequestListWithFilter,
}
from '../../Reports/_selectors';

import {
  StyledHistoryList,
  StyledHistoryListItem,
  StyledIsMore
}
from './styles';


import { StatusTag } from '../../Reports/components/StatusTag';
import { isPaymentRequired, isCompleted } from '../../_helpers/reports';

export const BlankRequestHistoryItem = () => {
    const intlMessages = useSelector(getIntlMessages)
    return(
      <StyledHistoryListItem
        selected = {false}
        blank = {true}
        onClick={() => {}}
        >
            <button disabled className={'camera'}><BlankCircle /></button>
            <p className='blank'>{intlMessages['reportDetail.history.noReport']}</p>
      </StyledHistoryListItem>
    )
  }

const IsMore = ({lastItem}) => {
    const dispatch = useDispatch();
    const intlMessages = useSelector(getIntlMessages);
    const requestListLoading = useSelector(getReportListLoading);

    const onClick = () => {
        dispatch(reportActions.fetchRequestList({'last_ts': lastItem.created_at_ts}))
    }

    return (
        <StyledIsMore>
        {!requestListLoading
            ?
            <div onClick={onClick}>
                <span>
                {intlMessages['settings.loadmore']}
                </span>
            </div>
            :
            <div >
                <LoadingEllipses />
            </div>
        }
        </StyledIsMore>
    )
}

export const CheckupRequestHistoryItem = React.forwardRef(({request, selected}, ref) => {
    const dispatch = useDispatch();
    const intlMessages = useSelector(getIntlMessages);
    const intlLocale = useSelector(getIntlLocale);
    const selectedProfile = useSelector(getSelectedProfile)
    const history = useHistory();
    const requestNotifications = useSelector(getNotificationsByRequestId);
    const chatUuid = useSelector(getUUID);
    const requestId = request && request.id;
    const requestUid = request && request.unique_id;
    let icon = (request && request.type == "REPORT") ? reportIcon : cameraIcon;

    if (request?.type == "GENERATIVE"){
      icon = aiReportIcon
    }
  
    let patient_name = '';
    let name = "";
    let status = "";
    let grade = null;
    let createdAt = "";
    let remark = "";
    let notifs = requestNotifications[requestUid];
    let isRead =  checkNotificationArrayRead(notifs, chatUuid);
    let isPay = false;
    let buttonClassGrade = "";
  
    const handleClick = () => {
      // check if unread notification is present for request, then mark read
      notifs && (notifs.length > 0) && !isRead && dispatch(notificationActions.markNotificationArrayRead(chatUuid, notifs));
      history.push("/requests/" + requestId);
    }



    if (request.user_profile?.id != null){
      patient_name =  request.user_profile.first_name //formatName(intlMessages['format.fullName'], request.user_profile.first_name, request.user_profile.last_name);
    //} else if (profile != null) {
    //  patient_name =  formatName(intlMessages['format.fullName'], profile.first_name, profile.last_name);
    }
    
    let scoreText = `${intlMessages['reportDetail.scoreCard.score.label']} ${request?.report?.grade || '--'}%`
    if (request?.type == 'GENERATIVE'){
      createdAt = localeFormatDate(request.created_at, intlLocale, "ll")
      if (!selectedProfile?.id){
        scoreText += ` (${patient_name})`;
        //scoreText = patient_name
      }
    } else {
      if (isCompleted(request)){
        name = formatName(intlMessages['format.fullName'], request.dentist.first_name, request.dentist.last_name);
        createdAt = localeFormatDate( request.report.sent_at , intlLocale, "ll")
        status = intlMessages[`requestItem.status.${request.status}`];
        remark = request.remark;
        grade = intlMessages[`requestItem.report.grade.${request.report.grade}`]
        buttonClassGrade = `GRADE_${toUpper(request.report.grade)}`;
      } else if (request?.status == "INIT"){
        if (isPaymentRequired(request)){
          // payment required, so show payment
          name = intlMessages['requestItem.status.PAYMENT'];
          createdAt = localeFormatDate( request.created_at , intlLocale, "ll")
          status = intlMessages['requestItem.status.PAYMENT'];
          remark = request.remark;
          isPay = true;
        } else {
          // pending
          name = intlMessages['requestItem.status.TOBEASSIGNED'];
          createdAt = localeFormatDate( request.created_at , intlLocale, "ll")
          status = intlMessages['requestItem.status.PENDING'];
          remark = request.remark;
        }
      } else if (request?.status == "PENDING" || request?.status == "ACCEPTED") {
        status = intlMessages[`requestItem.status.${request.status}`];
        createdAt = localeFormatDate( request.created_at , intlLocale, "ll")
        remark = request.remark;
        if (request.dentist && request.dentist.first_name){
          name = formatName(intlMessages['format.fullName'], request.dentist.first_name, request.dentist.last_name);
        } else {
          name = intlMessages['requestItem.status.TOBEASSIGNED'];
        }
      } else if (request?.status == "CANCELED"){
        status = intlMessages['requestItem.status.CANCELED'];
      }

    }

    const _renderGenerativeItem = () => {
      return (
        <>
          <button disabled className={'generative'}><img src={icon} /></button>
          <VStack
            spacing={'5px'}
            align={'flex-start'}
          >
            <Flex>
              <Text
                color={'black'}
                fontSize={'16px'}
                fontFamily={'Proxima Nova'}
              >
                {createdAt}
              </Text>
              <Flex ml={4} align={'center'}>
                <StatusTag status={request?.status} size={"sm"} />
              </Flex>
            </Flex>
            <Text
              color={'#4D5666'}
              fontSize={'14px'}
              letterSpacing={'0.7px'}
            >
             {scoreText} 
            </Text>
          </VStack>
        </>
      )
    }

    const _renderReportItem = () => {
      return (
        <>
          <button disabled className={'camera'}><img src={icon} /></button>
          <VStack
            spacing={'5px'}
            align={'flex-start'}
          >
            <Text
              color={'black'}
              fontSize={'16px'}
              fontFamily={'Proxima Nova'}
            >
              {createdAt}
            </Text>
            <Text
              color={'#4D5666'}
              fontSize={'14px'}
            >
              {patient_name}
            </Text>
            <StatusTag status={request?.status} size={"sm"} />
          </VStack>
        </>
      )
    }

    const _renderItem = (type) => {
      if (type == 'GENERATIVE'){
        return _renderGenerativeItem()
      } else {
        return _renderReportItem()
      }
    }
  
    return(
      <StyledHistoryListItem
        selected = {selected}
        isRead= {isRead}
        onClick ={handleClick}
        ref={ref}
        >
          {_renderItem(request?.type)}
      </StyledHistoryListItem>
    )
  })
  
export const DashboardCheckupRequestHistory = ({height='calc(100vh - 60px - 110px - 40px - 40px - 30px - 6px)'}) => {

    let params = useParams();
    const dispatch = useDispatch();
    const requestList = useSelector(getRequestListWithFilter)
    const currentListItemRef = useRef(null);
    let requestListLoading = useSelector(getReportListLoading);
    const isMore = useSelector(getRequestListIsMore);
  
    useEffect(()=>{
      dispatch(reportActions.fetchRequestList())
    }, [])
  
  
    useEffect(()=>{
      if (!(params.requestId) && requestList && requestList.length > 0){
        dispatch(reportActions.selectRequest(requestList[0].id))
      }
    }, [requestList && requestList.length])
  
  
    useEffect(() => {
      if (params.requestId && currentListItemRef.current){
        currentListItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      } else {
  
      }
    }, [currentListItemRef.current])
    
  
    return (
        <StyledHistoryList>
            <Scrollbars
                style={{height}}
            >
                {!isMore && requestListLoading &&
                    <div className={"loading"}><LoadingEllipses /></div>
                }
                {requestList && requestList.map((elem) =>
                    <CheckupRequestHistoryItem
                      selected = {elem.id == params.requestId}
                      key = {elem.id}
                      request = {elem}
                      ref = {(elem.id == params.requestId) ? currentListItemRef : null}
                    />
                )}
                { isMore && <IsMore lastItem={requestList[requestList.length - 1]} />}
                {!requestListLoading && requestList && requestList.length < 1 &&
                    <BlankRequestHistoryItem />
                }
            </Scrollbars>
        </StyledHistoryList>
    )
  
  }
  