export function authHeader() {
    // return authorization header with jwt token
    const header = {};
    let user = JSON.parse(localStorage.getItem('user'));
    let lang = localStorage.getItem('language') || 'en';
    if (lang) {
        header['Accept-Language'] = lang ;
    }
    if (user && user.token) {
        return {...header,  'Authorization': 'Token ' + user.token };
    } else {
        return {...header};
    }
}
