import { RECORD_TYPES, REQUEST_TYPES, LOCATIONS } from '../_config';
import React, { useRef, useState, useEffect} from 'react';
import { useLocation, useParams, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';

import { PloverScan } from '../PloverScan';
import { RequestCheckupPage } from '../Checkups';

import { LoadingContent } from '../Modals/Loading';
import { services as reportServices } from '../Reports/_services';
import { actions as checkupActions } from '../Checkups/_actions';
import { actions as reportActions } from '../Reports/_actions';
import { actions as mediaActions } from '../MediaUploader/_actions';

import { getAssignedDentist, getIntlMessages } from '../Authentication/_selectors';
import { getPaymentIntent, getCurrentCheckupRecord } from '../Checkups/_selectors';

import { useUrlSearchParams } from '../_helpers/useUrlSearchParams';
import { Wrapper } from './styles'
import { ClinicSearchPage } from '../Clinics/ClinicSearchPage';
import storage from '../_helpers/storage';
import { RequestConfirmation } from '../Reports/RequestConfirmation';
import { GenerativeReportConfirmation } from '../Reports/GenerativeReportConfirmation';
import { useReportDatesStore } from '../Dashboard/state/reports';

export const ScanningPage = ({closeModal, initPage=-1}) => {
  const dispatch = useDispatch();
  // 0 scan, 1 checkupform, 2 payment receipt
  const searchParams = useUrlSearchParams();
  const location = useLocation();
  const history = useHistory();
  const urlParams = useParams();
  const confirmedRouteMatch= useRouteMatch("/createrequest/confirmed");
  const generatingRouteMatch= useRouteMatch("/createrequest/generating");
  const requestRouteMatch = useRouteMatch("/createrequest/:rest");
  const clinicsRouteMatch = useRouteMatch("/createrequest/clinics");
  const clinicDetailRouteMatch = useRouteMatch("/createrequest/clinics/id/:clinicId");
  let page = searchParams.get("page") || '';
  const recordId = useRef(null);
  const intlMessages = useSelector(getIntlMessages);
  const assignedDentist = useSelector(getAssignedDentist)
  const paymentIntent = useSelector(getPaymentIntent);
  const checkupRecord = useSelector(getCurrentCheckupRecord);

  const setRefetch = useReportDatesStore((state) => state.setRefetch);

  console.log("urlParams location ", location);
  console.log("urlParams urlParams ", urlParams);
  console.log("urlParams requestRouteMatch ", requestRouteMatch)
  console.log("urlParams clinicsRouteMatch ", clinicsRouteMatch)
  console.log("urlParams clinicDetailRouteMatch ", clinicDetailRouteMatch) 

  const PAGES = {
    LOADING: -1,
    CLINICS: 0,
    SCAN: 1,
    CHECKUP: 2,
    PAYMENT: 3,
    GENERATING: 4
  }


  const setPage = (page) => {
    if (page === PAGES.SCAN){
      history.push("/createrequest/scan")
    } else if (page === PAGES.CHECKUP){
      history.push("/createrequest/checkup")
    } else if (page === PAGES.PAYMENT){
      history.push("/createrequest/payment")
    } else if (page === PAGES.CLINICS){
      history.push("/createrequest/clinics")
    } else if (page === PAGES.GENERATING){
      history.push("/createrequest/generating")
    } else if (page === PAGES.CONFIRMED){
      history.push("/createrequest/confirmed")
    }
  }

  const onScanFinished = (currentRecordId, isGenerative=false) => {
    storage.setScanRecord(currentRecordId);
    // if orphaniage, upload to dentalgenerative
    if (storage.getIsOrphanage() == true){
      if (currentRecordId){
        if (isGenerative){
          reportServices.createRequestGenerative({"record": currentRecordId, "type": "GEN_DMFT"})
            .then(
              data => {
                  storage.clearScanDetails();
                  setPage(PAGES.GENERATING);
                  dispatch(reportActions.fetchRequestList())
                  dispatch(mediaActions.resetMedia())
                  setRefetch()
              },
              error => {
                // error
                console.log("error creating request")
                alert("error creating request")
              }
            )
        } else {
          reportServices.createRequestOrphanage({"record": currentRecordId})
            .then(
              data => {
                  storage.clearScanDetails();
                  setPage(PAGES.CONFIRMED);
                  dispatch(reportActions.fetchRequestList())
                  dispatch(mediaActions.resetMedia())
                  setRefetch()
              },
              error => {
                // error
                console.log("error creating request")
                alert("error creating request")
              }
            )
        }
        }
    } else if (storage.getIsDentalType() == false){
      // if not dental type, upload already and show popup
      // create request and upload to server
      if (currentRecordId){
        reportServices.createRequestGenerative({"record": currentRecordId, "type": "GENERATIVE"})
          .then(
            data => {
                storage.clearScanDetails();
                setPage(PAGES.GENERATING);
                dispatch(reportActions.fetchRequestList())
                dispatch(mediaActions.resetMedia())
                setRefetch()
            },
            error => {
              // error
              console.log("error creating request")
              alert("error creating request")
            }
          )
      }
    } else {
      dispatch(checkupActions.setCheckupRecord(currentRecordId))
      setPage(PAGES.CHECKUP);
    }
  }

  const onClinicDentistSelect = (currentUid, currentId) => {
    storage.setScanDentist(currentId);
    dispatch(checkupActions.setCheckupClinicDentist(currentUid))
    setPage(PAGES.SCAN);
  }


  // route pages

  // if starts with clinic
  console.log("assignedDentist ", assignedDentist)

  if (assignedDentist){
    storage.setScanDentist(assignedDentist);
  }

  // if clinic
  if (clinicsRouteMatch?.isExact || clinicDetailRouteMatch?.isExact){
    if (assignedDentist){
      return (<Redirect to="/createrequest/scan" />)
    } else {
      return (
        <Wrapper>
          <ClinicSearchPage
            closeModal={closeModal}
            handleClinicDentistSelected={onClinicDentistSelect}
            />
        </Wrapper>
      ) 
    }
  } else if (confirmedRouteMatch) {
    // should only show for the orphanage
    return (
      <Wrapper>
        <RequestConfirmation
            closeModal={closeModal}
          />
      </Wrapper>
    )
  } else if (generatingRouteMatch){
    return (
      <Wrapper>
        <GenerativeReportConfirmation
            closeModal={closeModal}
          />
      </Wrapper>
    )
  } else if (requestRouteMatch){
    const restRoute = requestRouteMatch?.params?.rest ? requestRouteMatch?.params?.rest : "";
    if (restRoute.startsWith("scan")){
      return (
        <Wrapper>
          <PloverScan
            closeModal={closeModal}
            onScanFinished={onScanFinished}
            />
        </Wrapper>
      )
    } else if (restRoute.startsWith("checkup")){
      return (
        <Wrapper>
          <RequestCheckupPage
            />
        </Wrapper>
      )
    }
  }
  return (
    <Wrapper>
      <LoadingContent
        text={intlMessages['scanningpage.loading.text']}
      />
    </Wrapper>
  )
}
