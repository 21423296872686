import { constants } from './_constants';
import { services } from './_services';

import { actions as recordActions } from '../Records/_actions';

export const actions = {
  fetchRequestList,
  fetchLatestRequest,
  fetchReport,
  selectRequest,
  addToRequestList,
  selectReportCategory,
  updatePayment,
  updateRequestData
}

function updateRequestData(data){
  return (dispatch) => {
    dispatch(update(data));
  }
  function update(data) {
    return {type: constants.UPDATE_REQUEST_DATA, data}
  }
}

function addToRequestList(data){
  return (dispatch) => {
    dispatch(update(data));
  }
  function update(data) {
    return {type: constants.ADDTO_REPORTLIST, data}
  }
}

function updatePayment(data){
  return (dispatch) => {
    dispatch(update(data));
  }
  function update(data) {
    return {type: constants.UPDATE_PAYMENT, data}
  }
}

function selectRequest(id){
  return (dispatch) => {
    dispatch(update(id));
  }
  function update(id) {
    return {type: constants.UPDATE, data: { currentRequestId: id }}
  }
}

function selectReportCategory(cat){
  return (dispatch) => {
    dispatch(update(cat));
  }
  function update(cat) {
    return {type: constants.UPDATE, data: { currentChartCategory: cat }}
  }
}

function fetchLatestRequest(profile){
  return (dispatch, getState) => {
    dispatch(request());
    services.fetchLatestRequest(profile)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCHLATESTREQUEST_REQUEST } }
  function success(data) {
    // breakdown reports...
    console.log("latestrequest ", request)
    return { type:constants.FETCHLATESTREQUEST_SUCCESS, data: {request: data.request}}
  }
  function failure(error) { return { type:constants.FETCHLATESTREQUEST_FAILURE, error} }
}

function fetchRequestList(params={}){
  return (dispatch, getState) => {
    dispatch(request());
    services.fetchRequestList(params)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCHREQUESTS_REQUEST } }
  function success(data) {
    // breakdown reports...
    let requests = data.request_list.reduce((a,b) => {a[b.id] = b;return a;}, {})
    return { type:constants.FETCHREQUESTS_SUCCESS, data: {isMore: data.is_more, requests}}
  }
  function failure(error) { return { type:constants.FETCHREQUESTS_FAILURE, error} }
}

function fetchReport(id){
  return (dispatch, getState) => {
    dispatch(request());
    services.fetchReport(id)
      .then(
        data => {
          if (data.record){
            // TODO this might not be needed
            dispatch(recordActions.updateRecordDetail(data.record));
          }
          dispatch(success(data))
        },
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCHREPORT_REQUEST } }
  function success(data) {
    // breakdown reports...
    return { type:constants.FETCHREPORT_SUCCESS, data: {request : data}}
  }
  function failure(error) { return { type:constants.FETCHREPORT_FAILURE, error} }
}
