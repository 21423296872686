import { createSelector } from 'reselect';
import { toLower } from 'lodash';

export const getAuthenticationSlice = (state) => state.authentication;
export const getIntlSlice = (state) => state.intl;

export const sortProfiles = (a,b,locale) => {
  let key = 'first_name';
  if (['ja'].indexOf(toLower(locale)) != -1) key = 'last_name';

  if (a.is_default){
    return -1;
  }
  if (b.is_default){
    return 1;
  }
  if (a[key] < b[key]){
    return -1;
  } 
  if (a[key] > b[key]){
    return 1;
  }
  return 0;
}

export const getIntlMessages = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.messages;
  }
)
export const getIntlLocale = createSelector(
  [getIntlSlice],
  (intl) => {
    return intl.locale;
  }
)

export const getIsLoggedInFlag = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.loggedIn;
  }
)

export const getIsLoggedInLoading = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.loginLoading;
  }
)

export const getIsChatLoaded = createSelector(
  getAuthenticationSlice,
  (state) => {
    return Object.keys(state.chat).length > 0
  }
)

export const getIsProfileLoaded = createSelector(
  getAuthenticationSlice,
  (state) => {
    return Object.keys(state.profile || {}).length > 0
  }
)

export const getIsChatLoading = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.chatLoading
  }
)

export const getIsProfileLoading = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.profileLoading
  }
)

export const getIsOrphanage = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.is_orphanage
  }
)

export const getIsDentalType = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.is_dental_type
  }
)

export const getProfile = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.profile;
  }
)

export const getIsProfilesLoading = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.profilesLoading
  }
)

export const getSelectedProfile = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.currentProfile;
  }
)

export const getProfiles = createSelector(
  [getAuthenticationSlice, getIntlLocale],
  (state, locale) => {
    //return state.profiles.sort((a,b) => sortProfiles(a,b,locale));
    return state.profiles;
  }
)

export const getFirstName = createSelector(
  getProfile,
  (profile) => {
    return profile?.first_name;
  }
)

export const getCountry = createSelector(
  getProfile,
  (profile) => {
    return profile?.country;
  }
)

export const getLastName = createSelector(
  getProfile,
  (profile) => {
    return profile?.last_name;
  }
)

export const getAssignedDentist = createSelector(
  getProfile,
  (profile) => {
    return profile?.assigned_clinic_dentist;
  }
)

export const getAvatar = createSelector(
  getProfile,
  (profile) => {
    return profile?.avatar;
  }
)

export const getEmail = createSelector(
  getProfile,
  (profile) => {
    return profile?.email;
  }
)

export const getIsPaymentsLoaded = createSelector(
  getAuthenticationSlice,
  (state) => {
    return Object.keys(state.payment).length > 0
  }
)

export const getPayments = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.payments;
  }
)


export const getDefaultPayment = createSelector(
  getPayments,
  (payments) => {
    return (payments)
      ? payments.filter(item => item.default == true)[0]
      : null;
  }
)

export const getPayment = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.payment;
  }
)

export const getPaymentPortalUrl = createSelector(
  getPayment,
  (payment) => {
    return payment.portal_url;
  }
)

export const getPaymentProcessor = createSelector(
  getPayment,
  (payment) => {
    return payment.payment_processor && payment.payment_processor.processor;
  }
)

export const getPaymentProcessorCountry = createSelector(
  getPayment,
  (payment) => {
    return payment.payment_processor && payment.payment_processor.country;
  }
)

export const getPaymentsLoading = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.paymentsLoading;
  }
)

export const getChatState = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.chat
  })

export const getAuthKey = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.chat && state.chat.auth_key
  }
)

export const getUUID = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.chat && state.chat.uuid
  }
)

export const getChannelGroup = createSelector(
  getAuthenticationSlice,
  (state) => {
    return state.chat && state.chat.channel_group
  }
)
