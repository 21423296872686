import { RECORD_TYPES, REQUEST_TYPES, DEVICE_NAME, DEVICE_NAME_DESKTOP } from '../_config';
import React, { useRef, useState, useEffect} from 'react';
import { useHistory, useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { createSelector } from 'reselect'
import ReactPlayer from 'react-player';
import cn from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';
import {
  isMobile,
  isSafari,
  browserVersion
} from "react-device-detect";
import { toUpper, toLower, startCase } from 'lodash';
import { Box, Flex, Text, Button } from '@chakra-ui/react';

import { timeout } from './_helpers';

// components
import { FaTooth, FaPhotoVideo } from 'react-icons/fa';
import { MdPhoto } from 'react-icons/md';
import { PloverWaitingConnection } from './PloverWaitingConnection';
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { TidbitComprehensiveScan, TidbitSelfScan, TidbitCameraPermission, TidbitPloverCaptureButton } from './InformationTidbits';
import { LoadingDots } from '../_components/LoadingDots';
import { PloverCamera, NoCamera, UploadProgress } from '../PloverCamera';
import { ToothSelectChart } from '../ToothSelectChart';
import { FullScanChart } from '../FullScanChart';
import { MediaList } from '../MediaUploader';
import { ConfirmDialog } from '../Modals';
import { ModalHeader } from '../Modals/ModalHeader';
import { ProfileList } from '../Settings/ProfileList'
import { ScanRecordsHistory } from './ScanRecordsHistory';
import { UploadScans } from './UploadScans';

// redux state
import { actions as scanActions } from './_actions';
import {
  getScanType,
  getCurrentMediaToUpload,
  getUploadProgress,
  getCurrentMediaList
} from './_selectors';

// actions
import { actions as cameraActions } from '../PloverCamera/_actions';
import { actions as fullScanActions } from '../FullScanChart/_actions';
import { actions as mediaActions } from '../MediaUploader/_actions';
import { actions as modalActions } from '../Modals/_actions';

// services
import { services as checkupServices } from '../Checkups/_services';
import { services as recordServices } from '../Records/_services';

// selectors
import { getIntlMessages, getProfile, getSelectedProfile } from '../Authentication/_selectors';
import { getCurrentSelected, getCurrentSelectedItem, getMediaToUpload, getMediaCountByRegion } from '../MediaUploader/_selectors';
import { getCurrentConversationId } from '../Chats/_selectors';
import { getPloverDeviceId, getIsAwaitingPlover, getCameraIsError } from '../PloverCamera/_selectors';
import { getCurrentState, getCurrentRegion } from '../FullScanChart/_selectors';

// styles
import { StyledButton, StyledRawButton } from '../_styles/forms';
import { FlexColumn, FlexRow } from '../_styles/common';
import { CloseIcon } from '../Modals/styles';
import {
  Wrapper,
  FlexBox,
  NextButton,
  CancelButton,
  SwitchScanButton,
  StyledRecordedStreamReview,
  StyledMediaColumn,
  Header,
  ToggleButtons,
  ToggleButton,
  ChartIcon,
  MediaIcon,
  ScanActionsSection,
  StyledRecordedStreamReviewOverlay,
  StyledVideoFrame,
  FrameWrapper,
  ScanCameraSection,
  StyledSelectedChartReview,
  StyledScanOptions,
  StyledProfileOptions
}
from './styles';
import { ProfilesSelectorHeader } from '../Profiles/ProfilesSelector';
import storage from '../_helpers/storage';
import { SectionTitle } from '../Clinics/styles';

const equals = (a, b) =>
  a!= null && b != null && a.length === b.length &&
  a.every((v, i) => v === b[i]);


const PAGES = {
  PLOVER_CONNECTION: 0,
  PLOVER_SCAN_OPTIONS: 1,
  PLOVER_PROFILE_OPTIONS: 2,
  PLOVER_STUDIO: 3,
  PLOVER_PREVIOUS_SCANS: 4,
  PLOVER_UPLOAD_SCANS: 5,
}

const SelectedChartReview = ({item, handleFinished}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const [ selectedTeeth, setSelectedTeeth ] = useState([])

  const handleSkip = () => {
    // no need to update extra data
    handleFinished();
  }

  const handleDone = () => {
    // update extra data regions
    dispatch(mediaActions.updateMediaItem(item.getId(), {"extra_data": {"region": selectedTeeth}}))
    handleFinished();
  }

  console.log("selectedTeeth ", selectedTeeth, item.getRegion());
  return (
    <StyledSelectedChartReview>
      <div className={'chartReviewHeader'}>
        <div>{'Select the corresponding teeth on the chart'}</div>
        <MediaIcon onClick={handleSkip}>
          <MdPhoto />
        </MediaIcon>
      </div>
      <div className={'chartReviewChart'}>
        <ToothSelectChart
          item={item}
          defaultRegion={item.getRegion()}
          setCurrentTeeth={setSelectedTeeth}
          />
      </div>
      <div className={'chartReviewActions'}>
        <StyledRawButton disabled={equals(item.getRegion(), selectedTeeth)} onClick={handleDone}>
          {intlMessages['checkup.chart.review.done']}
        </StyledRawButton>
      </div>
    </StyledSelectedChartReview>
  )
}

const RecordedStreamReview = ({item, scanType, handleClose, handleRedo, handleDelete}) => {
  // TODO: chart tooth selection for images or video
  //const [showChart, setShowChart] = useState(item.getRegion() == null);
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();

  const displayChart = () => {
    //setShowChart(true);
  }

  const hideChart = () => {
    //setShowChart(false)
  }

  const onDeleteClick = () => {
    let negativeCallbacks = [modalActions.closeModal('confirmDialog')]
    let positiveCallbacks = [
      modalActions.closeModal('confirmDialog', [handleDelete])
    ]

    dispatch(modalActions.openConfirmDialog(
      intlMessages['scanningpage.mediaReview.delete.title'],
      intlMessages['scanningpage.mediaReview.delete.description'],
      negativeCallbacks,
      positiveCallbacks,
      intlMessages['scanningpage.mediaReview.delete.confirm.text']
    ))
  }

  if (item.getMimeType().startsWith("video")){
    return (
      <StyledRecordedStreamReview 
        className={cn({['FULLSCAN'] : scanType == RECORD_TYPES.FULLSCAN})}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {/* showChart &&
          <SelectedChartReview
            item={item}
            handleFinished={hideChart}
          />
        */}
        <ReactPlayer
            url={item.getUrl()}
            muted={true}
            controls={true}
            width={'640px'}
            height={'480px'}
          />
            <StyledRecordedStreamReviewOverlay>
              <div className={'reviewTitle'}>{intlMessages['checkup.fullscan.review.video.label']}</div>
              { (scanType == RECORD_TYPES.FULLSCAN) && <div className={'closeBtn'} onClick={handleRedo}>{toUpper(intlMessages['checkup.fullscan.review.redo'])}</div>}
              { /*(scanType != RECORD_TYPES.FULLSCAN) &&
                <ChartIcon onClick={displayChart}>
                  <FaTooth />
                </ChartIcon>
              */}
            </StyledRecordedStreamReviewOverlay>
            <UploadProgress />
            <div className={'streamReviewActions'}>
              { (scanType != RECORD_TYPES.FULLSCAN) &&
                <Flex justify={["flex-end"]} align={["center"]} py={"10px"}>
                  <Button onClick={() => onDeleteClick()} variant={"outlineDefault"} >
                    {intlMessages['checkup.review.delete']}
                  </Button>
                  <Button ml={["10px"]} onClick={handleClose}>
                    {intlMessages['checkup.review.close']}
                  </Button>
                </Flex>
              }
            </div>
      </StyledRecordedStreamReview>
    )
  } else if (item.getMimeType().startsWith("image")){
    return (
      <StyledRecordedStreamReview
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{duration: .1}}
        >
        {/* showChart &&
          <SelectedChartReview
            item={item}
            handleFinished={hideChart}
          />
        */}
        <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: {duration: .2} }}
            src={item.getUrl()}
            width={'640px'}
            height={'480px'}
          />
            <StyledRecordedStreamReviewOverlay>
              <div className={'reviewTitle'}>{intlMessages['checkup.fullscan.review.photo.label']}</div>
              { (scanType == RECORD_TYPES.FULLSCAN) &&
                <div className={'closeBtn'} onClick={handleRedo}>
                  {toUpper(intlMessages['checkup.fullscan.review.redo'])}
                </div>
              }
              {/* (scanType != RECORD_TYPES.FULLSCAN) &&
                <ChartIcon onClick={displayChart}>
                  <FaTooth />
                </ChartIcon>
              */}
            </StyledRecordedStreamReviewOverlay>
            <UploadProgress />
            <div className={'streamReviewActions'}>
              { (scanType != RECORD_TYPES.FULLSCAN) &&
                <Flex justify={["flex-end"]} align={["center"]} py={"10px"}>
                  <Button onClick={() => onDeleteClick()} variant={"outlineDefault"} >
                    {intlMessages['checkup.review.delete']}
                  </Button>
                  <Button ml={["10px"]} onClick={handleClose}>
                    {intlMessages['checkup.review.close']}
                  </Button>
                </Flex>
              }
            </div>
      </StyledRecordedStreamReview>
    )
  } else {
    return <></>;
  }
}

const getIsReadyToUpload = createSelector(
  // check if all is uploaded
  [getMediaToUpload, getMediaCountByRegion , getScanType],
  (mediaToUpload, mediaCountByRegion, scanType) => {
    if (scanType == RECORD_TYPES.FULLSCAN){
      return Object.values(mediaCountByRegion).indexOf(0) == -1;
    } else if (scanType == RECORD_TYPES.SELF){
      return mediaToUpload.length > 0;
    } else {
      return false;
    }
  }
)

const getIsReadyToSend = createSelector(
  [getCurrentMediaList],
  (items) => {
    return (items.length > 0)
      ? items.filter(item => item.isUploaded() == true).length == items.length
      : false;
  }
)

const PloverStudioActions = ({onScanFinished, type, isGenerative=true}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const scanType = useSelector(getScanType);
  const selectedProfile = useSelector(getSelectedProfile);
  const isReadyToUpload = useSelector(getIsReadyToUpload);
  const isReadyToSend = useSelector(getIsReadyToSend);
  const currentMediaToUpload = useSelector(getCurrentMediaToUpload);
  const uploadProgress = useSelector(getUploadProgress);
  const currentConversationId = useSelector(getCurrentConversationId);

  const isClose = useRef(false);

  const currentRecordRef = useRef(null);

  const clickCheckUp = () => {
    dispatch(scanActions.setScanType(RECORD_TYPES.FULLSCAN));
  }

  const clickScan = () => {
    // open confirm dialog
    let negativeCallbacks = [modalActions.closeModal('confirmDialog')]
    let positiveCallbacks = [mediaActions.resetMedia()]
    if (scanType == RECORD_TYPES.FULLSCAN){
      positiveCallbacks = [
        ...positiveCallbacks,
        scanActions.setScanType(RECORD_TYPES.SELF),
        modalActions.closeModal('confirmDialog')
      ]
    } else {
      positiveCallbacks = [
        ...positiveCallbacks,
        scanActions.setScanType(RECORD_TYPES.FULLSCAN),
        modalActions.closeModal('confirmDialog')
      ]
    }
    dispatch(modalActions.openConfirmDialog(
      intlMessages['scanningpage.media.removemedia.close.header'],
      intlMessages['scanningpage.media.removemedia.close.description'],
      negativeCallbacks,
      positiveCallbacks,
      intlMessages['scanningpage.media.removemedia.close.button.confirm'],
    ))
  }

  const uploadMedia = async () => {
    try {
      // create record here with API
      let extraData = null;
      if (type == "attachment" && currentConversationId){
        extraData = {'channel_id': currentConversationId};
      }
      const recordResp = await recordServices.createRecordId(scanType, selectedProfile.id, extraData);
      currentRecordRef.current = recordResp.id;
    } catch(err){
      console.log("UPLOAD ERROR: ", err)
    }
    if (currentMediaToUpload && currentRecordRef.current){
      dispatch(mediaActions.uploadAll(currentMediaToUpload, currentRecordRef.current ))
    } else {
      alert("error with upload");
    }
  }

  const closeModal = () => {
    isClose.current = true;
    let negativeCallbacks = [modalActions.closeModal('confirmDialog')]
    let positiveCallbacks = [
      mediaActions.resetMedia(),
      () => storage.clearScanDetails(),
      modalActions.closeModal('confirmDialog'),
      () => history.push("/")
      //modalActions.closeModal('scanningPage')
    ]
    dispatch(modalActions.openConfirmDialog(
      intlMessages['scanningpage.media.loseprogress.close.header'],
      intlMessages['scanningpage.media.loseprogress.close.description'],
      negativeCallbacks,
      positiveCallbacks,
      intlMessages['scanningpage.media.loseprogress.close.button.confirm'],
    ))
  }

  useEffect(()=>{
    // when get ready to send == true
    // then reportServices.createCheckupRequest()
    console.log("isreadytosend", isReadyToSend);
    if (isReadyToSend){
      console.log("issending", isReadyToSend);
      dispatch(scanActions.updateState('recordId', currentRecordRef.current))
      onScanFinished(currentRecordRef.current, isGenerative)
      // TODO send to requestForm
    }
  }, [isReadyToSend])
  
  if (type == "attachment"){ // no full scan, just take pictures
    return (
      <ScanActionsSection>
        <ToggleButtons></ToggleButtons>
        <NextButton onClick={uploadMedia} disabled={!isReadyToUpload}>{intlMessages['checkup.fullscan.buttons.send']}</NextButton>
      </ScanActionsSection>
    )
  } else if (storage.getIsDentalType() == true){
    return (
      <ScanActionsSection>
        <SwitchScanButton onClick={clickScan}>
            {scanType == RECORD_TYPES.FULLSCAN
              ? intlMessages['checkup.checkupform.switchscan.button.self']
              : intlMessages['checkup.checkupform.switchscan.button.guided']
            }
        </SwitchScanButton>
        <FlexBox>
          <Button variant={"outlineDefault"} onClick={closeModal} disabled={(uploadProgress > 0)}>
            {toUpper(intlMessages['checkup.checkupform.navButton.cancel'])}
          </Button>
          <Button ml={["10px"]} onClick={uploadMedia} disabled={!isReadyToUpload || (uploadProgress > 0)}>
            {toUpper(intlMessages['checkup.fullscan.buttons.send'])}
          </Button>
        </FlexBox>
      </ScanActionsSection>
    )
  } else {
    return (
      <ScanActionsSection>
        <div>{""}</div>
        <FlexBox>
          <Button variant={"outlineDefault"} onClick={closeModal} disabled={(uploadProgress > 0)}>
            {toUpper(intlMessages['checkup.checkupform.navButton.cancel'])}
          </Button>
          <Button ml={["10px"]} onClick={uploadMedia} disabled={!isReadyToUpload || (uploadProgress > 0)}>
            {isGenerative ? toUpper(intlMessages['reportGenerative.button.submit']) : toUpper(intlMessages['reportGenerative.dental.button.submit'])}
          </Button>
        </FlexBox>
      </ScanActionsSection>
    )
  }
}

const PloverStudioMediaColumn = () => {
  const scanType = useSelector(getScanType);
  const intlMessages = useSelector(getIntlMessages);
  return (
      <StyledMediaColumn className={scanType}>
        { (scanType == RECORD_TYPES.FULLSCAN)
          ? (
            <React.Fragment>
              <Header>{intlMessages['checkup.fullscan.title']}</Header>
              <FullScanChart />
              <Header>{intlMessages['checkup.fullscan.scans.title']}</Header>
              <MediaList />
            </React.Fragment>
          ): (
            <React.Fragment>
              <Header className={scanType}>{intlMessages['checkup.fullscan.scans.title']}</Header>
              <MediaList />
            </React.Fragment>
          )}
      </StyledMediaColumn>
  )
}

const PloverStudio = ({onScanFinished, type, isGenerative}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const scanType = useSelector(getScanType);
  const currentSelected = useSelector(getCurrentSelected);
  const currentDeviceId = useSelector(getPloverDeviceId);
  const currentSelectedItem = useSelector(getCurrentSelectedItem);

  let options = { mimeType: 'video/webm', videoBitsPerSecond: 5000000}; //150,000,000 18.75Mbps, 50,000,000 6.25Mbps, 5,000,000 0.625Mbps
  let blobOptions = {type: 'video/webm'}
  //options = Object.assign({}, options, {mimeType: null} );

  /*
  //DEBUGGING
  var types = ["video/webm",
             "video/mp4",
             "video/mp4\;codecs=h264",
             "video/mp4\;codecs=avc1.4d002a",
             "video/webm\;codecs=vp8",
             "video/webm\;codecs=vp9",
             "video/webm\;codecs=daala",
             "video/webm\;codecs=h264",
             "video/mpeg"];

  for (var i in types) {
    console.log( "Is " + types[i] + " supported? " + (MediaRecorder.isTypeSupported(types[i]) ? "Maybe!" : "Nope :("));
  }*/

  useEffect(() =>{
    return () => {};
  }, [])

  useEffect(()=>{
    if (currentDeviceId){
    }
  }, [currentDeviceId])

  const closeReview = () => {
    dispatch(mediaActions.setCurrentItem(null));
  }

  const closeModal= () => {
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push("/")
  }

  const redoRegion = () => {
    dispatch(mediaActions.removeItem(currentSelected));
    dispatch(fullScanActions.redoRegion())
  }

  const deleteReview = () => {
    //dispatch(mediaActions.setCurrentItem(null)); shoudl already set to null when removed
    dispatch(mediaActions.removeItem(currentSelectedItem.getId()))
  }

  if (!(isSafari && browserVersion < 12)){
    if (MediaRecorder.isTypeSupported('video/mp4')) {
      options = Object.assign({}, options, {mimeType: 'video/mp4'} )  
      blobOptions = Object.assign({}, blobOptions, {type: 'video/mp4'} )  
    } else if (MediaRecorder.isTypeSupported("video/webm\;codecs=h264")) {
      options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=h264'} )  
      blobOptions = Object.assign({}, blobOptions, {type: 'video/webm\;codecs=h264'} )
    } else if (MediaRecorder.isTypeSupported('video/webm\;codecs=vp9')) {
      options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=vp9'} )
      blobOptions = Object.assign({}, blobOptions, {type: 'video/webm\;codecs=vp9'} )
    } else if (MediaRecorder.isTypeSupported('video/webm\;codecs=vp8')) {
      options = Object.assign({}, options, {mimeType: 'video/webm\;codecs=vp8'} )
      blobOptions = Object.assign({}, blobOptions, {type: 'video/webm\;codecs=vp8'} )
    } else if (MediaRecorder.isTypeSupported('video/webm')) {
      options = Object.assign({}, options, {mimeType: 'video/webm'} )
      blobOptions = Object.assign({}, blobOptions, {type: 'video/webm'} )
    } else {
      options = Object.assign({}, options, {mimeType: null} );
      blobOptions = Object.assign({}, blobOptions, {type: null} );
      // probably throw an error here. or check options null in video
    }
  } else if (isSafari){
    options = Object.assign({}, options, {mimeType: 'video/mp4'} )  
    blobOptions = Object.assign({}, blobOptions, {type: 'video/mp4'} )
  }
  
  return (
    <Wrapper>
      <ProfilesSelectorHeader />
      <ScanCameraSection>
        <StyledVideoFrame>
          <Header>{intlMessages['checkup.fullscan.title']}</Header>
          <FrameWrapper>
            <AnimatePresence>
              {
                currentSelectedItem &&
                <RecordedStreamReview
                  key={`RecordedStreamReview_${currentSelectedItem}`}
                  item={currentSelectedItem}
                  scanType ={scanType}
                  handleClose={closeReview}
                  handleRedo={redoRegion}
                  handleDelete={deleteReview}
                />
              }
            </AnimatePresence>
          { currentDeviceId
            ? <PloverCamera
                key={`PloverCamera_${currentDeviceId}`}
                mediaRecorderOptions={options}
                deviceId={currentDeviceId}
                extraBlobOptions={blobOptions}
              />
            : <NoCamera />
          }
          </FrameWrapper>
        </StyledVideoFrame>
        <PloverStudioMediaColumn />
      </ScanCameraSection>
      <PloverStudioActions closeModal={closeModal} onScanFinished={onScanFinished} type={type} isGenerative={isGenerative}/>
    </Wrapper>
  )
}

const ScanOptionItem = ({type, title, description,  onClick, isSelected, isGenerativeItem}) => {
  
  const onSelect = () => {
    onClick(type, isGenerativeItem );
  }

  return (
    <Flex
      direction={["column"]}
      bg={isSelected ? '#273238': '#ffffff'}
      borderRadius={'10px'}
      color={isSelected ? '#ffffff': '#000'}
      py={'20px'}
      px={['30px']}
      mb={'10px'}
      align={["flex-start"]}
      justify={["center"]}
      _hover={{cursor: 'pointer'}}
      onClick={onSelect}
    >
      <Text textAlign={'center'} fontSize={'20px'} textTransform={'capitalize'}>
        {title}
      </Text>
      <Text textAlign={'left'} fontSize={'13px'} color={isSelected ? '#6B8A99' : '#405159' }>
        {description}
      </Text>
    </Flex>
  )
}

const PloverScanOptions = ({nextPage, setIsGenerative}) => {
  const [ selectedOption, setSelectedOption ] = useState(null)
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);

  const SCAN_OPTIONS = {
    'GUIDED': 'GUIDED',
    'GUIDED_GENERATIVE': 'GUIDED_GENERATIVE',
    'SELF': 'SELF',
    'PREVIOUS': 'PREVIOUS',
    'UPLOAD': 'UPLOAD'
  }

  const closeModal = () => {
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push("/")
  }

  const handleGuidedScanClick = () =>{
    dispatch(scanActions.setScanType(RECORD_TYPES.FULLSCAN));
    let doNotShow = localStorage.getItem(`doNotShow__comprehensiveScanTidbits`);
    if (!doNotShow){
      //dispatch(modalActions.openHowToModal('guidedScan'));
    } else {

    }
    nextPage()
  }

  const handleSelfScanClick = () => {
    dispatch(scanActions.setScanType(RECORD_TYPES.SELF));
    nextPage()
  }

  const handlePreviousScanClick = () => {
    nextPage(true, PAGES.PLOVER_PREVIOUS_SCANS)
  }

  const handleUploadScanClick = () => {
    nextPage(true, PAGES.PLOVER_UPLOAD_SCANS)
  }

  const handleNextClick = () => {
    if (selectedOption == SCAN_OPTIONS.GUIDED){
        handleGuidedScanClick();
    } else if (selectedOption == SCAN_OPTIONS.GUIDED_GENERATIVE){
        handleGuidedScanClick();
    } else if (selectedOption == SCAN_OPTIONS.SELF){
      handleSelfScanClick();
    } else if ( selectedOption == SCAN_OPTIONS.PREVIOUS){
        handlePreviousScanClick();
    } else if ( selectedOption == SCAN_OPTIONS.UPLOAD){
        handleUploadScanClick();
    }
  }

  const handleSetSelectedOption = (val, isGenerativeItem) => {
    setSelectedOption(val)
    setIsGenerative(isGenerativeItem)
  }

  return (
    <>
      <ModalHeader 
        title={intlMessages['tidbits.scanning.header']}
      />
      <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
      <Box
        px={[[12]]}
        py={['30px']}
        w={['570px']}
        bg={'#F7F7F7'}
      >
        <Box>
          <SectionTitle>
            {intlMessages['checkup.fullscan.title']}
          </SectionTitle>
          <Box
            pt={[2]}
          >
            <ScanOptionItem 
              type={SCAN_OPTIONS.GUIDED}
              title={intlMessages['tidbits.scanning.generated.button']} 
              description={intlMessages['tidbits.scanning.generated.description.1']}
              isSelected={selectedOption == SCAN_OPTIONS.GUIDED}
              onClick={handleSetSelectedOption}
              isGenerativeItem={true}
            />
            <ScanOptionItem 
              type={SCAN_OPTIONS.GUIDED_GENERATIVE}
              title={intlMessages['tidbits.scanning.dentist.button']} 
              description={intlMessages['tidbits.scanning.dentist.description.1']}
              isSelected={selectedOption == SCAN_OPTIONS.GUIDED_GENERATIVE}
              onClick={handleSetSelectedOption}
              isGenerativeItem={false}
            />
            <ScanOptionItem 
              type={SCAN_OPTIONS.UPLOAD}
              title={intlMessages['tidbits.scanning.uploads.button']} 
              description={intlMessages['tidbits.scanning.uploads.description']}
              isSelected={selectedOption == SCAN_OPTIONS.UPLOAD}
              onClick={handleSetSelectedOption}
              isGenerativeItem={false}
            />
          </Box>
        </Box>
        <Flex w={["full"]} justify={'center'} mt={['20px']}>
          <Button w={['full']} 
            onClick={handleNextClick} 
            isDisabled={selectedOption == null} 
            py={['12px']} 
            maxH={['unset']} 
            h={['40px']}>
              {intlMessages['tidbits.next']}
          </Button>
        </Flex>
      </Box>
      </>
  )
}

const PloverScanOptionsDental = ({nextPage, setIsGenerative}) => {
  const [ selectedOption, setSelectedOption ] = useState(null)
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);

  const SCAN_OPTIONS = {
    'GUIDED': 'GUIDED',
    'GUIDED_GENERATIVE': 'GUIDED_GENERATIVE',
    'SELF': 'SELF',
    'PREVIOUS': 'PREVIOUS',
    'UPLOAD': 'UPLOAD'
  }

  const closeModal = () => {
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push("/")
  }

  const handleGuidedScanClick = () =>{
    dispatch(scanActions.setScanType(RECORD_TYPES.FULLSCAN));
    let doNotShow = localStorage.getItem(`doNotShow__comprehensiveScanTidbits`);
    if (!doNotShow){
      //dispatch(modalActions.openHowToModal('guidedScan'));
    } else {

    }
    nextPage()
  }

  const handleSelfScanClick = () => {
    dispatch(scanActions.setScanType(RECORD_TYPES.SELF));
    nextPage()
  }

  const handlePreviousScanClick = () => {
    nextPage(true, PAGES.PLOVER_PREVIOUS_SCANS)
  }

  const handleUploadScanClick = () => {
    nextPage(true, PAGES.PLOVER_UPLOAD_SCANS)
  }

  const handleNextClick = () => {
    if (selectedOption == SCAN_OPTIONS.GUIDED){
        handleGuidedScanClick();
    } else if (selectedOption == SCAN_OPTIONS.GUIDED_GENERATIVE){
        handleGuidedScanClick();
    } else if (selectedOption == SCAN_OPTIONS.SELF){
      handleSelfScanClick();
    } else if ( selectedOption == SCAN_OPTIONS.PREVIOUS){
        handlePreviousScanClick();
    } else if ( selectedOption == SCAN_OPTIONS.UPLOAD){
        handleUploadScanClick();
    }
  }

  const handleSetSelectedOption = (val, isGenerativeItem) => {
    setSelectedOption(val)
    setIsGenerative(isGenerativeItem)
  }

  return (
    <>
      <ModalHeader 
        title={intlMessages['tidbits.scanning.header']}
      />
      <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
      <Box
        px={[[12]]}
        py={['30px']}
        w={['570px']}
        bg={'#F7F7F7'}
      >
        <Box>
          <SectionTitle>
            {intlMessages['checkup.fullscan.title']}
          </SectionTitle>
          <Box
            pt={[2]}
          >
            <ScanOptionItem 
              type={SCAN_OPTIONS.GUIDED}
              title={intlMessages['tidbits.scanning.guided.button']} 
              description={intlMessages['tidbits.scanning.guided.description.1']}
              isSelected={selectedOption == SCAN_OPTIONS.GUIDED}
              onClick={handleSetSelectedOption}
              isGenerativeItem={true}
            />
            <ScanOptionItem 
              type={SCAN_OPTIONS.PREVIOUS}
              title={intlMessages['tidbits.scanning.previous.button']} 
              description={intlMessages['tidbits.scanning.previous.description']}
              isSelected={selectedOption == SCAN_OPTIONS.PREVIOUS}
              onClick={handleSetSelectedOption}
              isGenerativeItem={false}
            />
          </Box>
        </Box>
        <Flex w={["full"]} justify={'center'} mt={['20px']}>
          <Button w={['full']} 
            onClick={handleNextClick} 
            isDisabled={selectedOption == null} 
            py={['12px']} 
            maxH={['unset']} 
            h={['40px']}>
              {intlMessages['tidbits.next']}
          </Button>
        </Flex>
      </Box>
      </>
  )
}

export const PloverScanOptions2 = ({nextPage}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);

  const closeModal = () => {
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push("/")
  }
  const handleGuidedScanClick = () =>{
    dispatch(scanActions.setScanType(RECORD_TYPES.FULLSCAN));
    let doNotShow = localStorage.getItem(`doNotShow__comprehensiveScanTidbits`);
    if (!doNotShow){
      //dispatch(modalActions.openHowToModal('guidedScan'));
    } else {

    }
    nextPage()
  }

  const handleSelfScanClick = () => {
    dispatch(scanActions.setScanType(RECORD_TYPES.SELF));
    nextPage()
  }

  const handlePreviousScanClick = () => {
    nextPage(true)
  }

  return(
    <>
      <ModalHeader 
          title={intlMessages['tidbits.scanning.header']}
      />
      <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
      <StyledScanOptions>
        <FlexRow className={'scanInformation'}>
          <Flex direction={['column']} justify={['space-between']}>
            <TidbitComprehensiveScan />
            <div className={'actions'}><NextButton onClick={handleGuidedScanClick}>
              {intlMessages['tidbits.scanning.guided.button']}</NextButton>
            </div>
          </Flex>
          <Flex direction={['column']} justify={['space-between']}>
            <TidbitSelfScan />
            <div className={'actions'}>
              <NextButton onClick={handleSelfScanClick}>{intlMessages['tidbits.scanning.self.button']}</NextButton>
            </div>
          </Flex>
        </FlexRow>
        <Flex
          align="center"
          justify="center"
          pt="20px"
          pb="10px"
          mt="20px"
          borderTop="1px solid #D8D8D8"
        >
          <Flex align="center"  mr="40px">
            <Text>
              {intlMessages['tidbits.scanning.previous.description']}
            </Text>
          </Flex>
          <Flex>
              <Button textTransform="capitalize" variant="link" onClick={handlePreviousScanClick}>
                {startCase(toLower(intlMessages['tidbits.scanning.previous.button']))}
              </Button>
          </Flex>
        </Flex>
      </StyledScanOptions>
    </>
  )
}


export const PloverProfileOptions = ({nextPage}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);

  const closeModal = () => {
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push("/");
  }

  const onSelect = (profile) => {
    storage.setScanProfile(profile);
  }

  return (
      <>
        <ModalHeader 
            title={intlMessages['checkup.modal.header.title']}
        />
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <ProfileList closeModal={nextPage} onSelect={onSelect} />
      </>
  )
}

export const PreviousScansModal = ({replacePage, onSelected}) => { 
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const selectedProfile = useSelector(getSelectedProfile)

  console.log("selectedProfile ", selectedProfile)

  const closeModal = () => {
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push("/");
  }

  const goBack = () => {
    console.log("go back now")
    replacePage(PAGES.PLOVER_SCAN_OPTIONS)
  }

  return (
    <>
      <ModalHeader 
          title={intlMessages['tidbits.scanning.previous.header']}
      />
      <CloseIcon onClick={closeModal}><CloseSvg/ ></CloseIcon>
      <ScanRecordsHistory onSelect={onSelected} goBack={goBack} partial={true} profileId={selectedProfile?.id} />
    </>
  )
}

export const UploadScansModal = ({replacePage, onSelected}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const selectedProfile = useSelector(getSelectedProfile)

  const closeModal = () => {
    history.push("/");
    
  }

  const goBack = () => {
    console.log("go back now")
    replacePage(PAGES.PLOVER_SCAN_OPTIONS)
  }

  return (
    <>
      <ModalHeader
          title={intlMessages['tidbits.scanning.uploads.header']}
      />
      <CloseIcon onClick={closeModal}><CloseSvg/ ></CloseIcon>
      <UploadScans goBack={goBack} onSelect={onSelected} profileId={selectedProfile?.id} />
    </>
  )
}

const BrowserCameraWrapper = ({onScanFinished, setPage, closeModal, type, replacePage, isGenerative}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checkMediaDevices, setCheckMediaDevices ] = useState(false);
  const currentDeviceId = useSelector(getPloverDeviceId);
  const deviceError = useSelector(getCameraIsError);
  const intlMessages = useSelector(getIntlMessages);
  const isAwaitingPlover = useSelector(getIsAwaitingPlover);
  const currentDeviceName = useRef(null);

  const ploverRouteMatch = useRouteMatch("/createrequest/scan/connect");
  const studioRouteMatch = useRouteMatch("/createrequest/scan/studio");

  useEffect(() =>{
    return () => {};
  }, [])

  const onDevicesChanged = (event) => {
    setCheckMediaDevices(!checkMediaDevices);
  }

  const updateDeviceId = (deviceId) => {
    dispatch(cameraActions.setPloverDeviceId(deviceId));
  }


  const handleError = (err) => {
    //TODO dialog explaining how permissions work
    console.log("PLOVER CONNECT ERROR", err);
    dispatch(cameraActions.updateState('deviceError', err));
    //alert(intlMessages['checkup.fullscan.error.camerapermission.message']);
  }

  useEffect(()=>{
    async function connectPlover(devId){
      // NOTE need to wait for plover to start up, or there is error redaing the stream
      dispatch(cameraActions.updateState('awaitingDevice', true));
      await timeout(2000);
      dispatch(cameraActions.updateState('awaitingDevice', false));
      updateDeviceId(devId);
    }
  
    const videoConstraints = { video: true};
    if (navigator && navigator.mediaDevices){
      if (navigator.mediaDevices.getUserMedia){
        // handle permissions
        navigator.mediaDevices.getUserMedia(videoConstraints).then(
          ()=>{
          navigator.mediaDevices.ondevicechange = onDevicesChanged;
          let deviceId = null;
          navigator.mediaDevices
            .enumerateDevices()
            .then(r => {
              console.log("DEBUG DEVICES: ", r)
              for (var i =0;i < r.length; i++){
                if (r[i].label.startsWith(DEVICE_NAME)){
                  deviceId = r[i].deviceId;
                  currentDeviceName.current = r[i].label;
                }
              }
              if (currentDeviceId && !deviceId){ // disconnected plover
                updateDeviceId(null);
              } else if (!currentDeviceId && deviceId){ // connected plover
                connectPlover(deviceId);
              } else {
                // nothing to do with plover. other devices connected/disconnected

              }
            })

          }
        ).catch(
          err => handleError(err)
        )
      }
    }
  }, [checkMediaDevices])


  if (ploverRouteMatch?.isExact){
    return (
      <PloverWaitingConnection
        nextPage={()=>setPage(PAGES.PLOVER_STUDIO)}
        currentDeviceId={currentDeviceId}
        deviceError={deviceError}
        closeModal={closeModal}
        isAwaitingPlover={isAwaitingPlover} />
    )
  } else if (studioRouteMatch?.isExact){
    return (<PloverStudio onScanFinished={onScanFinished} type={type} isGenerative={isGenerative}/>)
  }

}

export const PloverScan = ({closeModal, onScanFinished, type="default"}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isGenerative = useRef(false);
  const profilesRouteMatch = useRouteMatch("/createrequest/scan");
  const uploadScanMatch = useRouteMatch("/createrequest/scan/upload");
  const previousScanMatch = useRouteMatch("/createrequest/scan/previous");
  const scanOptionsRouteMatch = useRouteMatch("/createrequest/scan/options");
  const ploverRouteMatch = useRouteMatch("/createrequest/scan/connect");
  const studioRouteMatch = useRouteMatch("/createrequest/scan/studio");
  const [checkMediaDevices, setCheckMediaDevices ] = useState(false);
  const currentDeviceId = useSelector(getPloverDeviceId);
  const deviceError = useSelector(getCameraIsError);
  const intlMessages = useSelector(getIntlMessages);
  const isAwaitingPlover = useSelector(getIsAwaitingPlover);
  const currentDeviceName = useRef(null);

  useEffect(() =>{
    return () => {};
  }, [])

  const setIsGenerative = (val) => {
    isGenerative.current = val;
  }

  const onDevicesChanged = (event) => {
    setCheckMediaDevices(!checkMediaDevices);
  }

  const updateDeviceId = (deviceId) => {
    dispatch(cameraActions.setPloverDeviceId(deviceId));
  }

  const connectDevice = (deviceId) => {}

  const disconnectDevice = (deviceId) => {
    dispatch(cameraActions.setPloverDeviceId(deviceId));
  }

  const handleSuccess = () => {
    // TODO continue on?
  }

  const handleError = (err) => {
    //TODO dialog explaining how permissions work
    console.log("PLOVER CONNECT ERROR", err);
    dispatch(cameraActions.updateState('deviceError', err));
    //alert(intlMessages['checkup.fullscan.error.camerapermission.message']);
  }

  const setPage = (page, previous=false) => {
    if (page == PAGES.PLOVER_STUDIO){
      history.replace("/createrequest/scan/studio")
    } else if (page == PAGES.PLOVER_SCAN_OPTIONS){
      history.push("/createrequest/scan/options")
    } else if (page == PAGES.PLOVER_PROFILE_OPTIONS){
      history.push("/createrequest/scan")
    } else if (page == PAGES.PLOVER_CONNECTION){
      history.push("/createrequest/scan/connect")
    } else if (page == PAGES.PLOVER_PREVIOUS_SCANS){
      history.push("/createrequest/scan/previous")
    } else if (page == PAGES.PLOVER_UPLOAD_SCANS){
      history.push("/createrequest/scan/upload")
    } else {
      history.push("/createrequest/scan")
    }

  }
  
  const replacePage = (page) => {
    if (page == PAGES.PLOVER_STUDIO){
      history.replace("/createrequest/scan/studio")
    } else if (page == PAGES.PLOVER_SCAN_OPTIONS){
      history.replace("/createrequest/scan/options")
    } else if (page == PAGES.PLOVER_PROFILE_OPTIONS){
      history.replace("/createrequest/scan")
    } else if (page == PAGES.PLOVER_CONNECTION){
      history.replace("/createrequest/scan/connect")
    } else if (page == PAGES.PLOVER_PREVIOUS_SCANS){
      history.replace("/createrequest/scan/previous")
    } else if (page == PAGES.PLOVER_UPLOAD_SCANS){
      history.replace("/createrequest/scan/upload")
    } else {
      history.replace("/createrequest/scan")
    }
  }

  /*useEffect(()=>{
    async function connectPlover(devId){
      // NOTE need to wait for plover to start up, or there is error redaing the stream
      dispatch(cameraActions.updateState('awaitingDevice', true));
      await timeout(2000);
      dispatch(cameraActions.updateState('awaitingDevice', false));
      updateDeviceId(devId);
    }
  
    const videoConstraints = { video: true};
    if (navigator && navigator.mediaDevices){
      if (navigator.mediaDevices.getUserMedia){
        // handle permissions
        navigator.mediaDevices.getUserMedia(videoConstraints).then(
          ()=>{
          navigator.mediaDevices.ondevicechange = onDevicesChanged;
          let deviceId = null;
          navigator.mediaDevices
            .enumerateDevices()
            .then(r => {
              console.log("DEBUG DEVICES: ", r)
              for (var i =0;i < r.length; i++){
                if (r[i].label.startsWith(DEVICE_NAME)){
                  deviceId = r[i].deviceId;
                  currentDeviceName.current = r[i].label;
                }
              }
              if (currentDeviceId && !deviceId){ // disconnected plover
                updateDeviceId(null);
              } else if (!currentDeviceId && deviceId){ // connected plover
                connectPlover(deviceId);
              } else {
                // nothing to do with plover. other devices connected/disconnected

              }
            })

          }
        ).catch(
          err => handleError(err)
        )
      }
    }
  }, [checkMediaDevices])*/

  const onScanSelected = (recordId) => {
    onScanFinished(recordId);
  }

  const handleProfileSelect = () => {
    //setPage(PAGES.PLOVER_CONNECTION);
    setPage(PAGES.PLOVER_SCAN_OPTIONS);
  }
  const handleScanOptionClick = (isPrevious=false, page=PAGES.PLOVER_PREVIOUS_SCANS) => {
    //dispatch(modalActions.openModal('profileList'));
    console.log("handleScanOptionClick ", page)
    if (isPrevious){
      setPage(page, true);
    } else {
      setPage(PAGES.PLOVER_CONNECTION);
    }
  }

  // determine which options show to redirect to the user

  const isDentalUser = storage.getIsDentalType();
  const isOrphanageUser = storage.getIsOrphanage();

  // if it is a generative report user 

  if (ploverRouteMatch?.isExact){
    return (
      <BrowserCameraWrapper 
        setPage={setPage}
        closeModal={closeModal} 
        onScanFinished={onScanFinished} 
        type={type}
        isGenerative={isGenerative.current}
      />
    )
  } else if (uploadScanMatch?.isExact) {
    // if user somehow got to prevbivous scans, then redirect to scan
    // only for orphanage users
    if (isOrphanageUser){
      return (<UploadScansModal onSelected={onScanSelected} replacePage={replacePage} />)
    } else {
      return(
        <Redirect to="/createrequest/scan/connect" />
      )
    }
  }else if (previousScanMatch?.isExact) {
    // if user somehow got to prevbivous scans, then redirect to scan
    // only for dental users
    if (isOrphanageUser || isDentalUser){
      return(<PreviousScansModal onSelected={onScanSelected} replacePage={replacePage} />)
    } else {
      return(
        <Redirect to="/createrequest/scan/connect" />
      )
    }
  }else if (studioRouteMatch?.isExact){
    return (
      <BrowserCameraWrapper 
        setPage={setPage}
        closeModal={closeModal} 
        onScanFinished={onScanFinished} 
        type={type}
        isGenerative={isGenerative.current}
      />
    )
  }else if (scanOptionsRouteMatch?.isExact){
    // ability to select select previous scans
    // only for dental users
    if (isOrphanageUser){
      return(
        <PloverScanOptions nextPage={handleScanOptionClick} setIsGenerative={setIsGenerative} />
      )
    } else if (isDentalUser){
      return(
        <PloverScanOptionsDental nextPage={handleScanOptionClick} setIsGenerative={setIsGenerative}/>
      )
    } else {
      return(
        <Redirect to="/createrequest/scan/connect" />
      )
    }
  }  else if (profilesRouteMatch?.isExact) {
    return (
      <PloverProfileOptions nextPage={()=> handleProfileSelect()} />
    )
  }

}
