import './index.css';
import React from 'react';
import { useEffect, useRef } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import EditorJS from '@editorjs/editorjs';

import { EDITOR_TOOLS, READ_ONLY_EDITOR_BOX_PROPS } from './constants';
import { useReportStore } from '../state/report';

export const ReadOnlyEditor = ({ editorInstance=null, setEditorInstance=null, data={},isTitleOn=true, editorBoxStyle={}, editorStyle={} }) => {
    const editorReadRef = useRef(null);
    const editor = useRef(null);
    //const blockData = useReportStore(state => state.blockData);
    //console.log("blockData ", blockData)
    useEffect(() => {
        if (editorReadRef.current && editorInstance === null) {
            setEditorInstance(new EditorJS({
            holder: editorReadRef.current,
            minHeight: 0,
            data: data,
            readOnly: true,
            tools: EDITOR_TOOLS,
          }));
        } 
      }, [editorReadRef.current, editorInstance]);

    const defaultEditorStyle = {
        background: '#fff',
        ...editorStyle
    }
    
    //if (blockData.length < 1){
    //    return <></>
    //}
    return (
        <Box mt={'5px'}>
            <Flex 
                {...READ_ONLY_EDITOR_BOX_PROPS}
                overflowY ={'auto'}
                {...editorBoxStyle}
            >
                { isTitleOn &&
                    <Text
                        color={['#273238']}
                        fontSize={['30px']}
                        fontWeight={400}
                    >
                        <FormattedMessage
                            id={'reportDetailsPage.reviewReportModal.reportSection.title'}
                            defaultMessage={'Report Notes'}
                        />
                    </Text>
                }
                <div
                    ref={editorReadRef} 
                    style={defaultEditorStyle} 
                />
            </Flex>     
        </Box>

    );
}
export const ReadOnlyEditorDashboard = ({ editorInstance=null, data, setEditorInstance=null, isTitleOn=true, editorBoxStyle={}, editorStyle={} }) => {
    const editorDashboardRef = useRef(null);

    useEffect(() => {
        if (editorDashboardRef.current && editorInstance === null) {
            setEditorInstance(new EditorJS({
            holder: editorDashboardRef.current,
            minHeight: 0,
            data: data,
            readOnly: true,
            tools: EDITOR_TOOLS,
          }));
        } 
      }, [editorDashboardRef.current, editorInstance]);

    if (!data ){
        return <div />
    }

    const defaultEditorStyle = {
        background: '#fff',
        height: 200,
        ...editorStyle
    }
    return (
        <Box mt={'5px'} id={'readonlyeditordashboard'}>
            <Flex 
                {...READ_ONLY_EDITOR_BOX_PROPS}
                overflowY ={'auto'}
                {...editorBoxStyle}
            >
                { isTitleOn &&
                    <Text
                        color={['#273238']}
                        fontSize={['30px']}
                        fontWeight={400}
                    >
                        <FormattedMessage
                            id={'reportDetailsPage.reviewReportModal.reportSection.title'}
                            defaultMessage={'Report Notes'}
                        />
                    </Text>
                }
                <div
                    key={`editorReadRef`} 
                    ref={editorDashboardRef} 
                    style={defaultEditorStyle} 
                />
            </Flex>     
        </Box>

    );
}