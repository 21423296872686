import React from 'react';
import { MOMENT_DATE_FORMAT } from '../_config';

import cn from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import {
  StyledLabel,
  StyledDayPickerInput,
  ErrorLabel
} from '../_styles/forms';


export const DayPickerAdapter = ({input, meta, dateFormat, items, label, intlLocale, intlMessages, className, disabled, ...rest }) => {
  return (
    <StyledDayPickerInput className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <DayPickerInput
        inputProps = {{
          ...input,
          autoComplete: "off",
          disabled: disabled,
          className:cn(
            "form-control Input_Text", 
            {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)}
          )}
        }
        onDayChange={day=> input.onChange(day)}
        formatDate={formatDate}
        parseDate={parseDate}
        format={dateFormat || MOMENT_DATE_FORMAT || "L"}
        dayPickerProps={{
          locale: "th-TH",
          localeUtils: MomentLocaleUtils,
          month: input.value ? new Date(input.value) : new Date()
        }}
        locale = {intlLocale}
        value = {input.value}
        className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
        placeholder={`${formatDate(new Date(), dateFormat || MOMENT_DATE_FORMAT || "L", intlLocale)}`}
      />
      <ErrorLabel className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
        {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intlMessages[`form.error.${meta.error || meta.submitError}`] : undefined}
      </ErrorLabel>
    </StyledDayPickerInput>
  )}
