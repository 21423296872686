import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Link, useLocation, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import cn from 'classnames';
import { motion, useAnimation } from 'framer-motion';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
//import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Select,  { components }  from 'react-select';
import { Form, Field } from 'react-final-form';
import { upperFirst, find } from 'lodash';
import { Box, Flex, Button } from '@chakra-ui/react';
import { localeFormatDate, formatName, distanceToString, extractInitials } from '../_helpers'

import searchingIcon from '../_images/ClinicSearching.svg';
import emptyResults from '../_images/EmptyClinicResults.svg'


// components
import { ArrowRightIcon } from '../_images/icons/ArrowRightIcon';
import { ClinicDetails} from './ClinicDetails';
import { ClinicMap } from './ClinicMap';
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { actions as modalActions } from '../Modals/_actions';
import { ModalHeader } from '../Modals/ModalHeader';
import { LoadingEllipses } from '../_components/LoadingEllipses';
import { IoSearch } from 'react-icons/io5';
import { LocationPlacesSearch } from './LocationPlacesSearch';

// redux state
import {
  getClinics,
  getCurrentClinicId,
  getDragStarted,
  getCurrentClinic,
  getClinicsLoading,
  getRegionList
} from './_selectors';
import { actions } from './_actions';
import { services } from './_services';

// actions
import { actions as authenticationActions } from '../Authentication/_actions';

// selectors
import { getIntlMessages, getCountry, getAssignedDentist } from '../Authentication/_selectors'
import { useUrlSearchParams } from '../_helpers/useUrlSearchParams';

// styles
import { InitialsAvatar } from '../_styles/common'
import { StyledInput, ErrorLabel } from '../_styles/forms';
import {
  SectionPage,
  SectionTitle,
  StyledSectionWrapper,
  StyledClinicListSection,
  StyledClinicSearch,
  StyledClinicMapSection
} from './styles';
import { CloseIcon } from '../Modals/styles';
import { OverflowTextContainer } from '../_components/OverflowTextContainer';
import Scrollbars from 'react-custom-scrollbars';
import { getRatingAverage } from './ReviewBar';
import { ReviewStars } from './ReviewStars';

const selectStylesOld = {
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    return { ...provided, color };
  },
  valueContainer: (provided, state) => {
    const height = '38px' // add for 1px border
    const justifyContent = 'center';
    return { ...provided, height, justifyContent};
  },
  control: (provided, state) => {
    const borderColor = state.hasValue ? '#36454D' : '#C7D3D9';
    const borderRadius = '100px';
    const height = '40px' // add for 1px border
    return { ...provided, borderColor, borderRadius, height };
  },
  input: (provided, state) => {
    const height = '38px' // add for 1px border
    return { ...provided, height};
  },
  dropdownIndicator: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    const position = 'absolute';
    const right = '3px';
    return { ...provided, color, position, right};
  },
  indicatorSeparator:  (provided, state) => {
    const display = 'none';
    return { ...provided, display };
  }

}
const selectStyles = {
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#405159' : '#C7D3D9';
    return { ...provided, color };
  },
  control: (provided, state) => {
    const border = '1px solid #c7d3d9';
    const borderRadius = 30;
    const paddingLeft = 10;
    const backgroundColor = 'transparent';
    return { ...provided, border, borderRadius, backgroundColor, paddingLeft };
  },
  dropdownIndicator: (provided, state) => {
    const color = '#405159';
    return { ...provided, color };
  },
  indicatorSeparator:  (provided, state) => {
    const backgroundColor = state.hasValue ? '#405159' : '#C7D3D9';
    return { ...provided, backgroundColor };
  },
  /*input: (provided) => {
    const height = 36;
    return {...provided, height}
  }*/

}

export const SelectAdapterNonClearable = ({input, meta, items, label, type, onSelect, intlMessages , className="SelectAdapter", defaultValue, disabled, ...rest }) => {
  let newSelectStyles = selectStyles;
  if ((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)){
    selectStyles.control['borderColor'] = 'red';
    newSelectStyles = {
        ...selectStyles,
        menu: (provided, state) => ({
           ...provided, zIndex: 5 
        }),
        control: (provided, state) => {
          const borderRadius = '100px';
          const height = '40px' // add for 1px border
          const borderColor = 'red';
          const borderWidth = '2px';
          return { ...provided, borderColor, height, borderRadius, borderWidth};
        }
    }
  }
  return(
  <React.Fragment>
    <Select
      {...input}
      styles={newSelectStyles}
      className={ cn(className, {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      onChange={inputValue => {input.onChange(inputValue.value);onSelect(inputValue.value)}}
      options={items}
      isDisabled={disabled}
      isClearable={false}
      isSearchable={false}
      value={find(items, {'value': defaultValue || input.value})}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      {...rest}
    />
    <ErrorLabel
      isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
      className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
      {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) ? intlMessages["registration.form.location.error"] : undefined}
    </ErrorLabel>
  </React.Fragment>
  )
}


const Filter = ({label, items}) => {
  return(
    <div className={'clinicFilter'}>
    </div>
  )
}

/*export const SearchFilters = () => {
  let filters = {
    'gender': [{'male': 'male'}, {'female': 'female'}, {'all': 'all'}]
  }
  return(
    <div className={'clinicFilters'}>
      {filters.map((elem)=>{
        return (
          <Filter
            label={'no'}
            items={elem.items}
          />
        )
      })}
    </div>
  )
}
*/


const IndicatorSeparator = props => ({children, ...rest}) => (
  <components.IndicatorSeparator>

  </components.IndicatorSeparator>
)

const DropdownIndicator = props => (
  <components.DropdownIndicator { ...props}>
    <IoSearch />
  </components.DropdownIndicator>
);

export const SearchBar = ({init, map}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const [ value, setValue ] = useState("");

  const onChange = (e) =>{
    setValue(e);
    var request = {
      placeId: e.value.placeId,
      fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
    };

    var service = new window.google.maps.places.PlacesService();
    service.getDetails(request, (place, status) => {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        console.log("request for place ", place)

      }
    });

  }

  const handleSend = () => {
    // places. convert address to geopoint. bounding box vs specific point
    // dispatch(actions.updateMapCenter(null))
    dispatch(actions.updateMapCenter(null));
    dispatch(actions.searchClinics('geopoint', '13.7288780,100.5700442'))
  }
    return (
      <div className={'clinicSearchBar'}>
        {!map
          ? <div className={'loading'}><LoadingEllipses /></div>
          : <LocationPlacesSearch
                init={init}
                map={map}
                components={{DropdownIndicator, IndicatorSeparator}}
                onChange={onChange}
                styles={selectStyles}
                placeholder={intlMessages['clinicSearchPage.search.searchBar.places.placeholder']}
            />
        }
      </div>
    )
/*
  return(
    <div className={'clinicSearchBar'}>
      <StyledInput
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={'Enter Address'}
        />
    </div>
  )*/
}


export const SearchBarRegionsList = () => {
  const searchParams = useUrlSearchParams();
  let q = searchParams.get("q") || '';

  const dispatch = useDispatch();
  const regions = useSelector(getRegionList);
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);

  useEffect(()=>{
    dispatch(actions.getSearchRegions());
  }, [])


  useEffect(()=>{
    if (q.length > 0){    
      dispatch(actions.searchClinicsByRegion(q))
    }
  }, [q])


  const handleSubmit = (values) => {
    
  }


  const onSelect = (region) => {
    searchParams.set("q", region);
    history.replace({
      pathname: history.pathname,
      search: "?" + searchParams.toString()
    });
    //dispatch(actions.searchClinicsByRegion(region))
  }

  return(
    <Form
      onSubmit={handleSubmit}
      render = {({
        handleSubmit,
        form,
        values
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            >
            <Field
              name="location"
              className={'SelectAdapter'}
              type='settings'
              items={regions
                ? regions.map(item => {
                    return { label: item.region_name, value: item.region_name }
                   })
                : []
              }
              defaultValue={q}
              onSelect={onSelect}
              intlMessages={intlMessages}
              component={SelectAdapterNonClearable}
              placeholder={upperFirst(intlMessages['registration.form.location.label'])}
            />
          </form>
        )
      }}
  />)
}

export const ClinicSearch = ({init, map}) => {
  return(
    <StyledClinicSearch>
      {/*<SearchBar map={map} init={init} />*/}
    <SearchBarRegionsList />
    </StyledClinicSearch>
  )
}

const ClinicItemAvatar = ({src, initials}) => {
  if (src && src.length > 0){
    return (
        <img src={src} />
    )
  } else {
    return (
        <InitialsAvatar>
          {initials}
        </InitialsAvatar>
    )
  }
}


const heightVariant = {
  show: {
    height: 'fit-content',
    transition: { when: "beforeChildren"}
  },
  hidden: {
    height: 0,
    transition: { delay: 0.1}
  }
}
const visibleVariant = {
  show: {
    opacity: 1
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.1}
  }
}

const ClinicItem = ({selected, dentist, onClick, onDetailClick}) => {
  const intlMessages = useSelector(getIntlMessages);
  const dentistCategories = (dentist.categories || "").split(",")

  return(
    <div
      className={cn('clinicSearchItem', {'selected': selected})}
      onClick={() => onClick(dentist)}
    >
      <div className={'content'}>
        <div className={'avatar'}>
          <ClinicItemAvatar
            src={dentist.avatar}
            initials={extractInitials(dentist.name)}
            />
        </div>
        <div className={'details'}>
          <div className={'name'}>{`Dr. ${dentist.name}`}</div>
          <div className={'clinicName'}>{dentist.clinic.name}</div>
          <div className={'clinicAddress'}><OverflowTextContainer textProps={{fontSize: 12}}>{dentist.clinic.address}</OverflowTextContainer></div>
        </div>
        {
          dentist?.rating && getRatingAverage(dentist?.rating) > 0 &&
          (
            <div className={'rating'}>
              <div className={'stars'}><ReviewStars rating={1} numOfStars={1} justify={'center'} /></div>
              <div className={'ratingNumber'}>{getRatingAverage(dentist?.rating)}</div>
            </div>
          )
        }
      </div>
      <div className={'specialty'}>
        {(dentistCategories || []).map((tag) => (
            <div key={`tag_${tag}`}>{tag}</div>
        ))}
      </div>
      <motion.div
        variants={heightVariant}
        animate={selected ? 'show' : 'hidden'}
        className={'extraContent'}
        >
        <motion.div
          variants={visibleVariant}
          animate={selected ? 'show' : 'hidden'}
          >
          <p className={'description'}>{dentist.description || intlMessages['clinicSearchPage.searchList.clinic.detail.description.empty']}</p>
        </motion.div>
        <Flex justifyContent="center">
          <Button 
            variant={'ghost'}
            color={'#405159'}
            fontWeight={'normal'}
            onClick={() => onDetailClick(dentist.id)} 
            rightIcon={<ArrowRightIcon />}
          >
            <span className={'viewMore'}>{intlMessages['clinicSearchPage.searchList.clinic.detail.viewMore']}</span>
          </Button>
          </Flex>
      </motion.div>
    </div>
  )
}

const SearchingClinics = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <div className="searchLoading">
      <img src={searchingIcon} alt="searching" />
      <div>{intlMessages['clinicSearchPage.searchList.loading']}</div>
    </div>
  )
}

const NoResults = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <div className="noResults">
      <img src={emptyResults} alt="empty" />
      <div>{intlMessages['clinicSearchPage.searchList.emptyResults']}</div>
    </div>
  )
}

export const ClinicList = ({init, map, handleDetailClick, onDentistSelected}) => {
  const dispatch = useDispatch();
  const clinics = useSelector(getClinics);
  const currentClinicId = useSelector(getCurrentClinicId);
  const isLoading = useSelector(getClinicsLoading);


  const handleItemClick = (clinic) => {
    dispatch(actions.dragStarted(false));
    dispatch(actions.selectClinic(clinic.unique_id));
    //dispatch(actions.updateMapCenter(clinic.clinic.geopoint.lat, clinic.clinic.geopoint.lon))
  }

  const onDetailClick = (id) => {
    handleDetailClick(id);
  }

  return(
    <StyledClinicListSection>
      <Scrollbars
        autoHide
      >
        <div className={'clinicSearchList'}>
          { isLoading
            ? <SearchingClinics />
            : (clinics.length == 0)
              ? <NoResults  />
              : clinics.map((elem) =>
                  <ClinicItem
                    selected={currentClinicId==elem.unique_id}
                    key={elem.unique_id}
                    dentist={elem}
                    onClick={handleItemClick}
                    onDetailClick={onDetailClick}
                    />
                )
          }
        </div>
      </Scrollbars>
    </StyledClinicListSection>
  )
}

export const ClinicSearchPage = ({handleClinicDentistSelected}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const clinicsRouteMatch = useRouteMatch("/createrequest/clinics");
  const clinicDetailRouteMatch = useRouteMatch("/createrequest/clinics/id/:clinicId");
  const mapRegion = useSelector(getCountry)
  const assignedDentist = useSelector(getAssignedDentist);
  const currentClinicId = useSelector(getCurrentClinicId);
  const currentClinic = useSelector(getCurrentClinic);
  const [ map, setGoogleMap] = useState(null);

  // anything > -1 is a detail page
  const PAGE = {
    SEARCH: -1,
    DETAIL: 1
  }

  const init = useRef(false);

  useEffect(()=>{
    return () => {}; // unmount
  }, [])


  const setInit = (bool) => {
    init.current = bool;
  }

  const onMarkerClick = () => {
  }

  const setDentist = (id) => {
    history.push({
      pathname: "/createrequest/clinics/id/" + id
    })
  }


  const onDentistSelected = () => {
    handleClinicDentistSelected(currentClinicId, currentClinic.id);
  }

  const handleBackClick = () => {
    history.replace({
      pathname: "/createrequest/clinics"
    })
  }


  const closeModal= () => {
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push({
      pathname: "/"
    });
  }

  if (assignedDentist){
    return (<Redirect to="/createrequest/scan" />)
  }

  return (
    <>
      <ModalHeader
        title={intlMessages['clinicSearchPage.header']} 
      />
      <SectionPage>
      <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        {
          (clinicsRouteMatch.isExact)
          ? (<><SectionTitle>{intlMessages['clinicSearchPage.title.location']}</SectionTitle>
            <StyledSectionWrapper>
            { mapRegion
              ?
                <>
                  <div className='mapColumn'>
                    <ClinicSearch map={map} init={init} />
                    <ClinicMap
                      init={init}
                      setGoogleMap={setGoogleMap}
                      onMarkerClick={onMarkerClick}
                      country={mapRegion}
                      />
                  </div>
                  <div className='searchColumn'>
                    <ClinicList
                      init={init}
                      map={map}
                      handleDetailClick={setDentist}
                    />
                    {currentClinicId && 
                      (<motion.div className="selectButtonContainer">
                          <button className="selectButton" 
                          onClick={onDentistSelected}>
                            {intlMessages['tidbits.next']}
                          </button>
                        </motion.div >)
                    }
                    </div>
                  </>
                :
                <div className="loading">
                  <LoadingEllipses />
                </div>
              }
            </StyledSectionWrapper></>
            )
            : (clinicDetailRouteMatch?.params?.clinicId) 
              ?(
                <ClinicDetails 
                  onBackClick={handleBackClick}
                />
              )
              : (<div />)
        }
        
      </SectionPage>
    </>
  )
}
