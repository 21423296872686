import { API_URLS } from '../_config';
import { setUserId } from 'firebase/analytics';
import { analytics } from '../_helpers';
import { services } from './_services';
import { constants } from './_constants';
import { useHistory } from 'react-router-dom';

import { constants as modalConstants } from '../Modals/_constants';
import storage from '../_helpers/storage';

export const actions = {
    login,
    logout,
    getProfile,
    getProfiles,
    getPayments,
    selectProfile,
    getSettingsPayment,
    getChatInitial,
    removeProfile,
    updateObject
};

function login(email, password, redirectURL) {
    return dispatch => {
        dispatch(request({ email }));

        services.login(email, password)
            .then(
                user => {
                    dispatch(success(user));
                    //history.push(redirectURL);
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: constants.LOGIN_REQUEST, user } }
    function success(user) { return { type: constants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: constants.LOGIN_FAILURE, error } }
};

function loginSocial(code, provider){
  return dispatch => {
    dispatch(request());
    services.loginSocial(code, provider)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
        }
      )
  };
  function request(user) { return { type: constants.LOGIN_REQUEST, user } }
  function success(user) { return { type: constants.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: constants.LOGIN_FAILURE, error } }
}

function getProfile() {
  return dispatch => {
    dispatch(request());
    services.getProfile()
      .then(
        resp => {
          if (resp?.chat?.uuid){
            setUserId(analytics, resp?.chat?.uuid);
          }
          // set dental type user or not in the application
          storage.setIsDentalType(resp?.is_dental_type)
          storage.setIsOrphanage(resp?.is_orphanage)
          dispatch(success(resp));
        },
        error => {
          dispatch(failure(error));
        }
      )
  }

  function request() { return { type: constants.USER_PROFILE_REQUEST } }
  function success(data) { return { type: constants.USER_PROFILE_SUCCESS, data } }
  function failure(error) { return { type: constants.USER_PROFILE_FAILURE, error } }
}

function getProfiles(query='') {
  return dispatch => {
    dispatch(request());
    services.getProfiles(query)
      .then(
        resp => {
          dispatch(success(resp));
        },
        error => {
          dispatch(failure(error));
        }
      )
  }

  function request() { return { type: constants.USER_PROFILES_REQUEST } }
  function success(data) { return { type: constants.USER_PROFILES_SUCCESS, data } }
  function failure(error) { return { type: constants.USER_PROFILES_FAILURE, error } }
}

function removeProfile(unique_id, callback=null) {
  return dispatch => {
    services.removeProfile(unique_id)
      .then(
        resp => {
          dispatch(success(unique_id, resp));
          callback && callback();
        },
        error => {
          dispatch(failure(error));
        }
      )
  }
  function success(unique_id, data) { return { type: constants.USER_PROFILESREMOVE_SUCCESS, data : {unique_id, ...data} } }
  function failure(error) { return { type: constants.USER_PROFILESREMOVE_FAILURE, error } }
}

function updateObject(obj){
  return dispatch => {
    dispatch(update(obj));
  }
  function update(obj){ return { type: constants.UPDATE_OBJECT, data: {key: obj.key, value: obj.value} } }
}

function selectProfile(profile){
  return dispatch => {
    dispatch(update(profile));
  }
  function update(data){ return { type: constants.SELECT_PROFILE, data } }
}

function getChatInitial() {
  return dispatch => {
    dispatch(request());
    services.getChatInitial()
      .then(
        resp => {
          dispatch(success(resp));
        },
        error => {
            dispatch(failure(error));
        }
      )
  }

  function request() { return { type: constants.USER_CHAT_INITIALIZATION_REQUEST } }
  function success(data) { return { type: constants.USER_CHAT_INITIALIZATION_SUCCESS, data } }
  function failure(error) { return { type: constants.USER_CHAT_INITIALIZATION_FAILURE, error } }
}

function getSettingsPayment(return_url=null){
  return dispatch => {
    dispatch(request());
    services.getPaymentSetting(return_url)
      .then(
        resp => {
          dispatch(success(resp));
        },
        error => {
            dispatch(failure(error));
        }
      )
  }

  function request() { return { type: constants.USER_SETTINGS_PAYMENTS_REQUEST } }
  function success(data) { return { type: constants.USER_SETTINGS_PAYMENTS_SUCCESS, data: {payment: data} } }
  function failure(error) { return { type: constants.USER_SETTINGS_PAYMENTS_FAILURE, error } }
}

function getPayments(){
  return dispatch => {
    dispatch(request())
    services.fetchPayments()
      .then(
        resp => {
          dispatch(success(resp));
        },
        error => {
          dispatch(failure(error));
        }
      )
  }
  function request() { return { type: constants.USER_PAYMENTS_REQUEST}}
  function success(data) {
     return { type: constants.USER_PAYMENTS_SUCCESS, data: {payments: data.cards} }
   }
  function failure(error) { return { type: constants.USER_PAYMENTS_FAILURE, error } }
}

function logout() {
  return dispatch => {
    services.logout().then(
      response => {
        localStorage.removeItem('user');
        return
      }
  );
    dispatch({ type: constants.LOGOUT_SUCCESS })
  }
};
