import styled from 'styled-components';
import { StyledContent, StyledContentTitle, StyledContentSection } from '../_styles/common';
import { StyledInput } from '../_styles/forms';

export const StyledClinicMapSection = styled.div`
  position: relative;
  z-index: 1;

  .searchAgain{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    text-transform: uppercase;
    font-size: 15px;
    margin-top: 10px;

    div{
      text-align:center;
      margin: 5px auto;
      color: white;
      background: #273238;
      border-radius: 25px;
      text-align:center;
      width: fit-content;
      padding: 8px 18px;
      box-shadow: rgb(0 0 0 / 12%) 0px 3px 12px 0px;
      &:hover{
        cursor:pointer;
      }
    }
  }

  .loading{
    height: 100%;
    width: 100%;
    background-color: #EFEFEF;
    display:flex;
    align-items:center;
    justify-content:center;
  }

`;

export const StyledClinicSearch = styled.div`
  
  .clinicSearchBar{
    padding: 0 15px;
    ${StyledInput}{
      background: #EDEDED;
      border-radius: 78px;
      width: 100%;
      &:focus{
        outline: none;
      }
    }
  }

  .SelectAdapter{
    z-index:2;
  }

  .clinicFilters{}
  .clinicFilter{}
`;

export const StyledClinicListSection = styled.div`
  height: 100%;
  overflow: overlay;
  background-color: #F7F7F7;
  padding-bottom: 110px;
  position: relative;
  


  button.viewMore{
    border: 1px solid #6B8A99;
    color: #6B8A99;
    border-radius: 30px;
    background: transparent;
    padding: 8px 40px;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;

  }



  .clinicSearchList{
    padding-right: 10px;
    padding-bottom: 20px;
    height: 100%;

    .noResults{
      height: 100%;
      display:flex;
      flex-direction: column;
      justify-content:center;
      align-items:center;
      width: 100%;
      color: #6b8a99;
      text-transform: uppercase;
      > img {
        padding: 20px;
      }
    }

    .searchLoading {
      height: 100%;
      display:flex;
      flex-direction: column;
      justify-content:center;
      align-items:center;
      width: 100%;
      color: #6b8a99;
      text-transform: uppercase;
      > img {
        padding: 20px;
      }
    }

    .clinicSearchItem{
      display:flex;
      flex-direction: column;
      border-radius: 20px;
      background: #FFF;
      margin-top: 10px;
      padding: 20px;
      padding-left: 25px;
      overflow:hidden;

      &:last-child{
        margin-bottom: 100px;
      }

      .content{
        display:grid;
        grid-template-columns: 2fr 10fr 2fr;
        grid-rows: 1fr;

        .rating{
          border-left: 1px solid #C7D3D9; 
          padding-left: 15px;
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .stars{
            margin: 0px;
          }

          .ratingNumber{
            color: #6b8a99;
            margin-top: 2px;
            font-size: 18px;
            text-align: center;
          }
        }
      }

      .extraContent{
        padding: 0 10px;
        color: #273238;
        div{
          .address{
            font-family: 'Proxima Nova';
            font-size: 15px;
          }
          .description{
            font-family: 'Proxima Nova';
            font-size: 15px;
            padding: 20px 0;
          }
        }

        span.viewMore{
          border-bottom: 1px solid #405159;
          line-height: 20px;
        }
      }

      &:hover{
        cursor: pointer;
        transform: translateY(-1px);
      }

      &.selected{
        
        border: 1px solid #3299E3;
        &:hover{
          cursor:default;
          opacity: 1;
        }
      }

      .avatar{
        display:flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 60px;
          height: 60px;
        }
      }
      .details{
        padding: 0 10px;
        padding-left: 15px;
        overflow:hidden;
        .name{
          color: #12171A;
          font-size: 20px;
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .clinicName{
          color: #6b8a99;
          font-size: 15px;
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
        }
        .clinicAddress{
          color: #6b8a99;
          font-size: 12px;
          font-family: Proxima Nova;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
        }
      }
      .specialty{
        display:flex;
        margin-top: 12px;

        div{
          margin-top: 5px;
          margin-right: 5px;
          font-family: 'Proxima Nova';
          font-size: 11px;
          border-radius: 100px;
          color: #6B8A99;
          width: fit-content;
          padding: 4px 10px;
          border: 1px solid #6B8A99;
          text-transform: uppercase;
        }
      }
      .distance{
        font-family: 'Proxima Nova';
        font-size: 12px;
        color: #A3B6BF;
        text-align:right;
      }
    }
  }
`;

export const StyledReviewItem = styled.div`
  padding: 0 24px;
  .header{
    display:flex;
    justify-content:space-between;
    align-items:center;

    .reviewer {
      color: #12171A;
      font-size: 17px;
      font-family: Proxima Nova;
    }

    .time{
      color: #7A8F99;
      text-align: right;
      font-size: 12px;
      font-family: Proxima Nova;
    }
  }

  .comment{
    color: #405159;
    font-size: 15px;
    font-family: Proxima Nova;
    margin-top: 5px;
  }
`;

export const StyledReviews = styled.div`
  margin-top: 25px;
  ${StyledReviewItem}{
    &:not(:first-child){
      border-top: 1px solid #C7D3D9;
    }
    padding: 20px 5px; 

    .stars{
      padding-bottom: 5px;
      svg {
        height: 12px;
        width: 12px;
      }
    }
  }
  .loadMore{
    width: 100%;
    position: relative;
    display:flex;
    justify-content:center;
    align-items:center;
    padding: 0px 10px;
    margin-top: 10px;

    > button {
      width: 100%;
      border-radius: 30px;
      border: 1px solid #405159;
      color: #405159;
      text-align: center;
      font-family: Proxima Nova;
      font-size: 15px;
      padding: 8px 0;
      text-transform: uppercase;
    }
  }
`

export const StyledClinicDetailsSection = styled(StyledContentSection)`
  display: block;
  background-color: #F7F7F7;
  overflow: overlay;
  height: 100%;
  width: 100%;
  position: relative;
  font-family: Proxima Nova;

  .goBack{
    height: 30px;
    letter-spacing: 1px;
    color: #394B55;
    display:flex;
    align-items:center;
    color: #394B55;
    font-size: 15px;
    text-transform: uppercase;

    &:before{
      content: "<";
      font-size: 18px;
      margin-right: 6px;

    }

    &:hover{
      cursor:pointer;
    }
  }

  .title{
    color: #273238;
    font-size: 40px;
    font-family: Proxima Nova;
    line-height: 50px;
  }

  .title2{
    color: #273238;
    font-size: 25px;
    font-family: Proxima Nova;
  }

  .clinicDetails{
    display:grid;
    height: calc(100% - 30px);
    grid-template-columns: 3fr 7fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    padding-top: 10px;

    .avatarColumn{
      padding-right: 15px;
    }
  
    .detailsColumn{
      padding-right: 15px;
      padding-bottom: 20px;
    }
  
    .avatar{

      img {
        width: 100%;
        height: auto;
      }
    }

    .reviewList {
      display:flex;
      flex-direction: column;
      margin-top: 30px;


      .summary{
        display:grid;
        grid-template-columns: 1fr 1px 1fr;
        grid-gap: 20px;
        margin-top: 15px;

        .rating{
          color: #12171A;
          text-align: right;
          font-size: 40px;
          line-height: 48px;
        }

        .border{
          height: 100%;
          background-color: #C7D3D9;
        }

        .bars{
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          padding-right: 8px;
          
          .bar{
            &:not(:first-child){
            }

            color: #405159;
            font-family: Proxima Nova;
            font-size: 11px;

            display:grid;
            grid-template-columns: 2fr 9fr;
            grid-template-rows: 1fr;
          }
        }

        .totalReviews{
          margin-top: 4px;
          color: #6B8A99;
          text-align: right;
          font-size: 13px;
          font-family: Proxima Nova;
        }

      }

      

    }

    .clinicName{
      color: #6B8A99;
      font-size: 15px;
      font-family: Proxima Nova;
    }

    .description{
      color: #405159;
      font-size: 15px;
      font-family: Proxima Nova;
      line-height: 24px;
      margin-top: 20px;
    }

    .specialty{
      display:flex;
      margin-top: 8px;

      div{
        margin-top: 5px;
        margin-right: 5px;
        font-family: 'Proxima Nova';
        font-size: 13px;
        border-radius: 100px;
        color: #6B8A99;
        width: fit-content;
        padding: 4px 10px;
        border: 1px solid #6B8A99;
        text-transform: uppercase;
      }
    }

    .map{
      margin-top: 15px;
      .address{
        color: #6B8A99;
        font-size: 15px;
        font-family: Proxima Nova;
        padding-bottom: 12px;
      }
    }

  }

  .loading{
    position:absolute;
    top: 0;
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    height: 100%;
    z-index: 100;
    background-color: #F7F7F720;
  }

`;

export const StyledSectionWrapper = styled.div`
  height: 100%;
  width: 100%;
  display:grid;
  background-color: #F7F7F7;
  grid-template-columns: 10fr 9fr ;
  grid-template-rows: 1fr;
  grid-gap: 30px;

  > div{
    height: 100%;
    width: 100%;
    position: relative;

    &.searchColumn{
      overflow:overlay;
    }
  }

  .selectButtonContainer{
    width: 100%;
    background-color: #F7F7F7;
    display:flex;
    justify-content:center;
    align-items:center;
    position: absolute;
    bottom: 0;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-top: 10px;

    button{
      width: 100%;
      border-radius: 30px;
      background: #44C5A6;
      color: #405159;
      text-align: center;
      font-size: 15px;
      font-family: Proxima Nova;
      padding: 10px;
      text-transform: uppercase;
      text-overflow: ellipsis;
    }
  }
`;

export const SectionTitle = styled(StyledContentTitle)`
  color: #273238;
  font-size: 40px;
  font-family: Proxima Nova;
  padding: 0 10px;
`; // content__section--wrap


export const SectionPage = styled.div`
  width:1000px;
  height:700px;
  background-color: #F7F7F7;
  padding: 50px 40px;
  padding-right: 30px;
  padding-top: 24px;

`; // content report
