import { Button } from '../_styles/common';
import styled from 'styled-components';
import {blink} from '../_styles/anim';

export const TakePhotoButton = styled(Button)`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  margin: 10px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const RecordButton = styled(Button)`
  background-color: red;
  margin: 10px 15px;
`;

export const StopButton = styled(Button)`
  background-color: red;
  margin: 10px 15px;
`;

export const PhotoButton = styled(Button)`
  background-color: blue;
  margin: 10px 15px;
`;

export const NextRegionButton = styled(Button)`
  background-color: #273238;
  margin: 10px 15px;
`;

export const RedoRegionButton = styled(Button)`
  background-color: purple;
  margin: 10px 15px;
`;

export const StyledCameraTextButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display:flex;
  align-items:center;
  justify-content:center;
  background-color: #273238;

  > span{
    text-transform: uppercase;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.8px;

    color: #FFFFFF;
     &:hover {
      cursor: ${props => props.btnDisabled ? 'default' : 'pointer' };
    }
  }

  &:hover{
    cursor: ${props => props.btnDisabled ? 'default' : 'pointer' };
  }
`;

export const StyledTextButton = styled.div`
  font-family: Proxima Nova Bold;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  width: 170px;
  letter-spacing: 1px;
  padding: 20px 25px;
  text-transform: uppercase;
  color: ${props => props.btnDisabled ? '#C4C4C4' : '#273238' };

  &:last-child{
    text-align:right;
  }

  > span:hover {
    cursor: ${props => props.btnDisabled ? 'default' : 'pointer' };
  }

  &:hover{
    cursor: ${props => props.btnDisabled ? 'default' : 'pointer' };
  }
`;

export const StyledCameraButton = styled.div`
  width: 40px;
  height: 40px;

  &:hover{
    cursor: ${props => props.btnDisabled ? 'default' : 'pointer' };
  }

  svg path {
    &.outerCircle{
      stroke: ${props => props.btnDisabled ? '#C4C4C4' : '#273238' };
    }
  }
`;

export const FlipButton = styled(Button)`
  background-color: lightgray;
  margin: 10px 15px;
`;


export const VideoToggleButton = styled.div`
  ${Button}{
    width:
  }
`;

export const ButtonSection = styled.div`
  display:flex;
  justify-content: center;
  background: #FFFFFF;
  height: 60px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const CameraButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;

`;

export const StyledSelfScanButtons = styled(ButtonSection)`

`;

export const StyledPartialScanButtons = styled(ButtonSection)`
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  > * {
    &:first-child {width: 200px;}
    &:last-child {width: 200px;}
  }
`;

export const StyledDetectionScanButtons = styled(ButtonSection)`
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  > * {
    &:first-child {width: 200px;}
    &:last-child {width: 200px;}
  }
`;

export const StyledFullScanButtons = styled(ButtonSection)`
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  > * {
    &:first-child {width: 200px;}
    &:last-child {width: 200px;}
  }
`;

export const Canvas = styled.canvas`
  display: none;
`;

export const StyledLivePreview = styled.video`
  vertical-align:top;
  background: #12171A;
  min-height:480px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transform: rotateY(${({ mirrored }) => (mirrored ? '180deg' : '0deg')});
`;

export const VideoLoading = styled.div`
  width: 640px;
  height: 480px;
  min-height: 480px;
  background: #12171A;
  color: white;
  display:flex;
  align-items:center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const StyledProgressRail = styled.div`
  background-color: ${ ({progress}) => (progress > 0) ? '#C4C4C4': 'transparent'};
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 6px;
`;

export const StyledProgressBar = styled.div`
  visibility: ${ ({progress}) => (progress > 0) ? 'visibile': 'hidden'};
  width: ${({progress}) => (progress + '%')};
  position: absolute;
  background-color: #367FFF;
  bottom: 0;
  height: 6px;
`;


export const StyledProgressText = styled.div`
  padding: 5px 15px;
  border-radius: 50px;
  background-color: #00000021;
`;

export const StyledProgressTextContainer = styled.div`
  visibility: ${ ({progress}) => (progress > 0) ? 'visibile': 'hidden'};
  margin-top:-60px;
  height: 60px;
  font-size: 14px;
  display:flex;
  width: 100%;
  font-family: Proxima Nova Semibold;
  color: white;
  justify-content: center;
  align-items:center;
`;

export const StyledUploadProgress = styled.div`
  pointer-events: none;
  position: relative;
  z-index:2;
`;

export const StyledPloverCamera = styled.div`
  vertical-align:top;
  height:540px;
  min-height:540px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .videoContainer{
    position:relative;

    .videoTimerContainer{
      position:absolute;
      width:100%;
      top: 0px;
      height: fit-content;
      display:flex;
      justify-content: center;
      z-index: 10;
      margin-top: 10px;


      .recordingTimer{
          padding: 8px;
          background-color: rgba(0,0,0,0.3);
          border-radius: 50px;
          width: fit-content;
          color: white;
          display:flex;
          align-items:center;

          .recordingDot{
            width: 15px;
            height: 15px;
            background-color: red;
            border-radius: 50%;
            animation: ${blink} 2s infinite;
            margin-right: 8px;
          }

          .recordingTimerText{
            line-height: 14px;
          }

      }
    }
  }
`;
