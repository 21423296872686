
import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { forEach, startCase } from 'lodash';
import { Flex, Text, Circle, Tabs, Tab, TabList, Box } from '@chakra-ui/react';
import Select from 'react-select';
import { find } from 'lodash';

import { SearchInput } from '../Settings/ProfileList';

import { getIntlLocale, getIntlMessages, getProfiles } from "../Authentication/_selectors";

import { COLORS_CATEGORIES, REPORTCHART_TYPES } from '../_config';

import { actions } from './_actions';
import { constants } from './_constants';

import { calculateSelectWidth } from '../_helpers/select';
import { formatName } from '../_helpers/utils';
import { getChartCategory } from './_selectors';

import { actions as authActions } from '../Authentication/_actions';

import {
  StyledLabel,
  StyledInputLayout,
} from '../_styles/forms';
import { useReportStore } from './state/report';

// custom select component

const background = 'transparent';
const fontSize = '16px';
const height = '36px';
const borderRadius = "30px";
const boxSizing = 'border-box';
const border = '1px solid #405159'
const float = 'right';
const boxShadow = '0px 1px 6px 0px rgba(200, 201, 211, 0.60)';
const settingsControlStyles = {
  background, fontSize, height, borderRadius, boxSizing, border, float
}
const settingsStyles = {
  container: (provided, state) => {
    const width = '100%';
    let opacity = state.isDisabled ? 0.3 : 1;
    return { ...provided, width, opacity};
  },
  singleValue: (provided, state) => {
    //if (state?.getValue()[0].value == null){
    //  console.log("get rid of the dot?")
    //}
    const color = state.hasValue ? '#273238' : '#C7D3D9';
    const paddingLeft = '7px'
    return { ...provided, color, paddingLeft };
  },
  control: (provided, state) => {
    const background = 'transparent';
    const fontSize = '16px';
    const height = '36px';
    const borderRadius = "30px";
    const boxSizing = 'border-box';
    const border = '1px solid #405159'
    const boxShadow = '0px 1px 6px 0px rgba(200, 201, 211, 0.60)';
    
    return { ...provided, background, fontSize, height, borderRadius, boxSizing, border};
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      paddingLeft: "7px",
      color: "#999",
    }
  },
  dropdownIndicator: (provided, state) => {
    const color = '#000';
    return { ...provided, color };
  },
  indicatorSeparator:  (provided, state) => {
    const backgroundColor = '#F9F9F9';
    return { ...provided, backgroundColor };
  },
  menu: (provided, state) => {
    const zIndex = 1080;
    return {...provided, zIndex}
  }
}

const OptionLabel = ({cat, px='8px'}) => {
    let color = COLORS_CATEGORIES.OTHERS
    // cat is null is ALL
    //console.log("OptionLabel ", cat)
    if (!cat){
      return (
          <Flex
              justifyContent={'center'}
              align={'center'}
              px={px}
          >
              <Circle 
                  boxSize={'10px'}
                  backgroundColor={'transparent'}
              />
          </Flex>
      )
    }

    switch(cat){
        case 'COLOR':
            color = COLORS_CATEGORIES.COLOR
            break;
        case 'ORTHO':
            color = COLORS_CATEGORIES.ORTHO
            break;
        case 'HABITS':
            color = COLORS_CATEGORIES.HABITS
            break;
        case 'GUMS':
            color = COLORS_CATEGORIES.GUMS
            break;
        case 'CARIES':
            color = COLORS_CATEGORIES.CARIES
            break;
        case 'CALCULUS':
            color = COLORS_CATEGORIES.GUMS
            break;
        default:
            // OTHERS 
            break;
    }
    return (
        <Flex
            justifyContent={'center'}
            align={'center'}
            px={px}
        >
            <Circle 
                boxSize={'10px'}
                backgroundColor={color}
            />
        </Flex>
    )
}
const formatOptionLabelWithInput = ({value, label, data, ...props}) => {
    //console.log("value, label, data", value, label, data)
    if (value?.length === 0 || value === '0' || value === 0){
      return(
        <Flex >
            <Text
                color={'#3E5159'}
                fontFamily={'Proxima Nova'}
                fontSize={'14px'}
            >
                {'ALL'}
            </Text>
        </Flex>
      )
    } else {
        return (
        <Flex py={'6px'}>
            <OptionLabel cat={value} />
            <Text
                color={'#3E5159'}
                fontFamily={'Proxima Nova'}
                fontSize={'14px'}
                textTransform={'uppercase'}
            >
                {label}
            </Text>
        </Flex>
    )}
}

const SelectAdapterNonClearable = (
  {meta, items, label, type,  intlMessages , onChange, isLabelShowing=true,
    className="SelectAdapter", defaultValue, disabled, menuHeight='auto', width='100%',...rest }) => {
  return(
    <StyledInputLayout className={className}>
      { isLabelShowing && <StyledLabel>{label}</StyledLabel>}
      <Select
        styles={{
          ...settingsStyles, 
          control: (provided) => ({...provided, ...settingsControlStyles, width}),
          menuList: (provided) => ({...provided, minHeight: menuHeight})
        }}
        options={items}
        isDisabled={disabled}
        clearable={false}
        searchable={false}
        isSearchable={false}
        formatOptionLabel={formatOptionLabelWithInput}
        value={find(items, {'value': defaultValue})}
        onChange={(value) => onChange(value?.value)}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        {...rest}
      />
    </StyledInputLayout>
  )
}

export const CategoriesFilterSelect = ({isDisabled=false, categoriesCount={}, isLabelShowing=true}) => {
  const { category : chartCategory, setCategory } = useReportStore();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const profiles = useSelector(getProfiles);
  // overwriting styles for tabs
 
  const handleChange = (cat) => {
    setCategory(cat);
  };

  let items = [];
  let maxLength = 50;
  // add category to the items list if report has any
  REPORTCHART_TYPES.map((cat) => {
    if (categoriesCount[cat.value] > 0){
      items.push({label: intlMessages[`toothChart.category.${cat.value}`], value: cat.value})
      if (calculateSelectWidth(intlMessages[`toothChart.category.${cat.value}`], intlLocale) > maxLength){
        maxLength = calculateSelectWidth(intlMessages[`toothChart.category.${cat.value}`], intlLocale)
      }
    } else {
      // remove thsi when it is implementsed
      //items.push({label: intlMessages[`toothChart.category.${cat.value}`], value: cat.value})
      //if (getCategoryLength(intlMessages[`toothChart.category.${cat.value}`]) > maxLength){
      //  maxLength = getCategoryLength(intlMessages[`toothChart.category.${cat.value}`])
      //}
    }
  })

  items.unshift({label: intlMessages[`toothChart.category.ALL`], value: null})

  const currentCategoryLength = calculateSelectWidth(intlMessages[`toothChart.category.${chartCategory}`] || "ALL", intlLocale)
  const menuHeight = items.length*36 + 90;


  return (
      <Box pb={'10px'} w={ `${maxLength}px` }>
          <SelectAdapterNonClearable 
            name={'category'}
            className={'SelectAdapter'}
            width={`${currentCategoryLength}px`}
            menuHeight={`${menuHeight}px`}
            items={items}
            autosize={true}
            isDisabled={isDisabled}
            defaultValue={chartCategory}
            onChange={handleChange}
            isLabelShowing={isLabelShowing}
          />
      </Box>
  )
}