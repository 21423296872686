import { GOOGLE_CLIENT_ID, FACEBOOK_CLIENT_ID } from '../_config';
import React, { useState, useEffect, useRef }  from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { Flex, Image, Text, Link, Button, Modal, ModalHeader, ModalCloseButton, ModalContent, ModalOverlay, ModalBody } from '@chakra-ui/react';
import { toUpper, upperFirst } from 'lodash';

import logo from '../_images/logoPlover.png';
import { required } from '../_helpers/utils';

import googleIcon from '../_images/GoogleLogo.svg'
import facebookIcon from '../_images/FacebookIcon.svg';

import { LoadingEllipses } from '../_components/LoadingEllipses';

import { getIntlMessages, getIsLoggedInFlag, getIsChatLoaded } from '../Authentication/_selectors';

import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

import { LineLoginContainer } from './LineLoginContainer';

import { customAlphabet } from 'nanoid';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { constants as authConstants } from '../Authentication/_constants';
import { actions } from '../Authentication/_actions';

import { actions as appsettingsActions } from '../Settings/_actions';
import { services } from '../Authentication/_services';

import {
  StyledInputLayout, StyledLabel, StyledInput,
  FormHeader, StyledButton, errorCss, ErrorLabel
} from '../_styles/forms';
import {
  Wrapper,
  StyledCompanyLogo,
  StyledSpacing,
  StyledForgotPasswordLink,
  StyledActivateLink,
  StyledForm,
  StyledGoogleButton,
  StyledFacebookButton,
  StyledLoginButton,
  StyledLoadingButtonLoading
} from './styles';
import storage from '../_helpers/storage';
import { getCountries, getRegions } from '../Settings/_selectors';
import { CountrySelector } from './CountrySelector';
/*
 * functions
 */

function register(query, statusQuery, offset=0, dpid) {
   //const requestUrl = API_URLS.getDentistRequestList;
   //const requestOptions = {
   //   method: requestUrl.method,
   //   headers: { ...authHeader()}
   //};
   //return fetch(url, requestOptions).then(handleResponse);
 };

 export const FacebookButton = () => {
   return (
     <div></div>
   )
 }

export const GoogleButton = () => {
  return (
    <div></div>
  )
}

const getCountryFromLocale = () => {
    let newCountry = 'jp';
    for (let i = 0; i < navigator.languages.length - 1; i++) {
      if (navigator.languages[i].toLowerCase().startsWith('th')) {
        newCountry = 'th';
        break;
      } else if (navigator.languages[i].toLowerCase().startsWith('ja')) {
        newCountry = 'jp';
        break;
      }
      return newCountry;
    }
}

export const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const [isLoading, setIsLoading ] = useState(false);
  const [user, setUser ] = useState(null);
  const [country, setCountry ] = useState(storage.getCountry() ? storage.getCountry()  : getCountryFromLocale());
  const loggedinFlag = useSelector(getIsLoggedInFlag);
  const isChatLoaded = useSelector(getIsChatLoaded);
  const [verificationCode, setVerificationCode ] = useState('');
  const countries = useSelector(getCountries);
  const [lineError , setLineError ] = useState(false);
  const [lineLoading, setLineLoading]  = useState(false);
  const nanoid = customAlphabet(
    'useandom26T198340PX75pxJACKVERYMINDBUSHWOLFGQZbfghjklqvwyzrict',
    15
  );
  const state = nanoid();

  useEffect(()=>{
    dispatch(appsettingsActions.fetchAppSettings('countries'));
    return () => {};
  }, [])

  useEffect(()=> {
      // fetch user information if logged in on mount
      if (localStorage.getItem('user')){
        dispatch(actions.getProfile());
      } else {
        console.log("USER LOCAL STORAGE NOT FOUND")
      }
      return () => {};
  }, [loggedinFlag])

  useEffect(()=>{
    if (isChatLoaded){
      // user is logged in, so redirect,

    }
  }, [isChatLoaded])

  // setup LINE
  let fullURL = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) {
    fullURL += `:${window.location.port}`;
  }
  const lineRedirectUri = `${fullURL}/login`

  /** set line data state after LINE redirect and back into our app (saving for phone input popup) */
  const setIdToken = async (data) => {
    setLineLoading(true)
    const values  = {
      code: data?.code,
      social_app: "LINE",
      social_app_id: data?.socialAppId,
    }

    try {
      const user = await services.loginLineSocial(values);
      setLineLoading(false)
      dispatch( { type: authConstants.LOGIN_SUCCESS, data: user});
      if (history.location.state != null && history.location.state.from != null){
        history.push(history.location.state.from.pathname)
      } else {
        history.push('/');
      }
    } catch (err) {
      console.log(err);
      setLineError(err.message)
      setLineLoading(false)
      return {
        ...err,
        [FORM_ERROR] : upperFirst("general error")
      }
    }
  };

  const handleLogin = async (values) => {
    try {
      const user = await services.login(values);
      dispatch( { type: authConstants.LOGIN_SUCCESS, data: user});
      if (history.location.state != null && history.location.state.from != null){
        history.push(history.location.state.from.pathname)
      } else {
        history.push('/');
      }
    } catch (err) {
      console.log(err);
      return {
        ...err,
        [FORM_ERROR] : upperFirst("general error")
      }
    }
  }

  const handleSwitchCountry = () => {
    // remove from storage and set country as none
    storage.clearCountry();
    setCountry(null);
  }

  const renderLoginForm = () => {
      return(
        <StyledForm>
        <Flex justifyContent={'center'}>
            <Image src={logo} alt="logo" w={'150px'} h={'30px'} />
        </Flex>
       
          <Flex mt={['20px']} direction={['column']} align={['center']}>
           {/*
          <Modal isOpen={country == null} isCentered>
            <ModalOverlay />
            <ModalContent p={['50px 80px']}>
              <ModalHeader>{intlMessages['registration.intial.title']}</ModalHeader>
              <ModalBody py={['30px']}>
                <CountrySelector countries={countries} setCountry={setCountry}/>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Flex mt={[4]} pb={[2]} w={['full']} justifyContent={'center'}>
            <Text mr={2}>{intlMessages[`settings.countries.${toUpper(country)}.label`]}</Text>
            <Link onClick={handleSwitchCountry}>{intlMessages['registration.basicinformation.switchcountry']}</Link>
          </Flex>
          <LineLoginContainer 
              country={country}
              loginType={'login'}
              state={state}
              setIdToken={setIdToken}
              locale={country == "TH" ? "th" : "ja"}
              redirectUri={lineRedirectUri}
              isLoading={lineLoading}
              text={country == "TH" ? "เข้าสู่ระบบด้วย LINE" : "LINEでログイン"}
              isDisabled={!country}
              width={360}
            />
            <ErrorLabel
              isError={lineError}
              >
                {lineError}
            </ErrorLabel>*/}
        </Flex>
        <StyledSpacing>{intlMessages['login.form.title']}</StyledSpacing>
        <Form
            onSubmit={handleLogin}
            render = {({
              submitError,
              formError,
              handleSubmit,
              mutators,
              form,
              reset,
              submitting,
              pristine,
              validating,
              values
            }) => (
              <form
                onSubmit={handleSubmit}
                >

                {/*<StyledSpacing>{intlMessages['login.form.spacer.or']}</StyledSpacing>*/}
                <Field name="email" parse={v => v} validate={required}>
                    {({ input, meta}) => (
                      <StyledInputLayout>
                        <StyledInput
                          {...input}
                          tabindex="0"
                          type="text"
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          placeholder={upperFirst(intlMessages['login.form.email'])} />
                        <ErrorLabel
                          isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          setLineHeight={10}
                          >
                            {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`login.form.email.error`] || meta.submitError}
                        </ErrorLabel>
                      </StyledInputLayout>
                    )}
                  </Field>
                  <Field name="password" parse={v => v} validate={required}>
                    {({ input, meta}) => (
                      <StyledInputLayout>
                        <StyledInput
                          {...input}
                          tabindex="1"
                          addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          type="password"
                          placeholder={upperFirst(intlMessages['login.form.password'])} />
                        <ErrorLabel
                          isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                          setLineHeight={10}
                          >
                            {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`login.form.password.error`] || meta.submitError}
                        </ErrorLabel>
                        <StyledForgotPasswordLink marginTop={'0px'} to={"/reset"}>{intlMessages['login.form.forgetpassword.text']}</StyledForgotPasswordLink>

                      </StyledInputLayout>
                    )}
                  </Field>
                  <ErrorLabel
                    setLineHeight={10}
                    isError={submitError && errorCss}
                    >
                    {intlMessages['login.form.generalError']}
                  </ErrorLabel>                  

                  <Button
                    variant={'solid'}
                    w={['full']}
                    h={'45px'}
                    type={'submit'}
                    isDisabled={
                      !(values.email && values.password)
                    }
                    isLoading={submitting}
                    mb={'20px'}
                  >
                    {intlMessages['login.form.submit']}
                  </Button>
                  <StyledForgotPasswordLink to={"/register"}>{intlMessages['login.form.createaccount.text']}</StyledForgotPasswordLink>
                  <StyledActivateLink to={"/register/activation"}>{intlMessages['activation.form.submit']}</StyledActivateLink>

                </form>
            )}
            />
        </StyledForm>
      )
  }
  return (
    <Wrapper>
      { isChatLoaded && <Redirect to={"/"}/>}
      {renderLoginForm()}
    </Wrapper>
  )

}
