//media.js
import { css } from "styled-components"
/* min-width sizes
const sizes = {
  uhd: 1980,
  widescreen: 1366,
  desktop: 1024,
  tablet: 768,
}*/
/* max-wdith sizes */
const sizes = {
  uhd: 1979,
  widescreen: 1365,
  desktop: 1023,
  tablet: 767,
}
/* min width. css has mobile first, then chagne for larger sizes
export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
*/
/* max width. css has mobile first, then chagne for smaller sizes */
export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
