import React , {useState, useRef, useEffect} from 'react';
import {
  upperFirst, toUpper
} from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { actions as modalActions } from '../../Modals/_actions';
import { actions as authenticationActions } from '../../Authentication/_actions';
import { constants as authenticationConstants } from '../../Authentication/_constants';
import { services as authenticationServices } from '../../Authentication/_services';
import { getPayments, getPaymentsLoading, getDefaultPayment, getIntlMessages} from '../../Authentication/_selectors';

import { LoadingEllipses } from '../../_components/LoadingEllipses'

import { FaTimes, FaCreditCard} from 'react-icons/fa';
import MCLogo from '../../_images/MasterCard_light.png'
import VisaLogo from '../../_images/Visa_light.png'
import JCBLogo from '../../_images/JCB_light.png'

import {
  StyledCCard,
  StyledCCardRow,
  StyledCCardList,
  StyledChangeCardLink,
  StyledPage,
  RemoveButton
} from './styles';

const CCardBrand = ({brand}) => {
  if (brand == "Visa") {
    return (<img src={VisaLogo} />)
  } else if (brand == "MasterCard"){
    return (<img src={MCLogo} />)
  } else if (brand == "JCB"){
    return (<img src={JCBLogo} />)
  } else {
    return (<FaCreditCard />)
  }
}

const CCard = ({card, selectedCard, handleClick}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const cards = useSelector(getPayments);
  const [ error, setError ] = useState(null);

  const handleRemove = async () => {
    setError(null);
    try{
      const resp = await authenticationServices.removePayment({"card": card.id})
      dispatch({type: authenticationConstants.UPDATE, data: {payments: cards.filter((item) => item.id != card.id)}})
    } catch (error) {
      setError(intlMessages['settings.details.payments.error.remove'])
    }
  }

  const clickMakeDefault = async () => {
    setError(null);
    try{
      const resp = await authenticationServices.changeDefaultPayment({"card": card.id})
      dispatch({type: authenticationConstants.UPDATE, data: {payments: resp.cards}})
    } catch (error) {
      setError(intlMessages['settings.details.payments.error.update'])
    }
  }

  const clickCard = () => {
    if (handleClick){
      handleClick(card.id)
    }
  }
  if (card){
    return(
      <React.Fragment>
      <StyledCCardRow>
        <StyledCCard
          isSelected={card.id == selectedCard}
          isDefault={card.default}
          onClick={clickCard}
          >
          <div className="cardIcon">
            <CCardBrand brand={card.brand} />
          </div>
          <div className="cardDetails">
            <div className="cardNumber">{intlMessages['settings.details.payments.cardNumber.placeholder']}<span>{card.last_digits}</span></div>
            <div className="cardExp">{intlMessages['settings.details.payments.exp.label']}<span>{`${card.expiration_month}/${card.expiration_year.toString().slice(-2)}`}</span></div>
          </div>
        </StyledCCard>
        {(card.id == selectedCard && !card.default) && <RemoveButton onClick={handleRemove}><FaTimes /></RemoveButton>}
        <div className="default">
          {
            (card.default)
              ? <span className="isDefault">{intlMessages['settings.details.payments.defaultcard.text']} </span>
              : (card.id == selectedCard)
                ? <span className="makeDefault" onClick={clickMakeDefault}>{intlMessages['settings.details.payments.makedefault.text']} </span>
                : <span></span>
          }
        </div>
      </StyledCCardRow>
      <div className="error">{error}</div>
      </React.Fragment>
    )
  } else {
    return(
      <div></div>
    )
  }

}

const CCardList = ({handleClick, handleAddClick, selectedCard}) => {
  const cards = useSelector(getPayments);
  const intlMessages = useSelector(getIntlMessages);

  return(
    <StyledCCardList>
      { cards.map(item =>
        <CCard
          key={item.id}
          handleClick={handleClick}
          card={item}
          selectedCard={selectedCard} />)}

      <StyledChangeCardLink onClick={handleAddClick}>
        <span>{toUpper(intlMessages['checkup.paymentform.addCard'])}</span>
      </StyledChangeCardLink>
    </StyledCCardList>
  )
}

export const SettingsOmise = () => {
  const dispatch = useDispatch();
  const isPaymentsLoading = useSelector(getPaymentsLoading);
  const [succeeded, setSucceeded] = useState(null);
  const [selectedCard, setSelectedCard ] = useState(null);

  useEffect(()=>{
    dispatch(authenticationActions.getPayments());
  }, [])

  const addCard = () => {
    dispatch(modalActions.openModal('cardForm'))
  }

  const selectCard = (cardId) => {
    setSelectedCard(cardId)
  }

  return(
    <StyledPage>
      <div className={'successMessage'}>{succeeded && <span>{succeeded}</span>}</div>
      {isPaymentsLoading && <div className={'loading'}><LoadingEllipses /></div> }
      <CCardList
        handleClick={selectCard}
        handleAddClick={addCard}
        selectedCard={selectedCard}
        />
    </StyledPage>
  )
}
