import { API_URLS } from '../_config';
import { authHeader, handleResponse } from '../_helpers';
import { objectToQueryParamString } from '../_helpers/urls';


export const services = {
  searchClinics,
  getSearchRegions,
  getDentistDetails,
  getDentistReviews
};

function getSearchRegions(){
  const requestUrl = API_URLS.getSearchRegions;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function getDentistDetails(id){
  const requestUrl = API_URLS.getDentistDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{dentistId}", id);
  return fetch(url, requestOptions).then(handleResponse);
}

function getDentistReviews(id, offset=0, pg_sz=5){
  const requestUrl = API_URLS.getDentistReviews;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{dentistId}", id) + '?' + objectToQueryParamString({offset: offset, pg_sz: pg_sz})
  return fetch(url, requestOptions).then(handleResponse);
}

function searchClinics(query){
  const requestUrl = API_URLS.searchClinics;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url + '?' + new URLSearchParams(query);
  return fetch(url, requestOptions).then(handleResponse);
}

// document search e;astoc searcjh
function searchClinics2(query){
  const requestUrl = API_URLS.searchClinics;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url + '?' + new URLSearchParams(query);
  return fetch(url, requestOptions).then(handleResponse);
}
