import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import ReactPlayer from 'react-player';
import cn from 'classnames';

import { Group } from '@visx/group';
import { HeatmapCircle, HeatmapRect } from '@visx/heatmap';
import { scaleLinear } from '@visx/scale';
import { range } from 'lodash';

import { localeFormatDate } from '../_helpers';

import { actions } from './_actions';

import { getIntlLocale, getIntlMessages } from '../Authentication/_selectors';
import { getRecordUploads } from '../Reports/_selectors'
import {
  getCurrentMediaId,
  getCurrentSelectedMediaItem,
  getCurrentRecordId,
  getCurrentRecordDetail,
  getCurrentRecordUploadsById,
  getCurrentRecordUploads,
  getCurrentSelectedMedia,
  getCurrentRecordLoading,
  getCurrentRecordCreatedDate,
  getCurrentDetectionId,
  getCurrentSelectedDetectionDetails,
  getCurrentDetectionByFrames,
  getCurrentDetectionLoading,
  getCurrentSelectedDetectionTotalFrames,
  getCurrentSelectedDetectionFps,
  getCurrentDetectionMediaItem

} from './_selectors';

import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { LoadingEllipses } from '../_components/LoadingEllipses';

import {
  StyledRecordUploadMedia
} from '../_components/RecordUploadMedia/styles';

import {
  DetectionWrapper,
  StyledRecordDetail,
  StyledMediaPreview,
  StyledMediaList,
  StyledMediaItem,
  StyledDetectionPreview,
  StyledDetections,
  BlankMediaItem,
  BlankDiv
} from './styles';

const hot1 = '#77312f';
const hot2 = '#f33d15';
const cool1 = '#122549';
const cool2 = '#b4fbde';
const background = '#28272c';
const playBar = '#66ff00';
// accessors
const bins = (d) => d.bins;
const count = (d) => d.count;

function max(data, value) {
  return Math.max(...data.map(value));
}

function min(data, value) {
  return Math.min(...data.map(value));
}

const Detections = ({currentDetections, totalFrames, loaded, canvasFrame, onMouseEnter}) => {
  let mouseDown = useRef(false);
  let binData = range(1, totalFrames+1).reduce((acc, curr) => {
    // [timestamp in ms, frame number, array of detections [score, left, top, right, bottom]]
    acc.push({
      bin: curr,
      bins: [
        {
          bin: 1,
          count: (currentDetections[curr] && currentDetections[curr][2])
                  ? currentDetections[curr][2].reduce((a, c) => Math.max(a, Number(c[0])), 0)
                  : 0
        }
      ]
      })
    return acc;
  }, [])


  //accessors
  const colorMax = max(binData, d => max(bins(d), count));
  const bucketSizeMax = max(binData, d => bins(d).length);

  const margin = { top: 10, left: 0, right: 0, bottom: 10 };
  const size = totalFrames;
  const xMax = 640 - margin.left - margin.right;
  const yMax = 50 - margin.bottom - margin.top;
  // DEBUG console.log("total frames, xmax, ymax ", size, xMax, yMax)

  const binWidth = xMax / binData.length;
  const binHeight = yMax / 1;

  // scales
  const xScale = scaleLinear({
    range: [0, xMax],
    domain: [0, binData.length]
  });
  const yScale = scaleLinear({
    range: [0, yMax],
    domain: [0, bucketSizeMax]
  });

  // DEBUG console.log("y scale", yMax, bucketSizeMax)

  const circleColorScale = scaleLinear({
    range: [hot1, hot2],
    domain: [0, colorMax],
  });
  const opacityScale = scaleLinear({
    range: [0.0, 1],
    domain: [0, colorMax],
  });


  return (
    <StyledDetections>
      <svg width={640} height={50}>
        <rect className={cn('detectionSeekBar', {'notSeekable': !loaded})} x={canvasFrame && xScale(canvasFrame[0]) || 0} y={0} width={binWidth} height={50} rx={1} fill={playBar} />
        <Group left={margin.left} top={margin.top}>
          <HeatmapRect
            data={binData}
            xScale={xScale}
            yScale={yScale}
            colorScale={circleColorScale}
            opacityScale={opacityScale}
            binWidth={binWidth}
            binHeight={binHeight}
            gap={0}
          >
          {heatmap =>
            heatmap.map(heatmapBins =>
              heatmapBins.map(bin => (
                <rect
                  key={`heatmap-rect-${bin.row}-${bin.column}`}
                  className={cn("visx-heatmap-rect", {"detected": bin.opacity > 0})}
                  width={bin.width}
                  height={bin.height}
                  x={bin.x}
                  y={bin.y}
                  fill={bin.color}
                  fillOpacity={bin.opacity}
                  onMouseEnter={(e) => {
                    bin.opacity > 0 && onMouseEnter(bin.column)
                  }}
                />
              )),
            )
          }
          </HeatmapRect>
        </Group>
      </svg>
    </StyledDetections>
  )
}

const DetectionCanvas = ({frame, ...rest}) => {
  const ref = useRef();
  const lastDrawFrame = useRef(0);
  const canvas = ref.current;
  const context = canvas && canvas.getContext('2d');

  const maxSameFrame = 4;

  let lineWidth = 5;
  useEffect(()=>{
    if (context){
      // DEBUG console.log("should be drawing frame ", frame)
      // frame = [frameNum, detections array]
      //
      // if same frame for 4 frames and no current detections, remove
      if (Math.abs(frame[0] - lastDrawFrame.current) > maxSameFrame){
        context.clearRect(0, 0, canvas.width, canvas.height)
      }
      if (frame[1].length > 0){
        context.clearRect(0, 0, canvas.width, canvas.height)
        frame[1].map(elem => {
            let [score, left, top, right, bottom] = elem;
            context.beginPath();
            context.lineWidth = `${lineWidth}`;
            context.strokeStyle = "green";
            context.rect(left, top, Math.min(right, 640-lineWidth/2)-left, Math.min(480-lineWidth/2, bottom)-top);
            context.stroke();
        });
        lastDrawFrame.current = frame[0];
      }
    }
  }, [frame])

  return(
    <canvas ref={ref} width={640} height={480} />
  )
}

const DetectionPreview = () => {
  const dispatch = useDispatch();
  const canvasRef = useRef();
  const playerRef = useRef();
  const item = useSelector(getCurrentSelectedMedia);
  const currentDetectionsByFrame = useSelector(getCurrentDetectionByFrames);
  const totalFrames = useSelector(getCurrentSelectedDetectionTotalFrames);
  const detectionFps = useSelector(getCurrentSelectedDetectionFps);
  const [canvasFrame, setCanvasFrame] = useState();
  const [ upload, setUpload ] = useState(null);
  const htmlVideoTotalFrames = useRef(-1);

  const [ loaded, setLoaded ] = useState(false);

  const handleMouseEnterDetection = (frameNum) => {
    if (totalFrames > 0 && playerRef.current.getDuration() != Infinity){
      playerRef.current && playerRef.current.seekTo(frameNum/totalFrames, 'fraction');
    } else {
      // need video do be loaded and have duration to seek

    }
  }

  const onProgress = (prog) => {
    let {playedSeconds, loaded, loadedSeconds, played } = prog;
    console.log(prog);
    loaded > 0 && setLoaded(true);
    let currFrame = Math.round(played*totalFrames)
    let currDetection = currentDetectionsByFrame[currFrame] || currentDetectionsByFrame[currFrame+1];
    // currDetection: timestamp, frame num, detecitons array
    (currDetection && setCanvasFrame([currFrame, currDetection[2]])) || setCanvasFrame([currFrame,[]])
  }

  const onBuffer = (a=null) => {
    console.log("buffer started: " + a)
  }

  const onBufferEnd = (a=null) => {
    console.log("buffer ended: " + a);
  }

  let urlKey = "";
  let urlHeadKey = "";
  let fileName = "Uploaded File";
  if (item && item.file){
    urlKey = "file";
    urlHeadKey = "file_head"
  } else if (item && item.upload) {
    urlKey = "upload";
    urlHeadKey = "upload_head";
  }
  if (upload && upload.converted_url){
    urlKey = "converted_url"
  }

  if (item && item.file_name){
    fileName = item.file_name
  }

  useEffect(()=>{
    return () => {
    }
  }, [])

  useEffect(() => {
      setUpload(item)
  }, [item && item.id])

  const onError = (err) => {

  }

  if (item && upload){
    return (
      <StyledDetectionPreview>
        <div className={'videoPlayer'}>
          <ReactPlayer
            ref={playerRef}
            onError={onError}
            url={item[urlKey]}
            muted={true}
            width={640}
            height={480}
            preload={"true"}
            progressInterval={detectionFps}
            onProgress={onProgress}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            controls={false/*true*/}
          />
          <DetectionCanvas frame={canvasFrame} />
        </div>
      { Object.keys(currentDetectionsByFrame).length > 0 && totalFrames > -1 &&
        <Detections
          currentDetections={currentDetectionsByFrame}
          totalFrames={totalFrames}
          canvasFrame={canvasFrame}
          loaded={loaded}
          onMouseEnter={handleMouseEnterDetection}
        />
      }
      </StyledDetectionPreview>
    )
  } else {
    return (
      <BlankMediaItem
        imgWidth={640}
        imgHeight={480}
        imgBorderRadius={5}
        >
      </BlankMediaItem>
    )
  }
}

export const RecordDetectionDetail = () => {
  const dispatch = useDispatch();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);
  const currentDetectionLoading = useSelector(getCurrentDetectionLoading);
  const currentSelectedId = useSelector(getCurrentDetectionId);
  const currentDetectionDetails = useSelector(getCurrentSelectedDetectionDetails);
  const currentDetectionUpload = useSelector(getCurrentDetectionMediaItem);

  useEffect(()=>{
    // initialization. if no record in reducer, fetch.
    if (currentSelectedId && !currentDetectionDetails){
      dispatch(actions.fetchUploadDetections(currentSelectedId));
    }
    return () => {};
  }, [currentSelectedId, currentDetectionDetails])

  return (
    <DetectionWrapper>
      <h2>{intlMessages['recordDetail.detection.title']} {currentDetectionDetails && <span>{' ' + localeFormatDate(currentDetectionDetails.created_at, intlLocale, "lll")}</span>}</h2>
      <StyledRecordDetail>
        {currentDetectionLoading && <div className={'loading'}><LoadingEllipses /></div>}
        <DetectionPreview />
      </StyledRecordDetail>
    </DetectionWrapper>

  )
}
