import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { pickBy } from 'lodash';
//
import { ReportToothSet } from '../_components/ReportToothSet';
import { MdSwapHoriz } from 'react-icons/md';

const StyledFlipButton = styled.div`
  position:absolute;
  top: 0;
  left: 0;
  margin: 0;
  pointer-events: all;
  z-index: 5;
  svg{
    height: 21px;
    width: 21px;
    pointer-events: none;

    path{
      fill: #12171a;
    }
  }

  &:hover{
    cursor:pointer;
  }
`;

const StyledToothSelectChart = styled.div`
  position: relative;
  margin: auto;
  width: fit-content;
`;

export const ToothSelectChart = ({setCurrentTeeth, defaultRegion=[], height=400, width=280}) => {
  const dispatch = useDispatch();
  const [ charting, setCharting ] = useState(defaultRegion.reduce((acc,curr)=> {acc[curr] = 'ALL';return acc;}, {}) );
  const [ chartView, setChartView ] = useState(localStorage.getItem('chartView'))

  useEffect(()=>{
    setCurrentTeeth(Object.keys(pickBy(charting, (x) => x == 'ALL')));
  }, [])

  const handleFlipped = () => {
    localStorage.setItem('chartView', chartView == 'd' ? null : 'd');
    setChartView(old => (old == 'd') ? null : 'd');
  }

  const handleRegionChange = (val) => {
    let newCharting = {...charting, [val]: (charting[val] === 'ALL') ? '' : 'ALL'}
    setCharting(newCharting);
    //console.log(Object.keys(pickBy(newCharting, (x) => x == 'ALL')));
    setCurrentTeeth(Object.keys(pickBy(newCharting, (x) => x == 'ALL')));
  }

  return (
    <StyledToothSelectChart>
      <StyledFlipButton chartSize={"L"} onClick={handleFlipped}>
        <MdSwapHoriz />
      </StyledFlipButton>
      <ReportToothSet
        charting={charting}
        onClick={handleRegionChange}
        height={height}
        width={width}
        isFlipped={chartView == "d"}
      />
    </StyledToothSelectChart>
  )
}
