const PREFIX = 'AUTHENTICATION_';
export const constants = {
  LOGIN_REQUEST: `${PREFIX}LOGIN_REQUEST`,
  LOGIN_SUCCESS: `${PREFIX}LOGIN_SUCCESS`,
  LOGIN_FAILURE: `${PREFIX}LOGIN_FAILURE`,
  LOGOUT_REQUEST: `${PREFIX}LOGOUT_REQUEST`,
  LOGOUT_SUCCESS: `${PREFIX}LOGOUT_SUCCESS`,
  LOGOUT_FAILURE: `${PREFIX}LOGOUT_FAILURE`,
  USER_CHAT_INITIALIZATION_REQUEST: `${PREFIX}USER_CHAT_INITIALIZATION_REQUEST`,
  USER_CHAT_INITIALIZATION_SUCCESS: `${PREFIX}USER_CHAT_INITIALIZATION_SUCCESS`,
  USER_CHAT_INITIALIZATION_FAILURE: `${PREFIX}USER_CHAT_INITIALIZATION_FAILURE`,
  USER_GET_CHAT_CHANNELS_REQUEST: `${PREFIX}USER_GET_CHAT_CHANNELS_REQUEST`,
  USER_GET_CHAT_CHANNELS_SUCCESS: `${PREFIX}USER_GET_CHAT_CHANNELS_SUCCESS`,
  USER_GET_CHAT_CHANNELS_FAILURE: `${PREFIX}USER_GET_CHAT_CHANNELS_FAILURE`,
  USER_PROFILE_REQUEST: `${PREFIX}USER_PROFILE_REQUEST`,
  USER_PROFILE_SUCCESS: `${PREFIX}USER_PROFILE_SUCCESS`,
  USER_PROFILE_FAILURE: `${PREFIX}USER_PROFILE_FAILURE`,
  USER_PROFILES_REQUEST: `${PREFIX}USER_PROFILES_REQUEST`,
  USER_PROFILES_SUCCESS: `${PREFIX}USER_PROFILES_SUCCESS`,
  USER_PROFILES_FAILURE: `${PREFIX}USER_PROFILES_FAILURE`,
  USER_PROFILESREMOVE_REQUEST: `${PREFIX}USER_PROFILESREMOVE_REQUEST`,
  USER_PROFILESREMOVE_SUCCESS: `${PREFIX}USER_PROFILESREMOVE_SUCCESS`,
  USER_PROFILESREMOVE_FAILURE: `${PREFIX}USER_PROFILESREMOVE_FAILURE`,
  USER_PAYMENTS_REQUEST: `${PREFIX}USER_PAYMENTS_REQUEST`,
  USER_PAYMENTS_SUCCESS: `${PREFIX}USER_PAYMENTS_SUCCESS`,
  USER_PAYMENTS_FAILURE: `${PREFIX}USER_PAYMENTS_FAILURE`,
  USER_SETTINGS_PAYMENTS_REQUEST: `${PREFIX}USER_SETTINGS_PAYMENTS_REQUEST`,
  USER_SETTINGS_PAYMENTS_SUCCESS: `${PREFIX}USER_SETTINGS_PAYMENTS_SUCCESS`,
  USER_SETTINGS_PAYMENTS_FAILURE: `${PREFIX}USER_SETTINGS_PAYMENTS_FAILURE`,
  USER_INITIALIZATION_FAILURE: `${PREFIX}USER_INITIALIZATION_FAILURE`,
  UPDATE:`${PREFIX}UPDATE`,
  UPDATE_OBJECT: `${PREFIX}UPDATE_OBJECT`,
  ADD_PROFILE: `${PREFIX}ADD_PROFILE`,
  UPDATE_PROFILE: `${PREFIX}UPDATE_PROFILE`,
  SELECT_PROFILE: `${PREFIX}SELECT_PROFILE`
}
