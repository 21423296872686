import styled from 'styled-components';
import { StyledButton } from '../_styles/forms';

export const StyledBrowserNotifications = styled.div`
  background-color: #EFEFEF;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  width: 500px;
  padding: 40px;
  border-radius: 5px;

  .title{
    font-family: Proxima Nova Semibold;
    font-size: 18px;
    color: black;
    margin-bottom: 20px;
  }

  .examples{

  }

  .description{
    font-family: Proxima Nova;
    line-height: 18px;
    p{
      margin: 10px 0;
      line-height:1.4;
    }
  }

  .action{
    display:flex;
    flex-direction: column;
    margin-top: 10px;
    ${StyledButton}{
      margin: 0;
      &:first-child{
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }

  .notShowAgain{
    display: flex;
    align-items: center;
    margin-top: 20px;

    input{
      height: 11px;
      margin-right: 5px;
    }

    label {
      font-size: 12px;
      font-family: Proxima Nova Semibold;
    }
  }

`;
