export const clinicsData = {
    "clinics": [
      {
          "name": "Thanee Pawadeed",
          "description": "Implant Specialty. Clear Aligners.",
          "categories": "",
          "unique_id": "c20538a5-4354-49e1-8494-145521994831",
          "clinic": {
              "address": "751 Sukhumvit 41 Alley, Klongton Nua Watthana, Bangkok 10110, Thailand",
              "name": "NP International Dental Clinic",
              "branch": "",
              "email_address": "",
              "phone_number": "",
              "unique_id": "3979c867-692e-4797-9dea-f3156634e27e",
              "geopoint": {
                  "lat": 13.728878,
                  "lng": 100.5700442
              }
          }
      },
      {
          "name": "Nongtree Randapa",
          "description": "Orthodontics. Veneers. Korea.",
          "categories": "",
          "unique_id": "db223a90-aba9-4fc8-a9bc-43a8c907554b",
          "clinic": {
              "address": "55/7 Tambon Prachathipat, Thanyaburi District, Pathum Thani 12000, Thailand",
              "name": "Hollywood Smile Clinic",
              "branch": "",
              "email_address": "",
              "phone_number": "",
              "unique_id": "ff554a68-750d-4c3d-9628-233935901b84",
              "geopoint": {
                  "lat": 13.9985466,
                  "lng": 100.6635195
              }
          }
      }
    ]
}
