const PREFIX = 'VIDEOCHATS_';
export const constants = {
  FETCHVIDEOCHAT_REQUEST: `${PREFIX}FETCHVIDEOCHAT_REQUEST`,
  FETCHVIDEOCHAT_SUCCESS: `${PREFIX}FETCHVIDEOCHAT_SUCCESS`,
  FETCHVIDEOCHAT_FAILURE: `${PREFIX}FETCHVIDEOCHAT_FAILURE`,

  FETCHVIDEOCHATS_REQUEST: `${PREFIX}FETCHVIDEOCHATS_REQUEST`,
  FETCHVIDEOCHATS_SUCCESS: `${PREFIX}FETCHVIDEOCHATS_SUCCESS`,
  FETCHVIDEOCHATS_FAILURE: `${PREFIX}FETCHVIDEOCHATS_FAILURE`,

  FETCHAPPOINTMENTS_REQUEST: `${PREFIX}FETCHAPPOINTMENTS_REQUEST`,
  FETCHAPPOINTMENTS_SUCCESS: `${PREFIX}FETCHAPPOINTMENTS_SUCCESS`,
  FETCHAPPOINTMENTS_FAILURE: `${PREFIX}FETCHAPPOINTMENTS_FAILURE`,

  FETCHVIDEOCHATTOKEN_REQUEST: `${PREFIX}FETCHVIDEOCHATTOKEN_REQUEST`,
  FETCHVIDEOCHATTOKEN_SUCCESS: `${PREFIX}FETCHVIDEOCHATTOKEN_SUCCESS`,
  FETCHVIDEOCHATTOKEN_FAILURE: `${PREFIX}FETCHVIDEOCHATTOKEN_FAILURE`,

  UPDATE: `${PREFIX}UPDATE`,
  UPDATE_STATE: `${PREFIX}UPDATE_STATE`,
  UPDATE_STATE_OBJECT: `${PREFIX}UPDATE_STATE_OBJECT`
}
