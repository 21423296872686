import styled from 'styled-components';
import { Wrapper as BaseWrapper } from '../_styles/common';
import { StyledButton as Button }from '../_styles/forms';

export const StyledTerms = styled.div`

  display: flex;
  align-items: center;
  justify-content:flex-start;
  margin-top: 20px;
  margin-left: 0px;

  label {
    margin-left: 10px;
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #273238;

    a,a:visited,a:active,a:focus{
     color: #6B8A99;
     text-decoration: none !important;
     outline: none !important;
    }
    a:hover{
      cursor:pointer;
    }

  }

`;

export const ErrorLabel = styled.div`
  width:100%;
  height:15px;
  font-size:12px;
  text-align:left;
  font-color: red;
  font-family: Proxima Nova Semibold;
  letter-spacing: 0.9px;
  line-height: ${props => props.setLineHeight ? props.setLineHeight+'px' : '19px'};
  color:red;
  visibility: ${props => props.isError ? 'visible' : 'hidden'};
  ${props => props.addCSS}
`;

export const StyledTimeFieldIcon = styled.div`
  top : 4px;
  color: #C7D3D9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  padding-right: 15px;

  svg {
    fill: ${props => props.isSelected ? "#C7D3D9" : "#36454D" };
  }

  &:hover{
    cursor:pointer;
  }

`;

export const RequestAppointmentFieldTime = styled.div`
  position:relative;
  width: 244px;
  flex-shrink: 0;
  font-family: Proxima Nova Semibold;
  font-weight: normal;
`;

export const RequestAppointmentFieldDate = styled.div`
  position: relative;
  width: 244px;
  flex-shrink: 0;
`;

export const RequestAppointmentField = styled.div`
  display:flex;
  justify-content: space-between;
  flex-grow:1;
`;

export const StyledFormRow = styled.div`
  flex-direction: column;
  display: ${props => props.isShowing ? 'flex' : 'none' };
`;

export const RequestTypeFieldText = styled.span`

`;

export const RequestTypeFieldPrice = styled.span`
  font-family: Proxima Nova Semibold;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.9px;
  color: ${({readyStatus}) => (readyStatus=="loading") ? '#979797' : '#12171A'};
`;

export const StyledPaymentSummaryRow = styled.div`
  width: 100%;
  display:flex;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #12171A;
  margin-bottom:2px;

  ${RequestTypeFieldPrice}{
    font-family: Proxima Nova;
  }
`;

export const StyledPaymentTotalRow = styled.div`
  width: 100%;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-family: Proxima Nova Semibold;
  font-size: 15px;
  display:flex;
  color: #12171A;
  justify-content: space-between;
`;

export const RequestTypeField = styled.div`
  padding: 10px 30px;
  border-radius: 50px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #36454D;

  font-family: Proxima Nova Semibold;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.9px;
  color: #12171A;
`;

export const FormRadioLabel = styled.label`
  margin-bottom: 8px;

  width: calc(50% - 4px);

  input[type="radio"]{
    display:none;
  }

  input[type="radio"]+${RequestTypeField}{
    background-color: transparent;
    color: #12171A;
  }

  input[type="radio"]:checked+${RequestTypeField}{
    background-color: #36454D;
    color: white;

    ${RequestTypeFieldPrice}{
      color:white;
    }
  }

  &:hover{
    cursor: pointer;
  }

`;

export const FormRadioGroup = styled.div`
  display:flex;
  flex-direction: ${({direction}) => (direction=='horizontal') ? 'row': 'column'};
  justify-content: space-between;
`;


export const StyledStripePaymentForm = styled.div`
  padding: 50px;
  width: 400px;
  margin: 0 auto;
`;

export const StyledPaymentForm = styled.div`
  padding-top: 20px;
  padding-bottom: 70px;
  overflow-y:scroll;
`;

export const StyledConfirmationMessage = styled.div`
  padding: 50px;
  font-family: Proxima Nova, 'Open Sans', sans-serif;
  width: 500px;

  > h2 {
    font-size: 26px;
  }

  > h3 {
    color: var(--gray-light);
    font-size: 22px;
    margin-top: 10px;
  }

  > p {
    margin-top: 30px;
    line-height: 22px;
  }

  > div {
    display:flex;
    justify-content:flex-end;
    margin-top: 30px;
  }
  button {
    text-align:center;
    font-family: Proxima Nova,'Open Sans',sans-serif;
    margin-top: 20px;
    width: 160px;
    background-color: #273238;
    border-radius: 25px;
    color: #ffffff;
    font-size: 18px;
    line-height: 22px;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    padding: 10px 0;
    border: none;

    &:hover{
      cursor:pointer;
    }
  }
`;


export const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  padding: 10px 15px;
  width:100%;
  background: #FAFAFA;
  border: 1px solid #36454D;
  border-radius: 5px;

  &:placeholder-shown{
    border-color: #C7D3D9;
  }
  ${props => props.addCSS}
`;

export const StyledDescription = styled.div`
    font-family: Proxima Nova;
    font-style: normal;
    font-size: 11px;
    line-height: 14px;
    color: #273238;
    display: flex;

    > div:first-child{
      margin-right: 4px;
    }
`;

export const StyledLabel = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
  font-family: Proxima Nova Semibold;
  font-style: normal;
  font-size: 15px;
  line-height: 21px;
  color: #000000;
`;

export const StyledInputLayout = styled.div`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0 0;
`;

export const StyledCheckupFormBody = styled.div`
  padding: 0 50px;
`;

export const StyledCheckupFormHeader = styled.div`
  background: #FFFFFF;
  box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  text-transform: uppercase;
  display:flex;
  width:100%;
  justify-content: space-between;
  height: 75px;
  align-items: center;
  margin-bottom: 10px;


  .navButton{
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.9px;
    color: #12171A;
    padding: 25px 35px;
    &:hover{
      cursor:pointer;
      opacity: 0.8;
    }

    &.isDisabled{
      opacity: 0.2;
      &:hover{
        cursor:default;
        opacity: 0.2;
      }
    }
  }

`;

export const StyledButton = styled(Button)`
  width: 323px
`;

export const StyledButtons= styled.div`
  display:flex;
  width: 100%;
  justify-content:center;
`;

export const StyledCheckupForm = styled.form`
  width: 600px;
  padding-bottom: 40px;
  }
`;

export const PaymentFormWrapper= styled.div`
  width: 500px;
`;

export const Wrapper = styled(BaseWrapper)`
  margin: auto;
  background-color: #FFFFFF;
`;
