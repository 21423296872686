import { RECORD_TYPES, REQUEST_TYPES, LOCATIONS } from '../_config';
import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { createSelector } from 'reselect'

import { actions as modalActions } from '../Modals/_actions';
import { actions as reportActions } from '../Reports/_actions';
import { actions } from './_actions';

import { getIntlMessages } from '../Authentication/_selectors';
import { getCurrentRecordId } from '../PloverScan/_selectors';
import { getPaymentIntent, getCurrentCheckupRecord, getCurrentCheckupRequestId, getPaymentProcessor } from './_selectors';

import { CheckupForm } from './CheckupForm';
import { PaymentForm } from './PaymentForm';
import {
  upperFirst, toUpper, toLower
} from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map'
import { Wrapper, StyledConfirmationMessage } from './styles';
import storage from '../_helpers/storage';


const ConfirmationMessage = ({cost, currency}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  let priceString = "";

  useEffect(()=> {
    return () => {
      dispatch(actions.reset())
    }
  }, [])

  const close = () => {
    dispatch(actions.reset())
    //dispatch(modalActions.closeModal('scanningPage'))
    history.push("/");
  }

  if (cost > 0){
    if (toLower(currency) == "jpy"){
      priceString = getSymbolFromCurrency(toUpper(currency)) + " " + (cost).toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
      })
    } else {
      priceString = getSymbolFromCurrency(toUpper(currency)) + " " + (cost/100).toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
      })
    }
  } else {
    priceString = "FREE"
  }

  return (
    <StyledConfirmationMessage>
      <h2>{intlMessages['checkup.confirmationmessage.title']}</h2>
      <h3>{priceString}</h3>
      <p>
      {intlMessages['checkup.confirmationmessage.message']}
      </p>
      <div>
        <button onClick={close}>
          {intlMessages['checkup.confirmationmessage.button.complete']}
        </button>
      </div>
    </StyledConfirmationMessage>
  )
}

export const RequestCheckupPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkupMatch = useRouteMatch("/createrequest/checkup");
  const checkupPaymentMatch = useRouteMatch("/createrequest/checkup/payment");
  const checkupConfirmationMatch = useRouteMatch("/createrequest/checkup/confirmation");
  const [currentRecordId, setCurrentRecordId] = useState();
  const intlMessages = useSelector(getIntlMessages);
  const currentRecordIdFromReducer = useSelector(getCurrentRecordId);
  const currentCheckupRequestId = useSelector(getCurrentCheckupRequestId);
  //const paymentIntent = useRef(null);
  const currentRecord = useSelector(getCurrentCheckupRecord);
  const paymentIntent = useSelector(getPaymentIntent);
  const cost = useRef(0);
  const currency = useRef('');

  useEffect(()=> {
    return () => {};
  }, [])

  useEffect(()=>{
    // if paymentintent or requestId, go to payment page,
    // if record, go to checkup form
    if (currentCheckupRequestId || paymentIntent){
      if (paymentIntent == "FREE" || paymentIntent == 0) {
        cost.current = 0;
        currency.current = "USD"
        storage.clearCheckupRequest();
        storage.clearScanDetails();
        setPage(2);
      } else if(paymentIntent == "createPayment"){
        setPage(1)
      }else {
        setPage(1)
      }
    } else if (currentRecord){
      setCurrentRecordId(currentRecord);
      setPage(0);
    }
  }, [paymentIntent, currentRecord]);

  const setPage = (page) => {
    if (page == 0){
      history.push("/createrequest/checkup")
    } else if (page == 1){
      history.push("/createrequest/checkup/payment")
    } else if (page == 2){
      history.push("/createrequest/checkup/confirmation")
    } else {

    }
  }

  const onCheckupRequestSuccess = (currentRequest) => {
    // on request creation, update paymentintent and catch the change in useEffect
    // should change page to payment page
    console.log("onCheckupRequestSuccesss ", currentRequest)
    // clear record, profile, etc
    storage.clearScanDetails();
    dispatch(actions.setPaymentIntent(currentRequest))
    //dispatch(reportActions.updatePayment({id:currentCheckupRequestId, payment: currentPayment} ))
    //paymentIntent.current = currentPaymentIntent;
    //console.log("check up request created with intent ", currentPaymentIntent);
    /*if (currentPaymentIntent != null){
      if (currentPaymentIntent == "FREE") {
        cost.current = 0;
        currency.current = "USD"
        setPage(2);
      } else {
        setPage(1)
      }
    }*/
  }

  const onPaymentSuccess = (intentData) => {
    console.log("onPaymentSuccess", intentData)
    if (intentData){
      cost.current = intentData.amount
      currency.current = intentData.currency
    }
    dispatch(reportActions.updatePayment({id:currentCheckupRequestId, payment: intentData}));
    setPage(2);
  }

  if (checkupConfirmationMatch?.isExact){
    return (
      <Wrapper>
        <ConfirmationMessage
          cost = {cost.current}
          currency = {currency.current}
        />
      </Wrapper>
    )
  } else if (checkupPaymentMatch?.isExact){
    return (
      <Wrapper>
        <PaymentForm
          paymentIntent={paymentIntent}
          onSuccess={onPaymentSuccess}
        />
      </Wrapper>
    )
  } else if (checkupMatch?.isExact){
    return (
      <Wrapper>
        <CheckupForm
          recordId={currentRecordId}
          onSuccess={onCheckupRequestSuccess}
        />
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        {intlMessages['checkup.page.error']}
      </Wrapper>
    )
  }
}
