import { constants } from './_constants';
import { services } from './_services';

import { clinicsData } from './_testData';

export const actions = {
  update,
  searchClinics,
  updateMapCenter,
  selectClinic,
  getSearchRegions,
  dragStarted,
  updateDragMapBounds,
  searchClinicsByRegion,
  fetchDentistDetails,
  fetchDentistReviews
}

function update(data){
  return (dispatch) => {
    dispatch(update(data))
  }
  function update(data){
    return { type: constants.UPDATE, data}
  }
}

function updateDragMapBounds(data){
  return (dispatch) => {
    dispatch(update(data));
  }
  function update(data){
    return { type: constants.UPDATE, data: {dragMapBounds: data}}
  }
}

function dragStarted(bool){
  return (dispatch) => {
    dispatch(update(bool));
  }
  function update(bool){
    if (bool == false){
      return { type: constants.UPDATE, data: {dragStarted: bool, dragMapBounds: null}}
    }
    return { type: constants.UPDATE, data: {dragStarted: bool}}
  }
}

function updateMapCenter(lat, lng){
  return (dispatch) => {
    dispatch(update(lat, lng))
  }
  function update(lat, lng){
    if (lat == null || lng == null){
      return { type: constants.UPDATE, data: {currentMapCenter: null}}
    }
    return { type: constants.UPDATE_MAP_CENTER, data: {lat: parseFloat(lat), lng: parseFloat(lng)}}
  }
}

function updateMapBounds(bottomLeft, topRight){

}

function selectClinic(id){
  return (dispatch) => {
    dispatch(update(id))
  }
  function update(id){
    return { type: constants.UPDATE, data: {currentClinic: id}}
  }
}

function getSearchRegions(){
  return (dispatch) => {
    services.getSearchRegions()
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function success(data){
    return { type:constants.UPDATE, data: {region_list: data.region_list}}
  }
  function failure(error){
    return { type:constants.UPDATE, data: {region_list: []}}
  }
}

function searchClinicsByRegion(region){
  return (dispatch) => {
    var params = {};
    params['region'] = region;
    dispatch(request());
    services.searchClinics(params)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.SEARCHCLINICS_REQUEST } }
  function success(data) {
    // breakdown reports...
    const newData = {};
    const currentMapBounds = {west:null, east: null, north:null, south:null}
    let currentClinic = null;
    data.dentists.map((clinic, index) => {
      newData[clinic.unique_id] = clinic;
      if (index == 0){
        currentClinic = clinic.unique_id
      }
      if (index == 0){
        currentMapBounds.west = clinic.clinic.geopoint.lon
        currentMapBounds.east = clinic.clinic.geopoint.lon
        currentMapBounds.north = clinic.clinic.geopoint.lat
        currentMapBounds.south = clinic.clinic.geopoint.lat
      } else {
        currentMapBounds.west = clinic.clinic.geopoint.lon < currentMapBounds.west ? clinic.clinic.geopoint.lon : currentMapBounds.west;
        currentMapBounds.east = clinic.clinic.geopoint.lon > currentMapBounds.east ?  clinic.clinic.geopoint.lon : currentMapBounds.east;
        currentMapBounds.north = clinic.clinic.geopoint.lat > currentMapBounds.north ? clinic.clinic.geopoint.lat : currentMapBounds.north;
        currentMapBounds.south = clinic.clinic.geopoint.lat < currentMapBounds.south ? clinic.clinic.geopoint.lat : currentMapBounds.south;
      }

    })
    console.log('currentMapBounds ', currentMapBounds)
    return { type:constants.SEARCHCLINICS_SUCCESS, data: { byId: newData, currentClinic, currentMapBounds, queryType: "region" }}
  }
  function failure(error) { return { type:constants.SEARCHCLINICS_FAILURE, error} }
}

function fetchDentistDetails(id){
  return (dispatch) => {
    dispatch(request());
    services.getDentistDetails(id)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCH_DENTIST_DETAILS_REQUEST } }
  function success(data) { return { type:constants.FETCH_DENTIST_DETAILS_SUCCESS, data} }
  function failure(error) { return { type:constants.FETCH_DENTIST_DETAILS_FAILURE, error} }
}

function fetchDentistReviews(id, unique_id, offset, pg_sz=5){
  return (dispatch) => {
    dispatch(request());
    var params = {};
    services.getDentistReviews(id, offset, pg_sz)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCH_DENTIST_REVIEWS_REQUEST } }
  function success(data) { return { type:constants.FETCH_DENTIST_REVIEWS_SUCCESS, data: data, unique_id: unique_id} }
  function failure(error) { return { type:constants.FETCH_DENTIST_REVIEWS_FAILURE, unique_id: unique_id, error} }
}

function searchClinics(queryType, geo, filters){
  return (dispatch) => {
    var params = {};
    params[queryType] = geo;
    dispatch(request());
    services.searchClinics(params)
      .then(
        data => dispatch(success(queryType, data, geo)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.SEARCHCLINICS_REQUEST } }
  function success(queryType, data, geo) {
    // breakdown reports...
    const newData = {};
    const currentMapBounds = {west:null, east: null, north:null, south:null}
    let currentClinic = null;
    data.dentists.map((clinic, index) => {
      newData[clinic.unique_id] = clinic;
      if (index == 0){
        currentClinic = clinic.unique_id
      }
      if (queryType != 'bbox'){
        if (index == 0){
          currentMapBounds.west = clinic.clinic.geopoint.lon
          currentMapBounds.east = clinic.clinic.geopoint.lon
          currentMapBounds.north = clinic.clinic.geopoint.lat
          currentMapBounds.south = clinic.clinic.geopoint.lat
        } else {
          currentMapBounds.west = clinic.clinic.geopoint.lon < currentMapBounds.west ? clinic.clinic.geopoint.lon : currentMapBounds.west;
          currentMapBounds.east = clinic.clinic.geopoint.lon > currentMapBounds.east ?  clinic.clinic.geopoint.lon : currentMapBounds.east;
          currentMapBounds.north = clinic.clinic.geopoint.lat > currentMapBounds.north ? clinic.clinic.geopoint.lat : currentMapBounds.north;
          currentMapBounds.south = clinic.clinic.geopoint.lat < currentMapBounds.south ? clinic.clinic.geopoint.lat : currentMapBounds.south;
        }
      } else {
        let coords = geo.split(",");
        currentMapBounds.north = coords[0];
        currentMapBounds.west = coords[1];
        currentMapBounds.south = coords[2];
        currentMapBounds.east = coords[3];
        console.log("updating bounds in action ", coords)
      }
    })
    return { type:constants.SEARCHCLINICS_SUCCESS, data: { byId: newData, currentClinic, currentMapBounds, queryType }}
  }
  function failure(error) { return { type:constants.SEARCHCLINICS_FAILURE, error} }
}
