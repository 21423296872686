import { createSelector } from 'reselect';
import moment from 'moment';
import { extractDentistUuidFromChannelId } from '../_helpers';

import { getCurrentConversationId } from '../Chats/_selectors';

export const getVideoChatsSlice = (state) => state.videoChats;

export const getVideoChatById = createSelector(
  getVideoChatsSlice,
  (videoChats) => {
    return videoChats.byId
  }
)

export const getUpcomingLoading = createSelector(
  getVideoChatsSlice,
  (state) => {
    return state.appointmentsLoading;
  }
)

export const getPendingLoading = createSelector(
  getVideoChatsSlice,
  (state) => {
    return state.loading;
  }
)
export const getUpcomingVideoChatList = createSelector(
  getVideoChatById,
  (byId) => {
    return byId
      ? Object.values(byId).filter(item => item.video_chat.date)
        .sort((a,b)=>{
          if (moment(a.video_chat.date).isSame(b.video_chat.date)){
            return 0;
          } else if (moment(a.video_chat.date).isBefore(b.video_chat.date)){
            return -1;
          } else {
            return 1;
          }
        })
      : [];
  }
)

export const getPendingVideoChatList = createSelector(
  getVideoChatById,
  (byId) => {
    return byId
      ? Object.values(byId).filter(item => !item.video_chat.date)
        .sort((a,b)=>{
          if (moment(a.created_at).isSame(b.created_at)){
            return 0;
          } else if (moment(a.created_at).isBefore(b.created_at)){
            return -1;
          } else {
            return 1;
          }
        })
      : [];
  }
)

export const getCurrentDentistRequest = createSelector(
  [getVideoChatById, getCurrentConversationId],
  (byId, currentConversation) => {
    return (byId)
      ? Object.values(byId).filter(item => item.dentist.uuid == extractDentistUuidFromChannelId(currentConversation))
      : [];
  }
)

export const getCurrentVideoChatRequestId = createSelector(
  getVideoChatsSlice,
  (videoChats) => {
    return videoChats.currentVideoChat;
  }
)

export const getCurrentVideoChat = createSelector(
  [getVideoChatById, getCurrentVideoChatRequestId],
  (videoChats, current) => {
    return videoChats[current] && videoChats[current].video_chat;
  }
)

export const getCurrentVideoChatToken = createSelector(
  getVideoChatsSlice,
  (videoChats) => {
    return videoChats.currentVideoChatToken && videoChats.currentVideoChatToken.token;
  }
)

export const getCurrentVideoChatSessionId = createSelector(
  getVideoChatsSlice,
  (videoChats) => {
    return videoChats.currentVideoChatToken && videoChats.currentVideoChatToken.session_id;
  }
)
