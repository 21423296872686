import { FIREBASE_PUSH_KEY, FIREBASE_CONFIG } from '../_config';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, getToken as fbGetToken } from "firebase/messaging";

const app = initializeApp(FIREBASE_CONFIG);

export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);

export const getToken = (setTokenFound, handleError) => {
  return fbGetToken({vapidKey: FIREBASE_PUSH_KEY}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      handleError(true)
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    handleError(err)
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}
