import React, {useState} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { toUpper, startCase } from 'lodash';
import { Flex, Text ,Button } from '@chakra-ui/react';
import cn from 'classnames';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';


import PloverNoCaptureIcon from '../_images/PloverNoCaptureButton.svg'
import cameraPermissionsChromeGIF from '../_images/cameraPermissionsChrome.gif';

import connectionPloverNotConnected from '../_images/ProvidedCable.svg'
import connectionPloverConnected from '../_images/ploverConnectedComputer.svg'
import connectionPloverError from '../_images/ploverErrorComputer.svg'

import scanOver from '../_images/scanOver.png';
import startScan from '../_images/startScan.gif';
import stopScan from '../_images/stopScan.gif';
import allRegions from '../_images/allregions.gif';
import redoScan from '../_images/redoScan.gif';
import ploverLightButtonImage from '../_images/PloverLightButton.svg';

import quickstartSheath from '../_images/ProtectiveSheath.svg';
import quickstartCap from '../_images/CapAttachment.svg';
import quickstartConnecting from '../_images/ProvidedCable.svg';
import quickstartUsing from '../_images/CaptureButton.svg';
import quickstartSheathJa from '../_images/ProtectiveSheathJP.svg';
import quickstartSheathTh from '../_images/ProtectiveSheathTH.svg';


// selectors
import { getIntlLocale, getIntlMessages } from '../Authentication/_selectors';

import { StyledButton } from '../_styles/forms'

export const StyledTidbit = styled.div`
  width: 100%;
  padding: 30px;

  .subheader{
    width: 100%;
    > div {
      font-family: Proxima Nova Bold;
      margin-bottom: 15px;
    }
  }

  p{
    line-height: 20px;
    padding-bottom: 8px;
    &:first-child{
      padding-bottom: 30px;
    }
  }

  &.cameraErrorTidbit{
    > div {
      justify-content: center;
    }
  }

  &.captureButtonTidbit{


    .captureButtonTidbit__icon{
      img{
        width: 65px;
      }
    }

    .captureButtonTidbit__info{
      padding-left: 50px;
      justify-content: center;
    }
  }
`;

const FlexColumn = styled.div`
  display:flex;
  flex-direction: column;

`;

const FlexRow = styled.div`
  display:flex;

`;

const StyledTidbitCarousel = styled.div`
  width: 550px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow:hidden;

  .next, .prev{
    top: calc(50% - 20px);
    position: absolute;
    background: rgba(255,255,255,0.3);
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index: 2
  }
  .next { right: 10px;}
  .prev {
    left: 10px;
    transform: scale(-1);
  }

  .carouselContent{
    position: absolute;
    width: 100%;
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

const StyledCarouselActions = styled.div`
  padding: 0 50px;
  display:flex;
  justify-content: space-between;


  .notShowAgain{
    visibility: hidden;
    &.isVisible{
      visibility: visible;
    }

    input{
      height: 11px;
      margin-right:5px;
    }
    line-height:3px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 12px;
  }

  ${StyledButton}{
    margin: 0;
    min-width: unset;
    width: 150px;
  }
`;
const StyledHowToCarousel = styled(StyledTidbitCarousel)`
  height: 450px;

  .carouselContent__contentContainer{
    padding: 0 60px;
    color: black;
  }

  .carouselContent__title{
    font-family: Proxima Nova Bold;
    font-size: 110%;
    padding-bottom: 25px;
    justify-content:center;
  }

  .carouselContent__subtitle{
    font-family: Proxima Nova Semibold;
    font-size: 100%;
    padding-top: 30px;
    justify-content:flex-start;
  }

  .carouselContent__iconContainer{
    justify-content: center;
    align-items: center;
    min-height: 200px;
    padding-top: 20px;
    padding-bottom: 10px;
    img{
      max-width: 100%;
      user-drag: none;
      user-select: none;
      height: fit-content;
    }
  }

  .carouselContent__description{
    padding-top: 20px;
    line-height: 22px;
    white-space: break-spaces;
    
  }

  .isHidden{
    visibility: hidden;
  }
`;

export const TidbitCameraPermission = () => {
  const intlMessages = useSelector(getIntlMessages);
  return(
    <StyledTidbit className={'cameraPermissionTidbit'}>
      <FlexRow>
        <p>{intlMessages['tidbits.scanning.camera.permission']}</p>
      </FlexRow>
      <FlexRow>
        <div><img src={cameraPermissionsChromeGIF} /></div>
      </FlexRow>
    </StyledTidbit>
  )
}
 
export const TidbitCameraError = ({error}) => {
  const intlMessages = useSelector(getIntlMessages);
  
  const handleReload = () => {
    window.location.reload();
  }

  return(
    <StyledTidbit className={'cameraErrorTidbit'}>
      <Flex>
        <Text>
          {intlMessages['tidbits.connecting.plover.error.label']}
        </Text>
        <Text>
          {intlMessages[`tidbits.connecting.plover.${error}`] ? intlMessages[`tidbits.connecting.plover.${error}`] : error}
        </Text>
      </Flex>
      <Flex direction={'column'} align={['center']}>
        <Text
          color={["#12171a"]}
          whiteSpace={"pre"}
        >
          {intlMessages['tidbits.connecting.plover.error.directions']}
        </Text>
        <Button maxW={["220px"]} px={["20px"]} onClick={handleReload}>
          {intlMessages['tidbits.connecting.plover.error.directions.button']}
        </Button>
      </Flex>
    </StyledTidbit>
  )
}


export const TidbitPloverCaptureButton = () => {
  const intlMessages = useSelector(getIntlMessages);
  return(
    <StyledTidbit className={'captureButtonTidbit'}>
      <FlexRow>
        <FlexRow className={'captureButtonTidbit__icon'}>
          <div><img src={PloverNoCaptureIcon}/> </div>
        </FlexRow>
        <FlexRow>
          <FlexColumn className={'captureButtonTidbit__info'}>
          <Text>{intlMessages['tidbits.connecting.plover.header']}</Text>
          <Text>{intlMessages['tidbits.connecting.plover.description']}</Text>
          </FlexColumn>
        </FlexRow>
      </FlexRow>
    </StyledTidbit>
  )
}

export const TidbitConnectPlover = () => {
  const intlMessages = useSelector(getIntlMessages);
  return(
    <StyledHowToCarousel className={'carouselContent'} style={{"height": "max-content"}}>
      <FlexColumn className={'carouselContent__contentContainer'}>
        <FlexRow className={'carouselContent__iconContainer'}>
          <div><img src={connectionPloverNotConnected}/> </div>
        </FlexRow>
      </FlexColumn>
    </StyledHowToCarousel>
  )
}

export const TidbitConnectedPlover = () => {
  const intlMessages = useSelector(getIntlMessages);
  return(
    <StyledHowToCarousel className={'carouselContent'} style={{"height": "max-content"}}>
      <FlexColumn className={'carouselContent__contentContainer'}>
        <FlexRow className={'carouselContent__iconContainer'}>
          <div><img src={connectionPloverConnected}/> </div>
        </FlexRow>
      </FlexColumn>
    </StyledHowToCarousel>
  )
}


export const TidbitErrorPlover = () => {
  const intlMessages = useSelector(getIntlMessages);
  return(
    <StyledHowToCarousel className={'carouselContent'} style={{"height": "max-content"}}>
      <FlexColumn className={'carouselContent__contentContainer'}>
        <FlexRow className={'carouselContent__iconContainer'}>
          <div><img src={connectionPloverError}/> </div>
        </FlexRow>
      </FlexColumn>
    </StyledHowToCarousel>
  )
}

const TidbitStepTemplate = () => {
  const intlMessages = useSelector(getIntlMessages);
  return(
    <StyledTidbit className={'captureButtonTidbit'}>
      <FlexRow>
        <FlexRow className={'captureButtonTidbit__icon'}>
          <div><img src={PloverNoCaptureIcon}/> </div>
        </FlexRow>
        <FlexRow>
          <FlexColumn className={'captureButtonTidbit__info'}>
          <p>{intlMessages['tidbits.scanning.camera.capture.1']}</p>
          <p>{intlMessages['tidbits.scanning.camera.capture.2']}</p>
          </FlexColumn>
        </FlexRow>
      </FlexRow>
    </StyledTidbit>
  )
}

export const TidbitComprehensiveScan = () => {
  const intlMessages = useSelector(getIntlMessages);
  return(
    <StyledTidbit className={'guidedScanTidbit'}>
      <FlexRow className={'subheader'}>
        <div>{intlMessages['tidbits.scanning.guided.header']}</div>
      </FlexRow>
      <FlexRow>
        <FlexColumn>
          <Text>{intlMessages['tidbits.scanning.guided.description.1']}</Text>
          <Text>{intlMessages['tidbits.scanning.guided.description.2']}</Text>
        </FlexColumn>
      </FlexRow>
    </StyledTidbit>
  )
}

export const TidbitSelfScan = () => {
  const intlMessages = useSelector(getIntlMessages);
  return(
    <StyledTidbit className={'selfScanTidbit'}>
      <FlexRow className={'subheader'}>
        <div>{intlMessages['tidbits.scanning.self.header']}</div>
      </FlexRow>
      <FlexRow>
        <FlexColumn>
          <Text>{intlMessages['tidbits.scanning.self.description.1']}</Text>
          <Text>{intlMessages['tidbits.scanning.self.description.2']}</Text>
        </FlexColumn>
      </FlexRow>
    </StyledTidbit>
  )
}

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const images = [
  ploverLightButtonImage,
  scanOver,
  startScan,
  stopScan,
  redoScan,
  allRegions,
]

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const ComprehensiveScanTidbits = ({close}) => {
  const modalName = 'comprehensiveScanTidbits';
  const intlMessages = useSelector(getIntlMessages);
  const [[page, direction], setPage] = useState([0, 0]);

  const [ reachedLast, setReachedLast ] = useState(false);
  const [ checked, setChecked ] = useState(false);


  const comprehensiveScanSteps = [
    {
      title: intlMessages['tidbits.scanning.guided.lightmode.header'],
      description: intlMessages['tidbits.scanning.guided.lightmode.description'],
      src: ploverLightButtonImage
    },
    {
      title: intlMessages['tidbits.scanning.guided.initscan.header'],
      description: intlMessages['tidbits.scanning.guided.initscan.description'],
      src: scanOver
    },
    {
      title: intlMessages['tidbits.scanning.guided.startscan.header'],
      description: intlMessages['tidbits.scanning.guided.startscan.description'],
      src: startScan
    },
    {
      title: intlMessages['tidbits.scanning.guided.stopscan.header'],
      description: intlMessages['tidbits.scanning.guided.stopscan.description'],
      src: stopScan
    },
    {
      title: intlMessages['tidbits.scanning.guided.redoscan.header'],
      description: intlMessages['tidbits.scanning.guided.redoscan.description'],
      src: redoScan
    },
    {
      title: intlMessages['tidbits.scanning.guided.scanallregions.header'],
      description: intlMessages['tidbits.scanning.guided.scanallregions.description'],
      src: allRegions
    }
  ]

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, comprehensiveScanSteps.length, page);

  const paginate = (newDirection) => {
    ((page + newDirection) >= comprehensiveScanSteps.length - 1) && setReachedLast(old => old || true);
    setPage([page + newDirection, newDirection]);
  };

  const closeModal = () => {
    if (checked){
      localStorage.setItem(`doNotShow__${modalName}`, Date.now().toString());
    } else {
      localStorage.removeItem(`doNotShow__${modalName}`)
    }
    close();
  }

  const onChange = (e) => {
    setChecked(e.target.checked);
  }

  return (
    <div>
      <StyledHowToCarousel>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={page}
            className={'carouselContent'}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 }
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              // swipe to next/prev only for pages in between first and last
              if ((swipe < -swipeConfidenceThreshold) && (page < comprehensiveScanSteps.length-1)) {
                paginate(1);
              } else if ((swipe > swipeConfidenceThreshold) && (page > 0)) {
                paginate(-1);
              }
            }}
            >
            <FlexColumn className={'carouselContent__contentContainer'}>
              <FlexRow className={'carouselContent__title'}>{comprehensiveScanSteps[imageIndex].title}</FlexRow>
              <FlexRow className={'carouselContent__iconContainer'}><img src={comprehensiveScanSteps[imageIndex].src} /></FlexRow>
              <FlexRow className={'carouselContent__description'}>{comprehensiveScanSteps[imageIndex].description}</FlexRow>
            </FlexColumn>
          </motion.div>
        </AnimatePresence>
        <div className={cn('next', {'isHidden': page == comprehensiveScanSteps.length-1})} onClick={() => paginate(1)}>
          {"‣"}
        </div>
        <div className={cn('prev', {'isHidden': page == 0})} onClick={() => paginate(-1)}>
          {"‣"}
        </div>
      </StyledHowToCarousel>
      <StyledCarouselActions>
        <div className={cn('notShowAgain', {'isVisible': reachedLast})}>
          <input
            name={`doNotShow__${modalName}`}
            component="input"
            type="checkbox"
            value="yes"
            id="yes"
            onChange={onChange}
            />
          <label>{intlMessages['browsernotifications.firebase.modal.action.donotshow']}</label>
        </div>
        <StyledButton disabled={!reachedLast} onClick={closeModal}>{toUpper(intlMessages['tidbits.scanning.guided.button.close'])}</StyledButton>
      </StyledCarouselActions>
    </div>
  )
}

function getQuickStartSheathSrc(locale='en'){
  switch(locale){
    case 'ja':
      return quickstartSheathJa;
    case 'th':
      return quickstartSheathTh;
    default:
      return quickstartSheath;
  }
}

export const QuickStartTidbits = ({close}) => {
  const modalName = 'quickScanTidbits';
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale)
  const [[page, direction], setPage] = useState([0, 0]);

  const [ reachedLast, setReachedLast ] = useState(false);
  const [ checked, setChecked ] = useState(localStorage.getItem(`doNotShow__quickScanTidbits`));


  const steps = [
    {
      header: intlMessages['tidbits.quickstart.sheath.subheader'],
      title: intlMessages['tidbits.quickstart.sheath.header'],
      description: intlMessages['tidbits.quickstart.sheath.description'],
      src: getQuickStartSheathSrc(intlLocale)
    },
    {
      header: intlMessages['tidbits.quickstart.cap.subheader'],
      title: intlMessages['tidbits.quickstart.cap.header'],
      description: intlMessages['tidbits.quickstart.cap.description'],
      src: quickstartCap
    },
    {
      header: intlMessages['tidbits.quickstart.connectingPlover.subheader'],
      title: intlMessages['tidbits.quickstart.connectingPlover.header'],
      description: intlMessages['tidbits.quickstart.connectingPlover.description'],
      description2: intlMessages['tidbits.quickstart.connectingPlover.description.2'],
      src: quickstartConnecting
    },
    {
      header: intlMessages['tidbits.quickstart.captureButton.subheader'],
      title: intlMessages['tidbits.quickstart.captureButton.header'],
      description: intlMessages['tidbits.quickstart.captureButton.description'],
      src: quickstartUsing
    }
  ]
  

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, steps.length, page);

  const paginate = (newDirection) => {
    ((page + newDirection) >= steps.length - 1) && setReachedLast(old => old || true);
    setPage([page + newDirection, newDirection]);
  };

  const closeModal = () => {
    if (checked){
      localStorage.setItem(`doNotShow__${modalName}`, Date.now().toString());
    } else {
      localStorage.removeItem(`doNotShow__${modalName}`)
    }
    close();
  }

  const onChange = (e) => {
    setChecked(e.target.checked);
  }

  return (
    <div>
      <StyledHowToCarousel>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={page}
            className={'carouselContent'}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 }
            }}

            >
            <FlexColumn className={'carouselContent__contentContainer'}>
              <FlexRow className={'carouselContent__title'}>{startCase(steps[imageIndex].header)}</FlexRow>
              <FlexRow className={'carouselContent__iconContainer'}><img src={steps[imageIndex].src} /></FlexRow>
              <FlexRow className={'carouselContent__subtitle'}>{startCase(steps[imageIndex].title)}</FlexRow>
              <FlexRow className={'carouselContent__description'} style={{paddingTop: 10}}>{steps[imageIndex].description}</FlexRow>
              {steps[imageIndex].description2 && <FlexRow className={'carouselContent__description'} style={{paddingTop: 5}}>{steps[imageIndex].description2}</FlexRow>}
            </FlexColumn>
          </motion.div>
        </AnimatePresence>
      </StyledHowToCarousel>
      <CarouselActions 
        modalName={modalName}
        reachedLast={reachedLast}
        checked={checked}
        onChange={onChange}
        index={page}
        totalPages={steps.length}
        paginate={paginate}
        closeModal={closeModal}
      />
    </div>
  )
}

const CarouselActions = ({modalName, reachedLast, index, totalPages, checked, paginate, onChange, closeModal}) => {
  const intlMessages = useSelector(getIntlMessages);
  const isOnLastIndex = index == totalPages-1 
  return (
    <div class={'flex items-center justify-between px-[30px]'} >
      <div 
        class={cn('flex items-center text-black font-semibold hover:cursor-pointer', {'invisible': index == 0 })}  
        onClick={() => paginate(-1)}
      >
        <MdKeyboardArrowLeft size={24} color={'#000000'} />
        <div>{toUpper(intlMessages['tidbits.previous'])}</div>
      </div>
      <div class={cn('flex items-center text-[12px] ', {'visible': reachedLast}, {'invisible': !reachedLast})}>
        <input
          class={'h-[12px] mr-[5px] font-semibold'}
          name={`doNotShow__${modalName}`}
          component="input"
          type="checkbox"
          value="yes"
          id="yes"
          checked={checked}
          onChange={onChange}
          />
        <label class={'font-semibold'}>{intlMessages['browsernotifications.firebase.modal.action.donotshow']}</label>
      </div>
      <div 
        class={cn('flex items-center text-black font-semibold  hover:cursor-pointer')} 
        onClick={isOnLastIndex 
          ? closeModal
          : () => paginate(1)
        }
      >
        <div>{isOnLastIndex
              ? toUpper(intlMessages['tidbits.finish'])
              : toUpper(intlMessages['tidbits.next'])}
        </div>
        <MdKeyboardArrowRight size={24} color={'#000000'} />
      </div>
      {/*<StyledButton disabled={!reachedLast} onClick={closeModal}>{toUpper(intlMessages['tidbits.scanning.guided.button.close'])}</StyledButton>*/}
    </div>
  )
}

export const LoadingTidbits = () => {
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, 3, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <StyledTidbitCarousel>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={page}
          className={'carouselContent'}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          {
            (imageIndex == 0)
              ? <TidbitConnectPlover />
              : (imageIndex == 1)
                ? <TidbitComprehensiveScan />
                : <TidbitSelfScan />
          }
        </motion.div>
      </AnimatePresence>
      <div className="next" onClick={() => paginate(1)}>
        {"‣"}
      </div>
      <div className="prev" onClick={() => paginate(-1)}>
        {"‣"}
      </div>
    </StyledTidbitCarousel>
  )
}
