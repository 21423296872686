import {Flex, Tooltip, Text} from '@chakra-ui/react';

export const OverflowTextContainer = ({children, textProps}) => {
    return (
      <Flex overflow={'hidden'} mr={5} align="center" justify="start">
        <Tooltip label={children}>
          <Text
            fontSize={14}
            isTruncated
            whiteSpace={'nowrap'}
            alignSelf="center"
            {...textProps}
          >
            {children}
          </Text>
        </Tooltip>
      </Flex>
    );
  };
  