
import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { Tabs, Tab, TabList, Box } from '@chakra-ui/react';
import Select from 'react-select';
import { find } from 'lodash';
import { useIntl } from 'react-intl';

import { SearchInput } from '../Settings/ProfileList';

import { getIntlLocale, getIntlMessages, getProfiles, getSelectedProfile } from "../Authentication/_selectors";

import { actions } from './_actions';
import { constants } from './_constants';

import { formatName } from '../_helpers/utils';
import { getRequestFilter } from './_selectors';

import { actions as authActions } from '../Authentication/_actions';

import {
  StyledLabel,
  StyledInputLayout,
} from '../_styles/forms';
import { calculateSelectWidth } from '../_helpers/select';

// custom select component

const border = '0.5px solid #4D5666;';
const background = 'transparent';
const fontSize = '16px';
const height = '40px';
const borderRadius = "60px";
const boxSizing = 'border-box';
const float = 'right';
const settingsControlStyles = {
  background, fontSize, height, borderRadius, boxSizing, border, float
}


const settingsStyles = {
  container: (provided, state) => {
    const width = '100%';
    return { ...provided, width };
  },
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#273238' : '#C7D3D9';
    const paddingLeft = '7px'
    return { ...provided, color, paddingLeft };
  },
  control: (provided, state) => {
    const border = '0.5px solid #4D5666;';
    const background = 'transparent';
    const fontSize = '16px';
    const height = '40px';
    const borderRadius = "60px";
    const boxSizing = 'border-box';
    return { ...provided, border, background, fontSize, height, borderRadius, boxSizing };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      paddingLeft: "7px",
      color: "#999",
    }
  },
  dropdownIndicator: (provided, state) => {
    const color = '#273238';
    return { ...provided, color };
  },
  indicatorSeparator:  (provided, state) => {
    const backgroundColor = '#F9F9F9';
    return { ...provided, backgroundColor };
  },
  menu: (provided, state) => {
    const zIndex = 1080;
    return {...provided, zIndex}
  }
}
const SelectAdapterNonClearable = (
  {meta, items, label, type,  intlMessages , onChange, isLabelShowing,  width='100%',
    className="SelectAdapter", defaultValue, disabled, ...rest }) => {
  return(
    <StyledInputLayout className={className}>
      {isLabelShowing && <StyledLabel>{label}</StyledLabel>}
      <Select
        styles={{
          ...settingsStyles, 
          control: (provided) => ({...provided, ...settingsControlStyles, width})
        }}
        options={items}
        isDisabled={disabled}
        clearable={false}
        searchable={true}
        placeholder={intlMessages['profiles.search.placeholder']}
        value={find(items, {'value': defaultValue})}
        onChange={(value) => onChange(value)}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        {...rest}
      />
    </StyledInputLayout>
  )
}

export const ProfilesFilterSuper = ({fullWidth}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const profiles = useSelector(getProfiles);
  const selected = useSelector(getSelectedProfile);
  // overwriting styles for tabs

  const tabSx = {
    fontSize: 12,
    fontFamily: 'Proxima Nova Semibold',
    padding: '12px 18px',
    minHeight:'32px',
    padding: '5px 10px'
  }

  // setup some variables based on page
  let showAllOption = true;

  console.log("selected ", selected)

  useEffect(()=>{
    // initialize, set back to null and fetch all profiles except CHATRECORDATTACHMENT page
    showAllOption && dispatch(authActions.getProfiles());
    //dispatch({type: constants.UPDATE, data: {requestFilter: null}});
    return () => {};
  }, [])

  const handleChange = (profile) => {
    const newValue = profile?.id;
    // set requestFilter (profile.id) on change and fetch requests for that user
    dispatch({type: constants.UPDATE, data: {requestFilter: newValue}});
    // set profile value if newValue is not null (All)
    if (newValue){
      dispatch(actions.fetchRequestList({profile: newValue}));
      dispatch(authActions.selectProfile(profile))
    } else {
      dispatch(actions.fetchRequestList());
      dispatch(authActions.selectProfile(profile))
      //dispatch(actions.fetchRequestList());
    }[]
  };

  // loop through profiles and create items for select
  // also get max length of the select 
  let items = [{label: startCase(intlMessages['profiles.list.all']), value: null, id: null}];
  let maxWidth = 100;
  let currentProfileName = '';

  profiles.map((profile) => {
    let name = profile.first_name //formatMessage({id: 'format.fullName'}, {givenName: profile.first_name, familyName: profile.last_name})
    items.push({...profile, label: name, value: profile.id})
    // get the max width of the select (for the menu)
    if (calculateSelectWidth(name, intlLocale) > maxWidth){
        maxWidth = calculateSelectWidth(name, intlLocale)
    }
    // get the width of the current profile
    if (profile?.id == selected?.id){
      currentProfileName = name;
    }
  })

  let boxWidth = '100%'
  if (!fullWidth){
    boxWidth = `${maxWidth}px`;
  }


  return (
      <Box w={boxWidth} pb={'10px'}>
          <SelectAdapterNonClearable 
            name={'profile'}
            className={'SelectAdapter'}
            width={fullWidth ? '100%' : `${calculateSelectWidth(currentProfileName, intlLocale)}px`}
            items={items}
            intlMessages={intlMessages}
            defaultValue={selected?.id}
            onChange={handleChange}
            isLabelShowing={false}
          />
      </Box>
  )
}