import React, { useRef, useState, useEffect}  from 'react';
import cn from 'classnames';
import { useSelector, useDispatch} from 'react-redux';
import ReactPlayer from 'react-player';
import { localeFormatMessageTime } from '../../_helpers';

import {PaperclipIcon} from '../../_images/icons/PaperclipIcon';
import { LoadingCircle } from '../../_components/LoadingCircle';
import { RecordUploadMedia } from '../../_components/RecordUploadMedia'

import { services } from '../../Records/_services';

import { actions as partialScanActions } from '../../PartialScans/_actions';
import { actions as modalActions } from '../../Modals/_actions';
import { actions } from '../_actions';

import { getUUID } from '../../Chats/_selectors';
import { getIntlLocale, getIntlMessages } from '../../Authentication/_selectors';

import {
  StyledRecordMessage,
  StyledPartialScanMessage,
  StyledLoadingMessage,
  StyledTextMessage,
  StyledMessageContent,
  StyledMessageContentRecord,
  StyledMessage,
  StyledErrorDiv
}
from './styles'

import { VideoChatMessage as VideoChatTemplate } from './VideoChatMessage';

export const PartialScanMessage = ({msg, onClick}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <StyledPartialScanMessage>
      <div className="top">
        {intlMessages['chats.messageTemplates.partialScan.message.text']}
      </div>
      <div className="button" onClick={onClick}>
        {intlMessages['chats.messageTemplates.partialScan.message.button']}
      </div>
    </StyledPartialScanMessage>
  )
}

export const RecordMessage = ({msg}) => {
  const [ recordUpload, setRecordUpload] = useState(null);
  const [ error, setError] = useState(null);
  const intlMessages = useSelector(getIntlMessages);

  useEffect(()=>{
    async function f() {
      try{
        const record = await services.fetchRecordSimple(msg.text)
        //if (record && record.record_uploads.length > 0){
        //  setRecordUpload(record.record_uploads[0])
        //}
        if (record){
          setRecordUpload(record);
        }
      } catch (err) {
        setError(true);
        console.log("ERROR: recordmessage");
      }
    }
    f();
  }, []);

  if (error) {
    return (
      <StyledRecordMessage>
        <StyledErrorDiv>
          {intlMessages['chats.messageTemplates.recordmessage.errorFetching']}
        </StyledErrorDiv>
      </StyledRecordMessage>
    )
  }
  if (recordUpload){
    return(
      <RecordUploadMedia
        upload={recordUpload}
        imgWidth={225}
        imgHeight={225}
        marginLeft={0}
        />
    )
  } else {
    return (
      <StyledLoadingMessage>
        <LoadingCircle />
      </StyledLoadingMessage>
    )
  }
}

export const TextMessage = ({msg}) => {
  return (
    <StyledTextMessage>
      {msg.text}
    </StyledTextMessage>
  )
}

export const VideoChatMessage = ({msg}) => {
  const intlMessages = useSelector(getIntlMessages);
  if (msg?.data?.vid != "DAILYv1"){
    return (
      <StyledTextMessage>
        {intlMessages['chats.messageTemplates.invalid']}
      </StyledTextMessage>
    )
  }
  let action = msg.actions.filter(x => x.type == "status" && x.value == "completed")
  return (
    <VideoChatTemplate completed={action.length > 0} token={msg?.data?.token} room={msg?.data?.room} />
  )
}

export const LoadingMessage = ({msg}) => {
  return (
    <StyledLoadingMessage>
      <LoadingCircle />
    </StyledLoadingMessage>
  )
}

const MessageItemContent = ({messageFragment}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);

  const handlePartialScanClick = () => {
      dispatch(partialScanActions.setPartialScanId(messageFragment.text));
      dispatch(partialScanActions.setPartialScanMessageTimeToken(messageFragment.timetoken));
      dispatch(modalActions.openModal('partialScan'));
  }


  //const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
  if (messageFragment.type && messageFragment.type.toUpperCase() == "TEXT"){
    return (
      <StyledMessageContent>
        <TextMessage msg={messageFragment} />
      </StyledMessageContent>
    )
  } else if (messageFragment.type && messageFragment.type.toUpperCase().startsWith("RECORD")) {
    // TODO report
    return (
      <StyledMessageContentRecord>
        <RecordMessage msg={messageFragment} />
      </StyledMessageContentRecord>
    )
  } else if (messageFragment.type && messageFragment.type.toUpperCase() == "REPORT") {
    // TODO report
    return (
      <StyledMessageContent>
        {intlMessages['chats.messageTemplates.report.text']}
      </StyledMessageContent>
    )
  } else if (messageFragment.type && messageFragment.type.toUpperCase() == "PARTIALSCAN") {
    return (
      <PartialScanMessage onClick={handlePartialScanClick} msg={messageFragment} />
    )
  } else if (messageFragment.type && messageFragment.type.toUpperCase() == "VIDEOCHAT") {
    return (
      <VideoChatMessage msg={messageFragment} />
    )
  } else{
    return (<span></span>)
  }
}

const MessageItem = ({msg, senderMe}) => {
  const myUuid = useSelector(getUUID);
  const intlLocale = useSelector(getIntlLocale);

  if (!msg.text && msg.type.toUpperCase() == "TEXT"){
    return ( <span></span>)
  }
  return (
    <StyledMessage
      senderMe={senderMe || myUuid == msg.senderId }
      >
        <div className={cn('messageContent')}>
          <div className={cn('messageTime')}>
            {msg.timetoken && localeFormatMessageTime(msg.timetoken, intlLocale)}
          </div>
          <MessageItemContent messageFragment={msg} />
        </div>
    </StyledMessage>
  )
}

export const MessageListItem = ({ messageFragment, avatar, senderMe }) => {
  const sender = messageFragment.sender;
  //const [isHovering, hoverProps] = useHover({ mouseEnterDelayMS: 0 });
  return (
      <MessageItem msg={messageFragment} senderMe={senderMe} />
  );
};
