export const defaultSelectStyles = {
    dropdownIndicator: (provided, state) => {
      const display = state.hasValue ? 'none': 'block';
      return { ...provided, display };
    },
    indicatorsContainer: (provided) => {
      return { ...provided, padding: '0 20px' }
    },
    indicatorSeparator:  (provided) => {
      const display = 'none';
      return { ...provided, display };
    },
    placeholder: (provided) => {
      return { ...provided, color: '#7A8F99' }
    },
    valueContainer: (provided) => {
      return { ...provided, 
        padding: '0 20px'
      }
    },
    singleValue: (provided, state) => {
      return { ...provided, 
        color: state.isDisabled ? '#C5C5C5' : '#405159',
      }
    },
    control: (provided, state) => {
      return { ...provided, 
        boxShadow: state.isFocused ? '0 0 0 1px #405159' : 'none' , 
        borderColor: state.isFocused? '#405159' :'#C7D3D9', 
        height: 45,
        color: state.isDisabled ? '#C5C5C5' : '#405159',
        backgroundColor: state.isDisabled ? '#E1EBEC' : 'transparent',
        borderRadius: 30,
        "&:hover": {
          borderColor: '#405159'
        },
        "&:focus": {
          borderColor: '#405159'
        },
        "&:disabled": {
          borderColor: '#405159'
        },
      }
    },
    menu: (provided) => {
      return { ...provided,
        zIndex: 9999
      }
    },
    menuPortal: (provided) => {
      return { ...provided,
        zIndex: 9999
      }
    },
    
  
  }
  
export const errorStyles = {
    control: (provided, state) => {
      return { ...provided, 
        height: 45,
        color: '#405159',
        backgroundColor: 'transparent',
        borderRadius: 30,
        boxShadow: state.isFocused ? '0 0 0 1px #E95252' : 'none' , 
        borderColor: '#E95252', 
        "&:hover": {
          borderColor: '#E95252'
        },
      }
    },
}