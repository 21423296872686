import { constants } from './_constants';

export const actions = {
  getUnreadCounts,
  markChannelRead,
  addUnreadCount
}

function addUnreadCount(channel){
  return (dispatch) => {
    if (channel) {dispatch(update(channel))}
  }
  function update(channel){
    return { type: constants.UPDATE_CHANNEL_ADD, data: {channel}}
  }
}

function getUnreadCounts(channels, channelTimetokens){
  console.log("running unreadcount ")
  return (dispatch, getState, context) => {
    context.pubnub.api.messageCounts({
      channels,
      channelTimetokens
    }, (status, response) => {
      if (status.error){
        dispatch(failure(status.error));
      } else {
        dispatch(success(response));
      }
    })
  }
  function success(resp){
    return { type:constants.UPDATE_BYID, data: {byId: resp.channels}}
  };
  function failure(error){
    return { type:constants.UPDATE, data: {}}
  };
}

function markChannelRead(channel){
  console.log("markChannelRead")
  return (dispatch) => {
    if(channel){ dispatch(update(channel)) };

  }
  function update(channel){
    return {type:constants.UPDATE_CHANNEL, data: {count: 0, channel}}
  }
}
