
import React from 'react';

export const NoReportIcon = ({width=145, height=122, color="#405159"}) => {

  return(
<svg width={`${width}px`} height={`${height}px`}  viewBox="0 0 145 122" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path opacity="0.25" d="M2.68252 114.334H142.317C143.803 114.334 145 115.845 145 117.717C145 119.588 143.796 121.099 142.317 121.099H2.68252C1.19732 121.099 0 119.588 0 117.717C0 115.845 1.20386 114.334 2.68252 114.334Z" fill="#D8D9D8"/>
<path d="M116.882 0H28.136C25.1188 0 22.6729 2.44595 22.6729 5.46318V108.701C22.6729 111.718 25.1188 114.164 28.136 114.164H116.882C119.899 114.164 122.345 111.718 122.345 108.701V5.46318C122.345 2.44595 119.899 0 116.882 0Z" fill="#AEBDC2"/>
<path d="M114.82 16.1656V73.9117C112.622 72.9958 110.214 72.4985 107.688 72.4985H53.9463C53.8744 72.4985 53.8155 72.4985 53.7501 72.505H53.7435C53.7435 72.505 53.7304 72.4985 53.7304 72.505C50.4525 72.5508 47.4756 73.8267 45.238 75.8942C44.4659 76.5943 43.7855 77.399 43.2097 78.2692C41.8488 80.3105 41.0572 82.7575 41.0572 85.3942V88.7703C35.1753 84.668 31.3281 77.8505 31.3281 70.13V16.1656C31.3281 12.7895 34.063 10.0547 37.439 10.0547H108.722C112.092 10.0547 114.833 12.7895 114.833 16.1656H114.82Z" fill="#E3E7E8"/>
<path d="M54.0415 82.4102V93.5197C49.1803 93.4609 44.6789 91.9037 40.9561 89.3062V85.8189C40.9561 83.1037 41.7739 80.5717 43.1871 78.4649C43.7891 77.562 44.4826 76.7311 45.2873 76.0114C46.1772 75.7235 53.6032 73.5186 54.0481 82.4167L54.0415 82.4102Z" fill="#9EAEB3"/>
<path d="M54.0439 88.9831V82.4142C53.5925 73.5226 46.173 75.721 45.2832 76.0089C47.5928 73.8694 50.6679 72.5608 54.0439 72.5085C54.0439 72.502 54.057 72.502 54.0635 72.502H109.762C112.372 72.502 114.852 73.0123 117.122 73.961C119.87 75.0994 122.298 76.8463 124.228 79.0447C126.636 81.7861 128.284 85.2079 128.827 88.9831H54.0374H54.0439Z" fill="#F2F2F2"/>
<path d="M141.769 121.101H73.3257C67.555 121.101 62.3993 118.576 58.8728 114.559C55.8697 111.17 54.0508 106.707 54.0508 101.826V88.9766H128.841C128.978 89.8925 129.05 90.8347 129.05 91.7769V110.155C131.373 119.701 141.776 121.088 141.776 121.088L141.769 121.101Z" fill="#E3E7E8"/>
<path d="M87.6737 6.76758H58.4539C56.9471 6.76758 55.7256 7.98909 55.7256 9.4959V10.6147C55.7256 12.1215 56.9471 13.343 58.4539 13.343H87.6737C89.1806 13.343 90.4021 12.1215 90.4021 10.6147V9.4959C90.4021 7.98909 89.1806 6.76758 87.6737 6.76758Z" fill="#40525A"/>
<path d="M81.1701 4.72852H64.9637C64.4326 4.72852 64.002 5.15912 64.002 5.6903V7.8494C64.002 8.38058 64.4326 8.81118 64.9637 8.81118H81.1701C81.7013 8.81118 82.1319 8.38058 82.1319 7.8494V5.6903C82.1319 5.15912 81.7013 4.72852 81.1701 4.72852Z" fill="#40525A"/>
<path d="M55.7638 25.0566H45.688C43.0104 25.0566 40.8398 27.2272 40.8398 29.9048V39.9806C40.8398 42.6582 43.0104 44.8288 45.688 44.8288H55.7638C58.4414 44.8288 60.612 42.6582 60.612 39.9806V29.9048C60.612 27.2272 58.4414 25.0566 55.7638 25.0566Z" fill="#FAFAFB"/>
<path d="M82.0429 30.6504H66.4913C65.3603 30.6504 64.4434 31.235 64.4434 31.9561V33.2742C64.4434 33.9953 65.3603 34.5799 66.4913 34.5799H82.0429C83.174 34.5799 84.0909 33.9953 84.0909 33.2742V31.9561C84.0909 31.235 83.174 30.6504 82.0429 30.6504Z" fill="#FAFAFB"/>
<path d="M98.9385 38.5098H68.4574C66.2405 38.5098 64.4434 39.0943 64.4434 39.8155V41.1336C64.4434 41.8547 66.2405 42.4393 68.4574 42.4393H98.9385C101.155 42.4393 102.952 41.8547 102.952 41.1336V39.8155C102.952 39.0943 101.155 38.5098 98.9385 38.5098Z" fill="#FAFAFB"/>
<path d="M96.8103 53.4414H50.1556C46.7624 53.4414 44.0117 54.026 44.0117 54.7471V56.0652C44.0117 56.7863 46.7624 57.3709 50.1556 57.3709H96.8103C100.204 57.3709 102.954 56.7863 102.954 56.0652V54.7471C102.954 54.026 100.204 53.4414 96.8103 53.4414Z" fill="#FAFAFB"/>
<path d="M96.8103 62.0859H50.1556C46.7624 62.0859 44.0117 62.6705 44.0117 63.3916V64.7098C44.0117 65.4309 46.7624 66.0154 50.1556 66.0154H96.8103C100.204 66.0154 102.954 65.4309 102.954 64.7098V63.3916C102.954 62.6705 100.204 62.0859 96.8103 62.0859Z" fill="#FAFAFB"/>
<path d="M111.61 95.8789H69.9319C66.9007 95.8789 64.4434 96.4635 64.4434 97.1846V98.5027C64.4434 99.2238 66.9007 99.8084 69.9319 99.8084H111.61C114.641 99.8084 117.099 99.2238 117.099 98.5027V97.1846C117.099 96.4635 114.641 95.8789 111.61 95.8789Z" fill="#FAFAFB"/>
<path d="M116.947 106.098H74.0245C70.9027 106.098 68.3721 106.682 68.3721 107.403V108.721C68.3721 109.443 70.9027 110.027 74.0245 110.027H116.947C120.069 110.027 122.599 109.443 122.599 108.721V107.403C122.599 106.682 120.069 106.098 116.947 106.098Z" fill="#FAFAFB"/>
</g>
</svg>
  )
}
