import { createSelector } from 'reselect'

export const getMediaUploaderState = (state) => state.mediaUploader;

export const getCurrentSelected = createSelector(
  [getMediaUploaderState],
  (state) => {
    return state.currentSelected
  }
)

export const getMediaById = createSelector(
  [getMediaUploaderState],
  (state) => {
    return state.byId;
  }
)

export const getCurrentSelectedItem = createSelector(
  [getMediaById, getCurrentSelected],
  (byId, current) => {
    return byId[current]
  }
)

export const getMediaList = createSelector(
  [getMediaById],
  (items) => {
    return items
      ? Object.values(items)
          .map( (item) => {
            return item
          }).sort((a,b) => {
            if (a.getRecordedAt()  ==  b.getRecordedAt()){
              return 0;
            } else if (a.getRecordedAt() < b.getRecordedAt()){ // earliest first < , latest first >
              return -1;
            } else {
              return 1;
            }
          })
      : [];
  }
)

export const getMediaToUpload = createSelector(
  [getMediaById],
  (items) => {
    return items
      ? Object.values(items)
          .filter(item => item.uploaded === false)
          .map((item) => { return item})
          .sort((a,b) => {
            if (a.getRecordedAt()  ==  b.getRecordedAt()){
              return 0;
            } else if (a.getRecordedAt() < b.getRecordedAt()){ // earliest first < , latest first >
              return -1;
            } else {
              return 1;
            }
          })
      : [];
  }
)

export const getMediaCountByRegion = createSelector(
  [getMediaById],
  (items) => {
    // need to have regions 1,2,3,4
    let regions = {0:0, 1:0, 2:0, 3:0};
    Object.values(items)
      .filter(item => item.getRegion() != null)
      .map((item) => { regions[item.getRegion()] += 1 })
    return regions;
  }
)

export const getMediaByRegion1 = createSelector(
  [getMediaById],
  (items) => {
    return items
      ?
      Object.values(items)
        .filter(item => item.getRegion() == 0)
        .map((item) => { return item })
        .sort((a,b) => {
          if (a.getRecordedAt()  ==  b.getRecordedAt()){
            return 0;
          } else if (a.getRecordedAt() < b.getRecordedAt()){ // earliest first < , latest first >
            return -1;
          } else {
            return 1;
          }
        })
      : [];
  }
)

export const getMediaByRegion2 = createSelector(
  [getMediaById],
  (items) => {
    return items
      ?
      Object.values(items)
        .filter(item => item.getRegion() == 1)
        .map((item) => { return item })
        .sort((a,b) => {
          if (a.getRecordedAt()  ==  b.getRecordedAt()){
            return 0;
          } else if (a.getRecordedAt() < b.getRecordedAt()){ // earliest first < , latest first >
            return -1;
          } else {
            return 1;
          }
        })
      : [];
  }
)

export const getMediaByRegion3 = createSelector(
  [getMediaById],
  (items) => {
    return items
      ?
      Object.values(items)
        .filter(item => item.getRegion() == 2)
        .map((item) => { return item })
        .sort((a,b) => {
          if (a.getRecordedAt()  ==  b.getRecordedAt()){
            return 0;
          } else if (a.getRecordedAt() < b.getRecordedAt()){ // earliest first < , latest first >
            return -1;
          } else {
            return 1;
          }
        })
      : [];
  }
)

export const getMediaByRegion4 = createSelector(
  [getMediaById],
  (items) => {
    return items
      ?
      Object.values(items)
        .filter(item => item.getRegion() == 3)
        .map((item) => { return item })
        .sort((a,b) => {
          if (a.getRecordedAt()  ==  b.getRecordedAt()){
            return 0;
          } else if (a.getRecordedAt() < b.getRecordedAt()){ // earliest first < , latest first >
            return -1;
          } else {
            return 1;
          }
        })
      : [];
  }
)

export const getMediaByRegion = createSelector(
  [getMediaById],
  (items) => {
    let regions = {1:[], 2:[], 3:[], 4:[]};
    Object.values(items)
      .filter(item => item.getRegion() != null)
      .map((item) => { regions[item.getRegion()] = [...regions[item.getRegion()], item] })
  }
)
