import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Link, useLocation, useParams, useHistory, Redirect } from 'react-router-dom';

import { localeFormatDate } from '../_helpers';
import { Button, Flex, Text, Box } from '@chakra-ui/react';
// components
import { BiRadar } from "react-icons/bi";
import { LoadingEllipses } from '../_components/LoadingEllipses';
import { RecordUploadMedia } from '../_components/RecordUploadMedia';

// actions
import { actions as recordActions } from '../Records/_actions';
import { actions as modalActions } from '../Modals/_actions';

// selectors
import { getIntlLocale, getIntlMessages } from '../Authentication/_selectors';
import { getRecordsLoading, getAllRecords, getRecordsIsMore } from '../Records/_selectors';


const RecordItem = ({record, selectedRecord, handleClick}) => {
  const dispatch = useDispatch();
  const intlLocale = useSelector(getIntlLocale);
  const intlMessages = useSelector(getIntlMessages);

  const selected = selectedRecord == record.id;

  const onClick = () => {
    //dispatch(recordActions.fetchRecordDetail(record.id));
    handleClick(record.id)
    //dispatch(recordActions.selectRecord(record.id));
    //dispatch(modalActions.openModal('record'));
  }

  /*
    record.is_detect &&
      (<div className={'recordDetection'}>
        <BiRadar />
      </div>)
  */

  return (
    <Flex 
      w={['full']} 
      direction={['column']} 
      bg={selected ? '#273238' : '#FFF'} 
      p={['25px']}
      py={'15px'}
      borderRadius={'10px'}
      mt={'15px'}
      onClick={onClick}
      _hover={{cursor: 'pointer'}}
    >
        <Text fontSize={['20px']} color={selected ? '#F7F7F7' : '#12171A'}>
          {intlMessages[`config.RECORD_TYPES.${record.type}`]}
        </Text>
        <Text fontSize={['14px']} color={selected ? '#6B8A99' : '#405159'}>
          {record.created_at && localeFormatDate(record.created_at, intlLocale, "lll")}
        </Text>
        <Flex w={['full']} mt={['10px']}>
          <RecordUploadMedia
            upload={record}
            imgHeight={50}
            imgWidth={50}
            imgBorderRadius={4}
          />
          {record.upload_count > 1 && (
            <Flex align={['center']} px={['15px']}>
              <Text fontSize={['14px']}  color={selected ? '#6B8A99' : '#405159'}>
                {'+ '}{record.upload_count - 1}
              </Text>
            </Flex>
          )}
        </Flex>
    </Flex>
  )

}

const IsMore = ({lastItem}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const recordsLoading = useSelector(getRecordsLoading);

  const onClick = () => {
    dispatch(recordActions.fetchRecords({'last_ts': lastItem.created_at_ts}))
  }

  return (
    <Flex w={['full']} py={['20px']}>
      <Flex w={['full']} justify={['center']} align={['center']} padding={['0 15px']} _hover={{cursor: 'pointer'}}>
        {!recordsLoading
          ?
            <div onClick={onClick}>
              {intlMessages['settings.recordHistory.loadMore']}
            </div>
          :
            <div>
              <LoadingEllipses />
            </div>
        }
      </Flex>
    
    </Flex>
  )
}

export const ScanRecordsHistory = ({onSelect=null, partial=false, profileId=null, goBack=null}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [ selectedRecord, setSelectedRecord ] = useState(null);
    const intlMessages = useSelector(getIntlMessages);
    const intlLocale = useSelector(getIntlLocale);
    const recordsLoading = useSelector(getRecordsLoading);
    let recordsList = useSelector(getAllRecords);
    const isMore = useSelector(getRecordsIsMore);
  
    let [ succeeded, setSucceeded] = useState(null);
  
    let params = new URLSearchParams(location.search);
  
    let isScanning = onSelect ? true : false;
  
    const handleClick = () => {
      if (isScanning){
        onSelect(selectedRecord);
        return;
      }
    }
  
    const handleRecordSelect = (recId) => {
      // there is a next screen, otherwise open modal
      if (isScanning){
        setSelectedRecord(recId);
      } else {
        let newParams = new URLSearchParams();
        newParams.set("rec", recId);
        history.push((`${location.pathname}?${newParams.toString()}`))
      }
    }
  
  
    useEffect(()=>{
      let rec = params.get('rec');
      dispatch(recordActions.fetchRecords({profile: profileId || ''}))
      // record has been selectoed, open it
      if (rec){
        dispatch(recordActions.selectRecord(rec));
        dispatch(modalActions.openModal('recordDetail'));
      }
    }, [location.search, profileId])
    
  
    let lastRecord = recordsList[recordsList.length - 1];
  
    //if (profileId){
    //  recordsList = recordsList.filter(rec => rec.user_profile?.id == profileId);
    //}
  
    return (
      <Box bg={'#F7F7F7'} >
        {!partial && <h2>{intlMessages['settings.menu.menulist.menuItem.recordshistory.title']}</h2>}
        <Box pt={'20px'} position={'relative'} height={'660px'} overflow={'auto'} overflowX={'hidden'}>
          { !isMore && recordsLoading && <div className={'loading'}><LoadingEllipses /></div>}
          <Flex w={['full']} minW={`650px`} direction={['column']} px={['40px']}>
            {
              recordsList.filter(x => x.user_profile?.id == profileId).map((elem, index) =>
                <RecordItem key={elem.id} record={elem} handleClick={handleRecordSelect} selectedRecord={selectedRecord} />
              )
            }
          </Flex>
          { isMore && <IsMore lastItem={lastRecord} />}
        </Box>
        {isScanning && (
          <Flex w={'full'} p={['20px']} bg={'#F7F7F7'}>
            <Button 
                variant={'outlineDefault'} 
                w={['full']}
                onClick={goBack}
                mr={'10px'}
            >
                {intlMessages['clinicSearchPage.clinicDetails.goBack']}
            </Button>
            <Button 
              variant={'solid'} 
              w={['full']}
              isDisabled={!selectedRecord}
              onClick={handleClick}
              ml={'10px'}
            >
                {intlMessages['tidbits.next']}
            </Button>
          </Flex>
        )}
      </Box>
    )
  }