import {
    Flex,
    Box,
    Grid,
    Avatar, 
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,

} from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

const MoreInfoItem = ({children, title}) => {
    return (
        <AccordionItem
            mt={['10px']}
            borderRadius={['15px']}
            isFocusable={false}
        >
            <Box py={['10px']}>
                <AccordionButton _hover={{bg: 'transparent'}} _focus={{outline: 'none'}} px={[0]}>
                    <Flex w={['full']} justify={['space-between']} align={['center']} px={['10px']}>
                        <Flex align={['center']}>
                            <Text fontSize={['30px']} color={'#273238'}>
                               {title}
                            </Text>
                        </Flex>
                        <AccordionIcon />
                    </Flex>
                </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
                {children}
            </AccordionPanel>
        </AccordionItem>
    )
}

const RemarkItem = ({remark}) => {
    return (
        <MoreInfoItem 
            title={(<FormattedMessage
                id="requestDetailPage.reportsection.patientcomments.title"
                defaultMessage="Your Comments"
            />)}
        >
            <Flex>
                <Text 
                color={'#405159'} 
                fontSize={['15px']}
                >
                {remark}
                </Text>
            </Flex>
        </MoreInfoItem>
    )
}
const DentistItem = ({dentist}) => {
    const { formatMessage } = useIntl();
    let name = formatMessage({id: "format.fullName"}, {givenName: dentist?.first_name, familyName: dentist?.last_name})

    return (
        <MoreInfoItem 
            title={(<FormattedMessage 
                id="requestDetailPage.reportsection.dentist.title"
                defaultMessage="Dentist"
            />)}
        >
            <Flex>
                <Grid 
                    templateColumns="2fr 6fr 2fr" templateRows="1fr" gridGap={'12px'}
                    position={'relative'}
                >
                    <Flex align="center" justify="center">
                        {/* dentist avatar */}
                        <Avatar w="50px" h="50px" bg="#405159" color={'#fff'} name={name} src={dentist?.avatar} />
                    </Flex>
                    <Flex direction="column">
                        {/* dentist details */}
                        <Box>
                            <Text fontSize="20px" color="#111619">
                                {`Dr. ${name}`}
                            </Text>
                        </Box>
                        <Box>
                            <Text fontSize="14px" color="#405159">{dentist?.clinic?.name}</Text>
                        </Box>
                    </Flex>
                </Grid>
            </Flex>
        </MoreInfoItem>
    )
}

export const ReportMoreInfo = ({dentist, remark}) => {
    return (
        <Box>
            <Accordion allowToggle>
                {/*<DentistItem dentist={dentist} />*/}
                <RemarkItem remark={remark} />
            </Accordion>
        </Box>
    )
}