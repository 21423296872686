import { REPORTCHART_TYPES } from '../_config';
import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { createSelector } from 'reselect';
import { Flex, Box, Grid, Text, Circle, Button, useDisclosure } from '@chakra-ui/react';
import cn from 'classnames';
import {
  localeFormatDate,
  formatName,
  checkNotificationArrayRead,
  checkNotificationRead
} from '../_helpers'
import { Scrollbars } from 'react-custom-scrollbars';
import _, { upperFirst, startCase, toUpper } from 'lodash';
import { NoReportIcon } from '../_images/icons/NoReportIcon';

import { CategoriesFilterSelect } from './CategoriesFilterSelect'

import cameraIcon from '../_images/camera.png'
import reportIcon from '../_images/reportIcon.png'
import { BlankCircle } from '../_images/icons/BlankCircle';
import { LoadingEllipses } from '../_components/LoadingEllipses';
import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { ReportSection, ReportSectionDMFT } from './ReportReview';

// actions
import { actions as reportActions } from '../Reports/_actions';
import { actions as recordActions } from '../Records/_actions';
import { actions as checkupActions } from '../Checkups/_actions';
import { actions as notificationActions } from '../Notifications/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { actions as authActions } from '../Authentication/_actions';
import { actions } from './_actions';


import { getIntlLocale, getIntlMessages, getProfile, getSelectedProfile, getUUID } from '../Authentication/_selectors';
import { getNotificationsByRequestId } from '../Notifications/_selectors';
import {
  getCurrentRequestId,
  getRequestReport,
  getRequestDetailsId,
  getRequestDetailsUniqueId,
  getRequestRecordId,
  getChartCategory,
  getRequestDetails,
  getRequestList,
  getRequestListIsMore,
  getReportCharts,
  getFilteredReportRegions,
  getFilteredReportCharts,
  getRecordUploads,
  getReportListLoading,
  getReportLoading,
  getRequestListWithFilter,
  getReportCategoriesCount
}
from './_selectors';
import { UnstyledLink } from '../_styles/common';

import {
  Wrapper,
  SectionPage,
  SectionTitle,
  StyledSectionWrapper,
  StyledSection,
  StyledReportSection,
  StyledReportInformation,
  StyledHistorySection,
  StyledReportChartCategories,
  StyledImageSection,
  StyledImageBoxes,
  StyledChartColumn,
  StyledDetailColumn,
  StyledGradeLabel,
  StyledCategoryLabel,
  StyledReportChartCategoryItem,
  StyledReportChartInformation,
  StyledReportChartInformationGeneral,
  StyledReportChartInformationCategory,
  StyledReportChartInformationComment,
  StyledReportChartInformationRegions,
  StyledReportToothChart,
  StyledHistoryList,
  StyledHistoryListItem,
  StyledBlankReportSection,
  StyledIsMore
}
from './styles';

import { StatusTag } from './components/StatusTag';
import { ProfilesFilterSuper } from './ProfilesFilterSuper';
import { isPaymentRequired, isCompleted } from '../_helpers/reports';
import { ChartSection } from './ReportReview';
import { FormattedMessage } from 'react-intl';
import { ReportMoreInfo } from './components/ReportMoreInfo';
import { CheckupRequestHistory } from './components/CheckupRequestHistory';
import { useReportStore } from './state/report';
import { GenerativeReportSection } from './GenerativeReportDetail';
import storage from '../_helpers/storage';
import { ReportPrintPreview } from './components/ReportPreview';
import { GenerativeReportInspectionModal, InspectionReviewSentModal } from './GenerativeReportInspectionModal';
import { useRequestDetails } from './api/useRequestQuery';
import { ReportNotAvailableSection } from './components/ReportNotAvailableSection';

export const BlankRequestHistoryItem = () => {
  const intlMessages = useSelector(getIntlMessages)
  return(
    <StyledHistoryListItem
      selected = {false}
      blank = {true}
      onClick={() => {}}
      >
          <button disabled className={'camera'}><BlankCircle /></button>
          <p className='blank'>{intlMessages['reportDetail.history.noReport']}</p>
    </StyledHistoryListItem>
  )
}

const OptionLabel = ({cat}) => {
  let color = '#000'
  switch(cat){
      case 'COLOR':
          color = '#367FFF'
          break;
      case 'ORTHO':
          color = '#00574F'
          break;
      case 'HABITS':
          color = '#daff00'
          break;
      case 'GUMS':
          color = '#daff00'
          break;
      case 'CARIES':
          color = '#DC4E41'
          break;
      case 'CALCULUS':
          color = '#ffa500'
          break;
      default:
          // ALL
          break;
  }
  return (
      <Flex
          justifyContent={'center'}
          align={'center'}
          pr={'8px'}
          pl={'0px'}
      >
          <Circle 
              boxSize={'10px'}
              backgroundColor={color}
          />
      </Flex>
  )
}

const CategoryLabel = ({cat}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <Flex>
      <OptionLabel cat={cat} px={'2px'} />
      <Text
        fontSize={'22px'}
        color={'#273238'}
      >
        {intlMessages[`toothChart.category.${cat}`]}
      </Text>
    </Flex>
  )

}

const GradeLabel = ({grade}) => {
  const intlMessages = useSelector(getIntlMessages);

  return (
    <StyledGradeLabel
      className={`GRADE_${grade}`}
    >
      <span>
        {intlMessages[`requestItem.report.grade.${grade}`]}
      </span>
    </StyledGradeLabel>
  )
}


export const ReportChartCategories = () => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const chartCategory = useSelector(getChartCategory);

  const selectCategory = (cat) => {
    dispatch(actions.selectReportCategory(cat))
  }

  return (
    <StyledReportChartCategories>
      <ul>
        <StyledReportChartCategoryItem
          onClick={() => selectCategory(null)}
          selected={chartCategory==null}
          className="ALL"
          >{intlMessages['toothChart.category.ALL']}
          </StyledReportChartCategoryItem>
          {REPORTCHART_TYPES.map((elem) => {
            return (
              <StyledReportChartCategoryItem
                key={elem.value}
                onClick={() => selectCategory(elem.value)}
                selected={chartCategory== elem.value}
                className={elem.value}
                >
                {intlMessages[`toothChart.category.${elem.value}`]}
              </StyledReportChartCategoryItem>
            )
          })}
      </ul>
    </StyledReportChartCategories>
  )
}

const sortTeeth = (a,b) => {
  if (parseInt(a.replace("t", "")) === parseInt(b.replace("t", ""))){
    return 0;
  } else if (parseInt(a.replace("t", "")) > parseInt(b.replace("t", ""))){
    return 1;
  } else {
    return -1;
  }
}

export const ReportChartCategoryInformation = ({regions, comment, grade , category}) => {
  const intlMessages = useSelector(getIntlMessages);
  const currRegions = (regions && regions.length > 0) ? regions.sort(sortTeeth) : [];
  return (
    <StyledReportChartInformationCategory>
      <Flex direction={'column'} pb={'10px'}>
        <CategoryLabel cat={category} />
        <Flex>
          <Text
            color={'#6B8A99'}
            fontSize={'16px'}
            textTransform={'uppercase'}
            mr={'8px'}
          >
            {'Remarks'}
          </Text>
          <GradeLabel grade={grade} />
        </Flex>
      </Flex>
      <StyledReportChartInformationRegions>
        { currRegions.length < 1
          ? <li className="ALL">{intlMessages['toothChart.category.ALL']}</li>
          : currRegions.map(reg => <li>{reg.replace("t", "")}</li>) }
      </StyledReportChartInformationRegions>
      <StyledReportChartInformationComment>
        {comment}
      </StyledReportChartInformationComment>
    </StyledReportChartInformationCategory>
  )
}

export const ReportChartInformation = () => {
  const dispatch = useDispatch();
  const regionCharts = useSelector(getFilteredReportCharts);
  const request = useSelector(getRequestDetails);
  const intlMessages = useSelector(getIntlMessages)
  const reportLoading = useSelector(getReportLoading);
  const requestNotifications = useSelector(getNotificationsByRequestId);
  const requestUuid = useSelector(getRequestDetailsUniqueId);
  const chatUuid = useSelector(getUUID);

  useEffect(()=>{
    // trigger mark request notification read here. after loading is finished
    if (!reportLoading && requestUuid){
      let notifs = requestNotifications[requestUuid]
      ? requestNotifications[requestUuid].filter(elem=>!checkNotificationRead(elem, chatUuid))
      : [];
      notifs && (notifs.length > 0) && !checkNotificationArrayRead(notifs, chatUuid) && dispatch(notificationActions.markNotificationArrayRead(chatUuid, notifs));
    }
  }, [reportLoading, requestUuid])

  let generalTitle = intlMessages["reportDetail.reportchartinformation.general.title"];
  let categoryTitle = intlMessages["reportDetail.reportchartinformation.category.title"];
  let generalComment = "";
  let categoryComment = "";
  if (request && request.report && request.report.sent_at){
    generalComment = request.report.comment;
  } else if (request && request.status == "INIT"){
    generalTitle = intlMessages['reportDetail.reportchartinformation.general.title.pending'];
    categoryTitle = ''
  } else if (request && request.status == "PENDING"){
    generalTitle = intlMessages['reportDetail.reportchartinformation.general.title.pending'];
    categoryTitle = ''
  } else if (request && request.status == "ACCEPTED"){
    generalTitle = intlMessages['reportDetail.reportchartinformation.general.title.pending'];
    categoryTitle = ''
  }


  return (
    <Scrollbars style={{position:'relative', height:'780px'}}>
      { reportLoading &&
        <div className={'loading'}>
          <LoadingEllipses />
        </div>
      }
      <StyledReportChartInformation>
        <h4>{generalTitle}</h4>
        <StyledReportChartInformationGeneral>
          <StyledReportChartInformationComment>
            {generalComment}
          </StyledReportChartInformationComment>
        </StyledReportChartInformationGeneral>
        {
          (regionCharts && regionCharts.length > 0)
            ? regionCharts.map((elem) =>
              <ReportChartCategoryInformation
                regions = {(elem.extra_data && elem.extra_data.all_regions) || []}
                comment = {elem.comment}
                grade = {elem.grade}
                category = {elem.extra_data?.type}
              />)
            : ''
        }
      </StyledReportChartInformation>
    </Scrollbars>
  )
}

export const RecordUploadsSection = () =>{
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const uploads = useSelector(getRecordUploads);
  const reportLoading = useSelector(getReportLoading);
  const reportRecordId = useSelector(getRequestRecordId);

  const showRecord = (id) => {
    let newParams = new URLSearchParams();
    newParams.set("rec", 1);
    history.push((`${location.pathname}?${newParams.toString()}`))
  }

  useEffect(()=>{
    if (!params.requestId){
      dispatch(recordActions.selectRecord(null))
    } else if (!reportLoading && reportRecordId && params.requestId){
      dispatch(recordActions.selectRecord(reportRecordId))
    }
  }, [reportLoading, reportRecordId, params.requestId])

  return(
    <StyledImageSection>
      <StyledImageBoxes>
        { reportLoading &&
          <div className={'loading'}>
            <LoadingEllipses />
          </div>
        }
        <div>
        {(uploads.length > 0) ? uploads.slice(0,4).map((elem, ind) =>
            <RecordUploadMedia
              key={elem.id}
              onClick={() => showRecord(elem.id)}
              upload={elem}
              imgWidth={80}
              imgHeight={80}
              imgBorderRadius={10}
              alt={"recordupload"+ind}
              />
          )
          : <RecordUploadMedia
              imgWidth={80}
              imgHeight={80}
              imgBorderRadius={10}
              alt={"blank"}
              />
        }
        </div>
      </StyledImageBoxes>
    </StyledImageSection>
  )

}

const RequestInspectionButton = ({requestInspection, request, isOpen, onClose, onReportInspection, inspectWasSent}) => {
  let intlLocale = useSelector(getIntlLocale);
  let { isOpen: isInspectingOpen , onOpen, onClose: onInspectingClose } = useDisclosure({defaultIsOpen: request?.status == 'INSPECTING'});
  // if already requested, show message, not the button
  const _renderButton = () => {
    if (request?.user_report?.needs_inspection == true || inspectWasSent == true){
      if (request?.report?.status == 3){
        /*return (
            <Text textTransform={'uppercase'} color="#6B8A99">
              <FormattedMessage
                id={'reportGenerative.details.inspection.label.completed_on'}
                defaultMessage={'Review completed on: {date}'} 
                values={{date: localeFormatDate(request?.report?.sent_at, intlLocale, "LL")}}
              />
            </Text>
        )*/
        return (<></>)
      } else {
        /*return (
            <Text textTransform={'uppercase'}>
              <FormattedMessage
                id={'reportGenerative.details.inspection.label.requested'}
                defaultMessage={'Review has been requested'} 
              />
            </Text>
        )*/
        return (<></>)
      }
    } else {
      return (
        <Button variant={'ghost'} color={'#44C5A6'} onClick={requestInspection} size={'sm'}>
            <FormattedMessage 
                id={'reportGenerative.details.inspection.label'}
                defaultMessage={'Something wrong? Request Review'}
            />
        </Button>
      )
    }
  }

  if (request?.user_report?.status != "COMPLETE"){
      return <></>
  } 
  return (
  <>
      <GenerativeReportInspectionModal 
          reportId={request?.user_report?.id} 
          isOpen={isOpen} 
          onClose={onClose} 
          onSuccess={onReportInspection}
      />
      <InspectionReviewSentModal 
          isOpen={isInspectingOpen}
          onClose={onInspectingClose}
      />
      <Flex w={'full'} justify={'center'} mt={'20px'}>
        {_renderButton()}
      </Flex>
  </>
  )
}

export const ReportColumn = ({dateText}) => {
  const dispatch = useDispatch();
  const [ inspectWasSent, setInspectWasSent ] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const requestId = useReportStore(state => state.selectedReport);
  //const request = useSelector(getRequestDetails);
  const { data : request, isLoading, isFetching } = useRequestDetails({ requestId }); 

  const openScan = () => {
    dispatch(modalActions.openModal('scanningPage'))
  }

  const openPayment = () => {
    // set payment intent and open modal. modal checks state of request
    if (request?.payment){
      dispatch(checkupActions.setPaymentIntent(request))
      dispatch(modalActions.openModal('scanningPage'))
    } else {
      dispatch(checkupActions.setPaymentIntent(request))
      dispatch(modalActions.openModal('scanningPage'))
    }
  }

  // set data for report.

  let sectionData = request?.report?.report_data

  // if should be user report if GEM_DMFT
  if (request?.type == "GEN_DMFT") { // TODO "GENERATIVE"?
    // if insepected report is complete, then return the .report, not .user_report.
    // for some reason dentist report COMPLETE ==3 , not "COMPLETE"
    if (request?.user_report?.needs_inspection == true && request?.report?.status == 3){
      sectionData = request?.report?.report_data
    } else {
      sectionData = request?.user_report?.report_data
    }
  }

  console.log("ReportSectionDMFT request ", request)
  console.log("ReportSectionDMFT sectionData ", sectionData)


  const isDMFT = request?.type == "DMFT" || request?.type == "GEN_DMFT";

  const onReportInspection = () => {
    let newData = {
        ...request,
        status: 'INSPECTING',
        user_report: {
            ...request.user_report,
            needs_inspection: true
        }
    }
    setInspectWasSent(true)
    dispatch(reportActions.updateRequestData(newData))
  }

  const requestInspection = () => {
    onOpen();
  }

  //console.log("track ReportColumn ", data, request, sectionData, " isdmft ", isDMFT)

  if (!requestId || isLoading || isFetching){
    return (
      <ReportNotAvailableSection />
    )
  /*} else if (!isCompleted(request) && isPaymentRequired(request)){
    // TODO cna there be an instance where payment is needed but request is completed?
    return (
      <StyledBlankReportSection>
        <NoReportIcon />
        <p>
          {intlMessages['dashboard.toothchart.payNow']}
        </p>
        <p className={'bookScan'} onClick={openPayment}>
          {intlMessages['requestItem.button.payNow']}
        </p>
      </StyledBlankReportSection>
    )*/
  } else {
    return (
      <StyledReportSection>
        {/*<Text
          fontFamily={'Proxima Nova'}
          fontSize={'30px'}
          color={'#273238'}
        >
          {intlMessages['reportDetail.reportchartinformation.dentistcomments']}
        </Text> */}
        <StyledReportInformation>
          <Flex pl={['20px']} direction={'column'} grow={[1]} w={['full']}>
            <Box>
              <Flex align={['center']} flexWrap={'wrap'}>
                <Text
                  color="#273238"
                  fontSize={['25px']}
                  mr={['15px']}
                >
                  {/*<ScanTypeText type={request?.record?.type} />*/}
                  <FormattedMessage
                    id="format.fullName"
                    values={{
                      givenName: request?.user_profile?.first_name,
                      familyName: request?.user_profile?.last_name
                    }}
                    />
                </Text>
                <StatusTag status={request?.status} request={request} inspectWasSent={inspectWasSent}/>
              </Flex>
              <Text color="#6B8A99" fontSize={['16px']}>
                {dateText}
              </Text>
            </Box>
            { isDMFT ? (
              <ReportSectionDMFT 
                status={request?.status} 
                request={request} 
                data={sectionData} 
              />
            ) : (
              <ReportSection 
                status={request?.status} 
                request={request} 
                data={sectionData} 
              />
            ) }
            {(request?.type == "GEN_DMFT") && (
              <>
                <GenerativeReportInspectionModal 
                    reportId={request?.user_report?.id} 
                    isOpen={isOpen} 
                    onClose={onClose} 
                    onSuccess={onReportInspection}
                />
                <RequestInspectionButton 
                    requestInspection={requestInspection}
                    request={request}
                    inspectWasSent={inspectWasSent}
                />
              </>
            )}
            {storage.getIsDentalType() && <ReportMoreInfo remark={request?.remark}  dentist={request?.dentist} />}
          </Flex>
        </StyledReportInformation>
      </StyledReportSection>
    )
  }
}

const ScanTypeText = ({type}) => {
  if (type == 'SELF'){
    return (
      <FormattedMessage
        id="requestItem.scanType.SELF"
        defaultMessage="Self Scan"
        />
    )
  } else {
    return (
      <FormattedMessage
        id="requestItem.scanType.FULL"
        defaultMessage="Guided Scan"
        />
    )
  }
}

export const getSelectedProfileRequests = createSelector(
  [getRequestList, getSelectedProfile],
  (requests, selectedProfile) => {
    return requests.filter(elem => elem.user_profile?.id == selectedProfile?.id)
  }
)

export const DentalReportSection = ({isLoading, data, dateText, status=null, type=null}) => {
  return (
  <Flex>
    <Box>
      <StyledReportToothChart>
        { isLoading &&
          <div className={'loading'}>
            <LoadingEllipses />
          </div>
        }
        <ChartSection type={type} data={data} />
      </StyledReportToothChart>
      <RecordUploadsSection />
    </Box>
    <ReportColumn dateText={dateText} />
  </Flex>)
}


export const DMFTReportSection = ({isLoading, data, dateText}) => {
  return (
  <Flex>
    <Box>
      <StyledReportToothChart>
        { isLoading &&
          <div className={'loading'}>
            <LoadingEllipses />
          </div>
        }
        <ChartSection data={data} />
      </StyledReportToothChart>
      <RecordUploadsSection />
    </Box>
    <ReportColumn dateText={dateText} />
  </Flex>)
}

export const ReportDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let location = useLocation();
  const setCategory = useReportStore(state => state.setCategory);
  const setSelectedReport = useReportStore(state => state.setSelectedReport);
  let params = useParams();
  let intlMessages = useSelector(getIntlMessages);
  let intlLocale = useSelector(getIntlLocale);
  let request = useSelector(getRequestDetails);
  let report = useSelector(getRequestReport);
  let requestList = useSelector(getRequestList);
  let reportLoading = useSelector(getReportLoading); 
  let currentRequestId = useSelector(getCurrentRequestId);
  let reportCategoryCount = useSelector(getReportCategoriesCount);
  let reportRegions = useSelector(getFilteredReportRegions);
  let reportCharts = useSelector(getReportCharts);
  let requestListLoading = useSelector(getReportListLoading);
  let reportRecordId = useSelector(getRequestRecordId);
  const profile = useSelector(getProfile);
  const selectedProfile = useSelector(getSelectedProfile);
  const selectedProfileRequests = useSelector(getSelectedProfileRequests);
  let dateText = "";
  if (report && report.sent_at){
    dateText = localeFormatDate(report.sent_at, intlLocale, "dddd, LL")
  } else if (request && request.created_at){
    dateText = localeFormatDate(request.created_at, intlLocale, "dddd, LL")
  } else {
    dateText = '';
  }
  // set default strings and variables
  let patient_name = '';

  let reportTitle = "Request"
  if (request && request.report && request.report.sent_at){
    reportTitle = "Result"
  }

  useEffect(() => {
    return () => {
      dispatch(reportActions.selectRequest(null));
      dispatch(recordActions.selectRecord(null));
      dispatch(authActions.selectProfile(null));
      dispatch(reportActions.fetchRequestList())
      setSelectedReport(null);
    }
  }, [])

  useEffect(()=>{
    // get request Id from current url location

    if (params.requestId && !requestListLoading){
      // if request found in url, load request params.requestId from API
      dispatch(reportActions.selectRequest(params.requestId))
      setSelectedReport(params.requestId)
    } else {
      dispatch(reportActions.selectRequest(params.requestId))
      setSelectedReport(params.requestId)
      //if (requestList && requestList.length > 0)
      // if no request is selected, redirect to first in list
      //history.push(`/requests/${requestList[0].id}`)
      //dispatch(reportActions.selectRequest(requestList[0].id))
    }
    dispatch(reportActions.selectReportCategory(null))
    
  }, [params.requestId])

  /*useEffect(()=>{
    console.log("selectedProfileRequests report ", report)
    console.log("selectedProfileRequests selectedProfileRequests ", selectedProfileRequests)
    if (report?.user_profile){
      // make sure this was initialized first,
      if (selectedProfile && report?.user_profile?.id && selectedProfile.id != report.user_profile.id){
        console.log("selectedProfileRequests selectedProfile ", selectedProfile)
        console.log("selectedProfileRequests report?.user_profile?.id ", report?.user_profile?.id)
        console.log("selectedProfileRequests selectedProfileRequests.length ", selectedProfileRequests.length)
        // if profile was changed, and the current report does not match the profile, redirect to latests report for profile
        if (selectedProfileRequests.length > 0){
          //history.push(`/requests/${selectedProfileRequests[0].id}`)
          dispatch(reportActions.selectRequest(selectedProfileRequests[0].id))
        } else {
          history.push(`/requests`)
          //dispatch(reportActions.selectRequest(selectedProfileRequests[0].id))
        }
      }
    }
  }), [selectedProfileRequests]*/

  useEffect(()=>{
    if (currentRequestId && !request){
      dispatch(reportActions.fetchReport(currentRequestId));
    }
  }, [currentRequestId])

  useEffect(()=>{
    let searchParams = new URLSearchParams(location.search);
    if (searchParams.get('rec') == 1 && reportRecordId){
      // show record upload
      dispatch(recordActions.selectRecord(reportRecordId));
      dispatch(modalActions.openModal('recordDetail'));
    }
  }, [location.search])

  if (request?.user_profile?.id != null){
    patient_name = formatName(intlMessages['format.fullName'], request?.user_profile.first_name, request?.user_profile.last_name);
  } else if (profile != null) {
    patient_name =  profile?.first_name //formatName(intlMessages['format.fullName'], profile?.first_name, profile?.last_name);
  }

  const _renderReportDetail = (type) => {
    if (!type){
      return (
        <StyledReportSection>
          <StyledReportInformation>
            <Flex pl={['20px']} direction={'column'} grow={[1]} w={['full']}>
              <Box>
                <Flex align={['center']} flexWrap={'wrap'}>
                  <Text
                    color="#273238"
                    fontSize={['25px']}
                    mr={['15px']}
                  >
                    {request?.user_profile?.first_name}
                    {/*<ScanTypeText type={request?.record?.type} />
                    <FormattedMessage
                      id="format.fullName"
                      values={{
                        givenName: request?.user_profile?.first_name,
                        familyName: request?.user_profile?.last_name
                      }}
                      />*/}
                  </Text>
                </Flex>
                <Text color="#6B8A99" fontSize={['16px']}>
                  {dateText}
                </Text>
              </Box>
              <Box
                p={0} 
                m={['5px']}
                bg={['#fff']}
                w={['full']}
                borderRadius={['15px']}
              >   
                <Flex mt={['30px']}>
                  <Text fontSize={['30px']} color={'#273238'}>
                    <FormattedMessage
                        id="requestDetailPage.reportsection.report.title"
                        defaultMessage="Report Notes"
                    />
                  </Text>
                </Flex>
                <Flex 
                  w={'580px'}
                  p={['40px']}
                  py={['50px']}
                  pt={['80px']}
                  m={['5px']}
                  bg={['#fff']}
                  justify={'center'}
                  align={'center'}
                  borderRadius={['15px']}
                  direction={['column']}
                >
                  <NoReportIcon />
                  <Text mt={['10px']} color={'#6B8A99'} fontSize={['20px']}>
                    <FormattedMessage   
                      id={'reportDetail.history.noReport'}
                      defaultMessage={'No data'}
                    />
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </StyledReportInformation>
        </StyledReportSection>
      )
    }
    if (type == "GENERATIVE"){
      return (
        <GenerativeReportSection
          isLoading={reportLoading}
          request={request}
          dateText={dateText}
        />
      )
    } else if (type == "REPORT") {
      return (
        <DentalReportSection 
          type={type}
          isLoading={reportLoading}
          data={request?.report?.report_data}
          status={request?.report?.status}
          dateText={dateText}
        />
      )
    } else if (type == "GEN_DMFT") {
      return (
        <DentalReportSection 
          type={type}
          isLoading={reportLoading}
          data={request?.user_report?.report_data}
          status={request?.user_report?.status}
          dateText={dateText}
        />
      )
    } else if (type == "DMFT") {
      return (
        <DentalReportSection 
          type={type}
          isLoading={reportLoading}
          data={request?.report?.report_data}
          status={request?.report?.status}
          dateText={dateText}
        />
      )
    } else {
      if (storage.getIsDentalType() || storage.getIsOrphanage()){
        return (
          <DentalReportSection 
            isLoading={reportLoading}
            data={request?.report?.report_data}
            status={request?.report?.status}
            dateText={dateText}
          />
        )
      }
      return (
        <GenerativeReportSection
          isLoading={reportLoading}
          request={request}
          dateText={dateText}
          status={request?.report?.status}
        />
      )
    }
  }

  return (
    <SectionPage key={params?.requestId}>
      <Grid bg={'#fff'} gridTemplateColumns={['7fr 2fr']}>
          <Box pr={['20px']} pt={['30px']} pb={['40px']}>

           <SectionTitle>{startCase(intlMessages['reportDetail.title'])}</SectionTitle>
              <Flex
                id="reportDetailsPage.subheader"
                align={['center']}
                w={['full']}
                justify={['space-between']}
              >
              </Flex>
              {_renderReportDetail(request?.type)}
          </Box>
          <Box pt={['40px']} boxShadow={'0px 0px 2px 0px rgba(0, 0, 0, 0.25)'} >
            <StyledHistorySection pl={(params.requestId) ? '0px' : '0px'}>
                  <Flex justify={'space-between'} direction={'column'} pr={['15px']}>
                    <Text 
                      as={'h2'}
                      fontSize={['20px']}
                      color={'#273238'}
                      pb={'30px'}
                    >
                      {startCase(intlMessages['reportDetail.history.title'])}
                    </Text>
                    <ProfilesFilterSuper fullWidth={true} boxProps={{p: 0}}/>
                  </Flex>
                  <CheckupRequestHistory />
            </StyledHistorySection>
          </Box>
      </Grid>
    </SectionPage>
  )
}
