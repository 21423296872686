import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createSelector } from 'reselect'
import { Scrollbars } from 'react-custom-scrollbars';
import cn from 'classnames';
import moment from 'moment';
import { connect, useSelector, useDispatch } from 'react-redux';
import { fetchMessageHistory, fetchUserData } from "pubnub-redux";
import {
  usePagination,
  GetNextPage,
  SavePaginationState
} from "../_helpers/usePagination";
import {
  extractDentistUuidFromChannelId,
  extractPatientUuidFromChannelId,
  extractChannelType,
  localeFormatMessageTime,
  formatName
} from '../_helpers';

import { LoadingEllipses } from '../_components/LoadingEllipses';
import messageAttachment from '../_images/messageAttachment.svg';
import cameraIcon from '../_images/cameraIcon.svg';

import { getCurrentDentistRequest, getCurrentVideoChat } from '../VideoChats/_selectors';
import { getIntlLocale, getIntlMessages } from '../Authentication/_selectors';
import {
  getUUID, getCurrentConversationId, getCurrentConversationMessages, getChannelDataById,
  getCurrentConversationData, getDentistsById, getIsMembershipsLoaded, getCurrentDentistLoading, getDentistIsCurrent,
  getConversationMessageDraft, getUnknownUsers, getShowRecordsAttachment
}
from './_selectors';

import { actions as videoChatActions } from '../VideoChats/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { actions as dentistActions } from '../Dentists/_actions';
import { actions as unreadMessageCountActions } from '../UnreadMessageCount/_actions';
import { actions } from './_actions';

import {
  StyledMessage, StyledMessageListItemText, ScrollView, FlexColumn,
  StyledChatHeader, IconContainer, FlexRow, StyledBox, Divider, StyledTextArea,
  StyledChatConversation, StyledMessageInput, StyledConversationHeader,
  StyledChatList, StyledMessageInputWrapper, StyledChatListWrapper, StyledMessageContent,
  StyledBlankChatConversation, StyledExpiredMessageInput
}
from './styles';

import { CameraIcon } from '../_images/icons/CameraIcon';
import { MessageListItem } from './Messages/MessageTemplates';
import { ChatRecordAttachment } from './ChatRecordAttachment';

export const convertTimestampToTime = (ts) => {
  const date = new Date(parseInt(ts) / 10000);
  const minutes = date.getMinutes();
  return `${date.getHours()}:${minutes > 9 ? minutes : "0" + minutes}`;
};

const wasFetched = (() => {
  const ids = {};
  return (id) => {
    if (ids.hasOwnProperty(id)) {
      return true;
    } else {
      ids[id] = true;
      return false;
    }
  };
})();

const autoExpand = (el) => {
  setTimeout(function () {
    if (el.style.height < 80){
      el.style.cssText = "height:auto;padding:0";
      el.style.cssText = "padding:20px 20px;height:" + (el.scrollHeight + 20 ) + "px";
    }
  }, 0);
}

const newTextDraft = (draft, newText) => {
  if (draft.text=== newText) {
    return draft;
  }
  return {
    type: "text",
    senderId: draft.senderId,
    text: newText
  }
}

const isDraftModified = (message) => {
  switch (message.type) {
    case "text":
      return message.text !== "";
    default:
      console.log(`C3annot determine if message of type "${message.type}" has been modified.`)
  }
};

const MessageList = () => {
  const dispatch = useDispatch();
  const myUuid = useSelector(getUUID);
  const [height, setHeight] = useState(0);
  const conversationId = useSelector(getCurrentConversationId);

  const startRef = useRef();

  const handleScroll = (e) => {
    const scrollPosition = e.target.scrollTop;
    if (scrollPosition !== 0) {
    }
  };

  const getNextPage = useCallback(
    
    async (tt, total, channel) => {
      console.log("GETNEXTPAGECHAT now")
      const pageSize = 100;
      const action = await dispatch(
        fetchMessageHistory({
          count: pageSize,
          channel,
          start: tt || undefined,
          stringifiedTimeToken: true
        })
      );
      dispatch(actions.fetchMessageActions({
        limit: pageSize,
        channel,
        start: tt || undefined
      }));
      const response = action.payload.response;
      return {
        results: response.messages,
        pagination: `${response.startTimeToken}`,
        pagesRemain: response.messages.length === pageSize
      };
    },
    [dispatch]
  );

  const { containerRef, endRef } = usePagination(
    getNextPage,
    conversationId,
    null,
    null
  );


  const messages = useSelector(
    getCurrentConversationMessages
  );

  const unknownUsers = useSelector(getUnknownUsers);

  useEffect(() => {
    // send requests for missing data
    unknownUsers.forEach(uuid => {
      if (uuid && !wasFetched(uuid)) {
        dispatch(
          fetchUserData({
            uuid
          })
        );
      }
    });
  }, [unknownUsers, dispatch]);

  const el = containerRef.current;

  // when history is pulled, scroll down to compensate
  const newHeight = el && el.scrollHeight;
  useEffect(() => {
    if (height === 0 && newHeight) {
      setHeight(newHeight);
    } else if (newHeight && newHeight !== height) {
      if (el) {
        el.scrollTop += newHeight - height;
      }
      setHeight(newHeight);
    }
  }, [newHeight, height, el]);


  const scrollToBottom = useCallback(() => {
    // message has been received and is on users screen
    dispatch(actions.updateMembership(myUuid, conversationId));
    return el && (el.scrollTop = el.scrollHeight - el.clientHeight);
  }, [el]);

  const hasReachedBottom = el
    ? el.scrollHeight - el.clientHeight === el.scrollTop
    : false;

  useEffect(() => {
    if (hasReachedBottom) {
      scrollToBottom();
    }
  }, [messages.length, hasReachedBottom, scrollToBottom]);

  // TODO on load, send update to read/membership
  return (

    <ScrollView  ref={containerRef} onScroll={handleScroll}>
      <StyledChatList>
       {/* This moves the list of messages to the bottom, since there's a bug with flex-end scroll */}
       <FlexColumn></FlexColumn>
       <div ref={endRef}></div>

       { messages.map( (message ) => (
         <MessageListItem
           senderMe={myUuid == message.senderId || extractPatientUuidFromChannelId(conversationId) == message.senderId}
           messageFragment={message}
           key={message.timetoken}
         />
       ))}
      </StyledChatList>
    </ScrollView>
  )
}

const ChatHeader = ({item}) => {
  const dispatch = useDispatch();
  const conversationId = useSelector(getCurrentConversationId);
  const intlMessages = useSelector(getIntlMessages);

  const currentVideoChat = useSelector(getCurrentDentistRequest);

  /*useEffect(()=>{
    if (conversationId){
      dispatch(videoChatActions.fetchLatestCheckupwithDentist('uuid', extractDentistUuidFromChannelId(conversationId) ))
    }
  }, [conversationId])*/

  let name = "Beforedent"
  let subName = "Beforedent"

  if (item && item.first_name != null){
    name = formatName(intlMessages['format.fullName'], item.first_name, item.last_name)
  } else if (item && item.name != null){
    name = item.name
  }
  if (item && item.user_type === "DENT"){
    subName = intlMessages['chats.chatList.item.name.title'];
  } else if (item && item.user_type === "BEFO"){
    subName = "Beforedent Support"
  }

  const openVideoChat = () => {
    console.log("OPENVIDEOCHAT")
    dispatch(modalActions.openModal('videoChat'));
  }

  return (
    <StyledConversationHeader>
      <div>
        <p className="name">{name}</p>
        <p className="job">{subName}</p>
      </div>
      <button className="camera" onClick={openVideoChat} disabled={currentVideoChat.length < 1}>
        <CameraIcon />
      </button>
    </StyledConversationHeader>
  )
}

const Header = () => {
  const dispatch = useDispatch();
  const conversationData = useSelector(getCurrentConversationData);
  const channelsDataById = useSelector(getChannelDataById);
  const conversationId = useSelector(getCurrentConversationId);
  const dentistsById = useSelector(getDentistsById);
  let item = {}
  if (conversationId && extractChannelType(conversationId) == "CLINICALSUPPORT"){
    item = channelsDataById[conversationId];
  } else if (conversationId && extractChannelType(conversationId) == "DENTIST"){
    item = dentistsById[extractDentistUuidFromChannelId(conversationId)]
  }
  return (
    <ChatHeader
      item={item}
      label={conversationData.request_created_at}
      />
  )
}

const TextMessageEditor = ({message, sendDraft, updateDraft}) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUUID);
  const intlMessages = useSelector(getIntlMessages);
  const text = message.text;
  const textareaRef = useRef(document.createElement("textarea"));

  const textChanged = (e) => {
    updateDraft(newTextDraft(message, e.target.value))
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !(e.shiftKey)) {
      const draft = newTextDraft(message, text);
      if (isDraftModified(draft)) {
        sendDraft(draft);
      }
      e.preventDefault();
    }
    autoExpand(e.target);
  }

  const emojiInserted = (messageWithEmoji) => {
    updateDraft(newTextDraft(message, messageWithEmoji));
    textareaRef.current.focus();
  };

  useEffect(() => {
    autoExpand(textareaRef.current);
  }, [textareaRef]);

  return (
    <FlexRow addCss={{}}>
      <FlexRow addCss={{flexGrow:1}}>
        <StyledTextArea
          ref={textareaRef}
          value={text}
          onChange={textChanged}
          onKeyPress={handleKeyPress}
          placeholder={intlMessages['chats.chatConversation.messageEditor.placeholder']}
        ></StyledTextArea>
      </FlexRow>
    </FlexRow>
  )

}

const MessageEditor = ({message, sendDraft, updateDraft}) => {
  switch (message.type) {
    case "text":
      return (
        <StyledBox
          addCss={{
            position: 'relative',
            flexGrow: 1
          }}
          >
          <TextMessageEditor
            message={message}
            sendDraft={sendDraft}
            updateDraft={updateDraft}
            >
          </TextMessageEditor>
        </StyledBox>

      )
    default:
      console.log(`no editor available for draft message of type ${message.type}`);
  }
}

const MessageInput = () => {
  const conversationId = useSelector(getCurrentConversationId);
  const userId = useSelector(getUUID);
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const storedDraft = useSelector(getConversationMessageDraft);
  const showRecordsAttachment = useSelector(getShowRecordsAttachment);
  const requestIsCurrent = useSelector(getDentistIsCurrent);
  const currentLoading = useSelector(getCurrentDentistLoading);

  const defaultDraft = {
    type: "text",
    senderId: userId,
    text: ""
  }
  const message = storedDraft ? storedDraft : defaultDraft;
  const send = (appMessage) => {
    dispatch(actions.sendMessage(appMessage, userId));
    dispatch(actions.discardMessageDraft(conversationId));
  }

  const update = (draft) => {
    dispatch(actions.updateMessageDraft(conversationId, draft));
  }

  const setAttachment = () => {
    dispatch(actions.setShowRecordsAttachment(!showRecordsAttachment))
  }

  const startAppointment = () => {
    dispatch(modalActions.openModal('scanningPage'));
  }

  if (!requestIsCurrent && currentLoading){
      return(
        <StyledMessageInputWrapper>
          <div className={'loading'}>
            <LoadingEllipses />
            <div className={'loadingMessage'}>
              {intlMessages['chats.chatConversation.messageEditor.checkStatus']}
            </div>
          </div>
        </StyledMessageInputWrapper>
      )
  } else {
      return (
        <StyledMessageInputWrapper isCurrent={requestIsCurrent || conversationId.startsWith("clinicalsupport")}>
          {
            (requestIsCurrent || conversationId.startsWith("clinicalsupport"))
              ? <StyledMessageInput>
                  <ChatRecordAttachment />
                  <IconContainer
                      showing={showRecordsAttachment}
                    >
                    <img onClick={setAttachment} src={messageAttachment} />
                  </IconContainer>
                  <MessageEditor
                    message={message}
                    sendDraft={send}
                    updateDraft={update}
                    ></MessageEditor>
                </StyledMessageInput>
              : <StyledExpiredMessageInput>
                  <div className={'label'}>
                    <div>{intlMessages['chats.messageInput.expired.label.1']}</div>
                    <div>{intlMessages['chats.messageInput.expired.label.2']}</div>
                  </div>
                  <div className={'button'} onClick={startAppointment}>
                    {intlMessages['chats.messageInput.expired.butotn.text']}
                  </div>
                </StyledExpiredMessageInput>
          }
        </StyledMessageInputWrapper>
      )
  }

}

const BlankChatConversation = () => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <StyledBlankChatConversation>
      <div>{intlMessages['chats.chatConversation.noConversationSelected']}</div>
    </StyledBlankChatConversation>
  )
}

export const ChatConversation = () => {
  const dispatch = useDispatch();
  const myUuid = useSelector(getUUID);
  const membershipsLoaded = useSelector(getIsMembershipsLoaded);
  const currentConversationId = useSelector(getCurrentConversationId);

  useEffect(() => {
    currentConversationId && myUuid && dispatch(dentistActions.checkDentistChatStatus(extractDentistUuidFromChannelId(currentConversationId), 'uuid'))
    currentConversationId && myUuid && dispatch(unreadMessageCountActions.markChannelRead(currentConversationId));
    currentConversationId && myUuid && dispatch(actions.updateMembership(myUuid, currentConversationId));
    return () => {
      currentConversationId && myUuid && dispatch(unreadMessageCountActions.markChannelRead(currentConversationId));
      currentConversationId && myUuid && dispatch(actions.updateMembership(myUuid, currentConversationId));
    }
  }, [currentConversationId, myUuid])

  if (currentConversationId && membershipsLoaded){
    return (
      <StyledChatConversation>
        <Header />
          <MessageList />
          <MessageInput />
      </StyledChatConversation>
    )
  } else {
    return (
      <StyledChatConversation>
        <BlankChatConversation />
      </StyledChatConversation>
    )
  }
}
