import React from 'react';
import  { Icon } from '@chakra-ui/react';

export const BackChevronIcon = ({width=7, height=11, color="#7A8F99", ...props}) => {

  return(
    <Icon width={`${width}px`} height={`${height}px`} viewBox="0 0 7 11" color={color}  {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.36083 10.8057L0 5.44483L5.44483 -1.90735e-06L6.18742 0.743165L1.48517 5.44483L6.10342 10.0631L5.36083 10.8057Z" fill="currentColor"/>
    </Icon>
  )
}
