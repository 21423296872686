import { REPORTCHART_TYPES } from '../_config';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import { createSelector } from 'reselect';
import { Flex, Box, Button, Text, Circle, useDisclosure } from '@chakra-ui/react';
import cn from 'classnames';
import {
  localeFormatDate,
  formatName,
  checkNotificationArrayRead,
  checkNotificationRead
} from '../_helpers'
import { Scrollbars } from 'react-custom-scrollbars';
import { upperFirst, startCase, toUpper } from 'lodash';
import { NoReportIcon } from '../_images/icons/NoReportIcon';

import { CategoriesFilterSelect } from './CategoriesFilterSelect'

import cameraIcon from '../_images/camera.png'
import reportIcon from '../_images/reportIcon.png'
import { BlankCircle } from '../_images/icons/BlankCircle';
import { LoadingEllipses } from '../_components/LoadingEllipses';
import { RecordUploadMedia } from '../_components/RecordUploadMedia';
import { ReportSection } from './ReportReview';

import { GenerativeReportInspectionModal, InspectionReviewSentModal } from './GenerativeReportInspectionModal';

// actions
import { actions as reportActions } from '../Reports/_actions';
import { actions as recordActions } from '../Records/_actions';
import { actions as checkupActions } from '../Checkups/_actions';
import { actions as notificationActions } from '../Notifications/_actions';
import { actions as modalActions } from '../Modals/_actions';
import { actions as authActions } from '../Authentication/_actions';
import { actions } from './_actions';

import { services as reportServices } from '../Reports/_services';

import { getIntlLocale, getIntlMessages, getProfile, getSelectedProfile, getUUID } from '../Authentication/_selectors';
import { getNotificationsByRequestId } from '../Notifications/_selectors';
import {
  getCurrentRequestId,
  getRequestReport,
  getRequestDetailsId,
  getRequestDetailsUniqueId,
  getRequestRecordId,
  getChartCategory,
  getRequestDetails,
  getRequestList,
  getRequestListIsMore,
  getReportCharts,
  getFilteredReportRegions,
  getFilteredReportCharts,
  getRecordUploads,
  getReportListLoading,
  getReportLoading,
  getRequestListWithFilter,
  getReportCategoriesCount
}
from './_selectors';
import { UnstyledLink } from '../_styles/common';

import {
  Wrapper,
  SectionPage,
  SectionTitle,
  StyledSectionWrapper,
  StyledSection,
  StyledReportSection,
  StyledReportInformation,
  StyledHistorySection,
  StyledReportChartCategories,
  StyledImageSection,
  StyledImageBoxes,
  StyledChartColumn,
  StyledDetailColumn,
  StyledGradeLabel,
  StyledCategoryLabel,
  StyledReportChartCategoryItem,
  StyledReportChartInformation,
  StyledReportChartInformationGeneral,
  StyledReportChartInformationCategory,
  StyledReportChartInformationComment,
  StyledReportChartInformationRegions,
  StyledReportToothChart,
  StyledHistoryList,
  StyledHistoryListItem,
  StyledBlankReportSection,
  StyledIsMore
}
from './styles';

import { StatusTag } from './components/StatusTag';
import { ProfilesFilterSuper } from './ProfilesFilterSuper';
import { isPaymentRequired, isCompleted } from '../_helpers/reports';
import { ChartSection } from './ReportReview';
import { FormattedMessage } from 'react-intl';
import { ReportMoreInfo } from './components/ReportMoreInfo';
import { CheckupRequestHistory } from './components/CheckupRequestHistory';
import { useReportStore } from './state/report';
import { updateQueryData, useRequestDetails } from './api/useRequestQuery';

export const BlankRequestHistoryItem = () => {
  const intlMessages = useSelector(getIntlMessages)
  return(
    <StyledHistoryListItem
      selected = {false}
      blank = {true}
      onClick={() => {}}
      >
          <button disabled className={'camera'}><BlankCircle /></button>
          <p className='blank'>{intlMessages['reportDetail.history.noReport']}</p>
    </StyledHistoryListItem>
  )
}

const OptionLabel = ({cat}) => {
  let color = '#000'
  switch(cat){
      case 'COLOR':
          color = '#367FFF'
          break;
      case 'ORTHO':
          color = '#00574F'
          break;
      case 'HABITS':
          color = '#daff00'
          break;
      case 'GUMS':
          color = '#daff00'
          break;
      case 'CARIES':
          color = '#DC4E41'
          break;
      case 'CALCULUS':
          color = '#ffa500'
          break;
      default:
          // ALL
          break;
  }
  return (
      <Flex
          justifyContent={'center'}
          align={'center'}
          pr={'8px'}
          pl={'0px'}
      >
          <Circle 
              boxSize={'10px'}
              backgroundColor={color}
          />
      </Flex>
  )
}

const CategoryLabel = ({cat}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <Flex>
      <OptionLabel cat={cat} px={'2px'} />
      <Text
        fontSize={'22px'}
        color={'#273238'}
      >
        {intlMessages[`toothChart.category.${cat}`]}
      </Text>
    </Flex>
  )

}

const GradeLabel = ({grade}) => {
  const intlMessages = useSelector(getIntlMessages);

  return (
    <StyledGradeLabel
      className={`GRADE_${grade}`}
    >
      <span>
        {intlMessages[`requestItem.report.grade.${grade}`]}
      </span>
    </StyledGradeLabel>
  )
}


export const ReportChartCategories = () => {
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const chartCategory = useSelector(getChartCategory);

  const selectCategory = (cat) => {
    dispatch(actions.selectReportCategory(cat))
  }

  return (
    <StyledReportChartCategories>
      <ul>
        <StyledReportChartCategoryItem
          onClick={() => selectCategory(null)}
          selected={chartCategory==null}
          className="ALL"
          >{intlMessages['toothChart.category.ALL']}
          </StyledReportChartCategoryItem>
          {REPORTCHART_TYPES.map((elem) => {
            return (
              <StyledReportChartCategoryItem
                key={elem.value}
                onClick={() => selectCategory(elem.value)}
                selected={chartCategory== elem.value}
                className={elem.value}
                >
                {intlMessages[`toothChart.category.${elem.value}`]}
              </StyledReportChartCategoryItem>
            )
          })}
      </ul>
    </StyledReportChartCategories>
  )
}

const sortTeeth = (a,b) => {
  if (parseInt(a.replace("t", "")) === parseInt(b.replace("t", ""))){
    return 0;
  } else if (parseInt(a.replace("t", "")) > parseInt(b.replace("t", ""))){
    return 1;
  } else {
    return -1;
  }
}

export const ReportChartCategoryInformation = ({regions, comment, grade , category}) => {
  const intlMessages = useSelector(getIntlMessages);
  const currRegions = (regions && regions.length > 0) ? regions.sort(sortTeeth) : [];
  return (
    <StyledReportChartInformationCategory>
      <Flex direction={'column'} pb={'10px'}>
        <CategoryLabel cat={category} />
        <Flex>
          <Text
            color={'#6B8A99'}
            fontSize={'16px'}
            textTransform={'uppercase'}
            mr={'8px'}
          >
            {'Remarks'}
          </Text>
          <GradeLabel grade={grade} />
        </Flex>
      </Flex>
      <StyledReportChartInformationRegions>
        { currRegions.length < 1
          ? <li className="ALL">{intlMessages['toothChart.category.ALL']}</li>
          : currRegions.map(reg => <li>{reg.replace("t", "")}</li>) }
      </StyledReportChartInformationRegions>
      <StyledReportChartInformationComment>
        {comment}
      </StyledReportChartInformationComment>
    </StyledReportChartInformationCategory>
  )
}

export const ReportChartInformation = () => {
  const dispatch = useDispatch();
  const regionCharts = useSelector(getFilteredReportCharts);
  const request = useSelector(getRequestDetails);
  const intlMessages = useSelector(getIntlMessages)
  const reportLoading = useSelector(getReportLoading);
  const requestNotifications = useSelector(getNotificationsByRequestId);
  const requestUuid = useSelector(getRequestDetailsUniqueId);
  const chatUuid = useSelector(getUUID);

  useEffect(()=>{
    // trigger mark request notification read here. after loading is finished
    if (!reportLoading && requestUuid){
      let notifs = requestNotifications[requestUuid]
      ? requestNotifications[requestUuid].filter(elem=>!checkNotificationRead(elem, chatUuid))
      : [];
      notifs && (notifs.length > 0) && !checkNotificationArrayRead(notifs, chatUuid) && dispatch(notificationActions.markNotificationArrayRead(chatUuid, notifs));
    }
  }, [reportLoading, requestUuid])

  let generalTitle = intlMessages["reportDetail.reportchartinformation.general.title"];
  let categoryTitle = intlMessages["reportDetail.reportchartinformation.category.title"];
  let generalComment = "";
  let categoryComment = "";
  if (request && request.user_report && request.user_report.sent_at){
    generalComment = request.user_report.comment;
  } else if (request && request.status == "INIT"){
    generalTitle = intlMessages['reportDetail.reportchartinformation.general.title.pending'];
    categoryTitle = ''
  } else if (request && request.status == "PENDING"){
    generalTitle = intlMessages['reportDetail.reportchartinformation.general.title.pending'];
    categoryTitle = ''
  } else if (request && request.status == "ACCEPTED"){
    generalTitle = intlMessages['reportDetail.reportchartinformation.general.title.pending'];
    categoryTitle = ''
  }


  return (
    <Scrollbars style={{position:'relative', height:'780px'}}>
      { reportLoading &&
        <div className={'loading'}>
          <LoadingEllipses />
        </div>
      }
      <StyledReportChartInformation>
        <h4>{generalTitle}</h4>
        <StyledReportChartInformationGeneral>
          <StyledReportChartInformationComment>
            {generalComment}
          </StyledReportChartInformationComment>
        </StyledReportChartInformationGeneral>
        {
          (regionCharts && regionCharts.length > 0)
            ? regionCharts.map((elem) =>
              <ReportChartCategoryInformation
                regions = {(elem.extra_data && elem.extra_data.all_regions) || []}
                comment = {elem.comment}
                grade = {elem.grade}
                category = {elem.extra_data?.type}
              />)
            : ''
        }
      </StyledReportChartInformation>
    </Scrollbars>
  )
}

export const RecordUploadsSection = () =>{
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const intlMessages = useSelector(getIntlMessages);
  const uploads = useSelector(getRecordUploads);
  const reportLoading = useSelector(getReportLoading);
  const reportRecordId = useSelector(getRequestRecordId);

  const showRecord = (id) => {
    let newParams = new URLSearchParams();
    newParams.set("rec", 1);
    history.push((`${location.pathname}?${newParams.toString()}`))
  }

  useEffect(()=>{
    if (!params.requestId){
      dispatch(recordActions.selectRecord(null))
    } else if (!reportLoading && reportRecordId && params.requestId){
      dispatch(recordActions.selectRecord(reportRecordId))
    }
  }, [reportLoading, reportRecordId, params.requestId])

  return(
    <StyledImageSection>
      <StyledImageBoxes>
        { reportLoading &&
          <div className={'loading'}>
            <LoadingEllipses />
          </div>
        }
        <div>
        {(uploads.length > 0) ? uploads.slice(0,4).map((elem, ind) =>
            <RecordUploadMedia
              key={elem.id}
              onClick={() => showRecord(elem.id)}
              upload={elem}
              imgWidth={80}
              imgHeight={80}
              imgBorderRadius={10}
              alt={"recordupload"+ind}
              />
          )
          : <RecordUploadMedia
              imgWidth={80}
              imgHeight={80}
              imgBorderRadius={10}
              alt={"blank"}
              />
        }
        </div>
      </StyledImageBoxes>
    </StyledImageSection>
  )

}

export const GenerateButton = ({isLoading, onClick}) => {
    return (
        <Button onClick={onClick} isLoading={isLoading}>
            <FormattedMessage 
                id={"reportGenerative.details.generate.button.text"}
                defaultMessage={"Generate my Report"}
            />
        </Button>
    )
}

const RequestInspectionButton = ({requestInspection, request, isOpen, onClose, onReportInspection, inspectWasSent}) => {
  let { isOpen: isInspectingOpen , onOpen, onClose: onInspectingClose } = useDisclosure({defaultIsOpen: request?.status == 'INSPECTING'});
    // if already requested, show message, not the button
    const _renderButton = () => {
      if (request?.user_report?.needs_inspection == true || inspectWasSent == true){
        if (request?.report?.status == 3){
          /*return (
              <Text textTransform={'uppercase'} color="#6B8A99">
                <FormattedMessage
                  id={'reportGenerative.details.inspection.label.completed_on'}
                  defaultMessage={'Review completed on: {date}'} 
                  values={{date: localeFormatDate(request?.report?.sent_at, intlLocale, "LL")}}
                />
              </Text>
          )*/
          return (<></>)
        } else {
          /*return (
              <Text textTransform={'uppercase'}>
                <FormattedMessage
                  id={'reportGenerative.details.inspection.label.requested'}
                  defaultMessage={'Review has been requested'} 
                />
              </Text>
          )*/
          return (<></>)
        }
      } else {
        return (
          <Button variant={'ghost'} color={'#44C5A6'} onClick={requestInspection} size={'sm'}>
              <FormattedMessage 
                  id={'reportGenerative.details.inspection.label'}
                  defaultMessage={'Something wrong? Request Review'}
              />
          </Button>
        )
      }
    }

    if (request?.user_report?.status != "COMPLETE"){
      return <></>
    } 
    
    return (
    <>
        <InspectionReviewSentModal 
            isOpen={isInspectingOpen}
            onClose={onInspectingClose}
        />
        <Flex w={'full'} justify={'center'} mt={'20px'}>
          {_renderButton()}
        </Flex>
    </>
    )
}

export const ReportColumn = ({request, dateText, isLoading}) => {
  const dispatch = useDispatch();
  const [ inspectWasSent, setInspectWasSent ] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isGenerating, setIsGenerating] = useState(false);
  const [ reportData, setReportData ] = useState(null);
  const requestId = useReportStore(state => state.selectedReport);
  //const request = useSelector(getRequestDetails);
  const openScan = () => {
      dispatch(modalActions.openModal('scanningPage'))
  }

  const openPayment = () => {
    // set payment intent and open modal. modal checks state of request
    if (request?.payment){
      dispatch(checkupActions.setPaymentIntent(request))
      dispatch(modalActions.openModal('scanningPage'))
    } else {
      dispatch(checkupActions.setPaymentIntent(request))
      dispatch(modalActions.openModal('scanningPage'))
    }
  }

  const onReportInspection = () => {
    let newData = {
        ...request,
        status: 'INSPECTING',
        user_report: {
            ...request.user_report,
            needs_inspection: true
        }
    }
    console.log("on report inspection", newData)
    updateQueryData(newData.id, newData)
    dispatch(reportActions.updateRequestData(newData))
  }

  const requestInspection = () => {
    onOpen();
  }

  const generateReport = () => {
    setIsGenerating(true);
    reportServices.generateUserReport(requestId)
        .then(
            data=>{
                //request?.user_report?.report_data equivalent received
                let newRequestToUpload = {
                    ...request,
                    user_report: {
                        ...request.user_report,
                        report_data: data
                    }
                };
                setReportData(data);
                //dispatch(reportActions.updateRequestData(newRequestToUpload));
                setIsGenerating(false);
            },
            error=>{
                console.log('generate report error', error);
                setIsGenerating(false);
            }
        )
  }
  
  if (!requestId || isLoading){
    return (
      <StyledReportSection>
        <StyledReportInformation>
          <Flex pl={['20px']} direction={'column'} grow={[1]} w={['full']}>
            <Box>
              <Flex align={['center']} flexWrap={'wrap'}>
                <Text
                  color="#273238"
                  fontSize={['25px']}
                  mr={['15px']}
                >
                  {request?.user_profile?.first_name}
                  {/*<ScanTypeText type={request?.record?.type} />
                  <FormattedMessage
                    id="format.fullName"
                    values={{
                      givenName: request?.user_profile?.first_name,
                      familyName: request?.user_profile?.last_name
                    }}
                    />*/}
                </Text>
              </Flex>
              <Text color="#6B8A99" fontSize={['16px']}>
                {dateText}
              </Text>
            </Box>
            <Box
              p={0} 
              m={['5px']}
              bg={['#fff']}
              w={['full']}
              borderRadius={['15px']}
            >   
              <Flex mt={['30px']}>
                <Text fontSize={['30px']} color={'#273238'}>
                  <FormattedMessage
                      id="requestDetailPage.reportsection.report.title"
                      defaultMessage="Report Notes"
                  />
                </Text>
              </Flex>
              <Flex 
                w={'580px'}
                p={['40px']}
                py={['50px']}
                pt={['80px']}
                m={['5px']}
                bg={['#fff']}
                justify={'center'}
                align={'center'}
                borderRadius={['15px']}
                direction={['column']}
              >
                <NoReportIcon />
                <Text mt={['10px']} color={'#6B8A99'} fontSize={['20px']}>
                  <FormattedMessage   
                    id={'reportDetail.history.noReport'}
                    defaultMessage={'No data'}
                  />
                </Text>
              </Flex>
            </Box>
          </Flex>
        </StyledReportInformation>
      </StyledReportSection>
    )
  } else if (request?.status == 'READY'){
        return (
            <StyledReportSection>
                <StyledReportInformation>
                    <Flex pl={['20px']} direction={'column'} grow={[1]} w={['full']}>
                        <Box>
                            <Flex align={['center']} flexWrap={'wrap'}>
                                <Text
                                    color="#273238"
                                    fontSize={['25px']}
                                    mr={['15px']}
                                >
                                {/*<ScanTypeText type={request?.record?.type} />*/}
                                <FormattedMessage
                                    id="format.fullName"
                                    values={{
                                    givenName: request?.user_profile?.first_name,
                                    familyName: request?.user_profile?.last_name
                                    }}
                                    />
                                </Text>
                                <StatusTag status={request?.status} request={request} inspectWasSent={inspectWasSent} />
                            </Flex>
                            <Text color="#6B8A99" fontSize={['16px']}>
                                {dateText}
                            </Text>
                        </Box>
                        {(reportData != null) ? (
                            <ReportSection data={request?.user_report?.report_data} />
                            ) : (
                            <Flex w={'100%'} mt={'20px'} align={'center'} justify={'center'} px={8}>
                                <GenerateButton isLoading={isGenerating} onClick={generateReport} />
                            </Flex>)
                        }
                    </Flex>
                </StyledReportInformation>
            </StyledReportSection>
        )
  } else {
    return (
      <StyledReportSection>
        {/*<Text
          fontFamily={'Proxima Nova'}
          fontSize={'30px'}
          color={'#273238'}
        >
          {intlMessages['reportDetail.reportchartinformation.dentistcomments']}
        </Text> */}
        <StyledReportInformation>
          <Flex pl={['20px']} direction={'column'} grow={[1]} w={['full']}>
            <Box>
              <Flex align={['center']} flexWrap={'wrap'}>
                <Text
                  color="#273238"
                  fontSize={['25px']}
                  mr={['15px']}
                >
                  {request?.user_profile?.first_name}
                  {/*<ScanTypeText type={request?.record?.type} />
                  <FormattedMessage
                    id="format.fullName"
                    values={{
                      givenName: request?.user_profile?.first_name,
                      familyName: request?.user_profile?.last_name
                    }}
                    />*/}
                </Text>
                <StatusTag status={request?.status} request={request} inspectWasSent={inspectWasSent}  />
              </Flex>
              <Text color="#6B8A99" fontSize={['16px']}>
                {dateText}
              </Text>
            </Box>
            <ReportSection data={request?.user_report?.report_data} request={request} />
            <GenerativeReportInspectionModal 
                reportId={request?.user_report?.id} 
                isOpen={isOpen} 
                onClose={onClose} 
                onSuccess={onReportInspection}
            />
            <RequestInspectionButton 
                requestInspection={requestInspection}
                request={request}
                inspectWasSent={inspectWasSent}
            />
            </Flex> 
        </StyledReportInformation>
      </StyledReportSection>
    )
  }
}

const ScanTypeText = ({type}) => {
  if (type == 'SELF'){
    return (
      <FormattedMessage
        id="requestItem.scanType.SELF"
        defaultMessage="Self Scan"
        />
    )
  } else {
    return (
      <FormattedMessage
        id="requestItem.scanType.FULL"
        defaultMessage="Guided Scan"
        />
    )
  }
}

export const getSelectedProfileRequests = createSelector(
  [getRequestList, getSelectedProfile],
  (requests, selectedProfile) => {
    return requests.filter(elem => elem.user_profile?.id == selectedProfile?.id)
  }
)

export const GenerativeReportSection = ({request, dateText}) => {
    const query = useRequestDetails({requestId: request?.id})
    return (
        <Flex>
            <Box>
                <StyledReportToothChart>
                    { query.isLoading &&
                        <div className={'loading'}>
                        <LoadingEllipses />
                        </div>
                    }
                    <ChartSection data={query.data?.user_report?.report_data} type={'GENERATIVE'} />
                </StyledReportToothChart>
                <RecordUploadsSection />
            </Box>
            <ReportColumn request={query?.data} dateText={dateText} isLoading={query.isLoading || query.isFetching}/>
        </Flex>
    )
}
