import { constants } from './_constants';
import { FULLSCAN_STATES } from '../_config';

export const actions = {
  redoRegion,
  goToNextRegion,
  resetAll,
  gotoRegion,
  startScanning,
  stopScanning,
  updateState,
  updateStateObject,
}

function resetAll(){
  return (dispatch) => {
    const data = {
      currentState: FULLSCAN_STATES.START,
      currentRegion: 0
    }
    dispatch(update(data))
  }
  function update(data){
    return { type: constants.UPDATE, data}
  }
}

function redoRegion(){
  return (dispatch) => {
    const data = {
      currentState: FULLSCAN_STATES.START
    }
    dispatch(update(data))
  }
  function update(data){
    return { type: constants.UPDATE, data}
  }
}

function gotoRegion(region){
    return (dispatch) => {
      const data = {
        currentState: FULLSCAN_STATES.FINISHED,
        currentRegion: region
      }
      dispatch(update(data))
    }
    function update(data){
      return { type: constants.UPDATE, data}
    }
}

function goToNextRegion(currentRegion){
  return (dispatch) => {
    let nextRegion = 4;
    if (currentRegion < 0){
      nextRegion = 0;
    } else if (currentRegion < 3){
      nextRegion = currentRegion + 1;
    }
    const data = {
      currentState: FULLSCAN_STATES.START,
      currentRegion: nextRegion
    }
    dispatch(update(data));
  }
  function update(data){
    return { type: constants.UPDATE, data}
  }
}

function startScanning(){
  return (dispatch) => {
    const data = {currentState: FULLSCAN_STATES.SCANNING};
    dispatch(update(data));
  }
  function update(data){
    return { type: constants.UPDATE, data}
  }
}


function stopScanning(){
  return (dispatch) => {
    const data = {currentState: FULLSCAN_STATES.FINISHED};
    dispatch(update(data));
  }
  function update(data){
    return { type: constants.UPDATE, data}
  }
}

function updateState(key, value){
  return (dispatch) => {
    dispatch(update(key, value));
  }
  function update(key, value){
    return { type: constants.UPDATE_STATE, data: {key, value}}
  }
}

function updateStateObject(key, value){
  return (dispatch) => {
    dispatch(update(key, value));
  }
  function update(key, value){
    return { type: constants.UPDATE_STATE_OBJECT, data: {key, value}}
  }
}
