import styled from 'styled-components';

import { StyledContent, StyledContentTitle, StyledContentSection } from '../_styles/common';

import { Field } from 'react-final-form';

import { InitialsAvatar } from '../_styles/common'
import {
  StyledLabel as BaseStyledLabel,
  StyledInputLayout as BaseStyledInputLayout
} from '../_styles/forms';

export const StyledForm = styled.form`
  padding-left: 3px;
  padding-right:20px;
  button[type='submit']{
    position: absolute;
    right: 0;
  }

  .SelectAdapter{

  }
`;

export const StyledLabel = styled(BaseStyledLabel)`
font-family: Proxima Nova;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 22px;
margin-top: 5px;
margin-bottom: 5px;
/* identical to box height */

letter-spacing: 1px;

color: #121719;
`;

export const StyledInputLayout = styled(BaseStyledInputLayout)`
  min-width: 350px;
  
  input[type=tel]{
    background: #F9F9F9;
    border: 1px solid #C7D3D9;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px 15px;
    width:100%;
    color: black;
    &:placeholder-shown{
      color: #999;
    }
  }

`;

export const StyledField = styled(Field)`
  &.SelectAdapter{
    min-width: 350px;
    display:flex;
    align-items:flex-start;

    div:first-child{
      min-width: 350px;
    }
  }

  .DayPickerInput{
    min-width: 350px;
    width: 100%;
  }

  label{
    margin: 15px 0;
    margin-bottom: 5px;
  }

`;

export const StyledIsMore = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
  align-items:center;
  padding: 0 15px;
  &:hover{
    cursor: pointer;
  }
  > div{

  }
`;

export const StyledDetailsAuthentication = styled.div`
  form{
    height: 660px;
    overflow: auto;
    max-width: 60%;
    padding-right: 20px;
  }
`;

export const StyledDetailPersonalProfile = styled.div`
  form{
    height: 660px;
    max-width: 60%;
    padding-right: 20px;
    overflow: auto;
  }

  ${BaseStyledLabel}{
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    margin-top: 5px;
    /* identical to box height */

    letter-spacing: 1px;

    color: #121719;
  }
`;

export const StyledDetailsNotificationsContent = styled.div`
  padding-top: 20px;
  height: 660px;
  overflow: auto;
`;

export const StyledDetailsNotifications = styled.div``;

export const StyledDetailsPayment = styled.div`

`;

export const StyledDetailsProfilesList = styled.div`
`;

export const StyledDetailsProfilesDetails = styled.div`
  width: 100%;
`;

export const StyledDetailsRecordsHistoryContent = styled.div`
  height: 660px;
  overflow: auto;
  position:relative;
  background: ${(props) => (props?.isScanning ? '#F7F7F7' : 'transparent')};

  .loading{
    position: absolute;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.2);
    border-radius: 20px;
  }

  .records {
    display: grid;
    grid-template-columns:1fr;
    grid-gap: 15px;
    padding: 30px 15px;
    .recordItem{
      position:relative;
      &:hover{
        cursor: pointer;
        opacity: 0.7;
      }

      .recordTitle{
        position:absolute;
        padding: 8px 0;
        text-align: center;
        top: 0;
        width: 190px;
        background-color: rgba(0,0,0, .3);
        color: white;
        text-shadow: 0 2px 5px black;
      }

      .recordDetection{
        display: flex;
        align-items: center;
        margin-left: 2px;

        svg {
          path{
            fill: #d8d8d8;
          }

          &.ready{
            path{
              fill: #367fff;
            }
          }
        }
      }

      .uploadCount{
        position:absolute;
        border-radius: 50px;
        margin: 10px;
        text-align: center;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0, .3);
        color: white;
        text-shadow: 0 2px 5px black;
        min-width: 30px;
        justify-content: center;
        width: fit-content;
        padding: 0px 8px;

        > span {
          min-width: 16px;
        }
      }
    }
  }
`;

export const StyledDetailsRecordsHistory = styled.div`
  h2 {
    margin-right:20px;
  }
`;

export const StyledDownloadReport = styled.div`
  h2 {
    margin-right:20px;
  }
`;


export const StyledMenuSubHeader = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 1px;
  color: #7A8F99;
  padding-top:40px;
  margin-right:25px;
  padding-bottom:10px;
  border-bottom: 1px solid #EBF2F5;
  text-transform: uppercase;
`;

export const StyledMenuItem = styled.div`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.8px;
  color: #000000;
  margin-right:25px;
  border-bottom: 1px solid #EBF2F5;
  display:flex;
  align-items:center;

  background-color: ${props => props.selected ? '#EDF3F6' : ''};

  &:hover{
    cursor:pointer;
    opacity: 0.75;
  }

  a {
    width: 100%;height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  a,a:hover,a:visited,a:active,a:focus{
   color: inherit;
   text-decoration: none !important;
   outline: none !important;
  }

  .clickItem{

      width: 100%;height: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
  }

  svg {
    margin-left: 10px;
  }

`;

export const StyledLogoutButton = styled.div`
  display:flex;
  justify-content: center;
`;

export const StyledMenuList = styled.div`
  height: 570px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px 40px;
  padding-top: 0px;
  overflow: overlay;

  button {
    text-align:center;
  }
`;

export const StyledEditAvatar = styled.div`
  > input {
    display:none;
  }
  position: absolute;
  bottom: 0;
  text-align: center;
  font-family: Proxima Nova Bold;
  text-shadow: 0 2px 5px black;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.8px;
  color: #FFFFFF;
  width: 100%;
  margin-bottom:${props => (props.progress > 0) ? '5px' : '8px'};
  &:hover{
    cursor:pointer;
    cursor: ${props => (props.progress > 0) ? 'default' : 'pointer'};
  }
`;

export const StyledProfileAvatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100px;

  ${InitialsAvatar} {
    width: 100px;
    height: 100px;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit:cover;
    border-radius: 100px;
  }

`;

export const StyledMenuHeader = styled.div`
  margin-bottom: 20px;
  padding: 25px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: fit-content;

  h2 {
    font-family: Proxima Nova Bold;
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #121719;
  }


  > div {
    margin-top: 30px;
    display:flex;
    max-width: 400px;

    &.loading{
      width:100%;
      justify-content:center;
      height:100px;
    }
  }

  .loadingCircle{
    width: 80px;
  }

  .image{
    position:relative;
    height: 100px;
  }

  .details{
    margin-left: 30px;
    overflow-x: hidden;

    .name{
      font-family: Proxima Nova;
      font-style: normal;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.8px;
      color: #000000;
    }
    .email{
      margin-top: 0px;
      font-family: Proxima Nova;
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #6B8A99;
    }
    .link{
      display:flex;
      justify-content:space-between;
      align-items:center;
      margin-top: 15px;
      font-family: Proxima Nova;
      font-style: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.8px;
      color: #000000;
      opacity: 0.3;
      a,a:hover,a:visited,a:active,a:focus{
       color: inherit;
       display:flex;
       text-decoration: none !important;
       outline: none !important;
      }
      a:hover{
        cursor:pointer;
        opacity: 0.75;
      }
    }

  }
`;

export const StyledDetailColumn = styled.section`
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 35px 0px 25px 25px;
  position:relative;
  height: 800px;
  h2{
    font-family: Proxima Nova Semibold;
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    color: #121719;
    padding-bottom: 4px;
    border-bottom: 1px solid #EBF2F5;

  }

  .successMessage {
    height: 35px;
    display:flex;
    align-items:center;
  }

`;
export const StyledMenuColumn = styled.section`
  display:grid;
`;

export const StyledSectionWrapper = styled(StyledContentSection)`
  > section {
    width:1200px;height:800px;padding:0;
    display:grid;
    grid-template-columns:450px 1fr;grid-gap:2%;
    box-shadow: none;

    h2 {
      line-height:22px;letter-spacing:0;
    }
  }
`;

export const SectionTitle = styled(StyledContentTitle)`
  color: #273238;
  font-size: 30px;
  line-height: 30px;
  font-family: Proxima Nova;
  padding: 0 10px;
`; //content__title

export const SectionPage = styled(StyledContent)`
`; // content report
