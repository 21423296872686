const PREFIX = 'CHATLIST_';
export const constants = {
  UPDATE: `${PREFIX}UPDATE`,
  USER_DATA_REQUEST: `${PREFIX}USER_DATA_REQUEST`,
  USER_DATA_SUCCESS: `${PREFIX}USER_DATA_SUCCESS`,
  USER_DATA_FAILURE: `${PREFIX}USER_DATA_FAILURE`,
  CHAT_LIST_SELECT_CONVERSATION: `${PREFIX}CHAT_LIST_SELECT_CONVERSATION`,
  CHAT_LIST_LOAD_REQUEST: `${PREFIX}CHAT_LIST_LOAD_REQUEST`,
  CHAT_LIST_LOAD_SUCCESS: `${PREFIX}CHAT_LIST_LOAD_SUCCESS`,
  CHAT_LIST_LOAD_FAILURE: `${PREFIX}CHAT_LIST_LOAD_FAILURE`,

  MESSAGE_DRAFT_UPDATED: `${PREFIX}MESSAGE_DRAFT_UPDATED`,
  MESSAGE_DRAFT_DISCARDED: `${PREFIX}MESSAGE_DRAFT_DISCARDED`,

  UPDATE_CHANNEL_MESSAGE_ACTIONS: `${PREFIX}UPDATE_CHANNEL_MESSAGE_ACTIONS`,

  LOADING_MESSAGE_UPDATE: `${PREFIX}LOADING_MESSAGE_UPDATE`,
  LOADING_MESSAGE_DISCARDED: `${PREFIX}LOADING_MESSAGE_DISCARDED`,

}
