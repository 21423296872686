
import React from 'react';

export const PrintIcon = ({width=24, height=24, color="#405159"}) => {

  return(
        <svg  width={`${width}px`} height={`${height}px`} viewBox="0 0 24 24" color={color} fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7.04616" y="13.8574" width="9.90769" height="6.19231" stroke="currentColor"/>
            <rect x="7.04616" y="3.9502" width="9.90769" height="3.71538" stroke="currentColor"/>
            <path d="M7.04615 16.9545H3.95V7.66602H20.05V16.9545H16.9538" stroke="currentColor"/>
            <path d="M14.4769 10.7617H17.5731" stroke="currentColor"/>
        </svg>
  )
}
