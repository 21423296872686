import { API_URLS, USER_TYPES } from '../_config';
import { authHeader, handleResponse,handleResponseNoModal, handleMiscFetchError } from '../_helpers';
import { FORM_ERROR } from 'final-form';

export const services = {
  loginSocial,
  loginLineSocial,
  login,
  logout,
  getProfile,
  getPaymentSetting,
  updateProfile,
  changePassword,
  getProfiles,
  createProfile,
  getProfilesWithId,
  updateProfileWithId,
  removeProfile,
  getChatInitial,
  register,
  registerSocial,
  activateEmail,
  requestCodeActivation,
  createNewPassword,
  resetPassword,
  fetchPayments,
  addPayment,
  removePayment,
  changeDefaultPayment,
  requestCodeResetPassword
}


function loginLineSocial(data) {
  const requestUrl = API_URLS.loginLineSocial;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  return fetch(requestUrl.url, requestOptions)
      .then(response => {
          if (!response.ok) {
              return response.json().then(Promise.reject.bind(Promise));
          }
          return response.json();
      })
      .then(resp => {
          // login successful if there's a token in the response
          console.log("login response", resp)
          if (resp && resp.token) {
              localStorage.setItem('user', JSON.stringify(resp));
          }
          return resp;
      });
}

function loginSocial(data) {
    const requestUrl = API_URLS.loginSocial;
    const requestOptions = {
        method: requestUrl.method,
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    return fetch(requestUrl.url, requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(Promise.reject.bind(Promise));
            }
            return response.json();
        })
        .then(resp => {
            // login successful if there's a token in the response
            console.log("login response", resp)
            if (resp && resp.token) {
                localStorage.setItem('user', JSON.stringify(resp));
            }
            return resp;
        });
}

function loginFacebook(data) {
    const requestUrl = API_URLS.loginSocial;
    const requestOptions = {
        method: requestUrl.method,
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    return fetch(requestUrl.url, requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(Promise.reject.bind(Promise));
            }
            return response.json();
        })
        .then(resp => {
            // login successful if there's a token in the response
            console.log("login response", resp)
            if (resp && resp.token) {
                localStorage.setItem('user', JSON.stringify(resp));
            }
            return resp;
        });
}

function login(data) {
  const requestUrl = API_URLS.login;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  return fetch(requestUrl.url, requestOptions)
      .then(response => {
          if (!response.ok) {
              return response.json().then(Promise.reject.bind(Promise));
          }
          return response.json();
      })
      .then(resp => {
          // login successful if there's a token in the response
          // TODO user type is checked in backend anyway
          if (resp?.user_type != USER_TYPES.ENDUSER && resp?.user_type != USER_TYPES.ORPHANAGE  && resp?.user_type != USER_TYPES.DENTAL){
            // have to throw here and on the then reject
            throw {[FORM_ERROR] : 'wrong_user_type'}
          } else if (resp && resp.token) {
            localStorage.setItem('user', JSON.stringify(resp));
          }
          return resp;
      }).then(
        resp => {console.log("response on login", resp);return resp},
        err => {console.log("error login", err);throw err; }
      );
}

function registerSocial(data){
    const requestUrl = API_URLS.registerSocial;
    const requestOptions = {
        method: requestUrl.method,
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    return fetch(requestUrl.url, requestOptions)
        .then(response => {
            if (!response.ok) {
                return response.json().then(Promise.reject.bind(Promise));
            }
            return response.json();
        })
        .then(resp => {
            // login successful if there's a token in the response
            console.log("login response", resp)
            if (resp && resp.token) {
                localStorage.setItem('user', JSON.stringify(resp));
            }
            return resp;
        });
}

function register(data){
  const requestUrl = API_URLS.register;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function activateEmail(data){
  const requestUrl = API_URLS.activateEmail;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions)
      .then(response => {
          if (!response.ok) {
              return response.json().then(Promise.reject.bind(Promise));
          }
          return response.json();
      })
      .then(resp => {
          // login successful if there's a token in the response
          if (resp && resp.token) {
              localStorage.setItem('user', JSON.stringify(resp));
          }
          return resp;
      });
}

function requestCodeActivation(data){
  const requestUrl = API_URLS.requestCodeActivation;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function resetPassword(data){
  const requestUrl = API_URLS.resetPassword;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function createNewPassword(data){
  const requestUrl = API_URLS.createNewPassword;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponseNoModal);
}


function requestCodeResetPassword(data){
  const requestUrl = API_URLS.requestCodeResetPassword;
  const requestOptions = {
      method: requestUrl.method,
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };
  const url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function getProfile() {
  const requestUrl = API_URLS.getProfile;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse).catch(handleMiscFetchError);
}

function getProfiles(query){
  const requestUrl = API_URLS.getProfiles;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url+`?query=${query}`;
  return fetch(url, requestOptions).then(handleResponse).catch(handleMiscFetchError);
}

function getProfilesWithId(id){
  const requestUrl = API_URLS.getProfileWithId;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
  };

  let url = requestUrl.url.replace("{profileId}", id);
  return fetch(url, requestOptions).then(handleResponse);
}

function createProfile(data){
  const requestUrl = API_URLS.createProfile;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function removeProfile(id){
  const requestUrl = API_URLS.removeProfile;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify({})
  };

  let url = requestUrl.url.replace("{profileId}", id);
  return fetch(url, requestOptions).then(handleResponse);
}

function updateProfileWithId(id, data){
  const requestUrl = API_URLS.updateProfileWithId;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  let url = requestUrl.url.replace("{profileId}", id);
  return fetch(url, requestOptions).then(handleResponse);
}


function getPaymentSetting(return_url=null){
  const requestUrl = API_URLS.getPaymentSettings;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  if (return_url) {
    url = url + "?return_url=" + return_url;
  }
  
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchPayments(){
  const requestUrl = API_URLS.fetchOmisePayments;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function addPayment(data){
  const requestUrl = API_URLS.addOmisePayments;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function removePayment(data){
  const requestUrl = API_URLS.removeOmisePayments;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function changeDefaultPayment(data){
  const requestUrl = API_URLS.changeDefaultOmisePayments;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function updateProfile(data){
  const requestUrl = API_URLS.updateProfile;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function changePassword(data){
  const requestUrl = API_URLS.changePassword;
  const requestOptions = {
      method: requestUrl.method,
      headers: {  ...authHeader(), 'Content-Type': 'application/json'},
      body: JSON.stringify(data)
  };

  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function getChatInitial() {
  const requestUrl = API_URLS.getChatInitial;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function logout() {
    const requestUrl = API_URLS.logout;
    const requestOptions = {
        method: requestUrl.method,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({})
    };

    return fetch(requestUrl.url, requestOptions)
}
