import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation  } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import {
  getIsProfileLoaded, getIsProfileLoading, getProfile
}
from '../Authentication/_selectors';

import { StyledNavigationBar } from './styles';

import logo from '../_images/logoPlover.png';

export const NavigationBar = () => {
  const location = useLocation();
  var currentLocation = location.pathname
  const profileLoaded = useSelector(getIsProfileLoaded);
  const profileLoading = useSelector(getIsProfileLoading);
  const userProfile = useSelector(getProfile);
  if (currentLocation.startsWith("/videochat")){
    return(
      <div></div>
    )
  }
  return (
    <StyledNavigationBar>
      <a href="/">
        <Image src={logo} alt="logo" w={'97px'} h={'20px'} />
      </a>
    </StyledNavigationBar>
  )
}
