import styled from 'styled-components';

export const Styles = styled.div`
  .notifications{
    .content{
      display:flex;
      flex-direction: column;
    }
  }
`;
