import { createSelector } from 'reselect'

export const getCameraState = (state) => state.ploverCamera;

export const getIsAwaitingPlover = createSelector(
  [getCameraState],
  (state) => {
    return state.awaitingDevice;
  }
)

export const getCameraIsError = createSelector(
  [getCameraState],
  (state) => {
    return state.deviceError;
  }
)

export const getPloverDeviceId = createSelector(
  [getCameraState],
  (state) => {
    return state.ploverDeviceId;
  }
)
