import { constants } from './_constants';
import { services } from './_services';

export const actions = {
  filterRecords,
  fetchRecords,
  selectRecord,
  fetchRecordDetail,
  fetchUploadDetections,
  setCurrentDetectionMedia,
  setCurrentMedia,
  update,
  updateRecordDetail
}

function setCurrentMedia(id){
  return (dispatch) => {
    dispatch(update(id))
  }
  function update(id){
    return { type: constants.UPDATE, data: {currentSelectedMedia: id}}
  }
}

function selectRecord(id){
  return (dispatch) => {
    dispatch(update(id))
  }
  function update(id){
    return { type: constants.UPDATE, data: {currentRecord: id}}
  }
}

function update(data){
  return (dispatch) => {
    dispatch(update(data))
  }
  function update(data){
    return { type: constants.UPDATE, data}
  }
}

function filterRecords(type){
  return (dispatch) => {
    dispatch(update(type))
  }
  function update(data) { return {type: constants.UPDATE_STATE, data: {key: 'recordFilter', value:data}}}
}

function setCurrentDetectionMedia(id){
  return (dispatch) => {
    dispatch(update(id))
  }
  function update(id) { return { type: constants.UPDATE, data: {currentDetectionMedia: id}}}
}

function fetchUploadDetections(id){
  return (dispatch) => {
    dispatch(request(id));
    services.fetchUploadDetections(id)
      .then(
        data => dispatch(success(id, data)),
        error => dispatch(failure(id, error))
      )
  }
  function request(id) { return { type: constants.FETCHDETECTIONS_REQUEST, data:{id} } }
  function success(id, data){
    let dataByFrame = data.detections.reduce((acc, curr) => {
      acc[curr[1]] = curr;
      return acc;
    }, {})
    let newData = {[data.id] : {...data, detections:dataByFrame}}

    return { type: constants.FETCHDETECTIONS_SUCCESS, data: {byId: newData, id}}
  }
  function failure(id, error){ return { type: constants.FETCHDETECTIONS_FAILURE, data: { error, id}} }
}

function fetchRecords(params={}){
  return (dispatch) => {
    dispatch(request());
    services.fetchRecords(params)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: constants.FETCHRECORDS_REQUEST } }
  function success(data) {
    // breakdown reports...
    const newData = {};
    data.record_list.map((record, index) => {
      newData[record.id] = record
    })
    return { type:constants.FETCHRECORDS_SUCCESS, data: { byId: newData, isMore: data.is_more}}
  }
  function failure(error) { return { type:constants.FETCHRECORDS_FAILURE, error} }
}

function fetchRecordDetail(id){
  return (dispatch, getState) => {
    dispatch(request(id));
    services.fetchRecordDetail(id)
      .then(
        data => dispatch(success(id, data)),
        error => dispatch(failure(id, error))
      )
  }
  function request(id) { return { type: constants.FETCHRECORD_REQUEST, data: {id} } }
  function success(id, data) {
    // breakdown reports...
    let newData = {[data.id] : data}
    return { type:constants.FETCHRECORD_SUCCESS, data: { byId: newData, id}}
  }
  function failure(id, error) { return { type:constants.FETCHRECORD_FAILURE, data:{ error, id}} }
}

function updateRecordDetail(record){
  return (dispatch)=>{
    dispatch(update(record));
  }
  function update(data) {
    // breakdown eports...
    let newData = {[data.id] : data}
    return { type:constants.UPDATE_STATE_OBJECT, data: { key: 'recordDetailById', value: newData}}
  }
}
