import React, {useState, useEffect, useRef} from 'react';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux';
import cn from 'classnames';

import logo from '../_images/logoPlover.png';
import { authHeader, handleResponse } from '../_helpers';

import styled, {css} from 'styled-components'
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";
import { Flex, Image, Button } from '@chakra-ui/react';

import { LoadingEllipses } from '../_components/LoadingEllipses';

import { toUpper, upperFirst } from 'lodash';

import { API_URLS, URLS } from '../_config';

import { getIntlMessages } from '../Authentication/_selectors';
import { services } from '../Authentication/_services';
// components are from ai project
import {
  ErrorLabel,
  ErrorSpan
}
from '../_styles/forms'

import {
StyledCompanyLogo,
StyledActivationMessage,
StyledActivationWrapper,
StyledResetWrapper,
StyledInputLayout,
StyledInputWithOverlay,
StyledInput,
StyledLoginButton,
errorCss

} from '../Registration/styles';

import {
  Wrapper,
  StyledLoadingButtonLoading
} from '../LoginPage/styles'

const required = value => (value ? undefined : 'required')
const mustBeNumber = value => (isNaN(value) ? 'must_be_number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

const Page = styled.div`
  background-color: #F7F9FA;
  height:100vh;
  display:flex;
`;

const formName = 'resetPasswordForm';

async function resetPassword(values){
  const requestUrl = API_URLS.resetPassword;
  const requestOptions = {
    method: requestUrl.method,
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(values)
  };
  console.log(requestOptions)
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

export const PasswordResetPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [uid, setUid] = useState(null)
  const [token, setToken] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const [initValues, setInitValues] = useState({})
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [password2Hidden, setPassword2Hidden] = useState(true);
  const formRef = useRef(null);
  const emailRef = useRef();
  const intlMessages = useSelector(getIntlMessages);

  const [ sent, setSent ] = useState(false);

  useEffect(()=>{
    if (uid && token){
      setInitValues({uid, token})
    }
  }, [uid, token ])

  const requestResetPassword = async (email) => {
    console.log(emailRef.current, 'EMAIL');

    if (email){
      setSent(true)
      // send email to user
      try{
        const user = await services.requestCodeResetPassword({email});
      } catch (err){
        emailRef.current.focus();
        emailRef.current.blur();
        setSent(false);
      }
    } else {
      emailRef.current.focus();
      emailRef.current.blur();
      setSent(false);
    }
  }
  // handles form submission
  const handleReset = async (submitValues) => {
    console.log(submitValues)

    let values = submitValues;
    if (values.new_password1 !== values.new_password2){
      return {
        new_password2: 'passwordMatch'
      }
    }
    setIsLoading(true);
    try{
      const user = await services.resetPassword(values);
      history.push(URLS.login.url);
    } catch (err){
      var errType = 'general.error';
      if (err.token !== undefined || err.uid !== undefined){
        errType = 'token';
      }
      return {
        ...err,
        [FORM_ERROR] : errType

      }
    } finally{
      setIsLoading(false);
    }
  }

  const renderForm = () => {
    return (
      <StyledResetWrapper styles={{overflowY: 'auto'}}>
        <Form
           initialValues={initValues}
           onSubmit={handleReset}
           render = {({
             submitError,
             formError,
             handleSubmit,
             mutators,
             form,
             reset,
             submitting,
             pristine,
             validating,
             values
           }) => {
             formRef.current = form;
             return (
               <form
                 id={formName}
                 onSubmit={handleSubmit}
                >
                  <Flex justifyContent={'center'}>
                      <Image src={logo} alt="logo" w={'150px'} h={'30px'} />
                  </Flex>
                  <StyledActivationMessage>{intlMessages['resetPasswordForm.form.header']}</StyledActivationMessage>
                  <Field name="email" parse={v => v} validate={required}>
                      {({ input, meta}) => {
                      return (
                        <StyledInputLayout>
                          <StyledInputWithOverlay isSent={sent}>
                            <StyledInput
                              ref={emailRef}
                              {...input}
                              type="text"
                              addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                              placeholder={upperFirst(intlMessages['resetPasswordForm.form.email.label'])} />
                            <div onClick={() => (requestResetPassword(input.value))}>{sent ? intlMessages['resetPasswordForm.form.email.sent']: intlMessages['resetPasswordForm.form.email.sendAgain']}</div>
                          </StyledInputWithOverlay>
                          <ErrorLabel
                            setLineHeight={5}
                            isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                            >
                              {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`resetPasswordForm.form.email.error`] || meta.submitError}
                          </ErrorLabel>
                        </StyledInputLayout>
                      )}
                    }
                    </Field>
                    <Field name="code" parse={v => v} validate={required}>
                      {({ input, meta}) => (
                        <StyledInputLayout>
                          <StyledInput
                            {...input}
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            type="text"
                            placeholder={upperFirst(intlMessages['resetPasswordForm.form.code.label'])} />
                          <ErrorLabel
                            setLineHeight={5}
                            isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                            >
                              {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`resetPasswordForm.form.code.error`] || meta.submitError}
                          </ErrorLabel>
                        </StyledInputLayout>
                      )}
                    </Field>
                    <Field name="new_password1" parse={v => v} validate={required}>
                      {({ input, meta}) => (
                        <StyledInputLayout>
                          <StyledInput
                            {...input}
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            type="password"
                            placeholder={upperFirst(intlMessages['resetPasswordForm.form.password.label'])} />
                          <ErrorLabel
                            setLineHeight={5}
                            isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                            >
                              {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`resetPasswordForm.form.password.error`] || meta.submitError}
                          </ErrorLabel>
                        </StyledInputLayout>
                      )}
                    </Field>
                    <Field name="new_password2" parse={v => v} validate={required}>
                      {({ input, meta}) => (
                        <StyledInputLayout>
                          <StyledInput
                            {...input}
                            addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                            type="password"
                            placeholder={upperFirst(intlMessages['resetPasswordForm.form.password2.label'])} />
                          <ErrorLabel
                            setLineHeight={5}
                            isError={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit))}
                            >
                              {((meta.error && meta.touched)  || (meta.submitError && !meta.dirtySinceLastSubmit)) && intlMessages[`resetPasswordForm.form.password2.error`] || meta.submitError}
                          </ErrorLabel>
                        </StyledInputLayout>
                      )}
                    </Field>
                    <Button
                      variant={'solid'}
                      w={['full']}
                      h={'45px'}
                      isDisabled={
                        submitting ||
                        !(
                          values.code &&
                          values.new_password1 &&
                          values.new_password2
                         )
                      }
                      isLoading={submitting || isLoading}
                      type="submit">
                        {toUpper(intlMessages['resetPasswordForm.form.submit'])}
                    </Button>
              </form>
             )
           }
         } />
      </StyledResetWrapper>
    )
  }

  return (
    <Wrapper>
      {renderForm()}
    </Wrapper>
  )
}
