import React from 'react';

export const UploadIcon = ({width=57, height=38, color="#D3DCE0"}) => {

  return(
    <svg width={`${width}px`} height={`${height}px`}  viewBox="0 0 57 38" fill="none" color={color} xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M45.9563 14.345C45.1585 10.3021 42.9818 6.66154 39.798 4.04521C36.6143 1.42888 32.6209 -0.000931576 28.5 4.55375e-07C21.6362 4.55375e-07 15.675 3.895 12.7063 9.595C9.21556 9.97223 5.98739 11.6261 3.64204 14.2389C1.29668 16.8517 -0.000407792 20.239 9.61703e-08 23.75C9.61703e-08 31.6112 6.38875 38 14.25 38H45.125C51.68 38 57 32.68 57 26.125C57 19.855 52.1313 14.7725 45.9563 14.345ZM33.25 21.375V30.875H23.75V21.375H16.625L28.5 9.5L40.375 21.375H33.25Z" 
            fill="currentColor"
        />
    </svg>
  
  )
}
