import { useQuery } from 'react-query';
import { queryConfig } from '../../_helpers/react-query';

import { services } from '../_services';

export const QUERY_KEY_NAME = 'User_SelectOptions';

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryRequest = async (select_option) => {
    return services.fetchSelectOptions(select_option)
}

export const useSelectOptions = ({ option="" }) => {
    let queryName = null
    if( option == "relationships"){
        queryName = `${QUERY_KEY_NAME}_relationships`
    } else if( option == "report_bulk_filter"){
        queryName = `${QUERY_KEY_NAME}_report_bulk_filter`
    } 

    return useQuery({
        ...newQueryConfig,
        queryKey: [queryName, {}],
        queryFn: () => queryRequest(option), // in brackets because im parsing the object in queryRequest
      });
}