import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { getSelectedProfile, getIntlLocale, getIntlMessages, getProfile, getProfiles } from "../Authentication/_selectors"
import { formatName, localeFormatDate } from "../_helpers";
import { motion, AnimatePresence } from 'framer-motion';
import { toUpper, startCase } from 'lodash';
import { FaChevronDown, FaPlus } from "react-icons/fa";

import { constants as authConstants } from '../Authentication/_constants';
import { actions as authActions } from '../Authentication/_actions';
import { actions as modalActions } from '../Modals/_actions';
import storage from "../_helpers/storage";

const ProfileBadge = ({profile, isSelected, onSelect}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const name = formatName(intlMessages['format.fullName'], profile.first_name, profile.last_name);

  const onClick = () => {
    dispatch({type: authConstants.SELECT_PROFILE, data: profile})
    onSelect();
  }

  return (
    <motion.div 
      key={`ProfileBadge__${profile.id}`}
      initial={{ opacity: 0, x: 5 }}
      animate={{ opacity: isSelected ? 0.8: 1, x: 0 }}
      exit={{ opacity: 0, x: -5 }}
      className={`rounded-full  
        bg-bdDarkGray text-white 
        border-bdDarkGray border border-solid
        py-2 px-3 mr-2 font-semibold
        ${isSelected ? 'shadow-none': 'shadow-md shadow-bdShadow' }
        shadow-md shadow-bdShadow 
        hover:cursor-pointer hover:opacity-70`}
      onClick={() => onClick()}  
    >
      {name}
    </motion.div>
  )
}

const CreateBadge = () => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const handleClick = () => {
    dispatch(modalActions.openModal('profileList', {'profileListPage': 1}));
  }
  return (
    <motion.div
      initial={{ opacity: 0, x: 5 }}
      animate={{ opacity: 1, x: 0, y:0 }}
      exit={{ opacity: 0, y: -5 }}
      className={`
        bg-inherit text-bdDarkGray
        border-bdDarkGray border border-solid
        rounded-full 
        py-2 px-3 mr-2 font-semibold
        inline-flex items-center
        shadow-sm shadow-bdShadow 
        hover:bg-gray-100 hover:shadow-none
        hover:cursor-pointer hover:opacity-70`}
      onClick={()=>handleClick()}
    >
      <div>{startCase(intlMessages['profiles.addmember'])}</div>
      <FaPlus className={'text-bdDarkGray ml-0.5'} size={'12px'} />
    </motion.div>
  )
}


const ProfileList = ({onSelect}) => {
  const profiles = useSelector(getProfiles);
  const currentProfile = useSelector(getProfile);
  const selectedProfile = useSelector(getSelectedProfile);
  return (
    <div
      className={`absolute z-[2] flex w-full justify-center bg-[#EFEFEF] items-center pb-2`}>
      {profiles.map(prof => (<ProfileBadge profile={prof} isSelected={selectedProfile?.id == prof.id} onSelect={onSelect} />) )}
      <CreateBadge />
    </div>
  )
}

const ProfileItem = ({profile, isSelected}) => {
  const intlMessages = useSelector(getIntlMessages);
  const name = formatName(intlMessages['format.fullName'], profile.first_name, profile.last_name);
  return (
    <div class={isSelected ?
      `text-white bg-bdDarkGray mr-2 py-1 px-2 rounded-full`:
      `text-black bg-inherit mr-2 py-1 px-2
      rounded-full`
      }
    >
      {name}
    </div>
  )
}

const AllItem = ({isSelected}) => {
  const intlMessages = useSelector(getIntlMessages);
  return (
    <div class={isSelected ? 
      `text-white bg-bdDarkGray mr-2 py-1 px-2 rounded-full`:
      `text-black bg-inherit mr-2 py-1 px-2
      rounded-full`
      } 
    >
      {toUpper(intlMessages['profiles.list.all'])}
    </div>
  )
}

export const ProfilesSelectorFilter = () => {
  const dispatch = useDispatch();
  const profiles = useSelector(getProfiles);
  const selectedProfile = useSelector(getSelectedProfile);

  useEffect(()=>{
    dispatch(authActions.getProfiles());
  }, [])

  return (
    <div className={'flex py-2 text-[16px]'}>
      <AllItem isSelected={Object.keys(selectedProfile).length == 0} />
      {profiles.map(prof => (<ProfileItem profile={prof} isSelected={selectedProfile.id == prof.id} />))}
    </div>
  )
}

export const ProfilesSelectorHeader = () => {
  const [ isShowing, setIsShowing ] = useState(false);
  const dispatch = useDispatch();
  const selectedProfile = useSelector(getSelectedProfile);
  const intlMessages = useSelector(getIntlMessages);
  const changeProfile = (isShowing) => {
    dispatch(modalActions.openModal('profileList', {'profileListPage': 0}));
    setIsShowing(isShowing);
  }
  const storageProfile = storage.getScanProfile();
  if (selectedProfile?.id == null && storageProfile?.id != null){
    dispatch({type: authConstants.SELECT_PROFILE, data: storageProfile});
  }
  const name = formatName(intlMessages['format.fullName'], selectedProfile?.first_name, selectedProfile?.last_name);
  return (
    <div className="relative flex flex-col w-full pt-[20px] items-center">
      <div className="relative flex items-center">
        <div 
          key={`ProfileBadge__${selectedProfile?.id}`}
          className={`
          rounded-[4px]
          bg-bdBrown text-white
          py-2 px-3 mr-2 font-semibold`}
        >
          {name}
        </div>
        <FaChevronDown className={'hover:cursor-pointer'} onClick={() => changeProfile(true)} />
      </div>
    </div>
  )
}