import React from 'react';
import cn from 'classnames';
import styled , { keyframes } from 'styled-components';
import { TOOTH_NUMBERING_SYSTEM } from '../_config';

const blink = keyframes`
  50% {
    opacity: 1;
  }
`;

const StyledPartialScanTeeth = styled.div`
${props => !props.isFlipped ? 'transform: scale(-1,1);' : ''}

.number{
  opacity:0.2;
  fill:#FFFFFF;
  enable-background:new;
  font-family:Proxima Nova;
  font-size:14px;
  &.FILLED{
    fill:#367FFF;
    opacity: 1;
  }
  &.INIT{
    opacity: 1;
    fill:#ffffff;
  }
  &.SELECTED{
    opacity: 1;
    fill:#367FFF;
  }
  &.FILLEDSELECTED{
    opacity: 1;
    fill:#367FFF;
  }
  &.INITSELECTED{
    opacity: 1;
    fill:#367FFF;
  }

}
.partialRegion {
  opacity:0.2;
  fill-rule:evenodd;
  clip-rule:evenodd;
  fill:#FFFFFF;
  &.FILLED{
    fill:#367FFF;
    opacity: 1;
  }
  &.INIT{
    opacity: 1;
    fill:#ffffff;
  }
  &.FILLEDSELECTED{
    opacity: 0.3;
    fill:#367FFF;
    animation: ${blink} 2s linear infinite;
  }
  &.INITSELECTED{
    opacity: 0.3;
    fill:#367FFF;
    animation: ${blink} 2s linear infinite;
  }
  &:hover{
    cursor:pointer;
  }
}

.tongue{
  opacity:0.2;
  fill-rule:evenodd;
  clip-rule:evenodd;
  fill:#FFFFFF;
}
`;

const flipTextTransform = 'translate(12) scale(-1,1)';
const flipTextTransform16 = 'translate(16) scale(-1,1)';

// nuumbers for teeth are in palmer tooth numbering system
export const PartialScanTeeth = ({charting={}, numberCharting={}, selected,  width="267", height="405", numberingSystem="palmer", isFlipped=false, onClick}) => {
  return(
    <StyledPartialScanTeeth isFlipped={isFlipped}>
      <svg version="1.1" id="partialScan" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267 405" width={width} height={height}>
        <text transform={`matrix(1,0,0,1,-0.2,229.4) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_48", numberCharting["t48"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t48"]}</text>
        <text transform={`matrix(1,0,0,1,3.7,261.4) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_47", numberCharting["t47"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t47"]}</text>
        <text transform={`matrix(1,0,0,1,10.4,292.5) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_46", numberCharting["t46"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t46"]}</text>
        <text transform={`matrix(1,0,0,1,20.5,321.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_45", numberCharting["t45"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t45"]}</text>
        <text transform={`matrix(1,0,0,1,32.7,350.4) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_44", numberCharting["t44"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t44"]}</text>
        <text transform={`matrix(1,0,0,1,50.1,376.4) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_43", numberCharting["t43"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t43"]}</text>
        <text transform={`matrix(1,0,0,1,74.5,394.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_42", numberCharting["t42"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t42"]}</text>
        <text transform={`matrix(1,0,0,1,109.6,402.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_41", numberCharting["t41"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t41"]}</text>
        <text transform={`matrix(1,0,0,1,140.9,402.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_31", numberCharting["t31"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t31"]}</text>
        <text transform={`matrix(1,0,0,1,176.6,394.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_32", numberCharting["t32"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t32"]}</text>
        <text transform={`matrix(1,0,0,1,200.3,376.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_33", numberCharting["t33"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t33"]}</text>
        <text transform={`matrix(1,0,0,1,216.9,350.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_34", numberCharting["t34"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t34"]}</text>
        <text transform={`matrix(1,0,0,1,227.7,321.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_35", numberCharting["t35"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t35"]}</text>
        <text transform={`matrix(1,0,0,1,239.2,292.3) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_36", numberCharting["t36"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t36"]}</text>
        <text transform={`matrix(1,0,0,1,248,261.4) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_37", numberCharting["t37"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t37"]}</text>
        <text transform={`matrix(1,0,0,1,252,229.4) ${!isFlipped ? flipTextTransform16 : ''}`} className={cn("number", "num_38", numberCharting["t38"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t38"]}</text>
        <text transform={`matrix(1,0,0,1,251,183.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_28", numberCharting["t28"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t28"]}</text>
        <text transform={`matrix(1,0,0,1,246.3,151.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_27", numberCharting["t27"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t27"]}</text>
        <text transform={`matrix(1,0,0,1,240.5,120.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_26", numberCharting["t26"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t26"]}</text>
        <text transform={`matrix(1,0,0,1,229.8,91.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_25", numberCharting["t25"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t25"]}</text>
        <text transform={`matrix(1,0,0,1,216.9,62.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_24", numberCharting["t24"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t24"]}</text>
        <text transform={`matrix(1,0,0,1,202.7,36.3) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_23", numberCharting["t23"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t23"]}</text>
        <text transform={`matrix(1,0,0,1,176.9,18.3) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_22", numberCharting["t22"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t22"]}</text>
        <text transform={`matrix(1,0,0,1,144.3,10.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_21", numberCharting["t21"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t21"]}</text>
        <text transform={`matrix(1,0,0,1,113.2,10.1) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_11", numberCharting["t11"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t11"]}</text>
        <text transform={`matrix(1,0,0,1,78.1,18.3) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_12", numberCharting["t12"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t12"]}</text>
        <text transform={`matrix(1,0,0,1,54.7,36.3) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_13", numberCharting["t13"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t13"]}</text>
        <text transform={`matrix(1,0,0,1,38.9,62.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_14", numberCharting["t14"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t14"]}</text>
        <text transform={`matrix(1,0,0,1,25.8,91.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_15", numberCharting["t15"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t15"]}</text>
        <text transform={`matrix(1,0,0,1,15.7,120.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_16", numberCharting["t16"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t16"]}</text>
        <text transform={`matrix(1,0,0,1,8.4,151.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_17", numberCharting["t17"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t17"]}</text>
        <text transform={`matrix(1,0,0,1,5.3,183.2) ${!isFlipped ? flipTextTransform : ''}`} className={cn("number", "num_18", numberCharting["t18"])}>{TOOTH_NUMBERING_SYSTEM[numberingSystem]["t18"]}</text>

        <path className={cn("partialRegion", "38bm", "buc", "mes", charting["t38bm"])} onClick={onClick && (() => onClick('t38bm'))} d="M 229 238.6 c -1.7 0.1 -3.5 0.1 -5.2 0 l -0.4 -6.6 c 0.9 0 1.7 0 2.6 0 c 3.6 -0.1 5.6 -2.1 5.3 -5.1 c -0.1 -1 -0.1 -2 -0.1 -3 l 7.2 -0.4 c 0 1.9 0 3.9 0.2 5.8 C 239.2 234.9 235.5 238.4 229 238.6"/>
        <path className={cn("partialRegion", "38bd", "buc", "dist", charting["t38bd"])} onClick={onClick && (() => onClick('t38bd'))} d="M 231.2 223.8 c 0 -0.9 0 -1.8 0 -2.6 c 0 -2.1 -1 -3.5 -3 -4.3 c -1.7 -0.7 -3.5 -0.9 -5.4 -0.8 c -0.1 0 -0.2 0 -0.2 0 l -0.4 -6.6 c 0.3 0 0.6 0 1 0.1 c 3.6 -0.2 6.8 0.2 9.8 1.4 c 3.6 1.4 5.4 4 5.4 7.8 c 0 1.6 0 3.2 0 4.8 l -7.2 0.4 C 231.2 223.9 231.2 223.8 231.2 223.8"/>
        <path className={cn("partialRegion", "38lm", "ling", "mes", charting["t38lm"])} onClick={onClick && (() => onClick('t38lm'))} d="M 214.6 226.7 c -0.4 2.8 1.3 5 4 5.2 c 1.5 0.1 3 0.2 4.5 0.2 c 0.1 0 0.2 0 0.3 0 l 0.4 6.6 c -2.7 0 -5.4 -0.2 -8.1 -0.4 c -4.9 -0.4 -8 -4.4 -7.3 -9.4 c 0.2 -1.3 0.1 -2.4 -0.1 -3.6 l 6.4 -0.4 C 214.7 225.5 214.7 226.1 214.6 226.7"/>
        <path className={cn("partialRegion", "38ld", "ling", "dist", charting["t38ld"])}  onClick={onClick && (() => onClick('t38ld'))} d="M 222.2 216.1 c -1.6 -0.1 -3.4 0.3 -5 0.8 c -3.7 1.1 -4.7 3.1 -3.1 6.6 c 0.2 0.4 0.4 0.9 0.5 1.3 c 0 0.1 0 0.1 0 0.2 l -6.4 0.4 c -0.2 -0.8 -0.5 -1.6 -0.8 -2.4 c -2.8 -6.3 -1.1 -10 5.6 -12 c 3.1 -0.9 6.2 -1.5 9.2 -1.4 l 0.4 6.6 C 222.4 216.1 222.3 216.1 222.2 216.1"/>
        <path className={cn("partialRegion", "38o", "occ", charting["t38o"])} onClick={onClick && (() => onClick('t38o'))} d="M 217.2 216.8 c 1.7 -0.5 3.4 -0.8 5 -0.8 c 0.1 0 0.2 0 0.3 0 s 0.2 0 0.2 0 c 2 -0.1 3.7 0.1 5.4 0.8 c 2 0.8 3 2.2 3 4.3 c 0 0.9 0 1.8 0 2.6 c 0 0.1 0 0.1 0 0.2 c 0 1 0 2 0.1 3 c 0.3 3.1 -1.7 5 -5.3 5.1 c -0.9 0 -1.7 0 -2.6 0 c -0.1 0 -0.2 0 -0.3 0 c -1.5 0 -3 -0.1 -4.5 -0.2 c -2.7 -0.2 -4.4 -2.4 -4 -5.2 c 0.1 -0.6 0.1 -1.2 -0.1 -1.8 c 0 -0.1 0 -0.1 0 -0.2 c -0.1 -0.4 -0.3 -0.9 -0.5 -1.3 C 212.5 220 213.5 217.9 217.2 216.8"/>

        <path className={cn("partialRegion", "34ld", "ling", "dist", charting["t34ld"])} onClick={onClick && (() => onClick('t34ld'))} d="M 199.1 328.4 c -0.5 -0.3 -1.1 -0.5 -1.6 -0.8 c -2.4 -1.2 -4.8 -0.2 -6.1 1.9 c -0.2 0.3 -0.4 0.6 -0.5 0.9 c -0.1 0.1 -0.1 0.3 -0.2 0.4 l -4.9 -2.8 c 0.3 -0.6 0.6 -1.2 1 -1.8 c 2.5 -4 7 -5.9 11.6 -3.7 c 1 0.5 2.1 1 3.1 1.6 L 199.1 328.4 C 199.2 328.4 199.2 328.4 199.1 328.4"/>
        <path className={cn("partialRegion", "34lm", "ling", "mes", charting["t34lm"])} onClick={onClick && (() => onClick('t34lm'))} d="M 185.9 328 l 4.9 2.8 c -0.5 1.3 -0.8 2.7 -0.9 4.1 c -0.1 1.5 0.9 2.9 2.3 3.4 c 0.5 0.2 0.9 0.3 1.4 0.4 h 0.1 l -2.8 5.1 c -0.9 -0.2 -1.8 -0.5 -2.7 -0.8 c -2.7 -0.9 -4.6 -3.5 -4.4 -6.4 C 184 333.6 184.6 330.7 185.9 328"/>
        <path className={cn("partialRegion", "34bd", "buc", "dist", charting["t34bd"])} onClick={onClick && (() => onClick('t34bd'))} d="M 202.1 336.8 c 1.3 -1.9 1.3 -4.9 -0.1 -6.4 c -0.8 -0.8 -1.8 -1.4 -2.8 -2 l 2.3 -4.4 c 2 1.1 3.8 2.3 5.5 3.9 c 2.8 2.8 2.8 8.5 0.3 12.3 l -5.4 -3.1 C 201.9 337 202 336.9 202.1 336.8"/>
        <path className={cn("partialRegion", "34bm", "buc", "mes", charting["t34bm"])} onClick={onClick && (() => onClick('t34bm'))} d="M 196.7 339 c 1.1 0.1 2.2 -0.2 3.4 -0.6 c 0.7 -0.2 1.3 -0.7 1.7 -1.3 l 5.4 3.1 c -0.9 1.4 -2.2 2.5 -3.8 3.1 c -2.2 0.8 -4.4 1.3 -6.5 1.2 c -2.1 0 -4.1 -0.2 -6 -0.7 l 2.8 -5.1 C 194.7 338.9 195.7 339 196.7 339"/>
        <path className={cn("partialRegion", "34o", "occ", charting["t34o"])} onClick={onClick && (() => onClick('t34o'))} d="M 190.8 330.8 c 0.1 -0.1 0.1 -0.3 0.2 -0.4 c 0.2 -0.3 0.3 -0.6 0.5 -0.9 c 1.3 -2.1 3.6 -3.1 6.1 -1.9 c 0.5 0.3 1.1 0.5 1.6 0.8 c 0 0 0.1 0 0.1 0.1 c 1 0.5 1.9 1.2 2.8 2 c 1.5 1.4 1.5 4.4 0.1 6.4 c -0.1 0.1 -0.2 0.2 -0.3 0.3 c -0.5 0.6 -1 1 -1.7 1.3 c -1.1 0.4 -2.3 0.7 -3.4 0.6 c -1.1 0 -2 -0.1 -3 -0.3 h -0.1 c -0.5 -0.1 -1 -0.3 -1.4 -0.4 c -1.4 -0.5 -2.4 -1.8 -2.3 -3.4 C 190 333.5 190.3 332.1 190.8 330.8"/>

        <path className={cn("partialRegion", "35bd", "buc", "dist", charting["t35bd"])} onClick={onClick && (() => onClick('t35bd'))} d="M 214.2 314 c 1.2 -2.4 1.1 -5.6 -0.9 -7.4 c -1.2 -1.1 -2.5 -2 -3.9 -2.7 l 1.8 -4.4 c 2.4 1.2 4.6 2.7 6.7 4.6 c 3.4 3.1 3.5 8.6 1.5 12.6 l -5.6 -2.2 C 214 314.3 214.1 314.2 214.2 314"/>
        <path className={cn("partialRegion", "35bm", "buc", "mes", charting["t35bm"])} onClick={onClick && (() => onClick('t35bm'))} d="M 201.8 322.3 l 2.1 -5.1 c 1 0.2 2 0.2 2.9 0.3 c 1.6 -0.1 3 -0.3 4.4 -0.8 c 1.1 -0.4 2 -1.2 2.6 -2.3 l 5.6 2.2 c -1.1 2.2 -2.8 4 -5 4.8 c -2.4 0.8 -4.9 1.3 -7.6 1.4 C 205.3 322.8 203.6 322.6 201.8 322.3"/>
        <path className={cn("partialRegion", "35ld", "ling", "dist", charting["t35ld"])} onClick={onClick && (() => onClick('t35ld'))} d="M 209.4 303.9 c -1 -0.5 -2 -0.9 -3 -1.2 c -1.7 -0.5 -3.2 0.1 -4.5 1.2 c -1.3 1.2 -2.1 2.6 -2.6 4.1 c -0.1 0.2 -0.1 0.4 -0.2 0.6 l -5.1 -2 c 0.9 -2.6 2.2 -5 4.5 -7 c 2.2 -1.9 4.8 -2.9 7.6 -2.1 c 1.8 0.5 3.5 1.2 5.1 2 L 209.4 303.9 C 209.5 303.9 209.4 303.9 209.4 303.9"/>
        <path className={cn("partialRegion", "35lm", "ling", "mes", charting["t35lm"])} onClick={onClick && (() => onClick('t35lm'))} d="M 198.5 312 c -0.2 2.5 1 4.1 3.5 4.9 c 0.6 0.2 1.2 0.3 1.9 0.4 c 0 0 0 0 0.1 0 l -2.1 5.1 c -1.1 -0.2 -2.1 -0.4 -3.2 -0.7 c -4.2 -1.3 -6.3 -4 -5.9 -8.3 c 0.2 -2.3 0.6 -4.6 1.3 -6.7 l 5.1 2 C 198.8 309.7 198.6 310.8 198.5 312"/>
        <path className={cn("partialRegion", "35o", "occ", charting["t35o"])} onClick={onClick && (() => onClick('t35o'))} d="M 198.5 312 c 0.1 -1.1 0.3 -2.3 0.6 -3.4 c 0.1 -0.2 0.1 -0.4 0.2 -0.6 c 0.5 -1.5 1.3 -2.9 2.6 -4.1 c 1.3 -1.1 2.8 -1.7 4.5 -1.2 c 1.1 0.3 2.1 0.7 3 1.2 c 0 0 0 0 0.1 0 c 1.4 0.7 2.7 1.6 3.9 2.7 c 2 1.8 2.1 5 0.9 7.4 c -0.1 0.2 -0.2 0.3 -0.3 0.5 c -0.6 1 -1.5 1.9 -2.6 2.3 c -1.4 0.5 -2.9 0.8 -4.4 0.8 c -1 -0.1 -1.9 -0.2 -2.9 -0.3 c 0 0 0 0 -0.1 0 c -0.6 -0.1 -1.3 -0.2 -1.9 -0.4 C 199.5 316.1 198.3 314.5 198.5 312"/>


        <path className={cn("partialRegion", "36bd","buc", "dist",  charting["t36bd"])} onClick={onClick && (() => onClick('t36bd'))} d="M 222.6 286.3 c 0.3 -1.1 0.6 -2.1 0.8 -3.2 c 0.4 -2.2 -0.8 -4.5 -2.7 -5.2 c -1.2 -0.5 -2.5 -0.9 -3.7 -1.3 l 1.3 -5 c 2.2 0.6 4.3 1.4 6.4 2.2 c 3.3 1.2 5.2 5.1 4.5 8.8 c -0.3 1.8 -0.8 3.6 -1.4 5.4 l -5.4 -1.2 C 222.5 286.6 222.5 286.4 222.6 286.3"/>
        <path className={cn("partialRegion", "36bm", "buc", "mes",  charting["t36bm"])} onClick={onClick && (() => onClick('t36bm'))} d="M 213.1 297.3 c -0.5 0 -1 0 -1.5 0 l 1.4 -5.4 c 0.3 0 0.6 0 0.8 0 c 1.4 0.1 3 -0.1 4.5 -0.6 s 2.8 -1.3 3.4 -2.8 c 0.2 -0.6 0.4 -1.2 0.6 -1.7 l 5.4 1.2 c -0.4 1.2 -0.8 2.5 -1.3 3.7 c -1 2.6 -3.2 4 -5.8 4.7 C 218.2 297.1 215.6 297.5 213.1 297.3"/>
        <path className={cn("partialRegion", "36ld", "ling", "dist", charting["t36ld"])} onClick={onClick && (() => onClick('t36ld'))} d="M 216.9 276.6 c -0.7 -0.2 -1.4 -0.4 -2.2 -0.6 c -3.3 -0.8 -5.9 1.1 -6.4 4.4 c -0.1 0.9 -0.3 1.8 -0.5 2.6 c 0 0.2 -0.1 0.3 -0.1 0.4 l -4.7 -1.1 c 0.4 -1.4 0.7 -2.9 0.9 -4.4 c 0.7 -5.5 5.2 -8.6 10.7 -7.4 c 1.2 0.3 2.4 0.6 3.7 1 L 216.9 276.6 C 217 276.6 217 276.6 216.9 276.6"/>
        <path className={cn("partialRegion", "36lm", "ling", "mes", charting["t36lm"])}onClick={onClick && (() => onClick('t36lm'))}  d="M 206.7 286.5 c -0.9 2.4 -0.2 4 2.3 4.8 c 1.2 0.4 2.5 0.6 3.9 0.6 c 0 0 0 0 0.1 0 l -1.4 5.4 c -2.3 -0.1 -4.5 -0.3 -6.5 -1 c -4.2 -1.3 -5.5 -4 -3.9 -8.1 c 0.7 -1.9 1.4 -3.9 1.9 -5.8 l 4.7 1.1 C 207.4 284.4 207.1 285.5 206.7 286.5"/>
        <path className={cn("partialRegion", "36o", "occ", charting["t36o"])} onClick={onClick && (() => onClick('t36o'))} d="M 206.7 286.5 c 0.4 -1 0.7 -2 1 -3 c 0 -0.1 0.1 -0.3 0.1 -0.4 c 0.2 -0.9 0.4 -1.7 0.5 -2.6 c 0.4 -3.3 3.1 -5.1 6.4 -4.4 c 0.7 0.2 1.5 0.4 2.2 0.6 c 0 0 0 0 0.1 0 c 1.3 0.4 2.5 0.8 3.7 1.3 c 1.9 0.7 3.1 3 2.7 5.2 c -0.2 1.1 -0.5 2.2 -0.8 3.2 c 0 0.1 -0.1 0.3 -0.2 0.4 c -0.2 0.6 -0.4 1.2 -0.6 1.7 c -0.6 1.5 -1.9 2.4 -3.4 2.8 c -1.5 0.5 -3.1 0.7 -4.5 0.6 c -0.3 0 -0.6 0 -0.8 0 H 213 c -1.4 0 -2.7 -0.2 -3.9 -0.6 C 206.6 290.5 205.8 288.9 206.7 286.5"/>

        <path className={cn("partialRegion", "37bd", "buc", "dist", charting["t37bd"])} onClick={onClick && (() => onClick('t37bd'))} d="M 229.9 255.3 c 0.2 -0.7 0.4 -1.4 0.5 -2.2 c 0.3 -2.2 -0.5 -3.9 -2.5 -4.8 c -1.7 -0.8 -3.5 -1.2 -5.3 -1.4 c -0.1 0 -0.1 0 -0.2 0 l 1 -7 c 3.3 0.3 6.5 1.1 9.6 2.6 c 3.7 1.6 5.1 4.7 4.6 8.7 c -0.2 1.4 -0.5 2.7 -0.9 4 c -0.3 0.9 -0.6 1.9 -0.8 2.9 l -6.5 -0.8 c 0 -0.1 0 -0.2 0.1 -0.4 C 229.6 256.4 229.7 255.8 229.9 255.3 z"/>
        <path className={cn("partialRegion", "37bm", "buc", "mes", charting["t37bm"])} onClick={onClick && (() => onClick('t37bm'))} d="M 218.9 270.4 l 1 -6.7 c 0.1 0 0.1 0 0.2 0 c 0.9 0.2 1.8 0.3 2.5 0.4 c 4.1 0.1 6.3 -1.9 6.5 -5.3 c 0 -0.5 0.1 -1.1 0.2 -1.6 l 6.5 0.8 c -0.3 1.1 -0.4 2.3 -0.5 3.5 c -0.3 6.2 -4.3 9.7 -11.8 9.6 C 222.2 270.9 220.6 270.7 218.9 270.4"/>
        <path className={cn("partialRegion", "37ld", "ling", "dist", charting["t37ld"])} onClick={onClick && (() => onClick('t37ld'))} d="M 216.5 247.4 c -2.4 0.6 -3.6 2.6 -3.2 5 c 0.2 0.9 0.2 1.7 0.2 2.6 c 0 0.1 0 0.2 0 0.4 l -6.6 -0.8 c 0.1 -1.5 -0.1 -3.1 -0.3 -4.7 c -0.7 -4.3 1.5 -7.9 5.8 -9 c 3.7 -1 7.4 -1.3 11 -0.9 l -1 7 C 220.4 246.7 218.4 246.9 216.5 247.4"/>
        <path className={cn("partialRegion", "37lm", "ling", "mes", charting["t37lm"])} onClick={onClick && (() => onClick('t37lm'))} d="M 212.9 257.7 c -0.8 2.2 0.5 4.5 2.7 5.1 c 1.5 0.4 2.9 0.6 4.3 0.9 l -1 6.7 c -2.6 -0.4 -5.4 -0.9 -8.1 -1.6 c -4.1 -1 -6.4 -5.3 -5 -9.3 c 0.6 -1.7 0.9 -3.3 1 -5 l 6.6 0.8 C 213.4 256.1 213.2 256.9 212.9 257.7"/>
        <path className={cn("partialRegion", "37o", "occ", charting["t37o"])} onClick={onClick && (() => onClick('t37o'))} d="M 212.9 257.7 c 0.3 -0.8 0.4 -1.6 0.5 -2.4 c 0 -0.1 0 -0.2 0 -0.4 c 0 -0.8 0 -1.7 -0.2 -2.6 c -0.4 -2.4 0.8 -4.3 3.2 -5 c 2 -0.5 3.9 -0.7 5.9 -0.5 c 0.1 0 0.1 0 0.2 0 c 1.8 0.2 3.6 0.6 5.3 1.4 c 2 0.9 2.8 2.6 2.5 4.8 c -0.1 0.8 -0.3 1.5 -0.5 2.2 c -0.2 0.5 -0.3 1 -0.4 1.6 c 0 0.1 0 0.2 -0.1 0.4 c -0.1 0.5 -0.2 1 -0.2 1.6 c -0.2 3.4 -2.4 5.4 -6.5 5.3 c -0.8 -0.1 -1.6 -0.3 -2.5 -0.4 c -0.1 0 -0.1 0 -0.2 0 c -1.4 -0.2 -2.8 -0.5 -4.3 -0.9 C 213.4 262.2 212.1 259.9 212.9 257.7"/>

        <path className={cn("partialRegion", "31b", "buc",charting["t31b"])} onClick={onClick && (() => onClick('t31b'))} d="M 159.9 374.4 c -0.5 -0.6 -1 -1.2 -1.5 -1.8 l -24.1 4.1 c -0.1 0.8 -0.3 1.6 -0.4 2.3 c 0 3.3 0.2 4 1.6 4.7 c 2.5 1.3 5.4 1.5 8 1.5 c 3.5 0 7.1 -1.1 10.6 -1.8 c 1.8 -0.4 3.5 -1 5 -2.1 C 162.1 379.1 162.3 377.3 159.9 374.4"/>
        <path className={cn("partialRegion", "31l", "ling", charting["t31l"])} onClick={onClick && (() => onClick('t31l'))} d="M 154.6 369.2 c -3 -2.2 -6 -4.5 -9.8 -5.2 c -2.2 -0.4 -4.1 0 -5.8 1.6 c -3.3 3.3 -4.3 7.3 -4.9 11 l 24.1 -4.1 C 157.2 371.3 156 370.2 154.6 369.2"/>

        <path className={cn("partialRegion", "32b", "buc", charting["t32b"])} onClick={onClick && (() => onClick('t32b'))} d="M 182 365.6 c -1.3 -1.5 -2.7 -2.8 -4.1 -4 l -17.4 9.9 c 0.4 1.4 0.9 2.7 1.5 4 c 0.4 1 1.2 1.4 2.3 1.6 c 2.9 0.4 5.6 -0.4 8.2 -1.4 c 3.3 -1.3 6.5 -2.8 8.9 -5.4 C 183 368.6 183.3 367 182 365.6"/>
        <path className={cn("partialRegion", "32l", "ling",charting["t32l"])} onClick={onClick && (() => onClick('t32l'))} d="M 166.9 356.8 c -4.7 -0.4 -6.5 1.6 -7.2 5 c -0.2 0.8 -0.3 1.7 -0.3 2.4 c 0.1 2.6 0.5 5 1.2 7.3 l 17.4 -9.9 C 174.7 359 171.2 357.2 166.9 356.8"/>

        <path className={cn("partialRegion", "33b", "buc", charting["t33b"])} onClick={onClick && (() => onClick('t33b'))} d="M 195.6 353.5 c -0.2 -0.6 -0.5 -1.1 -0.8 -1.6 c -1.3 -2.3 -2.9 -4.2 -4.8 -5.9 l -10.5 14.4 c 2.9 0.8 5.9 1 8.9 1.3 c 0.6 0 1.2 0 1.8 0 C 195.7 361.3 197.6 358.4 195.6 353.5"/>
        <path className={cn("partialRegion", "33l", "ling", charting["t33l"])} onClick={onClick && (() => onClick('t33l'))} d="M 183.1 341.1 c -2.4 -1.2 -4.7 -0.9 -6.7 1 c -2.3 2.2 -3.7 4.9 -4.4 7.9 c -0.9 3.9 0.5 6.9 3.9 8.8 c 1.1 0.6 2.3 1.1 3.6 1.4 l 10.5 -14.4 C 188 344 185.7 342.4 183.1 341.1"/>

        <path className={cn("partialRegion", "28bm", "buc", "mes", charting["t28bm"])} onClick={onClick && (() => onClick('t28bm'))} d="M 229 164.6 c -1.7 -0.1 -3.5 -0.1 -5.2 0 l -0.4 6.6 c 0.9 0 1.7 0 2.6 0 c 3.6 0.1 5.6 2.1 5.3 5.1 c -0.1 1 -0.1 2 -0.1 3 l 7.2 0.4 c 0 -1.9 0 -3.9 0.2 -5.8 C 239.2 168.3 235.5 164.9 229 164.6"/>
        <path className={cn("partialRegion", "28bd", "buc", "dist", charting["t28bd"])} onClick={onClick && (() => onClick('t28bd'))} d="M 231.2 179.5 c 0 0.9 0 1.8 0 2.6 c 0 2.1 -1 3.5 -3 4.3 c -1.7 0.7 -3.5 0.9 -5.4 0.8 c -0.1 0 -0.2 0 -0.2 0 l -0.4 6.6 c 0.3 0 0.6 0 1 -0.1 c 3.6 0.2 6.8 -0.2 9.8 -1.4 c 3.6 -1.4 5.4 -4 5.4 -7.8 c 0 -1.6 0 -3.2 0 -4.8 l -7.2 -0.4 C 231.2 179.3 231.2 179.4 231.2 179.5"/>
        <path className={cn("partialRegion", "28lm", "ling", "mes", charting["t28lm"])} onClick={onClick && (() => onClick('t28lm'))} d="M 214.6 176.5 c -0.4 -2.8 1.3 -5 4 -5.2 c 1.5 -0.1 3 -0.2 4.5 -0.2 c 0.1 0 0.2 0 0.3 0 l 0.4 -6.6 c -2.7 0 -5.4 0.2 -8.1 0.4 c -4.9 0.4 -8 4.4 -7.3 9.4 c 0.2 1.3 0.1 2.4 -0.1 3.6 l 6.4 0.4 C 214.7 177.7 214.7 177.2 214.6 176.5"/>
        <path className={cn("partialRegion", "28ld", "ling", "dist", charting["t28ld"])} onClick={onClick && (() => onClick('t28ld'))} d="M 222.2 187.2 c -1.6 0.1 -3.4 -0.3 -5 -0.8 c -3.7 -1.1 -4.7 -3.1 -3.1 -6.6 c 0.2 -0.4 0.4 -0.9 0.5 -1.3 c 0 -0.1 0 -0.1 0 -0.2 l -6.4 -0.4 c -0.2 0.8 -0.5 1.6 -0.8 2.4 c -2.8 6.3 -1.1 10 5.6 12 c 3.1 0.9 6.2 1.5 9.2 1.4 l 0.4 -6.6 C 222.4 187.2 222.3 187.2 222.2 187.2"/>
        <path className={cn("partialRegion", "28o", "occ", charting["t28o"])} onClick={onClick && (() => onClick('t28o'))} d="M 217.2 186.4 c 1.7 0.5 3.4 0.8 5 0.8 c 0.1 0 0.2 0 0.3 0 s 0.2 0 0.2 0 c 2 0.1 3.7 -0.1 5.4 -0.8 c 2 -0.8 3 -2.2 3 -4.3 c 0 -0.9 0 -1.8 0 -2.6 c 0 -0.1 0 -0.1 0 -0.2 c 0 -1 0 -2 0.1 -3 c 0.3 -3.1 -1.7 -5 -5.3 -5.1 c -0.9 0 -1.7 0 -2.6 0 c -0.1 0 -0.2 0 -0.3 0 c -1.5 0 -3 0.1 -4.5 0.2 c -2.7 0.2 -4.4 2.4 -4 5.2 c 0.1 0.6 0.1 1.2 -0.1 1.8 c 0 0.1 0 0.1 0 0.2 c -0.1 0.4 -0.3 0.9 -0.5 1.3 C 212.5 183.3 213.5 185.3 217.2 186.4"/>

        <path className={cn("partialRegion", "24ld", "ling", "dist", charting["t24ld"])} onClick={onClick && (() => onClick('t24ld'))} d="M 199.1 74.9 c -0.5 0.3 -1.1 0.5 -1.6 0.8 c -2.4 1.2 -4.8 0.2 -6.1 -1.9 c -0.2 -0.3 -0.4 -0.6 -0.5 -0.9 c -0.1 -0.1 -0.1 -0.3 -0.2 -0.4 l -4.9 2.8 c 0.3 0.6 0.6 1.2 1 1.8 c 2.5 4 7 5.9 11.6 3.7 c 1 -0.5 2.1 -1 3.1 -1.6 L 199.1 74.9 C 199.2 74.9 199.2 74.9 199.1 74.9"/>
        <path className={cn("partialRegion", "24lm", "ling", "mes", charting["t24lm"])} onClick={onClick && (() => onClick('t24lm'))} d="M 185.9 75.3 l 4.9 -2.8 c -0.5 -1.3 -0.8 -2.7 -0.9 -4.1 c -0.1 -1.5 0.9 -2.9 2.3 -3.4 c 0.5 -0.2 0.9 -0.3 1.4 -0.4 h 0.1 l -2.8 -5.1 c -0.9 0.2 -1.8 0.5 -2.7 0.8 c -2.7 0.9 -4.6 3.5 -4.4 6.4 C 184 69.6 184.6 72.6 185.9 75.3"/>
        <path className={cn("partialRegion", "24bd", "buc", "dist", charting["t24bd"])} onClick={onClick && (() => onClick('t24bd'))} d="M 202.1 66.5 c 1.3 1.9 1.3 4.9 -0.1 6.4 c -0.8 0.8 -1.8 1.4 -2.8 2 l 2.3 4.4 c 2 -1.1 3.8 -2.3 5.5 -3.9 c 2.8 -2.8 2.8 -8.5 0.3 -12.3 l -5.4 3.1 C 201.9 66.2 202 66.3 202.1 66.5"/>
        <path className={cn("partialRegion", "24bm", "buc", "mes", charting["t24bm"])} onClick={onClick && (() => onClick('t24bm'))} d="M 196.7 64.2 c 1.1 -0.1 2.2 0.2 3.4 0.6 c 0.7 0.2 1.3 0.7 1.7 1.3 l 5.4 -3.1 c -0.9 -1.4 -2.2 -2.5 -3.8 -3.1 c -2.2 -0.8 -4.4 -1.3 -6.5 -1.2 c -2.1 0 -4.1 0.2 -6 0.7 l 2.8 5.1 C 194.7 64.4 195.7 64.2 196.7 64.2"/>
        <path className={cn("partialRegion", "24o", "occ", charting["t24o"])} onClick={onClick && (() => onClick('t24o'))} d="M 190.8 72.4 c 0.1 0.1 0.1 0.3 0.2 0.4 c 0.2 0.3 0.3 0.6 0.5 0.9 c 1.3 2.1 3.6 3.1 6.1 1.9 c 0.5 -0.3 1.1 -0.5 1.6 -0.8 c 0 0 0.1 0 0.1 -0.1 c 1 -0.5 1.9 -1.2 2.8 -2 c 1.5 -1.4 1.5 -4.4 0.1 -6.4 c -0.1 -0.1 -0.2 -0.2 -0.3 -0.3 c -0.5 -0.6 -1 -1 -1.7 -1.3 c -1.1 -0.4 -2.3 -0.7 -3.4 -0.6 c -1.1 0 -2 0.1 -3 0.3 h -0.1 c -0.5 0.1 -1 0.3 -1.4 0.4 c -1.4 0.5 -2.4 1.8 -2.3 3.4 C 190 69.8 190.3 71.1 190.8 72.4"/>


        <path className={cn("partialRegion", "25bd", "buc", "dist", charting["t25bd"])} onClick={onClick && (() => onClick('t25bd'))} d="M 214.2 89.3 c 1.2 2.4 1.1 5.6 -0.9 7.4 c -1.2 1.1 -2.5 2 -3.9 2.7 l 1.8 4.4 c 2.4 -1.2 4.6 -2.7 6.7 -4.6 c 3.4 -3.1 3.5 -8.6 1.5 -12.6 l -5.6 2.2 C 214 88.9 214.1 89.1 214.2 89.3"/>
        <path className={cn("partialRegion", "25bm", "buc", "mes", charting["t25bm"])} onClick={onClick && (() => onClick('t25bm'))} d="M 201.8 80.9 l 2.1 5.1 c 1 -0.2 2 -0.2 2.9 -0.3 c 1.6 0.1 3 0.3 4.4 0.8 c 1.1 0.4 2 1.2 2.6 2.3 l 5.6 -2.2 c -1.1 -2.2 -2.8 -4 -5 -4.8 c -2.4 -0.8 -4.9 -1.3 -7.6 -1.4 C 205.3 80.5 203.6 80.6 201.8 80.9"/>
        <path className={cn("partialRegion", "25ld", "ling", "dist", charting["t25ld"])} onClick={onClick && (() => onClick('t25ld'))} d="M 209.4 99.4 c -1 0.5 -2 0.9 -3 1.2 c -1.7 0.5 -3.2 -0.1 -4.5 -1.2 c -1.3 -1.2 -2.1 -2.6 -2.6 -4.1 c -0.1 -0.2 -0.1 -0.4 -0.2 -0.6 l -5.1 2 c 0.9 2.6 2.2 5 4.5 7 c 2.2 1.9 4.8 2.9 7.6 2.1 c 1.8 -0.5 3.5 -1.2 5.1 -2 L 209.4 99.4 C 209.5 99.4 209.4 99.4 209.4 99.4"/>
        <path className={cn("partialRegion", "25lm", "ling", "mes", charting["t25lm"])} onClick={onClick && (() => onClick('t25lm'))} d="M 198.5 91.3 c -0.2 -2.5 1 -4.1 3.5 -4.9 c 0.6 -0.2 1.2 -0.3 1.9 -0.4 c 0 0 0 0 0.1 0 l -2.1 -5.1 c -1.1 0.2 -2.1 0.4 -3.2 0.7 c -4.2 1.3 -6.3 4 -5.9 8.3 c 0.2 2.3 0.6 4.6 1.3 6.7 l 5.1 -2 C 198.8 93.6 198.6 92.4 198.5 91.3"/>
        <path className={cn("partialRegion", "25o",  "occ", charting["t25o"])} onClick={onClick && (() => onClick('t25o'))} d="M 198.5 91.3 c 0.1 1.1 0.3 2.3 0.6 3.4 c 0.1 0.2 0.1 0.4 0.2 0.6 c 0.5 1.5 1.3 2.9 2.6 4.1 c 1.3 1.1 2.8 1.7 4.5 1.2 c 1.1 -0.3 2.1 -0.7 3 -1.2 c 0 0 0 0 0.1 0 c 1.4 -0.7 2.7 -1.6 3.9 -2.7 c 2 -1.8 2.1 -5 0.9 -7.4 c -0.1 -0.2 -0.2 -0.3 -0.3 -0.5 c -0.6 -1 -1.5 -1.9 -2.6 -2.3 c -1.4 -0.5 -2.9 -0.8 -4.4 -0.8 c -1 0.1 -1.9 0.2 -2.9 0.3 c 0 0 0 0 -0.1 0 c -0.6 0.1 -1.3 0.2 -1.9 0.4 C 199.5 87.2 198.3 88.7 198.5 91.3"/>

        <path className={cn("partialRegion", "26bd", "buc", "dist", charting["t26bd"])} onClick={onClick && (() => onClick('t26bd'))} d="M 222.6 117 c 0.3 1.1 0.6 2.1 0.8 3.2 c 0.4 2.2 -0.8 4.5 -2.7 5.2 c -1.2 0.5 -2.5 0.9 -3.7 1.3 l 1.3 5 c 2.2 -0.6 4.3 -1.4 6.4 -2.2 c 3.3 -1.2 5.2 -5.1 4.5 -8.8 c -0.3 -1.8 -0.8 -3.6 -1.4 -5.4 l -5.4 1.2 C 222.5 116.7 222.5 116.8 222.6 117"/>
        <path className={cn("partialRegion", "26bm", "buc", "mes", charting["t26bm"])} onClick={onClick && (() => onClick('t26bm'))} d="M 213.1 105.9 c -0.5 0 -1 0 -1.5 0 l 1.4 5.4 c 0.3 0 0.6 0 0.8 0 c 1.4 -0.1 3 0.1 4.5 0.6 s 2.8 1.3 3.4 2.8 c 0.2 0.6 0.4 1.2 0.6 1.7 l 5.4 -1.2 c -0.4 -1.2 -0.8 -2.5 -1.3 -3.7 c -1 -2.6 -3.2 -4 -5.8 -4.7 C 218.2 106.1 215.6 105.7 213.1 105.9"/>
        <path className={cn("partialRegion", "26ld", "ling", "dist", charting["t26ld"])} onClick={onClick && (() => onClick('t26ld'))} d="M 216.9 126.7 c -0.7 0.2 -1.4 0.4 -2.2 0.6 c -3.3 0.8 -5.9 -1.1 -6.4 -4.4 c -0.1 -0.9 -0.3 -1.8 -0.5 -2.6 c 0 -0.2 -0.1 -0.3 -0.1 -0.4 L 203 121 c 0.4 1.4 0.7 2.9 0.9 4.4 c 0.7 5.5 5.2 8.6 10.7 7.4 c 1.2 -0.3 2.4 -0.6 3.7 -1 L 216.9 126.7 C 217 126.7 217 126.7 216.9 126.7"/>
        <path className={cn("partialRegion", "26lm", "ling", "mes", charting["t26lm"])} onClick={onClick && (() => onClick('t26lm'))} d="M 206.7 116.8 c -0.9 -2.4 -0.2 -4 2.3 -4.8 c 1.2 -0.4 2.5 -0.6 3.9 -0.6 c 0 0 0 0 0.1 0 l -1.4 -5.4 c -2.3 0.1 -4.5 0.3 -6.5 1 c -4.2 1.3 -5.5 4 -3.9 8.1 c 0.7 1.9 1.4 3.9 1.9 5.8 l 4.7 -1 C 207.4 118.8 207.1 117.8 206.7 116.8"/>
        <path className={cn("partialRegion", "26o", "occ", charting["t26o"])} onClick={onClick && (() => onClick('t26o'))} d="M 206.7 116.8 c 0.4 1 0.7 2 1 3 c 0 0.2 0.1 0.3 0.1 0.4 c 0.2 0.9 0.4 1.7 0.5 2.6 c 0.4 3.3 3.1 5.1 6.4 4.4 c 0.7 -0.2 1.5 -0.4 2.2 -0.6 c 0 0 0 0 0.1 0 c 1.3 -0.4 2.5 -0.8 3.7 -1.3 c 1.9 -0.7 3.1 -3 2.7 -5.2 c -0.2 -1.1 -0.5 -2.2 -0.8 -3.2 c 0 -0.1 -0.1 -0.3 -0.2 -0.4 c -0.2 -0.6 -0.4 -1.2 -0.6 -1.7 c -0.6 -1.5 -1.9 -2.4 -3.4 -2.8 c -1.5 -0.5 -3.1 -0.7 -4.5 -0.6 c -0.3 0 -0.6 0 -0.8 0 H 213 c -1.4 0 -2.7 0.2 -3.9 0.6 C 206.6 112.8 205.8 114.4 206.7 116.8"/>

        <path className={cn("partialRegion", "27bd", "buc", "dist", charting["t27bd"])} onClick={onClick && (() => onClick('t27bd'))} d="M 229.9 147.9 c 0.2 0.7 0.4 1.4 0.5 2.2 c 0.3 2.2 -0.5 3.9 -2.5 4.8 c -1.7 0.8 -3.5 1.2 -5.3 1.4 c -0.1 0 -0.1 0 -0.2 0 l 1 7 c 3.3 -0.3 6.5 -1.1 9.6 -2.6 c 3.7 -1.6 5.1 -4.7 4.6 -8.7 c -0.2 -1.4 -0.5 -2.7 -0.9 -4 c -0.3 -0.9 -0.6 -1.9 -0.8 -2.9 l -6.5 0.8 c 0 0.1 0 0.2 0.1 0.4 C 229.6 146.9 229.7 147.4 229.9 147.9 z"/>
        <path className={cn("partialRegion", "27bm", "buc", "mes", charting["t27bm"])} onClick={onClick && (() => onClick('t27bm'))} d="M 218.9 132.9 l 1 6.7 c 0.1 0 0.1 0 0.2 0 c 0.9 -0.2 1.8 -0.3 2.5 -0.4 c 4.1 -0.1 6.3 1.9 6.5 5.3 c 0 0.5 0.1 1.1 0.2 1.6 l 6.5 -0.8 c -0.3 -1.1 -0.4 -2.3 -0.5 -3.5 c -0.3 -6.2 -4.3 -9.7 -11.8 -9.6 C 222.2 132.3 220.6 132.6 218.9 132.9"/>
        <path className={cn("partialRegion", "27ld", "ling", "dist", charting["t27ld"])} onClick={onClick && (() => onClick('t27ld'))} d="M 216.5 155.8 c -2.4 -0.6 -3.6 -2.6 -3.2 -5 c 0.2 -0.9 0.2 -1.7 0.2 -2.6 c 0 -0.1 0 -0.2 0 -0.4 l -6.6 0.8 c 0.1 1.5 -0.1 3.1 -0.3 4.7 c -0.7 4.3 1.5 7.9 5.8 9 c 3.7 1 7.4 1.3 11 0.9 l -1 -7 C 220.4 156.5 218.4 156.3 216.5 155.8"/>
        <path className={cn("partialRegion", "27lm", "ling", "mes", charting["t27lm"])} onClick={onClick && (() => onClick('t27lm'))} d="M 212.9 145.6 c -0.8 -2.2 0.5 -4.5 2.7 -5.1 c 1.5 -0.4 2.9 -0.6 4.3 -0.9 l -1 -6.7 c -2.6 0.4 -5.4 0.9 -8.1 1.6 c -4.1 1 -6.4 5.3 -5 9.3 c 0.6 1.7 0.9 3.3 1 5 l 6.6 -0.8 C 213.4 147.2 213.2 146.4 212.9 145.6"/>
        <path className={cn("partialRegion", "27o", "occ", charting["t27o"])} onClick={onClick && (() => onClick('t27o'))} d="M 212.9 145.6 c 0.3 0.8 0.4 1.6 0.5 2.4 c 0 0.1 0 0.2 0 0.4 c 0 0.8 0 1.7 -0.2 2.6 c -0.4 2.4 0.8 4.3 3.2 5 c 2 0.5 3.9 0.7 5.9 0.5 c 0.1 0 0.1 0 0.2 0 c 1.8 -0.2 3.6 -0.6 5.3 -1.4 c 2 -0.9 2.8 -2.6 2.5 -4.8 c -0.1 -0.8 -0.3 -1.5 -0.5 -2.2 c -0.2 -0.5 -0.3 -1 -0.4 -1.6 c 0 -0.1 0 -0.2 -0.1 -0.4 c -0.1 -0.5 -0.2 -1 -0.2 -1.6 c -0.2 -3.4 -2.4 -5.4 -6.5 -5.3 c -0.8 0.1 -1.6 0.3 -2.5 0.4 c -0.1 0 -0.1 0 -0.2 0 c -1.4 0.2 -2.8 0.5 -4.3 0.9 C 213.4 141 212.1 143.4 212.9 145.6"/>

        <path className={cn("partialRegion", "21b", "buc", charting["t21b"])} onClick={onClick && (() => onClick('t21b'))} d="M 159.9 28.9 c -0.5 0.6 -1 1.2 -1.5 1.8 l -24.2 -4.1 c -0.1 -0.8 -0.3 -1.6 -0.4 -2.3 c 0 -3.3 0.2 -4 1.6 -4.7 c 2.5 -1.3 5.4 -1.5 8 -1.5 c 3.5 0 7.1 1.1 10.6 1.8 c 1.8 0.4 3.5 1 5 2.1 C 162.1 24.2 162.3 25.9 159.9 28.9"/>
        <path className={cn("partialRegion", "21l", "ling", charting["t21l"])} onClick={onClick && (() => onClick('t21l'))} d="M 154.6 34.1 c -3 2.2 -6 4.5 -9.8 5.2 c -2.2 0.4 -4.1 0 -5.8 -1.6 c -3.3 -3.3 -4.3 -7.3 -4.9 -11 l 24.1 4.1 C 157.2 31.9 156 33.1 154.6 34.1"/>

        <path className={cn("partialRegion", "22b", "buc", charting["t22b"])} onClick={onClick && (() => onClick('t22b'))} d="M 182 37.7 c -1.3 1.5 -2.7 2.8 -4.1 4 l -17.4 -9.9 c 0.4 -1.4 0.9 -2.7 1.5 -4 c 0.4 -1 1.2 -1.4 2.3 -1.6 c 2.9 -0.4 5.6 0.4 8.2 1.4 c 3.3 1.3 6.5 2.8 8.9 5.4 C 183 34.7 183.3 36.3 182 37.7"/>
        <path className={cn("partialRegion", "22l", "ling", charting["t22l"])} onClick={onClick && (() => onClick('t22l'))} d="M 166.9 46.5 c -4.7 0.4 -6.5 -1.6 -7.2 -5 c -0.2 -0.8 -0.3 -1.7 -0.3 -2.4 c 0.1 -2.6 0.5 -5 1.2 -7.3 l 17.4 9.9 C 174.7 44.3 171.2 46.1 166.9 46.5"/>

        <path className={cn("partialRegion", "23b", "buc", charting["t23b"])} onClick={onClick && (() => onClick('t23b'))} d="M 195.6 49.8 c -0.2 0.6 -0.5 1.1 -0.8 1.6 c -1.3 2.3 -2.9 4.2 -4.8 5.9 l -10.5 -14.4 c 2.9 -0.8 5.9 -1 8.9 -1.3 c 0.6 0 1.2 0 1.8 0 C 195.7 41.9 197.6 44.8 195.6 49.8"/>
        <path className={cn("partialRegion", "23l", "ling", charting["t23l"])} onClick={onClick && (() => onClick('t23l'))} d="M 183.1 62.2 c -2.4 1.3 -4.7 0.9 -6.7 -1 c -2.3 -2.2 -3.7 -4.9 -4.4 -7.9 c -0.9 -3.9 0.5 -6.9 3.9 -8.8 c 1.1 -0.6 2.3 -1.1 3.6 -1.4 L 190 57.5 C 188 59.3 185.7 60.9 183.1 62.2"/>

        <path className={cn("partialRegion", "48bm", "buc", "mes", charting["t48bm"])} onClick={onClick && (() => onClick('t48bm'))} d="M 36.3 238.6 c 1.7 0.1 3.5 0.1 5.2 0 l 0.4 -6.6 c -0.9 0 -1.7 0 -2.6 0 c -3.6 -0.1 -5.6 -2.1 -5.3 -5.1 c 0.1 -1 0.1 -2 0.1 -3 l -7.2 -0.4 c 0 1.9 0 3.9 -0.2 5.8 C 26.1 234.9 29.8 238.4 36.3 238.6"/>
        <path className={cn("partialRegion", "48bd", "buc", "dist", charting["t48bd"])} onClick={onClick && (() => onClick('t48bd'))} d="M 34.2 223.8 c 0 -0.9 0 -1.8 0 -2.6 c 0 -2.1 1 -3.5 3 -4.3 c 1.7 -0.7 3.5 -0.9 5.4 -0.8 c 0.1 0 0.2 0 0.2 0 l 0.4 -6.6 c -0.3 0 -0.6 0 -1 0.1 c -3.6 -0.2 -6.8 0.2 -9.8 1.4 c -3.6 1.4 -5.4 4 -5.4 7.8 c 0 1.6 0 3.2 0 4.8 l 7.2 0.4 C 34.2 223.9 34.2 223.8 34.2 223.8"/>
        <path className={cn("partialRegion", "48lm", "ling", "mes", charting["t48lm"])} onClick={onClick && (() => onClick('t48lm'))} d="M 50.7 226.7 c 0.4 2.8 -1.3 5 -4 5.2 c -1.5 0.1 -3 0.2 -4.5 0.2 c -0.1 0 -0.2 0 -0.3 0 l -0.4 6.6 c 2.7 0 5.4 -0.2 8.1 -0.4 c 4.9 -0.4 8 -4.4 7.3 -9.4 c -0.2 -1.3 -0.1 -2.4 0.1 -3.6 l -6.4 -0.4 C 50.7 225.5 50.6 226.1 50.7 226.7"/>
        <path className={cn("partialRegion", "48ld", "ling", "dist", charting["t48ld"])} onClick={onClick && (() => onClick('t48ld'))} d="M 43.1 216.1 c 1.6 -0.1 3.4 0.3 5 0.8 c 3.7 1.1 4.7 3.1 3.1 6.6 c -0.2 0.4 -0.4 0.9 -0.5 1.3 c 0 0.1 0 0.1 0 0.2 l 6.4 0.4 c 0.2 -0.8 0.5 -1.6 0.8 -2.4 c 2.8 -6.3 1.1 -10 -5.6 -12 c -3.1 -0.9 -6.2 -1.5 -9.2 -1.4 l -0.4 6.6 C 42.9 216.1 43 216.1 43.1 216.1"/>
        <path className={cn("partialRegion", "48o", "occ", charting["t48o"])} onClick={onClick && (() => onClick('t48o'))} d="M 48.1 216.8 c -1.7 -0.5 -3.4 -0.8 -5 -0.8 c -0.1 0 -0.2 0 -0.3 0 c -0.1 0 -0.2 0 -0.2 0 c -2 -0.1 -3.7 0.1 -5.4 0.8 c -2 0.8 -3 2.2 -3 4.3 c 0 0.9 0 1.8 0 2.6 c 0 0.1 0 0.1 0 0.2 c 0 1 0 2 -0.1 3 c -0.3 3.1 1.7 5 5.3 5.1 c 0.9 0 1.7 0 2.6 0 c 0.1 0 0.2 0 0.3 0 c 1.5 0 3 -0.1 4.5 -0.2 c 2.7 -0.2 4.4 -2.4 4 -5.2 c -0.1 -0.6 -0.1 -1.2 0.1 -1.8 c 0 -0.1 0 -0.1 0 -0.2 c 0.1 -0.4 0.3 -0.9 0.5 -1.3 C 52.8 220 51.8 217.9 48.1 216.8"/>

        <path className={cn("partialRegion", "44ld", "ling", "dist", charting["t44ld"])} onClick={onClick && (() => onClick('t44ld'))} d="M 66.2 328.4 c 0.5 -0.3 1.1 -0.5 1.6 -0.8 c 2.4 -1.2 4.8 -0.2 6.1 1.9 c 0.2 0.3 0.4 0.6 0.5 0.9 c 0.1 0.1 0.1 0.3 0.2 0.4 l 4.9 -2.8 c -0.3 -0.6 -0.6 -1.2 -1 -1.8 c -2.5 -4 -7 -5.9 -11.6 -3.7 c -1 0.5 -2.1 1 -3.1 1.6 L 66.2 328.4 C 66.1 328.4 66.2 328.4 66.2 328.4"/>
        <path className={cn("partialRegion", "44lm", "ling", "mes", charting["t44lm"])} onClick={onClick && (() => onClick('t44lm'))} d="M 79.5 328 l -4.9 2.8 c 0.5 1.3 0.8 2.7 0.9 4.1 c 0.1 1.5 -0.9 2.9 -2.3 3.4 c -0.5 0.2 -0.9 0.3 -1.4 0.4 h -0.1 l 2.8 5.1 c 0.9 -0.2 1.8 -0.5 2.7 -0.8 c 2.7 -0.9 4.6 -3.5 4.4 -6.4 C 81.3 333.6 80.7 330.7 79.5 328"/>
        <path className={cn("partialRegion", "44bd", "buc", "dist", charting["t44bd"])} onClick={onClick && (() => onClick('t44bd'))} d="M 63.2 336.8 c -1.3 -1.9 -1.3 -4.9 0.1 -6.4 c 0.8 -0.8 1.8 -1.4 2.8 -2 l -2.3 -4.4 c -2 1.1 -3.8 2.3 -5.5 3.9 c -2.8 2.8 -2.8 8.5 -0.3 12.3 l 5.4 -3.1 C 63.4 337 63.3 336.9 63.2 336.8"/>
        <path className={cn("partialRegion", "44bm", "buc", "mes", charting["t44bm"])} onClick={onClick && (() => onClick('t44bm'))} d="M 68.6 339 c -1.1 0.1 -2.2 -0.2 -3.4 -0.6 c -0.7 -0.2 -1.3 -0.7 -1.7 -1.3 l -5.4 3.1 c 0.9 1.4 2.2 2.5 3.8 3.1 c 2.2 0.8 4.4 1.3 6.5 1.2 c 2.1 0 4.1 -0.2 6 -0.7 l -2.8 -5.1 C 70.6 338.9 69.7 339 68.6 339"/>
        <path className={cn("partialRegion", "44o", "occ", charting["t44o"])} onClick={onClick && (() => onClick('t44o'))} d="M 74.5 330.8 c -0.1 -0.1 -0.1 -0.3 -0.2 -0.4 c -0.2 -0.3 -0.3 -0.6 -0.5 -0.9 c -1.3 -2.1 -3.6 -3.1 -6.1 -1.9 c -0.5 0.3 -1.1 0.5 -1.6 0.8 c 0 0 -0.1 0 -0.1 0.1 c -1 0.5 -1.9 1.2 -2.8 2 c -1.5 1.4 -1.5 4.4 -0.1 6.4 c 0.1 0.1 0.2 0.2 0.3 0.3 c 0.5 0.6 1 1 1.7 1.3 c 1.1 0.4 2.3 0.7 3.4 0.6 c 1.1 0 2 -0.1 3 -0.3 h 0.1 c 0.5 -0.1 1 -0.3 1.4 -0.4 c 1.4 -0.5 2.4 -1.8 2.3 -3.4 C 75.3 333.5 75.1 332.1 74.5 330.8"/>

        <path className={cn("partialRegion", "45bd", "buc", "dist", charting["t45bd"])} onClick={onClick && (() => onClick('t45bd'))} d="M 51.1 314 c -1.2 -2.4 -1.1 -5.6 0.9 -7.4 c 1.2 -1.1 2.5 -2 3.9 -2.7 l -1.8 -4.4 c -2.4 1.2 -4.6 2.7 -6.7 4.6 c -3.4 3.1 -3.5 8.6 -1.5 12.6 l 5.6 -2.2 C 51.3 314.3 51.2 314.2 51.1 314"/>
        <path className={cn("partialRegion", "45bm", "buc", "mes", charting["t45bm"])} onClick={onClick && (() => onClick('t45bm'))} d="M 63.5 322.3 l -2.1 -5.1 c -1 0.2 -2 0.2 -2.9 0.3 c -1.6 -0.1 -3 -0.3 -4.4 -0.8 c -1.1 -0.4 -2 -1.2 -2.6 -2.3 l -5.6 2.2 c 1.1 2.2 2.8 4 5 4.8 c 2.4 0.8 4.9 1.3 7.6 1.4 C 60 322.8 61.8 322.6 63.5 322.3"/>
        <path className={cn("partialRegion", "45ld", "ling", "dist", charting["t45ld"])} onClick={onClick && (() => onClick('t45ld'))} d="M 55.9 303.9 c 1 -0.5 2 -0.9 3 -1.2 c 1.7 -0.5 3.2 0.1 4.5 1.2 c 1.3 1.2 2.1 2.6 2.6 4.1 c 0.1 0.2 0.1 0.4 0.2 0.6 l 5.1 -2 c -0.9 -2.6 -2.2 -5 -4.5 -7 c -2.2 -1.9 -4.8 -2.9 -7.6 -2.1 c -1.8 0.5 -3.5 1.2 -5.1 2 L 55.9 303.9 L 55.9 303.9"/>
        <path className={cn("partialRegion", "45lm", "ling", "mes", charting["t45lm"])} onClick={onClick && (() => onClick('t45lm'))} d="M 66.8 312 c 0.2 2.5 -1 4.1 -3.5 4.9 c -0.6 0.2 -1.2 0.3 -1.9 0.4 c 0 0 0 0 -0.1 0 l 2.1 5.1 c 1.1 -0.2 2.1 -0.4 3.2 -0.7 c 4.2 -1.3 6.3 -4 5.9 -8.3 c -0.2 -2.3 -0.6 -4.6 -1.3 -6.7 l -5.1 2 C 66.5 309.7 66.7 310.8 66.8 312"/>
        <path className={cn("partialRegion", "45o", "occ", charting["t45o"])} onClick={onClick && (() => onClick('t45o'))} d="M 66.8 312 c -0.1 -1.1 -0.3 -2.3 -0.6 -3.4 c -0.1 -0.2 -0.1 -0.4 -0.2 -0.6 c -0.5 -1.5 -1.3 -2.9 -2.6 -4.1 c -1.3 -1.1 -2.8 -1.7 -4.5 -1.2 c -1.1 0.3 -2.1 0.7 -3 1.2 c 0 0 0 0 -0.1 0 c -1.4 0.7 -2.7 1.6 -3.9 2.7 c -2 1.8 -2.1 5 -0.9 7.4 c 0.1 0.2 0.2 0.3 0.3 0.5 c 0.6 1 1.5 1.9 2.6 2.3 c 1.4 0.5 2.9 0.8 4.4 0.8 c 1 -0.1 1.9 -0.2 2.9 -0.3 c 0 0 0 0 0.1 0 c 0.6 -0.1 1.3 -0.2 1.9 -0.4 C 65.8 316.1 67 314.5 66.8 312"/>

        <path className={cn("partialRegion", "46bd", "buc", "dist", charting["t46bd"])} onClick={onClick && (() => onClick('t46bd'))} d="M 42.7 286.3 c -0.3 -1.1 -0.6 -2.1 -0.8 -3.2 c -0.4 -2.2 0.8 -4.5 2.7 -5.2 c 1.2 -0.5 2.5 -0.9 3.7 -1.3 l -1.3 -5 c -2.2 0.6 -4.3 1.4 -6.4 2.2 c -3.3 1.2 -5.2 5.1 -4.5 8.8 c 0.3 1.8 0.8 3.6 1.4 5.4 l 5.4 -1.2 C 42.8 286.6 42.8 286.4 42.7 286.3"/>
        <path className={cn("partialRegion", "46bm", "buc", "mes", charting["t46bm"])} onClick={onClick && (() => onClick('t46bm'))} d="M 52.2 297.3 c 0.5 0 1 0 1.5 0 l -1.4 -5.4 c -0.3 0 -0.6 0 -0.8 0 c -1.4 0.1 -3 -0.1 -4.5 -0.6 s -2.8 -1.3 -3.4 -2.8 c -0.2 -0.6 -0.4 -1.2 -0.6 -1.7 l -5.4 1.2 c 0.4 1.2 0.8 2.5 1.3 3.7 c 1 2.6 3.2 4 5.8 4.7 C 47.2 297.1 49.8 297.5 52.2 297.3"/>
        <path className={cn("partialRegion", "46ld", "ling", "dist", charting["t46ld"])} onClick={onClick && (() => onClick('t46ld'))} d="M 48.4 276.6 c 0.7 -0.2 1.4 -0.4 2.2 -0.6 c 3.3 -0.8 5.9 1.1 6.4 4.4 c 0.1 0.9 0.3 1.8 0.5 2.6 c 0 0.2 0.1 0.3 0.1 0.4 l 4.7 -1.1 c -0.4 -1.4 -0.7 -2.9 -0.9 -4.4 c -0.7 -5.5 -5.2 -8.6 -10.7 -7.4 c -1.2 0.3 -2.4 0.6 -3.7 1 L 48.4 276.6 C 48.3 276.6 48.4 276.6 48.4 276.6"/>
        <path className={cn("partialRegion", "46lm", "ling", "mes", charting["t46lm"])} onClick={onClick && (() => onClick('t46lm'))} d="M 58.6 286.5 c 0.9 2.4 0.2 4 -2.3 4.8 c -1.2 0.4 -2.5 0.6 -3.9 0.6 c 0 0 0 0 -0.1 0 l 1.4 5.4 c 2.3 -0.1 4.5 -0.3 6.5 -1 c 4.2 -1.3 5.5 -4 3.9 -8.1 c -0.7 -1.9 -1.4 -3.9 -1.9 -5.8 l -4.7 1.1 C 57.9 284.4 58.2 285.5 58.6 286.5"/>
        <path className={cn("partialRegion", "46o", "occ", charting["t46o"])} onClick={onClick && (() => onClick('t46o'))} d="M 58.6 286.5 c -0.4 -1 -0.7 -2 -1 -3 c 0 -0.1 -0.1 -0.3 -0.1 -0.4 c -0.2 -0.9 -0.4 -1.7 -0.5 -2.6 c -0.4 -3.3 -3.1 -5.1 -6.4 -4.4 c -0.7 0.2 -1.5 0.4 -2.2 0.6 c 0 0 0 0 -0.1 0 c -1.3 0.4 -2.5 0.8 -3.7 1.3 c -1.9 0.7 -3.1 3 -2.7 5.2 c 0.2 1.1 0.5 2.2 0.8 3.2 c 0 0.1 0.1 0.3 0.2 0.4 c 0.2 0.6 0.4 1.2 0.6 1.7 c 0.6 1.5 1.9 2.4 3.4 2.8 c 1.5 0.5 3.1 0.7 4.5 0.6 c 0.3 0 0.6 0 0.8 0 h 0.1 c 1.4 0 2.7 -0.2 3.9 -0.6 C 58.8 290.5 59.5 288.9 58.6 286.5"/>

        <path className={cn("partialRegion", "47bd", "buc", "dist", charting["t47bd"])} onClick={onClick && (() => onClick('t47bd'))} d="M 35.4 255.3 c -0.2 -0.7 -0.4 -1.4 -0.5 -2.2 c -0.3 -2.2 0.5 -3.9 2.5 -4.8 c 1.7 -0.8 3.5 -1.2 5.3 -1.4 c 0.1 0 0.1 0 0.2 0 l -1 -7 c -3.3 0.3 -6.5 1.1 -9.6 2.6 c -3.7 1.6 -5.1 4.7 -4.6 8.7 c 0.2 1.4 0.5 2.7 0.9 4 c 0.3 0.9 0.6 1.9 0.8 2.9 l 6.5 -0.8 c 0 -0.1 0 -0.2 -0.1 -0.4 C 35.8 256.4 35.6 255.8 35.4 255.3 z"/>
        <path className={cn("partialRegion", "47bm", "buc", "mes", charting["t47bm"])} onClick={onClick && (() => onClick('t47bm'))} d="M 46.4 270.4 l -1 -6.7 c -0.1 0 -0.1 0 -0.2 0 c -0.9 0.2 -1.8 0.3 -2.5 0.4 c -4.1 0.1 -6.3 -1.9 -6.5 -5.3 c 0 -0.5 -0.1 -1.1 -0.2 -1.6 l -6.5 0.8 c 0.3 1.1 0.4 2.3 0.5 3.5 c 0.3 6.2 4.3 9.7 11.8 9.6 C 43.1 270.9 44.7 270.7 46.4 270.4"/>
        <path className={cn("partialRegion", "47ld", "ling", "dist", charting["t47ld"])} onClick={onClick && (() => onClick('t47ld'))} d="M 48.9 247.4 c 2.4 0.6 3.6 2.6 3.2 5 c -0.2 0.9 -0.2 1.7 -0.2 2.6 c 0 0.1 0 0.2 0 0.4 l 6.6 -0.8 c -0.1 -1.5 0.1 -3.1 0.3 -4.7 c 0.7 -4.3 -1.5 -7.9 -5.8 -9 c -3.7 -1 -7.4 -1.3 -11 -0.9 l 1 7 C 44.9 246.7 46.9 246.9 48.9 247.4"/>
        <path className={cn("partialRegion", "47lm", "ling", "mes", charting["t47lm"])} onClick={onClick && (() => onClick('t47lm'))} d="M 52.4 257.7 c 0.8 2.2 -0.5 4.5 -2.7 5.1 c -1.5 0.4 -2.9 0.6 -4.3 0.9 l 1 6.7 c 2.6 -0.4 5.4 -0.9 8.1 -1.6 c 4.1 -1 6.4 -5.3 5 -9.3 c -0.6 -1.7 -0.9 -3.3 -1 -5 l -6.6 0.8 C 52 256.1 52.1 256.9 52.4 257.7"/>
        <path className={cn("partialRegion", "47o", "occ", charting["t47o"])} onClick={onClick && (() => onClick('t47o'))} d="M 52.4 257.7 c -0.3 -0.8 -0.4 -1.6 -0.5 -2.4 c 0 -0.1 0 -0.2 0 -0.4 c 0 -0.8 0 -1.7 0.2 -2.6 c 0.4 -2.4 -0.8 -4.3 -3.2 -5 c -2 -0.5 -3.9 -0.7 -5.9 -0.5 c -0.1 0 -0.1 0 -0.2 0 c -1.8 0.2 -3.6 0.6 -5.3 1.4 c -2 0.9 -2.8 2.6 -2.5 4.8 c 0.1 0.8 0.3 1.5 0.5 2.2 c 0.2 0.5 0.3 1 0.4 1.6 c 0 0.1 0 0.2 0.1 0.4 c 0.1 0.5 0.2 1 0.2 1.6 c 0.2 3.4 2.4 5.4 6.5 5.3 c 0.8 -0.1 1.6 -0.3 2.5 -0.4 c 0.1 0 0.1 0 0.2 0 c 1.4 -0.2 2.8 -0.5 4.3 -0.9 C 51.9 262.2 53.2 259.9 52.4 257.7"/>

        <path className={cn("partialRegion", "41b", "buc", charting["t41b"])} onClick={onClick && (() => onClick('t41b'))} d="M 105.5 374.4 c 0.5 -0.6 1 -1.2 1.5 -1.8 l 24.1 4.1 c 0.1 0.8 0.3 1.6 0.4 2.3 c 0 3.3 -0.2 4 -1.6 4.7 c -2.5 1.3 -5.4 1.5 -8 1.5 c -3.5 0 -7.1 -1.1 -10.6 -1.8 c -1.8 -0.4 -3.5 -1 -5 -2.1 C 103.3 379.1 103 377.3 105.5 374.4"/>
        <path className={cn("partialRegion", "41l", "ling", charting["t41l"])} onClick={onClick && (() => onClick('t41l'))} d="M 110.7 369.2 c 3 -2.2 6 -4.5 9.8 -5.2 c 2.2 -0.4 4.1 0 5.8 1.6 c 3.3 3.3 4.3 7.3 4.9 11 l -24.1 -4.1 C 108.1 371.3 109.3 370.2 110.7 369.2"/>

        <path className={cn("partialRegion", "42b", "buc", charting["t42b"])} onClick={onClick && (() => onClick('t42b'))} d="M 83.3 365.6 c 1.3 -1.5 2.7 -2.8 4.1 -4 l 17.4 9.9 c -0.4 1.4 -0.9 2.7 -1.5 4 c -0.4 1 -1.2 1.4 -2.3 1.6 c -2.9 0.4 -5.6 -0.4 -8.2 -1.4 c -3.3 -1.3 -6.5 -2.8 -8.9 -5.4 C 82.3 368.6 82.1 367 83.3 365.6"/>
        <path className={cn("partialRegion", "42l", "ling",charting["t42l"])} onClick={onClick && (() => onClick('t42l'))} d="M 98.5 356.8 c 4.7 -0.4 6.5 1.6 7.2 5 c 0.2 0.8 0.3 1.7 0.4 2.4 c -0.1 2.6 -0.5 5 -1.2 7.3 l -17.4 -9.9 C 90.6 359 94.1 357.2 98.5 356.8"/>

        <path className={cn("partialRegion", "43b", "buc", charting["t43b"])} onClick={onClick && (() => onClick('t43b'))} d="M 69.7 353.5 c 0.2 -0.6 0.5 -1.1 0.8 -1.6 c 1.3 -2.3 2.9 -4.2 4.8 -5.9 l 10.5 14.4 c -2.9 0.8 -5.9 1 -8.9 1.3 c -0.6 0 -1.2 0 -1.8 0 C 69.7 361.3 67.7 358.4 69.7 353.5"/>
        <path className={cn("partialRegion", "43l", "ling", charting["t43l"])} onClick={onClick && (() => onClick('t43l'))} d="M 82.2 341.1 c 2.4 -1.2 4.7 -0.9 6.7 1 c 2.3 2.2 3.7 4.9 4.4 7.9 c 0.9 3.9 -0.5 6.9 -3.9 8.8 c -1.1 0.6 -2.3 1.1 -3.6 1.4 l -10.5 -14.4 C 77.3 344 79.6 342.4 82.2 341.1"/>

        <path className={cn("partialRegion", "18bm", "buc", "mes", charting["t18bm"])} onClick={onClick && (() => onClick('t18bm'))} d="M 36.3 164.6 c 1.7 -0.1 3.5 -0.1 5.2 0 l 0.4 6.6 c -0.9 0 -1.7 0 -2.6 0 c -3.6 0.1 -5.6 2.1 -5.3 5.1 c 0.1 1 0.1 2 0.1 3 l -7.2 0.4 c 0 -1.9 0 -3.9 -0.2 -5.8 C 26.1 168.3 29.8 164.9 36.3 164.6"/>
        <path className={cn("partialRegion", "18bd", "buc", "dist", charting["t18bd"])} onClick={onClick && (() => onClick('t18bd'))} d="M 34.2 179.5 c 0 0.9 0 1.8 0 2.6 c 0 2.1 1 3.5 3 4.3 c 1.7 0.7 3.5 0.9 5.4 0.8 c 0.1 0 0.2 0 0.2 0 l 0.4 6.6 c -0.3 0 -0.6 0 -1 -0.1 c -3.6 0.2 -6.8 -0.2 -9.8 -1.4 c -3.6 -1.4 -5.4 -4 -5.4 -7.8 c 0 -1.6 0 -3.2 0 -4.8 l 7.2 -0.4 C 34.2 179.3 34.2 179.4 34.2 179.5"/>
        <path className={cn("partialRegion", "18lm", "ling", "mes", charting["t18lm"])} onClick={onClick && (() => onClick('t18lm'))} d="M 50.7 176.5 c 0.4 -2.8 -1.3 -5 -4 -5.2 c -1.5 -0.1 -3 -0.2 -4.5 -0.2 c -0.1 0 -0.2 0 -0.3 0 l -0.4 -6.6 c 2.7 0 5.4 0.2 8.1 0.4 c 4.9 0.4 8 4.4 7.3 9.4 c -0.2 1.3 -0.1 2.4 0.1 3.6 l -6.4 0.4 C 50.7 177.7 50.6 177.2 50.7 176.5"/>
        <path className={cn("partialRegion", "18ld", "ling", "dist", charting["t18ld"])} onClick={onClick && (() => onClick('t18ld'))} d="M 43.1 187.2 c 1.6 0.1 3.4 -0.3 5 -0.8 c 3.7 -1.1 4.7 -3.1 3.1 -6.6 c -0.2 -0.4 -0.4 -0.9 -0.5 -1.3 c 0 -0.1 0 -0.1 0 -0.2 l 6.4 -0.4 c 0.2 0.8 0.5 1.6 0.8 2.4 c 2.8 6.3 1.1 10 -5.6 12 c -3.1 0.9 -6.2 1.5 -9.2 1.4 l -0.4 -6.6 C 42.9 187.2 43 187.2 43.1 187.2"/>
        <path className={cn("partialRegion", "18o", "occ", charting["t18o"])} onClick={onClick && (() => onClick('t18o'))} d="M 48.1 186.4 c -1.7 0.5 -3.4 0.8 -5 0.8 c -0.1 0 -0.2 0 -0.3 0 c -0.1 0 -0.2 0 -0.2 0 c -2 0.1 -3.7 -0.1 -5.4 -0.8 c -2 -0.8 -3 -2.2 -3 -4.3 c 0 -0.9 0 -1.8 0 -2.6 c 0 -0.1 0 -0.1 0 -0.2 c 0 -1 0 -2 -0.1 -3 c -0.3 -3.1 1.7 -5 5.3 -5.1 c 0.9 0 1.7 0 2.6 0 c 0.1 0 0.2 0 0.3 0 c 1.5 0 3 0.1 4.5 0.2 c 2.7 0.2 4.4 2.4 4 5.2 c -0.1 0.6 -0.1 1.2 0.1 1.8 c 0 0.1 0 0.1 0 0.2 c 0.1 0.4 0.3 0.9 0.5 1.3 C 52.8 183.3 51.8 185.3 48.1 186.4"/>

        <path className={cn("partialRegion", "14ld", "ling", "dist", charting["t14ld"])} onClick={onClick && (() => onClick('t14ld'))} d="M 66.2 74.9 c 0.5 0.3 1.1 0.5 1.6 0.8 c 2.4 1.2 4.8 0.2 6.1 -1.9 c 0.2 -0.3 0.4 -0.6 0.5 -0.9 c 0.1 -0.1 0.1 -0.3 0.2 -0.4 l 4.9 2.8 c -0.3 0.6 -0.6 1.2 -1 1.8 c -2.5 4 -7 5.9 -11.6 3.7 c -1 -0.5 -2.1 -1 -3.1 -1.6 L 66.2 74.9 C 66.1 74.9 66.2 74.9 66.2 74.9"/>
        <path className={cn("partialRegion", "14lm", "ling", "mes", charting["t14lm"])} onClick={onClick && (() => onClick('t14lm'))} d="M 79.5 75.3 l -4.9 -2.8 c 0.5 -1.3 0.8 -2.7 0.9 -4.1 c 0.1 -1.5 -0.9 -2.9 -2.3 -3.4 c -0.5 -0.2 -0.9 -0.3 -1.4 -0.4 h -0.1 l 2.8 -5.1 c 0.9 0.2 1.8 0.5 2.7 0.8 c 2.7 0.9 4.6 3.5 4.4 6.4 C 81.3 69.6 80.7 72.6 79.5 75.3"/>
        <path className={cn("partialRegion", "14bd", "buc", "dist", charting["t14bd"])} onClick={onClick && (() => onClick('t14bd'))} d="M 63.2 66.5 c -1.3 1.9 -1.3 4.9 0.1 6.4 c 0.8 0.8 1.8 1.4 2.8 2 l -2.3 4.4 c -2 -1.1 -3.8 -2.3 -5.5 -3.9 c -2.8 -2.8 -2.8 -8.5 -0.3 -12.3 l 5.4 3.1 C 63.4 66.2 63.3 66.3 63.2 66.5"/>
        <path className={cn("partialRegion", "14bm", "buc", "mes", charting["t14bm"])} onClick={onClick && (() => onClick('t14bm'))} d="M 68.6 64.2 c -1.1 -0.1 -2.2 0.2 -3.4 0.6 c -0.7 0.2 -1.3 0.7 -1.7 1.3 L 58.1 63 c 0.9 -1.4 2.2 -2.5 3.8 -3.1 c 2.2 -0.8 4.4 -1.3 6.5 -1.2 c 2.1 0 4.1 0.2 6 0.7 l -2.8 5.1 C 70.6 64.4 69.7 64.2 68.6 64.2"/>
        <path className={cn("partialRegion", "14o", "occ", charting["t14o"])} onClick={onClick && (() => onClick('t14o'))} d="M 74.5 72.4 c -0.1 0.1 -0.1 0.3 -0.2 0.4 c -0.2 0.3 -0.3 0.6 -0.5 0.9 c -1.3 2.1 -3.6 3.1 -6.1 1.9 c -0.5 -0.3 -1.1 -0.5 -1.6 -0.8 c 0 0 -0.1 0 -0.1 -0.1 c -1 -0.5 -1.9 -1.2 -2.8 -2 c -1.5 -1.4 -1.5 -4.4 -0.1 -6.4 c 0.1 -0.1 0.2 -0.2 0.3 -0.3 c 0.5 -0.6 1 -1 1.7 -1.3 c 1.1 -0.4 2.3 -0.7 3.4 -0.6 c 1.1 0 2 0.1 3 0.3 h 0.1 c 0.5 0.1 1 0.3 1.4 0.4 c 1.4 0.5 2.4 1.8 2.3 3.4 C 75.3 69.8 75.1 71.1 74.5 72.4"/>

        <path className={cn("partialRegion", "15bd", "buc", "dist", charting["t15bd"])} onClick={onClick && (() => onClick('t15bd'))} d="M 51.1 89.3 c -1.2 2.4 -1.1 5.6 0.9 7.4 c 1.2 1.1 2.5 2 3.9 2.7 l -1.8 4.4 c -2.4 -1.2 -4.6 -2.7 -6.7 -4.6 c -3.4 -3.1 -3.5 -8.6 -1.5 -12.6 l 5.6 2.2 C 51.3 88.9 51.2 89.1 51.1 89.3"/>
        <path className={cn("partialRegion", "15bm", "buc", "mes", charting["t15bm"])} onClick={onClick && (() => onClick('t15bm'))} d="M 63.5 80.9 L 61.4 86 c -1 -0.2 -2 -0.2 -2.9 -0.3 c -1.6 0.1 -3 0.3 -4.4 0.8 c -1.1 0.4 -2 1.2 -2.6 2.3 l -5.6 -2.2 c 1.1 -2.2 2.8 -4 5 -4.8 c 2.4 -0.8 4.9 -1.3 7.6 -1.4 C 60 80.5 61.8 80.6 63.5 80.9"/>
        <path className={cn("partialRegion", "15ld", "ling", "dist", charting["t15ld"])} onClick={onClick && (() => onClick('t15ld'))} d="M 55.9 99.4 c 1 0.5 2 0.9 3 1.2 c 1.7 0.5 3.2 -0.1 4.5 -1.2 c 1.3 -1.2 2.1 -2.6 2.6 -4.1 c 0.1 -0.2 0.1 -0.4 0.2 -0.6 l 5.1 2 c -0.9 2.6 -2.2 5 -4.5 7 c -2.2 1.9 -4.8 2.9 -7.6 2.1 c -1.8 -0.5 -3.5 -1.2 -5.1 -2 L 55.9 99.4 L 55.9 99.4"/>
        <path className={cn("partialRegion", "15lm", "ling", "mes", charting["t15lm"])} onClick={onClick && (() => onClick('t15lm'))} d="M 66.8 91.3 c 0.2 -2.5 -1 -4.1 -3.5 -4.9 c -0.6 -0.2 -1.2 -0.3 -1.9 -0.4 c 0 0 0 0 -0.1 0 l 2.1 -5.1 c 1.1 0.2 2.1 0.4 3.2 0.7 c 4.2 1.3 6.3 4 5.9 8.3 c -0.2 2.3 -0.6 4.6 -1.3 6.7 l -5.1 -2 C 66.5 93.6 66.7 92.4 66.8 91.3"/>
        <path className={cn("partialRegion", "15o", "occ", charting["t15o"])} onClick={onClick && (() => onClick('t15o'))} d="M 66.8 91.3 c -0.1 1.1 -0.3 2.3 -0.6 3.4 c -0.1 0.2 -0.1 0.4 -0.2 0.6 c -0.5 1.5 -1.3 2.9 -2.6 4.1 c -1.3 1.1 -2.8 1.7 -4.5 1.2 c -1.1 -0.3 -2.1 -0.7 -3 -1.2 c 0 0 0 0 -0.1 0 c -1.4 -0.7 -2.7 -1.6 -3.9 -2.7 c -2 -1.8 -2.1 -5 -0.9 -7.4 c 0.1 -0.2 0.2 -0.3 0.3 -0.5 c 0.6 -1 1.5 -1.9 2.6 -2.3 c 1.4 -0.5 2.9 -0.8 4.4 -0.8 c 1 0.1 1.9 0.2 2.9 0.3 c 0 0 0 0 0.1 0 c 0.6 0.1 1.3 0.2 1.9 0.4 C 65.8 87.2 67 88.7 66.8 91.3"/>

        <path className={cn("partialRegion", "16bd", "buc", "dist", charting["t16bd"])} onClick={onClick && (() => onClick('t16bd'))} d="M 42.7 117 c -0.3 1.1 -0.6 2.1 -0.8 3.2 c -0.4 2.2 0.8 4.5 2.7 5.2 c 1.2 0.5 2.5 0.9 3.7 1.3 l -1.3 5 c -2.2 -0.6 -4.3 -1.4 -6.4 -2.2 c -3.3 -1.2 -5.2 -5.1 -4.5 -8.8 c 0.3 -1.8 0.8 -3.6 1.4 -5.4 l 5.4 1.2 C 42.8 116.7 42.8 116.8 42.7 117"/>
        <path className={cn("partialRegion", "16bm", "buc", "mes", charting["t16bm"])} onClick={onClick && (() => onClick('t16bm'))} d="M 52.2 105.9 c 0.5 0 1 0 1.5 0 l -1.4 5.4 c -0.3 0 -0.6 0 -0.8 0 c -1.4 -0.1 -3 0.1 -4.5 0.6 s -2.8 1.3 -3.4 2.8 c -0.2 0.6 -0.4 1.2 -0.6 1.7 l -5.4 -1.2 c 0.4 -1.2 0.8 -2.5 1.3 -3.7 c 1 -2.6 3.2 -4 5.8 -4.7 C 47.2 106.1 49.8 105.7 52.2 105.9"/>
        <path className={cn("partialRegion", "16ld", "ling", "dist", charting["t16ld"])} onClick={onClick && (() => onClick('t16ld'))} d="M 48.4 126.7 c 0.7 0.2 1.4 0.4 2.2 0.6 c 3.3 0.8 5.9 -1.1 6.4 -4.4 c 0.1 -0.9 0.3 -1.8 0.5 -2.6 c 0 -0.2 0.1 -0.3 0.1 -0.4 l 4.7 1.1 c -0.4 1.4 -0.7 2.9 -0.9 4.4 c -0.7 5.5 -5.2 8.6 -10.7 7.4 c -1.2 -0.3 -2.4 -0.6 -3.7 -1 L 48.4 126.7 C 48.3 126.7 48.4 126.7 48.4 126.7"/>
        <path className={cn("partialRegion", "16lm", "ling", "mes", charting["t16lm"])} onClick={onClick && (() => onClick('t16lm'))} d="M 58.6 116.8 c 0.9 -2.4 0.2 -4 -2.3 -4.8 c -1.2 -0.4 -2.5 -0.6 -3.9 -0.6 c 0 0 0 0 -0.1 0 l 1.4 -5.4 c 2.3 0.1 4.5 0.3 6.5 1 c 4.2 1.3 5.5 4 3.9 8.1 c -0.7 1.9 -1.4 3.9 -1.9 5.8 l -4.7 -1 C 57.9 118.8 58.2 117.8 58.6 116.8"/>
        <path className={cn("partialRegion", "16o", "occ", charting["t16o"])} onClick={onClick && (() => onClick('t16o'))} d="M 58.6 116.8 c -0.4 1 -0.7 2 -1 3 c 0 0.2 -0.1 0.3 -0.1 0.4 c -0.2 0.9 -0.4 1.7 -0.5 2.6 c -0.4 3.3 -3.1 5.1 -6.4 4.4 c -0.7 -0.2 -1.5 -0.4 -2.2 -0.6 c 0 0 0 0 -0.1 0 c -1.3 -0.4 -2.5 -0.8 -3.7 -1.3 c -1.9 -0.7 -3.1 -3 -2.7 -5.2 c 0.2 -1.1 0.5 -2.2 0.8 -3.2 c 0 -0.1 0.1 -0.3 0.2 -0.4 c 0.2 -0.6 0.4 -1.2 0.6 -1.7 c 0.6 -1.5 1.9 -2.4 3.4 -2.8 c 1.5 -0.5 3.1 -0.7 4.5 -0.6 c 0.3 0 0.6 0 0.8 0 h 0.1 c 1.4 0 2.7 0.2 3.9 0.6 C 58.8 112.8 59.5 114.4 58.6 116.8"/>

        <path className={cn("partialRegion", "17bd", "buc", "dist", charting["t17bd"])} onClick={onClick && (() => onClick('t17bd'))} d="M 35.4 147.9 c -0.2 0.7 -0.4 1.4 -0.5 2.2 c -0.3 2.2 0.5 3.9 2.5 4.8 c 1.7 0.8 3.5 1.2 5.3 1.4 c 0.1 0 0.1 0 0.2 0 l -1 7 c -3.3 -0.3 -6.5 -1.1 -9.6 -2.6 c -3.7 -1.6 -5.1 -4.7 -4.6 -8.7 c 0.2 -1.4 0.5 -2.7 0.9 -4 c 0.3 -0.9 0.6 -1.9 0.8 -2.9 l 6.5 0.8 c 0 0.1 0 0.2 -0.1 0.4 C 35.8 146.9 35.6 147.4 35.4 147.9 z"/>
        <path className={cn("partialRegion", "17bm", "buc", "mes", charting["t17bm"])} onClick={onClick && (() => onClick('t17bm'))} d="M 46.4 132.9 l -1 6.7 c -0.1 0 -0.1 0 -0.2 0 c -0.9 -0.2 -1.8 -0.3 -2.5 -0.4 c -4.1 -0.1 -6.3 1.9 -6.5 5.3 c 0 0.5 -0.1 1.1 -0.2 1.6 l -6.5 -0.8 c 0.3 -1.1 0.4 -2.3 0.5 -3.5 c 0.3 -6.2 4.3 -9.7 11.8 -9.6 C 43.1 132.3 44.7 132.6 46.4 132.9"/>
        <path className={cn("partialRegion", "17ld", "ling", "dist", charting["t17ld"])} onClick={onClick && (() => onClick('t17ld'))} d="M 48.9 155.8 c 2.4 -0.6 3.6 -2.6 3.2 -5 c -0.2 -0.9 -0.2 -1.7 -0.2 -2.6 c 0 -0.1 0 -0.2 0 -0.4 l 6.6 0.8 c -0.1 1.5 0.1 3.1 0.3 4.7 c 0.7 4.3 -1.5 7.9 -5.8 9 c -3.7 1 -7.4 1.3 -11 0.9 l 1 -7 C 44.9 156.5 46.9 156.3 48.9 155.8"/>
        <path className={cn("partialRegion", "17lm", "ling", "mes", charting["t17lm"])} onClick={onClick && (() => onClick('t17lm'))} d="M 52.4 145.6 c 0.8 -2.2 -0.5 -4.5 -2.7 -5.1 c -1.5 -0.4 -2.9 -0.6 -4.3 -0.9 l 1 -6.7 c 2.6 0.4 5.4 0.9 8.1 1.6 c 4.1 1 6.4 5.3 5 9.3 c -0.6 1.7 -0.9 3.3 -1 5 l -6.6 -0.8 C 52 147.2 52.1 146.4 52.4 145.6"/>
        <path className={cn("partialRegion", "17o", "occ", charting["t17o"])} onClick={onClick && (() => onClick('t17o'))} d="M 52.4 145.6 c -0.3 0.8 -0.4 1.6 -0.5 2.4 c 0 0.1 0 0.2 0 0.4 c 0 0.8 0 1.7 0.2 2.6 c 0.4 2.4 -0.8 4.3 -3.2 5 c -2 0.5 -3.9 0.7 -5.9 0.5 c -0.1 0 -0.1 0 -0.2 0 c -1.8 -0.2 -3.6 -0.6 -5.3 -1.4 c -2 -0.9 -2.8 -2.6 -2.5 -4.8 c 0.1 -0.8 0.3 -1.5 0.5 -2.2 c 0.2 -0.5 0.3 -1 0.4 -1.6 c 0 -0.1 0 -0.2 0.1 -0.4 c 0.1 -0.5 0.2 -1 0.2 -1.6 c 0.2 -3.4 2.4 -5.4 6.5 -5.3 c 0.8 0.1 1.6 0.3 2.5 0.4 c 0.1 0 0.1 0 0.2 0 c 1.4 0.2 2.8 0.5 4.3 0.9 C 51.9 141 53.2 143.4 52.4 145.6"/>

        <path className={cn("partialRegion", "11b", "buc", charting["t11b"])} onClick={onClick && (() => onClick('t11b'))} d="M 105.5 28.9 c 0.5 0.6 1 1.2 1.5 1.8 l 24.1 -4.1 c 0.1 -0.8 0.3 -1.6 0.4 -2.3 c 0 -3.3 -0.2 -4 -1.6 -4.7 c -2.5 -1.3 -5.4 -1.5 -8 -1.5 c -3.5 0 -7.1 1.1 -10.6 1.8 c -1.8 0.4 -3.5 1 -5 2.1 C 103.3 24.2 103 25.9 105.5 28.9"/>
        <path className={cn("partialRegion", "11l", "ling", charting["t11l"])} onClick={onClick && (() => onClick('t11l'))} d="M 110.7 34.1 c 3 2.2 6 4.5 9.8 5.2 c 2.2 0.4 4.1 0 5.8 -1.6 c 3.3 -3.3 4.3 -7.3 4.9 -11 l -24.1 4.1 C 108.1 31.9 109.3 33.1 110.7 34.1"/>

        <path className={cn("partialRegion", "12b", "buc", charting["t12b"])} onClick={onClick && (() => onClick('t12b'))} d="M 83.3 37.7 c 1.3 1.5 2.7 2.8 4.1 4 l 17.4 -9.9 c -0.4 -1.4 -0.9 -2.7 -1.5 -4 c -0.4 -1 -1.2 -1.4 -2.3 -1.6 c -2.9 -0.4 -5.6 0.4 -8.2 1.4 c -3.3 1.3 -6.5 2.8 -8.9 5.4 C 82.3 34.7 82.1 36.3 83.3 37.7"/>
        <path className={cn("partialRegion", "12l", "ling", charting["t12l"])} onClick={onClick && (() => onClick('t12l'))} d="M 98.5 46.5 c 4.7 0.4 6.5 -1.6 7.2 -5 c 0.2 -0.8 0.3 -1.7 0.4 -2.4 c -0.1 -2.6 -0.5 -5 -1.2 -7.3 l -17.4 9.9 C 90.6 44.3 94.1 46.1 98.5 46.5"/>

        <path className={cn("partialRegion", "13b", "buc", charting["t13b"])} onClick={onClick && (() => onClick('t13b'))} d="M 69.7 49.8 c 0.2 0.6 0.5 1.1 0.8 1.6 c 1.3 2.3 2.9 4.2 4.8 5.9 l 10.5 -14.4 c -2.9 -0.8 -5.9 -1 -8.9 -1.3 c -0.6 0 -1.2 0 -1.8 0 C 69.7 41.9 67.7 44.8 69.7 49.8"/>
        <path className={cn("partialRegion", "13l", "ling", charting["t13l"])} onClick={onClick && (() => onClick('t13l'))} d="M 82.2 62.2 c 2.4 1.3 4.7 0.9 6.7 -1 c 2.3 -2.2 3.7 -4.9 4.4 -7.9 c 0.9 -3.9 -0.5 -6.9 -3.9 -8.8 c -1.1 -0.6 -2.3 -1.1 -3.6 -1.4 L 75.3 57.5 C 77.3 59.3 79.6 60.9 82.2 62.2"/>

        <path className="st3 tongue" d="M 132.7 289.6 c 0 0.3 -0.2 0.5 -0.5 0.5 s -0.5 -0.2 -0.5 -0.5 v -57.2 c 0 -0.3 0.2 -0.5 0.5 -0.5 s 0.5 0.2 0.5 0.5 V 289.6 z M 187.6 242.2 c 1.2 -13.7 -5.6 -24.7 -17.3 -28.1 c -18.3 -5.3 -31.1 2 -35.5 5.1 c -0.8 0.6 -1.7 0.9 -2.7 0.9 s -2 -0.3 -2.8 -0.9 c -6.6 -4.6 -14.5 -7.1 -22.7 -7 c -4.3 0 -8.6 0.6 -12.7 1.9 c -11.7 3.4 -18.4 14.4 -17.3 28 c 0 0.1 0 0.2 0 0.3 c 0 4 -0.4 83.2 32.9 101 c 0.1 0.1 0.2 0.1 0.3 0.2 c 1.1 0.5 9.9 4.9 23.4 4.9 c 14.4 0 20.9 -4.8 21 -4.8 l 0.2 -0.1 c 34.4 -18.1 33.1 -100.3 33.1 -101.1 v -0.3 H 187.6 z"/>

      </svg>
    </StyledPartialScanTeeth>
  )
}
