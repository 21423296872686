import { useState } from 'react';
import { useSelector } from 'react-redux';
import analyzingImage from '../_images/analyzingScanLaptop.png';
import { Box, Flex, Text, Image, Button, 
    Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody,  ModalFooter } from '@chakra-ui/react';
import { ModalHeader } from '../Modals/ModalHeader';
import { FormattedMessage, useIntl } from 'react-intl'; 
import { getIntlMessages } from '../Authentication/_selectors';

import { services as reportServices } from './_services';

export const InspectionReviewSentModal = ({isOpen, onClose}) => {
    const intlMessages = useSelector(getIntlMessages);
    return (
        <Modal isOpen={isOpen} size={'md'} onClose={onClose} isCentered >
            <ModalOverlay />
            <ModalContent bg={'#F7F7F7'} pb={'20px'}>
                <ModalHeader zIndex={0} title={intlMessages['reportGenerative.details.isInspecting.alert.title']} />
                <ModalCloseButton />    
                <ModalBody px={'30px'} py={'25px'}>
                    <Text color={'#4D5666'}>
                        <FormattedMessage 
                            id={'reportGenerative.details.isInspecting.alert.description'}
                            defaultMessage={'This report has been marked for review. We will notify you once the results are ready.'}
                        />
                    </Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export const GenerativeReportInspectionModal = ({ reportId, isOpen, onClose, onSuccess=null }) => {
    const intlMessages = useSelector(getIntlMessages);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isSent, setIsSent ] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true)
        setIsSent(true)
        try {
            const resp = await reportServices.requestInspection(reportId)
            setIsLoading(false)
            setIsSent(true)
            onSuccess && onSuccess()
            onClose()
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const _renderContent = () => {
        return (
            <>
                <ModalBody px={'30px'} py={'25px'}>
                    <Text
                        color={'#273238'}
                        fontSize={'38px'}
                    >
                    </Text>
                    <Text color={'#4D5666'}>
                        <FormattedMessage 
                            id={'reportGenerative.details.inspection.alert.description'}
                            defaultMessage={'Our team will carefully review your scans to address any discrepancies or errors in the report. This process may take additional time, but it ensures accurate results.'}
                        />
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Flex w={'full'}>
                        <Button
                            variant={'outlineDefault'}
                            w={'full'}
                            flexGrow={1}
                            isDisabled={isLoading}
                            onClick={onClose}
                            mr={2}
                        >
                            <FormattedMessage 
                                id={'reportGenerative.details.inspection.alert.cancel'}
                                defaultMessage={'Cancel'}
                            />
                        </Button>
                        <Button
                            w={'full'}
                            flexGrow={1}
                            ml={2}
                            isLoading={isLoading}
                            onClick={onConfirm}
                        >
                            <FormattedMessage 
                                id={'reportGenerative.details.inspection.alert.confirm'}
                                defaultMessage={'Proceed'}
                            />
                        </Button>
                    </Flex>
                </ModalFooter>
            </>
        )
    }

    const _renderSent = () => {
        return (
            <>
                <ModalBody px={'30px'} py={'25px'}>
                    <Text
                        color={'#273238'}
                        fontSize={'38px'}
                    >
                    </Text>
                    <Text color={'#4D5666'}>
                        <FormattedMessage 
                            id={'reportGenerative.details.inspection.confirmed.description'}
                            defaultMessage={'Your report has been marked for review. We will notify you once the results are ready.'}
                        />
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Flex w={'full'}>
                        <Button
                            w={'full'}
                            flexGrow={1}
                            mx={2}
                            onClick={onClose}
                        >
                            <FormattedMessage 
                                id={'reportGenerative.details.inspection.confirmed.button.close'}
                                defaultMessage={'Close'}
                            />
                        </Button>
                    </Flex>
                </ModalFooter>
            </>
        )
    }

    return (
        <Modal isOpen={isOpen} size={'lg'} onClose={onClose} isCentered >
            <ModalOverlay />
            <ModalContent bg={'#F7F7F7'} pb={'20px'}>
                <ModalHeader zIndex={0} title={intlMessages['reportGenerative.details.isInspecting.alert.title']} />
                <ModalCloseButton />    
                {
                    isSent ? _renderSent() : _renderContent()
                }
            </ModalContent>
        </Modal>
    )
}