import { createLogger } from 'redux-logger';
import ReduxThunk from "redux-thunk";
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './rootReducer';
import Pubnub from "pubnub";
import moment from 'moment';
import {addLocaleData, IntlProvider} from 'react-intl';



import localeData from './_locales/data.json';

export const pubnub = new Pubnub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  // TODO: only use cipherkey to encrypt the messages
  //cipherKey: process.env.REACT_APP_PUBNUB_CIPHER_KEY
});
const thunkArgument = {
  pubnub: {
    api: pubnub
  }
};
const thunkMiddleware = ReduxThunk.withExtraArgument(thunkArgument);
const loggerMiddleware = createLogger();

var browserLanguage = "en-us";
const availableLanguages = ['ja', 'ja-jp', 'th', 'th-th', 'en', 'en-us']
var navigatorLanguages = navigator.languages.map(elem => {return elem.toLowerCase()})
if (availableLanguages.indexOf(navigator.language.toLowerCase()) != -1){
  browserLanguage = navigator.language;
} else {
  for (var lang in navigatorLanguages){
    if (availableLanguages.indexOf(navigatorLanguages[lang]) != -1){
      browserLanguage = navigatorLanguages[lang];
      break;
    }
  }
}
var localStorageLanguage = localStorage.getItem('language')
if (availableLanguages.indexOf(localStorageLanguage) != -1){
  browserLanguage = localStorageLanguage
}

// convert browser language into language
if (browserLanguage.includes('-')){
  browserLanguage = browserLanguage.split("-", 1)[0]
}

export const getIntlMessagesWithDefault = (lang) => {
  if (lang == 'ja' || lang == 'th'){
      moment.locale('lang')
      return localeData[lang]
  } else {
      moment.locale('en')
      return localeData['en']
  }
  /*return (lang == 'en')
    ? localeData[browserLanguage]
    : Object.keys(localeData['en']).reduce((curr, key) => {
      return Object.assign(curr, { [key] : localeData[browserLanguage][key] || localeData['en'][key]})
    })*/
}

const initialIntlState = {
  intl: {
    locale: browserLanguage,
    messages: getIntlMessagesWithDefault(browserLanguage)
  }
}

export const store = createStore(
    rootReducer,
    initialIntlState,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ),
);
