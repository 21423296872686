export const isPaymentRequired = (request) => {
    if (request?.payment?.client_secret == "FREE"){
        // free, so no payment required
        return false;
    }
    if (request?.payment?.status == "succeeded" || request?.payment?.status == "successful"){
        return false;
    }
    return true;
}

export const isCompleted = (request) => {
    if (request?.status == "COMPLETE" || !!request?.report?.sent_at){
      return true;
    }
    return false;
}