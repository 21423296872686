export class MediaItem{
  constructor(data, name, type, scanType, extraData){
    this.data = data;
    this.name = name;
    if (type == "video"){
      this.url = URL.createObjectURL(this.data);
      this.mimeType = data.type
    } else { // image
      this.url = URL.createObjectURL(data);
      //this.url = data
      this.mimeType = "image/jpeg";
    }
    this.uploaded = false;
    this.uploadError = false;
    this.progress = 0;
    this.id = new Date().getTime().toString();
    this.uploadId = null;
    this.recordedAt = this.id;
    this.extra_data = extraData;
    this.scanType = scanType;
  }
  getId(){return this.id}
  getData(){return this.data;}
  getProgress(){return this.progress;}
  getExtraData(){return this.extra_data}
  getUrl(){
    if (this.uploaded){
      return this.uploadData.upload;
    }else {
    return this.url;
    }
  }
  getRegion(){return this.extra_data.region}
  getSize(){return this.data.size}
  getMimeType(){return this.mimeType;}
  getName(){return this.name;}
  getScanType(){return this.scanType;}
  getRecordedAt(){return this.recordedAt;}
  isUploaded(){return this.uploaded;}
  getUploadId(){return this.uploadId;}
  setUploaded(uploaded){this.uploaded=uploaded;}
  setUrl(url){this.url=url;}
  setName(name){this.name=name;}
  setData(data){this.data=data;}
  setMimeType(mimeType){this.mimeType=mimeType;}
  setRegion(region){this.extra_data = {...this.extra_data, region}}
}

export const mediaItem = {
  data: null,
  name: "",
  url: "",
  mimeType: "",
  uploaded: false,
  uploadError: false,
  progress: 0,
  id: "",
  uploadId: null,
  recordedAt: "",
  extra_data: {},
  uploadData: {},
  scanType: "",
  getId: function(){return this.id},
  getData: function(){return this.data},
  getProgress: function(){return this.progress},
  getExtraData: function(){return this.extra_data},
  getUrl: function(){
    if (this.uploaded){
      return this.uploadData.upload;
    }else {
    return this.url;
    }
  },
  getRegion: function(){return this.extra_data.region},
  getSize: function(){return this.data.size},
  getMimeType: function(){return this.mimeType;},
  getName: function(){return this.name;},
  getScanType: function(){return this.scanType;},
  getRecordedAt: function(){return this.recordedAt;},
  isUploaded: function(){return this.uploaded;},
  getUploadId: function(){return this.uploadId;},
  setUploaded: function(uploaded){this.uploaded=uploaded;},
  setUrl: function(url){this.url=url;},
  setName: function(name){this.name=name;},
  setData: function(data){this.data=data;},
  setMimeType: function(mimeType){this.mimeType=mimeType;},
  setRegion: function(region){this.extra_data = {...this.extra_data, region}}

}

export const createMediaItem = (data, name, type, scanType, extraData) => {
  const recordedAt = new Date().getTime();
  let url = "";
  let mimeType = "";
  if (type == "video"){
    url = URL.createObjectURL(data);
    mimeType = data.type
  } else {
    url = URL.createObjectURL(data);
    mimeType = "image/jpeg";
  }
  const newData = {data, name, type, scanType, recordedAt, url, mimeType, id: recordedAt.toString(), extra_data: extraData,  }
  return Object.assign({}, mediaItem, newData)

}

export const updateMediaItem = (oldData, newData) => {
  return Object.assign({}, mediaItem, {...oldData, ...newData})
}
