import { constants } from './_constants';

const initState = {
  loadingRequests: false,
  errorRequests: "",
  loadingReport: false,
  errorReport: "",
  requestFilter: null,
  requestList: [],
  record: null,//recordData.record,
  request: {},
  requests: {},
  report: null, //reportData.report,
  currentRequestId: null,
  currentChartCategory: null,
  isMore: false,
  requestDetailsById: {}
}

export function reports(state = initState, action) {
  switch (action.type) {
    case constants.FETCHREQUESTS_REQUEST:
      return {
        ...state,
        loadingRequests: true,
        errorRequests: ""
      }
    case constants.FETCHREQUESTS_SUCCESS:
      return {
        ...state,
        loadingRequests: false,
        errorRequests: "",
        requests: Object.assign({}, state.requests, action.data.requests),
        isMore: action.data.isMore
      }
    case constants.FETCHREQUESTS_FAILURE:
      return {
        ...state,
        loadingRequests: false,
        errorRequests: "err"
      }
    case constants.FETCHLATESTREQUEST_REQUEST:
      return {
        ...state,
        loadingRequests: true,
        errorRequests: ""
      }
    case constants.FETCHLATESTREQUEST_SUCCESS:
      console.log("latestrequest ", action.data)
      return {
        ...state,
        loadingRequests: false,
        errorRequests: "",
        requestDetailsById: Object.assign(
          {},
          state.requestDetailsById, 
          {[action.data.request.id]: action.data.request}
        ),
        currentRequestId: action.data.request.id
      }
    case constants.FETCHLATESTREQUEST_FAILURE:
      return {
        ...state,
        loadingRequests: false,
        errorRequests: "err"
      }
    case constants.FETCHREPORT_REQUEST:
      return {
        ...state,
        loadingReport: true,
        errorReport: ""
      }
    case constants.FETCHREPORT_SUCCESS:
      return {
        ...state,
        loadingReport: false,
        errorReport: "",
        requestDetailsById: Object.assign(
          {},
          state.requestDetailsById, 
          {[action.data.request.id]: action.data.request}
        )
      }
    case constants.FETCHREPORT_FAILURE:
      return {
        ...state,
        loadingReport: false,
        errorReport: "err"
      }
    case constants.ADDTO_REPORTLIST:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.data.id] : action.data
        }
      }
    case constants.UPDATE_PAYMENT:
      return {
        ...state,
        requests: {
          ...state.requests,
          [action.data.id]: {
            ...state.requests[action.data.id],
            payment: action.data.payment
          }
        }
      }
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    case constants.UPDATE_REQUEST_DATA:
      return {
        ...state,
        requestDetailsById: {
          ...state.requestDetailsById,
          [action.data.id]: Object.assign(
            {},
            state.requestDetailsById[action.data.id],
            action.data
          )
        }
      }
    default:
      return state
    }
  }
