import React, { useRef, useEffect} from 'react';
import cn from 'classnames';
import { FULLSCAN_STATES } from '../_config'

export const Tongue = () => {
  return(
    <svg id="tongue" width="80" height="107" viewBox="0 0 80 107" fill="none">
      <path opacity="0.05" fillRule="evenodd" clipRule="evenodd" d="M40.7476 66.2868C40.7476 66.5309 40.531 66.7291 40.2642 66.7291C39.9974 66.7291 39.7809 66.5309 39.7809 66.2868V15.9376C39.7809 15.6935 39.9974 15.4953 40.2642 15.4953C40.531 15.4953 40.7476 15.6935 40.7476 15.9376V66.2868ZM79.3243 23.4606C80.1469 12.7723 75.3655 4.16158 67.0033 1.51949C53.9875 -2.63024 44.8237 3.08828 41.7078 5.51869C41.141 5.96087 40.4609 6.19058 39.7619 6.19058C39.0519 6.19058 38.3696 5.93421 37.7897 5.4936C33.0658 1.90601 27.4161 -0.00931334 21.6232 0.0298868C18.5531 0.0416469 15.5012 0.530865 12.5604 1.48343C4.23303 4.14982 -0.543242 12.7017 0.229914 23.3289C0.236454 23.412 0.239361 23.4967 0.238634 23.5806C0.210295 26.6687 -0.0578377 88.4874 23.6978 102.429C23.7712 102.471 23.8402 102.507 23.9151 102.546C24.6839 102.942 30.9585 106.363 40.6273 106.363C50.8905 106.363 55.528 102.641 55.5832 102.605L55.7278 102.508C80.2363 88.3612 79.3352 24.2054 79.3243 23.5806V23.4606Z" fill="white"/>
    </svg>

  )
}

export const Region1 = ({region, state}) => {
  let currentState = false;
  if (region > 0){
    currentState = FULLSCAN_STATES.FINISHED
  } else if (region == 0){
    currentState = state;
  }
  return (
    <svg id="region1" className={cn('region', currentState)} width="209" height="167" viewBox="0 0 209 167" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className={cn('tooth', currentState)} opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M156.317 124.207C156.137 123.761 155.929 123.322 155.687 122.905C154.631 121.086 153.342 119.509 151.867 118.129L143.41 129.663C145.718 130.334 148.145 130.496 150.56 130.689C151.048 130.689 151.535 130.705 152.021 130.687C156.39 130.521 157.935 128.194 156.317 124.207Z"  fill="white" />
    <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M165.876 103.085C164.566 101.698 163.076 100.669 161.505 99.7617L157.29 108.157L153.074 116.554C154.574 116.941 156.131 117.181 157.829 117.145C159.498 117.26 161.273 116.775 163.028 116.121C164.324 115.639 165.341 114.691 166.089 113.512C168.102 110.34 168.105 105.45 165.876 103.085Z" fill="white"/>
    <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M174.946 83.2725C173.275 81.7171 171.452 80.4516 169.484 79.4609L165.439 89.3567L161.773 98.326C163.179 98.555 164.587 98.66 165.944 98.7944C168.143 98.7007 170.139 98.3156 172.115 97.6164C173.887 96.9893 175.281 95.5025 176.171 93.6774C177.797 90.3352 177.704 85.8383 174.946 83.2725Z" fill="white"/>
    <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M191.688 21.4312C191.516 19.8206 191.5 18.2257 191.511 16.6334C191.52 15.3175 191.548 14.0041 191.526 12.6856C191.474 9.53573 190.033 7.41756 187.075 6.2381C184.558 5.23508 181.925 4.92826 178.992 5.07776C178.732 5.05081 178.469 5.03864 178.207 5.02734L178.886 17.3878L179.535 29.1825C180.964 29.2033 182.393 29.1998 183.817 29.1433C189.163 28.9313 192.182 26.05 191.688 21.4312Z" fill="white"/>
    <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M180.206 58.0592C178.489 57.4028 176.746 56.7932 174.982 56.2617L172.124 67.3301L169.508 77.4682C169.921 77.4786 170.332 77.4907 170.76 77.4829C172.736 77.6607 174.862 77.3043 176.937 76.673C179.035 76.034 180.839 74.9016 181.662 72.7729C182.049 71.774 182.408 70.7621 182.74 69.7424C183.215 68.2813 183.626 66.8003 183.901 65.2873C184.446 62.2871 182.862 59.0746 180.206 58.0592Z" fill="white"/>
    <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M186.886 32.2936C184.282 31.1147 181.623 30.42 178.93 30.1621L176.992 43.7713L175.309 55.5946C176.695 55.8341 177.995 56.0481 179.128 56.2604C185.342 56.3481 188.616 53.4105 188.88 48.2142C188.932 47.2045 189.081 46.2326 189.291 45.2757C189.467 44.4713 189.684 43.6783 189.939 42.8871C190.288 41.8003 190.544 40.7004 190.691 39.5627C191.123 36.2075 189.907 33.6611 186.886 32.2936Z" fill="white"/>
    <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M127.536 141.731C127.119 141.262 126.687 140.807 126.244 140.361L106.028 143.465C105.914 144.075 105.815 144.672 105.715 145.248C105.674 147.753 105.921 148.307 107.043 148.853C109.139 149.873 111.568 150.012 113.752 150.028C116.714 150.049 119.701 149.223 122.646 148.635C124.118 148.342 125.569 147.889 126.826 147.044C129.376 145.328 129.556 144.009 127.536 141.731Z" fill="white"/>
    <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M145.658 133.924C144.581 132.758 143.451 131.662 142.242 130.695L127.945 138.677C128.269 139.781 128.674 140.854 129.173 141.906C129.535 142.67 130.154 143.063 131.072 143.182C133.46 143.492 135.654 142.862 137.816 142.041C140.533 141.008 143.126 139.76 145.169 137.672C146.477 136.336 146.693 135.044 145.658 133.924Z" fill="white"/>
    <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M54.1302 124.207C54.3109 123.761 54.5189 123.322 54.7609 122.905C55.8169 121.086 57.1056 119.509 58.58 118.129L67.0373 129.663C64.7293 130.334 62.302 130.496 59.8875 130.689C59.3999 130.689 58.9124 130.705 58.4266 130.687C54.0578 130.521 52.5126 128.194 54.1302 124.207Z" fill="white"/>
    <path className={cn('tooth', 'startTooth', currentState)}   id="t48" opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M31.455 5.07776C28.5222 4.92826 25.8898 5.23508 23.3723 6.2381C20.4142 7.41756 18.9739 9.5366 18.9216 12.6865C18.8999 14.0041 18.9269 15.3175 18.9364 16.6334C18.9478 18.2257 18.9312 19.8215 18.7597 21.4312C18.2651 26.0508 21.2841 28.9313 26.6299 29.1433C28.0545 29.1998 29.4835 29.2033 30.9125 29.1825L31.5612 17.3878L32.2405 5.02734C31.9775 5.03864 31.7154 5.05081 31.455 5.07776Z" fill="white"/>
    <path className={cn('tooth', currentState)}   opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M53.1584 108.157L48.9434 99.7617C47.3739 100.669 45.8831 101.698 44.5734 103.085C42.3444 105.45 42.347 110.34 44.3601 113.512C45.1085 114.691 46.126 115.639 47.4221 116.121C49.1762 116.775 50.9506 117.26 52.6208 117.145C54.3173 117.181 55.875 116.941 57.3751 116.554L53.1584 108.157Z" fill="white" />
    <path className={cn('tooth', currentState)}   opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M45.0077 89.3567L40.9625 79.4609C38.9958 80.4516 37.1726 81.7171 35.5001 83.2725C32.7433 85.8383 32.6499 90.3352 34.2766 93.6774C35.1652 95.5034 36.5592 96.9893 38.3312 97.6164C40.3075 98.3156 42.3037 98.7007 44.5041 98.7944C45.8608 98.66 47.2686 98.555 48.6738 98.326L45.0077 89.3567Z"  fill="white"/>
    <path className={cn('tooth', currentState)}   opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M38.3241 67.3301L35.4674 56.2617C33.704 56.7932 31.9596 57.4028 30.243 58.0592C27.5866 59.0746 26.0026 62.2871 26.5488 65.2873C26.8236 66.8003 27.2337 68.2813 27.7088 69.7424C28.0409 70.7621 28.3998 71.774 28.7865 72.7729C29.6092 74.9016 31.4134 76.034 33.5115 76.673C35.587 77.3043 37.7137 77.6607 39.6887 77.4829C40.1162 77.4907 40.528 77.4786 40.9415 77.4682L38.3241 67.3301Z" fill="white"/>
    <path className={cn('tooth', currentState)}   opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M33.4588 43.7709L31.5209 30.1621C28.8283 30.42 26.1699 31.1147 23.5658 32.2928C20.5446 33.6604 19.3289 36.2068 19.7601 39.563C19.9065 40.6998 20.1633 41.8007 20.513 42.8867C20.7672 43.6779 20.9846 44.4718 21.1608 45.2753C21.3702 46.2323 21.5201 47.2051 21.5709 48.2139C21.8356 53.4104 25.1094 56.349 31.3228 56.2604C32.4561 56.0481 33.756 55.834 35.1426 55.5946L33.4588 43.7709Z" fill="white"/>
    <path className={cn('tooth', currentState)}   opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M82.8609 141.868C83.2604 141.352 83.6743 140.852 84.0985 140.361L103.473 143.775C103.582 144.446 103.677 145.104 103.772 145.736C103.812 148.493 103.575 149.101 102.5 149.702C100.492 150.824 98.1639 150.977 96.0703 150.994C93.2315 151.018 90.3697 150.109 87.5471 149.463C86.1367 149.14 84.7458 148.641 83.5415 147.712C81.097 145.825 80.925 144.373 82.8609 141.868Z" fill="white"/>
    <path className={cn('tooth', currentState)}   opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M64.7905 133.924C65.868 132.758 66.9979 131.662 68.2064 130.695L82.5035 138.677C82.1796 139.781 81.7744 140.854 81.2759 141.906C80.9135 142.67 80.2953 143.063 79.3767 143.182C76.9886 143.492 74.7952 142.862 72.6324 142.041C69.916 141.008 67.3227 139.76 65.2795 137.672C63.9715 136.336 63.7558 135.044 64.7905 133.924Z" fill="white"/>
    <g filter={ (currentState == FULLSCAN_STATES.FINISHED) ? "url(#filled_edge)" : ""}>
      <path id="edge1" className={cn('edge', currentState)} opacity="0.2"  d="M5.29476 5C2.97043 43.7506 12.476 162 104.036 162C104.242 162 104.442 161.99 104.647 161.988C104.853 161.99 105.053 162 105.259 162C196.819 162 202.05 43.2188 204 5" stroke="white" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <path id="animEdge1" className={cn('animEdge', currentState)} opacity="1"  d="M5.29476 5C2.97043 43.7506 12.476 162 104.036 162C104.242 162 104.442 161.99 104.647 161.988C104.853 161.99 105.053 162 105.259 162C196.819 162 202.05 43.2188 204 5" stroke="white" strokeWidth="0.96" strokeLinecap="round" strokeLinejoin="round"/>

    <defs>
    <filter id="filled_edge" x="0.699219" y="0.699219" width="207.6" height="165.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="2"/>
      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
    </defs>
    </svg>

  )
}

export const Region2 = ({region, state}) => {
  let currentState = false;
  if (region > 1){
    currentState = FULLSCAN_STATES.FINISHED
  } else if (region == 1){
    currentState = state;
  }

  return(
    <svg id="region2" className={cn('region', currentState)} width="149" height="139" viewBox="0 0 149 139" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M115.27 108.891C113.242 107.86 111.275 108.184 109.635 109.732C107.721 111.541 106.497 113.764 105.882 116.252C105.096 119.432 106.275 121.941 109.211 123.535C110.183 124.061 111.19 124.437 112.217 124.728L121.111 112.883C119.393 111.312 117.435 109.991 115.27 108.891Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M141.332 50.5241C136.873 49.4842 133.199 52.0649 132.608 56.6084C132.449 57.8389 132.192 59.034 131.888 60.2151C131.468 61.8478 130.944 63.4483 130.337 65.0342C129.053 68.3922 130.106 70.6029 133.527 71.6991C135.22 72.2425 136.971 72.4808 138.854 72.5284L141.462 62.3972L144.309 51.3352C143.322 51.0371 142.331 50.7572 141.332 50.5241Z" fill="white"/>
      <path className={cn('tooth', 'startTooth', currentState)}  id="t38" opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M147.53 12.4004L146.853 0.0407624C144.403 -0.0661381 141.852 0.478794 139.353 1.20798C133.845 2.81496 132.433 5.90812 134.734 11.1176C135.03 11.7885 135.258 12.4517 135.408 13.1269C135.617 14.0604 135.675 15.0199 135.527 16.0724C134.943 20.2354 137.488 23.5623 141.525 23.8804C143.733 24.0551 145.955 24.162 148.176 24.1942L147.53 12.4004Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M127.307 93.6223C123.491 91.751 119.821 93.3582 117.785 96.6495C117.483 97.137 117.224 97.6383 116.986 98.1457C115.94 100.376 115.468 102.78 115.318 105.242C115.171 107.631 116.752 109.836 118.946 110.555C119.679 110.796 120.419 111.011 121.171 111.195L125.49 103.049L129.809 94.9038C128.992 94.457 128.155 94.0379 127.307 93.6223Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M134.25 72.7548C131.874 72.1106 129.689 72.9009 127.888 74.4366C126.004 76.0453 124.858 77.9812 124.142 80.0837C123.553 81.8088 123.254 83.6462 123.082 85.5073C122.757 89.0163 124.474 91.1544 127.978 92.2337C128.855 92.5048 129.747 92.6901 130.641 92.8295L134.382 84.0422L138.51 74.3465C137.157 73.693 135.74 73.1584 134.25 72.7548Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M139.245 26.0466C135.762 26.9752 133.927 29.8715 134.531 33.4332C134.752 34.738 134.857 36.0006 134.807 37.2555C134.752 38.6078 134.515 39.9507 134.026 41.3306C132.859 44.623 134.711 48.1105 138.047 48.9554C140.285 49.5215 142.556 49.9375 144.624 50.2956L146.276 38.663L148.176 25.2733C145.233 24.9911 142.25 25.2449 139.245 26.0466Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M91.0995 132.068C88.6969 130.212 86.2857 128.373 83.2363 127.76C81.477 127.405 79.937 127.745 78.629 129.105C76.0189 131.818 75.2149 135.195 74.7109 138.262L94.0444 134.898C93.1452 133.87 92.1848 132.906 91.0995 132.068Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M102.274 121.872C98.3409 121.53 96.8976 123.224 96.2682 126.172C96.1144 126.897 96.0526 127.641 95.9766 128.178C96.1021 130.365 96.4185 132.425 96.9709 134.395L111.443 125.923C108.847 123.744 105.887 122.185 102.274 121.872Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M33.1809 108.891C35.2092 107.86 37.1766 108.184 38.8159 109.732C40.7303 111.541 41.9538 113.764 42.5695 116.252C43.3547 119.432 42.176 121.941 39.2398 123.535C38.2682 124.061 37.2608 124.437 36.2337 124.728L27.3398 112.883C29.058 111.312 31.0164 109.991 33.1809 108.891Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M9.09619 1.20861C6.59771 0.479449 4.04632 -0.0654681 1.59642 0.0405603L0.919746 12.4007L0.273438 24.1942C2.49431 24.1612 4.71605 24.0551 6.92477 23.8805C10.9614 23.5624 13.5058 20.2355 12.922 16.0726C12.7745 15.0201 12.8326 14.0598 13.0408 13.1273C13.1918 12.4511 13.4191 11.7889 13.7149 11.1179C16.0165 5.90776 14.6041 2.81555 9.09619 1.20861Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M30.4941 98.1459C30.2565 97.6385 29.998 97.1371 29.6962 96.6487C27.6591 93.3575 23.9898 91.7512 20.173 93.6224C19.3266 94.0372 18.4888 94.4563 17.6719 94.904L21.9899 103.048L26.3096 111.195C27.0624 111.01 27.8022 110.795 28.5341 110.555C30.7283 109.835 32.3084 107.631 32.1627 105.242C32.0118 102.779 31.5409 100.376 30.4941 98.1459Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M25.3693 85.5074C25.1971 83.6462 24.8977 81.8089 24.3096 80.0829C23.5935 77.9813 22.4472 76.0445 20.5628 74.4367C18.7623 72.9002 16.5776 72.1107 14.2013 72.7549C12.7124 73.1585 11.2942 73.6931 9.94141 74.3466L14.0688 84.0414L17.8103 92.8295C18.704 92.6901 19.5959 92.504 20.4736 92.2337C23.9767 91.1544 25.6942 89.0154 25.3693 85.5074Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M18.1123 65.0342C17.5058 63.4483 16.9814 61.8478 16.5615 60.2151C16.2574 59.0348 16.0008 57.8389 15.841 56.6084C15.25 52.0649 11.5774 49.4842 7.11776 50.5241C6.11818 50.7572 5.12724 51.0371 4.14062 51.3352L6.98731 62.3972L9.59554 72.5284C11.4781 72.4808 13.2293 72.2425 14.9226 71.6991C18.3438 70.6029 19.3961 68.3922 18.1123 65.0342Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M14.4232 41.3305C13.9348 39.9514 13.6975 38.6076 13.6433 37.2561C13.5918 36.0004 13.6984 34.7378 13.9194 33.4329C14.523 29.8721 12.6872 26.9758 9.20477 26.0463C6.20043 25.2445 3.21587 24.9908 0.273438 25.2739L2.17458 38.6629L3.82636 50.2956C5.89432 49.9383 8.1652 49.5215 10.4025 48.9554C13.7388 48.1104 15.5901 44.6229 14.4232 41.3305Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M56.3863 132.068C58.789 130.212 61.2001 128.373 64.2496 127.76C66.0088 127.405 67.5489 127.745 68.8569 129.105C71.4669 131.818 72.2709 135.195 72.7749 138.262L53.4414 134.898C54.3406 133.87 55.301 132.906 56.3863 132.068Z" fill="white"/>
      <path className={cn('tooth', currentState)} opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M47.1419 121.872C51.0751 121.53 52.5185 123.224 53.1478 126.172C53.3016 126.897 53.3634 127.641 53.4394 128.178C53.3139 130.365 52.9975 132.425 52.4451 134.395L37.9727 125.923C40.5686 123.744 43.5286 122.185 47.1419 121.872Z" fill="white"/>

      <g filter={(currentState == FULLSCAN_STATES.FINISHED) ? "url(#filled_edge)" : ""}>
        <path id="edge2" className={cn('edge', currentState)} opacity="0.2" d="M21.6947 2.92773C20.4979 31.559 25.3925 118.929 72.5376 118.929C72.6439 118.929 72.7467 118.921 72.8526 118.92C72.9585 118.921 73.0612 118.929 73.1676 118.929C120.313 118.929 123.006 31.166 124.01 2.92773" stroke="white" strokeWidth="0.96"/>
      </g>
      <path id="animEdge2" className={cn('animEdge', currentState)} opacity="1" d="M21.6947 2.92773C20.4979 31.559 25.3925 118.929 72.5376 118.929C72.6439 118.929 72.7467 118.921 72.8526 118.92C72.9585 118.921 73.0612 118.929 73.1676 118.929C120.313 118.929 123.006 31.166 124.01 2.92773" stroke="white" strokeWidth="0.96"/>

    </svg>

  )
}

export const Region3 = ({region, state}) => {
  let currentState = false;
  if (region > 2){
    currentState = FULLSCAN_STATES.FINISHED
  } else if (region == 2){
    currentState = state;
  }
  return(
    <svg id="region3" className={cn('region', currentState)} width="200" height="160" viewBox="0 0 200 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M50.1263 40.1148C50.307 40.5609 50.515 41.0004 50.757 41.417C51.813 43.2363 53.1017 44.8135 54.5761 46.1934L63.0334 34.6595C60.7254 33.9881 58.2981 33.8262 55.8835 33.633C55.396 33.633 54.9085 33.617 54.4227 33.6356C50.0539 33.8009 48.5087 36.1287 50.1263 40.1148Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M174.9 158.893C177.833 159.042 180.466 158.736 182.983 157.733C185.941 156.553 187.382 154.434 187.434 151.284C187.456 149.967 187.429 148.653 187.419 147.337C187.408 145.745 187.424 144.149 187.596 142.54C188.09 137.92 185.071 135.039 179.726 134.827C178.301 134.771 176.872 134.767 175.443 134.788L174.794 146.583L174.115 158.943C174.378 158.932 174.64 158.92 174.9 158.893Z" fill="white"/>
      <path className={cn('tooth', 'startTooth', currentState)}  opacity="0.2" id="t18" fillRule="evenodd" clipRule="evenodd" d="M27.4511 159.245C24.5183 159.394 21.8858 159.087 19.3684 158.084C16.4103 156.905 14.97 154.786 14.9177 151.636C14.896 150.318 14.923 149.005 14.9325 147.689C14.9439 146.097 14.9273 144.501 14.7558 142.891C14.2612 138.271 17.2802 135.391 22.626 135.179C24.0506 135.122 25.4796 135.119 26.9086 135.14L27.5573 146.934L28.2366 159.295C27.9736 159.284 27.7115 159.271 27.4511 159.245Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M49.1545 56.1677L44.9395 64.5625C43.37 63.6548 41.8792 62.6267 40.5695 61.2388C38.3405 58.8746 38.3431 53.9837 40.3562 50.8127C41.1046 49.6337 42.1221 48.685 43.4182 48.203C45.1723 47.5497 46.9467 47.0642 48.6169 47.1793C50.3134 47.1427 51.8711 47.3837 53.3712 47.7702L49.1545 56.1677Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M41.0038 74.9636L36.9586 84.8594C34.9919 83.8688 33.1687 82.6032 31.4962 81.0478C28.7394 78.482 28.646 73.9852 30.2727 70.6429C31.1613 68.817 32.5553 67.331 34.3273 66.7039C36.3036 66.0047 38.2998 65.6196 40.5002 65.5259C41.8569 65.6603 43.2647 65.7653 44.6699 65.9943L41.0038 74.9636Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M33.5233 96.9921L30.6666 108.061C28.9032 107.529 27.1588 106.919 25.4422 106.263C22.7858 105.248 21.2018 102.035 21.748 99.035C22.0228 97.5219 22.4329 96.0409 22.908 94.5799C23.2401 93.5602 23.599 92.5483 23.9857 91.5494C24.8084 89.4207 26.6126 88.2883 28.7107 87.6492C30.7863 87.018 32.913 86.6616 34.8879 86.8394C35.3154 86.8316 35.7272 86.8437 36.1407 86.8541L33.5233 96.9921Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M28.658 120.553L26.7201 134.162C24.0275 133.904 21.3691 133.209 18.7651 132.031C15.7438 130.664 14.5281 128.117 14.9593 124.761C15.1057 123.624 15.3625 122.524 15.7122 121.438C15.9664 120.646 16.1838 119.852 16.36 119.049C16.5695 118.092 16.7193 117.119 16.7702 116.11C17.0349 110.914 20.3086 107.975 26.522 108.064C27.6553 108.276 28.9552 108.49 30.3418 108.73L28.658 120.553Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M78.857 22.452C79.2565 22.9688 79.6704 23.4688 80.0946 23.959L99.4688 20.545C99.5778 19.8743 99.6732 19.2168 99.7686 18.5841C99.8078 15.8276 99.571 15.2188 98.4961 14.618C96.4877 13.4959 94.16 13.3437 92.0664 13.326C89.2276 13.3021 86.3658 14.2109 83.5432 14.8578C82.1328 15.1808 80.7419 15.679 79.5376 16.6081C77.0931 18.4956 76.9211 19.9469 78.857 22.452Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M60.7866 30.3981C61.8641 31.5642 62.994 32.6602 64.2025 33.627L78.4996 25.6453C78.1757 24.5417 77.7705 23.4685 77.2719 22.4165C76.9096 21.6523 76.2914 21.2597 75.3728 21.1406C72.9847 20.8308 70.7913 21.4598 68.6285 22.2814C65.9121 23.314 63.3188 24.562 61.2756 26.65C59.9676 27.9867 59.7519 29.2785 60.7866 30.3981Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M152.221 39.7652C152.041 40.2113 151.833 40.6508 151.591 41.0674C150.535 42.8867 149.246 44.4639 147.772 45.8437L139.314 34.3099C141.622 33.6385 144.05 33.4766 146.464 33.2834C146.952 33.2834 147.439 33.2674 147.925 33.2859C152.294 33.4513 153.839 35.7791 152.221 39.7652Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M153.197 55.8161L157.412 64.2109C158.982 63.3033 160.472 62.2751 161.782 60.8873C164.011 58.523 164.008 53.6322 161.995 50.4611C161.247 49.2821 160.229 48.3334 158.933 47.8515C157.179 47.1981 155.405 46.7126 153.735 46.8278C152.038 46.7912 150.48 47.0321 148.98 47.4186L153.197 55.8161Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M161.344 74.614L165.389 84.5098C167.356 83.5191 169.179 82.2536 170.851 80.6982C173.608 78.1324 173.702 73.6356 172.075 70.2933C171.186 68.4673 169.792 66.9814 168.02 66.3543C166.044 65.6551 164.048 65.27 161.847 65.1763C160.491 65.3107 159.083 65.4157 157.678 65.6447L161.344 74.614Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M168.031 96.6406L170.888 107.709C172.651 107.177 174.396 106.568 176.112 105.912C178.769 104.896 180.353 101.684 179.807 98.6834C179.532 97.1704 179.122 95.6894 178.647 94.2283C178.315 93.2086 177.956 92.1967 177.569 91.1978C176.746 89.0691 174.942 87.9367 172.844 87.2977C170.768 86.6664 168.642 86.31 166.667 86.4878C166.239 86.48 165.828 86.4921 165.414 86.5025L168.031 96.6406Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M172.897 120.202L174.835 133.811C177.527 133.553 180.186 132.858 182.79 131.68C185.811 130.312 187.027 127.766 186.595 124.41C186.449 123.273 186.192 122.172 185.842 121.086C185.588 120.295 185.371 119.501 185.195 118.697C184.985 117.74 184.835 116.768 184.785 115.759C184.52 110.562 181.246 107.624 175.033 107.712C173.899 107.925 172.6 108.139 171.213 108.378L172.897 120.202Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M123.495 22.1024C123.095 22.6192 122.681 23.1191 122.257 23.6094L102.883 20.1954C102.774 19.5247 102.678 18.8672 102.583 18.2345C102.544 15.478 102.781 14.8692 103.855 14.2684C105.864 13.1463 108.192 12.9941 110.285 12.9764C113.124 12.9525 115.986 13.8613 118.808 14.5082C120.219 14.8312 121.61 15.3294 122.814 16.2585C125.258 18.146 125.431 19.5972 123.495 22.1024Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M141.557 30.0485C140.48 31.2146 139.35 32.3106 138.141 33.2773L123.844 25.2957C124.168 24.1921 124.573 23.1189 125.072 22.0668C125.434 21.3027 126.052 20.9101 126.971 20.791C129.359 20.4811 131.552 21.1102 133.715 21.9317C136.432 22.9644 139.025 24.2123 141.068 26.3004C142.376 27.6371 142.592 28.9289 141.557 30.0485Z" fill="white"/>
      <g filter={(currentState == FULLSCAN_STATES.FINISHED) ? "url(#filled_edge)" : ""}>
        <path id="edge3" className={cn('edge', currentState)} opacity="0.2" d="M1.46541 158.328C-0.849207 119.437 8.61666 0.760268 99.7935 0.760268C99.9992 0.760268 100.198 0.770749 100.403 0.772496C100.608 0.770749 100.806 0.760268 101.012 0.760268C192.189 0.760268 197.398 119.971 199.34 158.328" stroke="white" strokeWidth="0.96"/>
      </g>
      <path id="animEdge3" className={cn('animEdge', currentState)} opacity="1" d="M1.46541 158.328C-0.849207 119.437 8.61666 0.760268 99.7935 0.760268C99.9992 0.760268 100.198 0.770749 100.403 0.772496C100.608 0.770749 100.806 0.760268 101.012 0.760268C192.189 0.760268 197.398 119.971 199.34 158.328" stroke="white" strokeWidth="0.96"/>
    </svg>

  )
}

export const Region4 = ({region, state}) => {
  let currentState = false;
  if (region > 3){
    currentState = FULLSCAN_STATES.FINISHED
  } else if (region == 3){
    currentState = state;
  }
  return(
    <svg id="region4" className={cn('region', currentState)} width="149" height="139" viewBox="0 0 149 139" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M115.266 29.4318C113.238 30.4626 111.271 30.1386 109.631 28.5898C107.717 26.7811 106.493 24.5584 105.878 22.0698C105.093 18.8899 106.271 16.3814 109.207 14.7875C110.179 14.2609 111.186 13.8849 112.214 13.5939L121.107 25.4394C119.389 27.0107 117.431 28.3317 115.266 29.4318Z" fill="white"/>
      <path className={cn('tooth', 'startTooth', currentState)}  opacity="0.2"  id="t28" fillRule="evenodd" clipRule="evenodd" d="M147.526 125.922L146.849 138.282C144.399 138.388 141.848 137.843 139.349 137.114C133.841 135.507 132.429 132.414 134.731 127.205C135.026 126.534 135.254 125.871 135.405 125.195C135.613 124.262 135.671 123.302 135.523 122.25C134.94 118.087 137.484 114.76 141.521 114.442C143.729 114.267 145.951 114.16 148.172 114.128L147.526 125.922Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M127.304 44.7C123.487 46.5712 119.817 44.9641 117.781 41.6728C117.479 41.1853 117.22 40.6839 116.982 40.1765C115.936 37.9459 115.464 35.5422 115.314 33.0804C115.168 30.6914 116.748 28.4859 118.942 27.7672C119.675 27.5265 120.415 27.3109 121.167 27.1273L125.487 35.2738L129.805 43.4185C128.988 43.8653 128.151 44.2844 127.304 44.7Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M134.246 65.5695C131.87 66.2136 129.685 65.4233 127.884 63.8876C126 62.2789 124.854 60.343 124.138 58.2405C123.55 56.5154 123.25 54.678 123.078 52.8169C122.753 49.308 124.471 47.1698 127.974 46.0905C128.851 45.8194 129.743 45.6341 130.637 45.4948L134.378 54.282L138.506 63.9777C137.153 64.6312 135.736 65.1658 134.246 65.5695Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M140.359 87.7982C135.9 88.8381 132.227 86.2574 131.636 81.7139C131.476 80.4833 131.219 79.2883 130.915 78.1071C130.495 76.4745 129.971 74.8739 129.364 73.2881C128.081 69.9301 129.133 67.7194 132.554 66.6232C134.247 66.0798 135.999 65.8415 137.881 65.7938L140.489 75.9251L143.336 86.987C142.349 87.2851 141.358 87.5651 140.359 87.7982Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M139.241 112.278C135.758 111.349 133.923 108.453 134.527 104.891C134.748 103.586 134.854 102.324 134.803 101.069C134.748 99.7164 134.511 98.3736 134.022 96.9936C132.855 93.7012 134.707 90.2137 138.043 89.3688C140.281 88.8027 142.552 88.3867 144.62 88.0286L146.272 99.6612L148.172 113.051C145.23 113.333 142.246 113.079 139.241 112.278Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M91.0956 6.25454C88.693 8.11039 86.2818 9.94967 83.2324 10.5628C81.4731 10.9168 79.9331 10.5776 78.6251 9.21709C76.015 6.50396 75.211 3.12715 74.707 0.0599461L94.0405 3.42454C93.1413 4.45276 92.1809 5.41644 91.0956 6.25454Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M102.27 16.4507C98.337 16.7922 96.8936 15.0983 96.2643 12.1505C96.1105 11.4254 96.0487 10.6815 95.9727 10.1438C96.0982 7.95693 96.4146 5.89734 96.967 3.92738L111.439 12.3996C108.844 14.5784 105.883 16.137 102.27 16.4507Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M33.177 29.4318C35.2053 30.4626 37.1727 30.1386 38.812 28.5898C40.7264 26.7811 41.9499 24.5584 42.5656 22.0698C43.3508 18.8899 42.1721 16.3814 39.2359 14.7875C38.2643 14.2609 37.2569 13.8849 36.2298 13.5939L27.3359 25.4394C29.0541 27.0107 31.0125 28.3317 33.177 29.4318Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M9.09229 137.114C6.59381 137.843 4.04241 138.388 1.59251 138.282L0.915839 125.922L0.269531 114.128C2.4904 114.161 4.71214 114.267 6.92087 114.442C10.9575 114.76 13.5019 118.087 12.9181 122.25C12.7706 123.302 12.8287 124.262 13.0369 125.195C13.1879 125.871 13.4152 126.533 13.711 127.204C16.0126 132.415 14.6002 135.507 9.09229 137.114Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M30.4902 40.1764C30.2526 40.6838 29.9941 41.1852 29.6923 41.6735C27.6552 44.9648 23.9858 46.571 20.1691 44.6998C19.3227 44.2851 18.4849 43.866 17.668 43.4183L21.986 35.2746L26.3057 27.1273C27.0585 27.3118 27.7983 27.5274 28.5302 27.7672C30.7243 28.4868 32.3045 30.6914 32.1588 33.0804C32.0079 35.543 31.537 37.9458 30.4902 40.1764Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M25.3654 52.8169C25.1932 54.678 24.8938 56.5153 24.3057 58.2413C23.5896 60.3429 22.4433 62.2797 20.5589 63.8875C18.7583 65.424 16.5737 66.2135 14.1974 65.5694C12.7085 65.1657 11.2903 64.6311 9.9375 63.9776L14.0649 54.2828L17.8064 45.4948C18.7001 45.6341 19.592 45.8203 20.4697 46.0905C23.9728 47.1698 25.6903 49.3088 25.3654 52.8169Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M18.1084 73.2881C17.5019 74.8739 16.9775 76.4745 16.5576 78.1071C16.2535 79.2874 15.9969 80.4833 15.8371 81.7139C15.2461 86.2574 11.5735 88.8381 7.11386 87.7982C6.11428 87.5651 5.12334 87.2851 4.13672 86.987L6.9834 75.9251L9.59164 65.7938C11.4742 65.8415 13.2254 66.0798 14.9187 66.6232C18.3399 67.7194 19.3922 69.9301 18.1084 73.2881Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M14.4193 96.9937C13.9309 98.3728 13.6936 99.7166 13.6394 101.068C13.5879 102.324 13.6945 103.586 13.9155 104.891C14.5191 108.452 12.6833 111.348 9.20086 112.278C6.19652 113.08 3.21196 113.333 0.269531 113.05L2.17067 99.6613L3.82246 88.0286C5.89041 88.3859 8.16129 88.8027 10.3986 89.3689C13.7349 90.2138 15.5862 93.7013 14.4193 96.9937Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M56.3824 6.25454C58.7851 8.11039 61.1962 9.94967 64.2457 10.5628C66.0049 10.9168 67.545 10.5776 68.853 9.21709C71.463 6.50396 72.267 3.12715 72.771 0.0599461L53.4375 3.42454C54.3367 4.45276 55.2971 5.41644 56.3824 6.25454Z" fill="white"/>
      <path className={cn('tooth', currentState)}  opacity="0.2"  fillRule="evenodd" clipRule="evenodd" d="M47.138 16.4507C51.0712 16.7922 52.5145 15.0983 53.1439 12.1505C53.2977 11.4254 53.3595 10.6815 53.4355 10.1438C53.31 7.95693 52.9936 5.89734 52.4412 3.92738L37.9688 12.3996C40.5647 14.5784 43.5247 16.137 47.138 16.4507Z" fill="white"/>
      <g filter={(currentState == FULLSCAN_STATES.FINISHED) ? "url(#filled_edge)" : ""}>
        <path id="edge4" className={cn('edge', currentState)} opacity="0.2" d="M21.6908 137.328C20.494 108.697 25.3886 21.3272 72.5336 21.3272C72.64 21.3272 72.7428 21.335 72.8487 21.3363C72.9546 21.335 73.0573 21.3272 73.1637 21.3272C120.309 21.3272 123.002 109.09 124.007 137.328" stroke="white" strokeWidth="0.96"/>
      </g>
      <path id="animEdge4" className={cn('animEdge', currentState)} opacity="1" d="M21.6908 137.328C20.494 108.697 25.3886 21.3272 72.5336 21.3272C72.64 21.3272 72.7428 21.335 72.8487 21.3363C72.9546 21.335 73.0573 21.3272 73.1637 21.3272C120.309 21.3272 123.002 109.09 124.007 137.328" stroke="white" strokeWidth="0.96"/>

  </svg>

  )
}
