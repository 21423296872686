import React, { useState, useEffect, useRef } from 'react';
import storage from '../_helpers/storage';
import { useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getIntlMessages, getSelectedProfile } from '../Authentication/_selectors';
import { UploadIcon } from '../_images/icons/UploadIcon';
import axios from 'axios'

import { Box, Flex, HStack, Text, Square, Circle, Progress, CloseButton, Button } from '@chakra-ui/react';
import { useUploadScanStore } from './state/uploadScanState';

import { actions as reportActions } from '../Reports/_actions';

import { services as uploaderServices } from '../MediaUploader/_services';
import { services as reportServices } from '../Reports/_services';
import { services as recordServices } from '../Records/_services';
import { RECORD_TYPES } from '../_config';

const ScanRegionTitle = ({region}) => {
    if (region == 0){
        return (
            <FormattedMessage 
                id={'checkup.upload.region.label.0'}
                defaultMessage={'lower outer region'}
            />
        )
    } else if (region == 1){
        
        return (
            <FormattedMessage 
                id={'checkup.upload.region.label.1'}
                defaultMessage={'lower inner region'}
            />
        )
    } else if (region == 2){
        
        return (
            <FormattedMessage 
                id={'checkup.upload.region.label.2'}
                defaultMessage={'upper outer region'}
            />
        )
    } else if (region == 3){
        
        return (
            <FormattedMessage 
                id={'checkup.upload.region.label.3'}
                defaultMessage={'upper inner region'}
            />
        )
    }
    return (<></>)
}

const formatFileSize = (bytes, unit = 'MB', decimalPlaces = 1) => {
    if (!bytes) { return 0}
    if (unit.toUpperCase() === 'KB') {
      return (bytes / 1024);
    } else { // Default to MB if not specified or recognized
      return (bytes / (1024 * 1024));
    }
  };

const formatNumber = (num) => {
    // returns decimal to tenths place if number is less than 10
    // otherwise, returns the number with no decimal places
    if (!num) {
        return "0.0";
    }
    if (num < 10) {
        return num.toFixed(1);
    } else {
        return num.toFixed(0);
    }
}

const UploadPreview = ({selectedFile, innerBoxSize, handleRemove}) => {
    return (
        <Box borderRadius={'10px'} w={innerBoxSize} height={innerBoxSize} pos={'relative'}>
            <video
                style={{
                    objectFit: 'cover',
                    borderRadius: '10px',
                    width: innerBoxSize,
                    height: innerBoxSize,
                    position: 'absolute'
                }}
                src={selectedFile?.previewUrl}
            />   
            { (!selectedFile?.uploadCompleted || selectedFile?.progress > 0) &&
                <Circle 
                    bg={'#273238'} 
                    align={'center '}
                    justify={'center'}
                    size={'26px'} pos={'absolute'} 
                    right={'5px'} top={'5px'}
                    
                    _hover={{
                        cursor: 'pointer',
                    }}
                >
                <CloseButton isDisabled={selectedFile?.isUploading || selectedFile?.uploadCompleted} onClick={handleRemove} color={'#FFF'} size={'sm'} />
            </Circle>
            }
            {(selectedFile?.isUploading || selectedFile?.uploadCompleted) ? (
                <Flex 
                    direction={'column'}
                    px={'10px'}
                    pos={'absolute'} 
                    bottom={'10px'} 
                    w={innerBoxSize}
                    justify={'flex-start'}
                    align={'flex-start'}
                    textAlign={'left'}
                >
                    <Flex
                        position={'relative'}
                        w={['full']}
                        justify={'center'}
                        pb={'10px'}
                    >
                        <Text
                            color={'#FFF'}
                            fontSize={'18px'}
                            textAlign={'center'}
                            mixBlendMode={'screen'}
                        >
                            {`${formatNumber((selectedFile?.progress) * formatFileSize(selectedFile?.data?.size))} / ${formatNumber(formatFileSize(selectedFile?.data?.size))} MB`}
                        </Text>

                    </Flex>
                    <Progress 
                        position={'relative'}
                        w={'full'}
                        value={selectedFile?.progress ? selectedFile?.progress * 100 : 0} 
                        size={'xs'}
                        borderRadius={'5px'}
                        colorScheme={'teal'}
                    />
                </Flex>
            ) : (
                <Flex 
                    direction={'column'}
                    px={'10px'}
                    pos={'absolute'} 
                    bottom={'10px'} 
                    w={innerBoxSize}
                    justify={'flex-start'}
                    align={'flex-start'}
                    textAlign={'left'}
                >
                    <Flex
                        position={'relative'}
                        w={['full']}
                        overflow={'hidden'}
                        justify={'center'}
                        pb={'10px'}
                    >
                        <Text
                            w={['full']}
                            color={'#FFF'}
                            fontSize={'14px'}
                            textAlign={'left'}
                            noOfLines={2}
                            mixBlendMode={'screen'}
                        >
                            {selectedFile?.data?.name}
                        </Text>

                    </Flex>
                </Flex>
            ) }
        </Box>
    )
}

const UploadPlaceholder = ({innerBoxSize, dragOver, handleDragOver, handleDragEnter, handleDragLeave, handleDrop, handleFileInputClick}) => {
    return (
        <Flex
            direction={'column'}
            w={'full'}
            align={'center'}
            justify={'center'}
            height={innerBoxSize}
            width={innerBoxSize}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleFileInputClick}
            _hover={{
                cursor: 'pointer',
            }}
        >
            <UploadIcon color={dragOver ? '#FFF' : '#D3DCE0'} />
            <Text
                mt={'5px'}
                color={dragOver ? '#FFF' : '#009482'}
            >
                <FormattedMessage 
                    id={'checkup.upload.field.text'}
                    defaultMessage={'Upload file'}
                />
            </Text>
        </Flex>
    )
}

const ScanRegion = ({region, selectedFile}) => {
    const removeRegion = useUploadScanStore(state => state.removeRegion);
    const setRegion = useUploadScanStore(state => state.setRegion);

    const fileInputRef = useRef(null);
    const [dragOver, setDragOver] = useState(false);

    const handleFileInputClick = () => {
        // Trigger the file input click event
        fileInputRef.current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        if (!dragOver) setDragOver(true);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false);
        const file = event.dataTransfer.files[0];
        processFile(file);
    };


    const handleRemove = () => {
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      console.log("handleRemove ", region)
      //setPreviewUrl('');
      // Clear the selected file state
      //setSelectedFile(null);
      if (region != null){
          removeRegion(region)
      }
    };

    const handleFileChange = (event) => {
        processFile(event.target.files[0]);
    };

    const processFile = (file) => {
        if (!file) {
            return;
        }
        //if (selectedFile?.previewUrl) {
        //    URL.revokeObjectURL(selectedFile?.previewUrl);
        //}
        setRegion(region, file)
    };

    const boxSize = '150px';
    const innerBoxSize = '140px';

    return (
        <Flex  direction={'column'} align={'center'}>
            <Flex>
                <Text
                    fontSize={'20px'}
                    color={'#7B8887'}
                    textTransform={'capitalize'}
                >
                    <ScanRegionTitle region={region} />
                </Text>
            </Flex>
            <Square 
                mt={'15px'}
                borderRadius={'10px'}
                pos={'relative'}
                size={boxSize}
                align={'center'}
                bg={dragOver ? '#35BE9D' : 'transparent'}
                border={'2px dashed #6B8A99'}
            >
                <Box w={['full']} pos={'absolute'}>
                    <input 
                        type="file" 
                        ref={fileInputRef}
                        hidden 
                        onChange={handleFileChange} 
                        style={{display: 'none'}}
                        accept="video/*" 
                    />
                    { selectedFile ? (
                        <UploadPreview
                            selectedFile={selectedFile}
                            innerBoxSize={innerBoxSize}
                            handleRemove={handleRemove}
                        />
                    ) : (
                        <UploadPlaceholder 
                            innerBoxSize={innerBoxSize}
                            dragOver={dragOver}
                            handleDragOver={handleDragOver}
                            handleDragEnter={handleDragEnter}
                            handleDragLeave={handleDragLeave}
                            handleDrop={handleDrop}
                            handleFileInputClick={handleFileInputClick}
                        />
                    )}
                </Box>

            </Square>
        </Flex>
    )
}

const NextButton = ({handleNextClick, handleUploadClick}) => {
    const uploadState = useUploadScanStore();
    if (uploadState.region0?.uploadCompleted && uploadState.region1?.uploadCompleted && uploadState.region2?.uploadCompleted && uploadState.region3?.uploadCompleted){
        
        return (
            <Button 
                variant={'solid'} 
                w={['full']}
                ml={'10px'}
                onClick={handleNextClick}
            >
                <FormattedMessage 
                    id={'tidbits.next'}
                    defaultMessage={'Next'}
                />
            </Button>
        )
    } else {
        return (
            <Button 
                variant={'solid'} 
                w={['full']}
                ml={'10px'}
                onClick={handleUploadClick}
                isDisabled={
                        !uploadState.region0?.data ||
                        !uploadState.region1?.data ||
                        !uploadState.region2?.data ||
                        !uploadState.region3?.data
                }
                isLoading={uploadState.isUploading}
            >
                <FormattedMessage 
                    id={'checkup.upload.button.label'}
                    defaultMessage={'Upload Files'}
                />
            </Button>
        )
    }
}

export const UploadScans = ({goBack, onSelected, profileId}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isUploading = useUploadScanStore(state => state.isUploading);
    const clearUploads = useUploadScanStore(state => state.clearUploads);
    const setIsUploading = useUploadScanStore(state => state.setIsUploading);  
    const updateRegion = useUploadScanStore(state => state.updateRegion);
    const region0 = useUploadScanStore(state => state.region0);
    const region1 = useUploadScanStore(state => state.region1);
    const region2 = useUploadScanStore(state => state.region2);
    const region3 = useUploadScanStore(state => state.region3);
    const intlMessages = useSelector(getIntlMessages);
    const selectedProfile = useSelector(getSelectedProfile);
    const currentRecordRef = useRef(null);

    const [ isCreated, setIsCreated ] = useState(false);
    const [ isCreating, setIsCreating ] = useState(false);
    const [ isSet , setIsSet ]  = useState(false);
    const [ recordError, setRecordError ] = useState(false);


    useEffect(() => {
        return () => {
            storage.clearScanDetails();
            clearUploads();
        };
      }, []);

    const handleUpload =  async () => {
        if (region0 && region1 && region2 && region3) {
                try {
                    // create record here with API
                    let extraData = null; 
                    const recordResp = await recordServices.createRecordId(RECORD_TYPES.FULLSCAN, selectedProfile.id, extraData);
                    currentRecordRef.current = recordResp.id;
                } catch(err){
                    console.log("UPLOAD ERROR: ", err)
                    setRecordError(true);
                    return;
                }
                let uploadList = [ 
                    region0, region1, region2, region3 
                ].map((selectedFile, index) => {
                    return  uploaderServices.upload(
                                selectedFile?.data, 
                                currentRecordRef.current, 
                                (event) => {
                                    // HACK set final progress to 95 because there is a delay between progress and finished upload
                                    updateRegion(index, {progress: Math.round((event.loaded) / event.total), isUploading: true})
                                }, 
                                selectedFile.extraData
                    )
                })
                axios.all(uploadList)
                    .then( axios.spread((...responses) => {
                        responses.map((resp, index) => {
                            updateRegion(index, {uploadData: resp.data, isUploading: false, uploadCompleted: true})
                        })
                    })).catch(errs => {
                        console.log(errs);
                        // TODO errs lenght or check if object
                        if (errs.length > 0){
                            errs.map((err, index) => {
                                updateRegion(index, {uploadError: err})
                            })
                        } else if (typeof errs == typeof {}){
                            updateRegion(0, {uploadError: JSON.stringify(errs)})
                        } else {
                            updateRegion(0, {uploadError: errs})
                        }
                    })
        }
    }

    const handleClose = () => {
        storage.clearScanDetails();
        clearUploads();
        history.replace("/")
    }

    const handleUploadClick = () => {
        if (isUploading){
            return
        }
        setIsUploading(true)
        handleUpload()
    }

    const handleNextClick = async () => {
        // create the request for user and go to confirm screen
        if (currentRecordRef.current){
            setIsCreating(true)
            reportServices.createRequestOrphanage({"record": currentRecordRef.current})
                .then(
                    data => {
                        storage.clearScanDetails();
                        dispatch(reportActions.fetchRequestList())
                        setIsCreated(true);
                    },
                    error => {
                        // error
                        console.log("error creating request")
                        alert("error creating request")
                        setIsCreating(false)
                    }
                )
        }
    }

    
    // CONFIRMATION if the request has been created, then show the confirmation dialog
    if (isCreated){
        return (
            <Box bg={'#F7F7F7'} >
                <Box pt={'20px'} position={'relative'} height={'360px'} overflow={'auto'}>
                    <Box  w={['full']} maxW={`600px`} px={['40px']} pt={['10px']}>
                        <Text
                            color={'#273238'}
                            fontSize={'40px'}
                        >
                            <FormattedMessage
                                id={'checkup.upload.createrequest.confirmed.title'}
                                defaultMessage={'Request sent!'} 
                            />

                        </Text>
                        <Text
                            color={'#4D5666'}
                            fontSize={'18px'}
                        >
                            <FormattedMessage
                                id={'checkup.upload.createrequest.confirmed.description'}
                                defaultMessage={'Your request has been sent. We will notify you once the results are ready.'} 
                            />

                        </Text>
                    </Box>
                </Box>
                <Flex w={'full'} p={['40px']} bg={'#F7F7F7'}>
                    <Button 
                        variant={'solid'} 
                        w={['full']}
                        onClick={handleClose}
                    >
                        <FormattedMessage 
                            id={'checkup.fullscan.review.close'}
                            defaultMessage={'Close'}
                        />
                    </Button>
                </Flex>
            </Box>
        )
    }
    // CREATE REQUEST if all uploads are completed, show the create request button
    else if (region0?.uploadCompleted && region1?.uploadCompleted && region2?.uploadCompleted && region3?.uploadCompleted){
        return (
            <Box bg={'#F7F7F7'} >
                <Box pt={'20px'} position={'relative'} overflow={'auto'}>
                    <Box  w={['full']} maxW={`600px`} px={['40px']} pt={['10px']}>
                        <Text
                            color={'#273238'}
                            fontSize={'36px'}
                        >
                            <FormattedMessage
                                id={'checkup.upload.createrequest.title'}
                                defaultMessage={'Submit Request?'} 
                            />

                        </Text>
                        <Text
                            color={'#4D5666'}
                            fontSize={'18px'}
                            mt={'15px'}
                        >
                            <FormattedMessage
                                id={'checkup.upload.createrequest.description'}
                                defaultMessage={'Your scans have been uploaded successfully. Do you want to submit your request now?'} 
                            />

                        </Text>
                    </Box>
                </Box>
                <Flex w={'full'} p={['40px']} bg={'#F7F7F7'}>
                    <Button 
                        variant={'outlineDefault'} 
                        w={['full']}
                        onClick={handleClose}
                        mr={'10px'}
                    >
                        {intlMessages['checkup.fullscan.review.close']}
                    </Button>
                    <Button 
                        variant={'solid'} 
                        w={['full']}
                        ml={'10px'}
                        isLoading={isCreating}
                        onClick={handleNextClick}
                    >
                        <FormattedMessage 
                            id={'checkup.checkupform.submit'}
                            defaultMessage={'Submit'}
                        />
                    </Button>
                </Flex>
            </Box>
        )
    } 
    // UPLOAD FILES show the upload files 
    else {
        return (
            <Box bg={'#F7F7F7'} >
                <Box pt={'20px'} position={'relative'} height={'360px'} overflow={'auto'}>
                    { /*<div className={'loading'}><LoadingEllipses /></div>*/}
                    <HStack w={['full']} minW={`650px`} px={['40px']} py={['30px']} spacing={'25px'}>
                    {
                        [region0, region1, region2, region3].map((selectedFile, region) => {
                            return (
                                <ScanRegion region={region} selectedFile={selectedFile} key={region} />
                            )
                        })
                    }
                    </HStack>
                </Box>
                <Flex w={'full'} p={['40px']} bg={'#F7F7F7'}>
                    <Button 
                        variant={'outlineDefault'} 
                        w={['full']}
                        onClick={goBack}
                        isDisabled={isUploading}
                        mr={'10px'}
                    >
                        {intlMessages['clinicSearchPage.clinicDetails.goBack']}
                    </Button>
                    <Button 
                        variant={'solid'} 
                        w={['full']}
                        ml={'10px'}
                        onClick={handleUploadClick}
                        isDisabled={
                                !region0?.data ||
                                !region1?.data ||
                                !region2?.data ||
                                !region3?.data
                        }
                        isLoading={isUploading}
                    >
                        <FormattedMessage 
                            id={'checkup.upload.button.label'}
                            defaultMessage={'Upload Files'}
                        />
                    </Button>
                </Flex>
            </Box>
    )}
}