import { createSelector } from 'reselect';

export const getModalsSlice = (state) => state.modals;

export const getScanningPageModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.scanningPage || false;
  }
)

export const getVideoChatModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.videoChat || false;
  }
)

export const getProfileListModalPage = createSelector(
  [getModalsSlice],
  (state) => {
    return state.profileListPage || 0;
  }
)

export const getProfileListModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.profileList || false;
  }
)

export const getLoadingModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.loading || false;
  }
)

export const getLoadingModalText = createSelector(
  [getModalsSlice],
  (state) => {
    return state.loadingText
  }
)

export const getConfirmDialogModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.confirmDialog || false;
  }
)

export const getConfirmDialogHeader = createSelector(
  [getModalsSlice],
  (state) => {
    return state.confirmDialogHeader
  }
)

export const getConfirmDialogText = createSelector(
  [getModalsSlice],
  (state) => {
    return state.confirmDialogText
  }
)

export const getConfirmDialogNegativeCallback = createSelector(
  [getModalsSlice],
  (state) => {
    return state.confirmDialogNegativeCallback
  }
)

export const getConfirmDialogPositiveCallback = createSelector(
  [getModalsSlice],
  (state) => {
    return state.confirmDialogPositiveCallback
  }
)

export const getConfirmDialogPositiveLabel = createSelector(
  [getModalsSlice],
  (state) => {
    return state.confirmDialogPositiveLabel
  }
)

export const getHowToModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.howTo || false;
  }
)

export const getHowToModalContent = createSelector(
  [getModalsSlice],
  (state) => {
    return state.howToContent || false;
  }
)

export const getMessageModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.message || false;
  }
)

export const getMessageModalText = createSelector(
  [getModalsSlice],
  (state) => {
    return state.messageText;
  }
)

export const getErrorModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.error || false;
  }
)

export const getErrorModalText = createSelector(
  [getModalsSlice],
  (state) => {
    return state.errorText;
  }
)

export const getPartialScanModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.partialScan || false;
  }
)

export const getCardFormModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.cardForm || false;
  }
)

export const getFirebaseNotificationsModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.firebaseNotifications || false;
  }
)

export const getApnNotificationsModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.apnNotifications || false;
  }
)

export const getSafariVersionModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.safariVersion || false;
  }
)

export const getSwitchLanguageModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.switchLanguage || false;
  }
)

export const getUploadDetectionModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.uploadDetection || false;
  }
)

export const getRecordDetailModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.recordDetail || false;
  }
)

export const getDetectionScanModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.detectionScan || false;
  }
)

export const getAttachmentPloverScanModal = createSelector(
  [getModalsSlice],
  (state) => {
    return state.attachmentPloverScan || false;
  }
)
