import { constants } from './_constants';

const initState = {
  loggedIn: false,
  loginLoading: false,
  profileLoading: false,
  chatLoading: false,
  profile: null,
  currentProfile: {},
  profiles: [],
  chat: {},
  payment: {},
  payments: [],
  profileError: false,
  chatError: false
};

export function authentication(state = initState, action) {
  switch (action.type) {
    case constants.LOGOUT_SUCCESS:
      return initState
    case constants.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true
      }
    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loginLoading: false
      }
    case constants.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loginLoading: false
      }
    case constants.USER_PROFILE_REQUEST:
      return {
        ...state,
        profileLoading: true,
      }
    case constants.USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.data,
        //currentProfile: action.data.profile,
        profileLoading: false,
        profileError: false
      }
    case constants.USER_PROFILE_FAILURE:
      return {
        ...state,
        profile: null,
        profileLoading: false,
        profileError: true
      }
    case constants.USER_PROFILES_REQUEST:
      return {
        ...state,
        profilesLoading: true,
      }
    case constants.USER_PROFILES_SUCCESS:
      return {
        ...state,
        ...action.data,
        profilesLoading: false,
        profilesError: false
      }
    case constants.USER_PROFILES_FAILURE:
      return {
        ...state,
        profilesLoading: false,
        profilesError: true
      }

    case constants.USER_PROFILESREMOVE_SUCCESS:
      return {
        ...state,
        profiles: state.profiles.filter(prof => prof.unique_id != action.data.unique_id),
        profilesLoading: false,
        profilesError: false
      }
    case constants.USER_PROFILESREMOVE_FAILURE:
      return {
        ...state,
        profilesLoading: false,
        profilesError: true
      }

    case constants.USER_SETTINGS_PAYMENTS_REQUEST:
      return {
        ...state,
        paymentLoading: true,
      }
    case constants.USER_SETTINGS_PAYMENTS_SUCCESS:
      return {
        ...state,
        ...action.data,
        paymentLoading: false,
        paymentError: false
      }
    case constants.USER_SETTINGS_PAYMENTS_FAILURE:
      return {
        ...state,
        paymentLoading: false,
        paymentError: true
      }
    case constants.USER_PAYMENTS_REQUEST:
      return {
        ...state,
        paymentsLoading: true,
      }
    case constants.USER_PAYMENTS_SUCCESS:
      return {
        ...state,
        ...action.data,
        paymentsLoading: false,
        paymentsError: false
      }
    case constants.USER_PAYMENTS_FAILURE:
      return {
        ...state,
        paymentsLoading: false,
        paymentsError: true
      }
    case constants.USER_CHAT_INITIALIZATION_REQUEST:
      return {
        ...state,
        chatLoading: true
      }
    case constants.USER_CHAT_INITIALIZATION_SUCCESS:
      return {
        ...state,
        chat: action.data,
        chatLoading: false,
        chatError: false
      }
    case constants.USER_CHAT_INITIALIZATION_FAILURE:
      return {
        ...state,
        chat: {},
        chatLoading: false,
        chatError: true
      }
    case constants.ADD_PROFILE:
      return {
        ...state,
        profiles : [
          action.data,
          ...state.profiles
        ],
      }
    case constants.UPDATE_PROFILE:
      let newProfiles = state.profiles.map(prof => {
        if(prof.unique_id == action.data.unique_id){
          return action.data
        }
        return prof
      })
      return {
        ...state,
        profiles : newProfiles,
    }
    case constants.SELECT_PROFILE:
      return {
        ...state,
        currentProfile: action.data
      }
    case constants.UPDATE_OBJECT:
      return {
        ...state,
        [action.data.key] : Object.assign({}, state[action.data.key], action.data.value)
      }
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    default:
      return state
  }
}
