import { get } from 'lodash';
import { API_URLS } from '../_config';
import { constants } from './_constants';
import { services } from './_services';
export const actions = {
  fetchAppSettings,
  setCountry,
  setChannelIOLoaded,
  removeSettingDentist
}

function setChannelIOLoaded(bool){
  return dispatch => {
    dispatch(update(bool))
  }
  function update(bool) { return { type: constants.UPDATE, data: {channelIOLoaded: bool}}}
}

function setCountry(country){
  return dispatch => {
    dispatch(update(country))
  }
  function update(country){ return {type: constants.UPDATE, data: {currentCountry: country}}}
}

function removeFavoriteDentist(favorite_id, data){
  return (dispatch) => {
    services.deleteFavoriteDentist(favorite_id, data)
      .then(
        resp => {
          dispatch(success({favorite_id}))
        }
      )
  }
  function success(data) { return { type: constants.REMOVE_FAVORITE_DENTIST,  data: {favoriteId: data.favorite_id}}}
}

function removeSettingDentist(favorite_id){
  return (dispatch) => {
    dispatch( { type: constants.REMOVE_FAVORITE_DENTIST,  data: {favoriteId: favorite_id}})
  }
}

function fetchAppSettings(setting, data){
  return (dispatch, getState) => {
    dispatch(request(setting))

    if (setting == "countries"){
      services.fetchCountries()
        .then(
          resp => {

            dispatch(success(setting, resp.country_list))
          },
          error => {
            dispatch(failure(setting, error))
          }
        )
    } else if (setting == "regions"){

      services.fetchCountryRegions(data)
        .then(
          resp => {
            let currRegions = getState().appSettings.regions;
            currRegions = Object.assign({}, currRegions, {[data] : resp.region_list});
            dispatch(success(setting, currRegions))
          },
          error => {
            dispatch(failure(setting, error))
          }
        )
    } else if (setting == "relationships"){

      services.fetchRelationships()
        .then(
          resp => {
            dispatch(success(setting,  resp.relationships))
          },
          error => {
            dispatch(failure(setting, error))
          }
        )
    } else if (setting == "favoriteDentist"){

      services.fetchFavoriteDentist()
        .then(
          resp => {
            dispatch(success(setting,  resp.favorite_dentists))
          },
          error => {
            dispatch(failure(setting, error))
          }
        )
    }

  }
  function request(setting) { return { type: constants.FETCHSETTING_REQUEST,  data: {setting}}}
  function success(setting, data) { return { type: constants.FETCHSETTING_SUCCESS,  data: {setting, data}}}
  function failure(setting, error) { return { type: constants.FETCHSETTING_FAILURE, data: {setting, error}}}
}
