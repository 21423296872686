import { Icon } from '@chakra-ui/react';
// 9B9B9B
const VerticalDotsIcon = (props) => {
  return (
    <Icon width="3" height="14" viewBox="0 0 3 14" color={'#405159'} {...props}>
    <path d="M3 1.49952C3.00006 1.6965 2.96133 1.89157 2.886 2.07358C2.81068 2.25559 2.70024 2.42099 2.561 2.56032C2.42176 2.69965 2.25643 2.81019 2.07447 2.88563C1.89251 2.96108 1.69746 2.99994 1.50048 3C1.3035 3.00006 1.10843 2.96133 0.92642 2.886C0.744407 2.81068 0.579013 2.70024 0.43968 2.561C0.300348 2.42176 0.189806 2.25643 0.114366 2.07447C0.0389251 1.89251 6.33508e-05 1.69746 7.7495e-08 1.50048C-0.000127709 1.10266 0.157785 0.721075 0.438999 0.439681C0.720214 0.158286 1.10169 0.000127872 1.49952 7.75053e-08C1.89734 -0.000127717 2.27892 0.157785 2.56032 0.438999C2.84171 0.720213 2.99987 1.10169 3 1.49952Z" 
        fill="currentColor"/>
    <path d="M1.49952 8.14747C2.32768 8.14747 2.99904 7.47612 2.99904 6.64796C2.99904 5.8198 2.32768 5.14844 1.49952 5.14844C0.671357 5.14844 0 5.8198 0 6.64796C0 7.47612 0.671357 8.14747 1.49952 8.14747Z" 
        fill="currentColor"/>
    <path d="M1.49952 13.2959C2.32768 13.2959 2.99904 12.6246 2.99904 11.7964C2.99904 10.9682 2.32768 10.2969 1.49952 10.2969C0.671357 10.2969 0 10.9682 0 11.7964C0 12.6246 0.671357 13.2959 1.49952 13.2959Z" 
        fill="currentColor"/>
    </Icon>
  );
};

export default VerticalDotsIcon;