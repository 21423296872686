import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const CircleScore = ({value}) => {
    return (
        <CircularProgressbar 
            value={value} 
            text={`${value}%`} 
            background={false}    
        />
    )
}