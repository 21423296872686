import React from 'react';
export const VideoIcon = () =>{
  return(
    <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
      <path d="M4 4.72266H8.52769" stroke="#ffffff" stroke-width="1.4" stroke-linejoin="bevel"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.7 0L0 0.7V16.352L0.7 17.052H15.534L16.234 16.352V14.324L20.559 16.933L21.621 16.332V0.718L20.559 0.119L16.234 2.727V0.7L15.534 0H0.7ZM1.4 1.4H14.834V3.571V5.207L16.234 4.361L20.22 1.958V15.093L16.234 12.689L14.834 11.845V13.479V15.652H1.4V1.4Z" fill="#ffffff"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="22" height="17" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )}
