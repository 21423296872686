import { createSelector } from 'reselect'

export const getFullScanChartState = (state) => state.fullScanChart;

export const getCurrentRegion = createSelector(
  [getFullScanChartState],
  (state) => {
    return state.currentRegion;
  }
)

export const getCurrentState = createSelector(
  [getFullScanChartState],
  (state) => {
    return state.currentState;
  }
)
