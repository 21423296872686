import { constants } from './_constants';
const initState = {
  ploverDeviceId: null,
  awaitingDevice: false,
  deviceError: false
}

export function ploverCamera(state=initState, action){
  switch (action.type) {
    case constants.SET_PLOVERDEVICEID:
      return {
        ...state,
        ploverDeviceId: action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state
  }
}
