import styled from 'styled-components';

import { StyledContent, StyledContentTitle, StyledContentSection } from '../_styles/common';
import Scanner from '../_images/scanner.png';

export const StyledIsMore = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
  align-items:center;
  padding: 20px 0;
  font-size: 90%;
  &:hover{
    cursor: pointer;
  }
  > div{

  }
`;

export const StyledReportChartCategoryItem = styled.li`
  border: 0.5px solid #6B8A99;
  background-color: 'transparent';
  border-radius: 60px;
  color: ##6B8A99;
  text-transform: capitalize;
`;

export const StyledToothChartInformationCategories = styled.ul`
  margin-top:20px;
  display:flex;
  flex-wrap: wrap;
  li {
    position:relative;border-radius:60px;padding:8px 20px;font-size:16px;line-height:24px;color:#6B8A99;
    margin-bottom: 5px;
  }
  li::before {content:'';display:inline-block;width:10px;height:10px;border-radius:10px;vertical-align:middle;margin-left:0px;margin-right:12px;}
  li.COLOR::before {background-color:#367FFF}
  li.ORTHO::before {background-color:#00574F}
  li.GUMS::before {background-color:#daff00}
  li.HABITS::before {background-color:#daff00}
  li.CARIES::before {background-color:#DC4E41}
  li.CALCULUS::before {background-color:#ffa500}
  li.ALL::before {background-color:#000000}
  li:not(:last-child) {margin-right:12px;}
`;

export const StyledToothChartInformation = styled.div`
  display: flex;
  flex-direction: column;
  float:left;
  margin-top:20px;
  margin-left:26px;
  .date {font-size:15px;line-height:21px;letter-spacing:1px;color:#121719;}
  .date strong {margin-top:5px;font-size:15px;font-family:Proxima Nova Semibold;line-height:21px;letter-spacing:1px;font-family:Proxima Nova Bold;}
  .score {font-size:15px;line-height:25px;margin-top:9px;font-family:'Proxima Nova Thin'}
  .score strong {font-size:36px;line-height:36px;font-family:Proxima Nova;}
`;
export const StyledToothChart = styled.div`
  position:relative;
  float:left;
  margin-top:0px;
  * {
    &:hover{cursor:default !important;}
  }
  
`;


export const StyledCheckupToothChartContainer = styled.div`
  position:relative;
  .loading{
    z-index:2;
    position:absolute;
    top: 0;
    left: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0, 0.3);
  }
  margin-top:8px;background-color:#efefef;border-radius:10px;width:430px;height:370px;overflow:hidden;
`;

export const StyledNoReports = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color:#EFEFEF;
  p{
    font-family: Proxima Nova;
    font-size: 16px;
    text-align: center;
    color: #979797;
    margin-top: 10px;
  }
`;

export const StyledCheckupRequestHistoryItem = styled.li`
  display:flex;align-items:center;
  display:grid;
  grid-template-columns: 1fr 1fr 9fr 6fr 6fr;
  height:72px;width:100%;padding: 0 10px;
  ${props => props.isBlank ?
     '' :
    '&:hover {background-color:#ebf2f5;border:1px solid #376fff;border-radius:3px;cursor: pointer;}'
  }

  .camera{
    background:none;border:none;width:30px;height:30px;padding:0;margin:auto;flex-grow:1;
    img{width:30px;height:30px;}
  }

  &:before {
    content:'';display:inline-block;width:9px;height:9px;border-radius:9px;
    background-color:${props => props.isRead || props.isBlank ? '#d8d8d8' : '#367fff' };
  }
  &:not(:last-child) {border-bottom: 1px solid #ebf2f5;}
  ${props => props.isBlank ? 
    '': 
    '&:not(:last-child):hover {border-bottom:1px solid #376fff;}'
  }
  
  > button {background:none;border:none;margin:1 auto; &:hover{cursor:pointer;}}
  .info {margin-left:20px;}
  .info .blank{font-size:14px;font-family:Proxima Nova Semibold;line-height:17px;color:#979797;}
  .info .name {font-size:14px;font-family:Proxima Nova Semibold;line-height:17px;color:#000000;}
  .info .date {font-size:12px;line-height:15px;color:#4d5666;}
  .info .date span {margin-left:10px;font-size:12px;}
  .score {font-size:18px;line-height:22px;letter-spacing:1px;color:#000000;margin-left:40px;}
  .profile {
    display:flex;
    align-items: center;
    justify-content:center;
    padding: 0 5px;
    overflow:hidden;
  }
  .profile .badge {
    font-family:Proxima Nova;
    text-align:center;
    font-size:12px;
    background-color: #405159;
    color: white;
    border-radius:4px;
    max-width:100%;
    padding: 5px 8px;
    overflow:hidden;
  }
  .profile .badge div {
    width:100%;
    text-overflow: clip;
  }
  .profile .badge span {
  }
  .status .progress {
    text-align:right;
    font-size:10px;
    color:#000000;
    margin-bottom:5px;
  }
  .status .progress::after{
    content:'';
    display:inline-block;
    width:5px;
    height:5px;
    background-color:rgb(239, 239, 239);
    border-radius:5px;
    margin-left:5px;
    
  }
  .status button {
    background:#efefef;
    border-radius:3px;
    padding:6px 15px;
    border:none;
    font-size:10px;
    font-family: Proxima Nova Semibold;
    float:right;
    min-width: 75px;
    color:#979797;
    &:hover{cursor:pointer;}
  }

  .status{
    &.PAYMENT_REQUIRED{
      button{
        color: #273237;
      }
      .progress::after{
        background-color:#FFA05C;
      }
    }
    &.GRADE_1{
      button {
        background: #FFEDED ;
        color: #E8686D;
      }
      .progress::after{
        display:none;background-color:#E8686D;
      }
    }
    &.GRADE_2{
      button {
        background: #FDF8E2 ;
        color: #E9B54D;
      }
      .progress::after{
        display:none;background-color:#E9B54D;
      }
    }
    &.GRADE_3{
      button {
        background: #E9F9F1 ;
        color: #43AC72;
      }
      .progress::after{
        display:none;background-color:#43AC72;
      }
    }
  }

  &.selected{
    background-color: #efefef;
    &:hover{
      cursor: default;
    }
  }
`;


export const StyledCheckupRequestHistory = styled.ul`
  margin-top: 30px;

  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
`;
export const StyledCheckupLink = styled.p`
  text-align:right;
  a {font-size:14px;line-height:17px;color:#367fff;text-decoration:none;}
`;

export const StyledScanHistoryColumn = styled.section`  
  h2 {
    font-family: Proxima Nova Semibold;
    font-size: 15px;
  }
`;


export const StyledCheckupSummary = styled.p`
  font-family: Proxima Nova, Open Sans, sans-serif;
  padding-left:20px;margin-top:40px;font-size:18px;line-height:22px;letter-spacing:0.5px;color:#000000;
  .greeting {font-size:18px;line-height:24px;color:#405159}
`;
export const StyledCheckupInformation = styled.div`
  position:absolute;left:290px;top:155px;
  font-family: Proxima Nova, 'Open Sans', sans-serif;
  p {font-size:36px;font-family:Proxima Nova Semibold;letter-spacing:0.8px;color:#12171a;}
  button {
    font-family: Proxima Nova, 'Open Sans', sans-serif;
    margin-top:20px;width:200px;background-color:#273238;border-radius:25px;color:#ffffff;font-size:18px;line-height:22px;letter-spacing:1px;padding:10px 0;border:none;
    &:hover{
      cursor:pointer;
    }
  }

`;

export const StyledCheckupSection = styled.div`
  position:relative;margin-top:8px;height:480px;border-radius:10px;background:url(${Scanner}) no-repeat left 170px bottom;background-color:#D5E4FF;
`;

export const StyledCheckupColumn = styled.section`
`;

export const StyledSectionWrapper = styled(StyledContentSection)`
  width:1200px;height:800px;display:grid;grid-template-columns:60% 1fr;grid-gap:2%;
`;

export const SectionTitle = styled(StyledContentTitle)`
`; // content__section--wrap

export const SectionPage = styled(StyledContent)`
  min-width: 1200px;
  background: #f7f7f7;
  height: ${props => props.height ? props.height : '100%'};
`; // content report
