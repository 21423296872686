const PREFIX = 'DENTISTS_';
export const constants = {
  FETCH_DENTIST_REQUEST: `${PREFIX}FETCH_DENTIST_REQUEST`,
  FETCH_DENTIST_SUCCESS: `${PREFIX}FETCH_DENTIST_SUCCESS`,
  FETCH_DENTIST_FAILURE: `${PREFIX}FETCH_DENTIST_FAILURE`,
  UPDATE_DENTISTS:`${PREFIX}UPDATE_DENTISTS`,
  UPDATE_DENTISTS_LATEST_CHECKUP_REQUEST:`${PREFIX}UPDATE_DENTISTS_LATEST_CHECKUP_REQUEST`, 
  UPDATE_DENTISTS_LATEST_CHECKUP:`${PREFIX}UPDATE_DENTISTS_LATEST_CHECKUP`,
  UPDATE_STATE: `${PREFIX}UPDATE_STATE`,
  UPDATE_STATE_OBJECT: `${PREFIX}UPDATE_STATE_OBJECT`
}
