import { constants} from './_constants';

const initState = {
  loading: {},
  settings: {},
  errors: {},
  channelIOLoaded: false
}

export function appSettings(state=initState, action){
  switch(action.type){
    case constants.FETCHSETTING_REQUEST:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data.setting]: true
        },
        errors: {
          ...state.errors,
          [action.data.setting]: false
        }
      }
    case constants.FETCHSETTING_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data.setting]: false
        },
        errors: {
          ...state.errors,
          [action.data.setting]: false
        },
        settings: {
          ...state.settings,
          [action.data.setting]: action.data.data
        }
      }
    case constants.FETCHSETTING_FAILURE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.data.setting]: false
        },
        errors: {
          ...state.errors,
          [action.data.setting]: action.data.error
        }
      }
    case constants.REMOVE_FAVORITE_DENTIST: 
      let newFaveDentists = state.settings.favoriteDentist.filter((fave) => {
        return fave.id != action.data.favoriteId
      })
      return {
        ...state,
        settings: {
          ...state.settings,
          favoriteDentist: newFaveDentists
        } 
    }
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    default:
      return state;
  }
}
