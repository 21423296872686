import create from 'zustand';

export const useReportDatesStore = create((set) => ({
    reports: {},
    hasMore: {},
    refetch: false,
    setRefetch: () => set((state) => ({...state, refetch: !state.refetch})),
    setReports: (profileId, reportsVal, hasMore) => 
        set((state) => ({
            ...state,
            reports: {
                ...state.reports,
                [profileId]: reportsVal
            },
            hasMore: {
                ...state.hasMore,
                [profileId]: hasMore
            }
        })),
    addReports: (profileId, reportsVal, hasMore) => 
        set((state) => ({
            ...state,
            reports: {
                ...state.reports,
                [profileId]: [
                    ...(state.reports[profileId] || []), 
                    ...reportsVal
                ]
            },
            hasMore: {
                ...state.hasMore,
                [profileId]: hasMore
            }
        })),

}));