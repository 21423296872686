import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  /* .toast-container */

   /* .toast is passed to toastClassName */
  .toast {
    background-color: #367FFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  button[aria-label="close"] {
  }

  /* .body is passed to bodyClassName */
  .body {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.8px;
    padding: 15px 25px;

    color: #FFFFFF;
  }

  /* .progress is passed to progressClassName */
  .progress {}
`;
