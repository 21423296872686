import { createSelector } from 'reselect'
import { TOOTH_NUMBERING_SYSTEM, REGEX_ONLY_TOOTH, REGEX_ONLY_REGION } from '../_config';
let DEFAULT_NUMBERING = "international";

export const getPartialScansState = (state) => state.partialScans;

export const getCurrentPartialScanId = createSelector(
  getPartialScansState,
  (state) => {
    return state.partialScanId;
  }
)

export const getReviewOpen = createSelector(
  getPartialScansState,
  (state) => {
    return state.reviewOpen;
  }
)

export const getPartialScanMessageTimeToken = createSelector(
  getPartialScansState,
  (state) => {
    return state.partialScanMessageTimeToken;
  }
)

export const getCurrentPartialScanData = createSelector(
  getPartialScansState,
  (state) => {
    return state.partialScan;
  }
)


export const getRegionsById = createSelector(
  getPartialScansState,
  (state) => {
    return state.byId;
  }
)

export const getSelectedRegion = createSelector(
  getPartialScansState,
  (state) => {
    return state.selectedRegion;
  }
)

export const getRegionsList = createSelector(
  getCurrentPartialScanData,
  (partialScan) => {
    return (partialScan && partialScan.regions)
      ? partialScan.regions.map(elem =>  {return elem.region})
      : [];
  }
)

export const getCurrentPartialScanRegion = createSelector(
  [getRegionsById, getSelectedRegion],
  (byId, currentRegion) => {
    return (byId && currentRegion) && byId[currentRegion]
  }
)

export const getRegionDataList = createSelector(
  getRegionsById,
  (byId) => {
    return (byId)
      ? Object.values(byId)
      : [];
  }
)

export const getSortedRegionList = createSelector(
  getRegionsById,
  (byId) => {
    return (byId)
      ? Object.keys(byId).sort((a,b)=>{
        if (TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][a.match(REGEX_ONLY_TOOTH)] == TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][b.match(REGEX_ONLY_TOOTH)]){
          return 0;
        } else if (TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][a.match(REGEX_ONLY_TOOTH)] < TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][b.match(REGEX_ONLY_TOOTH)]){
          return -1;
        } else {
          return 1;
        }
      })
      : [];
  }
)

export const getPartialScansToUpload = createSelector(
  getRegionsById,
  (byId) => {
    return (byId)
      ? Object.values(byId)
        .filter(item => ((item.status == false) && item.data))
        .map((item) => { return item})
        /*.sort((a,b)=>{
          if (TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][a.match(REGEX_ONLY_TOOTH)] == TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][b.match(REGEX_ONLY_TOOTH)]){
            return 0;
          } else if (TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][a.match(REGEX_ONLY_TOOTH)] < TOOTH_NUMBERING_SYSTEM[DEFAULT_NUMBERING][b.match(REGEX_ONLY_TOOTH)]){
            return -1;
          } else {
            return 1;
          }
        })*/
        : [];
  }
)

export const getIsReadyToUpload = createSelector(
  getRegionsById,
  (byId) => {
    return (byId)
      ? Object.values(byId)
        .filter(item => (item.status == false && !item.data))
      : [];
  }
)

export const getIsComplete = createSelector(
  getRegionsById,
  (byId) => {
    return (byId)
      ? Object.values(byId).filter(item => item.status == false)
      : [];
  }
)


export const getUploadTotal = createSelector(
  [getRegionDataList],
  (items) => {
    return items
      ? items.length * 100
      : 0;
  }
)

export const getUploadProgress = createSelector(
  [getRegionDataList],
  (items) => {
    return items
      ? items.reduce((a,b) => {
          return a + (b.progress || 0);
        }, 0)
      : 0;
  }
)
