import { BaseCategory } from './BaseCategory'
import { categoryIcons } from './constants';
import './index.css'

class ColorCategory extends BaseCategory {
    /**
     *
     * @param data
     * @param config
     * @param api
     * @param readOnly
     */
    constructor({data, config, api, readOnly}) {
        data.category = 'color';
        super({
            data,
            config,
            api,
            readOnly
        });
    }
    
    /**
     * Icon and title for displaying at the Toolbox
     *
     * @return {{icon: string, title: string}}
     */
    static get toolbox() {
        return {
            icon: categoryIcons['color'],
            title: 'Color'
        };
    }
}

export default ColorCategory;