import { constants } from './_constants';
import { services } from './_services';
import { services as mediaServices } from '../MediaUploader/_services';
import { createMediaItem } from '../MediaUploader/_models';

export const actions = {
  toggleReview,
  addDetectionScan,
  removeDetection,
  uploadMedia
}

function uploadMedia(item, record){
  return (dispatch) => {
    mediaServices.upload(item.getData(), record, (event) => {
      // HACK set final progress to 95 because there is a delay between progress and finished upload
      dispatch(setProgress(item.getId(), Math.round((95 * event.loaded) / event.total)))
    }, item.getExtraData())
      .then(
        resp => {
          dispatch(success(item.getId(), resp.data));
        }
      ).catch(
        error => dispatch(failure(item.getId(), error))
      )
  }
  function setProgress(id, progress){return {type: constants.UPLOAD_SETPROGRESS, data: {progress: progress}}}
  function success(id, data){ return {type: constants.UPLOAD_SUCCESS, data: { uploadData: data} }}
  function failure(id, error){ return {type: constants.UPLOAD_FAILURE, data: {error} }}
}

function toggleReview(toggle){
  return (dispatch) => {
    dispatch(update(toggle));
  }
  function update(toggle){ return { type: constants.UPDATE, data: {reviewOpen: toggle}}}
}

function removeDetection(){
  return (dispatch) => {
    dispatch(remove());
  }
  function remove(){ return { type: constants.UPDATE, data: { detectionScan: null}}}
}

function addDetectionScan(data, name, type, scanType, extraData={} ){
  return (dispatch) => {
    dispatch(update(data, name, type, scanType, extraData));
  }

  function update(data, name, type, scanType, extraData){
    let mediaItem = createMediaItem(data, name, type, scanType, extraData);
    return { type: constants.UPDATE, data: { detectionScan: mediaItem }}
  }
}
