import { constants } from './_constants';

const initState = {
  recordFilter: "FULLSCAN",
  byId: {},
  isMore: false,
  uploadsbyId: {},
  loading: false,
  error: false,
  currentRequest: null,
  currentRecord: null,
  currentSelectedMedia: null,
  requestDetails: {},
  recordDetailById: {},
  loadingRecordDetail: false,
  errorRecordDetail: false,
  detailsLoading: {},
  detailsError: {},
  currentDetectionMedia: null,
  detectionsById: {},
  detectionsLoading: {},
  detectionsError: {}
}

export function records(state=initState, action){
  switch (action.type){
    case constants.FETCHRECORDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case constants.FETCHRECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        byId: Object.assign({}, state.byId, action.data.byId),
        isMore: action.data.isMore
      }
    case constants.FETCHRECORDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.FETCHRECORD_REQUEST:
      return {
        ...state,
        detailsLoading: {
          ...state.detailsLoading,
          [action.data.id]: true
        },
        detailsError: {
          ...state.detailsError,
          [action.data.id]: false
        },
      }
    case constants.FETCHRECORD_SUCCESS:
      return {
        ...state,
        detailsLoading: {
          ...state.detailsLoading,
          [action.data.id]: false
        },
        detailsError: {
          ...state.detailsError,
          [action.data.id]: false
        },
        recordDetailById: Object.assign({}, state.recordDetailById, action.data.byId)
      }
    case constants.FETCHRECORD_FAILURE:
      return {
        ...state,
        detailsLoading: {
          ...state.detailsLoading,
          [action.data.id]: false
        },
        detailsError: {
          ...state.detailsError,
          [action.data.id]: action.data.error
        },
      }
    case constants.FETCHDETECTIONS_REQUEST:
      return {
        ...state,
        detectionsLoading: {
          ...state.detectionsLoading,
          [action.data.id]: true
        },
        detectionsError: {
          ...state.detectionsError,
          [action.data.id]: false
        },
      }
    case constants.FETCHDETECTIONS_SUCCESS:
      return {
        ...state,
        detectionsLoading: {
          ...state.detailsLoading,
          [action.data.id]: false
        },
        detectionsError: {
          ...state.detailsError,
          [action.data.id]: false
        },
        detectionsById: Object.assign({}, state.detectionsById, action.data.byId)
      }
    case constants.FETCHDETECTIONS_FAILURE:
      return {
        ...state,
        detectionsLoading: {
          ...state.detectionsLoading,
          [action.data.id]: false
        },
        detectionsError: {
          ...state.detectionsError,
          [action.data.id]: action.data.error
        },
      }
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state
  }
}
