import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button }  from '@chakra-ui/react';
// selectors
import { getIntlMessages } from '../Authentication/_selectors';

// styles
import { StyledBrowserNotifications } from './styles';
import { StyledButton } from '../_styles/forms';

export const FirebaseNotifications = ({closeModal}) => {
  const modalName = 'firebaseNotifications';
  const intlMessages = useSelector(getIntlMessages);
  const [ checked, setChecked ] = useState(false);

  const handleClose = () => {
    if (checked){
      localStorage.setItem(`doNotShow__${modalName}`, Date.now().toString());
    }
    closeModal()
  }

  const onChange = (e) => {
    setChecked(e.target.checked);
  }

  return (
    <StyledBrowserNotifications>
      <div className={'title'}>{intlMessages['browsernotifications.firebase.modal.title']}</div>
      <div className={'examples'}>
        {/* insert notificatoin gif, images showing how to enable here */}
      </div>
      <div className={'description'}>
        <p>{intlMessages['browsernotifications.firebase.modal.description']}</p>
      </div>
      <div className={'action'}>
        <Button 
          variant={'solid'}
          minW={'250px'}
          w={'250px'}
          onClick={handleClose}
        >
          {intlMessages['browsernotifications.firebase.modal.action.positive']}
        </Button>
      </div>
      <div className={'notShowAgain'}>
        <input
          name={`doNotShow__${modalName}`}
          component="input"
          type="checkbox"
          value="yes"
          id="yes"
          onChange={onChange}
          />
        <label>{intlMessages['browsernotifications.firebase.modal.action.donotshow']}</label>
      </div>
    </StyledBrowserNotifications>
  )
}
