import storage from '../_helpers/storage';
import { constants } from './_constants';

export const actions = {
  setPaymentIntent,
  setCheckupRecord,
  setCheckupRequest,
  setCheckupClinicDentist,
  reset
}

function setPaymentIntent(request){
  return (dispatch) => {
    dispatch(update(request));
  }
  function update(request){
    let data = {paymentIntent: null, paymentInformation: {}, paymentProcessor: null, checkupRequest:null, checkupRecord: null}
    if (request == null){
      storage.setCheckupRequest(data);
      return {type:constants.UPDATE, data};
    }
    let { payment, id } = request;
    if (payment == null){
      data = {paymentIntent: null, paymentInformation: {}, paymentProcessor: null, checkupRequest:id}
    } else if (payment.processor && payment.processor.processor == "STRIPE"){
      data = {paymentIntent: payment.client_secret, paymentInformation: payment, paymentProcessor: "STRIPE", checkupRequest:id}
    } else if (payment.processor && payment.processor.processor == "OMISE"){
      data = {paymentIntent: payment.amount, paymentInformation: payment, paymentProcessor: "OMISE", checkupRequest:id}
    } else {
      data = {paymentIntent: null, paymentInformation: {}, paymentProcessor: null, checkupRequest:id}
    }
    storage.setCheckupRequest(data);
    return {type:constants.UPDATE, data}
  }
}


function setCheckupClinicDentist(id){
  return (dispatch) => {
    dispatch(update(id));
  }
  function update(id) { return {type:constants.UPDATE, data: {checkupClinicDentist: id}}}
}

function setCheckupRecord(id){
  return (dispatch) => {
    dispatch(update(id));
  }
  function update(id) { return {type:constants.UPDATE, data: {checkupRecord: id}}}
}

function setCheckupRequest(id){
  return (dispatch) => {
    dispatch(update(id))
  }
  function update(id) { return {type:constants.UPDATE, data: {checkupRequest: id}}}
}

function reset(){
  return (dispatch) => {
    dispatch(reset())
  }
  function reset(){return {type:constants.RESET}}
}
