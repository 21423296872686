import React from 'react';
import { deviceDetect, osName, osVersion, mobileVendor, mobileModel } from 'react-device-detect';

export const CompatibilityPage = () => {
  return (
    <div className={'min-h-screen text-[#12171a]'}>
      <div className={'px-6 py-12 text-center md:p-28 md:text-left' }>
        <h1 className={'my-5 text-3xl font-bold'}>{'Compatibility'}</h1>
        <div>
          <div className={'text-xl my-2'}>{'OS'}</div>
          <div className={'flex text-lg'}>
            <div className={'font-bold mr-1'}>
              {osName}
            </div>
            <div>
              {osVersion}
            </div>

          </div>
          <div className={'text-xl my-2'}>{'Device'}</div>
          <div className={'flex text-lg'}>
            <div className={'font-bold mr-1'}>
              {(mobileVendor != "none") ? mobileVendor : '-'}
            </div>
            <div>
              {(mobileVendor != "none") && mobileModel}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}