import styled, {css} from 'styled-components';

export const StyledPageTitle = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */
  color: #12171A;
  text-align: left;
`;

export const StyledLoadingButtonLoading = styled.div`
  width: 100%;
  display:flex;
  align-items:center;
  justify-content: center;
  border-radius: 5px;
  height: 100%;
  &:hover{
    cursor:default;
  }
`;

export const StyledForm = styled.form`
  background: #FFFFFF;
  border: 1px solid #EDEDED;
  box-shadow: 1px 3px 20px rgba(239, 239, 239, 0.5);
  border-radius: 10px;
  text-align:center;
  display:flex;
  flex-direction: column;
  padding: 20px 50px 40px;
`;

export const StyledFormHeader = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  margin: 20px 0 30px 0;
`;

export const StyledDescription = styled.div`
  width:100%;
  height:15px;
  margin-top:10px;
  font-size:12px;
  text-align:center;
`;

export const ScanSectionTitleStyles = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 24px;
  color: #12171A;
  text-align: center;
  padding: 20px;
`;

export const SubsectionTitleStyles = styled.div`
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  color: #12171A;
  padding: 20px 0px;
`;

export const StyledSelectedFields = styled.div`
  display:flex;
  justify-content: flex-start;

  span:not(:first-child)::before{
    content: ", ";
  }
`;

export const StyledInputLayout = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0;
`;

export const StyledTextArea = styled.textarea`
  background: #FFFFFF;
  border: 1px solid #36454D;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 15px;
  width:100%;

  &:placeholder-shown{
    border-color: #C7D3D9;
  }
  ${props => props.addCSS}
`;

export const StyledLabel = styled.label`
  margin: 15px 0;
  margin-bottom: 5px;
  font-size: 14px;
  width:100%;
  text-align: left;

  font-family: Proxima Nova Semibold;
  font-size: 18px;
  text-align: left;
  color: #12171A;
`;

export const StyledInput = styled.input`
  background: #F9F9F9;
  border: 1px solid #C7D3D9;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 15px;
  width:100%;
  color: black;
  &:placeholder-shown{
    color: #999;
  }
  ${props => props.addCSS}
`;

export const FormHeader = styled.span`
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  margin: 20px 0 30px 0;
`;


export const ErrorLabel = styled.div`
  visibility:hidden;
  width:100%;
  height:15px;
  margin-top:10px;
  font-size:12px;
  text-align:left;
  font-color: red;
  font-family: Proxima Nova Semibold;
  letter-spacing: 0.9px;
  line-height: ${props => props.setLineHeight ? props.setLineHeight+'px' : '19px'};
  color:red;
  visibility: ${props => props.isError ? 'visible' : 'hidden'};
  ${props => props.addCSS}
`;

export const ErrorSpan = styled.span`
    visibility:hidden;
    height:15px;
    font-size:12px;
    text-align:left;
    font-color: red;
    font-family: Proxima Nova Semibold;
    margin-left: 5px;
    letter-spacing: 0.9px;
    line-height: ${props => props.setLineHeight ? props.setLineHeight+'px' : '19px'};
    color:red;
    visibility: ${props => props.isError ? 'visible' : 'hidden'};
`;

export const errorCss = css`
  border-color:red !important;
  border-width:2px;
  visibility:visible;
`;

export const StyledRawButton = styled.button`
  background: #273238;
  box-shadow: 0px 2px 14px rgba(39, 50, 56, 0.3);
  border-radius: 28px;
  color:white;
  padding: 10px 15px;
  font-family: Proxima Nova Bold;
  letter-spacing: 0.9px;
  width: fit-content;
  margin: 0 auto;
  border: none;
  text-transform: uppercase;

  &.red{
    background: #E54848;
  }

  &.secondary{
    background: #4D5666;
  }

  &:hover{
    cursor: pointer;
  }

  &:disabled{
    opacity: .5;
    &:hover{
      cursor: default;
      opacity: .5;
    }
  }
`;

export const StyledButton = styled.button`
  background: #273238;
  box-shadow: 0 4px 6px -1px rgba(39, 50, 56, 0.3);
  border-radius: 28px;
  color:white;
  padding: 10px 15px;
  font-family: Proxima Nova Bold;
  letter-spacing: 0.9px;
  width: fit-content;
  min-width: 200px;
  margin: 0 auto;
  border: none;
  margin-top: 30px;

  &.cancel{
    border: 2px solid #273238;
    box-shadow: 0 1px 2px 0 rgba(39, 50, 56, 0.3);
    background-color: inherit;
    color: #273238;
  }

  &.delete{
    border: 2px solid #E54848;
    box-shadow: 0 1px 2px 0 rgba(39, 50, 56, 0.3);
    background-color: inherit;
    color: #E54848;
  }

  &:hover{
    cursor: pointer;
    box-shadow: none;
    opacity: 0.8;
  }

  &:disabled{
    opacity: .5;
    &:hover{
      cursor: default;
      opacity: .5;
    }
  }
`;

export const StyledDayPickerInput = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0;
  align-items:flex-start;
  color: black;

  .DayPickerInput {
    > input {
      background: #F9F9F9;
      border: 1px solid #C7D3D9;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 16px;
      padding: 10px 15px;
      width:100%;
      ${props => props.addCSS}
    }
  }

  input.Input_Error{
    border-color:red;
    border-width:2px;
  }

  ${StyledLabel} {
    margin: 15px 0;
    margin-bottom: 5px;
  }
`;

export const StyledLoadingDiv = styled.div`
  font-size:48px;
  padding: 40px;
`;

export const StyledErrorDiv = styled.div`
  font-size:48px;
  padding: 40px;
`;
