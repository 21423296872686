export const deleteAccount = `
#Deleting your Account
In order to delete your account, you must first navigate to the settings page. 
Once there, you will find a button that says "Delete Account". 
Clicking this button will prompt you to confirm that you would like to delete your account. 
Once you confirm, your account will be deleted and you will be logged out of the application.:

#Don't have access to your account?
If you no longer have access to your account, please contact us by email at support@plover.life for assistance.
`;
