import { createSelector } from 'reselect';
import moment from 'moment';
export const getReportsSlice = (state) => state.reports;

export const getRequestList = createSelector(
  [getReportsSlice],
  (reports) => {
    return (reports.requests)
      ? Object.values(reports.requests)
          .map( item => {return item})
          .sort((a,b)=>{
            if (moment(a.created_at).isSame(b.created_at)){
              return 0;
            } else if (moment(a.created_at).isBefore(b.created_at)){
              return 1;
            } else {
              return -1;
            }
          })
      : [];
  }
)

export const getRequestDetailById = createSelector(
  [getReportsSlice],
  (reports) => {
    return reports.requestDetailsById
  }
)

export const getCurrentRequestId = createSelector(
  [getReportsSlice],
  (reports) => {
    return reports.currentRequestId
  }
)


export const getCurrentMediaId = createSelector(
  [getReportsSlice],
  (reports) => {
    return reports.currentSelectedMedia;
  }
)

export const getRequestFilter = createSelector(
  [getReportsSlice],
  (reports) => {
    return reports.requestFilter;
  }
)


export const getRequestListWithFilter = createSelector(
  [getReportsSlice, getRequestFilter],
  (reports, requestFilter) => {
    return (reports.requests)
      ? Object.values(reports.requests)
          .filter(item => {
            if (requestFilter == null){
              return true
            } else {
              return (requestFilter == item.user_profile.id)
            }
          } )
          .map( item => {return item})
          .sort((a,b)=>{
            if (moment(a.created_at).isSame(b.created_at)){
              return 0;
            } else if (moment(a.created_at).isBefore(b.created_at)){
              return 1;
            } else {
              return -1;
            }
          })
      : [];
  }
)

export const getReportLoading = createSelector(
  [getReportsSlice],
  (reports) => {
    return reports.loadingReport;
  }
)

export const getReportListLoading = createSelector(
  [getReportsSlice],
  (reports) => {
    return reports.loadingRequests;
  }
)

export const getRequestListIsMore = createSelector(
  [getReportsSlice],
  (reports) => {
    return reports.isMore;
  }
)

export const getReportList = createSelector(
  [getRequestList],
  (requests) => {
    return requests.filter(item => item.report && item.report.sent_at);
  }
)

export const getRequestDetails = createSelector(
  [getRequestDetailById, getCurrentRequestId],
  (byId, current) => {
    return byId[current];
  }
)

export const getRequestDetailsId = createSelector(
  [getRequestDetails],
  (details) => {
    return details && details.id;
  }
)

export const getRequestDetailsUniqueId = createSelector(
  [getRequestDetails],
  (details) => {
    return details && details.unique_id;
  }
)

export const getRequestReport = createSelector(
  [getRequestDetails],
  (request) => {
    if (!request) return null;
    return ((request?.type == "GENERATIVE" || request?.type == "GEN_DMFT") ? request?.user_report : request.report)
  }
)

export const getRequestRecord = createSelector(
  [getRequestDetails],
  (request) => {
    return (request && request.record)
  }
)

export const getRequestRecordId = createSelector(
  [getRequestRecord],
  (record) => {
    return record && record.id;
  }
)


export const getChartCategory = createSelector(
  [getReportsSlice],
  (reports) => {
    return reports.currentChartCategory
  }
)

export const getRecordUploads = createSelector(
  [getRequestRecord],
  (record) => {
    return (record && record.record_uploads) ? record.record_uploads : [];
  }
)

export const getCurrentSelectedMedia = createSelector(
  [getRecordUploads, getCurrentMediaId],
  (uploads, currentId) =>{
    return (uploads && currentId)
      ? uploads[currentId]
      : null;
  }
)

export const getReportCharts = createSelector(
  [getRequestReport],
  (report) => {
    return report && report.report_charts
  }
)

export const getReportCategoriesCount = createSelector(
  [getReportCharts],
  (charts) => {
    const categories = {};
    (charts && charts.length) && charts.map((elem,) => {
      if (elem.extra_data?.type && !categories[elem.extra_data?.type]){
        categories[elem.extra_data?.type] = 1;
      } else {
        categories[elem.extra_data?.type] = categories[elem.extra_data?.type] + 1;
      }
    })
    return categories
  }
)

export const getFilteredReportCharts = createSelector(
  [getReportCharts, getChartCategory],
  (charts, category ) => {
    return (charts && category)
      ? charts.filter(item =>item.extra_data.type == category)
      : charts;
  }
)

export const getFilteredReportRegions = createSelector(
  [getFilteredReportCharts, getChartCategory],
  (charts, category) => {
    const regions = {};
    (charts && charts.length) && charts.map((elem,) => {
      ((elem.extra_data && elem.extra_data.all_regions) || []).map((item,) => {
        regions[item] = (regions[item]) ?  ( category ? category : 'ALL') : elem.extra_data && elem.extra_data.type;
      })
    })
    return regions
  }
)

export const getUpcomingAppointments = createSelector(
  [getRequestList],
  (requests) => {
    return (requests) ?
      requests.filter(item => item.type === "VIDEOCHAT" )
      : [];
  }
)
