import { Box, Flex, Text, Circle } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { COLORS_STATUS } from "../../_config";

function StatusText({status}){
    if (status == 'COMPLETE'){
        return <FormattedMessage id="requestItem.status.COMPLETE" defaultMessage="Complete"/>
    } else if (status == 'INSPECTING'){
        return <FormattedMessage id="requestItem.status.INSPECTING" defaultMessage="Review Requested"/>
    } else if (status == 'READY'){
        return <FormattedMessage id="requestItem.status.READY" defaultMessage="Requested"/>
    } else {
        return <FormattedMessage id="requestItem.status.INIT" defaultMessage="Requested"/>
    } 
}

function StatusDot({status, size=10}){
    // payment required. need a better flow if thats the case
    // or the request never gets created unless the payment goes through
    let dotColor = COLORS_STATUS.PENDING;
    if (status == 'COMPLETE' ){
        return (
            <Box>
                <Circle mr={['5px']}  size={`${size}px`} bg={COLORS_STATUS.COMPLETED} />
            </Box>
        )
    } else if (status == 'INSPECTING'){
        return (
            <Box>
                <Circle mr={['5px']}  size={`${size}px`} bg={COLORS_STATUS.INSPECTING} />
            </Box>
        )
    } else {
        return (
            <Box>
                <Circle mr={['5px']} size={`${size}px`} bg={dotColor} />
            </Box>
        )
    }
}

export const StatusTag = ({ status, size="md", inspectWasSent=false }) => { 
    const statusColor = {
        'PENDING': '#FFA05C',
        'READY': '35BE9D',
        'COMPLETED': '#35BE9D',
        'PAYMENT_REQUIRED': '#E95252',
    }

    let fontSize = '14px';
    let dotSize = 6
    let height = 26

    if (size == "sm"){
        fontSize="12px"
        dotSize = 5
        height=22
    }

    if (inspectWasSent){
        status = 'INSPECTING'
    }
    
    return (
        <Flex
            borderRadius={['60px']}
            align={'center'}
            px={['15px']}
            py={['0px']}
            border={'0.5px solid #6B8A99'}
            h={[`${height}px`]}
            bg={'transparent'}
        >
            <StatusDot status={status} size={dotSize} />
            <Text
                textTransform={'upperCase'}
                color={'#6B8A99'}
                fontSize={[fontSize]}
                whiteSpace={'nowrap'}
            >
                <StatusText status={status} /> 
            </Text>
            
        </Flex>
    )
}