const PREFIX = 'MEDIAUPLOADER_';
export const constants = {
  SET_CURRENTSELECTED: `${PREFIX}SET_CURRENTSELECTED`,
  REMOVE_MEDIA: `${PREFIX}REMOVE_MEDIA`,
  UPDATE_MEDIA: `${PREFIX}UPDATE_MEDIA`,
  UPDATE_STATE: `${PREFIX}UPDATE_STATE`,
  UPDATE_STATE_OBJECT: `${PREFIX}UPDATE_STATE_OBJECT`,
  UPLOAD_SETPROGRESS: `${PREFIX}UPLOAD_SETPROGRESS`,
  UPLOAD_SUCCESS: `${PREFIX}UPLOAD_SUCCESS`,
  UPLOAD_FAILURE: `${PREFIX}UPLOAD_FAILURE`,
  RESET_MEDIA: `${PREFIX}RESET_MEDIA`
}
