import styled from 'styled-components';

import { StyledButton } from '../../_styles/forms';

export const StyledSwitchLanguage = styled.div`

  background-color: #EFEFEF;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  width: 500px;
  padding: 40px;
  border-radius: 5px;

  .title{
      font-family: Proxima Nova Semibold;
      font-size: 18px;
      color: black;
      margin-bottom: 20px;
  }

  .description{
    font-family: Proxima Nova;
    line-height: 18px;
    p{
      margin: 10px 0;
    }
  }

  .actions{
    display:flex;
    flex-direction: column;
    ${StyledButton}{
      margin: 15px;
    }
  }
`;
