import styled , { keyframes } from 'styled-components';
import { Header } from '../PloverScan/styles';

export const StyledDetectionScanButtons = styled.div`
  width: 100%;
  display:flex;
  justify-content: flex-end;
  padding-top:20px;
`;

export const StyledDetectionScanColumn = styled.div`
  padding-left: 15px;
  padding-right: 20px;

  ${Header} {
    font-family: Proxima Nova Semibold;
    font-style: normal;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 1px;
    color: #121719;
  }
`;

export const StyledDetectionInformationColumn = styled.div`
    font-family: Proxima Nova Semibold;

    .infoColumn{
      padding-left: 10px;
      font-size: 14px;
      > div{
          margin-bottom: 15px;
          display:flex;
          justify-content: flex-start;
          align-items: flex-start;

          div:first-child{
            margin-right: 10px;
            padding-top: 4px;
          }
      }

    }
`;

export const StyledDetectionScan = styled.div`
    width: 980px;
    padding: 20px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
`;
