import styled from 'styled-components';

export const RemoveButton = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 20px;
  height: 20px;
  background: #F9F9F9;
  opacity: 0.9;
  border: 0.5px solid #405159;
  box-sizing: border-box;
  border-radius:10px;
  display:flex;
  align-items:center;
  justify-content: center;

  > svg {
    width:10px;
    height:10px;
  }

  &:hover{
    cursor: pointer;
  }
`;

export const StyledCCard = styled.div`
  padding: 15px 45px;
  width: 300px;
  display:flex;
  justify-content:space-between;
  box-sizing: border-box;
  font-size: 14px;

  border: ${({isSelected, isDefault}) => (isSelected) ? '2px solid #179BD7' : (isDefault) ? '2px solid #4D5666' : '2px solid #36454D'};

  box-shadow: ${({isSelected}) => (isSelected) ? '0px 2px 14px rgba(39, 50, 56, 0.5)' :'none'};

  border-radius: 15px;

  .cardIcon{
    img {
      width: 44px;
    }
  }
  .cardDetails{
    margin-left: 20px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:flex-end;
  }

  .cardNumber span, .cardExp span{
    font-family: Proxima Nova Bold;
  }

  &:hover{
    cursor:pointer;
    opacity: 0.8;
  }
`;

export const StyledCCardRow = styled.div`
  display:flex;
  position: relative;
  margin: 0px 0px;
  &:first-child{
    margin-top: 0;
  }

  .default{
    padding-left: 15px;
    display:flex;
    align-items: center;
    justify-content:center;
    .isDefault{
      color: #12171a;
      &:hover{
        cursor:default;
      }
    }

    .makeDefault{
      color: #7A8F99;
      &:hover{
        cursor: pointer;
      }
    }
  }
`;

export const StyledChangeCardLink = styled.div`
  span{
    font-family: Proxima Nova Semibold;
    font-size: 13px;
    letter-spacing: 0.9px;
    width: 300px;
    display:flex;
    justify-content: center;
    padding-top: 10px;
    color: #7A8F99;
  }

  &:hover{
    cursor:pointer;
  }
`;


export const StyledCCardList = styled.div`
  padding-left: 15px;
  .error{
    height: 15px;
    font-size: 12px;
    color: red;
    margin-left: 10px;
}

`;

export const StyledPage = styled.div`
  height: 700px;
  overflow: auto;
  .loading{
    margin: 15px auto;
  }
`;
