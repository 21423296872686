import { useSelector } from 'react-redux';
import analyzingImage from '../_images/analyzingScanLaptop.png';
import { ModalHeader } from '../Modals/ModalHeader';
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getIntlMessages } from '../Authentication/_selectors';
import { CloseIcon } from '../Modals/styles';


const ModalContainer = ({children, closeModal}) => {
    const { formatMessage } = useIntl();
    return (
      <div style={{position: 'relative', maxWidth: '620px'}}>
        <ModalHeader
          title={formatMessage({"id": "checkup.upload.createrequest.header", "defaultMessage": "Confirmation"})} 
        />
          {children}  
      </div>
    )
  }

export const RequestConfirmation = ({ closeModal }) => {

    return (
        <ModalContainer closeModal={closeModal}>
        <Box bg={'#F7F7F7'} >
            <Box pt={'20px'} position={'relative'} overflow={'auto'}>
                <Box  w={['full']} maxW={`600px`} px={['40px']} pt={['10px']}>
                    <Text
                        color={'#273238'}
                        fontSize={'40px'}
                    >
                        <FormattedMessage
                            id={'checkup.upload.createrequest.confirmed.title'}
                            defaultMessage={'Request sent!'} 
                        />

                    </Text>
                    <Text
                        color={'#4D5666'}
                        fontSize={'18px'}
                    >
                        <FormattedMessage
                            id={'checkup.upload.createrequest.confirmed.description'}
                            defaultMessage={'Your request has been sent. We will notify you once the results are ready.'} 
                        />

                    </Text>
                </Box>
            </Box>
            <Flex w={'full'} p={['40px']} bg={'#F7F7F7'}>
                <Button 
                    variant={'solid'} 
                    w={['full']}
                    onClick={closeModal}
                >
                    <FormattedMessage 
                        id={'checkup.fullscan.review.close'}
                        defaultMessage={'Close'}
                    />
                </Button>
            </Flex>
        </Box>
        </ModalContainer>
    )
}