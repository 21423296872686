import styled , { keyframes } from 'styled-components';

import { StyledContent, StyledContentTitle, StyledContentSection } from '../_styles/common';

const blink = keyframes`
  50% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  width: 1200px;
  margin: auto;
`;

export const StyledBox = styled.div`
  ${props => props.addCss}
`;

export const  FlexRow = styled.div`
  display: flex;
  align-items: center;
  ${props => props.addCss}
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  ${props => props.addCss}
`;

export const ScrollView = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px
  ${props => props.addCss}
`;

export const StyledChatConversation = styled.section`
  /*display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-top: 30px;
  height: 100%;
  width: 100%;
  position: fixed, static;
  border-right: light;*/
  height:100%;
  width:100%;
  position:relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 630px 80px;


`;

export const StyledConversationHeader = styled.div`
  padding-right:20px;padding-left:20px;padding-bottom:8px;border-bottom:1px solid #EBF2F5;display:flex;align-items:center;justify-content:space-between;
  .name {font-family:Proxima Nova Semibold;font-size:19px;line-height:24px;color:#12171A;}
  .job {font-size:12px;line-height:18px;}
  .camera {
    background:none;border:none;height:100%;padding: 0 10px;
    &:disabled{
      svg  {
        path {
          fill: #C4C4C4;
        }
      }
    }
    &:disabled:hover{
      cursor: default;
    }
    &:not(:disabled):hover{cursor:pointer;}
  }
`;

export const StyledChatConversationSection = styled.div`
  position:relative;padding:20px 20px;
`;

export const StyledChatList = styled.div`
  h2 {font-size:24px;line-height:30px;font-family:Proxima Nova Semibold;color:#12171a;margin-bottom:12px;margin-top:12px;padding-left:20px;padding-right:20px;}
`;

export const StyledChatListWrapper = styled.div`
  position:absolute;left:0;bottom:35px;width:90%;padding:0 20px;
`;

export const StyledTextArea= styled.textarea`
  overflow: hidden;
  color: #12171A;
  resize: none;
  font-family: Proxima Nova;
  font-size: 17px;
  width: 100%;
  /*border:none;*/

  background: #FFFFFF;
  border: 1px solid #12171A;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 40px;

  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  display:flex;
  align-items: center;
  flex-direction: center;
  margin-right: 10px;

  > img{
    transform: ${props => props.showing ? 'rotate(45deg)' : ''};
    background: #12171A;
    border: 1px solid #273238;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(26, 20, 19, 0.05);
    border-radius: 50%;
    height: 42px;
    width: 42px;
    padding: 10px;
  }

  > :hover{
    cursor: pointer;
  }
`;

export const StyledMessageInput = styled.div`
  display:flex;
  position:relative;
`;

export const StyledMessageInputWrapper = styled.div`
  position:absolute;left:0;bottom:35px;width:100%;
  padding: ${({isCurrent})=> (isCurrent) ? '0 35px' : '0'};
  .loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    .loadingMessage{
      margin-top: 20px;
    }
  }
`;

export const StyledExpiredMessageInput = styled.div`
  display:flex;
  flex-direction: column;
  position:relative;
  align-items:center;
  justify-content:center;

  .label{
    font-family: Proxima Nova;
    font-style: normal;
    font-family:Proxima Nova Semibold;
    font-size: 15px;
    line-height: 21px;
    /* or 140% */

    text-align: center;

    color: #405159;
    background-color: #F2F2F2;
    padding: 10px;
    width: 100%;
  }

  .button{
    background: #273238;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    position:relative;
    width: fit-content;
    height: fit-content;
    text-transform: uppercase;
    font-family: Proxima Nova;
    font-style: normal;
    font-family:Proxima Nova Semibold;
    font-size: 13px;
    text-align: center;
    letter-spacing: 0.9px;
    margin-top: 20px;
    color: #FFFFFF;
    border:none;
    padding: 20px 30px;
    border-radius: 100px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    filter: drop-shadow(0px 2px 14px rgba(39, 50, 56, 0.5));

    &:hover{
         opacity:0.8;
         cursor:pointer;
       }
  }
`;

export const Divider = styled.div`
  /*border-bottom: 1px solid #DADADA;
  margin: 10px;*/
  border-left: 2px solid #E6E6E6;
  height: 25px;
`;


export const StyledLoadingDiv = styled.div`
  font-size:48px;
  padding: 40px;
`;

export const StyledErrorDiv = styled.div`
  font-size:48px;
  padding: 40px;
`;


export const StyledPageTitle = styled.div`
  font-family:Proxima Nova Bold;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */
  color: #12171A;
  text-align: left;
  margin-bottom: 40px;
`;

export const StyledButton = styled.button`
  background: #273238;
  box-shadow: 0px 2px 14px rgba(39, 50, 56, 0.3);
  border-radius: 28px;
  color:white;
  padding: 10px 15px;
  font-family:Proxima Nova Semibold;
  width: fit-content;
  min-width: 200px;
  margin: 0 auto;
  margin-top: 30px;
`;

export const StyledUnreadNotification = styled.div`
  border-radius: 50%;
  height: 15px;
  width: 15px;
  position:absolute;
  right: 0;
  top: 0;
  background: #367FFF;
`;

export const StyledChatAvatarWrapper = styled.div`
  position:relative;
`;

export const StyledChatAvatar = styled.div`
display:flex;
align-items:center;
justify-content:flex-start;
position:relative;
`;


export const StyledChatListItemName = styled.div`
  font-family:Proxima Nova Semibold;
  font-size: 19px;
  line-height: 24px;
  color: #12171A;
`;
export const StyledChatListItemSubtitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #57717D;
`;
export const StyledChatListItemMessage = styled.div`
  color: #405159;
  font-family:Proxima Nova Semibold;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const StyledChatListItemTime = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #BACAD1;
  font-size: 12px;
  text-align:right;
  margin-top: 3px;
`;

export const StyledChatListItemDetails = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:center;
`;

export const StyledBlankChatListItem = styled.div`
  display:flex;
  border-bottom:1px solid #ebf2f5;
  padding: 30px 0;
  justify-content: center;
  text-align:center;
  margin: 0 20px;
`;

export const StyledChatListItem = styled.div`
  display:flex;
  justify-content:space-between;
  border-bottom:1px solid #ebf2f5;
  display: grid;
  padding: 15px 20px;
  grid-template-columns: minmax(0, 4fr) minmax(0,10fr) minmax(0,3fr);
  border-bottom: solid 1px #efefef;
  background-color: ${props => props.selected ? '#eaeaff' : 'transparent'};

  &:not(:first-child) {padding-top:15px;}
  &:hover{
    cursor:pointer;
  }
`; //list__chat

export const selectedCss = {
  //'transform': 'translateX(5px)',
  'backgroundColor':'#eaeaff'
}

export const StyledBlankAppointmentListItem = styled.div`
  width:100%;
  margin-bottom:0px;
`;

export const StyledAppointmentListItem = styled.div`
  width:100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding:13px 20px 0 20px;
  margin-bottom:0px;

`; //list__alert

export const StyledAppointmentListItemAvatar = styled.div`
  width: 50px; height:50px; border-radius:50px; background-color:#405159; display:flex;align-items:center;justify-content:center;
  > svg {stroke:white;}
  img {  width: 50px;  border-radius: 50px;}
`;

export const StyledAppointmentList = styled.div`
  h2 {font-size:24px;line-height:30px;font-family:Proxima Nova Semibold;;color:#12171a;margin-bottom:12px;padding-left:20px;padding-right:20px;}

  .pending{

    .showPendingButton{
      display:flex;
      padding: 20px 0;
      justify-content: center;
      color: #7a8f99;
      &:hover{
        cursor:pointer;
      }
    }
  }
  .profile {display:flex;align-items:center;margin-bottom:10px;}
  .profile > div {}
  .profile > span {font-size: 15px;line-height: 21px;color: #405159;font-family:Proxima Nova Semibold;;}
  .profile .info {margin-left:30px;display:inline-block;}
  .profile .info p {font-size:19px;font-family:Proxima Nova Semibold;;line-height:24px;color:#12171a;}
  .profile .info span {font-size:12px;line-height:18px;color:#57717d;}
  .detail {display:flex;justify-content:space-between;}
  .detail.availableNow {margin-bottom:15px;}
  .detail{
    .purpose{font-size:15px;font-family:Proxima Nova Semibold;}
    .time{
      &:hover{
        cursor: default;
      }
      font-size:15px;color:#405159;font-family:Proxima Nova Semibold;padding-left:14px;background:url('./images/bar.png') no-repeat left;
    }
    &.availableNow{
      .purpose{
        font-size:15px;line-height:unset;font-family:Proxima Nova Semibold;;
      }
      .time{
        &:hover{
          cursor: pointer;
        }

        background:#273238;line-height:28px;border-radius:5px;text-align:center;padding:3px 10px;color:#fff;
        opacity: 0.4;
        animation: ${blink} 2s linear infinite;
      }
    }

  }
  .date {font-size:12px;color:#405159;line-height:18px;font-weight:400;margin-bottom:16px;}
  .btn--wrap {display:grid;grid-template-columns:1fr 1fr;border-top: 2px solid #f0f3f5;padding:15px 0;}
  .btn--wrap button {
    width:100%;border:none;background:transparent;font-size:13px;color:#7a8f99;font-family:Proxima Nova Semibold;font-weight:bold;
    &:hover{cursor:pointer;}
  }

  .blankAppointment{
    font-family: Proxima Nova Semibold;
    font-size: 15px;
    letter-spacing: 1px;
    color: #405159;
    padding: 30px 0;
    text-align:center;
    border-bottom: 1px solid #F0F3F5;
    margin: 0 20px;
  }

  .blankScheduleAppointment{
    font-family: Proxima Nova Semibold;
    font-size: 13px;
    letter-spacing: 0.9px;
    color: #7A8F99;
    padding: 20px 0;
    text-align:center;
    &:hover{
      cursor:pointer;
    }
  }

`; //list__alert--wrap

export const StyledChatsSection = styled.div`

`; //section--list--wrap

export const StyledBlankChatConversation = styled.div`
  display:flex;
  justify-content:center;
  margin-top: 70px;
`;

export const StyledChatUI = styled.div`
  display:grid;
  grid-template-columns: 360px minmax(500px, 100%);
  column-gap: 20px;
  overflow-y:hidden;
  height: 100vh;

  ${StyledChatList} {
    width:360px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
  }

  ${StyledChatConversation} {
    flex-grow: 1;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 35px;
  }

`;

export const StyledChatWindow = styled.section`
  flex: 0 0 auto;
  flex-direction: column;
  height: calc(100%-72px-50px-40px);
  width:75%;
`;

export const StyledMessageContent = styled.div`
  max-width: 280px;
  align-items:right;
  border-radius: 30px;
  font-size: 15px;
  line-height: 21px;
  color: #12171A;
  background-color: ${props => props.senderMe ? '#273238' : '#F0F3F5'};
  color: ${props => props.senderMe ? '#FFFFFF' : '#12171A'};
  order: ${props => props.senderMe ? '2' : '1'};
  padding: 15px 30px;


`;

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding-top:10px;

  align-items:flex-end;
  text-align: left;
  margin-right: ${props => props.senderMe ? '0px' : '0px'};
  margin-right: 10px;
  margin-left: 10px;

  > .messageContent {
    display:flex;
    justify-content: ${props => props.senderMe ? 'flex-end' : 'flex-start'};
    align-items: flex-end;
    width: 100%;

    > .messageTime {
      order: ${props => props.senderMe ? '1' : '2'};
      margin: 0px 10px;
      font-size: 12px;
      color: #3E5159;
    }

    ${StyledMessageContent} {
      background-color: ${props => props.senderMe ? '#273238' : '#F0F3F5'};
      color: ${props => props.senderMe ? '#FFFFFF' : '#12171A'};
      order: ${props => props.senderMe ? '2' : '1'};
    }
  }
`;

export const StyledMessageListItemText = styled.div`
  font-size: 15px;
  font-weight: 300;
`;
export const StyledChatHeader = styled(StyledBox)`
  font-size: 24px;
  font-family:Proxima Nova Bold;
  padding: 15px;
`;


export const StyledRecordListItemTitle = styled.div`
  font-family:Proxima Nova Semibold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #12171A;
  padding: 10px 0;
`;

export const StyledRecordPreview = styled.div`

  /*width: 190px;
  height: 190px;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;*/

  display:flex;
  justify-content:flex-end;

  > img {
    border-radius: 4px;
    height: 190px;
    width: 190px;
    object-fit: cover;
    &:hover{
      cursor:pointer;
    }
  }
`;

export const StyledRecordListItem = styled.div`

`;


export const StyledRecordAttachmentFilter = styled.div`
  color: ${props => (props.selected) ? '#12171A' : '#A3B6BF'};
  flex-grow: 1;
  display:flex;
  align-items: center;
  justify-content:center;
  font-style: normal;
  font-family:Proxima Nova Semibold;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height, or 146% */
  letter-spacing: 0.9px;

  &:hover{
    cursor: ${props => (props.selected) ? 'default' : 'pointer'};
  }



  svg {
    path {stroke: ${props => (props.selected) ? '#12171A' : '#A3B6BF'};}
    margin-right: 13px;
  }
`;

export const StyledRecordAttachmentFilterActions = styled.div`
  width: 100%;
  height: 50px;
  display:flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFileAttachment = styled.div`
  margin-bottom : 10px;

   > input[type='file']{
     display:none;
   }
   svg {
     width: 40px;
   }
   &:hover{
     cursor:pointer;
     cursor: ${props => props.btnDisabled ? 'default' : 'pointer'};
   }
`;

export const StyledPloverAttachment = styled.div`
  &:hover{
    cursor:pointer;
  }
`;

export const StyledOtherAttachments = styled.div`
  display:flex;
  flex-direction: column;
  width: 190px;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 0 10px;
  > * {
    border: 1px solid #6B8A99;
    color: #4D5666;
    box-sizing: border-box;
    border-radius: 4px;
    display:flex;
    align-items:center;
    justify-content:center;
    width: 100%;
    height: 90px;

    > svg {
      margin-right: 8px;
    }

  }
`;

export const StyledRecordAttachmentList = styled.div`
  display:flex;
  overflow: scroll;
  padding-bottom: 10px;
   > * {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 10px;

   }
`;


export const StyledBlankRecordListItem = styled.div`
  height: 228px;
  display:flex;
  width: 100%;
  align-items:center;
  justify-content:center;
`;


export const StyledChatRecordAttachment = styled.div`
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  background: #FFFFFF;
  position: absolute;
  top: 0;
  overflow-x: scroll;
  width: 100%;
  margin-top: -353px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
`;

export const StyledSectionWrapper = styled(StyledContentSection)`
  width: 1200px;
  height: 800px;
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-gap: 2%;

  >div {
    position:relative
  }

  > section {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const SectionTitle = styled(StyledContentTitle)`
`; //content__title

export const SectionPage = styled(StyledContent)`
`; // content report
