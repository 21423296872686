import { constants } from './_constants';
import { MediaItem } from './_models';

const initState = {
  byId: {},
  currentSelected: null
}

const removeId = (key, {[key]: _, ...rest}) => rest;

export function mediaUploader(state=initState, action){
  switch (action.type){
    case constants.SET_CURRENTSELECTED:
      return {
        ...state,
        currentSelected: action.data
      }
    case constants.REMOVE_MEDIA:
      return {
        ...state,
        byId: Object.keys(state.byId).reduce((object, key) => {
          if (action.data.indexOf(key) == -1){
            object[key] = state.byId[key]
          }
          return object
        }, {}),
        currentSelected: null
      }
    case constants.UPDATE_MEDIA:
      return {
        ...state,
        //TODO might need to change this to not automatically select latest added
        currentSelected: action.data.id,
        byId: Object.assign({}, state.byId, {[action.data.id]: action.data.data})
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    case constants.UPLOAD_SETPROGRESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: Object.assign({}, state.byId[action.data.id], {progress: action.data.progress})
        }
      }
    case constants.UPLOAD_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: Object.assign({}, state.byId[action.data.id], {progress: 100, uploaded: true, uploadData:action.data.uploadData})
        }
      }
    case constants.UPLOAD_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: Object.assign({}, state.byId[action.data.id], {progress: 0, uploadError: true})
        }
      }
    case constants.RESET_MEDIA:
      return initState;
    default:
      return state
  }
}
