import React, { useEffect, useState } from 'react';
import { GENDERS, API_DATE_FORMAT } from '../_config';
import cn from 'classnames';

import { Button, Flex, Grid, useDisclosure,
 Modal, ModalBody, ModalContent, ModalOverlay, ModalFooter } from '@chakra-ui/react';
 import { ModalHeader } from '../Modals/ModalHeader';

import { motion } from 'framer-motion';

import { toast } from 'react-toastify';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { formatName, localeFormatDate, convertToAPIValues} from '../_helpers';
import { upperFirst, toUpper, find, map, cloneDeep } from 'lodash';

// components
import Select from 'react-select';
import { DayPickerAdapter } from '../_components/DayPickerAdapter';

// constants
import { constants as authConstants } from '../Authentication/_constants';

// actions
import { actions as authenticationActions } from '../Authentication/_actions';
import { actions as modalActions } from '../Modals/_actions';

// services
import { services as authServices } from '../Authentication/_services';
import { actions as settingsActions } from '../Settings/_actions';

// selectors 
import { getRelationships } from './_selectors';
import { 
  getIntlLocale, 
  getIntlMessages,
} from '../Authentication/_selectors';


import {
  ErrorLabel,
  errorCss,
  StyledInput,
  StyledButton
}
from '../_styles/forms';
import {
  StyledField,
  StyledForm,
  StyledLabel,
  StyledInputLayout,
} from './styles';
import { useDispatch, useSelector } from 'react-redux';

// custom select component
const settingsStyles = {
  container: (provided, state) => {
    return {
      ...provided,
      width: '100%'
    }
  },
  singleValue: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    return { ...provided, color };
  },
  control: (provided, state) => {
    const borderColor = '#C7D3D9';
    const background = '#F9F9F9';
    const fontSize = '16px';
    const borderRadius = "3px";
    const paddingLeft = '4px'
    const height = '46px';
    const width = '100%';
    return { ...provided, borderColor, background, fontSize, paddingLeft, borderRadius, height, width };
  },
  dropdownIndicator: (provided, state) => {
    const color = state.hasValue ? '#000000' : '#C7D3D9';
    return { ...provided, color };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: '#999'
    }
  },
  indicatorSeparator:  (provided, state) => {
    const backgroundColor = '#F9F9F9';
    return { ...provided, backgroundColor };
  },
  menu: (provided, state) => {
    const zIndex = 1080;
    return {...provided, zIndex}
  }
}
const SelectAdapterNonClearable = ({input, meta, items, label, type,  intlMessages , className="SelectAdapter", defaultValue, disabled, ...rest }) => {
  return(
    <div className="flex flex-col items-center w-full">
      {label && <label class="text-[18px] text-[#121719] my-1 self-start">{label}</label>}
      <Select
        {...input}
        styles={settingsStyles}
        className={
          cn({"Input_Error": (meta.error && meta.touched)
              || (meta.submitError && !meta.dirtySinceLastSubmit)})
        }
        onChange={inputValue => {input.onChange(inputValue.value)}}
        options={items}
        isDisabled={disabled}
        clearable={false}
        searchable={false}
        menuPosition={'fixed'}
        value={find(items, {'value': defaultValue || input.value})}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        {...rest}
      />
      <ErrorLabel
        className={
          cn("Error__Label",
            {"Error": (meta.error && meta.touched)
                      || (meta.submitError && !meta.dirtySinceLastSubmit)} )
      } >
        {(meta.error && meta.touched
          || meta.submitError && !meta.dirtySinceLastSubmit)
          && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
      </ErrorLabel>
    </div>
  )
}


const RelationshipSelect = ({input, meta, type , className="SelectAdapter", defaultValue, disabled, ...rest }) => {

  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const relationships = useSelector(getRelationships);

  useEffect(() => {
    dispatch(settingsActions.fetchAppSettings('relationships'))
  }, [])

  let options = relationships.map(x=>({value: x, label: intlMessages[`profiles.relationship.${x}`]}))

  return(
    <div className={'flex flex-col w-full'}>
      <Select
        {...input}
        styles={settingsStyles}
        className={
          cn({"Input_Error": (meta.error && meta.touched)
              || (meta.submitError && !meta.dirtySinceLastSubmit)})
        }
        onChange={inputValue => {input.onChange(inputValue.value)}}
        options={options}
        isDisabled={disabled}
        clearable={false}
        searchable={false}
        value={find(options, {'value': defaultValue || input.value})}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        {...rest}
      />
      <ErrorLabel
        className={
          cn("Error__Label",
            {"Error": (meta.error && meta.touched)
                      || (meta.submitError && !meta.dirtySinceLastSubmit)} )
      } >
        {(meta.error && meta.touched
          || meta.submitError && !meta.dirtySinceLastSubmit)
          && intlMessages[`form.error.${(meta.error || meta.submitError)}`]}
      </ErrorLabel>
    </div>
  )
}

export const EditProfileForm = ({profileId, onCancel, onDelete, onSuccess}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [profile, setProfile] = useState({date_of_birth: ""});
  const [ isError, setIsError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  

  useEffect(()=> {
    // on component mount, fetch the current profile given by profileId
    async function f(id){
      setIsLoading(true);
      setIsError(false);
      try{
        const resp = await authServices.getProfilesWithId(id);
        setProfile(resp);
        setIsLoading(false);
      } catch (err){
        setIsError(true);
        setIsLoading(false);
      }
    }
    if (profileId){
      f(profileId);
    }
    return () => {};
  }, [])

  const deleteProfile = () => {
    authServices.removeProfile(profileId)
      .then(
        resp => {
          setIsError(false);
          setIsLoading(false);
          onDelete();
          return {}
        },
        error => {
          setIsError(true);
          setIsLoading(false);
          return {}
        }
      );
  }

  const postDelete = async () => {
      toast.success(intlMessages['settings.details.personalProfile.form.success.message'],
        {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      )
  }

  const onDeleteProfile = async () => {
    // pop up of delete confirmation
    onOpen();
    /**let negativeCallbacks = [modalActions.closeModal('confirmDialog')]
    let positiveCallbacks = [
      authenticationActions.removeProfile(profileId),
      modalActions.closeModal('confirmDialog', [onDelete])
    ]
    setIsLoading(true);
    dispatch(modalActions.openConfirmDialog(
      intlMessages['settings.details.profilesList.confirmdialog.remove.header'],
      intlMessages['settings.details.profilesList.confirmdialog.remove.description'],
      negativeCallbacks,
      positiveCallbacks,
      intlMessages['scanningpage.media.loseprogress.close.button.confirm'],
    ))*/

  }

  const onDeleteClick = async () => {
    dispatch(authenticationActions.removeProfile(profileId));
    onClose();
    onCancel();
  }

  const editProfile = async (values) => {
    const valuesCopy = cloneDeep(values);
    if (valuesCopy.date_of_birth == ""){
      valuesCopy.date_of_birth = null;
    } else {
      valuesCopy.date_of_birth =  localeFormatDate(valuesCopy.date_of_birth, intlLocale, API_DATE_FORMAT)
    }
    const convertedValues = convertToAPIValues(valuesCopy)
    try{
      const resp = await authServices.updateProfileWithId(profileId, convertedValues);
      dispatch({type: authConstants.UPDATE_PROFILE, data: resp});
      onSuccess(resp);    
      onCancel();
      return null;
    } catch (error) {
      let newErrors = {}
      return {
        ...error,
        [FORM_ERROR]: intlMessages['form.error.general_error']
      };
    }
  }

  return (
    <div class="flex flex-col w-full m-[4px]">
      <Modal isOpen={isOpen} isCentered size={["sm"]}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader title={intlMessages['settings.details.profilesList.confirmdialog.remove.header']} />
          <ModalBody py={['30px']} px={['40px']}>
            {intlMessages['settings.details.profilesList.confirmdialog.remove.description']}
          </ModalBody>
          <ModalFooter px={['30px']} pb={['30px']}>
            <Flex w={['full']} justify={'space-between'}>
              <Button variant="outlineDefault" w={['full']} onClick={onClose} mr={'10px'}>
                {intlMessages['cancel']}
              </Button>
              <Button onClick={onDeleteClick}  w={['full']} ml={'10px'}>
                {intlMessages['scanningpage.media.loseprogress.close.button.confirm']}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Form
         onSubmit={editProfile}
         initialValues = {{
           ...profile, 
           date_of_birth: profile.date_of_birth ? localeFormatDate(profile.date_of_birth, intlLocale, "l") : ""
         }}
         render = {({
           submitError,
           formError,
           handleSubmit,
           mutators,
           form,
           reset,
           submitting,
           pristine,
           validating,
           values
         }) => (
           <form
              class="w-full self-center"
              onSubmit={handleSubmit}
            >
              <div class="flex flex-col w-full">
                <label class="text-[18px] text-bdBlack my-1 self-start">
                  {upperFirst(intlMessages['profiles.form.relationships.label'])}
                </label>
                <Field
                  name="relationship"
                  type='settings'
                  intlMessages={intlMessages}
                  disabled={(submitting) ? true : false}
                  component={RelationshipSelect}
                  placeholder={upperFirst(intlMessages['profiles.form.relationships.label'])}
                />
              </div>
              <StyledField name="first_name" parse={v => v}>
                 {({ input, meta}) => (
                   <StyledInputLayout>
                     <label class="text-[18px] text-bdBlack my-1 self-start">
                       {upperFirst(intlMessages['settings.details.personalProfile.form.first_name.label'])}
                     </label>
                     <StyledInput
                       {...input}
                       type="text"
                       addCSS={
                         ((meta.error && meta.touched)
                          || (meta.submitError && !meta.dirtySinceLastSubmit)
                          )
                          && errorCss
                        }
                       placeholder={upperFirst(intlMessages['settings.details.personalProfile.form.first_name.label'])} />
                     <ErrorLabel
                       addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                       >
                         {(
                           (meta.error && meta.touched)
                           || (meta.submitError && !meta.dirtySinceLastSubmit)
                          )
                          && upperFirst(intlMessages['settings.details.personalProfile.form.first_name.error'])
                            || meta.submitError
                         }
                     </ErrorLabel>
                   </StyledInputLayout>
                 )}
               </StyledField>
            
                 <div className="flex w-full mr-1">

                  </div>
                <div class="flex flex-col mb-4">

                  <Grid w={'full'} templateColumns={["1fr 1fr"]} gridGap={['20px']} mt={['10px']}>
                    <Button 
                      variant="outlineDefault"
                      onClick={() => onCancel()}
                    >
                      {toUpper(intlMessages['profiles.form.cancel'])}
                    </Button>
                    <Button 
                      variant="solid"
                      type={"submit"}
                      disabled={submitting || pristine} 
                    >
                      {toUpper(intlMessages['profiles.form.save'])}
                    </Button>
                  </Grid>
                  {
                    (!profile.is_default) && (
                      <Flex w={['full']} mt={['20px']}>
                        <Button
                          variant={"outline"}
                          w={['full']}
                          colorScheme={"red"}
                          onClick={() => onDeleteProfile()}
                        >
                          {toUpper(intlMessages['profiles.form.delete'])}
                        </Button>
                      </Flex>
                    )
                  }
                </div>
           </form>
         )}
         />
    </div>
  )

}

export const ProfileForm = ({onCancel, onSuccess}) => {
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const intlLocale = useSelector(getIntlLocale);

  const createProfile = async (values) => {
    const valuesCopy = cloneDeep(values);
    const convertedValues = convertToAPIValues(valuesCopy)
    console.log("convertedValues ", convertedValues)
    if (convertedValues.date_of_birth == ""){
      convertedValues.date_of_birth = null;
    }
    try {
      const resp = await authServices.createProfile(convertedValues);
      dispatch({type: authConstants.ADD_PROFILE, data: resp});
      onSuccess(resp);
      onCancel();
      return null;
    } catch (error) {
      let newErrors = {}
      return {
        ...error,
        [FORM_ERROR]: intlMessages['form.error.general_error']
      };
    }
  }

  const initValues = {
      date_of_birth: "",
  }

  return (
      <div class="flex flex-col m-[4px]">
        <Form
           onSubmit={createProfile}
           initialValues = {initValues}
           render = {({
             submitError,
             formError,
             handleSubmit,
             mutators,
             form,
             reset,
             submitting,
             pristine,
             validating,
             values
           }) => (
             <form
                class="w-full self-center"
                onSubmit={handleSubmit}
              >
                <div class="flex flex-col w-full">
                  <label class="text-[18px] text-bdBlack my-1 self-start">
                    {upperFirst(intlMessages['profiles.form.relationships.label'])}
                  </label>
                  <Field
                    name="relationship"
                    type='settings'
                    intlMessages={intlMessages}
                    disabled={(submitting) ? true : false}
                    component={RelationshipSelect}
                    placeholder={upperFirst(intlMessages['profiles.form.relationships.label'])}
                  />
                </div>
                <StyledField name="first_name" parse={v => v}>
                   {({ input, meta}) => (
                     <StyledInputLayout>
                       <label class="text-[18px] text-bdBlack my-1 self-start">
                         {upperFirst(intlMessages['settings.details.personalProfile.form.first_name.label'])}
                       </label>
                       <StyledInput
                         {...input}
                         type="text"
                         addCSS={
                           ((meta.error && meta.touched)
                            || (meta.submitError && !meta.dirtySinceLastSubmit)
                            )
                            && errorCss
                          }
                         placeholder={upperFirst(intlMessages['settings.details.personalProfile.form.first_name.label'])} />
                       <ErrorLabel
                         addCSS={((meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)) && errorCss}
                         >
                           {(
                             (meta.error && meta.touched)
                             || (meta.submitError && !meta.dirtySinceLastSubmit)
                            )
                            && upperFirst(intlMessages['settings.details.personalProfile.form.first_name.error'])
                              || meta.submitError
                           }
                       </ErrorLabel>
                     </StyledInputLayout>
                   )}
                 </StyledField>
                   <div className="flex w-full mr-1">
  
                    </div>
                  <div class="flex flex-row justify-between mb-4">

                    <Grid w={'full'} templateColumns={["1fr 1fr"]} gridGap={['20px']} mt={['10px']}>
                      <Button 
                        variant="outlineDefault"
                        onClick={() => onCancel()}
                      >
                        {toUpper(intlMessages['profiles.form.cancel'])}
                      </Button>
                      <Button 
                        variant="solid"
                        type={"submit"}
                        disabled={submitting || pristine} 
                      >
                        {toUpper(intlMessages['profiles.form.save'])}
                      </Button>
                    </Grid>
                  </div>
             </form>
           )}
           />
      </div>
  )
}