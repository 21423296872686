import { API_URLS } from '../_config';

import { authHeader, handleResponse } from '../_helpers';

export const services = {
  createCheckupRequest,
  fetchPaymentPrices,
  fetchUserMemberships,
  createGenerativeCheckupRequest,
  createOmisePayment
}

/*
1. Show Checkup Form.
  a. ask additional questions here, select re
2. Show summary with Checkup request button (payment stuff)
3. Create Checkup Request
4. If client_secret is null, then
 */

function createOmisePayment(data){
  const requestUrl = API_URLS.createOmisePayments;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function createGenerativeCheckupRequest(data){
  const requestUrl = API_URLS.createRequestGenerative;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function createCheckupRequest(data){
  const requestUrl = API_URLS.createCheckupRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchPaymentPrices() {
  const requestUrl = API_URLS.fetchPaymentPrices;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchUserMemberships() {
  const requestUrl = API_URLS.fetchUserMemberships;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}
