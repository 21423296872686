import { RECORD_TYPES } from '../_config';
import React, { useRef, useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import ReactPlayer from 'react-player';

import { getScanType } from '../PloverScan/_selectors';
import { getCurrentSelected, getMediaList,
        getMediaByRegion1, getMediaByRegion2,
        getMediaByRegion3, getMediaByRegion4} from './_selectors';

import { actions } from './_actions';

import { FaTimes } from 'react-icons/fa';

// actions
import { actions as fullScanActions } from '../FullScanChart/_actions';

import {
  StyledMediaItem, StyledMediaList, BlankMediaItem, RemoveButton, BlankDiv
} from './styles';

const MediaItem = ({item, selected, uploaded, label, scanType}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (item){
      dispatch(actions.setCurrentItem(item.getId()));
      if (scanType == RECORD_TYPES.FULLSCAN){
        // set region to whatever region
        dispatch(fullScanActions.gotoRegion(label));
      }
    }
    //dispatch(mediaActions.uploadMedia(item, 14))
  }

  const handleRemove = () => {
    if (item){
      dispatch(actions.removeItem(item.getId()));
      if (scanType == RECORD_TYPES.FULLSCAN){
        // set region to whatever region
        dispatch(fullScanActions.redoRegion());
      }
    }
  }

  if (!item){
    if (label > -1){
    return (
      <BlankMediaItem>
        <BlankDiv>{label + 1}</BlankDiv>
      </BlankMediaItem>
    )
    } else {
    return (
      <BlankMediaItem>
        <BlankDiv></BlankDiv>
      </BlankMediaItem>
    )
    }
  } else if (item.getMimeType().startsWith("video")){
    return (
      <StyledMediaItem
        selected={selected}
        uploaded={uploaded}
        onClick={handleClick}
        >
        {(selected && !(scanType == RECORD_TYPES.FULLSCAN)) && <RemoveButton onClick={handleRemove}><FaTimes /></RemoveButton>}
        <video
            src={item.getUrl()}
          />
      </StyledMediaItem>
    )
  } else if(item.getMimeType().startsWith("image") ) { // should be image
    return (
      <StyledMediaItem
        selected={selected}
        uploaded={uploaded}
        onClick={handleClick}
        >
        {(selected && !(scanType == RECORD_TYPES.FULLSCAN))  && <RemoveButton onClick={handleRemove}><FaTimes /></RemoveButton>}
        <img
            src={item.getUrl()}
          />
      </StyledMediaItem>
    )
  } else {
    return null;
  }
}

export const MediaList = () => {
  const currentSelected = useSelector(getCurrentSelected);
  const mediaList = useSelector(getMediaList);
  const mediaByRegion1 = useSelector(getMediaByRegion1);
  const mediaByRegion2 = useSelector(getMediaByRegion2);
  const mediaByRegion3 = useSelector(getMediaByRegion3);
  const mediaByRegion4 = useSelector(getMediaByRegion4);
  const scanType = useSelector(getScanType);
  if (scanType == RECORD_TYPES.FULLSCAN){
    return (
      <StyledMediaList className={scanType}>
        <MediaItem
          label={0}
          scanType={scanType}
          item={(mediaByRegion1.length > 0) ? mediaByRegion1[0] : null}
          selected={(mediaByRegion1.length > 0) ? mediaByRegion1[0].getId() == currentSelected : false}
          />
        <MediaItem
          label={1}
          scanType={scanType}
          item={(mediaByRegion2.length > 0) ? mediaByRegion2[0] : null}
          selected={(mediaByRegion2.length > 0) ? mediaByRegion2[0].getId() == currentSelected : false}
          />
        <MediaItem
          label={2}
          scanType={scanType}
          item={(mediaByRegion3.length > 0) ? mediaByRegion3[0] : null}
          selected={(mediaByRegion3.length > 0) ? mediaByRegion3[0].getId() == currentSelected : false}
          />
        <MediaItem
          label={3}
          scanType={scanType}
          item={(mediaByRegion4.length > 0) ? mediaByRegion4[0] : null}
          selected={(mediaByRegion4.length > 0) ? mediaByRegion4[0].getId() == currentSelected : false}
          />
      </StyledMediaList>
    )
  } else if (scanType == RECORD_TYPES.SELF) {
    return (
      <StyledMediaList  className={scanType}>
        { mediaList.map((item, index) =>
          <MediaItem
            key={item.getId()}
            item={item}
            selected={item.getId() == currentSelected}
          />
        )}
        {
          [...Array(28 - mediaList.length ).keys()].map((item, index) =>
            <MediaItem
              key={"media"+item}
              item={null}
              selected={false}
            />
        )}
      </StyledMediaList>
    )
  } else {
    return (
      <StyledMediaList>

      </StyledMediaList>
    )
  }

}
