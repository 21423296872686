import { CategorytDot } from "../CategoryDot"
import { CategoryTitle } from "../CategoryTitle"
import { useReportStore } from "../state/report"
import { REPORTCHART_TYPES } from '../../_config';
import { FormattedMessage } from 'react-intl';
import { 
  Text,
  Flex,
  Grid,
} from '@chakra-ui/react';

import { ToothSetModern } from '../../_components/ToothSetModern';

const CategoryItem = ({category}) => {
    return (
      <Flex
        borderRadius={['30px']}
        align={'center'}
        bg={'#fff'}
        pr={['10px']}
        pl={['5px']}
        py={['4px']}
      >
        <CategorytDot category={category} width={['10px']} height={['10px']} marginRight={['8px']} />
        <Text color={'#405159'} fontSize={['12px']} textTransform={'upperCase'}>
          <CategoryTitle  category={category} />
        </Text>
      </Flex> 
    )
  }
  
export const Categories = ({isHidden=false}) => {
    return (
      <Grid visibility={isHidden ? 'invisible' : 'visible'} gridTemplateColumns={['1fr 1fr 1fr']} gridTemplateRows={['1f 1fr 1fr']} justify={['center']} w={['full']} px={['15px']} py={['15px']}>
        {REPORTCHART_TYPES.map((category) => <CategoryItem key={category.value} category={category.value} />)}
        <CategoryItem key={'multiple'} category={'MULTIPLE'} />
      </Grid>
    )
}

export const ToothChart = ({data}) => {

    const { category } = useReportStore();

    const toothClasses = {};
    const categoriesCount = {};

    // go through the block data and convert it to tooth charting object
    // object with key of tooth number "txx" and value of category in all caps for class
    (data?.blocks || []).map((block) => {
        const data = block?.data;
        if (category != null){
          // check that the category matches the selected category
          if (data.category.toUpperCase() == category.toUpperCase()){
            data.regions.map((region) => {
              if (region.length > 0){
                // will overwrite any previous block styles
                if (toothClasses[`t${region}`]){
                    toothClasses[`t${region}`].push(block.data.category.toUpperCase())
                } else {
                    toothClasses[`t${region}`] = [block.data.category.toUpperCase()]
                }
              }
            })
          }
        } else {
          // if category is null, then add all categories
          data.regions.map((region) => {
            if (region.length > 0){
              // will overwrite any previous block styles
              if (toothClasses[`t${region}`]){
                  toothClasses[`t${region}`].push(block.data.category.toUpperCase())
              } else {
                  toothClasses[`t${region}`] = [block.data.category.toUpperCase()]
              }
            }
          })
        }
        categoriesCount[data.category.toUpperCase()] = (categoriesCount[data.category.toUpperCase()] || 0) + 1
        
    })

    return( 
        <Flex 
          justify={'center'} 
          background={'#fff'}
        >
          <Flex align={['center']}>
            <Text mr={['5px']}>
              <FormattedMessage
                id={'reportDetailsPage.reviewReportModal.toothChart.right'}
                defaultMessage={'R'}
                />
            </Text>
          </Flex>
          <ToothSetModern
            toothClasses={toothClasses}
            width={190}
            height={360}
          />
          <Flex align={['center']}>
            <Text ml={['5px']}>
              <FormattedMessage
                id={'reportDetailsPage.reviewReportModal.toothChart.left'}
                defaultMessage={'L'}
                />
            </Text>
          </Flex>
        </Flex>
    )
  }