import { constants } from './_constants';
import { RECORD_TYPES } from '../_config'

export const actions = {
  toggleScanType,
  updateState,
  updateStateObject,
  setScanType
}

function setScanType(scanType){
  return (dispatch) => {
      dispatch(update(scanType))
  }
  function update(scanType){
    return { type: constants.UPDATE_SCANTYPE, data: {scanType}}
  }
}

function toggleScanType(scanType){
  return (dispatch) => {
    if (scanType == RECORD_TYPES.FULLSCAN){
      dispatch(update(RECORD_TYPES.SELF))
    } else if (scanType == RECORD_TYPES.SELF){
      dispatch(update(RECORD_TYPES.FULLSCAN))
    }
  }
  function update(scanType){
    return { type: constants.UPDATE_SCANTYPE, data: {scanType}}
  }
}

function updateState(key, value){
  return (dispatch) => {
    dispatch(update(key, value));
  }
  function update(key, value){
    return { type: constants.UPDATE_STATE, data: {key, value}}
  }
}

function updateStateObject(key, value){
  return (dispatch) => {
    dispatch(update(key, value));
  }
  function update(key, value){
    return { type: constants.UPDATE_STATE_OBJECT, data: {key, value}}
  }
}
