import { constants } from './_constants';
import { FULLSCAN_STATES } from '../_config';
// state: START, END, SCANNING


const initState = {
  currentRegion: 0,
  currentState: FULLSCAN_STATES.START,
}

export function fullScanChart(state=initState, action){
  switch (action.type){
    case constants.UPDATE:
      return {
        ...state,
        ...action.data
      }
    case constants.UPDATE_STATE:
      return {
        ...state,
        [action.data.key]: action.data.value
      }
    case constants.UPDATE_STATE_OBJECT:
      return {
        ...state,
        [action.data.key]: Object.assign({}, state[action.data.key], action.data.value)
      }
    default:
      return state
  }
}
