import { Flex } from '@chakra-ui/react';

export const ModalHeader = ({title, zIndex=3, bg='#F7F7F7', props}) => {
    return (
      <Flex
        w={'full'}
        h ={'70px'}
        alignItems={'center'}
        bg={bg}
        justifyContent={'center'}
        borderTopRadius={'10px'}
        boxShadow={'0px -2px 15px rgba(0, 0, 0, 0.08)'}
        filter={'drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.08))'}
        color={'#12171A'}
        fontSize={'18px'}
        textTransform={'uppercase'}
        letterSpacing={'1px'}
        zIndex={zIndex}
        {...props}
      >
        {title}
      </Flex>
    )
};