export function calculateSelectWidth (input, locale='en', addLength=null, emptyWidth=220){
    let defaultLength = 8
    switch (locale) {
        case 'th':
        case 'th-TH':
            defaultLength = 10
            break
        case 'en':
        case 'en-US':
        default:
            defaultLength = 8
            break
        }

    let additionalLength = addLength ? addLength : defaultLength* 10;
    if (input){
        return defaultLength*input.length+additionalLength
    } else {
        return emptyWidth
    }
  }