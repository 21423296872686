import { Flex, Box } from "@chakra-ui/react";

export function normalizeRating(reviewCounts) {
    // rating is in format {"1": 10, "2": 4, "3": 1, "4": 19, "5": 35}
    // Find the maximum count
    let maxCount = Math.max(...Object.values(reviewCounts));

    // Normalize the counts
    for (let rating in reviewCounts) {
        let count = reviewCounts[rating];
        let normalizedCount = (maxCount !== 0) ? (count / maxCount) * 100 : 0;
        reviewCounts[rating] = normalizedCount;
    }
    return reviewCounts;
}

export function getRatingAverage(reviewCounts){
    let totalReviews = 0;
    let totalScore = 0;

    for (let rating in reviewCounts) {
        totalScore += rating * reviewCounts[rating];
        totalReviews += reviewCounts[rating];
    }

    let avg = totalReviews ? totalScore / totalReviews : 0
    return avg.toFixed(1);
}


export function getRatingCount(reviewCounts){
    let totalReviews = 0;


    for (let rating in reviewCounts) {
        totalReviews += reviewCounts[rating];
    }

    return totalReviews;
}

export function getReviewSummary(reviewCounts){
    // rating is in format {"1": 10, "2": 4, "3": 1, "4": 19, "5": 35}

    // Find the maximum count
    let maxCount = Math.max(...Object.values(reviewCounts));
    let normalizedCounts = {"1": 0, "2": 0, "3": 0, "4": 0, "5": 0};

    // intialize totalReviews and totalScore
    let totalReviews = 0;
    let totalScore = 0;

    // Normalize the counts
    for (let rating in reviewCounts) {
        // update rating scores and totals
        totalScore += rating * reviewCounts[rating];
        totalReviews += reviewCounts[rating];

        // normalize the counts
        let count = reviewCounts[rating];
        let normalizedCount = (maxCount !== 0) ? (count / maxCount) * 100 : 0;
        normalizedCounts[rating] = normalizedCount;
    }

    let avg = totalReviews ? totalScore / totalReviews : 0

    return {
        reviewCounts: normalizedCounts, 
        averageRating: avg.toFixed(1), 
        totalReviews: totalReviews
    };
}

export const ReviewBar = ({percent=100}) => {
    return (
        <Flex w={'full'} align={'center'}>
            <Box bg={'#273238'} h={'3px'} w={`${percent}%`}></Box>
        </Flex>
    )
}