import { constants } from './_constants';
import { services } from './_services';
export const actions = {
  fetchChatUserInformation,
  fetchLatestCheckupWithDentist,
  checkDentistChatStatus
}

function fetchChatUserInformation(id, type){
  return (dispatch) => {
    services.fetchChatUserInformation(id, type)
      .then(
        data => dispatch(success(id, data)),
        error => dispatch(failure(id, error))
      );

  }
  function failure(id, error) { return { type: constants.UPDATE_DENTISTS, data: { id, data: {error}}};}
  function success(id, data) { return { type: constants.UPDATE_DENTISTS, data: { id, data }};}
}
function fetchLatestCheckupWithDentist(id, type){
  return (dispatch) => {
    if (id){
    services.fetchLatestCheckupWithDentist(id, type)
      .then(
        data => dispatch(success(id, data)),
        error => dispatch(failure(id, error))
      );
    }
  }
  function failure(id, error) { return { type: constants.UPDATE_DENTISTS_LATEST_CHECKUP, data: { id, data: {latestRequest: {error}}}};}
  function success(id, successData) { return { type: constants.UPDATE_DENTISTS_LATEST_CHECKUP, data: { id, data: {latestRequest: successData} }};}
}

function checkDentistChatStatus(id, type){
  return (dispatch) => {
    if (id){
      dispatch(request());
      services.fetchLatestCheckupWithDentist(id, type, true)
        .then(
          data => dispatch(success(id, data)),
          error => dispatch(failure(id, error))
        );
    }
  }
  function request(){ return { type: constants.UPDATE_DENTISTS_LATEST_CHECKUP_REQUEST, data: { id }}}
  function failure(id, error) { return { type: constants.UPDATE_DENTISTS_LATEST_CHECKUP, data: { id, data: {latestExpired: {error}}}};}
  function success(id, successData) { return { type: constants.UPDATE_DENTISTS_LATEST_CHECKUP, data: { id, data: {latestExpired: successData} }};}
}
