function createPubnubMessageTextPayload(pn, message, conversationId, sender){
  const secureData = pn.api.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY)

  const fcm_notif = {
    title: "Message from Patient",
    body: "Open to check your messages",
    click_action: "OPEN_CHAT_NOTIF"
  }
  const fcm_data = {
    pn_channel: "CHAT",
    pn_type: "text",
    pn_channelId: conversationId,
    pn_senderId: sender
  }
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data
    }
  }
}

function createPubnubMessageRecordPayload(pn, message, conversationId){
  const secureData = pn.api.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY)

  const fcm_notif = {
    title: "Message from Patient",
    body: "Open to check your messages",
    click_action: "OPEN_CHAT_NOTIF"
  }
  const fcm_data = {
    pn_channel: "RECORD",
    pn_type: "record",
    pn_channelId: conversationId
  }
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data
    }
  }
}

function createPubnubMessageReportPayload(pn, message, reportId){
  const secureData = pn.api.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY)

  const fcm_notif = {
    title: "Report has been Sent",
    body: "Open to check your report",
    click_action: "OPEN_REPORT_NOTIF"
  }
  const fcm_data = {
    pn_channel: "REPORT",
    pn_type: "report",
    pn_reportId: reportId
  }
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data
    }
  }
}

function createPubnubMessageVideoChatPayload(pn, message, conversationId){
  message.type = "videochat"
  const secureData = pn.api.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY)

  const fcm_notif = {
    title: "Video Chat has started",
    body: "Go to video chat",
    click_action: "OPEN_VIDEOCHAT_NOTIF"
  }
  const fcm_data = {
    pn_channel: "VIDEOCHAT",
    pn_type: "videochat",
    pn_channelId: conversationId
  }
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data
    }
  }
}

function createPubnubMessagePartialScanPayload(pn, message, conversationId){
  message.type = "record_partialscan"
  const secureData = pn.api.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY)

  const fcm_notif = {
    title: "Partial Scan Completed",
    body: "Open to check partial scan",
    click_action: "OPEN_PARTIALSCAN_NOTIF"
  }
  const fcm_data = {
    pn_channel: "PARTIAL_SCAN",
    pn_type: "partial_scan",
    pn_channelId: conversationId
  }
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data
    }
  }
}

function endPubnubMessageVideoChatPayload(pn, message, conversationId){
  message.type = "videochat_end"
  const secureData = pn.api.encrypt(JSON.stringify(message), process.env.REACT_APP_PUBNUB_CIPHER_KEY)

  const fcm_notif = {
    title: "Video Chat has ended",
    body: "The dentist has ended the video chat",
    click_action: "OPEN_VIDEOCHAT_NOTIF"
  }
  const fcm_data = {
    pn_channel: "VIDEOCHAT",
    pn_type: "videochat_end",
    pn_channelId: conversationId
  }
  return {
    data: secureData,
    pn_gcm: {
      notification: fcm_notif,
      data: fcm_data
    }
  }
}

function extractChannelType(channelId){
  if (channelId.startsWith("PD1t1_")){
    return "DENTIST";
  } else if (channelId.startsWith("clinicalsupport.")){
    return "CLINICALSUPPORT";
  } else {
    return null;
  }
}

function checkSenderIsUser(uid, senderId, channelId){
  if (channelId.startsWith("PD1t1_")){
    var [dentist, patient] = channelId.split(".");
    return senderId == uid || senderId == patient;
  }
  return false;
}

function getNotificationChannelName(uid){
  return `notifications.${uid}`;
}

function extractPatientUuidFromChannelId(channelId){
  if (channelId.startsWith("PD1t1_")){
    var [dentist, patient] = channelId.split(".");
    return patient;
  } else if (channelId.startsWith("clinicalsupport.")){
    var [support, patient] = channelId.split(".");
    return patient;
  } else {
    return null;
  }
}

function extractDentistUuidFromChannelId(channelId){
  if (channelId.startsWith("PD1t1_")){
    var [dentist, patient] = channelId.replace("PD1t1_", "").split(".");
    return dentist;
  }
  return null;
}

function getMarkNotificationReadAction(uid){
  // create the data to send to pubnub addMessageAction for read notifications/messages
  return {
    type: 'read_status',
    value: 'read'
  }
}

/**
 * checks notification to see if it has been read
 * @param  {PNMessagePayload} notif message payload from pubnub
 * @param  {string} uid   users unique_id
 * @return {boolean}      returns if notification is read
 */
function checkNotificationRead(notif, uid){
  // if no notification, then no notification to be read
  if (notif){
    if (notif.data && notif.data.read_status && notif.data.read_status.read){
      return notif.data.read_status.read.filter((elem) => elem.uuid == uid).length > 0;
    } else {
      // notification is present, but no read message action
      return false;
    }
  } return true;
}

function checkNotificationArrayRead(notifs, uid){
  // notifs should be an array, if no notifs, then no notifications to read
  if (!notifs || notifs.length == 0){
    return true;
  }
  let res = true;
  // go through each notification tied to request. if any are unread,
  // res ends up being false, so there are unread notifications for the request
  notifs.map( (notif) => {
    if (notif){
      if (notif.data && notif.data.read_status && notif.data.read_status.read){
        res = res && notif.data.read_status.read.filter((elem) => elem.uuid == uid).length > 0;
      } else {
        res = res && false;
      }
    }else {
      res = res && true;
    }
  })
  return res;
}
function decryptMessage(pn, entry, cipherKey){
  if (typeof entry.message == "string"){
    return pn.decrypt(entry.message, cipherKey)
  } else if (entry.message.data){
    return pn.decrypt(entry.message.data, cipherKey)
  } else {
    return entry.message;
  }
}


const unminifySignalType = {ma: {s: 'status'}}
const unminifySignalValue = {ma: {c: 'completed'}}
// unminify signal message. 
// reducer checks
function unminifySignalMessage(signal){
  // for reference. signal.message.t = type, signal.message.d = data, data.t = type, data.v = value
  return {
    type: unminifySignalType[signal.message.t][signal.message.d.t],
    value: unminifySignalValue[signal.message.t][signal.message.d.v]
  }
}

export {
  createPubnubMessageTextPayload,
  createPubnubMessageRecordPayload,
  createPubnubMessageReportPayload,
  endPubnubMessageVideoChatPayload,
  createPubnubMessagePartialScanPayload,
  createPubnubMessageVideoChatPayload,
  extractDentistUuidFromChannelId,
  extractPatientUuidFromChannelId,
  extractChannelType,
  checkSenderIsUser,
  decryptMessage,
  getNotificationChannelName,
  getMarkNotificationReadAction,
  checkNotificationRead,
  checkNotificationArrayRead,
  unminifySignalMessage
 }
