import React from 'react';
import Modal from 'react-modal';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { ScanningPage } from '../ScanningPage';
import { RecordDetail, RecordDetectionDetail } from '../Records';
import { PloverScan } from '../PloverScan';
//import { VideoChat } from '../VideoChats';
import { PartialScan } from '../PartialScans';
import { DetectionScan } from '../Detection';
import { ProfileList } from '../Settings/ProfileList';
import { FirebaseNotifications, APNNotifications, SafariVersion } from '../BrowserNotifications';
import { CCardForm } from '../_components/CCardForm/CCardForm';
import { SwitchLanguage } from '../_components/SwitchLanguage/SwitchLanguage';

import { MessageContent } from './Message';
import { HowToContent } from './HowToContent';
import { ErrorContent } from './Error';
import { LoadingContent } from './Loading';

import { actions as chatActions } from '../Chats/_actions';
import { actions as mediaActions } from '../MediaUploader/_actions';
import { actions as recordActions } from '../Records/_actions';
import { actions } from './_actions';

import { getIntlMessages } from '../Authentication/_selectors';
import { getUUID, getCurrentConversationId } from '../Chats/_selectors';
import { getCurrentPartialScanId, getPartialScanMessageTimeToken } from '../PartialScans/_selectors';

import {
  getLoadingModal,
  getScanningPageModal,
  getRecordDetailModal,
  getAttachmentPloverScanModal,
  getVideoChatModal,
  getPartialScanModal,
  getLoadingModalText,
  getCardFormModal,
  getErrorModal,
  getErrorModalText,
  getMessageModal,
  getMessageModalText,
  getFirebaseNotificationsModal,
  getApnNotificationsModal,
  getSafariVersionModal,
  getSwitchLanguageModal,
  getUploadDetectionModal,
  getDetectionScanModal,
  getProfileListModal,
  getHowToModal,
  getHowToModalContent,
  getConfirmDialogModal,
  getConfirmDialogHeader,
  getConfirmDialogText,
  getConfirmDialogNegativeCallback,
  getConfirmDialogPositiveCallback,
  getConfirmDialogPositiveLabel
} from './_selectors';

import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';
import { FaTimes } from 'react-icons/fa';

import {
  CloseIcon,
  StyledLoadingContent,
  StyledLoadingText,
  StyledHowToContent
} from './styles';
import { GenerativeReportConfirmation } from '../Reports/GenerativeReportConfirmation';

const generalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  'fit-content',
      outline: 'none',
      padding: '0',
      position: 'initial',
      borderRadius: '10px', 
      zIndex: 10
    }
}
const loadingStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 11,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  'fit-content',
      outline: 'none',
      padding: '0',
      position: 'initial',
      borderRadius: '10px', 
      zIndex: 11
    }
}
const messageStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 99,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  'fit-content',
      outline: 'none',
      padding: '0',
      position: 'initial',
      borderRadius: '10px', 
      zIndex: 13
    }
}
const howToStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  'fit-content',
      outline: 'none',
      padding: '0',
      position: 'initial',
      borderRadius: '10px', 
      zIndex: 12,
      maxHeight: '80vh'
    }
}
const cCardFormStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 11,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    width: 'fit-content',
    height:  'fit-content',
    outline: 'none',
    padding: '0',
    position: 'initial',
    borderRadius: '10px', 
    zIndex: 11
  }
}

const ploverScanningStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    width: 'fit-content',
    height:  'fit-content',
    outline: 'none',
    padding: '0',
    position: 'initial',
    borderRadius: '10px', 
    zIndex: 10
  }
};

const videoChatStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    width: 'fit-content',
    height:  'fit-content',
    outline: 'none',
    padding: '0',
    position: 'initial',
    borderRadius: '10px', 
    zIndex: 10
  }
};
const recordStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    width: 'fit-content',
    height:  'fit-content',
    outline: 'none',
    padding: '0',
    position: 'initial',
    borderRadius: '10px', 
    zIndex: 10
  }
};

const attachmentPloverScanStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    width: 'fit-content',
    height:  'fit-content',
    outline: 'none',
    padding: '0',
    position: 'initial',
    borderRadius: '10px', 
    zIndex: 10
  }
};

export const LoadingModal  = () => {
  const modalIsOpen = useSelector(getLoadingModal);
  const dispatch = useDispatch();
  const intlMessages = useSelector(getIntlMessages);
  const loadingText = useSelector(getLoadingModalText);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('loading'))
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={loadingStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Loading"
    >
      <div style={{position: 'relative'}}>
        <Box
          boxShadow={'0px -2px 15px rgba(0, 0, 0, 0.08)'}
        >
        </Box>
        <LoadingContent
          text={intlMessages[`loading.text.${loadingText}`]}
          />
      </div>
    </Modal>
  )
}

export const PloverScanningModal = () => {
  const location = useLocation();
  const history = useHistory();
  const modalIsOpen = location.pathname.startsWith("/createrequest");
  // change to base this on pathname
  //const modalIsOpen = useSelector(getScanningPageModal);
  const dispatch = useDispatch();

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    //dispatch(actions.closeModal('scanningPage'))
    history.push("/")

  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={ploverScanningStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Plover Scanning"
    >
      <div style={{position: 'relative'}}>
        <ScanningPage closeModal={closeModal} />
      </div>
    </Modal>
  )
}

export const RecordModal = () => {
  const modalIsOpen = useSelector(getRecordDetailModal);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //let params = new URLSearchParams(location.search);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    let searchParams = new URLSearchParams(location.search);
    dispatch(actions.closeModal('recordDetail'))
    dispatch(recordActions.selectRecord(null));
    dispatch(recordActions.setCurrentMedia(null));
    searchParams.delete("rec")
    history.push(location.pathname + "?" + searchParams);
  }

  // so i didnt have to keep loading records from remote
  if (modalIsOpen){
    recordStyles.overlay.visibility = 'visible'
  } else {
    recordStyles.overlay.visibility = 'hidden'
  }

  return(
    <Modal
      className="recordModal"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={recordStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Record"
    >

      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <RecordDetail />
      </div>
    </Modal>
  )
}

export const UploadDetectionModal = () => {
  const modalIsOpen = useSelector(getUploadDetectionModal);
  const dispatch = useDispatch();

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('uploadDetection'))
    dispatch(recordActions.setCurrentDetectionMedia(null));
  }

  return(
    <Modal
      className="uploadDetection"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={recordStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Upload Detection"
    >
      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal} ><CloseSvg /></CloseIcon>
        <RecordDetectionDetail />
      </div>
    </Modal>
  )
}

/* const VideoChatModal = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getVideoChatModal);

  const afterOpenModal = () => {
    // all refs are set
  }

  const closeModal = () => {
    dispatch(actions.closeModal('videoChat'))
  }

  return (
    <Modal
      className="videoChatModal"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={videoChatStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Video Chat Scan"
    >
      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <VideoChat
          type={"videoChat"}
        />
      </div>
    </Modal>
  )

}*/

export const AttachmentPloverScanModal = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getAttachmentPloverScanModal);
  const userId = useSelector(getUUID);
  const conversationId = useSelector(getCurrentConversationId);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('attachmentPloverScan'))
  }

  const onScanFinished = (currentRecordId) => {
    // TODO send record to chat.
    console.log("send record ", currentRecordId)
    const recordMsg = {
      type: "record",
      senderId: userId,
      text: currentRecordId
    }
    dispatch(chatActions.sendRecord(recordMsg));
    dispatch(mediaActions.resetMedia());
    dispatch(actions.closeModal('attachmentPloverScan'));
    dispatch(chatActions.setShowRecordsAttachment(false));
  }

  return (
    <Modal
      className="recordModal"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={attachmentPloverScanStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Attachment Plover Scan"
    >
      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <PloverScan
          closeModal={closeModal}
          onScanFinished={onScanFinished}
          type={"attachment"}
          />
        </div>
    </Modal>
  )
}

export const DetectionScanModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const modalIsOpen = useSelector(getDetectionScanModal);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    let newParams = new URLSearchParams();
    newParams.delete("detectionbeta")
    console.debug("new params: ", newParams)
    history.push(`${location.pathname}?${newParams}`)
    //dispatch(actions.closeModal('detectionScan'))
  }

  const onScanFinished = () => {
  }

  return (
    <Modal
      className="detectionScan"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={attachmentPloverScanStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Detection Upload Request"
    >
      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <DetectionScan closeModal={closeModal}  />
      </div>
    </Modal>
  )
}

export const ProfileListModal = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getProfileListModal);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('profileList'));
  }

  return (
    <Modal
      className="profileList"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={howToStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Profile List"
    >
      <div style={{position: 'relative', maxHeight: '80vh'}}>
        {/*<CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>*/}
        <ProfileList
          closeModal={closeModal}
          />
      </div>
    </Modal>
  )
}

export const AttachmentPartialScanModal = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getPartialScanModal);
  const userId = useSelector(getUUID);
  const recordId = useSelector(getCurrentPartialScanId);
  const messageTimeToken = useSelector(getPartialScanMessageTimeToken);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('partialScan'))
  }

  const onScanFinished = () => {
    // TODO send record to chat.
    const recordMsg = {
      type: "record_partialscan",
      senderId: userId,
      text: recordId
    }
    dispatch(chatActions.sendPartialScan(recordMsg));
    dispatch(chatActions.addMessageActionPartialScan(messageTimeToken));
    dispatch(actions.closeModal('partialScan'));
    dispatch(chatActions.setShowRecordsAttachment(false))
  }

    return (
      <Modal
        className="partialScan"
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={attachmentPloverScanStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Partial Scan"
      >
        <div style={{position: 'relative'}}>
          <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
          <PartialScan
            onScanFinished={onScanFinished}
            type={"partialscan"}
            />
        </div>
      </Modal>
    )
}

export const CCardModal = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getCardFormModal);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('cardForm'))
  }

  return (
    <Modal
      className="cCardForm"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={cCardFormStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Credit Card Form"
      >
      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal}><CloseSvg /></CloseIcon>
        <CCardForm  />
      </div>
    </Modal>
  )
}

export const FirebaseNotificationsModal = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getFirebaseNotificationsModal);

  const afterOpenModal = () => {

  }

  const closeModal = () => {
    dispatch(actions.closeModal('firebaseNotifications'))
  }

  return (
    <Modal
      className="notificationPermission"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Firebase Notifications"
      >
      <div style={{position: 'relative'}}>
        <FirebaseNotifications closeModal={closeModal} />
      </div>
    </Modal>
  )
}

export const APNNotificationsModal = ({checkAPNPermission}) => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getApnNotificationsModal);

  const afterOpenModal = () => {

  }

  const closeModal = () => {
    dispatch(actions.closeModal('apnNotifications'))
  }

  return (
    <Modal
      className="notificationPermission"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="APN Notifications"
      >
      <div style={{position: 'relative'}}>
        <APNNotifications closeModal={closeModal} checkAPNPermission={checkAPNPermission} />
      </div>
    </Modal>
  )
}

export const SafariVersionModal = ({}) => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getSafariVersionModal);

  const afterOpenModal = () => {

  }

  const closeModal = () => {
    dispatch(actions.closeModal('safariVersion'))
  }

  return (
    <Modal
      className="safariVersion"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Safari Version"
      >
      <div style={{position: 'relative'}}>
        <SafariVersion closeModal={closeModal}  />
      </div>
    </Modal>
  )
}

export const SwitchLanguageModal = ({}) => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(getSwitchLanguageModal);

  const afterOpenModal = () => {

  }

  const closeModal = () => {
    dispatch(actions.closeModal('switchLanguage'))
  }

  return (
    <Modal
      className="switchLanguage"
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={true}
      contentLabel="Switch Language"
      >
      <div style={{position: 'relative'}}>
        <SwitchLanguage closeModal={closeModal}  />
      </div>
    </Modal>
  )
}

export const MessageModal = () => {
  const modalIsOpen = useSelector(getMessageModal);
  const messageText = useSelector(getMessageModalText);
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('message'))
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={messageStyles}
      shouldCloseOnOverlayClick={true}
      contentLabel="Error"
    >

      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <MessageContent
          header={intlMessages[`modal.message.${messageText}.header`]}
          text={intlMessages[`modal.message.${messageText}.text`]}
          closeModal={closeModal}
          />
      </div>
    </Modal>
  )
}

export const HowToModal = () => {
  const modalIsOpen = useSelector(getHowToModal);
  const howToContent = useSelector(getHowToModalContent);
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('howTo'))
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={howToStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="How To"
    >

      <div style={{position: 'relative'}}>
        <StyledHowToContent>
          <HowToContent
            content={howToContent}
            closeModal={closeModal}
            />
        </StyledHowToContent>
      </div>
    </Modal>
  )
}

export const GenerateConfirmModal = () => {
  const modalIsOpen = false//  useSelector(getGenerateConfirmDialogModal);
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('generateConfirm'))
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={howToStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Generate AI"
    >

      <div style={{position: 'relative'}}>
        <GenerativeReportConfirmation />
      </div>
    </Modal>
  )
}

export const ConfirmDialog = () => {
  const modalIsOpen = useSelector(getConfirmDialogModal);
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();
  const header = useSelector(getConfirmDialogHeader);
  const text = useSelector(getConfirmDialogText);
  const negativeCallbacks = useSelector(getConfirmDialogNegativeCallback);
  const positiveCallbacks = useSelector(getConfirmDialogPositiveCallback);
  const positiveLabel = useSelector(getConfirmDialogPositiveLabel);

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('confirmDialog'))
  }

  const negativeCallback = () => {
    negativeCallbacks.forEach((elem) => {
      dispatch(elem);
    })
  }

  const positiveCallback = () => {
    positiveCallbacks.forEach((elem) => {
      dispatch(elem);
    })
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={messageStyles}
      shouldCloseOnOverlayClick={true}
      contentLabel="Error"
    >

      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <MessageContent
          header={header}
          text={text}
          negativeCallback={negativeCallback}
          closeModal={positiveCallback || closeModal}
          positiveLabel={positiveLabel || intlMessages['scanningpage.media.removemedia.close.button.confirm']}
          />
      </div>
    </Modal>
  )
}

export const ErrorModal = () => {
  const modalIsOpen = useSelector(getErrorModal);
  const errorText = useSelector(getErrorModalText);
  const intlMessages = useSelector(getIntlMessages);
  const dispatch = useDispatch();

  const afterOpenModal = () => {
    /* all refs are set*/
  }

  const closeModal = () => {
    dispatch(actions.closeModal('error'))
  }

  return(
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={generalStyles}
      shouldCloseOnOverlayClick={true}
      contentLabel="Error"
    >

      <div style={{position: 'relative'}}>
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <ErrorContent
          header={intlMessages[`error.${errorText}.header`]}
          text={intlMessages[`error.${errorText}.text`]}
          />
      </div>
    </Modal>
  )
}

export const AllModals = ({...rest}) => {
  return (
    <>
    <ErrorModal />
    <MessageModal />
    <LoadingModal />
    <CCardModal />
    <PloverScanningModal />
    <RecordModal />
    <DetectionScanModal />
    <AttachmentPloverScanModal />
    <AttachmentPartialScanModal />
    <FirebaseNotificationsModal />
    <SafariVersionModal />
    <SwitchLanguageModal />
    <UploadDetectionModal />
    <HowToModal />
    <APNNotificationsModal checkAPNPermission={rest?.checkAPNPermission}/>
    <ProfileListModal />
    <ConfirmDialog />
    </>
  )
}