import React, { useState } from 'react';
import { 
  Box, 
  Text,
  Flex,
  HStack,
  VStack,
  Skeleton,
  Circle,
  Divider,
} from '@chakra-ui/react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toUpper } from 'lodash';
import { useReportStore } from '../state/report';
import storage from '../../_helpers/storage';
import { Categories } from '../../Reports/components/ToothChart';

import { ToothSetModern } from '../../_components/ToothSetModern';
import { localeFormatDate } from '../../_helpers';
import { getIntlLocale } from '../../Authentication/_selectors';
import { HealthyReportSection } from './HealthyReportSection';

export const ToothChart = ({data, isDMFT=false, height=360, width=190}) => {
  const [ isFlipped, setIsFlipped ] = useState(storage.getChartFlipped() || true)
  const { category } = useReportStore();

  const toothClasses = {};
  const categoriesCount = {};

  // go through the block data and convert it to tooth charting object
  // object with key of tooth number "txx" and value of category in all caps for class
  (data?.blocks || []).map((block) => {
      const data = block?.data;
      if (category != null){
        // check that the category matches the selected category
        if (toUpper(data.category) == toUpper(category)){
          data.regions.map((region) => {
            if (region.length > 0){
              // will overwrite any previous block styles
              if (toothClasses[`t${region}`]){
                  toothClasses[`t${region}`].push(toUpper(block.data.category))
                  !isDMFT && toothClasses[`t${region}`].push("MULTIPLE")
              } else {
                  toothClasses[`t${region}`] = [toUpper(block.data.category)]
              }
            }
          })
        }
      } else {
        // if category is null, then add all categories
        data.regions.map((region) => {
          if (region.length > 0){
            // will overwrite any previous block styles
            if (toothClasses[`t${region}`]){
                toothClasses[`t${region}`].push(toUpper(block.data.category))
                !isDMFT && toothClasses[`t${region}`].push("MULTIPLE")
            } else {
                toothClasses[`t${region}`] = [toUpper(block.data.category)]
            }
          }
        })
      }
      categoriesCount[toUpper(data.category)] = (categoriesCount[toUpper(data.category)] || 0) + 1
      
  })

  const handleFlip = () => {
    //storage.setChartFlipped(!isFlipped)
    //setIsFlipped(!isFlipped)
  }

  return (
      <Flex 
        w={['full']}
        justify={'center'} 
        background={'#fff'}
        borderRadius={['25px']}
        direction={isFlipped ? 'row-reverse' : 'row'}
        onClick={handleFlip}
      >
        <Flex align={['center']}>
          <Text mr={['5px']}>
            <FormattedMessage
              id={'reportDetailsPage.reviewReportModal.toothChart.right'}
              defaultMessage={'R'}
              />
          </Text>
        </Flex>
        <ToothSetModern
          toothClasses={toothClasses}
          width={width}
          height={height}
          isFlipped={isFlipped}
        />
        <Flex align={['center']}>
          <Text ml={['5px']}>
            <FormattedMessage
              id={'reportDetailsPage.reviewReportModal.toothChart.left'}
              defaultMessage={'L'}
              />
          </Text>
        </Flex>
      </Flex>
  )
}

const ToothLabelItem = ({toothNum, category=""}) => {
    let color = "#273238"
    let fontColor = "#FFF"
    if (category.toLowerCase() == "caries"){
      color="#DC4E41"
      fontColor="#fff"
    } else if(category.toLowerCase() == "color"){
      color="#367FFF"
      fontColor="#fff"
    } else if(category.toLowerCase() == "gums"){
      color="#FFA500"
      fontColor="#273238"
    } else if(category.toLowerCase() == "habits"){
      color="#DAFF00"
      fontColor="#273238"
    } else if(category.toLowerCase() == "ortho"){
      color="#405159"
      fontColor="#fff"
    } else if(category.toLowerCase() == "general" || category.toLowerCase() == "others"){
      color="#A9A8A8"
      fontColor="#273238"
    }
    return (
      <Circle size={'30px'} bg={color}>
        <Text
          color={fontColor}
          fontSize={'15px'}
        >
          {toothNum}
        </Text>
      </Circle>
    )
  }
  
  
//import html2canvas from 'html2canvas';
//import { jsPDF } from "jspdf";
const CategoryLabelItem = ({block}) => {
    // data?.
    // block?.data?.category <- for color
    // block?.data?.subCategory
    // block?.data?.regions
    return (
      <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'}>
        <Text color="#394B55" fontSize={'18px'} textTransform={'capitalize'}>
          {block?.data?.title || block?.data?.subCategory}
        </Text>
        <HStack spacing={['8px']} mt={['10px']}>
          {
            block?.data?.regions.map((toothNum) => (
              <ToothLabelItem key={`${block?.id}_${toothNum}`} toothNum={toothNum} category={block?.data?.category || ""} />
            ))
          }
        </HStack>
      </Flex>
    )

}
const CategoryLabelSection = ({data}) => {

  return (
    <VStack align={'flex-start'} justify={'flex-start'} spacing={['15px']}>
      {
        (data?.blocks || []).map((block) => (<CategoryLabelItem key={block?.id} block={block} />))
      }

    </VStack>
  )
}
function removeXmlAndEntities(str) {
  // Remove XML tags
  return str.replace(/<toothnumbers[^>]*>(.*?)<\/toothnumbers>/gis, function(match, p1) {
    return p1.replace(/<tooth[^>]*>[\s\S]*?<\/tooth>/gi, '') // Remove all <tooth> tags and their contents
             .replace(/&nbsp;|,/g, ''); // Remove all &nbsp; entities and commas
  }).replace(/^[\s.]+/, '');
}

function removeXmlAndKeepTeeth(str) {
  // Removing HTML tags
  let output = str.replace(/<\/?[^>]+(>|$)/g, "");
  // Replacing HTML entities
  output = output.replace(/&nbsp;/gi, " "); // Replace all occurrences of &nbsp; with a space

  return output;
}

const CategoryCommentItem = ({block, type}) => {

    // data?.
    // block?.data?.category <- for color
    // block?.data?.subCategory
    // block?.data?.regions
    // block?.data?.text

    const comment = (type == "DMFT") ? removeXmlAndEntities(block?.data?.text) : removeXmlAndKeepTeeth(block?.data?.text)
    return (
      <Flex direction={'column'}  align={'flex-start'} justify={'flex-start'}>
        <Text color="#394B55" fontSize={'18px'} textTransform={'capitalize'}
          textAlign={'left'}
        >
        {block?.data?.title || block?.data?.subCategory}
        </Text>
        <Text
          color={'#405159'}
          fontSize={'15px'}
          lineHeight={'18px'}
          textAlign={'left'}
          mt={'5px'}
        >
          {comment}
        </Text>
      </Flex>
    )
}

const CategoryCommentsSection = ({data, type}) => {
  return (
    <VStack align={'flex-start'} justify={'flex-start'} spacing={['15px']} mt={'20px'}>
      {
        (data?.blocks || []).map((block) => (<CategoryCommentItem key={block?.id} block={block} type={type} />))
      }
    </VStack>
  )
}

export const PrintPreviewReportSection = ({data, type="DMFT", status=null, isPrint=true}) => {
  // if report is complete and is healthy (no conditions, )
  const isCleanReport = (status == 3 || ((status || "").indexOf("COMPLETE") !== -1)) && (!data?.blocks || data?.blocks?.length == 0);
  if (isCleanReport){
    return (
      <HealthyReportSection />
    )
  } else if (!data?.blocks || data?.blocks?.length == 0){
    console.log("report status ", status)
      if (status == 3 || ((status || "").indexOf("COMPLETE") !== -1) || ((status || "").indexOf("INSPECTING") !== -1)){
        return (
          <HealthyReportSection />
        )
      } else {
        return (
            <Box 
              p={['20px']} 
              pl={[0]}
              m={['5px']}
              bg={['#fff']}
              borderRadius={['15px']}
            >
              <Skeleton height={['580px']} w={['full']} />
            </Box>
        )
      }
  } 
  if (isPrint){
    return (
        <Box w={'580px'}>

            <Box
              p={['20px']} 
              pl={[0]}
              m={['5px']}
              bg={['#fff']}
              borderRadius={['15px']}
            >
                <CategoryLabelSection data={data} />
                <Divider orientation='horizontal' borderColor={'#C5C5C5'} py={'10px'} />
                <CategoryCommentsSection data={data} type={type}/>
            </Box>
        </Box>
    )
  } else {
    return (
        <Box w={'520px'} height={'580px'}>

          <Scrollbars autoHide style={{height: '100%'}} >
            <Box
              p={['20px']}
              pl={[0]}
              m={['5px']}
              bg={['#fff']}
              height={'100%'}
              borderRadius={['15px']}
            >   
                <CategoryLabelSection data={data} />
                <Divider orientation='horizontal' borderColor={'#C5C5C5'} py={'10px'} />
                <CategoryCommentsSection data={data} type={type}/>
            </Box>
          </Scrollbars>
        </Box>
    )
  }
}

const PrintPreviewChartSection = ({status, type, data, height=360, width=190}) => {
  const categoriesCount = {};

  // go through the block data and convert it to tooth charting object
  // object with key of tooth number "txx" and value of category in all caps for class
  (data?.blocks || []).map((block) => {
      categoriesCount[toUpper(block?.data.category)] = (categoriesCount[toUpper(block?.data.category)] || 0) + 1  
  })

  const isDMFT = (type || "").indexOf("DMFT") !== -1

  if (isDMFT || data == null){

    return( 
      <Box  
        px={['0px']}
        py={'20px'}
        pb={'30px'}
        bg={'#fff'}
        minW={['390px']}
        m={['5px']}
        borderRadius={['30px']}
      >
          <ToothChart 
            isDMFT={isDMFT}
            data={data} 
            width={width}
            height={height}
          />
      </Box>
    )
  } else {

    return( 
      <Box  
        px={['20px']}
        bg={'#fff'}
        minW={['390px']}
        m={['5px']}
        borderRadius={['30px']}
      >
        <Flex w={'full'} justify={['end']} pt={['20px']}>
          {/*<CategoriesFilterSelect isDisabled={!data?.blocks} categoriesCount={categoriesCount} isLabelShowing={false} />*/}
        </Flex>
          <ToothChart 
            data={data} 
            width={width}
            height={height}
          />
        <Categories isHidden={(type || "").indexOf("DMFT") !== -1} type={type} />
      </Box>
    )
  }

}

const PrintPreview = React.forwardRef(({ data, isLoading, isFetching, status=null }, ref) => {
    let reportData = data?.report?.report_data;
    if (data?.type == "GEN_DMFT") { // TODO "GENERATIVE"?
      // if insepected report is complete, then return the .report, not .user_report.
      // for some reason dentist report COMPLETE ==3 , not "COMPLETE"
      if (data?.user_report?.needs_inspection == true && data?.report?.status == 3){
        reportData = data?.report?.report_data
      } else {
        reportData = data?.user_report?.report_data
      }
    }

    if (isLoading || isFetching || reportData) {
        return (
          <Box ref={ref} >
            <Flex overflow={'auto'} maxH={['70vh']} justify={['center']}>
                <PrintPreviewChartSection status={status} type={data?.type}  data={reportData} />
                <PrintPreviewReportSection status={status} data={reportData} type={data?.report?.report_data} remark={data?.report?.remark} />
            </Flex>
          </Box>
        );
    } else {
        return <></>;  // Return null instead of empty fragment for potentially cleaner output
    }
});

PrintPreview.displayName = 'PrintPreview';
  

export const ReportPrintPreview = React.forwardRef(({data, status, isLoading, isFetching}, ref) => {
    const intlLocale = useSelector(getIntlLocale);
    let dateString = data?.created_at
    return (
        <div style={{ display: "none"}}>
        <Box ref={ref}  pt={'20px'}>
            <Flex overflow={'auto'} maxH={['70vh']} justify={['center']} pb={'30px'}>
            <Text fontSize={['24px']} color={'#273238'}>
                { `${localeFormatDate(dateString, intlLocale, 'll')}` }
            </Text>
            <Text fontSize={['24px']} color={'#273238'} ml={'5px'}>
                {data?.user_profile?.first_name}
            </Text>
            </Flex>
            <PrintPreview status={status} data={data} isLoading={isLoading} isFetching={isFetching} />
        </Box>
        </div>
    )
})

ReportPrintPreview.displayName = 'ReportPrintPreview';
