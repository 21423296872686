import { API_URLS } from '../_config';
import { authHeader, handleResponse } from '../_helpers';


export const services = {
  createRecordId,
  fetchRecords,
  fetchRecordDetail,
  fetchRecordSimple,
  fetchUploadDetections
};

function createRecordId(scanType, profileId, extraData={}){
  const requestUrl = API_URLS.createRecordId;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({type: scanType, user_profile: profileId, extra_data: extraData})
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchRecords(query){
  const requestUrl = API_URLS.fetchRecords;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url + '?' + new URLSearchParams(query);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchRecordDetail(id){
  const requestUrl = API_URLS.fetchRecordDetail;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{recordId}", id);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchRecordSimple(id){
  const requestUrl = API_URLS.fetchRecordSimple;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{recordId}", id);
  return fetch(url, requestOptions).then(handleResponse);
}

function fetchUploadDetections(id){
  const requestUrl = API_URLS.fetchUploadDetections;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };
  let url = requestUrl.url.replace("{uploadId}", id);
  return fetch(url, requestOptions).then(handleResponse);
}
