import React from 'react';
import Modal from 'react-modal';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Box, Flex, Text, Textarea,  RadioGroup, Stack, Radio, Button
} from '@chakra-ui/react';

import { ModalHeader } from '../Modals/ModalHeader';
import { CloseIcon } from '../Modals/styles';
import { CloseIcon as CloseSvg } from '../_images/icons/CloseIcon';

// services
import { services } from './_services';

//actions
import { actions } from './_actions';
import { getIntlMessages } from '../Authentication/_selectors';
import { useIntl, FormattedMessage } from 'react-intl';

const generalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      width: 'fit-content',
      height:  'fit-content',
      outline: 'none',
      background: '#F7F7F7',
      padding: '0',
      position: 'initial',
      borderRadius: '10px', 
      zIndex: 10,
      maxWidth: '600px'
    }
}

const RemoveAssignedDentistForm = ({favoriteId, onClose}) => {
    const intlMessages  = useSelector(getIntlMessages)
    const { formatMessage } = useIntl();
    const [page, setPage] = React.useState(0)
    const [value, setValue] = React.useState(null)
    const [otherValue, setOtherValue] = React.useState(null)

    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        setOtherValue(e.target.value)
    }

    const handleSubmit = async () => {
      console.log(value)
      console.log(otherValue)
      try{
        const resp = await services.deleteFavoriteDentist(favoriteId, {reason: value, other_reason: otherValue})
        dispatch(actions.removeSettingDentist(favoriteId));
      } catch (err) {
        console.log(err)
      }
      onClose();
  }

    const nextPage = () => {
        setPage(1)
    }


    const radioProps = {
      color: '#405159',
      //_checked: {
        //bg: '#405159',
        //sx: {
        //  '&::before': {
        //    background: 'white'
        //  }
       // }
      //}
    }

    return (
        <Box bg={'#F7F7F7'} pb={'40px'}>
        <ModalHeader
          title={intlMessages['settings.details.favoriteDentist.menu.remove.label']}
        />
        <Box
          px={'40px'} 
          pt={'20px'}
        >
          {
            (page === 0) ? (
              <>
                <Box>
                  <Text
                    color={'#273238'}
                    fontSize={'40px'}
                  >
                    {intlMessages['settings.details.favoriteDentist.menu.remove.label']}
                  </Text>
                  <Text
                    mt={'10px'}
                    fontSize={'15px'}
                    color={'#405159'}
                  >
                    <FormattedMessage 
                      id={'settings.details.favoriteDentist.menu.remove.description'}
                      defaultMessage={'Please let us know why you want to remove your assigned dentist. This will help us improve our services. Your feedback is valuable to us.'}
                    />
                  </Text>
                </Box>
                <RadioGroup mt={'30px'} onChange={setValue} value={value}>
                  <Stack direction='column' spacing={'20px'}>
                    <Radio {...radioProps} value='dissatisfied'>
                      <Text color={'#405159'}>
                        <FormattedMessage
                            id={'settings.details.favoriteDentist.menu.remove.dissatisfied'}
                            defaultMessage={'Dissatisfied with service'}
                          />
                      </Text></Radio>
                    <Radio {...radioProps} value='relocating'>
                      <Text color={'#405159'}>
                        <FormattedMessage
                            id={'settings.details.favoriteDentist.menu.remove.relocating'}
                            defaultMessage={'Relocating to a different area'}
                        />
                      </Text>
                    </Radio>
                    <Radio {...radioProps} value='preference'><Text color={'#405159'}>
                      <FormattedMessage
                          id={'settings.details.favoriteDentist.menu.remove.preference'}
                          defaultMessage={'Prefer a different dentist'}
                      />
                      </Text></Radio>
                    <Radio {...radioProps} value='specialized'><Text color={'#405159'}>
                      <FormattedMessage
                          id={'settings.details.favoriteDentist.menu.remove.specialized'}
                          defaultMessage={'Need specialized dental treatment'}
                      />
                      </Text></Radio>
                    <Radio {...radioProps} value='other'>
                      <Text color={'#405159'}>
                        <FormattedMessage
                            id={'settings.details.favoriteDentist.menu.remove.other'}
                            defaultMessage={'Other'}
                        />
                      </Text>
                    </Radio>
                    {
                        (value === 'other') && (
                            <Textarea 
                                rows={4}
                                value={otherValue}
                                onChange={handleInputChange}
                                placeholder={formatMessage({'id': 'settings.details.favoriteDentist.menu.remove.other.description', 'defaultMessage': 'Please specify'})}
                            />
                        )
                    }
                  </Stack>
                </RadioGroup>
              </>
            ) : (
              <Box>
                <Text
                  color={'#273238'}
                  fontSize={'40px'}
                >
                  <FormattedMessage
                      id={'settings.details.favoriteDentist.menu.remove.confirmation'}
                      defaultMessage={'Confirmation'}
                  />
                </Text>
                <Text
                  mt={'10px'}
                  fontSize={'15px'}
                  color={'#405159'}
                >
                  <FormattedMessage
                      id={'settings.details.favoriteDentist.menu.remove.confirmation.description'}
                      defaultMessage={'Removing your assigned dentist will unlink you from their services. You will need to choose a new dentist for future checkup requests.'}
                  />
                </Text>
                <Text
                  mt={'30px'}
                  fontSize={'15px'}
                  color={'#405159'}
                >
                  <FormattedMessage
                      id={'settings.details.favoriteDentist.menu.remove.confirmation.warning'}
                      defaultMessage={'This action cannot be undone. Do you still want to proceed?'}
                  />
                </Text>
              </Box>
            )
          }
          <Flex w={'full'} justify={'space-between'} pt={'30px'} pb={'0px'}>
            <Flex grow={1} pr={'10px'}>
              <Button w={'full'} variant={'outline'} onClick={onClose}>
                <FormattedMessage
                    id={'settings.details.favoriteDentist.menu.remove.button.cancel'}
                    defaultMessage={'Cancel'}
                />
              </Button>
            </Flex>
            <Flex grow={1} pl={'10px'}>
              <Button w={'full'} onClick={(page === 0) ? nextPage : handleSubmit} >
                <FormattedMessage
                    id={'settings.details.favoriteDentist.menu.remove.button.next'}
                    defaultMessage={'Next'}
                />
              </Button>
            </Flex>
          </Flex>
        </Box>
        </Box>
    )

}

export const RemoveAssignedDentistModal = ({isOpen, onClose, favoriteId}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
  
    //let params = new URLSearchParams(location.search);
  
    const afterOpenModal = () => {
      /* all refs are set*/
    }
  
    const closeModal = () => {
        onClose();
    }
  
    // so i didnt have to keep loading records from remote
    if (isOpen){
        generalStyles.overlay.visibility = 'visible'
    } else {
        generalStyles.overlay.visibility = 'hidden'
    }

  
    return(
      <Modal
        className="removeFavoriteModal"
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={generalStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="AssignedDentist"
      >
  
        <CloseIcon onClick={closeModal} ><CloseSvg/ ></CloseIcon>
        <RemoveAssignedDentistForm favoriteId={favoriteId} onClose={onClose} />
      </Modal>
    )
  }
  