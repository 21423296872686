import React from 'react';

export const SelfScanAttachmentIcon = () => {

  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1378 1C10.3999 1 9.89453 1.24165 9.406 1.47515C8.97085 1.68344 8.55984 1.87913 8.00037 1.87987C7.44016 1.87913 7.02988 1.6827 6.59473 1.47515C6.1062 1.24165 5.60085 1 4.86293 1C2.73254 1 1 3.0985 1 5.25704C1 7.54233 2.48974 15.7621 4.65961 15.9949C4.68594 15.9978 4.71081 15.9993 4.7364 15.9993C5.48529 15.9993 5.79758 14.8874 6.15886 13.6006C6.55597 12.1878 7.00575 10.5859 7.98866 10.5548H7.99086H8.01207C8.99425 10.5859 9.44403 12.1878 9.84114 13.6006C10.2032 14.8874 10.5154 15.9993 11.2643 15.9993C11.295 16.0015 11.3148 15.9978 11.3404 15.9949C13.511 15.7621 15 7.41928 15 4.91532C15 2.75604 13.2675 1 11.1378 1Z" stroke="#C7D3D9" strokeWidth="1.2"/>
    </svg>
  )
}
