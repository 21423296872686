import { createSelector } from 'reselect';

export const getCheckupsSlice = (state) => state.checkups;

export const getPaymentIntent = createSelector(
  [getCheckupsSlice],
  (checkups) => {
    return checkups.paymentIntent;
  }
)

export const getPaymentInformation = createSelector(
  [getCheckupsSlice],
  (checkups) => {
    return checkups.paymentInformation;
  }
)

export const getPaymentProcessor = createSelector(
  [getCheckupsSlice],
  (checkups) => {
    return checkups.paymentProcessor;
  }
)

export const getCurrentCheckupRequestId = createSelector(
  [getCheckupsSlice],
  (checkups) => {
    return checkups.checkupRequest;
  }
)
export const getCurrentCheckupRecord = createSelector(
  [getCheckupsSlice],
  (checkups) => {
    return checkups.checkupRecord;
  }
)
