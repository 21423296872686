import { createSelector } from 'reselect'

export const getDetectionState = (state) => state.detection;

export const getReviewOpen = createSelector(
  getDetectionState,
  (state) => {
    return state.reviewOpen;
  }
)

export const getDetectionScan = createSelector(
  getDetectionState,
  (state) => {
    return state.detectionScan;
  }
)

export const getIsDetectionUploaded = createSelector(
  getDetectionState,
  (state) => {
    return state.detectionUploaded;
  }
)

export const getUploadTotal = createSelector(
  getDetectionState,
  (state) => {
    return 100;
  }
)

export const getUploadProgress = createSelector(
  getDetectionState,
  (state) => {
    return state.uploadProgress;
  }
)
